import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { catchError, map, take, tap } from 'rxjs/operators';
import { Learner } from '@models/learner.model';
import { VCalendarEvent } from '@models/v-calendar-event.model';
import { PracticeLessonTopic } from '@models/practice-lesson-topic.model';
import { LearnerDocument } from '@models/learner-document.model';
import { TeamMember } from '@models/team-member.model';
import { LearnerTheoryLesson } from '@models/learner-theory-lesson.model';
import { LessonBooking } from '@models/lesson-booking.model';

import { saveAs as importedSaveAs } from 'file-saver';
import { NotificationService } from './notification.service';
import { BasicFee } from '@models/basic-fee.model';
import { LearnerDocumentType } from '@models/learner-document-type.model';
import { PostalCodeInfo } from '@models/postal-code-info.model';
import { AddLearnerCreditRequest } from '@models/add-learner-credit-request.model';
import { LearnerCredit } from '@models/learner-credit.model';
import { LearnerLessonsPreference } from '@models/learner-lessons-preference.model';
import { MobileAppPaymentStatus } from '@models/mobile-app-payment-status.model';
import { ManualTransmissionProofAvailableRes } from '@models/manual-transmission-proof-available-res.model';
import { LearnerSeenType } from '@models/learner-seen.model';

@Injectable()
export class LearnerService {
  private readonly apiPath = `${env.apiSchoolService}`;

  constructor(
    private api: ApiService,
    private notificationService: NotificationService
  ) {}

  getLearner(id: number): Observable<Learner> {
    return this.api.get(`${this.apiPath}/learners/${id}`).pipe(
      take(1),
      map((it) => new Learner(it))
    );
  }

  getLearnerByUserUID(uid: string): Observable<Learner> {
    return this.api.get(`${this.apiPath}/learners/user_uid/${uid}`).pipe(
      take(1),
      map((it) => new Learner(it))
    );
  }

  getUpcomingEvents(
    learnerId: number,
    instructorId?: number
  ): Observable<VCalendarEvent[]> {
    let url = `${this.apiPath}/learners/${learnerId}/upcoming_events`;
    if (instructorId) {
      url += `?instructorId=${instructorId}`;
    }
    return this.api.get(url).pipe(
      map((it) => this.mapEvents(it)),
      map((it) => this.mapConfirmedEvents(it))
    );
  }

  private mapEvents(events: VCalendarEvent[]) {
    return events.map((it) => new VCalendarEvent(it));
  }

  private mapConfirmedEvents(events: VCalendarEvent[]): VCalendarEvent[] {
    return events.filter(
      (it) =>
        ((it.isPracticeEvent() || it.isExamEvent()) && it.isConfirmed()) ||
        (it.isTheoryEvent() && it.isRequested())
    );
  }

  getLearnerPracticeLessons(
    learnerId: number,
    lessonTopics: PracticeLessonTopic[],
    filters: Map<string, any>
  ): Observable<LessonBooking[]> {
    let url = `${this.apiPath}/learners/${learnerId}/practice_lessons`;
    if (lessonTopics.length > 0) {
      let queryParams = '';
      lessonTopics.forEach((topic) => (queryParams += `&topics=${topic}`));
      url += `?${queryParams.substring(1)}`;
    }

    return this.api
      .get<LessonBooking[]>(url, filters)
      .pipe(map((res) => this.mapLessonBookings(res)));
  }

  getLearnerNextStandardizedTopicHandler(
    learnerId: number
  ): Observable<number> {
    return this.api
      .get(
        `${this.apiPath}/learners/${learnerId}/next_standardized_practice_topic`
      )
      .pipe(map((it) => it.nextStandardizedPracticeTopic));
  }

  private mapLessonBookings(res: LessonBooking[]): LessonBooking[] {
    if (!res) {
      return [];
    }
    return res.map((it) => new LessonBooking(it));
  }

  getLearnerTheoryLessons(
    learnerId: number
  ): Observable<LearnerTheoryLesson[]> {
    return this.api
      .get<LearnerTheoryLesson[]>(
        `${this.apiPath}/learners/${learnerId}/theory_lessons`
      )
      .pipe(map((res) => this.mapLearnerTheoryLessons(res)));
  }

  getLearnerAdditionalTheoryLessons(
    learnerId: number
  ): Observable<LearnerTheoryLesson[]> {
    return this.api
      .get<LearnerTheoryLesson[]>(
        `${this.apiPath}/learners/${learnerId}/additional_theory_lessons`
      )
      .pipe(map((res) => this.mapLearnerTheoryLessons(res)));
  }

  updateLearnerTheoryTopic(
    learnerId: number,
    theoryTopicKey: string,
    unlocked: boolean
  ): Observable<void> {
    return this.api.put(
      `${this.apiPath}/learners/${learnerId}/theory_topic/${theoryTopicKey}`,
      { unlocked }
    );
  }

  private mapLearnerTheoryLessons(
    lessons: LearnerTheoryLesson[]
  ): LearnerTheoryLesson[] {
    return lessons.map((it) => new LearnerTheoryLesson(it));
  }

  getTheoryProofForLearner(learnerId: number) {
    return this.api
      .getBlob(`${this.apiPath}/learners/${learnerId}/theory_proof`)
      .pipe(
        tap((blob: Blob) =>
          importedSaveAs(
            blob,
            `Theoriebescheinigung-${this.getPrintableDate(new Date())}.pdf`
          )
        ),
        catchError((err) => {
          this.notificationService.loadError();
          return of(err);
        })
      );
  }

  getTrainingConfirmationForLearner(
    learnerId: number,
    learnerName: string,
    licenseKey?: string,
    schoolId?: number,
    teamMemberId?: number,
    setCompleted = false,
    reset = false
  ) {
    const params = new Map<string, string>();
    if (licenseKey) {
      params.set('licenseKey', encodeURIComponent(licenseKey));
    }
    if (schoolId) {
      params.set('schoolId', '' + schoolId);
    }
    if (teamMemberId) {
      params.set('teamMemberId', '' + teamMemberId);
    }
    params.set('setCompleted', `${setCompleted}`);
    params.set('reset', `${reset}`);

    return this.api
      .getBlob(
        `${this.apiPath}/learners/${learnerId}/training_confirmation`,
        params
      )
      .pipe(
        tap((blob: Blob) =>
          importedSaveAs(
            blob,
            `${learnerId}_${learnerName}_Ausbildungsnachweis-${this.getPrintableDate(
              new Date()
            )}.pdf`
          )
        ),
        catchError((err) => {
          if (err) {
            const fr = new FileReader();

            fr.addEventListener('load', (e) => {
              const errJson = JSON.parse(fr.result.toString());
              if (
                errJson?.error.includes(
                  'could not generate training document: no lessons'
                )
              ) {
                this.notificationService.warning(
                  'Noch kein Dokument mit abgeschlossenen Termine vorhanden'
                );
              } else {
                this.notificationService.loadError();
              }
            });

            fr.readAsText(err);
          } else {
            this.notificationService.loadError();
          }

          return of(err);
        })
      );
  }

  getLearnerHasManualProofAvailable(
    learnerId: number
  ): Observable<ManualTransmissionProofAvailableRes> {
    return this.api.get<ManualTransmissionProofAvailableRes>(
      `${this.apiPath}/learners/${learnerId}/manual_proof_available`
    );
  }

  getManualTransmissionProofForLearner(
    learnerId: number,
    licenseId: number,
    getProof: boolean
  ) {
    const params = new Map<string, string>();
    params.set('licenseId', licenseId.toString());
    params.set('getProof', `${getProof}`);

    return this.api
      .getBlob(
        `${this.apiPath}/learners/${learnerId}/manual_transmission_proof`,
        params
      )
      .pipe(
        tap((blob: Blob) =>
          importedSaveAs(
            blob,
            `Schaltnachweis-${this.getPrintableDate(new Date())}.pdf`
          )
        ),
        catchError((err) => {
          this.notificationService.loadError();
          return of(err);
        })
      );
  }

  generateContractForLearner(
    learnerId: number,
    learnerLicenseId: number,
    schoolLicensePricingId: number,
    final = false,
    type = LearnerDocumentType.CONTRACT,
    signatureLearner?: string,
    signatureGuardian?: string
  ): Observable<Blob> {
    const body = { final };
    body['type'] = type;
    body['learnerLicenseId'] = learnerLicenseId;
    body['schoolLicensePricingId'] = schoolLicensePricingId;
    if (signatureLearner) {
      body['signatureLearnerBase64Content'] = signatureLearner;
    }
    if (signatureLearner) {
      body['signatureGuardianBase64Content'] = signatureGuardian;
    }

    return this.api.postBlob(
      `${this.apiPath}/learners/${learnerId}/documents/contract`,
      body
    );
  }

  generateDrivingLicenseApplicationForLearner(
    learnerId: number
  ): Observable<Blob> {
    return this.api
      .postBlob(
        `${this.apiPath}/learners/${learnerId}/documents/drivingLicenseApplication`,
        {}
      )
      .pipe(
        tap((blob: Blob) =>
          importedSaveAs(
            blob,
            `Führerscheinantrag-${this.getPrintableDate(new Date())}.pdf`
          )
        ),
        catchError((err) => {
          this.notificationService.loadError();
          return of(err);
        })
      );
  }

  private getPrintableDate(date: Date) {
    let dateDay = date.getDate().toString();
    if (dateDay.length == 1) dateDay = '0' + dateDay;
    let dateMonth = (date.getMonth() + 1).toString();
    if (dateMonth.length == 1) dateMonth = '0' + dateMonth;
    const dateYear = date.getFullYear();
    const dateComplete = dateDay + '.' + dateMonth + '.' + dateYear;

    return dateComplete;
  }

  createLearnerDocument(
    learnerID: number,
    fileType: string,
    fileName: string,
    base64Content: string,
    licenseID?: number,
    learnerLicenseID?: number
  ): Observable<void> {
    const body = {
      fileType: fileType,
      fileName: fileName,
      base64Content: base64Content,
      licenseId: licenseID,
      learnerLicenseId: learnerLicenseID,
    };
    return this.api.postFile(
      `${this.apiPath}/learners/${learnerID}/documents`,
      body
    );
  }

  updateLearnerDocument(
    learnerId: number,
    docId: number,
    fileType: string,
    fileName: string,
    base64Content: string,
    licenseID?: number,
    learnerLicenseID?: number
  ): Observable<void> {
    const body = {
      fileType: fileType,
      fileName: fileName,
      base64Content: base64Content,
      licenseId: licenseID,
      learnerLicenseId: learnerLicenseID,
    };
    return this.api.patchFile(
      `${this.apiPath}/learners/${learnerId}/documents/${docId}`,
      body
    );
  }

  downloadLearnerDocument(
    learnerID: number,
    documentID: number,
    licenseId?: number
  ): Observable<Blob> {
    const params = new Map<string, string>();
    if (licenseId) {
      params.set('licenseId', licenseId.toString());
    }
    return this.api.getBlob(
      `${this.apiPath}/learners/${learnerID}/documents/${documentID}/content`,
      params
    );
  }

  downloadLearnerDocuments(
    learnerId: number,
    fileName: string
  ): Observable<Blob> {
    const params = new Map<string, string>();
    if (learnerId) {
      params.set('learnerId', learnerId.toString());
    }
    if (fileName && fileName !== '') {
      params.set('fileName', fileName);
    }
    return this.api
      .getBlob(`${this.apiPath}/learner-documents-content`, params)
      .pipe(
        tap((blob: Blob) =>
          importedSaveAs(blob, fileName ? fileName : 'dokumente.zip')
        ),
        catchError((err) => {
          this.notificationService.loadError();
          return of(err);
        })
      );
  }

  getLearnerDocuments(
    id: number,
    licenseId?: number,
    learnerLicenseId?: number,
    schoolLicensePricingId?: number
  ): Observable<LearnerDocument[]> {
    const params = new Map<string, string>();
    if (licenseId) {
      params.set('licenseId', licenseId.toString());
    }
    if (learnerLicenseId) {
      params.set('learnerLicenseId', learnerLicenseId.toString());
    }
    if (schoolLicensePricingId) {
      params.set('schoolLicensePricingId', schoolLicensePricingId.toString());
    }
    return this.api
      .get(`${this.apiPath}/learners/${id}/documents`, params)
      .pipe(map((documents) => this.mapLearnerDocuments(documents)));
  }

  private mapLearnerDocuments(documents) {
    return documents.map((it) => new LearnerDocument(it));
  }

  sendTrainingDocumentsToLearner(learnerId: number): Observable<void> {
    return this.api.post(
      `${this.apiPath}/learners/${learnerId}/send-training-documents`,
      {}
    );
  }

  getPotentialChiefInstructors(learnerId: number) {
    return this.api
      .get(`${this.apiPath}/learners/${learnerId}/potential_chief_instructors`)
      .pipe(map((it) => this.mapInstructors(it)));
  }

  private mapInstructors(list: TeamMember[]): TeamMember[] {
    return list.map((it) => new TeamMember(it));
  }

  getLearnerBasicFee(learnerId: number): Observable<BasicFee> {
    return this.api
      .get(`${this.apiPath}/learners/${learnerId}/basic_fee`)
      .pipe(map((it) => new BasicFee(it)));
  }

  isBasicFeePaid(learnerKey: string): Observable<boolean> {
    return this.api
      .get(`${env.paymentRootLink}/basicfees/${learnerKey}/isPaid`)
      .pipe(map((it) => !!it));
  }

  getLearnerBasicFees(learnerId: number): Observable<BasicFee[]> {
    return this.api
      .get(`${this.apiPath}/learners/${learnerId}/basic_fees`)
      .pipe(map((it) => this.mapBasicFees(it)));
  }

  private mapBasicFees(list: BasicFee[]): BasicFee[] {
    return list.map((it) => new BasicFee(it));
  }

  areBasicFeesPaid(learnerKey: string): Observable<boolean> {
    return this.api
      .get(`${env.paymentRootLink}/learners/${learnerKey}/areBasicFeesPaid`)
      .pipe(map((it) => !!it));
  }

  setLearnerTheoryLearning(
    learnerId: number,
    isTheoryLearningActive: boolean
  ): Observable<void> {
    return this.api.post(
      `${this.apiPath}/learners/${learnerId}/set_theory_learning/${isTheoryLearningActive}`,
      {}
    );
  }

  setLearnerLicenseIsOnlineTheory(
    learnerId: number,
    licenseId: number,
    isOnlineTheoryActive: boolean
  ) {
    return this.api.post(
      `${this.apiPath}/learners/${learnerId}/licenses/${licenseId}`,
      { isOnlineTheoryActive }
    );
  }

  getLearnerIsHarmonization(id: number): Observable<boolean> {
    return this.api.get(`${this.apiPath}/learners/${id}/is_harmonization`).pipe(
      take(1),
      map((it) => !!it)
    );
  }

  saveLearner(learner: Learner): Observable<Learner> {
    return this.api
      .post(`${this.apiPath}/learners`, learner)
      .pipe(map((it) => new Learner(it)));
  }

  updateLearner(learner: Learner): Observable<Learner> {
    return this.api
      .put(`${this.apiPath}/learners`, learner)
      .pipe(map((it) => new Learner(it)));
  }

  delete(learnerId: number): Observable<any> {
    return this.api.delete(`${this.apiPath}/learners/${learnerId}`);
  }

  getPostalCodeInfo(postalCode: string) {
    return this.api
      .get(`${this.apiPath}/postal_code_info/${postalCode}`)
      .pipe(map((it) => new PostalCodeInfo(it)));
  }

  updateLearnerLessonsPreference(
    req: LearnerLessonsPreference
  ): Observable<LearnerLessonsPreference> {
    return this.api.post<LearnerLessonsPreference>(
      `${env.apiSchoolService}/learners/${req.learnerId}/learnerLessonsPreference`,
      req
    );
  }

  notifyLearnerForOnlineTheoryAvailability(
    learnerId: number,
    email: string
  ): Observable<void> {
    return this.api.post(
      `${env.apiSchoolService}/learners/${learnerId}/notify_for_online_theory_availability`,
      { email }
    );
  }

  getMobileAppPaymentStatus(
    learnerId: number
  ): Observable<MobileAppPaymentStatus> {
    return this.api.get<MobileAppPaymentStatus>(
      `${env.apiNoVersion}/learners/${learnerId}/mobile_app_payment_status`
    );
  }

  // learner credits
  addLearnerCredits(
    learnerId: number,
    req: AddLearnerCreditRequest
  ): Observable<LearnerCredit> {
    return this.api
      .post<LearnerCredit>(
        `${env.apiSchoolService}/learners/${learnerId}/credits`,
        req
      )
      .pipe(map((it) => new LearnerCredit(it)));
  }

  sendLearnerCreditAddedOnlineEmail(
    learnerCreditKey: string
  ): Observable<void> {
    return this.api.post<void>(
      `${env.apiSchoolService}/learner_credits/${learnerCreditKey}/send_credit_added_email`,
      {}
    );
  }

  // seen
  markSeen(learnerId: number, type: LearnerSeenType): Observable<void> {
    return this.api.post<void>(
      `${env.apiSchoolService}/learners/${learnerId}/mark_seen`,
      { type }
    );
  }
}
