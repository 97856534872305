export class LearnerExistingLicenseInfo {
  id: number;
  learnerId: number;

  existingLicenseKey: string[];
  dateOfIssue: Date;
  endOfProbationaryPeriod: Date;
  exhibitionAuthority?: string;
  licenseNumber?: string;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.learnerId = +json.learnerId;

    this.existingLicenseKey = json.existingLicenseKey;
    this.dateOfIssue = new Date(json.dateOfIssue);
    this.endOfProbationaryPeriod = new Date(json.endOfProbationaryPeriod);
    if (json.exhibitionAuthority) {
      this.exhibitionAuthority = json.exhibitionAuthority;
    }
    if (json.licenseNumber) {
      this.licenseNumber = json.licenseNumber;
    }
  }

  areMandatoryFieldsFilled(): boolean {
    return !!this.existingLicenseKey && !!this.dateOfIssue && !!this.endOfProbationaryPeriod;
  }
}
