import { Component, HostBinding, Input } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'eddy-dev-marker',
  templateUrl: './dev-marker.component.html',
  styleUrls: ['./dev-marker.component.scss']
})
export class DevMarkerComponent {

  @Input() label = '???';
  @HostBinding('class.c-hidden') private hidden: boolean;

  constructor() {
    this.hidden = environment.environmentName === 'live';
  }

}
