import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { VCalendarEvent } from '@models/v-calendar-event.model';
import { CalendarEvent } from '@models/calendar-event.model';
import { RecurringEventRequest } from '@models/recurring-event-request.model';
import { Time, toString as timeToString } from '@models/time.model';
import { convertTimeRangesToStrings } from '@models/time-range.model';
import { AddPracticeLessonRequest } from '@models/add-practice-lesson-request.model';
import { DateUtils } from './date-utils.service';
import { SaveBlockTimeRequest } from '@models/save-block-time.model';
import { UpdatePracticeLessonRequest } from '@models/accept-practice-lesson-request.model';
import { CancelPracticeLessonRequest } from '@models/cancel-practice-lesson-request.model';
import { UpdateBlockTimeRequest } from '@models/update-block-time.model';
import { DeleteBlockTimeRequest } from '@models/delete-block-time.model';
import { PracticeLessonRating } from '@models/practice-lesson-rating.model';
import { EddyClubPracticeLessonRating } from '@models/practice-lesson-rating-eddyclub.model';
import { StandardizedPracticeTopic } from '@models/standardized-practice-topic.model';
import { PracticeLesson } from '@models/practice-lesson.model';
import { MovePastPracticeLessonDateRequest } from '@models/move-past-practice-lesson-date-request.model';
import { AddExamRequest } from '@models/add-exam-request.model';
import { PageListingResponse } from '@models/page-listing-response.model';
import { ExamsStats } from '@models/exams-stats.model';
import { LessonType } from '@models/lesson-type.model';
import { ExamEventsStats } from '@models/exam-events-stats.model';
import { AddExamEventRequest } from '@models/add-exam-event-request.model';
import { ExamStatus } from '@models/exam-status.model';
import { UpdateExamRequest } from '@models/update-exam-request.model';

export const eventInPastError =
  'bad request - cannot schedule an event that is in the past';

@Injectable()
export class CalendarService {
  static readonly MAX_ALLOWED_LESSON_LENGTH_IN_MINUTES = 360;

  private readonly basePath = `${env.apiSchoolService}`;

  constructor(private api: ApiService) {}

  getTeamMemberCalendar(
    teamMemberId: number,
    startDate: Date,
    endDate: Date,
    includeTheoryEvents: boolean,
    includeBlockTimeEvents: boolean,
    practiceRequestsOnly: boolean,
    examsOnly: boolean,
    practiceExamsOnly: boolean,
    theoryExamsOnly: boolean,
    searchTerm?: string,
    bookedOnly?: boolean,
    licenseId?: number
  ): Observable<VCalendarEvent[]> {
    const filters: Map<string, string> = new Map()
      .set('startDate', this.dateToString(startDate))
      .set('endDate', this.dateToString(endDate))
      .set('includeTheoryEvents', includeTheoryEvents)
      .set('includeBlockTimeEvents', includeBlockTimeEvents)
      .set('practiceRequestsOnly', practiceRequestsOnly)
      .set('examsOnly', examsOnly)
      .set('practiceExamsOnly', practiceExamsOnly)
      .set('theoryExamsOnly', theoryExamsOnly)
      .set('bookedOnly', bookedOnly)
      .set('mapTheoryExams', true);

    if (searchTerm && searchTerm !== '') {
      filters.set('searchTerm', searchTerm);
    }

    if (licenseId) {
      filters.set('licenseId', licenseId.toString());
    }

    return this.api
      .get<VCalendarEvent[]>(
        `${this.basePath}/team_members/${teamMemberId}/calendar`,
        filters
      )
      .pipe(map((res) => this.mapVCalendarEvents(res)));
  }

  getSchoolCalendar(
    startDate: Date,
    endDate: Date,
    includeTheoryEvents: boolean,
    includeBlockTimeEvents: boolean,
    practiceRequestsOnly: boolean,
    examsOnly: boolean,
    practiceExamsOnly: boolean,
    theoryExamsOnly: boolean,
    practiceEventsOnly: boolean,
    theoryEventsOnly: boolean,
    bookedOnly: boolean,
    searchTerm?: string,
    licenseId?: number,
    teamMemberId?: number,
    schoolId?: number
  ): Observable<VCalendarEvent[]> {
    const filters: Map<string, string> = new Map()
      .set('startDate', this.dateToString(startDate))
      .set('endDate', this.dateToString(endDate))
      .set('includeTheoryEvents', includeTheoryEvents)
      .set('includeBlockTimeEvents', includeBlockTimeEvents)
      .set('practiceRequestsOnly', practiceRequestsOnly)
      .set('examsOnly', examsOnly)
      .set('practiceExamsOnly', practiceExamsOnly)
      .set('theoryExamsOnly', theoryExamsOnly)
      .set('practiceEventsOnly', practiceEventsOnly)
      .set('theoryEventsOnly', theoryEventsOnly)
      .set('bookedOnly', bookedOnly)
      .set('mapTheoryExams', true);

    if (teamMemberId) {
      filters.set('teamMemberId', teamMemberId.toString());
    } else if (schoolId) {
      filters.set('schoolId', schoolId.toString());
    }

    if (searchTerm && searchTerm !== '') {
      filters.set('searchTerm', searchTerm);
    }

    if (licenseId) {
      filters.set('licenseId', licenseId.toString());
    }

    return this.api
      .get<VCalendarEvent[]>(`${this.basePath}/school_calendar`, filters)
      .pipe(map((res) => this.mapVCalendarEvents(res)));
  }

  getSchoolCalendarPaged(
    startDate: Date,
    endDate: Date,
    includeTheoryEvents: boolean,
    includeBlockTimeEvents: boolean,
    practiceRequestsOnly: boolean,
    examsOnly: boolean,
    practiceExamsOnly: boolean,
    theoryExamsOnly: boolean,
    practiceEventsOnly: boolean,
    theoryEventsOnly: boolean,
    bookedOnly: boolean,
    mapTheoryExams: boolean,
    searchTerm?: string,
    licenseId?: number,
    examStatus?: ExamStatus,
    teamMemberId?: number,
    schoolId?: number,
    pageNumber?: number,
    pageSize?: number,
    sortProperty?: string,
    sortDirection?: string
  ): Observable<PageListingResponse<VCalendarEvent>> {
    const filters: Map<string, string> = new Map()
      .set('startDate', this.dateToString(startDate))
      .set('endDate', this.dateToString(endDate))
      .set('includeTheoryEvents', includeTheoryEvents)
      .set('includeBlockTimeEvents', includeBlockTimeEvents)
      .set('practiceRequestsOnly', practiceRequestsOnly)
      .set('examsOnly', examsOnly)
      .set('practiceExamsOnly', practiceExamsOnly)
      .set('theoryExamsOnly', theoryExamsOnly)
      .set('practiceEventsOnly', practiceEventsOnly)
      .set('theoryEventsOnly', theoryEventsOnly)
      .set('bookedOnly', bookedOnly)
      .set('mapTheoryExams', mapTheoryExams);

    if (teamMemberId) {
      filters.set('teamMemberId', teamMemberId.toString());
    } else if (schoolId) {
      filters.set('schoolId', schoolId.toString());
    }

    if (searchTerm && searchTerm !== '') {
      filters.set('searchTerm', searchTerm);
    }

    if (licenseId) {
      filters.set('licenseId', licenseId.toString());
    }

    if (examStatus) {
      filters.set('examStatus', examStatus);
    }

    if (sortProperty) {
      filters.set('sortProperty', sortProperty);
      if (sortDirection) {
        filters.set('sortDirection', sortDirection);
      }
    }

    return this.api
      .getPaged<PageListingResponse<VCalendarEvent>>(
        `${this.basePath}/school_calendar/paged`,
        pageNumber,
        pageSize,
        filters
      )
      .pipe(map((res) => this.mapVCalendarEventsPaged(res)));
  }

  getExamEventsPaged(
    schoolId: number,
    practiceExamsOnly: boolean,
    theoryExamsOnly: boolean,
    bookedOnly: boolean,
    mapTheoryExams: boolean,
    eventId?: number,
    teamMemberId?: number,
    learnerId?: number,
    licenseId?: number,
    startDate?: Date,
    endDate?: Date,
    searchTerm?: string,
    examStatus?: ExamStatus,
    sortProperty?: string,
    sortDirection?: string,
    pageNumber?: number,
    pageSize?: number
  ): Observable<PageListingResponse<VCalendarEvent>> {
    const filters: Map<string, string> = new Map()
      .set('schoolId', schoolId)

      .set('practiceExamsOnly', practiceExamsOnly)
      .set('theoryExamsOnly', theoryExamsOnly)
      .set('bookedOnly', bookedOnly)
      .set('mapTheoryExams', mapTheoryExams);

    if (searchTerm && searchTerm !== '') {
      filters.set('searchTerm', searchTerm);
    }

    if (eventId) {
      filters.set('eventId', eventId.toString());
    }

    if (teamMemberId) {
      filters.set('teamMemberId', teamMemberId.toString());
    }

    if (learnerId) {
      filters.set('learnerId', learnerId.toString());
    }

    if (licenseId) {
      filters.set('licenseId', licenseId.toString());
    }

    if (startDate) {
      filters.set('startDate', this.dateToString(startDate));
    }

    if (endDate) {
      filters.set('endDate', this.dateToString(endDate));
    }

    if (examStatus) {
      filters.set('examStatus', examStatus);
    }

    if (sortProperty) {
      filters.set('sortProperty', sortProperty);
      if (sortDirection) {
        filters.set('sortDirection', sortDirection);
      }
    }

    return this.api
      .getPaged<PageListingResponse<VCalendarEvent>>(
        `${this.basePath}/exam_events`,
        pageNumber,
        pageSize,
        filters
      )
      .pipe(map((res) => this.mapVCalendarEventsPaged(res)));
  }

  searchTeamMemberCalendar(
    teamMemberId: number,
    searchTerm: string,
    includeTheoryEvents: boolean
  ): Observable<VCalendarEvent[]> {
    const filters: Map<string, string> = new Map()
      .set('searchTerm', searchTerm)
      .set('includeTheoryEvents', includeTheoryEvents);

    return this.api
      .get<VCalendarEvent[]>(
        `${this.basePath}/team_members/${teamMemberId}/calendar/search`,
        filters
      )
      .pipe(map((res) => this.mapVCalendarEvents(res)));
  }

  getSchoolTheoryCalendar(
    schoolId: number,
    connectedSchoolId: number,
    startDate: Date,
    endDate: Date
  ): Observable<VCalendarEvent[]> {
    const filters: Map<string, string> = new Map()
      .set('connectedSchoolId', connectedSchoolId)
      .set('startDate', this.dateToString(startDate))
      .set('endDate', this.dateToString(endDate));

    return this.api
      .get<VCalendarEvent[]>(
        `${this.basePath}/schools/${schoolId}/calendar_theory_events`,
        filters
      )
      .pipe(map((res) => this.mapVCalendarEvents(res)));
  }

  private mapVCalendarEvents(res: VCalendarEvent[]): VCalendarEvent[] {
    if (!res) {
      return [];
    }
    return res.map((it) => new VCalendarEvent(it));
  }

  private mapVCalendarEventsPaged(
    res: PageListingResponse
  ): PageListingResponse<VCalendarEvent> {
    return {
      total: res.total,
      records: res.records.map((it) => new VCalendarEvent(it)),
    };
  }

  saveRecurringEvent(
    recurringEventRequest: RecurringEventRequest
  ): Observable<CalendarEvent[]> {
    const req: RecurringEventRequest = JSON.parse(
      JSON.stringify(recurringEventRequest)
    );

    req.startDate = DateUtils.dateOnlyToString(
      <Date>recurringEventRequest.startDate
    );
    req.endDate = DateUtils.dateOnlyToString(
      <Date>recurringEventRequest.endDate
    );
    req.timeRanges = convertTimeRangesToStrings(req.timeRanges);
    req.utcOffsetInMinutes = (<Date>(
      recurringEventRequest.startDate
    )).getTimezoneOffset();

    return this.api
      .post<CalendarEvent[]>(`${this.basePath}/calendar_recurring_events`, req)
      .pipe(map((res) => this.mapCalendarEvents(res)));
  }

  private mapCalendarEvents(res: CalendarEvent[]): CalendarEvent[] {
    return res.map((it) => new CalendarEvent(it));
  }

  private dateToString(date: Date): string {
    if (!date) {
      return '';
    }

    return date.toISOString();
  }

  acceptPracticeLesson(
    practiceLessonId: number,
    req: UpdatePracticeLessonRequest
  ): Observable<void> {
    return this.api.post(
      `${this.basePath}/practice_lessons/${practiceLessonId}/accept`,
      req
    );
  }

  rejectPracticeLesson(practiceLessonId: number): Observable<void> {
    return this.api.post(
      `${this.basePath}/practice_lessons/${practiceLessonId}/reject`,
      {}
    );
  }

  deletePracticeLesson(practiceLessonId: number): Observable<void> {
    return this.api.delete(
      `${this.basePath}/practice_lessons/${practiceLessonId}`
    );
  }

  cancelPracticeLesson(
    practiceLessonId: number,
    req: CancelPracticeLessonRequest
  ): Observable<void> {
    return this.api.post(
      `${this.basePath}/practice_lessons/${practiceLessonId}/cancel`,
      req
    );
  }

  setPracticeLessonNotOccurred(
    practiceLessonId: number,
    req: CancelPracticeLessonRequest
  ): Observable<void> {
    return this.api.post(
      `${this.basePath}/practice_lessons/${practiceLessonId}/set_not_occurred`,
      req
    );
  }

  revertPracticeLessonNotOccurred(practiceLessonId: number): Observable<void> {
    return this.api.post(
      `${this.basePath}/practice_lessons/${practiceLessonId}/revert_not_occurred`,
      {}
    );
  }

  savePracticeLessons(
    request: AddPracticeLessonRequest
  ): Observable<PracticeLesson[]> {
    const req: AddPracticeLessonRequest = JSON.parse(JSON.stringify(request));

    if (req.newEventDate && req.newEventTimeslot) {
      req.newEventDate = DateUtils.dateOnlyToString(<Date>request.newEventDate);
      req.newEventTimeslot.startTime = timeToString(
        <Time>request.newEventTimeslot.startTime,
        true
      );
      req.newEventTimeslot.endTime = timeToString(
        <Time>request.newEventTimeslot.endTime,
        true
      );
      req.utcOffsetInMinutes = (<Date>request.newEventDate).getTimezoneOffset();
    }

    return this.api.post(`${this.basePath}/practice_lessons`, req);
  }

  saveExam(request: AddExamRequest): Observable<PracticeLesson[]> {
    const req: AddExamRequest = JSON.parse(JSON.stringify(request));

    if (req.newEventDate && req.newEventTimeslot) {
      req.newEventDate = DateUtils.dateOnlyToString(<Date>request.newEventDate);
      req.newEventTimeslot.startTime = timeToString(
        <Time>request.newEventTimeslot.startTime,
        true
      );
      req.newEventTimeslot.endTime = timeToString(
        <Time>request.newEventTimeslot.endTime,
        true
      );
      req.utcOffsetInMinutes = (<Date>request.newEventDate).getTimezoneOffset();
    }

    return this.api.post(`${this.basePath}/exams`, req);
  }

  updateExam(
    practiceLessonId: number,
    updateRequest: UpdateExamRequest
  ): Observable<any> {
    const request = JSON.parse(JSON.stringify(updateRequest));

    if (request.newEventDate) {
      request.newEventDate = DateUtils.dateOnlyToString(
        updateRequest.newEventDate
      );
      request.newEventTimeslot = {
        startTime: timeToString(
          <Time>updateRequest.newEventTimeslot.startTime,
          true
        ),
        endTime: timeToString(
          <Time>updateRequest.newEventTimeslot.endTime,
          true
        ),
      };
    }

    return this.api.put(`${this.basePath}/exams/${practiceLessonId}`, request);
  }

  saveExamEvent(request: AddExamEventRequest): Observable<VCalendarEvent> {
    const req: AddExamEventRequest = JSON.parse(JSON.stringify(request));

    if (req.newEventDate && req.newEventTimeslot) {
      req.newEventDate = DateUtils.dateOnlyToString(<Date>request.newEventDate);
      req.newEventTimeslot.startTime = timeToString(
        <Time>request.newEventTimeslot.startTime,
        true
      );
      req.newEventTimeslot.endTime = timeToString(
        <Time>request.newEventTimeslot.endTime,
        true
      );
      req.utcOffsetInMinutes = (<Date>request.newEventDate).getTimezoneOffset();
    }

    return this.api
      .post(`${this.basePath}/exam_events`, req)
      .pipe(map((it) => new VCalendarEvent(it)));
  }

  updateExamEvent(
    eventId: number,
    request: AddExamEventRequest
  ): Observable<VCalendarEvent> {
    const req: AddExamEventRequest = JSON.parse(JSON.stringify(request));

    if (req.newEventDate && req.newEventTimeslot) {
      req.newEventDate = DateUtils.dateOnlyToString(<Date>request.newEventDate);
      req.newEventTimeslot.startTime = timeToString(
        <Time>request.newEventTimeslot.startTime,
        true
      );
      req.newEventTimeslot.endTime = timeToString(
        <Time>request.newEventTimeslot.endTime,
        true
      );
      req.utcOffsetInMinutes = (<Date>request.newEventDate).getTimezoneOffset();
    }

    return this.api.put(`${this.basePath}/exam_events/${eventId}`, req);
  }

  deleteExamEvent(eventId: number): Observable<void> {
    return this.api.delete(`${this.basePath}/exam_events/${eventId}`);
  }

  archiveExamEvent(eventId: number): Observable<void> {
    return this.api.post(`${this.basePath}/exam_events/${eventId}/archive`, {});
  }

  saveBlockTime(request: SaveBlockTimeRequest): Observable<any> {
    return this.api.post(`${this.basePath}/calendar_block_times`, request);
  }

  updateBlockTime(request: UpdateBlockTimeRequest): Observable<any> {
    return this.api.put(
      `${this.basePath}/calendar_block_times/${request.eventId}`,
      request
    );
  }

  deleteBlockTime(request: DeleteBlockTimeRequest): Observable<any> {
    return this.api.delete(
      `${this.basePath}/calendar_block_times/${request.eventId}`
    );
  }

  deleteCalendarEvent(calendarEventId: number): Observable<void> {
    return this.api.delete(
      `${this.basePath}/calendar_events/${calendarEventId}`
    );
  }

  updatePracticeLesson(
    practiceLessonId: number,
    updateRequest: UpdatePracticeLessonRequest
  ): Observable<any> {
    const request = JSON.parse(JSON.stringify(updateRequest));

    if (request.newEventDate) {
      request.newEventDate = DateUtils.dateOnlyToString(
        updateRequest.newEventDate
      );
      request.newEventTimeslot = {
        startTime: timeToString(
          <Time>updateRequest.newEventTimeslot.startTime,
          true
        ),
        endTime: timeToString(
          <Time>updateRequest.newEventTimeslot.endTime,
          true
        ),
      };
    }

    return this.api.put(
      `${this.basePath}/practice_lessons/${practiceLessonId}`,
      request
    );
  }

  addRating(
    practiceLessonId: number,
    eventId: number,
    request: PracticeLessonRating | EddyClubPracticeLessonRating
  ): Observable<any> {
    return this.api
      .post(
        `${this.basePath}/practice_lessons/${practiceLessonId}/rating`,
        request
      )
      .pipe(take(1));
  }

  movePastPracticeLessonDate(
    practiceLessonId: number,
    moveDateRequest: MovePastPracticeLessonDateRequest
  ): Observable<any> {
    const request = JSON.parse(JSON.stringify(moveDateRequest));

    if (request.newEventDate) {
      request.newEventDate = DateUtils.dateOnlyToString(
        moveDateRequest.newEventDate
      );
      request.newEventTimeslot = {
        startTime: timeToString(
          <Time>moveDateRequest.newEventTimeslot.startTime,
          true
        ),
        endTime: timeToString(
          <Time>moveDateRequest.newEventTimeslot.endTime,
          true
        ),
      };
    }

    return this.api.post(
      `${this.basePath}/practice_lessons/${practiceLessonId}/move_past_date`,
      request
    );
  }

  getAllStandardizedPracticeTopics(): Observable<StandardizedPracticeTopic[]> {
    return this.api
      .get<StandardizedPracticeTopic[]>(
        `${this.basePath}/standardized_practice_topics`
      )
      .pipe(map((res) => this.mapStandardizedPracticeTopics(res)));
  }

  private mapStandardizedPracticeTopics(
    res: StandardizedPracticeTopic[]
  ): StandardizedPracticeTopic[] {
    return res.map((it) => new StandardizedPracticeTopic(it));
  }

  getExamsStats(
    type: LessonType,
    schoolId?: number,
    teamMemberId?: number
  ): Observable<ExamsStats> {
    const params = new Map<string, string>();

    params.set('type', type);

    if (schoolId) {
      params.set('schoolId', '' + schoolId);
    }
    if (teamMemberId) {
      params.set('teamMemberId', '' + teamMemberId);
    }

    return this.api.get<ExamsStats>(`${this.basePath}/exams_stats`, params);
  }

  getDateRangeExamsStats(params: Map<string, string>): Observable<ExamsStats> {
    return this.api.get<ExamsStats>(
      `${this.basePath}/date_range_exams_stats`,
      params
    );
  }

  getExamEventsStats(
    schoolId?: number,
    teamMemberId?: number
  ): Observable<ExamEventsStats> {
    const params = new Map<string, string>();

    if (schoolId) {
      params.set('schoolId', '' + schoolId);
    }
    if (teamMemberId) {
      params.set('teamMemberId', '' + teamMemberId);
    }

    return this.api.get<ExamEventsStats>(
      `${this.basePath}/exam_events_stats`,
      params
    );
  }
}
