<div class="dialog-container" [class.full-height-dialog]="step > 1">
  <mat-dialog-content>
    <span (click)="onQuit()" class="close-button">
      <i class="fal fa-times"></i>
    </span>

    <div class="steps" *ngIf="!preselectedLicenseKey">
      <div
        class="step"
        [class.active]="step == 1 || step == 2 || step == 3"
      ></div>
      <div class="step" [class.active]="step == 2 || step == 3"></div>
      <div class="step last" [class.active]="step == 3"></div>
    </div>

    <form novalidate [formGroup]="form">
      <div [class.display-none]="step != 1">
        <div id="main-content">
          <div id="description">
            Jetzt kostenlos zum Online Theorieunterricht anmelden!
          </div>
          <p class="input-label">Vorname</p>
          <input
            id="name"
            type="text"
            placeholder="Vorname"
            class="form-control"
            formControlName="name"
          />

          <p class="input-label">Nachname</p>
          <input
            id="lastName"
            type="text"
            placeholder="Nachname"
            class="form-control"
            formControlName="lastName"
          />

          <p class="input-label">E-mail</p>
          <input
            id="email"
            type="email"
            placeholder="E-Mail"
            class="form-control"
            formControlName="email"
          />

          <p class="input-label">Passwort</p>
          <div class="password-container">
            <input
              id="password"
              type="{{showPassword ? 'text' : 'password'}}"
              placeholder="Passwort"
              class="form-control"
              formControlName="password"
            />
            <div (click)="onToggleShowPassword()" class="eye-container">
              <i
                class="fal"
                [class.fa-eye]="showPassword"
                [class.fa-eye-slash]="!showPassword"
              ></i>
            </div>
          </div>

          <p class="input-label">Passwort wiederholen</p>
          <div class="password-container">
            <input
              id="confirm-password"
              type="{{showConfirmPassword ? 'text' : 'password'}}"
              placeholder="Passwort wiederholen"
              class="form-control"
              formControlName="confirmPassword"
            />
            <div (click)="onToggleShowConfirmPassword()" class="eye-container">
              <i
                class="fal"
                [class.fa-eye]="showConfirmPassword"
                [class.fa-eye-slash]="!showConfirmPassword"
              ></i>
            </div>
          </div>

          <div *ngIf="!equalPasswords" class="password-not-equal">
            Passwörter stimmen nicht überein!
          </div>
        </div>
        <div class="action-btn-cont-desktop">
          <div class="register-btn-container">
            <button
              [type]="step != 1 ? 'button' : 'submit'"
              class="form-btn"
              id="confirm-button"
              (click)="onEnterDetails()"
            >
              Jetzt kostenlos registrieren
            </button>
          </div>
          <div class="login-btn-container">
            <a class="login-btn" [routerLink]="loginPageUrl" (click)="onQuit()"
              >Login mit drivEddy Account</a
            >
          </div>
          <hr class="line" />
          <p class="agb">
            Durch deine Anmeldung akzeptierst du die drivEddy GmbH
            <a [routerLink]="learnerTermsAndConditionsPage" target="_blank" (click)="onQuit()"
              >AGB’s</a
            >
            und
            <a [routerLink]="dataPrivacyWebPage" target="_blank" (click)="onQuit()"
              >Datenschutzrichtlinie</a
            >.
          </p>
        </div>
      </div>
      <div [class.display-none]="step != 2">
        <div class="back-btn" (click)="goBack()">
          <i class="fal fa-arrow-left"></i>
        </div>
        <p class="welcome-title">Wähle deinen Führerschein</p>
        <div *ngIf="licenses" class="license-options">
          <div
            *ngFor="let license of licenses"
            class="license-option"
            [ngClass]="{'selected' : isLicenseSelected(license.id), 'less-padding': hasBiggerImage(license.key), 'a-specific-padding': license.key === 'A (nur klassensp. Kurse)'}"
            (click)="selectLicense(license)"
          >
            <img class="option-img" [src]="getImagePath(license.key)" />
            <div class="option-cont">
              <div class="option-lbl">
                <span class="lbl-bold">Klasse {{ license.key }}</span
                >{{license.includedLicenseKeys.length > 0 ? '(' +
                license.includedLicenseKeys + ')' : ''}}
              </div>
              <div class="option">
                <i
                  *ngIf="isLicenseSelected(license.id)"
                  class="fal fa-check"
                ></i>
              </div>
            </div>
          </div>
        </div>

        <div class="register-btn-container action-btn-cont-desktop">
          <button type="button" class="form-btn" (click)="onEnterLicense()">
            Weiter
          </button>
        </div>
      </div>
      <div [class.display-none]="step != 3" class="main-container-second">
        <div class="back-btn" (click)="goBack()">
          <i class="fal fa-arrow-left"></i>
        </div>
        <p class="welcome-title">Willkommen {{learner?.firstName}},</p>
        <div
          class="in-progress-container"
          *ngIf="signUpRequestInProgress || loginInProgress; else step3Content"
        >
          <div class="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <ng-template #step3Content>
          <p class="welcome-text">
            Wir freuen uns, dass du mit an Board bist und deinen
            Theorie-Pflichtunterricht online absolvieren möchtest. Um die
            lokalen Führerscheingegebenheiten berücksichtigen zu können, gebe
            bitte deine Postleitzahl an.
          </p>
          <p class="zip-code">Wo wohnst du in Deutschland?</p>
          <input
            #postalCode
            id="zip-code"
            type="text"
            placeholder="Deine Postleitzahl"
            class="form-control"
            formControlName="postalCode"
          />
        </ng-template>

        <div class="action-btn-cont-desktop">
          <button
            [type]="step != 3 ? 'button' : 'submit'"
            class="form-btn"
            (click)="onSignUp()"
          >
            Weiter
          </button>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>
<div class="floating-register-btn-container">
  <div *ngIf="step === 1">
    <button
      [type]="step != 1 ? 'button' : 'submit'"
      class="form-btn"
      id="confirm-button"
      (click)="onEnterDetails()"
    >
      Jetzt kostenlos registrieren
    </button>
    <p class="agb">
      Durch deine Anmeldung akzeptierst du die drivEddy GmbH
      <a [routerLink]="learnerTermsAndConditionsPage" target="_blank" (click)="onQuit()"
        >AGB’s</a
      >
      und
      <a [routerLink]="dataPrivacyWebPage" target="_blank" (click)="onQuit()"
        >Datenschutzrichtlinie</a
      >.
    </p>
  </div>
  <button
    *ngIf="step === 2"
    type="button"
    class="form-btn form-btn-mobile"
    (click)="onEnterLicense()"
  >
    Weiter
  </button>
  <button
    *ngIf="step === 3"
    [type]="step != 3 ? 'button' : 'submit'"
    class="form-btn form-btn-mobile"
    (click)="onSignUp()"
  >
    Weiter
  </button>
</div>
