export class TheoryLessonAddress {
  id: number;

  theoryLessonId: number;

  streetNumber: string;
  street: string;
  postalCode: string;
  locality: string;
  country: string;

  constructor(json?: any) {
    if (!json) {
      this.country = 'Deutschland';
      return;
    }

    if (json.id) {
      this.id = +json.id;
    }

    if (json.theoryLessonId) {
      this.theoryLessonId = +json.theoryLessonId;
    }

    this.streetNumber = json.streetNumber;
    this.street = json.street;
    this.postalCode = json.postalCode;
    this.locality = json.locality;
    this.country = json.country;
  }

  isPartiallyFilled(): boolean {
    return !!this.street || !!this.streetNumber || !!this.postalCode || !!this.locality;
  }

  isFullyFilled(): boolean {
    return !!this.street && !!this.streetNumber && !!this.postalCode && !!this.locality;
  }
}
