import { LeadRating } from './lead-rating.model';
import { Offer } from './offer.model';

export class Lead {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;

  offerId?: number;
  offer?: Offer;

  name: string;
  email: string;
  phone: string;
  status: LeadStatus;

  type: LeadType;

  street?: string;
  streetNumber?: string;
  postalCode: string;
  locality?: string;

  birthday?: Date;
  hasOwnCarOrPlanningToBuy?: boolean;
  learningLicenses?: string;

  rating?: LeadRating;
  contactedDate?: Date;

  firstName?: string;
  lastName?: string;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.createdAt = new Date(json.createdAt);
    this.updatedAt = new Date(json.updatedAt);

    if (json.offerId) {
      this.offerId = +json.offerId;
    }
    if (json.offer) {
      this.offer = new Offer(json.offer);
    }

    this.name = json.name;
    this.email = json.email;
    this.phone = json.phone;
    this.status = json.status;

    this.type = json.type;

    this.street = json.street;
    this.streetNumber = json.streetNumber;
    this.postalCode = json.postalCode;
    this.locality = json.locality;

    if (json.birthday) {
      this.birthday = new Date(json.birthday);
    }
    if (json.hasOwnCarOrPlanningToBuy !== undefined) {
      this.hasOwnCarOrPlanningToBuy = !!json.hasOwnCarOrPlanningToBuy;
    }
    this.learningLicenses = json.learningLicenses;

    if (json.rating) {
      this.rating = json.rating;
    }
    if (json.contactedDate) {
      this.contactedDate = new Date(json.contactedDate);
    }

    if (json.firstName) {
      this.firstName = json.firstName;
    }
    if (json.lastName) {
      this.lastName = json.lastName;
    }
  }
}

export enum LeadStatus {
  NEW = 'NEW',
  CONTACTED = 'CONTACTED',
  ARCHIVED = 'ARCHIVED',
}

export enum LeadType {
  ARAG = 'ARAG',
  ALLIANZ = 'ALLIANZ',
  LINK = 'LINK',
}
