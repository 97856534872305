export enum USER_ROLE {
  PLATFORM = 'PLATFORM',
  INSTRUCTOR = 'INSTRUCTOR',
  SECRETARY = 'SECRETARY',
  PRINCIPAL = 'PRINCIPAL',
  DISTRIBUTION = 'DISTRIBUTION',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  MANAGEMENT = 'MANAGEMENT',
  STUDENT = 'STUDENT',
  EDDY_SUPPORT = 'EDDY_SUPPORT'
}

export function getTeamMemberUserRoles(): USER_ROLE[] {
  return [USER_ROLE.INSTRUCTOR, USER_ROLE.PRINCIPAL, USER_ROLE.SECRETARY, 
    USER_ROLE.DISTRIBUTION, USER_ROLE.CUSTOMER_SERVICE, USER_ROLE.MANAGEMENT, USER_ROLE.EDDY_SUPPORT];
}

export function getDrivingInstructorsUserRoles(): USER_ROLE[] {
  return [USER_ROLE.INSTRUCTOR, USER_ROLE.PRINCIPAL, USER_ROLE.MANAGEMENT];
}

export function isTeamMemberUserRole(userRole: USER_ROLE): boolean {
  return getTeamMemberUserRoles().indexOf(userRole) !== -1;
}

export function isDrivingInstructorUserRole(userRole: USER_ROLE): boolean {
  return getDrivingInstructorsUserRoles().indexOf(userRole) !== -1;
}

export function isLearner(userRole: USER_ROLE): boolean {
  return userRole === USER_ROLE.STUDENT;
}

export function getTeamMemberUserRolesForAccounting(): USER_ROLE[] {
  return [USER_ROLE.PRINCIPAL, USER_ROLE.SECRETARY, USER_ROLE.MANAGEMENT, USER_ROLE.EDDY_SUPPORT];
}

export function getUserRolesForUsersList(): USER_ROLE[] {
  return [USER_ROLE.PLATFORM, USER_ROLE.STUDENT, USER_ROLE.INSTRUCTOR];
}

export class User {
  id: number;

  first_name: string;
  last_name: string;

  email: string;
  phone: string;

  gender: string;
  doi: boolean;
  role: USER_ROLE;

  createdAt?: Date;
  updatedAt?: Date;

  constructor(json?: any) {
    this.id = +json.id;
    this.first_name = json.first_name;
    this.last_name = json.last_name;
    this.email = json.email;
    this.phone = json.phone;
    this.gender = json.gender;
    this.doi = !!json.doi;
    this.role = json.role;

    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }
    if (json.updatedAt) {
      this.updatedAt = new Date(json.updatedAt);
    }
  }

  get fullName(): string {
    return `${this.first_name} ${this.last_name}`;
  }
}
