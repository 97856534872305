import { catchError, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { asapScheduler, BehaviorSubject, Observable, scheduled } from 'rxjs';
import { environment as env } from '@environments/environment';
import {
  LearnerHasPendingLicensePriceList,
  License,
  LicensePriceList,
  LicensePriceListsSetting,
  LicensePriceListsSettingType,
  PlatformSchoolLicensePricing,
  SchoolLicensePricing,
  UpdateLicensePriceListsSettingsRequest,
} from '@models/license.model';
import { ApiService } from '@services/api.service';
import { LearnerLicense } from '@models/learner.model';

@Injectable()
export class LicenseService {
  private fetchingLicensesInProgress = false;
  private allLicenses = new BehaviorSubject<License[] | null>(null);

  private fetchingStandardPriceListLicensesInProgress = false;
  private standardPriceListLicenses = new BehaviorSubject<License[] | null>(
    null
  );

  constructor(private api: ApiService) {}

  getAllLicenses(): Observable<License[]> {
    if (this.allLicenses.getValue() === null) {
      this.fetchLicenses();
    }
    return this.allLicenses.asObservable().pipe(
      filter((value: any) => value !== null),
      distinctUntilChanged()
    );
  }

  private fetchLicenses() {
    if (this.fetchingLicensesInProgress) {
      return;
    }
    this.fetchingLicensesInProgress = true;

    this.api
      .get<License[]>(`${env.apiSchoolService}/licenses`)
      .pipe(
        map((it: License[]) => this.mapLicenses(it)),
        catchError(() => {
          this.fetchingLicensesInProgress = false;
          return scheduled([], asapScheduler);
        })
      )
      .toPromise()
      .then((value) => {
        this.fetchingLicensesInProgress = false;
        this.allLicenses.next(value);
      });
  }

  getStandardPriceListLicenses(): Observable<License[]> {
    if (this.standardPriceListLicenses.getValue() === null) {
      this.fetchStandardPriceListLicenses();
    }
    return this.standardPriceListLicenses.asObservable().pipe(
      filter((value: any) => value !== null),
      distinctUntilChanged()
    );
  }

  private fetchStandardPriceListLicenses() {
    if (this.fetchingStandardPriceListLicensesInProgress) {
      return;
    }
    this.fetchingStandardPriceListLicensesInProgress = true;

    this.api
      .get<License[]>(`${env.apiSchoolService}/standardPriceListLicenses`)
      .pipe(
        map((it: License[]) => this.mapLicenses(it)),
        catchError(() => {
          this.fetchingStandardPriceListLicensesInProgress = false;
          return scheduled([], asapScheduler);
        })
      )
      .toPromise()
      .then((value) => {
        this.fetchingStandardPriceListLicensesInProgress = false;
        this.standardPriceListLicenses.next(value);
      });
  }

  getLicenseById(licenseId: number) {
    return this.api
      .get<License>(`${env.apiSchoolService}/licenses/${licenseId}`)
      .pipe(map((it: License) => new License(it)));
  }

  private mapLicenses(licenses: License[]) {
    return licenses.map((it: License) => new License(it));
  }

  getSchoolProfileLicenses(schoolId: number): Observable<License[]> {
    return this.getSchoolLicensePricings(schoolId, true).pipe(
      map((it: SchoolLicensePricing[]) => this.mapLicensePricingsToLicenses(it))
    );
  }

  private mapLicensePricingsToLicenses(
    pricings: SchoolLicensePricing[]
  ): License[] {
    return pricings.map((it: SchoolLicensePricing) => it.license);
  }

  getAllSchoolLicensePricings(
    schoolId: number
  ): Observable<SchoolLicensePricing[]> {
    return this.getSchoolLicensePricings(schoolId, false);
  }

  private getSchoolLicensePricings(
    schoolId: number,
    onlySchoolProfileLicenses: boolean
  ): Observable<SchoolLicensePricing[]> {
    return this.api
      .get<SchoolLicensePricing[]>(
        `${env.apiSchoolService}/schools/${schoolId}/license_pricings?onlySchoolProfileLicenses=${onlySchoolProfileLicenses}`
      )
      .pipe(map((it: any) => this.mapLicensePricings(it)));
  }

  mapLicensePricings(list: SchoolLicensePricing[]): any {
    return list.map((it: SchoolLicensePricing) => new SchoolLicensePricing(it));
  }

  getSchoolLicensePricing(
    schoolId: number,
    licenseId: number
  ): Observable<SchoolLicensePricing> {
    return this.api
      .get<SchoolLicensePricing>(
        `${env.apiSchoolService}/schools/${schoolId}/licenses/${licenseId}/pricing`
      )
      .pipe(map((it: any) => new SchoolLicensePricing(it)));
  }

  saveSchoolLicensePricing(pricing: SchoolLicensePricing) {
    const url = `${env.apiSchoolService}/schools/${pricing.schoolId}/licenses/${pricing.license.id}/pricing`;
    return this.api
      .post<SchoolLicensePricing>(url, pricing)
      .pipe(map((it: any) => new SchoolLicensePricing(it)));
  }

  getSchoolLicensePriceLists(
    schoolId: number,
    filters?: Map<string, string>
  ): Observable<LicensePriceList[]> {
    return this.api
      .get<SchoolLicensePricing[]>(
        `${env.apiSchoolService}/schools/${schoolId}/priceLists`,
        filters
      )
      .pipe(map((it: any) => this.mapLicensePriceLists(it)));
  }

  mapLicensePriceLists(list: LicensePriceList[]): any {
    return list.map((it: LicensePriceList) => new LicensePriceList(it));
  }

  getLicensePriceList(
    id: number,
    currentOnly = true
  ): Observable<LicensePriceList> {
    const params = new Map<string, string>();
    params.set('currentOnly', `${currentOnly}`);

    return this.api
      .get<LicensePriceList>(
        `${env.apiSchoolService}/licensePriceList/${id}`,
        params
      )
      .pipe(map((it: any) => new LicensePriceList(it)));
  }

  saveLicensePriceList(priceList: LicensePriceList) {
    return this.api
      .post<LicensePriceList>(
        `${env.apiSchoolService}/licensePriceList`,
        priceList
      )
      .pipe(map((it: any) => new LicensePriceList(it)));
  }

  deleteLicensePriceList(priceListId: number): Observable<void> {
    return this.api.delete<void>(
      `${env.apiSchoolService}/licensePriceList/${priceListId}`
    );
  }

  learnerHasPendingLicensePriceList(learnerId: number) {
    return this.api.get<LearnerHasPendingLicensePriceList>(
      `${env.apiSchoolService}/learners/${learnerId}/hasPendingLicensePriceList`
    );
  }

  getLearnerPendingLicensePriceList(
    schoolLearnerId: number
  ): Observable<LicensePriceList> {
    return this.api
      .get<LicensePriceList>(
        `${env.apiSchoolService}/school_learners/${schoolLearnerId}/pendingLicensePriceList`
      )
      .pipe(map((it: any) => new LicensePriceList(it)));
  }

  changeLearnerLicensePriceList(
    schoolLearnerId: number,
    priceListId: number,
    silentUpdate: boolean,
  ) {
    return this.api
      .post<LicensePriceList>(
        `${env.apiSchoolService}/school_learners/${schoolLearnerId}/changeLicensePriceList/${priceListId}`,
        {},
        new Map<string, string>([['silentUpdate', `${silentUpdate}`]]),
      )
      .pipe(map((it: any) => new LicensePriceList(it)));
  }

  editLearnerLicensePriceListPricing(
    schoolLearnerId: number,
    pricing: SchoolLicensePricing,
    silentUpdate: boolean,
  ): Observable<SchoolLicensePricing[]> {
    return this.api
      .post<SchoolLicensePricing[]>(
        `${env.apiSchoolService}/school_learners/${schoolLearnerId}/licensePriceList/${pricing.licensePriceListId}/license/${pricing.licenseId}`,
        pricing,
        new Map<string, string>([['silentUpdate', `${silentUpdate}`]]),
      )
      .pipe(map((it: any) => this.mapLicensePricings(it)));
  }

  revertLearnerLicensePriceListChanges(schoolLearnerId: number) {
    return this.api
      .post<LicensePriceList>(
        `${env.apiSchoolService}/school_learners/${schoolLearnerId}/revertLicensePriceListChanges`,
        {}
      )
      .pipe(map((it: any) => new LicensePriceList(it)));
  }

  acceptLearnerLicensePriceListChanges(priceListId: number) {
    return this.api
      .post<LicensePriceList>(
        `${env.apiSchoolService}/licensePriceList/${priceListId}/acceptChanges`,
        {}
      )
      .pipe(map((it: any) => new LicensePriceList(it)));
  }

  rejectLearnerLicensePriceListChanges(priceListId: number) {
    return this.api
      .post<LicensePriceList>(
        `${env.apiSchoolService}/licensePriceList/${priceListId}/rejectChanges`,
        {}
      )
      .pipe(map((it: any) => new LicensePriceList(it)));
  }

  saveLicensePriceListPricing(
    pricing: SchoolLicensePricing
  ): Observable<SchoolLicensePricing[]> {
    return this.api
      .post<SchoolLicensePricing[]>(
        `${env.apiSchoolService}/licensePriceList/${pricing.licensePriceListId}/license/${pricing.licenseId}`,
        pricing
      )
      .pipe(map((it: any) => this.mapLicensePricings(it)));
  }

  deleteLicensePriceListPricing(
    priceListId: number,
    pricingId: number
  ): Observable<void> {
    return this.api.delete<void>(
      `${env.apiSchoolService}/licensePriceList/${priceListId}/pricing/${pricingId}`
    );
  }

  getSchoolLicensePriceListsSettings(
    schoolId: number,
    type?: LicensePriceListsSettingType
  ): Observable<LicensePriceListsSetting[]> {
    const params = new Map<string, string>();

    if (type) {
      params.set('type', type);
    }

    return this.api
      .get<LicensePriceListsSetting[]>(
        `${env.apiSchoolService}/schools/${schoolId}/priceListsSettings`,
        params
      )
      .pipe(map((it: any) => this.mapLicensePriceListsSettings(it)));
  }

  updateSchoolLicensePriceListsSettings(
    schoolId: number,
    req: UpdateLicensePriceListsSettingsRequest
  ) {
    const url = `${env.apiSchoolService}/schools/${schoolId}/priceListsSettings`;
    return this.api
      .post<LicensePriceListsSetting[]>(url, req)
      .pipe(map((it: any) => this.mapLicensePriceListsSettings(it)));
  }

  mapLicensePriceListsSettings(list: LicensePriceListsSetting[]): any {
    return list.map(
      (it: LicensePriceListsSetting) => new LicensePriceListsSetting(it)
    );
  }

  // Platform school license pricing
  updatePlatformSchoolLicensePricing(pricings: PlatformSchoolLicensePricing[]) {
    const url = `${env.apiSchoolService}/schools/platformSchoolLicensePricing`;
    return this.api
      .post<SchoolLicensePricing[]>(url, pricings)
      .pipe(map((it: any) => this.mapPlatformLicensePricings(it)));
  }

  getPlatformSchoolLicensePricings(
    schoolId: number
  ): Observable<PlatformSchoolLicensePricing[]> {
    return this.api
      .get<PlatformSchoolLicensePricing[]>(
        `${env.apiSchoolService}/schools/${schoolId}/platformSchoolLicensePricing`
      )
      .pipe(map((it: any) => this.mapPlatformLicensePricings(it)));
  }

  getPlatformSchoolLicensePricing(
    schoolId: number,
    licenseId: number
  ): Observable<PlatformSchoolLicensePricing> {
    return this.api
      .get<PlatformSchoolLicensePricing>(
        `${env.apiSchoolService}/schools/${schoolId}/platformSchoolLicensePricing/${licenseId}`
      )
      .pipe(map((it) => new PlatformSchoolLicensePricing(it)));
  }

  mapPlatformLicensePricings(list: PlatformSchoolLicensePricing[]): any {
    return list.map(
      (it: PlatformSchoolLicensePricing) => new PlatformSchoolLicensePricing(it)
    );
  }

  saveLearnerLicense(
    learnerLicense: LearnerLicense
  ): Observable<LearnerLicense> {
    return this.api.post<LearnerLicense>(
      `${env.apiSchoolService}/learner_licenses`,
      learnerLicense
    );
  }

  acceptLearnerLicense(learnerLicenseKey: string): Observable<void> {
    return this.api.post<void>(
      `${env.apiSchoolService}/learner_licenses/${learnerLicenseKey}/accept`,
      {}
    );
  }

  deleteLearnerLicense(learnerLicenseId: number): Observable<void> {
    return this.api.delete<void>(
      `${env.apiSchoolService}/learner_licenses/${learnerLicenseId}`
    );
  }
}
