import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthTeamMemberPositionGuard implements CanActivateChild {

  constructor(private authService: AuthService) {
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!route.data.positions) {
        return true;
      }

    const user = this.authService.getUser();

    this.authService.destinationUrl = state.url;
    if (!user || (user && !this.authService.userHasAnyRole(route.data.positions, user.role))) {
      this.authService.logout(true);
      return false;
    }

    return true;
  }
}