<div class="input-cont">
  <div *ngIf="label" class="form-label">
    {{ label }}{{ required ? '*' : '' }}
  </div>
  <mat-form-field appearance="outline">
    <input
      matInput
      [matDatepicker]="picker"
      readonly
      (click)="picker.open()"
      [min]="min"
      [max]="max"
      [formControlName]="controlName"
      [placeholder]="placeholder"
      [disabled]="disabled"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker touchUi></mat-datepicker>
  </mat-form-field>
</div>
