<div *ngFor="let language of availableLanguages" class="select-lang-dialog">
<div class="choosle-lang-dialog-cont row">
    <div class="flag-img-dialog-cont">
      <img src="assets/{{language.icon}}" class="thumbnail" height="50">
      <span class="language-name">{{language.name}}</span>
    </div>
    <div class="checkbox-flag-container">
        <mat-slide-toggle  [checked]="isSupported(language.id)"  (change)="onSupportChange(language)"></mat-slide-toggle>
        <!-- <mat-checkbox checked]="isSupported(language.id)"  (change)="onSupportChange(language)">{{language.name}}</mat-checkbox> -->
    </div>
</div>
</div>
<button type="button" class="btn btn-primary save-btn save-lang-btn" (click)="onClose()">Speichern</button>
