import { AfterViewInit, Component, Inject, OnInit, ViewChild,ElementRef, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Learner, LearnerLicense } from '@models/learner.model';
import * as moment from 'moment';
import { VideoPlayerComponent } from '@modules/shared/components/video-player/video-player.component';

import SignaturePad from 'signature_pad';
import { saveAs as importedSaveAs } from 'file-saver';
import { finalize, takeUntil } from 'rxjs/operators';
import { NotificationService } from '@services/notification.service';
import { Subject } from 'rxjs';
import { LearnerDocumentType } from '@models/learner-document-type.model';


@Component({
  selector: 'eddy-sign-contract-dialog',
  templateUrl: './sign-contract-dialog.component.html',
  styleUrls: ['./sign-contract-dialog.component.scss']
})

export class SignContractDialogComponent implements OnInit, AfterViewInit {
	readonly generationError = 'Leider gab es einen Fehler beim Generieren des Vertrags. Bitte versuche es erneut';
	public signaturePadLearner: SignaturePad;
	public signaturePadGuardian: SignaturePad;
	@ViewChild('canvasSignatureLearnerContainer', {static: true}) public canvasContainerLearner: ElementRef;
	@ViewChild('canvasSingatureLearner', {static: true }) public canvasLearner: ElementRef;
	@ViewChild('canvasSignatureGuardianContainer', {static: true}) public canvasContainerGuardian: ElementRef;
	@ViewChild('canvasSingatureGuardian', {static: true }) public canvasGuardian: ElementRef;
	@ViewChild('previewContainer', {static: true }) public previewContainer: ElementRef;
	@ViewChild('videoPlayer', {static: true }) public videoPlayer: VideoPlayerComponent;
	
	stepNumber = 0;
	learner: Learner;
	contractTimestamp: Date;
	generatingContract = true;

	contractType: LearnerDocumentType;
	learnerLicenseId: number;
	schoolLicensePricingId: number;
	schoolName: string;

	private previewContract: Blob = null;
	private signedContract: Blob = null;

	/** Subject that emits when the component has been destroyed. */
	private _onDestroy = new Subject<void>();

	constructor(
		private notificationService: NotificationService,
		private dialogRef: MatDialogRef<SignContractDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any
	) {
		this.learner = this.data.learner;
		this.contractType = this.data.contractType;
		this.learnerLicenseId = this.data.learnerLicenseId;
		this.schoolLicensePricingId = this.data.schoolLicensePricingId;
		this.schoolName = this.data.schoolName;
	}

	ngOnInit() {
		this.data.previewContract
			.pipe(
				takeUntil(this._onDestroy),
				finalize(() => this.generatingContract = false)
			)
			.subscribe((resultingPdf: Blob) => {
				this.previewContract = resultingPdf;
			},
			() => {
				this.notificationService.error(this.generationError);
				this.closeDialog(null);
			});
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.videoPlayer.play();
		}, 1500);
	}

	async contractSigned() {
		// will be notified of szimek/signature_pad's onEnd event
		this.generatingContract = true;
		if (this.signaturePadLearner.isEmpty()) {
			this.generatingContract = false;
			return false;
		}
		const signatureLearner = this.signaturePadLearner.toDataURL();
		let signatureGuardian = null;
		if(!this.learner.isOver18()) {
			if (this.signaturePadGuardian.isEmpty()) {
				this.generatingContract = false;
				return false;
			}
			signatureGuardian = this.signaturePadGuardian.toDataURL();
		}
			

		const result = await this.data.callbackOnFinish({
			contractType: this.contractType,
			learnerLicenseId: this.learnerLicenseId,
			schoolLicensePricingId: this.schoolLicensePricingId,
			signatureLearner: signatureLearner,
			signatureGuardian: signatureGuardian
		});
		this.generatingContract = false;

		if(result === false) {
			this.nextStep();
		} else {
			this.contractTimestamp = new Date();
			this.signedContract = result;
			this.nextStep(3);
		}
		return true;
	}

	get submitContractDisabled(): boolean {
		return this.generatingContract || (this.signaturePadLearner && this.signaturePadLearner.isEmpty()) || (!this.learner.isOver18() && this.signaturePadGuardian && this.signaturePadGuardian.isEmpty());
	}

	drawEnd() {
	}
	
	clearSignatureLearner() {
		if (!this.signaturePadLearner) return;
		this.signaturePadLearner.clear();
	}
	
	clearSignatureGuardian() {
		if (!this.signaturePadGuardian) return;
		this.signaturePadGuardian.clear();
	}

	get contractDate(): string {
		return moment(this.contractTimestamp).format('L')+', '+moment(this.contractTimestamp).format('LT')+' Uhr';
	}

	nextStep(step?) {
		if(step !== undefined) {
			this.stepNumber = step;
		} else {
			this.stepNumber++;
		}

		if(this.stepNumber === 0) {
			this.videoPlayer.play(0);
		} else {
			this.videoPlayer.pause();
		}

		const signaturePadOptions = {
			onEnd: this.drawEnd.bind(this)
		};

		if(this.stepNumber == 1) {
			try {
				this.previewContainer.nativeElement.src = window.URL.createObjectURL(this.previewContract);
				
				// calculate canvas width
				const popupWidth = window.innerWidth / 100 * 90;
				const popupContentWidth = popupWidth - 48;

				setTimeout(() => {
					const signaturePadMaxHeight = 100;
					this.canvasLearner.nativeElement.width = 0;

					const targetWidth = this.canvasContainerLearner.nativeElement.offsetWidth;
					let targetHeight = this.canvasContainerLearner.nativeElement.offsetHeight;
					targetHeight = Math.min(signaturePadMaxHeight, targetHeight);

					this.canvasLearner.nativeElement.width = targetWidth;
					this.canvasLearner.nativeElement.height = targetHeight;
					this.signaturePadLearner = new SignaturePad(this.canvasLearner.nativeElement, signaturePadOptions);
	
					if(!this.learner.isOver18()) {
						this.canvasGuardian.nativeElement.width = 0;

						const guardianTargetWidth = this.canvasContainerGuardian.nativeElement.offsetWidth;
						let guardianTargetHeight = this.canvasContainerGuardian.nativeElement.offsetHeight;
						guardianTargetHeight = Math.min(signaturePadMaxHeight, guardianTargetHeight);
	
						this.canvasGuardian.nativeElement.width = guardianTargetWidth;
						this.canvasGuardian.nativeElement.height = guardianTargetHeight;
						this.signaturePadGuardian = new SignaturePad(this.canvasGuardian.nativeElement, signaturePadOptions);
					}
				}, 500);
			} catch (err) {
				console.error(err);
			}
		}
	}

	closeDialog(val: any) {
		this._onDestroy.next();
    	this._onDestroy.complete();
		this.dialogRef.close(val);
	}

	downloadContract() {
		if(this.signedContract === null) return false;
		if (this.contractType === LearnerDocumentType.SCHOOL_CONTRACT) {
			importedSaveAs(this.signedContract, `Fahrschulvertrag-${this.getPrintableDate(new Date())}.pdf`);
		} else {
			importedSaveAs(this.signedContract, `Ausbildungsvertrag-${this.getPrintableDate(new Date())}.pdf`);
		}
	}

	private getPrintableDate(date: Date) {
		let dateDay = date.getDate().toString();
		if (dateDay.length == 1) dateDay = '0' + dateDay;
		let dateMonth = (date.getMonth() + 1).toString();
		if (dateMonth.length == 1) dateMonth = '0' + dateMonth;
		const dateYear = date.getFullYear();
		const dateComplete = dateDay + '.' + dateMonth + '.' + dateYear;
	
		return dateComplete;
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.handleSignatureCanvasSize(); //handle the resizing of signature pad canvas on resizing screen
	}

	handleSignatureCanvasSize() {
		setTimeout(() => {
			const signaturePadMaxHeight = 100;
			const signaturePadMaxWidth = 485;

			this.canvasLearner.nativeElement.width = 0;

			let targetWidth = this.canvasContainerLearner.nativeElement.offsetWidth;
			targetWidth = Math.min(signaturePadMaxWidth, targetWidth);
			let targetHeight = this.canvasContainerLearner.nativeElement.offsetHeight;
			targetHeight = Math.min(signaturePadMaxHeight, targetHeight);

			this.canvasLearner.nativeElement.width = targetWidth;
			this.canvasLearner.nativeElement.height = targetHeight;

			this.clearSignatureLearner();

			if(!this.learner.isOver18()) {
				this.canvasGuardian.nativeElement.width = 0;

				let guardianTargetWidth = this.canvasContainerGuardian.nativeElement.offsetWidth;
				guardianTargetWidth = Math.min(signaturePadMaxWidth, guardianTargetWidth);
				let guardianTargetHeight = this.canvasContainerGuardian.nativeElement.offsetHeight;
				guardianTargetHeight = Math.min(signaturePadMaxHeight, guardianTargetHeight);

				this.canvasGuardian.nativeElement.width = guardianTargetWidth;
				this.canvasGuardian.nativeElement.height = guardianTargetHeight;
				
				this.clearSignatureGuardian();
			}
		}, 0);
	}
}
