import { Component } from '@angular/core';

@Component({
  selector: 'eddy-known-from',
  templateUrl: './known-from.component.html',
  styleUrls: ['./known-from.component.scss']
})
export class KnownFromComponent {

}
