import { NUMBER_OF_STANDARDIZED_LESSONS } from '@models/practice-lesson.model';

export class StandardizedPracticeTopicContentItem {
  id: number;
  contentId: number;
  orderNumber: number;
  title: string;

  constructor(json) {
    this.id = +json.id;
    this.contentId = +json.contentId;
    this.orderNumber = +json.orderNumber;
    this.title = json.title;
  }
}

export class StandardizedPracticeTopicContent {
  id: number;
  topicId: number;
  orderNumber: number;
  title: string;
  items: StandardizedPracticeTopicContentItem[];

  constructor(json) {
    this.id = +json.id;
    this.topicId = +json.topicId;
    this.orderNumber = +json.orderNumber;
    this.title = json.title;
    this.items = this.mapItems(json.items);
  }

  private mapItems(items: StandardizedPracticeTopicContentItem[]) {
    if (!items) {
      return [];
    }
    return items.map(it => new StandardizedPracticeTopicContentItem(it));
  }
}

export enum StandardizedPracticeTopicType {
  BASIC_TRAINING = 'BASIC_TRAINING',
  SPECIAL_LESSON = 'SPECIAL_LESSON',
  EXAM_READINESS = 'EXAM_READINESS',
}

export function getTopicTypeDisplayName(type: StandardizedPracticeTopicType): string {
  switch (type) {
    case StandardizedPracticeTopicType.BASIC_TRAINING:
      return 'Grundausbildung';
    case StandardizedPracticeTopicType.SPECIAL_LESSON:
      return 'Sonderfahrten';
    case StandardizedPracticeTopicType.EXAM_READINESS:
      return 'Prüfungsreife';
    default:
      return '';
  }
}

export enum SpecialLessonType {
  OVERLAND = 'OVERLAND',
  HIGHWAY = 'HIGHWAY',
  NIGHT_DRIVE = 'NIGHT_DRIVE',
}

const overlandSpecialLessonLengthInMinutes = 225;
const highwaySpecialLessonLengthInMinutes = 180;
const nightDriveSpecialLessonLengthInMinutes = 135;

const practiceExamPreparationSpecialLessonLengthInMinutes = 90;
const practiceExamSpecialLessonLengthInMinutes = 55;
export const practiceExamStandardizedPracticeTopicId = 33;

export function getSpecialLessonTypeLengthInMinutes(lessonType: SpecialLessonType): number {
  switch (lessonType) {
    case SpecialLessonType.OVERLAND:
      return overlandSpecialLessonLengthInMinutes;
    case SpecialLessonType.HIGHWAY:
      return highwaySpecialLessonLengthInMinutes;
    case SpecialLessonType.NIGHT_DRIVE:
      return nightDriveSpecialLessonLengthInMinutes;
    default:
    return 0;
  }
}

export function getSpecialLessonTypeLengthInMinutesByTopicId(standardizedPracticeTopicId: number): number {
  switch (standardizedPracticeTopicId) {
    case 27:
      return overlandSpecialLessonLengthInMinutes;
    case 28:
      return highwaySpecialLessonLengthInMinutes;
    case 29:
      return nightDriveSpecialLessonLengthInMinutes;
    case 30:
    case 31:
    case 33:
      return practiceExamSpecialLessonLengthInMinutes;
    default:
      return 0;
  }
}

export function getPracticeExamPreparationSpecialLessonLengthInMinutes(): number {
  return practiceExamPreparationSpecialLessonLengthInMinutes;
}

export function getPracticeExamSpecialLessonLengthInMinutes(): number {
  return practiceExamSpecialLessonLengthInMinutes;
}

export const specialLessonIdTypeMap = new Map([
  [27, SpecialLessonType.OVERLAND],
  [28, SpecialLessonType.HIGHWAY],
  [29, SpecialLessonType.NIGHT_DRIVE],
]);

export function getAllSpecialLessonStandardizedPracticeTopicIds(): number[] {
  return [27, 28, 29];
}

export class StandardizedPracticeLevel {
  id: number;
  createdAt: Date;
  updatedAt: Date;

  title: string;
  description: string;

  constructor(json) {
    this.id = +json.id;

    this.createdAt = new Date(json.createdAt);
    this.updatedAt = new Date(json.updatedAt);

    this.title = json.title;
    this.description = json.description;
  }
}

export class StandardizedPracticeTopic {
  id: number;
  title: string;
  type: StandardizedPracticeTopicType;

  levelId: number;
  level?: StandardizedPracticeLevel;

  contents: StandardizedPracticeTopicContent[];

  constructor(json) {
    this.id = +json.id;
    this.title = json.title;
    this.type = json.type;

    this.levelId = +json.levelId;
    if (json.level) {
      this.level = new StandardizedPracticeLevel(json.level);
    }

    this.contents = this.mapContents(json.contents);
  }

  private mapContents(contents: StandardizedPracticeTopicContent[]) {
    if (!contents) {
      return [];
    }
    return contents.map(it => new StandardizedPracticeTopicContent(it));
  }

  getDisplayName(): string {
    return `${this.id}/${NUMBER_OF_STANDARDIZED_LESSONS}: ${this.title}`;
  }
}
