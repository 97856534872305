export class TheoryQuestion {
  id: number;

  title: string;
  subtitle: string;
  caption: string;
  annex: string;
  code: string;
  solution: string;
  asset: string;
  option0: string;
  option1: string;
  option2: string;

  a: boolean;
  a1: boolean;
  a2: boolean;
  am: boolean;
  mofa: boolean;
  b: boolean;
  c: boolean;
  ce: boolean;
  c1: boolean;
  d: boolean;
  d1: boolean;
  l: boolean;
  t: boolean;
  g: boolean;

  points: number;
  license: string;
  flagged: boolean;
  isDeleted: boolean;
  explanation: string;

  failRatio?: number;
  correctAnswers?: number;
  failedAnswers?: number;
  totalAnswers?: number;

  constructor(json: any) {
    this.id = +json.id;

    this.title = json.title;
    this.subtitle = json.subtitle;
    this.caption = json.caption;
    this.annex = json.annex;
    this.code = json.code;
    this.solution = json.solution;
    this.asset = json.asset;
    this.option0 = json.option0;
    this.option1 = json.option1;
    this.option2 = json.option2;

    this.a = !!json.a;
    this.a1 = !!json.a1;
    this.a2 = !!json.a2;
    this.am = !!json.am;
    this.mofa = !!json.mofa;
    this.b = !!json.b;
    this.c = !!json.c;
    this.ce = !!json.ce;
    this.c1 = !!json.c1;
    this.d = !!json.d;
    this.d1 = !!json.d1;
    this.l = !!json.l;
    this.t = !!json.t;
    this.g = !!json.g;

    this.points = +json.points;
    this.license = json.license;
    this.flagged = !!json.flagged;
    this.isDeleted = !!json.isDeleted;
    this.explanation = json.explanation;

    if (json.failRatio || json.failRatio === 0) {
      this.failRatio = +json.failRatio;
    }
    if (json.correctAnswers) {
      this.correctAnswers = +json.correctAnswers;
    }
    if (json.failedAnswers) {
      this.failedAnswers = +json.failedAnswers;
    }
    if (json.totalAnswers) {
      this.totalAnswers = +json.totalAnswers;
    }
  }

  get options(): string[] {
    const options = [];
    if (this.option0 !== '') {
      options.push(this.option0);
    }
    if (this.option1 !== '') {
      options.push(this.option1);
    }
    if (this.option2 !== '') {
      options.push(this.option2);
    }
    return options;
  }

  getShuffledOptions(): string[] {
    return this.shuffleArray(this.options);
  }

  hasAsset(): boolean {
    return this.asset && this.asset !== '';
  }

  getAssetUrl(): string {
    return `https://static.driveddy.com/theory/${this.asset}`;
  }

  private shuffleArray(array: string[]): string[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
}
