export class CarModel {
  id: number;
  name: string;
  brandId: number;
  ownerSchoolId?: number;


  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.name = json.name;
    this.brandId = +json.brandId;
    if (json.ownerSchoolId) {
      this.ownerSchoolId = +json.ownerSchoolId;
    }
  }
}
