import { Component, Input, OnDestroy } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'eddy-mat-input',
  templateUrl: './mat-input.component.html',
  styleUrls: ['./mat-input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class MatInputComponent implements OnDestroy {
  private _control: FormControl;

  @Input() label: string;

  @Input() controlName: string;

  @Input() set control(control: FormControl) {
    this._control = control;
    this.value = this.control.value;
    this.control.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => (this.value = this.control.value));
  }

  get control(): FormControl {
    return this._control;
  }

  @Input() placeholder = 'bitte eintragen';

  @Input() disabled = false;

  @Input() required = false;

  @Input() readonly = false;

  value: any;

  isFocused: boolean;

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor() {}

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  resetValue() {
    this.control.setValue('');
  }

  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    this.isFocused = false;
  }

  get hasValue(): boolean {
    return this.value && this.value !== '';
  }
}
