import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LocationService } from '@services/location.service';
import { Address } from '@models/address.model';

@Component({
  selector: 'eddy-address-edit',
  templateUrl: './address-edit.component.html',
  styleUrls: ['./address-edit.component.scss']
})
export class AddressEditComponent {
  private _address: Address;

  form: FormGroup;

  @Output() addressChange: EventEmitter<Address> = new EventEmitter();

  @ViewChild('street', { read: ElementRef }) street: ElementRef;
  @ViewChild('streetNumber', { read: ElementRef }) streetNumber: ElementRef;
  @ViewChild('postalCode', { read: ElementRef }) postalCode: ElementRef;
  @ViewChild('locality', { read: ElementRef }) locality: ElementRef;

  constructor(private locationService: LocationService) {
  }

  @Input() set address(address: Address) {
    this._address = address;
  }

  get address() {
    return this._address;
  }

  async onUpdateAddress() {
    this.address.street = this.street.nativeElement.value;
    this.address.streetNumber = this.streetNumber.nativeElement.value;
    this.address.postalCode = this.postalCode.nativeElement.value;
    this.address.locality = this.locality.nativeElement.value;

    if (!this.address.street || !this.address.streetNumber || !this.address.postalCode || !this.address.locality) {
      return;
    }

    try {
      await this.locationService.updateAddressCoordinates(this.address);
    } catch {
    }

    this.addressChange.emit(this.address);
  }
}
