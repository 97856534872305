export enum SchoolLearnerOnboardingRequestChannel {
    SCHOOL_PROFILE = 'SCHOOL_PROFILE',
    EXTERNAL_PAGE = 'EXTERNAL_PAGE',
  }
  
  export namespace SchoolLearnerOnboardingRequestChannel {
    export function getDisplayName(status: SchoolLearnerOnboardingRequestChannel): string {
      switch (status) {
        case SchoolLearnerOnboardingRequestChannel.SCHOOL_PROFILE:
          return 'Fahrschulprofil';
        case SchoolLearnerOnboardingRequestChannel.EXTERNAL_PAGE:
          return 'Website (html code)';
        default:
          return '';
      }
    }
}
