import { PaymentStatus } from './payment-status.model';
import { TheoryLessonAttendeeStatus } from './theory-lesson-attendee.model';
import { TheoryLessonTopic } from './theory-lesson-topic.model';

export class TheoryLessonVideoAttendee {
  id: number;

  createdAt?: Date;

  learnerId: number;

  topicKey: string;
  topic: TheoryLessonTopic;

  status: TheoryLessonAttendeeStatus;
  lessonWatchedDate?: Date;

  rating?: number;
  feedback?: string;

  videoCurrentTimeInSeconds?: number;

  paymentStatus?: PaymentStatus;

  constructor(json) {
    this.id = +json.id;

    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.learnerId = +json.learnerId;

    this.topicKey = json.topicKey;
    this.topic = new TheoryLessonTopic(json.topic);

    this.status = json.status;

    if (json.lessonWatchedDate) {
      this.lessonWatchedDate = new Date(json.lessonWatchedDate);
    }

    if (json.rating) {
      this.rating = +json.rating;
    }
    this.feedback = json.feedback;

    if (json.videoCurrentTimeInSeconds) {
      this.videoCurrentTimeInSeconds = +json.videoCurrentTimeInSeconds;
    }

    if (json.paymentStatus) {
      this.paymentStatus = json.paymentStatus;
    }
  }

  isRated(): boolean {
    return this.rating !== undefined;
  }

  isConfirmed(): boolean {
    return this.status === TheoryLessonAttendeeStatus.CONFIRMED;
  }

  isPresent(): boolean {
    return this.status === TheoryLessonAttendeeStatus.PRESENT;
  }

  isWatched(): boolean {
    return this.status === TheoryLessonAttendeeStatus.LESSON_WATCHED;
  }
}
