<div class="lp-background">
  <img src="/assets/header-tc.png"/>
  <div class="container">
    <div class="row no-side-space">
      <div class="col-12 col-lg-10 offset-lg-1">
        <h1 class="lp-title">Impressum</h1>

        <p class="tc-section">
          drivEddy GmbH
          <br/><br/>
          Monbijouplatz 4
          <br/>
          10178 Berlin
          <br/><br/>
          E-Mail: info@driveddy.com
          <br/><br/>
          Vertretungsberechtiger Geschäftsführer: Robin Stegemann
          <br/><br/>
          Eingetragen beim Amtsgericht Charlottenburg (Berlin): HRB 138574 B
          <br/>
          Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz: DE281288532
          <br/><br/>
          Hinweis gemäß Online-Streitbeilegungs-Verordnung
          <br/><br/>
          Nach geltendem Recht sind wir verpflichtet, Verbraucher auf die Existenz der Europäischen
          Online-Streitbeilegungs-Plattform hinzuweisen, die für die Beilegung von Streitigkeiten genutzt werden kann,
          ohne dass ein Gericht eingeschaltet werden muss. Für die Einrichtung der Plattform ist die Europäische
          Kommission zuständig. Die Europäische Online-Streitbeilegungs-Plattform ist hier zu finden:
          http://ec.europa.eu/odr. Unsere E-Mail lautet: info@driveddy.com
          <br/><br/>
          Wir weisen aber darauf hin, dass wir nicht bereit sind, uns am Streitbeilegungsverfahren im Rahmen der
          Europäischen Online-Streitbeilegungs-Plattform zu beteiligen. Nutzen Sie zur Kontaktaufnahme bitte unsere
          obige E-Mail und Telefonnummer.
          <br/><br/>
          Hinweis gemäß Verbraucherstreitbeilegungsgesetz (VSBG) Wir sind nicht bereit und verpflichtet, an
          Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
      </div>
    </div>
  </div>
</div>
