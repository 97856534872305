import { Refund } from './refund.model';

export class Credit {
    id: number;

    createdAt: Date;
    updatedAt: Date;

    schoolId: number;

    subject: string;
    date: Date;

    amountInCents: number;
    usedAmountInCents: number;
    isAutomatic: boolean;

    refund?: Refund;

    constructor(json?: any) {
        if (!json) {
          return;
        }

        this.id = +json.id;
        this.createdAt = new Date(json.createdAt);
        this.updatedAt = new Date(json.updatedAt);

        this.schoolId = +json.schoolId;

        this.subject = json.subject;
        this.date = new Date(json.date);

        this.amountInCents = +json.amountInCents;
        this.usedAmountInCents = +json.usedAmountInCents;
        this.isAutomatic = !!json.isAutomatic;

        if (json.refund) {
            this.refund = new Refund(json.refund);
        }
    }

    isAmountNegative() {
        return this.amountInCents < 0;
    }

    canBeDeleted() {
        return !this.isAutomatic && this.usedAmountInCents === 0 && !this.isRefund;
    }

    get absoluteAmountInCents() {
        return Math.abs(this.amountInCents);
    }

    get isRefund(): boolean {
        return this.refund !== undefined;
    }

    get hasRefundDocument(): boolean {
        return this.refund !== undefined && this.refund.refundDocumentId !== undefined;
    }

    get isNonSchoolLearnerRefund(): boolean {
      return this.refund && !this.refund.schoolLearnerId;
    }

    get isRefundWithOriginalInvoiceNumber(): boolean {
        return this.isRefund && this.refund.originalInvoiceNumber && this.refund.originalInvoiceNumber !== '';
    }

    get typeDisplayName(): string {
      return this.isNonSchoolLearnerRefund ? 'Gutschrift' : 'Guthaben';
    }

    get refundOriginalInvoiceNumber(): string {
        return this.refund?.originalInvoiceNumber;
    }
}
