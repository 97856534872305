import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'eddy-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.scss']
})
export class YesNoDialogComponent implements OnInit {
  title: string;
  message: string;
  messageHtml: string;
  yesButtonLabel: string;
  noButtonLabel: string;

  showNoButton = true;
  showCheck = false;

  logoPath: string;

  constructor(
    private dialogRef: MatDialogRef<YesNoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.title = this.data.title;
    this.message = this.data.message;
    this.messageHtml = this.data.messageHtml;
    this.yesButtonLabel = this.data.yesButtonLabel || 'Ja';
    this.noButtonLabel = this.data.noButtonLabel || 'Nein';

    if (this.data.showNoButton !== undefined) {
      this.showNoButton = this.data.showNoButton;
    }
    this.showCheck = this.data.showCheck;
    this.logoPath = this.data.logoPath;
  }

  ngOnInit() {
  }

  onYes() {
    this.dialogRef.close(true);
  }

  onNo() {
    this.dialogRef.close(false);
  }

}
