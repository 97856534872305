<div class="dialog-container">
  <span (click)="onQuit()" class="close-button">
      <i class="fal fa-times"></i>
    </span>

  <div class="main-content">
    <div class="title" mat-dialog-title>
      <div class="icon-circle"><span class="title-icon"><i class="fal fa-newspaper"></i></span></div>
      <span class="title-text">Wichtige Mitteilung & Neuigkeiten</span>
    </div>

    <div class="content">
      <img class="person-image" src="assets/lasse.png" width="100px" height="100px" />
      <span>
        Liebes Team der Fahrschule {{getSchoolName()}}
        <br>
        <br>
        es gibt Neugigkeiten. Wir konnten unsere Genehmigung
erneut erfolgreich schützen/verteidigen und können
unseren Service somit weiterhin für dich anbieten. :)
Nach der diesjährigen Verkehrsministerkonferenz haben
wir einen weiteren Termin.
        <br>
        <br>
Da das Ergebnis dort pro Online-Theorie ausfallen wird, sind wir
 zuversichtlich, dass wir die Genehmigung auch ins neue Jahr bringen können.
Wir halten dich darüber auf dem Laufenden.
<br>
        <br>
Ich muss dich zudem noch über die geplante Preiserhöhung für unseren Online-Theorie Service informieren. Aufgrund der steigenden Kosten rund um die rechtsgültige Genehmigung müssen wir den Preis für Klasse B (als Referenzwert, analog alle anderen Klassen) um <span class="font-bold">20%</span> anheben.
Beim Hinzufügen eines Fahrschülers wird somit der neue Preis pro Lizenz transparent ausgewiesen.
<br>
        <br>
Wir verstehen, dass dies für dich eine Belastung sein kann, aber wir hoffen auf dein Verständnis. Nur so können wir unseren Service wirtschaftlich aufrecht erhalten und dich wie gewohnt mit unserem Service unterstützen. Die Kapazitäten werden weiter erhöht werden und wir planen nun mit einem langfristigen Angebot.
<br>
        <br>
Der Preis wird wie angekündigt ab dem <span class="font-bold">14.09.2023</span> automatisch in deinem Profil hinterlegt. So hast du genügend Zeit, die Mehrkosten bspw. auf deine Fahrschüler umzulegen. Wir danken dir für deine gute Zusammenarbeit und hoffen, dass du auch weiterhin unseren Service nutzen wirst.
<br>
        <br>
Bei Fragen kannst du mich gerne jederzeit kontaktieren: +49 (0) 151 24170412.
<br>
        <br>
Viele Grüße,
        <br>
Lasse
      </span>
    </div>

    <button class="confirm-button" (click)="confirm()">
      Okay, Verstanden
    </button>
  </div>
</div>
