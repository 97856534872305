export interface TooltipOptions {
  placement: 'top' | 'right' | 'bottom' | 'left';
  trigger: 'click' | 'hover';
  offset: number;
  maxWidth?: number;
  showInfoIcon: boolean;
  whiteBackground: boolean;
  showShadow: boolean;
  destroyOnMouseLeave: boolean;
}

export const defaultOptions: TooltipOptions = {
  placement: 'top',
  trigger: 'hover',
  offset: 8,
  showInfoIcon: true,
  whiteBackground: false,
  showShadow: false,
  destroyOnMouseLeave: true,
};
