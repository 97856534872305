import { DateUtils } from '@services/date-utils.service';
import { Learner } from './learner.model';
import {
  getDisplayName,
  YoudriveBookingType,
} from './youdrive-booking-type.model';

export class YoudriveBooking {
  id: number;

  type: YoudriveBookingType;

  learnerId: number;
  learner?: Learner;

  date: Date;
  startDate?: Date;
  endDate?: Date;

  instructorName?: string;
  car?: string;
  licenseId?: number;
  licenseKey?: string;
  theoryTopicKey?: string;

  private readonly amountInCents: number;
  invoice: string;

  isExamPassed?: boolean;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;

    this.type = json.type;

    this.learnerId = +json.learnerId;
    if (json.learner) {
      this.learner = new Learner(json.learner);
    }

    this.date = new Date(json.date);
    if (json.startDate) {
      this.startDate = new Date(json.startDate);
    }
    if (json.endDate) {
      this.endDate = new Date(json.endDate);
    }

    if (json.instructorName) {
      this.instructorName = json.instructorName;
    }
    if (json.car) {
      this.car = json.car;
    }
    if (json.licenseId) {
      this.licenseId = +json.licenseId;
    }
    if (json.licenseKey) {
      this.licenseKey = json.licenseKey;
    }
    if (json.theoryTopicKey) {
      this.theoryTopicKey = json.theoryTopicKey;
    }

    this.amountInCents = +json.amountInCents;
    this.invoice = json.invoice;

    if (json.isExamPassed !== undefined) {
      this.isExamPassed = !!json.isExamPassed;
    }
  }

  getTypeDisplayName(): string {
    return getDisplayName(this.type);
  }

  getDurationInMinutes(): number {
    if (!this.startDate || !this.endDate) {
      return 0;
    }
    const diff = Math.abs(this.endDate.getTime() - this.startDate.getTime());
    return Math.floor(diff / 1000 / 60);
  }

  get fullPriceInCents(): number {
    return this.amountInCents;
  }

  getTotalPrice(): number {
    return this.fullPriceInCents / 100;
  }

  hasTimeSlot(): boolean {
    return true;
  }

  get startTime() {
    if (!this.startDate) {
      return '';
    }
    return DateUtils.getTimeString(this.startDate);
  }

  get endTime() {
    if (!this.endDate) {
      return '';
    }
    return DateUtils.getTimeString(this.endDate);
  }

  getTimeInterval(): string {
    return `${this.startTime} - ${this.endTime}`;
  }

  instructorShortenedName() {
    if (!this.instructorName) {
      return '';
    }

    return this.instructorName;
  }
}
