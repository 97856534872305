export enum MaritalStatus {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  CIVIL_PARTNERSHIP = 'CIVIL_PARTNERSHIP',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
}

export function getDisplayName(status: MaritalStatus): string {
  switch (status) {
    case MaritalStatus.SINGLE:
      return 'ledig';
    case MaritalStatus.MARRIED:
      return 'verheiratet';
    case MaritalStatus.CIVIL_PARTNERSHIP:
      return 'lebenspartnerschaft';
    case MaritalStatus.DIVORCED:
      return 'geschieden';
    case MaritalStatus.WIDOWED:
      return 'verwitwet';
    default:
      return '';
  }
}

export function getAllMaritalStatuses(): MaritalStatus[] {
  return [MaritalStatus.SINGLE, MaritalStatus.MARRIED, MaritalStatus.CIVIL_PARTNERSHIP, MaritalStatus.DIVORCED, MaritalStatus.WIDOWED];
}
