import { Component } from '@angular/core';

@Component({
  templateUrl: './thank-you-for-your-inquiry.page.html',
  styleUrls: ['./thank-you-for-your-inquiry.page.scss']
})
export class ThankYouForYourInquiryPage {
  constructor() {
  }
}
