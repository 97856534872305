import { Component, Input } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
} from '@angular/forms';

@Component({
  selector: 'eddy-mat-datepicker',
  templateUrl: './mat-datepicker.component.html',
  styleUrls: ['./mat-datepicker.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class MatDatePickerComponent {
  @Input() label: string;

  @Input() controlName: string;

  @Input() control: FormControl;

  @Input() placeholder = 'TT/MM/YYYY';

  @Input() disabled = false;

  @Input() required = false;

  @Input() min: Date;

  @Input() max: Date;

  constructor() {}

  resetValue() {
    this.control.setValue('');
  }
}
