export enum PracticeLessonTopic {
  DRIVING_LESSON = 'DRIVING_LESSON',
  HIGHWAY_DRIVING = 'HIGHWAY_DRIVING',
  NIGHT_RIDE = 'NIGHT_RIDE',
  OVERLAND_RIDE = 'OVERLAND_RIDE',
  INSTRUCTION_IN_VEHICLE = 'INSTRUCTION_IN_VEHICLE',
  DRIVE_TO_EXAM_CENTER = 'DRIVE_TO_EXAM_CENTER',
  PRACTICE_EXAM = 'PRACTICE_EXAM',
}

export function getAllPracticeLessonTopics(): PracticeLessonTopic[] {
  return [
    PracticeLessonTopic.DRIVING_LESSON,
    PracticeLessonTopic.HIGHWAY_DRIVING,
    PracticeLessonTopic.NIGHT_RIDE,
    PracticeLessonTopic.OVERLAND_RIDE,
    PracticeLessonTopic.INSTRUCTION_IN_VEHICLE,
    PracticeLessonTopic.DRIVE_TO_EXAM_CENTER,
    PracticeLessonTopic.PRACTICE_EXAM,
  ];
}

export function getPracticeLessonTopicsWithoutExam(): PracticeLessonTopic[] {
  return [
    PracticeLessonTopic.DRIVING_LESSON,
    PracticeLessonTopic.HIGHWAY_DRIVING,
    PracticeLessonTopic.NIGHT_RIDE,
    PracticeLessonTopic.OVERLAND_RIDE,
    PracticeLessonTopic.INSTRUCTION_IN_VEHICLE,
    PracticeLessonTopic.DRIVE_TO_EXAM_CENTER,
  ];
}

export function getSpecialPracticeLessonTopics(): PracticeLessonTopic[] {
  return [
    PracticeLessonTopic.HIGHWAY_DRIVING,
    PracticeLessonTopic.NIGHT_RIDE,
    PracticeLessonTopic.OVERLAND_RIDE,
  ];
}

export function getDisplayName(
  practiceLessonTopic: PracticeLessonTopic
): string {
  switch (practiceLessonTopic) {
    case PracticeLessonTopic.DRIVING_LESSON:
      return 'Übungsfahrstunde';
    case PracticeLessonTopic.HIGHWAY_DRIVING:
      return 'Autobahnfahrt';
    case PracticeLessonTopic.NIGHT_RIDE:
      return 'Nachtfahrt';
    case PracticeLessonTopic.OVERLAND_RIDE:
      return 'Überlandfahrt';
    case PracticeLessonTopic.INSTRUCTION_IN_VEHICLE:
      return 'Unterweisung am Fahrzeug';
    case PracticeLessonTopic.DRIVE_TO_EXAM_CENTER:
      return 'Fahrt zur Prüfungsstelle';
    case PracticeLessonTopic.PRACTICE_EXAM:
      return 'Praktische Prüfung';
    default:
      return '';
  }
}
