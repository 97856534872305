export class UserLocation {
  locality: string;
  postalCode: string;
  country: string;

  constructor(json) {
    this.locality = json.locality;
    this.postalCode = json.postalCode;
    this.country = json.country;
  }

  toString(): string {
    return `${this.locality} ${this.postalCode}, ${this.country}`;
  }
}
