import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Time } from '@models/time.model';

@Component({
  selector: 'eddy-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent {

  @Input() time: Time;
  @Output() timeChange: EventEmitter<Time> = new EventEmitter();

  @ViewChild('hour', { read: ElementRef }) hour: ElementRef;
  @ViewChild('minute', { read: ElementRef }) minute: ElementRef;

  onUpdateTime() {
    const hour = +this.hour.nativeElement.value;
    const minute = +this.minute.nativeElement.value;

    if (hour < 0 || hour > 23) {
      this.hour.nativeElement.value = this.time.hour;
      this.minute.nativeElement.value = this.time.minute;
      return;
    }

    if (minute < 0 || minute > 59) {
      this.hour.nativeElement.value = this.time.hour;
      this.minute.nativeElement.value = this.time.minute;
      return;
    }

    this.time = {
      hour,
      minute
    };

    this.timeChange.emit(this.time);
  }

  get timeHour() {
    if (!this.time || !this.time.hour) {
      return '';
    }

    if (this.time.hour > 9) {
      return this.time.hour;
    }

    return '0' + this.time.hour;
  }

  get timeMinute() {
    if (!this.time || this.time.minute === null || this.time.minute === undefined) {
      return '';
    }

    if (this.time.minute > 9) {
      return this.time.minute;
    }

    return '0' + this.time.minute;
  }
}
