import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddressEditComponent } from '../components/addresses/address-edit/address-edit.component';
import { OkDialogComponent } from '../dialogs/ok-dialog/ok-dialog.component';
import { SignContractDialogComponent } from '../dialogs/sign-contract-dialog/sign-contract-dialog.component';
import { YesNoDialogComponent } from '../dialogs/yes-no-dialog/yes-no-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';

import * as moment from 'moment';
import * as localization from 'moment/locale/de';

import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileUploadModule } from 'ng2-file-upload';
import { LicenseSummaryComponent } from '../components/license-summary/license-summary.component';
import { NavbarDropdownComponent } from '../components/navbar-dropdown/navbar-dropdown.component';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { AddressSearchBarComponent } from '../components/addresses/address-search-bar/address-search-bar.component';
import { AppButtonsComponent } from '../components/app-buttons/app-buttons.component';
import { ImageViewDialog } from '../dialogs/image-view-dialog/image-view.dialog';
import { BackComponent } from '../components/back/back.component';
import { RatingStarsComponent } from '../components/rating-stars/rating-stars.component';
import { SchoolLanguagesComponent } from '../components/school-languages/school-languages.component';
import { CarouselComponent } from '../components/carousel/carousel.component';
import { SlideComponent } from '../components/carousel/slide.component';
import { CarouselConfig } from '../components/carousel/carousel.config';
import { DatepickerComponent } from '../components/datepicker/datepicker.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AccordionItemComponent } from '../components/accordion-item/accordion-item.component';
import { AnalyticsModule } from '@modules/analytics/platform/analytics.module';
import { InfoCardComponent } from '../components/info-card/info-card.component';
import { FileUploaderComponent } from '../components/file-uploader/file-uploader.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AdComponent } from '../components/ad/ad.component';
import { ImageCroppingDialog } from '../dialogs/image-cropping/image-cropping.dialog';
import {
  APP_DATE_FORMATS,
  AppDateAdapter,
} from '../../../adapters/app-date.adapter';
import { SearchBoxComponent } from '../components/search-box/search-box.component';
import { TooltipModule } from '@modules/tooltip/tooltip.module';
import { PostInfoComponent } from '../components/post-info/post-info.component';
import { FileDownloaderComponent } from '../components/file-downloader/file-downloader.component';
import { GalleryCardComponent } from '../components/gallery-card/gallery-card.component';
import { ViewEditCardComponent } from '../components/view-edit-card/view-edit-card.component';
import { TimePickerComponent } from '../components/time-picker/time-picker.component';
import { DateRangePickerComponent } from '../components/date-range-picker/date-range-picker.component';
import { BookingDriverNotes } from '../components/booking-driver-notes/booking-driver-notes.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { EddyLearnerTypeDropdownComponent } from '../components/eddy-learner-type-dropdown/eddy-learner-type-dropdown.component';
import { EddyPaymentStatusDropdownComponent } from '../components/eddy-payment-status-dropdown/eddy-payment-status-dropdown.component';
import { EddyPaymentTypeDropdownComponent } from '../components/eddy-payment-type-dropdown/eddy-payment-type-dropdown.component';
import { ChooseLicenseDialog } from '../dialogs/choose-license/choose-license.dialog';
import { InfoVideoPlayerComponent } from '../components/info-video-player/info-video-player.component';
import { KnownFromComponent } from '../components/known-from/known-from.component';
import { DonutChartComponent } from '../components/donut-chart/donut-chart.component';
import { CountdownComponent } from '../components/countdown/countdown.component';
import { VideoPlayerComponent } from '../components/video-player/video-player.component';
import { AutoCompleteComponent } from '../components/geo-auto-complete/auto-complete.component';
import { SocialShareComponent } from '../components/social-share/social-share.component';
import { OnlineTheoryTestimonialsComponent } from '../components/online-theory-testimonials/online-theory-testimonials.component';
import { DevMarkerComponent } from '@modules/shared/components/dev-marker/dev-marker.component';
import { SlimSpinnerComponent } from '@modules/shared/components/slim-spinner/slim-spinner.component';
import { AddLearnerLicenseDialog } from '../dialogs/add-learner-license/add-learner-license.dialog';
import { AccountExistsDialog } from '../dialogs/account-exists/account-exists.dialog';
import { DrivingSchoolsTrustComponent } from '../components/driving-schools-trust/driving-schools-trust.component';
import { ChangedPriceListCardComponent } from '../components/changed-price-list-card/changed-price-list-card.component';
import { EddyPracticeLessonStatusDropdownComponent } from '../components/eddy-practice-lesson-status-dropdown/eddy-practice-lesson-status-dropdown.component';
import { EddyPracticeLessonTopicDropdownComponent } from '../components/eddy-practice-lesson-topic-dropdown/eddy-practice-lesson-topic-dropdown.component';
import { RatingsAndReviewsComponent } from '../components/ratings-and-reviews/ratings-and-reviews.component';

import { MatInputComponent } from '../components/input-components/mat-input/mat-input.component';
import { MatSelectComponent } from '../components/input-components/mat-select/mat-select.component';
import { MatDatePickerComponent } from '../components/input-components/mat-datepicker/mat-datepicker.component';
import { NgSelectComponent } from '../components/input-components/ng-select/ng-select.component';

import { VideoPlayerDialog } from '../dialogs/video-player/video-player.dialog';
import { PopupWidgetDialog } from '../dialogs/popup-widget/popup-widget.dialog';
import { DisableToogleDirective } from '../directives/disable-mat-slide-toggle.directive';
import { BookingConfirmationDialog } from '../dialogs/booking-confirmation/booking-confirmation.dialog';
import { AcceptLearnerPriceListDialog } from '../dialogs/accept-learner-price-list/accept-learner-price-list.dialog';
import { PriceAdjustmentDialog } from '@modules/shared/dialogs/price-adjustment-dialog/price-adjustment.dialog';
import { OnlineTheoryInfoDialog } from '../dialogs/online-theory-info-dialog/online-theory-info.dialog';

moment.locale('de', localization);

const modules = [
  CommonModule,
  HttpClientModule,
  ReactiveFormsModule,
  FileUploadModule,
  Nl2BrPipeModule,

  MatRadioModule,
  MatDialogModule,
  MatCardModule,
  MatButtonModule,
  MatSnackBarModule,
  MatSidenavModule,
  MatDividerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatListModule,
  MatTableModule,
  MatMenuModule,
  MatExpansionModule,
  MatSlideToggleModule,
  MatInputModule,
  MatPaginatorModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatFormFieldModule,
  MatSelectModule,
  MatCheckboxModule,
  MatSliderModule,
  NgxMatSelectSearchModule,
  AnalyticsModule,
  NgxFileDropModule,
  ImageCropperModule,
  TooltipModule,
  FormsModule,
  NgSelectModule,
];

const onlyImportedModules = [
  NgxDaterangepickerMd.forRoot({
    applyLabel: 'Speichern',
    customRangeLabel: ' - ',
    daysOfWeek: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    monthNames: [
      'Jan.',
      'Feb.',
      'März',
      'Apr.',
      'Mai',
      'Juni',
      'Juli',
      'Aug.',
      'Sep.',
      'Okt.',
      'Nov.',
      'Dez.',
    ],
    firstDay: 1,
  }),
];

const dialogs = [
  OkDialogComponent,
  SignContractDialogComponent,
  YesNoDialogComponent,
  ImageViewDialog,
  ImageCroppingDialog,
  ChooseLicenseDialog,
  AddLearnerLicenseDialog,
  AccountExistsDialog,
  VideoPlayerDialog,
  PopupWidgetDialog,
  BookingConfirmationDialog,
  AcceptLearnerPriceListDialog,
  PriceAdjustmentDialog,
  OnlineTheoryInfoDialog,
];

const components = [
  SpinnerComponent,
  AddressEditComponent,
  LicenseSummaryComponent,
  NavbarDropdownComponent,
  AddressSearchBarComponent,
  AppButtonsComponent,
  BackComponent,
  RatingStarsComponent,
  SchoolLanguagesComponent,
  CarouselComponent,
  SlideComponent,
  DatepickerComponent,
  AccordionItemComponent,
  InfoCardComponent,
  FileUploaderComponent,
  FileDownloaderComponent,
  AdComponent,
  SearchBoxComponent,
  PostInfoComponent,
  GalleryCardComponent,
  ViewEditCardComponent,
  TimePickerComponent,
  DateRangePickerComponent,
  BookingDriverNotes,
  EddyLearnerTypeDropdownComponent,
  EddyPaymentStatusDropdownComponent,
  EddyPaymentTypeDropdownComponent,
  InfoVideoPlayerComponent,
  KnownFromComponent,
  DonutChartComponent,
  CountdownComponent,
  VideoPlayerComponent,
  AutoCompleteComponent,
  SocialShareComponent,
  OnlineTheoryTestimonialsComponent,
  DevMarkerComponent,
  SlimSpinnerComponent,
  DrivingSchoolsTrustComponent,
  ChangedPriceListCardComponent,
  EddyPracticeLessonStatusDropdownComponent,
  EddyPracticeLessonTopicDropdownComponent,
  RatingsAndReviewsComponent,

  MatInputComponent,
  MatSelectComponent,
  MatDatePickerComponent,
  NgSelectComponent,
];

const directives = [DisableToogleDirective];

const services = [CarouselConfig];

@NgModule({
  imports: [RouterModule, modules, onlyImportedModules],
  exports: [modules, components, directives],
  declarations: [components, dialogs, directives],
  providers: [
    services,
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
export class SharedModule {}
