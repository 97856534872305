import { environment as env } from '@environments/environment';

export class Provider {
    id: number;

    name: string;
    logoPath: string;
  
    constructor(json) {
      this.id = +json.id;

      this.name = json.name;
      this.logoPath = json.logoPath;
    }

    getLogoPath(): string {
        return `${env.apiNoVersion}${this.logoPath}`;
    }
}
