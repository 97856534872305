<div class="form-group" *ngIf="address">
  <div class="row no-side-space">
    <div class="col-9 no-left-space">
      <div class="address-info-label">Straße</div>
      <input #street [value]="address.street" placeholder="Straße"
             (keyup)="onUpdateAddress()">
    </div>

    <div class="col-3 no-right-space">
      <div class="address-info-label">Nummer</div>
      <input #streetNumber [value]="address.streetNumber" placeholder="Nummer"
             (keyup)="onUpdateAddress()">
    </div>
  </div>


  <div class="row no-side-space">
    <div class="col-6 no-left-space">
      <div class="address-info-label">PLZ</div>
      <input #postalCode [value]="address.postalCode" placeholder="Postleitzahl"
             (keyup)="onUpdateAddress()">
    </div>

    <div class="col-6 no-right-space">
      <div class="address-info-label">Stadt</div>
      <input #locality [value]="address.locality" placeholder="Stadt"
             (keyup)="onUpdateAddress()"/>
    </div>
  </div>

  <div class="row no-side-space">
    <div class="col-12 no-side-space">
      <div class="address-info-label">Land</div>
      <input disabled value="Deutschland"/>
    </div>
  </div>
</div>
