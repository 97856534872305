export enum LessonType {
  DRIVING_LESSON = 'DRIVING_LESSON',
  PRACTICE_EXAM = 'PRACTICE_EXAM',
  THEORY_EXAM = 'THEORY_EXAM',
}

export function getDisplayName(type: LessonType): string {
  switch (type) {
    case LessonType.DRIVING_LESSON:
      return 'Fahrstunde';
    case LessonType.PRACTICE_EXAM:
      return 'Praktische Prüfung';
    case LessonType.THEORY_EXAM:
      return 'Theoretische Prüfung';
    default:
      return '';
  }
}
