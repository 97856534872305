import { AppRoutes } from '../app/app.routes';

export const environment = {
  environmentName: 'live',
  production: true,
  api: 'https://prod-v2-k8s.driveddy.com/v1',
  apiV2: 'https://prod-v2-k8s.driveddy.com/v2',
  apiNoVersion: 'https://prod-v2-k8s.driveddy.com',
  apiEnrichment: 'https://prod-v2-k8s.driveddy.com/enrichment-service',
  apiAdSelector: 'https://prod-v2-k8s.driveddy.com/ad-selector',
  apiEventTracking: 'https://prod-v2-k8s.driveddy.com/event-tracking',
  apiSurveyQuestions: 'https://prod-v2-k8s.driveddy.com/survey-questions',
  apiBookings: 'https://prod-v2-k8s.driveddy.com/booking-service',
  apiSchoolService: 'https://prod-v2-k8s.driveddy.com/school-service',
  apiPayouts: 'https://prod-v2-k8s.driveddy.com/payouts',
  apiOffers: 'https://prod-v2-k8s.driveddy.com/offers',
  apiSSEService: 'https://prod-v2-k8s.driveddy.com/sse-service',
  assets: 'https://www.driveddy.com',
  website: 'https://www.driveddy.com',
  landing: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.dashboard}`,
  learnerLanding: `/${AppRoutes.LearnersInternal.root}/${AppRoutes.LearnersInternal.profile}`,
  learnerWelcome: `/${AppRoutes.LearnersInternal.root}/${AppRoutes.EddyAcademyLearner.welcome}`,
  landingInternal: '/internal/schools',
  name: 'Driveddy Platform',
  googleApiKey: 'AIzaSyBKbAbMjueUwUMjPLHogb0ZTFZN_0pzk_w',
  gtagTrackingKey: 'G-N2J6GSJ66F',
  paymentRootLink: 'https://payments-v2.driveddy.com',
};
