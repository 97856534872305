export class TeamMemberDocument {
  id: number;
  type: TeamMemberDocumentType;
  mimeType: string;

  constructor(json) {
    this.id = +json.id;
    this.type = json.type;
    this.mimeType = json.mimeType;
  }

  isUploaded(): boolean {
    return !!this.id;
  }

  get mimeTypeExtension() {
    return this.mimeType.replace('image/', '');
  }
}

export enum TeamMemberDocumentType {
    SIGNATURE = 'SIGNATURE'
}
