<ng-select
  class="custom-height-40 {{ class }}"
  [class.full-width]="fullWidth"
  [class.rounded-corners]="roundedCorners"
  [ngModel]="topic ? topic : null"
  [items]="practiceLessonTopics"
  [placeholder]="placeholder"
  (change)="onChange($event)"
  [clearable]="true"
  notFoundText=""
>
  <ng-template ng-option-tmp let-item="item">
    <div class="select-value-wrapper">
      {{ getTopicDisplayName(item) }}
    </div>
  </ng-template>
  <ng-template ng-label-tmp let-item="item">
    <div class="select-value-wrapper">
      {{ getTopicDisplayName(item) }}
    </div>
  </ng-template>
</ng-select>
