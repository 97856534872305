export class TheoryQuestionStatsByCategory {
    group: string;
    incorrect: number;
    correct: number;
    unanswered: number;

    constructor(json: any) {
        this.group = json.category;

        if (json.theoryQuestionStats) {
            this.incorrect = +json.theoryQuestionStats.failedQuestions;
            this.correct = +json.theoryQuestionStats.correctQuestions;
            this.unanswered = +json.theoryQuestionStats.unansweredQuestions;
        }
    }
}
