import moment from 'moment';
import { School } from './school.model';

export class SchoolServicesBilling {
  id: number;

  createdAt: Date;
  updatedAt: Date;

  schoolId: number;
  school: School;

  amountInCents: number;
  amountPaidToSchoolInCents: number;
  billingMonth: Date;

  datePaid?: Date;

  orderKey: string;
  receiptNumber?: string;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.createdAt = new Date(json.createdAt);
    this.updatedAt = new Date(json.updatedAt);

    this.schoolId = +json.schoolId;
    if (json.school) {
      this.school = new School(json.school);
    }

    this.amountInCents = +json.amountInCents;
    this.amountPaidToSchoolInCents = +json.amountPaidToSchoolInCents;
    this.billingMonth = new Date(json.billingMonth);

    if (json.datePaid) {
      this.datePaid = new Date(json.datePaid);
    }

    this.orderKey = json.orderKey;

    this.receiptNumber = json.receiptNumber;
  }

  get billingMonthMonth(): number {
    return moment(this.billingMonth).month();
  }

  get billingMonthYear(): number {
    return moment(this.billingMonth).year();
  }

  isExcessPayment(): boolean {
    return this.amountInCents - this.amountPaidToSchoolInCents < 0;
  }

  getAmountColumnName(): string {
    if (this.isExcessPayment()) {
      return 'Mehrbetrag';
    }
    return 'Betrag';
  }

  get priceToPayInCents(): number {
    return Math.abs(this.amountInCents - this.amountPaidToSchoolInCents);
  }
}
