export enum LearnerDocumentType {
  CONTRACT = 'CONTRACT',
  SCHOOL_CONTRACT = 'SCHOOL_CONTRACT',
  FIRST_AID = 'FIRST_AID',
  VISION_TEST = 'VISION_TEST',
  OFFICIAL_REGISTRATION = 'OFFICIAL_REGISTRATION',
  THEORY_CERTIFICATE = 'THEORY_CERTIFICATE',
  PRACTICE_CERTIFICATE = 'PRACTICE_CERTIFICATE',
  TRAINING = 'TRAINING',
  BASIC_TRAINING_PAYMENT = 'BASIC_TRAINING_PAYMENT',
  ADDITIONAL_DOCUMENT = 'ADDITIONAL_DOCUMENT',
  DIGITAL_APPLICATION = 'DIGITAL_APPLICATION',
  MISCELLANEOUS_DOCUMENT = 'MISCELLANEOUS_DOCUMENT',
  MANUAL_TRANSMISSION_PROOF = 'MANUAL_TRANSMISSION_PROOF',
}

export function learnerCanUpload(documentType: LearnerDocumentType): boolean {
  return documentType === LearnerDocumentType.MISCELLANEOUS_DOCUMENT;
}
