<mat-dialog-content>
  <div class="cropper-container">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [imageFileChanged]="imageFileChanged"
      [maintainAspectRatio]="!!aspectRatio ? true: false"
      [aspectRatio]="aspectRatio"
      [resizeToWidth]="resizeToWidth"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>
</mat-dialog-content>

<mat-dialog-actions id="buttons-container">
  <button id="cancel-button" mat-dialog-close>Abbrechen</button>
  <button class="save-button" (click)="closeDialog()">Speichern</button>
</mat-dialog-actions>
