import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { SidenavService } from '@services/sidenav.service';
import { isTeamMemberUserRole } from '@models/user.model';
import { UserInfo } from '@models/user-info.model';

@Component({
  selector: 'eddy-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @Input() navRoutes: any[];
  @Input() externalNavRoutes: any[];

  isLoggedIn: Observable<boolean>;

  showMoreSubmenu = false;
  private loggedUser: UserInfo;

  constructor(private authService: AuthService,
              private sidenavService: SidenavService) {
    // subscribe to authentication status
    this.isLoggedIn = this.authService.isAuthenticatedSubject.asObservable();
    this.authService.userSubject.subscribe(user => this.loggedUser = user);
  }

  ngOnInit() {
  }

  public toggleSidenav() {
    this.sidenavService.toggle().then(() => {
    });
  }

  onLogout() {
    this.authService.logout();
  }

  toggleMoreSubmenu() {
    this.showMoreSubmenu = !this.showMoreSubmenu;
  }

  closeSidenav() {
    this.sidenavService.close();
  }

  isSchoolEmployee() {
    return this.loggedUser && isTeamMemberUserRole(this.loggedUser.role);
  }

  isInternalRoute(navRouter: any) {
    return navRouter && navRouter.route &&
      (navRouter.route.indexOf('https') === -1 || navRouter.route.indexOf('http') === -1);
  }
}
