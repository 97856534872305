import { SchoolDocumentType } from './school-document-type.model';

export class SchoolDocument {
  id: number;
  schoolId: number;
  type: SchoolDocumentType;

  constructor(json) {
    this.id = +json.id;
    this.schoolId = +json.schoolId;
    this.type = json.type;
  }
}
