import { AnalyticsDirective } from '@modules/analytics/directives/analytics/analytics.directive';
import { NgModule } from '@angular/core';
import { AnalyticsService } from '@modules/analytics/services/analytics.service';

const directives = [
  AnalyticsDirective
];

const services = [
  AnalyticsService
];

@NgModule({
  imports: [],
  providers: [
    ...services
  ],
  declarations: [
    ...directives
  ],
  exports: [
    ...directives
  ]
})
export class AnalyticsModule {
}
