import { Component, HostListener, Input } from '@angular/core';
import { isLearner, isTeamMemberUserRole } from '@models/user.model';
import { AuthService } from '@services/auth.service';
import { NavbarDropdownData } from '@models/navbar-dropdown-data.model';
import { SidenavService } from '@services/sidenav.service';
import { ScreenWidthService } from '@services/screen-width.service';
import { GlobalCommunicationService } from '@services/global-communication.service';
import { environment as env } from '@environments/environment';
import { UserInfo } from '@models/user-info.model';
import { TeamMember } from '@models/team-member.model';

@Component({
  selector: 'eddy-internal-user-icon',
  templateUrl: './internal-user-icon.component.html',
  styleUrls: ['./internal-user-icon.component.scss'],
})
export class InternalUserIconComponent {
  @Input() narrowMode: boolean;

  loggedUser: UserInfo | undefined;
  loggedTeamMember: TeamMember | undefined;

  instructorMenuData: NavbarDropdownData = {
    headerText: '',
    items: [
      {
        url: '/settings/account',
        label: 'Konto',
        icon: 'fa-user-circle',
      },
      {
        event: 'LOGOUT',
        label: 'Abmelden',
        icon: 'fa-sign-out-alt',
      },
    ],
  };

  learnerMenuData: NavbarDropdownData = {
    headerText: '',
    items: [
      {
        url: env.learnerLanding,
        label: 'Startseite',
        icon: 'fa-tachometer-alt-fastest',
      },
      {
        event: 'LOGOUT',
        label: 'Abmelden',
        icon: 'fa-sign-out-alt',
      },
    ],
  };

  isDesktop: boolean;
  navbarDisplayed = true;
  lastTopPosition = 0;
  private topBuffer = 20;

  constructor(
    private authService: AuthService,
    private sidenavService: SidenavService,
    private screenWidthService: ScreenWidthService,
    private globalCommunicationService: GlobalCommunicationService
  ) {
    this.trackUserChanges();
    this.trackIsDesktopChange();
  }

  onMenuEvent(eventID: string) {
    if (eventID === 'LOGOUT') {
      this.onLogout();
    }
  }

  onLogout() {
    this.authService.logout();
  }

  private trackUserChanges() {
    this.authService.userSubject.subscribe((user) => {
      this.loggedUser = user;
      if (this.isSchoolEmployee) {
        this.setInstructorNameInMoreMenu();
      } else if (this.isLearner) {
        this.setLearnerNameInMoreMenu();
      }
    });
    this.authService.teamMemberSubject.subscribe(
      (teamMember) => (this.loggedTeamMember = teamMember)
    );
  }

  toggleSidenav() {
    this.sidenavService.toggle().then();
  }

  private setInstructorNameInMoreMenu() {
    if (!this.loggedUser) {
      return;
    }

    if (this.isDesktop) {
      this.instructorMenuData.headerText = this.loggedUser.getDisplayName();
    } else {
      this.instructorMenuData.headerText = this.loggedUser.firstName;
    }
  }

  private setLearnerNameInMoreMenu() {
    if (!this.loggedUser) {
      return;
    }

    if (this.isDesktop) {
      this.learnerMenuData.headerText = this.loggedUser.getDisplayName();
    } else {
      this.learnerMenuData.headerText = this.loggedUser.firstName;
    }
  }

  get isSchoolEmployee() {
    return this.loggedUser && isTeamMemberUserRole(this.loggedUser.role);
  }

  get isLearner() {
    return this.loggedUser && isLearner(this.loggedUser.role);
  }

  private trackIsDesktopChange() {
    this.screenWidthService.isDesktop().subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
      if (this.isSchoolEmployee) {
        this.setInstructorNameInMoreMenu();
      } else if (this.isLearner) {
        this.setLearnerNameInMoreMenu();
      }
    });
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    if (!this.isDesktop) {
      const isScrollUp = this.isScrollUp();

      if (
        (this.navbarDisplayed && !isScrollUp) ||
        (!this.navbarDisplayed && isScrollUp)
      ) {
        this.globalCommunicationService.navbarDisplayChanged(isScrollUp);
      }

      this.navbarDisplayed = isScrollUp;
    }

    this.lastTopPosition = window.scrollY;
  }

  private isScrollUp() {
    return (
      this.lastTopPosition <= this.topBuffer ||
      this.lastTopPosition > window.scrollY
    );
  }
}
