export class PracticeLessonRating {
  id: number;
  points?: number;
  description?: string;
  positiveNotes?: string;
  negativeNotes?: string;
  otherNotes?: string;
  lessonPaidInCash?: boolean;
  isExamPassed?: boolean;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    if (json.points) {
      this.points = +json.points;
    }
    if (json.description) {
      this.description = json.description;
    }
    if (json.positiveNotes) {
      this.positiveNotes = json.positiveNotes;
    }
    if (json.negativeNotes) {
      this.negativeNotes = json.negativeNotes;
    }
    if (json.otherNotes) {
      this.otherNotes = json.otherNotes;
    }
    if (json.lessonPaidInCash !== undefined) {
      this.lessonPaidInCash = !!json.lessonPaidInCash;
    }
    if (json.isExamPassed !== undefined) {
      this.isExamPassed = !!json.isExamPassed;
    }
  }

  ratingText(): string {
    return getRatingText(this.points);
  }

  colorHex(): string {
    switch (this.points) {
      case 1:
        return '#dd6060';
      case 2:
        return '#f2d95c';
      case 3:
        return '#a1d863';
      default:
        return '';
    }
  }
}

function getRatingText(rating: number): string {
  switch (rating) {
    case 1:
      return 'Schlecht';
    case 2:
      return 'Okay';
    case 3:
      return 'Toll!';
    default:
      return '';
  }
}

export interface PracticeLessonRatingValue {
  name: string;
  value: number;
  title: string;
}

export function getPracticeLessonRatingValues(): PracticeLessonRatingValue[] {
  return [
    {
      name: 'green',
      value: 3,
      title: getRatingText(3),
    }, {
      name: 'yellow',
      value: 2,
      title: getRatingText(2),
    }, {
      name: 'red',
      value: 1,
      title: getRatingText(1),
    }];
}
