import { Component } from '@angular/core';
import { Ratings } from '@models/ratings.model';
import { TheoryLessonService } from '@services/theory-lesson.service';

@Component({
  selector: 'eddy-ratings-and-reviews',
  templateUrl: './ratings-and-reviews.component.html',
  styleUrls: ['./ratings-and-reviews.component.scss'],
})
export class RatingsAndReviewsComponent {
  ratings: Ratings;

  constructor(private theoryLessonService: TheoryLessonService) {}

  ngOnInit() {
    this.theoryLessonService
      .getOnlineTheoryRatings()
      .subscribe((ratings) => (this.ratings = ratings));
  }
}
