<div class="input-cont">
  <div *ngIf="label" class="form-label">
    {{ label }}{{ required ? '*' : '' }}
  </div>
  <mat-form-field appearance="outline">
    <mat-select
      [formControlName]="controlName"
      [placeholder]="placeholder"
      [multiple]="multiple"
      (selectionChange)="onSelectionChange($event.value)"
    >
      <ng-container *ngIf="yet">
        <mat-option *ngFor="let option of options" [value]="option.value">
          {{ option.viewValue }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <div style="display: none" *ngIf="!yet">
    <ng-content></ng-content>
  </div>
</div>
