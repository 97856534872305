import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LessonBookingService } from '@services/lesson-booking.service';

@Component({
  selector: 'eddy-booking-confirmation-dialog',
  templateUrl: './booking-confirmation.dialog.html',
  styleUrls: ['./booking-confirmation.dialog.scss']
})
export class BookingConfirmationDialog implements OnInit {
  title: string;

  learnerId: number;

  firstName: string;
  lastName: string;
  bookingNumber: number;
  amountInCents: number;
  bic: string;
  iban: string;

  availableCredits: number;

  constructor(
    private dialogRef: MatDialogRef<BookingConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private lessonBookingService: LessonBookingService,
  ) {
    this.title = this.data.title;

    this.learnerId = this.data.learnerId;

    this.firstName = this.data.firstName;
    this.lastName = this.data.lastName;
    this.bookingNumber = this.data.bookingNumber;
    this.amountInCents = this.data.amountInCents;
    this.bic = this.data.bic;
    this.iban = this.data.iban;
  }

  ngOnInit() {
    this.getLearnerAvailableCredits();
  }

  onPay() {
    this.dialogRef.close({
      payOnline: true,
    });
  }

  onPayByCredit() {
    this.dialogRef.close({
      payByCredit: true,
    });
  }

  onYes() {
    this.dialogRef.close({
      payOnline: false,
    });
  }

  onNo() {
    this.dialogRef.close(false);
  }

  private getLearnerAvailableCredits() {
    if (!this.learnerId) {
      return;
    }
    this.lessonBookingService.getLearnerAvailableCredits(this.learnerId)
      .subscribe(availableCredits => {
        this.availableCredits = availableCredits.availableCreditsInCents;
      });
  }

  get hasCredits(): boolean {
    return this.availableCredits >= this.amountInCents;
  }

}
