import { TheoryLessonAttendeeStatus } from '@models/theory-lesson-attendee.model';

export enum PracticeLessonStatus {
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  CREATED_BY_INSTRUCTOR = 'CREATED_BY_INSTRUCTOR',
  CREATED_BY_LEARNER = 'CREATED_BY_LEARNER',
  CONFIRMED_BY_INSTRUCTOR = 'CONFIRMED_BY_INSTRUCTOR',
  CONFIRMED_BY_LEARNER = 'CONFIRMED_BY_LEARNER',
  REJECTED_BY_INSTRUCTOR = 'REJECTED_BY_INSTRUCTOR',
  REJECTED_BY_LEARNER = 'REJECTED_BY_LEARNER',
  CANCELLED_BY_INSTRUCTOR = 'CANCELLED_BY_INSTRUCTOR',
  CANCELLED_BY_LEARNER = 'CANCELLED_BY_LEARNER',
  COMPLETED_BY_INSTRUCTOR = 'COMPLETED_BY_INSTRUCTOR',
  COMPLETED_BY_LEARNER = 'COMPLETED_BY_LEARNER',
  COMPLETED = 'COMPLETED',
  DELETED = 'DELETED',
  NOT_OCCURRED = 'NOT_OCCURRED',
  NOT_OCCURRED_LEARNERS_FAULT = 'NOT_OCCURRED_LEARNERS_FAULT',
  NOT_OCCURRED_INSTRUCTORS_FAULT = 'NOT_OCCURRED_INSTRUCTORS_FAULT',
}

export function getAllPracticeLessonStatuses(): PracticeLessonStatus[] {
  return [
    PracticeLessonStatus.CREATED_BY_INSTRUCTOR,
    PracticeLessonStatus.CREATED_BY_LEARNER,
    PracticeLessonStatus.CONFIRMED_BY_INSTRUCTOR,
    PracticeLessonStatus.CONFIRMED_BY_LEARNER,
    PracticeLessonStatus.REJECTED_BY_INSTRUCTOR,
    PracticeLessonStatus.REJECTED_BY_LEARNER,
    PracticeLessonStatus.COMPLETED_BY_INSTRUCTOR,
    PracticeLessonStatus.COMPLETED_BY_LEARNER,
    PracticeLessonStatus.COMPLETED,
    PracticeLessonStatus.CANCELLED_BY_INSTRUCTOR,
    PracticeLessonStatus.CANCELLED_BY_LEARNER,
    PracticeLessonStatus.NOT_OCCURRED,
    PracticeLessonStatus.NOT_OCCURRED_LEARNERS_FAULT,
    PracticeLessonStatus.NOT_OCCURRED_INSTRUCTORS_FAULT,
  ];
}

export function getConfirmedOrCompletedStatuses(): PracticeLessonStatus[] {
  return [
    PracticeLessonStatus.CONFIRMED_BY_INSTRUCTOR,
    PracticeLessonStatus.CONFIRMED_BY_LEARNER,
    PracticeLessonStatus.COMPLETED_BY_INSTRUCTOR,
    PracticeLessonStatus.COMPLETED_BY_LEARNER,
    PracticeLessonStatus.COMPLETED,
  ];
}

export function getCancelledStatuses(): PracticeLessonStatus[] {
  return [
    PracticeLessonStatus.CANCELLED_BY_INSTRUCTOR,
    PracticeLessonStatus.CANCELLED_BY_LEARNER,
  ];
}

export function isPending(status: PracticeLessonStatus): boolean {
  return (
    status === PracticeLessonStatus.CREATED_BY_INSTRUCTOR ||
    status === PracticeLessonStatus.CREATED_BY_LEARNER
  );
}

export function isCreatedByLearner(status: PracticeLessonStatus): boolean {
  return status === PracticeLessonStatus.CREATED_BY_LEARNER;
}

export function isCompletedByInstructor(status: PracticeLessonStatus): boolean {
  return status === PracticeLessonStatus.COMPLETED_BY_INSTRUCTOR;
}

export function isCompleted(status: PracticeLessonStatus): boolean {
  return status === PracticeLessonStatus.COMPLETED;
}

export function isPartiallyOrFullyCompleted(
  status: PracticeLessonStatus
): boolean {
  return (
    isCompleted(status) ||
    isCompletedByInstructor(status) ||
    isCompletedByLearner(status)
  );
}

export function isCompletedByLearner(status: PracticeLessonStatus): boolean {
  return status === PracticeLessonStatus.COMPLETED_BY_LEARNER;
}

export function isCreatedByInstructor(status: PracticeLessonStatus): boolean {
  return status === PracticeLessonStatus.CREATED_BY_INSTRUCTOR;
}

export function isConfirmed(status: PracticeLessonStatus): boolean {
  return (
    status === PracticeLessonStatus.CONFIRMED_BY_INSTRUCTOR ||
    status === PracticeLessonStatus.CONFIRMED_BY_LEARNER
  );
}

export function isNotOccurred(status: PracticeLessonStatus): boolean {
  return (
    status === PracticeLessonStatus.NOT_OCCURRED || isNotOccurredFinal(status)
  );
}

export function isNotOccurredFinal(status: PracticeLessonStatus): boolean {
  return (
    status === PracticeLessonStatus.NOT_OCCURRED_INSTRUCTORS_FAULT ||
    status === PracticeLessonStatus.NOT_OCCURRED_LEARNERS_FAULT
  );
}

export function isCancelled(status: PracticeLessonStatus): boolean {
  return (
    status === PracticeLessonStatus.CANCELLED_BY_INSTRUCTOR ||
    status === PracticeLessonStatus.CANCELLED_BY_LEARNER
  );
}

export function isCancelledByInstructor(status: PracticeLessonStatus): boolean {
  return status === PracticeLessonStatus.CANCELLED_BY_INSTRUCTOR;
}

export function isCancelledByLearner(status: PracticeLessonStatus): boolean {
  return status === PracticeLessonStatus.CANCELLED_BY_LEARNER;
}

export function isConfirmedOrCompleted(status: PracticeLessonStatus): boolean {
  return (
    status === PracticeLessonStatus.CONFIRMED_BY_INSTRUCTOR ||
    status === PracticeLessonStatus.CONFIRMED_BY_LEARNER ||
    status === PracticeLessonStatus.COMPLETED_BY_INSTRUCTOR ||
    status === PracticeLessonStatus.COMPLETED_BY_LEARNER ||
    status === PracticeLessonStatus.COMPLETED
  );
}

export function isRejected(status: PracticeLessonStatus): boolean {
  return (
    status === PracticeLessonStatus.REJECTED_BY_INSTRUCTOR ||
    status === PracticeLessonStatus.REJECTED_BY_LEARNER
  );
}

export function isRequested(status: TheoryLessonAttendeeStatus): boolean {
  return status === TheoryLessonAttendeeStatus.REQUESTED;
}

export function getDisplayName(status: PracticeLessonStatus): string {
  switch (status) {
    case PracticeLessonStatus.CREATED_BY_INSTRUCTOR:
      return 'Fahrschüler angefragt';
    case PracticeLessonStatus.CREATED_BY_LEARNER:
      return 'Fahrschüleranfrage';
    case PracticeLessonStatus.CONFIRMED_BY_INSTRUCTOR:
      return 'Vom Fahrlehrer angenommen';
    case PracticeLessonStatus.CONFIRMED_BY_LEARNER:
      return 'Vom Fahrschüler angenommen';
    case PracticeLessonStatus.COMPLETED_BY_INSTRUCTOR:
      return 'Warten auf Fahrschülerbestätigung';
    case PracticeLessonStatus.COMPLETED_BY_LEARNER:
      return 'Warten auf Fahrlehrerbestätigung';
    case PracticeLessonStatus.COMPLETED:
      return 'Abgeschlossen';
    case PracticeLessonStatus.REJECTED_BY_INSTRUCTOR:
      return 'Vom Fahrlehrer abgelehnt';
    case PracticeLessonStatus.REJECTED_BY_LEARNER:
      return 'Vom Fahrschüler abgelehnt';
    case PracticeLessonStatus.CANCELLED_BY_INSTRUCTOR:
      return 'Vom Fahrlehrer abgesagt';
    case PracticeLessonStatus.CANCELLED_BY_LEARNER:
      return 'Vom Fahrschüler abgesagt';
    case PracticeLessonStatus.NOT_OCCURRED:
      return 'Nicht stattgefunden';
    case PracticeLessonStatus.NOT_OCCURRED_LEARNERS_FAULT:
      return 'Nicht stattgefunden durch Fahrschüler';
    case PracticeLessonStatus.NOT_OCCURRED_INSTRUCTORS_FAULT:
      return 'Nicht stattgefunden durch Fahrlehrer';
    default:
      return '';
  }
}
