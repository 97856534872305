import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'eddy-rating-stars',
  templateUrl: './rating-stars.component.html',
  styleUrls: ['./rating-stars.component.scss']
})
export class RatingStarsComponent {
  private _rating: number;

  @Input() additionalLabelText: string;

  @Input() additionalLabelTextPosition: 'bottom' | 'right' | 'none' = 'bottom';

  @Input() showRatingNumber = true;

  @Input() numStars = 5;

  @Input() starSize = 16;

  @Input() spaceBetweenStars: boolean;

  @Input() set rating(rating: number) {
    this._rating = rating;
    this.setupStarRatings();
  }

  @Output() ratingChange: EventEmitter<number> = new EventEmitter();

  @Input() readOnly = true;

  get rating(): number {
    return this._rating;
  }

  @Input() displayInline: boolean;

  @Input() alternativeTextColor: boolean;

  @Input() alternativeStarsColor: boolean;

  @Input() hollowStars: boolean;

  stars: number[];

  constructor() {
  }

  onStarClick(starNumber: number) {
    if (this.readOnly) {
      return;
    }
    this.fillStarsArray(0, starNumber);
    this.ratingChange.emit(starNumber);
  }

  private setupStarRatings() {
    const numberOfFullStars = this.numberOfFullStars();
    const numberOfHalfStars = this.numberOfHalfStars();
    this.fillStarsArray(numberOfHalfStars, numberOfFullStars);
  }

  private fillStarsArray(numberOfHalfStars: number, numberOfFullStars: number) {
    const stars = new Array(this.numStars); for (let i = 0; i < this.numStars; ++i) { stars[i] = 0; }

    for (let i = 0; i < numberOfFullStars; i++) {
      stars[i] = 1;
    }

    for (let i = numberOfFullStars; i < numberOfFullStars + numberOfHalfStars; i++) {
      stars[i] = 0.5;
    }

    this.stars = stars;
  }

  numberOfFullStars() {
    if (!this.rating) {
      return 0;
    }

    let fullStars = Math.floor(this.rating);
    // if the rating is a float with a decimal part > 0.5
    // that should count as a full star
    if (this.rating % 1 >= 0.75) {
      fullStars++;
    }

    return fullStars;
  }

  numberOfHalfStars() {
    if (!this.rating) {
      return 0;
    }

    const remainder = this.rating % 1;
    return remainder > 0 && remainder < 0.75 ? 1 : 0;
  }
}
