import { NumberUtils } from '@services/number-utils.service';

export class DateUtils {
  static readonly months = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];
  static readonly weekdays = [
    'Montag', // Monday
    'Dienstag', // Tuesday
    'Mittwoch', // Wednesday
    'Donnerstag', // Thursday
    'Freitag', // Friday
    'Samstag', // Saturday
    'Sonntag', // Sunday
  ];

  static adjustDateUTC(d: Date): Date {
    if (!d) {
      return d;
    }

    d.setMinutes(d.getTimezoneOffset() * -1);
    return d;
  }

  static startOfDate(date: Date): Date {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
  }

  static startOfCurrentDate(): Date {
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    return d;
  }

  static equalDates(d1: Date, d2: Date): boolean {
    return (
      d1.getDate() === d2.getDate() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getFullYear() === d2.getFullYear()
    );
  }

  static dateOnlyToString(date: Date): string | null {
    if (!date) {
      return null;
    }

    const monthNumber = date.getMonth() + 1;
    const month = NumberUtils.padStart(monthNumber, 2, '0');

    const dayNumber = date.getDate();
    const day = NumberUtils.padStart(dayNumber, 2, '0');

    return `${date.getFullYear()}-${month}-${day}`;
  }

  static dateOnlyToStringZeroTime(date: Date): string | null {
    if (!date) {
      return null;
    }

    return `${DateUtils.dateOnlyToString(date)}T00:00:00Z`;
  }

  static getDateString(date: Date): string {
    const day = date.getDate();
    const dayString = NumberUtils.padStart(day, 2, '0');

    const month = date.getMonth() + 1;
    const monthString = NumberUtils.padStart(month, 2, '0');

    return `${dayString}.${monthString}.${date.getFullYear()}`;
  }

  static getTimeString(date: Date, includeSeconds?: boolean): string {
    const hours = date.getHours();
    const hoursString = NumberUtils.padStart(hours, 2, '0');

    const minutes = date.getMinutes();
    const minutesString = NumberUtils.padStart(minutes, 2, '0');

    const time = `${hoursString}:${minutesString}`;
    if (!includeSeconds) {
      return time;
    }

    const seconds = date.getSeconds();
    const secondsString = NumberUtils.padStart(seconds, 2, '0');

    return `${time}:${secondsString}`;
  }

  static addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  static getMonth(monthNumber: number): string {
    if (monthNumber < 0 || monthNumber > 11) return '';
    return this.months[monthNumber];
  }

  static subtractDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }
}
