import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment as env } from '@environments/environment';

import { ApiService } from '@services/api.service';
import { PageListingResponse } from '@models/page-listing-response.model';

import { saveAs } from 'file-saver';
import { SchoolServicesBilling } from '@models/school-services-billing.model';
import { DateUtils } from './date-utils.service';

@Injectable()
export class PayoutService {
  constructor(private api: ApiService) {}

  getServicesPayoutsForMonth(
    month: number,
    year: number,
    pageNumber = 0,
    pageSize = 10
  ): Observable<PageListingResponse<SchoolServicesBilling>> {
    const params = new Map<string, string>();
    params.set('month', '' + month).set('year', '' + year);

    return this.api
      .getPaged<SchoolServicesBilling>(
        `${env.apiPayouts}/get_services_payouts_for_month`,
        pageNumber,
        pageSize,
        params
      )
      .pipe(map(this.mapSchoolServicesBillingsPaged));
  }

  getSchoolServicesPayouts(
    schoolId: number,
    filters: Map<string, string>,
    pageNumber = 0,
    pageSize = 10
  ): Observable<PageListingResponse<SchoolServicesBilling>> {
    return this.api
      .getPaged<SchoolServicesBilling>(
        `${env.apiPayouts}/schools/${schoolId}/services_payouts`,
        pageNumber,
        pageSize,
        filters
      )
      .pipe(map(this.mapSchoolServicesBillingsPaged));
  }

  private mapSchoolServicesBillingsPaged(
    res: PageListingResponse
  ): PageListingResponse<SchoolServicesBilling> {
    return {
      total: res.total,
      records: res.records.map((it) => new SchoolServicesBilling(it)),
    };
  }

  schoolHasServicesBilling(
    schoolId: number,
    billingMonth: Date
  ): Observable<boolean> {
    const params = new Map<string, string>();
    params.set('billingMonth', billingMonth.toISOString());

    return this.api
      .get<boolean>(
        `${env.apiPayouts}/schools/${schoolId}/has_services_payout`,
        params
      )
      .pipe(map((it) => !!it));
  }

  generateSchoolServicesBillingForSchool(
    schoolId: number
  ): Observable<SchoolServicesBilling> {
    return this.api
      .post<SchoolServicesBilling>(
        `${env.apiPayouts}/schools/${schoolId}/services_payouts`,
        {}
      )
      .pipe(map((it) => new SchoolServicesBilling(it)));
  }

  downloadServicesPayoutInvoice(
    servicesPayout: SchoolServicesBilling
  ): Observable<any> {
    return this.api
      .getBlob(
        `${env.apiPayouts}/services_payouts/${servicesPayout.id}/document`
      )
      .pipe(
        tap((blob: Blob) => {
          if (blob.type === 'application/zip') {
            saveAs(
              blob,
              `${
                servicesPayout.receiptNumber
              }_leistungsabrechnung_${DateUtils.dateOnlyToString(
                servicesPayout.createdAt
              )}.zip`
            );
          } else {
            saveAs(
              blob,
              `${
                servicesPayout.receiptNumber
              }_leistungsabrechnung_${DateUtils.dateOnlyToString(
                servicesPayout.createdAt
              )}.pdf`
            );
          }
        })
      );
  }
}
