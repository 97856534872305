import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { asapScheduler, Observable, scheduled, throwError } from 'rxjs';
import { environment as env } from '@environments/environment';

import { School } from '@models/school.model';

import { AuthService } from './auth.service';
import { Branch } from '@models/branch.model';
import { VendorLegal } from '@models/vendor-legal.model';
import { ApiService } from '@services/api.service';
import { PageListingResponse } from '@models/page-listing-response.model';
import { VSchool } from '@models/v-school.model';
import { SchoolFilters } from '@modules/school-listing/components/school-filters/school-filters.component';
import { Asset } from '@models/asset.model';
import { base64toBlob } from './base64-to-blob';
import { EddyClubSchoolDetails } from '@models/eddy-club-school-details.model';
import { NumberOfRegisteredUsers } from '@models/number-of-registered-users.model';
import { SchoolLearnerStatus } from '@models/school-learner-status.model';
import { NumberOfRegisteredSchools } from '@models/number-of-registered-schools.model';
import { NumberOfSchools } from '@models/number-of-schools.model';
import { NumberOfLoggedInSchools } from '@models/number-of-logged-in-schools.model';
import { AddSchoolCreditsRequest } from '@models/add-school-credits-request.model';
import { Credit } from '@models/credit.model';
import { TotalCredits } from '@models/total-credits.model';
import { SchoolBillingPreference } from '@models/school-billing-preference.model';
import { TotalSchoolTurnover } from '@models/total-school-turnover.model';
import { SchoolBilling } from '@models/school-billing.model';
import { SchoolBillingStatus } from '@models/school-billing-status.model';
import { SchoolDocumentType } from '@models/school-document-type.model';
import { SchoolOutstandingBalance } from '@models/school-oustanding-balance.model';

import { saveAs } from 'file-saver';
import { SchoolLessonsPreference } from '@models/school-lessons-preference.model';
import { Refund } from '@models/refund.model';
import { AddSchoolRefundRequest } from '@models/add-school-refund-request.model';
import { SchoolOnboardingIntegrationFormPreference } from '@models/school-onboarding-integration-form-preference.model';
import { SchoolTheoryLearningPreference } from '@models/school-theory-learning-preference.model';
import { PickupLocation } from '@models/location.model';
import { SchoolDocument } from '@models/school-document.model';
import { BlobWithFileName } from '@models/blob-with-filename.model';

@Injectable()
export class SchoolService {
  constructor(
    private api: ApiService,
    private http: HttpClient,
    private authService: AuthService
  ) {}

  createSchool(school: School): Observable<School> {
    return this.api.post<School>(`${env.api}/school`, school);
  }

  getSchoolById(id: number): Observable<School> {
    return this.api
      .get<School>(`${env.apiSchoolService}/schools/${id}`)
      .pipe(map((it) => new School(it)));
  }

  getSchoolDetailsByUri(uri: string, branchId: number): Observable<School> {
    return this.api
      .get(this.buildSchoolsDetailsUrl(uri, branchId))
      .pipe(map((it) => new School(it)));
  }

  private buildSchoolsDetailsUrl(uri: string, branchId: number) {
    let url = `${env.apiSchoolService}/schools/uri/${uri}`;
    if (branchId > 0) {
      url += `?branchId=${branchId}`;
    }

    return url;
  }

  getSchools(
    filters: Map<string, string> = new Map<string, string>(),
    pageNumber = 0,
    pageSize = 10
  ) {
    if (!filters) {
      filters = new Map<string, string>();
    }

    const params = filters
      .set('pageNumber', '' + pageNumber)
      .set('pageSize', '' + pageSize);
    return this.api.get(`${env.api}/school`, params);
  }

  getAllSchools(filters: Map<string, string> = new Map<string, string>()) {
    return this.api.get(`${env.api}/school/all`, filters);
  }

  updateSchool(_school: School): Observable<School> {
    return this.http
      .put<School>(
        `${env.api}/school`,
        _school,
        this.authService.getHttpOptions()
      )
      .pipe(
        map((res) => res['data']),
        catchError((err) => throwError(err))
      );
  }

  deleteSchool(id: number) {
    return this.http
      .delete<School>(
        `${env.api}/school/${id}`,
        this.authService.getHttpOptions()
      )
      .pipe(
        map((res) => res['data']),
        catchError((err) => throwError(err))
      );
  }

  removeLanguageFromSchool(_license_id: number, _school_id: number) {
    return this.http
      .delete<School>(
        `${env.api}/school/${_school_id}/language/${_license_id}`,
        this.authService.getHttpOptions()
      )
      .pipe(
        map((res) => res['data']),
        catchError((err) => throwError(err))
      );
  }

  getBranchesBySchoolID(_id: number) {
    return this.http
      .get<Branch[]>(
        `${env.api}/branch/bySchoolID/${_id}`,
        this.authService.getHttpOptions()
      )
      .pipe(
        map((res) => res['data']),
        catchError((err) => throwError(err))
      );
  }

  getVendorLegal(vendorId: number): Observable<VendorLegal> {
    return this.api
      .get<VendorLegal>(`${env.api}/vendor/${vendorId}/legal`)
      .pipe(map((it) => new VendorLegal(it)));
  }

  saveVendorLegal(vendorLegal: VendorLegal): Observable<VendorLegal> {
    return this.api
      .post<VendorLegal>(
        `${env.api}/vendor/${vendorLegal.vendorId}/legal`,
        vendorLegal
      )
      .pipe(map((it) => new VendorLegal(it)));
  }

  loadSchoolsGrid(
    filters: SchoolFilters,
    pageNumber: number,
    pageSize: number
  ): Observable<PageListingResponse<VSchool>> {
    if (
      !filters ||
      !filters.address ||
      !filters.address.lat ||
      !filters.address.lng
    ) {
      const emptyResponse = new PageListingResponse<VSchool>();
      emptyResponse.total = 0;
      emptyResponse.records = [];
      return scheduled([emptyResponse], asapScheduler);
    }

    const queryParams = this.generateQueryParams(filters);
    return this.api.getPaged<VSchool>(
      `${env.apiSchoolService}/schools/filter${queryParams}`,
      pageNumber,
      pageSize
    );
  }

  private generateQueryParams(filters: SchoolFilters): string {
    let queryParams = `?lng=${filters.address.lng}&lat=${filters.address.lat}`;
    queryParams += `&eddyVerified=${
      filters.eddyVerified
    }&withinDistanceInMeters=${filters.maximumDistanceInKilometers * 1000}`;

    for (const licenseId of filters.licenseIds) {
      queryParams += `&license=${licenseId}`;
    }

    for (const carBrandId of filters.carBrandIds) {
      queryParams += `&carBrand=${carBrandId}`;
    }

    for (const languageId of filters.languageIds) {
      queryParams += `&language=${languageId}`;
    }

    for (const day of filters.openingDays) {
      queryParams += `&openingDay=${day}`;
    }

    return queryParams;
  }

  getEddyClubData(id: number): Observable<School> {
    return this.api
      .get<School>(`${env.apiSchoolService}/schools/${id}/eddy_club`)
      .pipe(map((it) => new School(it)));
  }

  generateAndGetSchoolDataProcessingContractBlob(
    token: string
  ): Observable<Blob> {
    const params = new Map<string, string>();
    params.set('token', token);

    return this.api.getBlob(
      `${env.apiSchoolService}/schools/data_processing_contract`,
      params
    );
  }

  getSchoolContractBlob(
    schoolId: number,
    type: SchoolDocumentType = SchoolDocumentType.CONTRACT
  ): Observable<Blob> {
    const params = new Map<string, string>();
    params.set('type', type);

    return this.api.getBlob(
      `${env.apiSchoolService}/schools/${schoolId}/contract`,
      params
    );
  }

  acceptSchoolDataProcessingContract(schoolId: number) {
    return this.api.patch(
      `${env.apiSchoolService}/schools/${schoolId}/accept_data_processing_contract`,
      {}
    );
  }

  saveSchoolContract(schoolId: number, contract: Asset) {
    return this.api.post(
      `${env.apiSchoolService}/schools/${schoolId}/contract`,
      base64toBlob(contract.base64, 'application/octet-stream')
    );
  }

  saveSchoolProposal(schoolId: number, proposal: Asset) {
    return this.api.post(
      `${env.apiSchoolService}/schools/${schoolId}/proposal`,
      base64toBlob(proposal.base64, 'application/octet-stream')
    );
  }

  saveSchoolAGBDocument(schoolId: number, agb: Asset) {
    return this.api.post(
      `${env.apiSchoolService}/schools/${schoolId}/agbDocument`,
      base64toBlob(agb.base64, 'application/octet-stream')
    );
  }

  saveSchoolDocument(
    schoolId: number,
    documentType: SchoolDocumentType,
    schoolDocument: Asset
  ) {
    return this.api.post(
      `${env.apiSchoolService}/schools/${schoolId}/documents`,
      {
        documentType: documentType,
        schoolDocumentBase64: schoolDocument.base64,
      }
    );
  }

  setIsDvfffSchool(schoolId: number, isDvfff: boolean): Observable<School> {
    return this.api.post(
      `${env.apiSchoolService}/schools/${schoolId}/dvfff/${isDvfff}`,
      {}
    );
  }

  shouldShowDvfffPopup(schoolId: number): Observable<boolean> {
    return this.api
      .get(`${env.apiSchoolService}/schools/${schoolId}/shouldShowDvfffPopup`)
      .pipe(map((it) => it.shouldShowDvfffPopup));
  }

  getSchoolDocuments(
    schoolId: number,
    types: SchoolDocumentType[]
  ): Observable<SchoolDocument[]> {
    const params = new Map<string, any>();
    params.set('types', types.join(','));
    return this.api
      .get<SchoolDocument[]>(
        `${env.apiSchoolService}/schools/${schoolId}/documents`,
        params
      )
      .pipe(map((it) => this.mapSchoolDocuments(it)));
  }

  private mapSchoolDocuments(list: SchoolDocument[]) {
    if (!list) {
      return [];
    }
    return list.map((it) => new SchoolDocument(it));
  }

  deleteSchoolDocument(schoolId: number, documentId: number) {
    return this.api.delete(
      `${env.apiSchoolService}/schools/${schoolId}/documents/${documentId}`
    );
  }

  getEddyClubSchoolsDetails(
    eddyClubId: number
  ): Observable<EddyClubSchoolDetails[]> {
    return this.api
      .get<EddyClubSchoolDetails[]>(
        `${env.apiSchoolService}/eddy_clubs/${eddyClubId}/schools`
      )
      .pipe(map((it) => this.mapEddyClubSchoolsDetails(it)));
  }

  private mapEddyClubSchoolsDetails(list: EddyClubSchoolDetails[]) {
    if (!list) {
      return [];
    }
    return list.map((it) => new EddyClubSchoolDetails(it));
  }

  updateSchoolLessonsPreferences(
    req: SchoolLessonsPreference
  ): Observable<SchoolLessonsPreference> {
    return this.api.post<SchoolLessonsPreference>(
      `${env.apiSchoolService}/schools/schoolLessonsPreferences`,
      req
    );
  }

  getSchoolLessonsPreferences(
    schoolId: number
  ): Observable<SchoolLessonsPreference> {
    return this.api
      .get<SchoolLessonsPreference>(
        `${env.apiSchoolService}/schools/${schoolId}/schoolLessonsPreferences`
      )
      .pipe(map((it) => new SchoolLessonsPreference(it)));
  }

  updateSchoolOnboardingIntegrationFormPreferences(
    req: SchoolOnboardingIntegrationFormPreference
  ): Observable<SchoolOnboardingIntegrationFormPreference> {
    return this.api.post<SchoolOnboardingIntegrationFormPreference>(
      `${env.apiSchoolService}/schools/schoolOnboardingIntegrationFormPreferences`,
      req
    );
  }

  getSchoolOnboardingIntegrationFormPreferences(
    schoolId: number
  ): Observable<SchoolOnboardingIntegrationFormPreference> {
    return this.api
      .get<SchoolOnboardingIntegrationFormPreference>(
        `${env.apiSchoolService}/schools/${schoolId}/schoolOnboardingIntegrationFormPreferences`
      )
      .pipe(map((it) => new SchoolOnboardingIntegrationFormPreference(it)));
  }

  updateSchoolTheoryLearningPreferences(
    req: SchoolTheoryLearningPreference
  ): Observable<SchoolTheoryLearningPreference> {
    return this.api.post<SchoolTheoryLearningPreference>(
      `${env.apiSchoolService}/schools/schoolTheoryLearningPreferences`,
      req
    );
  }

  getSchoolTheoryLearningPreferences(
    schoolId: number
  ): Observable<SchoolTheoryLearningPreference> {
    return this.api
      .get<SchoolTheoryLearningPreference>(
        `${env.apiSchoolService}/schools/${schoolId}/schoolTheoryLearningPreferences`
      )
      .pipe(map((it) => new SchoolTheoryLearningPreference(it)));
  }

  getNumberOfRegisteredSchools(
    year: number,
    month: number
  ): Observable<NumberOfRegisteredSchools> {
    const params = new Map<string, string>();
    params.set('year', '' + year).set('month', '' + month);

    return this.api.get<NumberOfRegisteredSchools>(
      `${env.apiSchoolService}/numberOfRegisteredSchools`,
      params
    );
  }

  getNumberOfSchoolsWithStatus(
    year: number,
    month: number,
    status: SchoolLearnerStatus
  ): Observable<NumberOfSchools> {
    const params = new Map<string, string>();
    params
      .set('year', '' + year)
      .set('month', '' + month)
      .set('status', status);

    return this.api.get<NumberOfSchools>(
      `${env.apiSchoolService}/numberOfSchoolsWithLearnerStatus`,
      params
    );
  }

  getNumberOfLoggedInSchools(
    year: number,
    month: number,
    day: number
  ): Observable<NumberOfLoggedInSchools> {
    const params = new Map<string, string>();
    params
      .set('year', '' + year)
      .set('month', '' + month)
      .set('day', '' + day);

    return this.api.get<NumberOfLoggedInSchools>(
      `${env.apiSchoolService}/numberOfLoggedInSchools`,
      params
    );
  }

  getNumberOfRegisteredUsers(
    schoolId: number,
    year: number,
    month: number
  ): Observable<NumberOfRegisteredUsers> {
    const params = new Map<string, string>();
    params.set('year', '' + year).set('month', '' + month);

    return this.api.get<NumberOfRegisteredUsers>(
      `${env.apiSchoolService}/schools/${schoolId}/numberOfRegisteredUsers`,
      params
    );
  }

  getTotalSchoolTurnover(schoolId: number): Observable<number> {
    return this.api
      .get<TotalSchoolTurnover>(
        `${env.apiSchoolService}/schools/${schoolId}/totalTurnover`
      )
      .pipe(map((it) => it.totalSchoolTurnoverInCents));
  }

  getSchoolOutstandingBalance(schoolId: number): Observable<number> {
    return this.api
      .get<SchoolOutstandingBalance>(
        `${env.apiSchoolService}/schools/${schoolId}/outstanding_balance`
      )
      .pipe(map((it) => it.schoolOutstandingAmountInCents));
  }

  getNumberOfRegisteredUsersAll(
    year: number,
    month: number
  ): Observable<NumberOfRegisteredUsers> {
    const params = new Map<string, string>();
    params.set('year', '' + year).set('month', '' + month);

    return this.api.get<NumberOfRegisteredUsers>(
      `${env.apiSchoolService}/numberOfRegisteredUsers`,
      params
    );
  }

  getTotalSchoolsTurnover(): Observable<number> {
    return this.api
      .get<TotalSchoolTurnover>(`${env.apiSchoolService}/totalTurnover`)
      .pipe(map((it) => it.totalSchoolTurnoverInCents));
  }

  addSchoolCredits(
    schoolId: number,
    req: AddSchoolCreditsRequest
  ): Observable<Credit> {
    return this.api.post<Credit>(
      `${env.apiSchoolService}/schools/${schoolId}/credits`,
      req
    );
  }

  addSchoolRefund(
    schoolId: number,
    req: AddSchoolRefundRequest
  ): Observable<Refund> {
    return this.api.post<Refund>(
      `${env.apiSchoolService}/schools/${schoolId}/refunds`,
      req
    );
  }

  downloadSchoolRefundDocument(
    schoolId: number,
    refundDocumentId: number
  ): Observable<any> {
    return this.api
      .getBlob(
        `${env.apiSchoolService}/schools/${schoolId}/refundDocuments/${refundDocumentId}`
      )
      .pipe(
        tap((blob: Blob) => {
          saveAs(blob, `Gutschrift.pdf`);
        })
      );
  }

  getSchoolCreditsPaged(
    schoolId: number,
    filters: any,
    pageNumber: number,
    pageSize: number
  ): Observable<PageListingResponse<Credit>> {
    return this.api
      .getPaged<Credit>(
        `${env.apiSchoolService}/schools/${schoolId}/credits`,
        pageNumber,
        pageSize,
        filters
      )
      .pipe(map((it) => this.mapCredits(it)));
  }

  private mapCredits(res: PageListingResponse<Credit>) {
    return {
      total: res.total,
      records: res.records.map((it) => new Credit(it)),
    };
  }

  deleteSchoolCredit(id: number) {
    return this.http
      .delete(
        `${env.apiSchoolService}/schools/credits/${id}`,
        this.authService.getHttpOptions()
      )
      .pipe(catchError((err) => throwError(err)));
  }

  getTotalSchoolCredits(schoolId: number): Observable<TotalCredits> {
    return this.api.get<TotalCredits>(
      `${env.apiSchoolService}/schools/${schoolId}/totalCredits`
    );
  }

  updateSchoolBillingPreferences(
    req: SchoolBillingPreference
  ): Observable<SchoolBillingPreference> {
    return this.api.post<SchoolBillingPreference>(
      `${env.apiSchoolService}/schools/schoolBillingPreferences`,
      req
    );
  }

  getSchoolBillingPreferences(
    schoolId: number
  ): Observable<SchoolBillingPreference> {
    return this.api.get<SchoolBillingPreference>(
      `${env.apiSchoolService}/schools/${schoolId}/schoolBillingPreferences`
    );
  }

  markMonthAsConfirmed(
    schoolId: number,
    month: number,
    year: number
  ): Observable<SchoolBilling> {
    const queryParams = `?month=${month}&year=${year}`;
    return this.api
      .post<SchoolBilling>(
        `${env.apiSchoolService}/schools/${schoolId}/billing/markMonthAsConfirmed${queryParams}`,
        {}
      )
      .pipe(map((it) => new SchoolBilling(it)));
  }

  addBillingForMonth(
    schoolId: number,
    month: number,
    year: number,
    isOnline: boolean
  ): Observable<SchoolBilling> {
    const queryParams = `?month=${month}&year=${year}&isOnline=${isOnline}`;
    return this.api
      .post<SchoolBilling>(
        `${env.apiSchoolService}/schools/${schoolId}/billing/addBillingForMonth${queryParams}`,
        {}
      )
      .pipe(map((it) => new SchoolBilling(it)));
  }

  addBillingForPreviousMonths(schoolId: number): Observable<SchoolBilling[]> {
    return this.api
      .post<SchoolBilling[]>(
        `${env.apiSchoolService}/schools/${schoolId}/billing/addBillingForPreviousMonths`,
        {}
      )
      .pipe(map(this.mapSchoolBillings));
  }

  private mapSchoolBillings(schoolBillings: any[]) {
    return schoolBillings.map((it: any) => new SchoolBilling(it));
  }

  getMonthlyBillingStatuses(
    schoolId: number,
    dateFrom: Date,
    dateTo: Date
  ): Observable<SchoolBillingStatus[]> {
    const params = new Map<string, string>();
    params
      .set('dateFrom', '' + dateFrom.toISOString())
      .set('dateTo', '' + dateTo.toISOString());

    return this.api.get<SchoolBillingStatus[]>(
      `${env.apiSchoolService}/schools/${schoolId}/billing/getMonthlyBillingStatuses`,
      params
    );
  }

  getBillingsForMonth(
    month: number,
    year: number,
    pageNumber = 0,
    pageSize = 10
  ): Observable<PageListingResponse<SchoolBilling>> {
    const params = new Map<string, string>();
    params.set('month', '' + month).set('year', '' + year);

    return this.api
      .getPaged<SchoolBilling>(
        `${env.apiSchoolService}/schools/billing/getBillingsForMonth`,
        pageNumber,
        pageSize,
        params
      )
      .pipe(map(this.mapSchoolBillingsPaged));
  }

  private mapSchoolBillingsPaged(
    res: PageListingResponse
  ): PageListingResponse<SchoolBilling> {
    return {
      total: res.total,
      records: res.records.map((it) => new SchoolBilling(it)),
    };
  }

  sendBillingLinkEmail(billingKey: string) {
    return this.api.post(
      `${env.apiSchoolService}/schools/billing/${billingKey}/sendBillingLinkEmail`,
      {}
    );
  }

  sendBillingReminderEmail(billingKey: string) {
    return this.api.post(
      `${env.apiSchoolService}/schools/billing/${billingKey}/sendBillingReminderEmail`,
      {}
    );
  }

  downloadSchoolBillingReceipt(
    orderKey: string,
    month: number,
    year: number
  ): Observable<any> {
    return this.api
      .getBlobWithFileName(
        `${env.paymentRootLink}/schoolbillingpayments/${orderKey}/receipt`
      )
      .pipe(
        tap((blobWithFileName: BlobWithFileName) => {
          saveAs(blobWithFileName.blob, blobWithFileName.fileName);
        })
      );
  }

  buildSchoolBillingPaymentLink(schoolBillingKey: string): string {
    const redirectUrl = encodeURIComponent(
      `${env.website}/instructors/accounting?success=true`
    );
    const url = `${env.paymentRootLink}/schoolbillings/${schoolBillingKey}?redirectUrl=${redirectUrl}`;

    return url;
  }

  getAverageLearnerOnlineTime(
    dateFrom: Date,
    dateTo = new Date(),
    schoolId?: number
  ): Observable<number> {
    const params = new Map<string, string>();
    params
      .set('dateFrom', dateFrom.toISOString())
      .set('dateTo', '' + dateTo.toISOString());
    if (schoolId) {
      params.set('schoolId', schoolId.toString());
    }

    return this.api
      .get(
        `${env.apiSchoolService}/schools/getAverageLearnerOnlineTime`,
        params
      )
      .pipe(map((it) => it.averageLearnerOnlineTime));
  }

  getSchoolTeamMemberPickupLocations(
    schoolId: number
  ): Observable<PickupLocation[]> {
    return this.api
      .get(`${env.apiSchoolService}/schools/${schoolId}/pickup_locations`)
      .pipe(map(this.mapSchoolPickupLocations));
  }

  private mapSchoolPickupLocations(pickupLocations: any[]) {
    if (!pickupLocations) {
      return [];
    }
    return pickupLocations.map((it: any) => new PickupLocation(it));
  }
}
