import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { getTeamMemberUserRoles, USER_ROLE } from '@models/user.model';
import { AuthRoleGuard } from '@services/auth-role-guard.service';
import { OnlyUnauthenticatedUsersGuard } from '@services/only-unauthenticated-users.guard.service';
import { AppRoutes } from './app.routes';
import { LoginComponent } from './components/login/login.component';
import { StudentRegisterInfoComponent } from './components/students/student-register-info/student-register-info.component';
import { AuthSchoolStatusGuard } from '@services/auth-school-status-guard.service';
import { LearnerTermsAndConditionsPage } from './pages/learner-terms-and-conditions/learner-terms-and-conditions.page';
import { InstructorTermsAndConditionsPage } from './pages/instructor-terms-and-conditions/instructor-terms-and-conditions.page';
import { DataPrivacyAppPage } from './pages/data-privacy-app/data-privacy-app.page';
import { DataPrivacyWebPage } from './pages/data-privacy-web/data-privacy-web.page';
import { ImprintPage } from './pages/imprint/imprint.page';
import { EddyAcademyPage } from './pages/eddy-academy/eddy-academy.page';
import { OnlineTheoryLicenseBPage } from './pages/online-theory-license-b/online-theory-license-b.page';
import { ThankYouForYourInterestPage } from './pages/thank-you-for-your-interest/thank-you-for-your-interest.page';
import { ThankYouForYourRegistrationPage } from './pages/thank-you-for-your-registration/thank-you-for-your-registration.page';
import { ThankYouForYourInquiryPage } from './pages/thank-you-for-your-inquiry/thank-you-for-your-inquiry.page';
import { AppModule } from './app.module';
import { AuthTeamMemberPositionGuard } from '@services/auth-team-member-position-guard.service';
import { PurchaseThankYouPage } from './pages/purchase-thank-you/purchase-thank-you.page';

const routes: Routes = [
  {
    path: AppRoutes.Root.signUp,
    loadChildren: () =>
      import('./modules/signup/platform/sign-up.module').then(
        (m) => m.SignUpModule
      ),
  },
  {
    path: AppRoutes.Root.user,
    loadChildren: () =>
      import('./modules/user/platform/user.module').then((m) => m.UserModule),
  },
  {
    path: AppRoutes.Root.login,
    component: LoginComponent,
    data: { breadcrumb: 'login' },
    canActivate: [OnlyUnauthenticatedUsersGuard],
  },
  {
    path: AppRoutes.Root.register,
    component: LoginComponent,
    data: { breadcrumb: 'register' },
    canActivate: [OnlyUnauthenticatedUsersGuard],
  },
  {
    path: AppRoutes.Root.user,
    loadChildren: () =>
      import('./modules/user/platform/user.module').then((m) => m.UserModule),
  },
  {
    path: AppRoutes.Root.learnerRegister,
    component: StudentRegisterInfoComponent,
  },
  {
    path: AppRoutes.Root.eddyClubSchoolCalendar,
    loadChildren: () =>
      import(
        './modules/eddy-club-calendar/platform/eddy-club-calendar.module'
      ).then((m) => m.EddyClubCalendarModule),
  },
  {
    path: AppRoutes.InstructorsInternal.root,
    loadChildren: () =>
      import(
        '@modules/instructors-internal/platform/instructors-internal.module'
      ).then((m) => m.InstructorsInternalModule),
    canActivateChild: [
      AuthRoleGuard,
      AuthSchoolStatusGuard,
      AuthTeamMemberPositionGuard,
    ],
    data: { roles: getTeamMemberUserRoles() },
  },
  {
    path: AppRoutes.LearnersInternal.root,
    loadChildren: () =>
      import(
        '@modules/learners-internal/platform/learners-internal.module'
      ).then((m) => m.LearnersInternalModule),
    canActivateChild: [AuthRoleGuard],
    data: { roles: USER_ROLE.STUDENT },
  },
  {
    path: AppRoutes.Offers.root,
    loadChildren: () =>
      import('@modules/offers/platform/offers.module').then(
        (m) => m.OffersModule
      ),
    canActivateChild: [AuthRoleGuard],
    data: { roles: USER_ROLE.STUDENT },
  },
  {
    path: 'testing',
    loadChildren: () =>
      import(
        '@modules/learners-internal/platform/learners-internal.module'
      ).then((m) => m.LearnersInternalModule),
  },
  {
    path: AppRoutes.StudentsInfo.root,
    loadChildren: () =>
      import('./modules/students-info/platform/students-info.module').then(
        (m) => m.StudentsInfoModule
      ),
  },
  {
    path: AppRoutes.SchoolsInfo.root,
    loadChildren: () =>
      import('./modules/schools-info/platform/schools-info.module').then(
        (m) => m.SchoolsInfoModule
      ),
  },

  {
    path: AppRoutes.Root.learnerTermsAndConditions,
    component: LearnerTermsAndConditionsPage,
  },
  {
    path: AppRoutes.Root.instructorTermsAndConditions,
    component: InstructorTermsAndConditionsPage,
  },
  { path: AppRoutes.Root.dataPrivacyApp, component: DataPrivacyAppPage },
  { path: AppRoutes.Root.dataPrivacyWeb, component: DataPrivacyWebPage },
  { path: AppRoutes.Root.imprint, component: ImprintPage },
  { path: AppRoutes.Root.eddyAcademy, component: EddyAcademyPage },
  {
    path: AppRoutes.Root.onlineTheoryLicenseB,
    component: OnlineTheoryLicenseBPage,
  },
  {
    path: AppRoutes.Root.thankYouForYourInterest,
    component: ThankYouForYourInterestPage,
  },
  {
    path: AppRoutes.Root.thankYouForYourRegistration,
    component: ThankYouForYourRegistrationPage,
  },
  {
    path: AppRoutes.Root.thankYouForYourInquiry,
    component: ThankYouForYourInquiryPage,
  },
  {
    path: AppRoutes.Root.purchaseThankYou,
    component: PurchaseThankYouPage,
  },

  // ================== Internal Routes ==================

  // internal routes
  {
    path: AppRoutes.Internal.root,
    loadChildren: () =>
      import('./modules/internal/platform/internal.module').then(
        (m) => m.InternalModule
      ),
    canActivateChild: [AuthRoleGuard],
    data: { roles: [USER_ROLE.PLATFORM] },
  },
  {
    path: AppRoutes.Root.settings,
    loadChildren: () =>
      import('./modules/settings/platform/settings.module').then(
        (m) => m.SettingsModule
      ),
    canActivateChild: [AuthRoleGuard],
    data: { roles: getTeamMemberUserRoles() },
  },

  {
    path: AppRoutes.Root.advertising,
    loadChildren: () =>
      import('./modules/advertising/platform/advertising.module').then(
        (m) => m.AdvertisingModule
      ),
    canActivateChild: [AuthRoleGuard],
    data: { roles: [USER_ROLE.PLATFORM] },
  },

  {
    path: AppRoutes.Questions.root,
    loadChildren: () =>
      import('./modules/questions/platform/questions.module').then(
        (m) => m.QuestionsModule
      ),
    canActivateChild: [AuthRoleGuard],
    data: { roles: [USER_ROLE.PLATFORM] },
  },

  {
    path: AppRoutes.Cars.root,
    loadChildren: () =>
      import('./modules/cars/platform/cars.module').then((m) => m.CarsModule),
    canActivateChild: [AuthRoleGuard],
    data: { roles: getTeamMemberUserRoles() },
  },

  {
    path: AppRoutes.EddyAcademyLearner.root,
    loadChildren: () =>
      import(
        './modules/eddy-academy-learner/platform/eddy-academy-learner.module'
      ).then((m) => m.EddyAcademyLearnerModule),
    canActivateChild: [AuthRoleGuard],
    data: { roles: USER_ROLE.STUDENT },
  },

  {
    path: AppRoutes.Root.acceptTermsAndConditions,
    loadChildren: () =>
      import(
        './modules/accept-terms-and-conditions/platform/accept-terms-and-conditions.module'
      ).then((m) => m.AcceptTermsAndConditionsModule),
    canActivateChild: [AuthRoleGuard],
    data: { roles: USER_ROLE.STUDENT },
  },

  // ================== PUBLIC ROUTES ==================

  {
    path: AppRoutes.Root.acceptLessons,
    loadChildren: () =>
      import('./modules/accept-lessons/platform/accept-lessons.module').then(
        (m) => m.AcceptLessonsModule
      ),
  },
  {
    path: AppRoutes.Root.rejectLessons,
    loadChildren: () =>
      import('./modules/reject-lessons/platform/reject-lessons.module').then(
        (m) => m.RejectLessonsModule
      ),
  },

  {
    path: AppRoutes.Root.acceptOnboardingRequest,
    loadChildren: () =>
      import(
        './modules/accept-onboarding-request/platform/accept-onboarding-request.module'
      ).then((m) => m.AcceptOnboardingRequestModule),
  },

  {
    path: AppRoutes.Root.downloadLicenseApplication,
    loadChildren: () =>
      import(
        './modules/download-license-application/platform/download-license-application.module'
      ).then((m) => m.DownloadLicenseApplicationModule),
  },

  {
    path: AppRoutes.Root.theoryTopicQuestion,
    loadChildren: () =>
      import(
        './modules/theory-questions/platform/theory-questions.module'
      ).then((m) => m.TheoryQuestionsModule),
  },

  {
    path: AppRoutes.Root.leadForm,
    loadChildren: () =>
      import('./modules/lead-form/platform/lead-form.module').then(
        (m) => m.LeadFormModule
      ),
  },

  {
    path: AppRoutes.Root.schools,
    loadChildren: () =>
      import('./modules/school-listing/platform/school-listing.module').then(
        (m) => m.SchoolListingModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/landing-page/platform/landing-page.module').then(
        (m) => m.LandingPageModule
      ),
  },
  {
    path: AppRoutes.Root.vipSchools,
    loadChildren: () =>
      import('./modules/vip-profiles/platform/vip-profiles.module').then(
        (m) => m.VipProfilesModule
      ),
  },

  { path: 'frag-eddy', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '' },
];

export const ModuleRouting: ModuleWithProviders<AppModule> =
  RouterModule.forRoot(routes, {
    enableTracing: false,
    anchorScrolling: 'enabled',
    initialNavigation: 'enabled',
  });
