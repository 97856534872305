import { Learner } from './learner.model';

export class LessonBookingTheoryPackage {
    learnerId: number;
    learner?: Learner;
    orderKey: string;

    constructor(json?: any) {
        if (!json) {
          return;
        }

        this.learnerId = +json.learnerId;
        if (json.learner) {
          this.learner = new Learner(json.learner);
        }

        this.orderKey = json.orderKey;
    }
}
