import { Injectable } from '@angular/core';

import { ApiService } from '@services/api.service';
import { environment as env } from '@environments/environment';
import { Observable } from 'rxjs';
import { AppFeedback } from '@models/app-feedback.model';
import { map } from 'rxjs/operators';
import { PageListingResponse } from '@models/page-listing-response.model';
import { AppFeedbackStats } from '@models/app-feedback-stats.model';

@Injectable()
export class AppFeedbackService {

  constructor(private api: ApiService) {
  }

  getFeedbacks(): Observable<AppFeedback[]> {
    return this.api.get<AppFeedback[]>(`${env.api}/app_feedbacks`)
      .pipe(
        map(it => this.mapAppFeedbacks(it))
      );
  }

  getFeedbacksPaged(pageNumber: number, pageSize: number): Observable<PageListingResponse<AppFeedback>> {
    return this.api.getPaged<AppFeedback>(`${env.api}/app_feedbacks/paged`, pageNumber, pageSize)
      .pipe(
        map(it => this.mapPagedAppFeedbacks(it))
      );
  }

  getFeedbackStats(): Observable<AppFeedbackStats> {
    return this.api.get<AppFeedbackStats>(`${env.api}/app_feedbacks/stats`);
  }

  private mapAppFeedbacks(appFeedbacks: AppFeedback[]) {
    return appFeedbacks.map(it => new AppFeedback(it));
  }

  private mapPagedAppFeedbacks(res: PageListingResponse<AppFeedback>) {
    return {
      total: res.total,
      records: res.records.map(it => new AppFeedback(it))
    };
  }
}
