<div class="dialog-container">
  <div class="image-container" *ngIf="imgSrc">
    <img [src]="imgSrc" />
  </div>
  <div id="title-container" mat-dialog-title>
    {{ title }}
    <span
      *ngIf="!disableCloseBtn"
      (click)="closeDialog(null)"
      class="close-button"
    >
      <i class="fal fa-times"></i>
    </span>
  </div>
  <mat-dialog-content>
    <div id="main-content" *ngIf="message">
      {{ message }}
    </div>
    <div id="main-content" *ngIf="messageHtml" [innerHTML]="messageHtml"></div>
    <div id="info-content" *ngIf="info">
      <div class="info-icon">
        <i class="fas fa-exclamation-circle"></i>
      </div>
      <div class="info-message" [innerHTML]="info"></div>
    </div>
  </mat-dialog-content>

  <div class="spacer"></div>

  <mat-dialog-actions id="buttons-container">
    <button id="ok-button" (click)="closeDialog(true)">
      {{ buttonLabel }}
    </button>
  </mat-dialog-actions>
</div>
