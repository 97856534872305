import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VideoPlayerComponent } from '@modules/shared/components/video-player/video-player.component';

@Component({
  templateUrl: './video-player.dialog.html',
  styleUrls: ['./video-player.dialog.scss']
})
export class VideoPlayerDialog implements AfterViewInit {
  @ViewChild('videoPlayer') videoPlayer: VideoPlayerComponent;

  videoPlaying: boolean;
  
  videoUrl: string;
  thumbnailImgSrc: string;
  showControls = false;
  autoplay = true;

  buttonLabel: string;
  buttonIcon: string;

  constructor(
    private dialogRef: MatDialogRef<VideoPlayerDialog>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.videoUrl = this.data.videoUrl;
    this.thumbnailImgSrc = this.data.thumbnailImgSrc;
    this.showControls = this.data.showControls;
    if (this.data.autoplay !== undefined) {
      this.autoplay = this.data.autoplay;
    }

    this.buttonLabel = this.data.buttonLabel;
    this.buttonIcon = this.data.buttonIcon;
  }

  ngAfterViewInit() {
    if (this.autoplay) {
      this.playVideo();
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  onButton() {
    this.dialogRef.close(true);
  }

  playVideo() {
    const timeout = this.videoPlayer.videoPlayer ? 0 : 1000;
    setTimeout(() => {
      this.videoPlaying = true;
      this.videoPlayer.play();
    }, timeout)
  }

  onVideoEnd() {
    this.videoPlaying = false;
  }

}
