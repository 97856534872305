export enum YoudriveBookingType {
  DRIVING_LESSON = 'DRIVING_LESSON',
  HIGHWAY_DRIVING = 'HIGHWAY_DRIVING',
  NIGHT_RIDE = 'NIGHT_RIDE',
  OVERLAND_RIDE = 'OVERLAND_RIDE',
  BASIC_DRIVING_TASKS = 'BASIC_DRIVING_TASKS',
  PRACTICAL_DRIVING_AND_BASIC_DRIVING_TASKS = 'PRACTICAL_DRIVING_AND_BASIC_DRIVING_TASKS',
  OTHER_PRACTICE_LESSON = 'OTHER_PRACTICE_LESSON',
  PRACTICE_EXAM = 'PRACTICE_EXAM',
  THEORY_EXAM = 'THEORY_EXAM',
  TEST_DRIVE_B197 = 'TEST_DRIVE_B197',
  THEORY_LEARNING = 'THEORY_LEARNING',
  BASIC_FEE = 'BASIC_FEE',
  THEORY_LESSON = 'THEORY_LESSON',
  SPECIFIC_THEORY_LESSON = 'SPECIFIC_THEORY_LESSON',
  MOFA_GROUP_TRAINING = 'MOFA_GROUP_TRAINING',
  TRANSFER = 'TRANSFER',
}

export function getDisplayName(
  youdriveBookingType: YoudriveBookingType
): string {
  switch (youdriveBookingType) {
    case YoudriveBookingType.DRIVING_LESSON:
      return 'Übungsfahrstunde';
    case YoudriveBookingType.HIGHWAY_DRIVING:
      return 'Autobahnfahrt';
    case YoudriveBookingType.NIGHT_RIDE:
      return 'Nachtfahrt';
    case YoudriveBookingType.OVERLAND_RIDE:
      return 'Überlandfahrt';
    case YoudriveBookingType.BASIC_DRIVING_TASKS:
      return 'Grundfahraufgaben';
    case YoudriveBookingType.PRACTICAL_DRIVING_AND_BASIC_DRIVING_TASKS:
      return 'praktisches Fahren und Grundfahraufgaben';
    case YoudriveBookingType.OTHER_PRACTICE_LESSON:
      return 'sonstige Fahrstunden';
    case YoudriveBookingType.PRACTICE_EXAM:
      return 'Praktische Prüfung';
    case YoudriveBookingType.THEORY_EXAM:
      return 'Vorstellung Theoretische Prüfung';
    case YoudriveBookingType.TEST_DRIVE_B197:
      return 'Testfahrt B197';
    case YoudriveBookingType.THEORY_LEARNING:
      return 'Lernapp';
    case YoudriveBookingType.BASIC_FEE:
      return 'Grundbetrag';
    case YoudriveBookingType.THEORY_LESSON:
      return 'Theorieunterricht';
    case YoudriveBookingType.SPECIFIC_THEORY_LESSON:
      return 'Spez. Theor. Unterricht';
    case YoudriveBookingType.MOFA_GROUP_TRAINING:
      return 'Mofa Gruppenschulung';
    case YoudriveBookingType.TRANSFER:
      return 'Versetzt';
    default:
      return '';
  }
}
