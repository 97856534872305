import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuRoute } from '@models/menu-route.model';
import { NavigationEnd, Router } from '@angular/router';
import { SidenavService } from '@services/sidenav.service';
import { NavbarDropdownData } from '@models/navbar-dropdown-data.model';
import { environment as env } from '@environments/environment';

@Component({
  selector: 'eddy-internal-sidenav',
  templateUrl: './internal-sidenav.component.html',
  styleUrls: ['./internal-sidenav.component.scss'],
})
export class InternalSidenavComponent {
  private _routes: MenuRoute[] = [];

  @Input() set routes(routes: MenuRoute[]) {
    if (!!routes && routes.length > 0) {
      this._routes = routes;
      this.checkRouteItemOpened();
    }
  }

  get routes(): MenuRoute[] {
    return this._routes;
  }

  @Input() isOverMode: boolean;

  @Input() narrowMode: boolean;

  @Output() narrowModeChange: EventEmitter<boolean> = new EventEmitter();

  activeRouteUrl: string;
  shouldExpandMenuContainer: boolean;

  learnerMenuData: NavbarDropdownData = {
    headerText: '',
    items: [
      {
        url: env.learnerLanding,
        label: 'Startseite',
      },
      {
        event: 'LOGOUT',
        label: 'Abmelden',
      },
    ],
  };

  constructor(private router: Router, private sidenavService: SidenavService) {
    this.trackRouteChanges();
  }

  private trackRouteChanges() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeRouteUrl = window.location.pathname;
        this.checkRouteItemOpened();
      }
    });
  }

  isRouteActive(route: string): boolean {
    return route === this.activeRouteUrl;
  }

  toggleSidenav() {
    this.sidenavService.toggle().then();
  }

  toggleNarrow() {
    this.narrowMode = !this.narrowMode;
    this.narrowModeChange.emit(this.narrowMode);
  }

  onRouteClick() {
    if (this.isOverMode) {
      this.closeSidenav();
    }
  }

  onParentRouteClick(routeItem: MenuRoute) {
    routeItem.opened = !routeItem.opened;
  }

  closeSidenav() {
    this.sidenavService.close().then();
  }

  onMenuItemMouseEnter(routeItem: MenuRoute) {
    if (!this.narrowMode) {
      return;
    }
    routeItem['showLabel'] = true;
    this.shouldExpandMenuContainer = true;
  }

  onMenuItemMouseLeave(routeItem: MenuRoute) {
    if (!this.narrowMode) {
      return;
    }
    routeItem['showLabel'] = false;
    this.shouldExpandMenuContainer = false;
  }

  checkRouteItemOpened() {
    for (const route of this.routes) {
      if (route.children) {
        route.opened = this.isRouteItemOpened(route.children);
      }
    }
  }

  isRouteItemOpened(routes: MenuRoute[]): boolean {
    for (const route of routes) {
      if (this.activeRouteUrl === route.route) {
        return true;
      }
    }
    return false;
  }
}
