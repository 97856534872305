import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';
import { CDR } from '@services/change-detector-ref-utils';

interface Timer {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

function isEmpty(t: Timer): boolean {
  return t.days === 0 && t.hours === 0 && t.minutes === 0 && t.seconds === 0;
}

function totalTimeInSeconds(t: Timer): number {
  return t.days * 24 * 60 * 60 + t.hours * 60 * 60 + t.minutes * 60 + t.seconds;
}

@Component({
  selector: 'eddy-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownComponent implements OnInit {
  private timerRefreshPeriodInMilliseconds = 1000;

  @Input() date: Date;

  @Input() isOnlineTheoryActivationCountdown: boolean;

  @Input() minutesLabel = 'm';
  @Input() secondsLabel = 's';

  @Input() showOnEnd = false;

  @Output() countdownTick: EventEmitter<number> = new EventEmitter();

  @Output() countdownEnd: EventEmitter<void> = new EventEmitter();

  timer: Timer;
  private timerInterval;
  private wasCountdownEndSignaled: boolean;

  constructor(
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.isOnlineTheoryActivationCountdown) {
      this.timerRefreshPeriodInMilliseconds = 60000;
    }
    this.ngZone.runOutsideAngular(() => {
      this.runTimer();
    });
  }

  private runTimer() {
    if (this.isOnlineTheoryActivationCountdown) {
      this.timer = this.recalculateTimer();
      if (!CDR.isDestroyed(this.changeDetectorRef)) {
        this.changeDetectorRef.detectChanges();
      }
    }

    this.timerInterval = setInterval(() => {
      if (new Date().getTime() > this.date.getTime()) {
        this.closeTimer();
        if (!this.wasCountdownEndSignaled) {
          this.emitCountdownEnd();
        }
        return;
      }
      this.timer = this.recalculateTimer();
      if (!CDR.isDestroyed(this.changeDetectorRef)) {
        this.changeDetectorRef.detectChanges();
      }
      this.countdownTick.emit(totalTimeInSeconds(this.timer));
      if (isEmpty(this.timer)) {
        this.emitCountdownEnd();
        this.closeTimer();
        return;
      }
    }, this.timerRefreshPeriodInMilliseconds);
  }

  private emitCountdownEnd() {
    this.ngZone.run(() => {
      this.countdownEnd.emit();
      this.wasCountdownEndSignaled = true;
    });
  }

  private closeTimer() {
    clearInterval(this.timerInterval);
    this.timerInterval = void 0;
  }

  private recalculateTimer(): Timer {
    const now = new Date();

    // get total seconds between the times
    let delta = Math.abs(this.date.getTime() - now.getTime()) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    const seconds = Math.floor(delta % 60);

    return {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };
  }
}
