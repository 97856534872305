import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { LicensePriceList } from '@models/license.model';
import { Router } from '@angular/router';
import { AppRoutes } from 'app/app.routes';


@Component({
  templateUrl: './accept-learner-price-list.dialog.html',
  styleUrls: ['./accept-learner-price-list.dialog.scss']
})
export class AcceptLearnerPriceListDialog implements OnInit {
  priceListId: LicensePriceList;

  constructor(private dialogRef: MatDialogRef<AcceptLearnerPriceListDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router) {
    this.priceListId = data.priceListId;
  }

  ngOnInit() {}

  onQuit() {
    this.closeDialog(null);
  }

  acceptPriceList() {
    this.closeDialog(true);
  }

  rejectPriceList() {
    this.closeDialog(false);
  }

  goToCheckPriceList() {
    if (!this.priceListId) {
      return;
    }

    this.router.navigate([AppRoutes.LearnersInternal.root, AppRoutes.LearnersInternal.priceListChangeOverview, this.priceListId]);
    this.onQuit();
  }

  private closeDialog(result) {
    this.dialogRef.close(result);
  }

}
