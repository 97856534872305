import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
} from '@angular/forms';

@Component({
  selector: 'eddy-ng-select',
  templateUrl: './ng-select.component.html',
  styleUrls: ['./ng-select.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class NgSelectComponent implements AfterViewInit {
  @Input() label: string;

  @Input() controlName: string;

  @Input() control: FormControl;

  @Input() placeholder = 'bitte auswählen';

  @Input() disabled = false;

  @Input() required = false;

  @Input() multiple = false;

  @Input() clearable = true;

  @Output() selectionChange: EventEmitter<any> = new EventEmitter();

  @ContentChildren('ngOption')
  queryOptions: QueryList<any>;

  options: any[];
  yet: boolean;
  isFocused: boolean;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.options = this.queryOptions.map((x) => {
      return { value: x.value, viewValue: x.label };
    });
    setTimeout(() => {
      this.yet = true;
      this.changeDetectorRef.detectChanges();
    });
  }

  resetValue() {
    this.control.setValue('');
  }

  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    this.isFocused = false;
  }

  onSelectionChange(value: any) {
    this.selectionChange.emit(value);
  }
}
