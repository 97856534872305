import {
  CalendarEventType,
  getDisplayName as getCalendarEventTypeDisplayName,
} from '@models/calendar-event-type.model';
import {
  getDisplayName as getStatusDisplayName,
  isCompleted,
  isCompletedByInstructor,
  isCompletedByLearner,
  isConfirmed,
  isCreatedByInstructor,
  isCreatedByLearner,
  isPartiallyOrFullyCompleted,
  isPending,
  isRequested,
  isConfirmedOrCompleted,
  PracticeLessonStatus,
  isCancelled,
  isNotOccurred,
  isNotOccurredFinal,
  isRejected,
} from '@models/practice-lesson-status.model';
import { UserInfo } from '@models/user-info.model';
import { TheoryLessonTopic } from '@models/theory-lesson-topic.model';
import { TimeRange } from '@models/time-range.model';
import {
  getDisplayName,
  PracticeLessonTopic,
} from '@models/practice-lesson-topic.model';
import * as moment from 'moment';
import { Address } from '@models/address.model';
import { PracticeLessonRating } from './practice-lesson-rating.model';
import { DateUtils } from '@services/date-utils.service';
import { PaymentStatus } from '@models/payment-status.model';
import { StandardizedPracticeTopic } from './standardized-practice-topic.model';
import {
  TheoryLessonAttendeeStatus,
  TheoryLessonAttendeeUser,
} from '@models/theory-lesson-attendee.model';
import { LearnerLicense } from './learner.model';
import {
  isCancellationReasonInstructorsFault,
  PracticeLessonCancellationReason,
} from './practice-lesson-cancellation-reason.model';

export class LessonCar {
  id: number;
  brandName: string;
  modelName: string;
  isAutomaticTransmission: boolean;

  constructor(json) {
    this.id = +json.id;
    this.brandName = json.brandName;
    this.modelName = json.modelName;
    this.isAutomaticTransmission = !!json.isAutomaticTransmission;
  }
}

export class LessonLicense {
  id: number;
  key: string;

  constructor(json) {
    this.id = +json.id;
    this.key = json.key;
  }
}

export class VCalendarEvent {
  eventId: number;

  title?: string;

  type: CalendarEventType;

  startDate: Date;
  endDate: Date;

  movedStartDate?: Date;
  movedEndDate?: Date;

  lessonId?: number;
  key?: string;

  practiceTopic?: PracticeLessonTopic;

  standardizedPracticeTopic?: StandardizedPracticeTopic;

  learnerId?: number;
  learnerUserId?: number;
  learnerUser?: UserInfo;
  learnerLicenses?: LearnerLicense[];
  instructor?: UserInfo;

  lessonTopic?: TheoryLessonTopic;
  maximumCapacity?: number;
  theoryLessonAttendeesList?: TheoryLessonAttendeeUser[];
  theoryLessonAttendees?: number;
  theoryLessonConfirmedAttendees?: number;

  theoryLessonAttendeesOfSchool?: number;
  theoryLessonConfirmedAttendeesOfSchool?: number;

  status?: PracticeLessonStatus | TheoryLessonAttendeeStatus;

  learnerMessage?: string;

  car?: LessonCar;

  paymentStatus?: PaymentStatus;

  license?: LessonLicense;

  examId?: number;
  examAttempt?: number;
  theoryExamAttendeesEvents?: VCalendarEvent[];
  isExamArchived: boolean;

  responsibleAuthoritySettingId?: number;
  responsibleAuthorityProvider?: string;
  responsibleAuthorityName?: string;

  pickupLocationId?: number;
  customLocation?: Address;

  private readonly priceInCents?: number;

  cancellationReason?: PracticeLessonCancellationReason;

  isConsideredForCancellationFee?: boolean;

  rating?: PracticeLessonRating;

  isCashPayment?: boolean;

  schoolName: string;
  schoolIsDigitalBookingAndPaymentAvailable?: boolean;

  readonly isEddyClubLesson: boolean;
  private readonly schoolCanUsePaymentSystem: boolean;

  isOnlineEvent: boolean;
  isPureLiveStream: boolean;

  isPracticeExam: boolean;
  isExamPassed?: boolean;

  paymentLink?: string;

  isDeleted: boolean;

  isYoudriveExam: boolean;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.eventId = +json.eventId;
    this.title = json.title;
    this.type = json.type;

    this.startDate = new Date(json.startDate);
    this.endDate = new Date(json.endDate);

    if (json.movedStartDate) {
      this.movedStartDate = new Date(json.movedStartDate);
    }
    if (json.movedEndDate) {
      this.movedEndDate = new Date(json.movedEndDate);
    }

    if (json.lessonId) {
      this.lessonId = +json.lessonId;
    }
    if (json.key) {
      this.key = json.key;
    }

    if (json.practiceTopic) {
      this.practiceTopic = json.practiceTopic;
    }
    if (json.standardizedPracticeTopic) {
      this.standardizedPracticeTopic = new StandardizedPracticeTopic(
        json.standardizedPracticeTopic
      );
    }

    if (json.learnerId) {
      this.learnerId = +json.learnerId;
    }
    if (json.learnerUserId) {
      this.learnerUserId = +json.learnerUserId;
    }
    if (json.learnerUser) {
      this.learnerUser = new UserInfo(json.learnerUser);
    }
    if (
      json.learnerLicenses &&
      json.learnerLicenses !== null &&
      json.learnerLicenses.length > 0
    ) {
      this.learnerLicenses = json.learnerLicenses;
    }

    if (json.lessonTopic) {
      this.lessonTopic = new TheoryLessonTopic(json.lessonTopic);
      this.theoryLessonAttendees = +json.theoryLessonAttendees || 0;
      this.theoryLessonConfirmedAttendees =
        +json.theoryLessonConfirmedAttendees || 0;

      this.theoryLessonAttendeesOfSchool =
        +json.theoryLessonAttendeesOfSchool || 0;
      this.theoryLessonConfirmedAttendeesOfSchool =
        +json.theoryLessonConfirmedAttendeesOfSchool || 0;
    }

    if (json.theoryLessonAttendeesList) {
      this.theoryLessonAttendeesList = json.theoryLessonAttendeesList.map(
        (it) => new TheoryLessonAttendeeUser(it)
      );
    }

    if (json.maximumCapacity) {
      this.maximumCapacity = +json.maximumCapacity;
    }

    this.status = json.status;

    this.learnerMessage = json.learnerMessage;

    if (json.car) {
      this.car = new LessonCar(json.car);
    }

    if (json.paymentStatus) {
      this.paymentStatus = json.paymentStatus;
    }

    if (json.license) {
      this.license = new LessonLicense(json.license);
    }

    if (json.examId) {
      this.examId = +json.examId;
    }

    if (json.examAttempt) {
      this.examAttempt = json.examAttempt;
    }

    if (json.theoryExamAttendeesEvents) {
      this.theoryExamAttendeesEvents = json.theoryExamAttendeesEvents.map(
        (it) => new VCalendarEvent(it)
      );
    }
    if (json.isExamArchived !== undefined) {
      this.isExamArchived = !!json.isExamArchived;
    }
    if (json.responsibleAuthoritySettingId) {
      this.responsibleAuthoritySettingId = +json.responsibleAuthoritySettingId;
      this.responsibleAuthorityProvider = json.responsibleAuthorityProvider;
      this.responsibleAuthorityName = json.responsibleAuthorityName;
    }

    if (json.instructor) {
      this.instructor = new UserInfo(json.instructor);
    }

    if (json.pickupLocationId) {
      this.pickupLocationId = +json.pickupLocationId;
    }
    if (json.customLocation) {
      this.customLocation = new Address(json.customLocation);
    }

    if (json.priceInCents) {
      this.priceInCents = json.priceInCents;
    }

    if (json.cancellationReason) {
      this.cancellationReason = json.cancellationReason;
    }

    if (json.isConsideredForCancellationFee) {
      this.isConsideredForCancellationFee =
        !!json.isConsideredForCancellationFee;
    }

    if (json.instructorRatingId) {
      this.rating = new PracticeLessonRating({
        id: json.instructorRatingId,
        points: json.instructorRating,
        description: json.instructorRatingFeedback,
      });
    }

    if (json.isCashPayment) {
      this.isCashPayment = !!json.isCashPayment;
    }

    this.schoolName = json.schoolName;

    if (json.schoolIsDigitalBookingAndPaymentAvailable !== undefined) {
      this.schoolIsDigitalBookingAndPaymentAvailable =
        !!json.schoolIsDigitalBookingAndPaymentAvailable;
    }

    this.isEddyClubLesson = !!json.isEddyClubLesson;
    this.schoolCanUsePaymentSystem = !!json.schoolCanUsePaymentSystem;
    this.isOnlineEvent = !!json.isOnlineEvent;
    this.isPureLiveStream = !!json.isPureLiveStream;
    this.isPracticeExam = !!json.isPracticeExam;
    if (json.isExamPassed !== undefined) {
      this.isExamPassed = !!json.isExamPassed;
    }

    if (json.paymentLink) {
      this.paymentLink = json.paymentLink;
    }

    this.isDeleted = !!json.isDeleted;

    this.isYoudriveExam = !!json.isYoudriveExam;
  }

  get freeSeats() {
    if (this.isTheoryExamEvent()) {
      return Math.max(0, this.maximumCapacity - this.numTheoryExamAttendees);
    }
    return Math.max(0, this.maximumCapacity - this.theoryLessonAttendees);
  }

  get numTheoryExamAttendees(): number {
    if (!this.theoryExamAttendeesEvents) {
      return 0;
    }
    return this.theoryExamAttendeesEvents?.length;
  }

  getDisplayName(): string {
    if (this.type === CalendarEventType.PRACTICE) {
      if (this.practiceTopic) {
        return this.getPracticeLessonTopicDisplayName();
      } else if (this.standardizedPracticeTopic) {
        return this.getStandardizedPracticeLessonTopicDisplayName();
      }
    } else if (this.type === CalendarEventType.THEORY) {
      return this.getTheoryLessonTopicDisplayName();
    } else if (this.type === CalendarEventType.PRACTICE_EXAM) {
      return 'Praktische Prüfung';
    } else if (this.type === CalendarEventType.THEORY_EXAM) {
      return 'Theoretische Prüfung';
    }

    return '';
  }

  getCalendarPracticeLessonDisplayName(): string {
    if (this.type === CalendarEventType.PRACTICE) {
      if (this.practiceTopic) {
        return this.getPracticeLessonTopicDisplayName();
      } else if (this.standardizedPracticeTopic) {
        return this.isPracticeExam ? 'Praktische Prüfung' : 'Praktische Stunde';
      }
    } else if (
      this.type === CalendarEventType.PRACTICE_EXAM ||
      this.type === CalendarEventType.THEORY_EXAM
    ) {
      return getCalendarEventTypeDisplayName(this.type);
    }

    return '';
  }

  getCalendarEventTypeDisplayName(): string {
    return getCalendarEventTypeDisplayName(this.type);
  }

  private getPracticeLessonTopicDisplayName(): string {
    return getDisplayName(this.practiceTopic);
  }

  private getStandardizedPracticeLessonTopicDisplayName(): string {
    if (!this.standardizedPracticeTopic) {
      return '';
    }

    return this.standardizedPracticeTopic.getDisplayName();
  }

  private getTheoryLessonTopicDisplayName(): string {
    return `Theoriestunde: ${this.lessonTopic.key}`;
  }

  getExamResponsibleAuthorityDisplayName(): string {
    if (
      !this.responsibleAuthorityProvider ||
      this.responsibleAuthorityProvider === ''
    ) {
      return '-';
    }
    return `${this.responsibleAuthorityProvider} ${this.responsibleAuthorityName}`;
  }

  getParticipantsEnrolledDisplayName(): string {
    if (
      !this.maximumCapacity ||
      (this.maximumCapacity === 1 && this.isPracticeExamEvent())
    ) {
      return '1';
    }

    return `${this.numTheoryExamAttendees} / ${this.maximumCapacity}`;
  }

  instructorShortenedName(): string {
    if (!this.instructor) {
      return '';
    }

    return this.instructor.getShortenedName();
  }

  getSchoolStartDate(): Date {
    if (!this.movedStartDate) {
      return this.startDate;
    }
    return this.movedStartDate;
  }

  getSchoolEndDate(): Date {
    if (!this.movedEndDate) {
      return this.endDate;
    }
    return this.movedEndDate;
  }

  getSchoolStartTime(): string {
    if (!this.movedStartDate) {
      return this.startTime();
    }

    return this.movedStartTime();
  }

  getSchoolEndTime(): string {
    if (!this.movedEndDate) {
      return this.endTime();
    }

    return this.movedEndTime();
  }

  schoolStartDateIsAfterEndDate() {
    return (
      this.getSchoolStartDate().getTime() > this.getSchoolEndDate().getTime()
    );
  }

  startTime(): string {
    return DateUtils.getTimeString(this.startDate);
  }

  endTime(): string {
    return DateUtils.getTimeString(this.endDate);
  }

  movedStartTime(): string {
    if (!this.movedStartDate) {
      return '';
    }
    return DateUtils.getTimeString(this.movedStartDate);
  }

  movedEndTime(): string {
    if (!this.movedEndDate) {
      return '';
    }
    return DateUtils.getTimeString(this.movedEndDate);
  }

  isFree() {
    return this.isPracticeEvent() && !this.lessonId;
  }

  isFreeExamEvent() {
    return this.isExamEvent() && !this.lessonId;
  }

  isFreePracticeExamEvent() {
    return this.isPracticeExamEvent() && !this.lessonId;
  }

  isFreeTheoryExamEvent() {
    return this.isTheoryExamEvent() && !this.lessonId;
  }

  isInThePast() {
    return !!this.startDate && this.startDate.getTime() < Date.now();
  }

  isToday() {
    return moment(this.startDate).isSame(moment(), 'day');
  }

  isCompletelyFinished(): boolean {
    return !!this.endDate && this.endDate.getTime() < Date.now();
  }

  isPaid() {
    return this.paymentStatus === PaymentStatus.PAID;
  }

  isPaidOrRefunded(): boolean {
    return [
      PaymentStatus.PAID,
      PaymentStatus.REFUNDED,
      PaymentStatus.CANCELLATION_REFUNDED,
    ].includes(this.paymentStatus);
  }

  isPending() {
    return !!this.status && isPending(<PracticeLessonStatus>this.status);
  }

  isConfirmed() {
    return (
      !!this.status &&
      (isConfirmed(<PracticeLessonStatus>this.status) ||
        this.status.toString() === 'CONFIRMED')
    ); // for theory lessons
  }

  isCompletedByInstructor() {
    return (
      !!this.status &&
      isCompletedByInstructor(<PracticeLessonStatus>this.status)
    );
  }

  isPartiallyOrFullyCompleted() {
    return (
      !!this.status &&
      isPartiallyOrFullyCompleted(<PracticeLessonStatus>this.status)
    );
  }

  isCompleted() {
    return !!this.status && isCompleted(<PracticeLessonStatus>this.status);
  }

  isWaitingForInstructorConfirmation() {
    return (
      !!this.status && isCreatedByLearner(<PracticeLessonStatus>this.status)
    );
  }

  isWaitingForLearnerConfirmation() {
    return (
      !!this.status && isCreatedByInstructor(<PracticeLessonStatus>this.status)
    );
  }

  isRequested(): boolean {
    return (
      !!this.status && isRequested(<TheoryLessonAttendeeStatus>this.status)
    );
  }

  isConfirmedOrCompleted(): boolean {
    return (
      !!this.status && isConfirmedOrCompleted(<PracticeLessonStatus>this.status)
    );
  }

  isCancelled(): boolean {
    return !!this.status && isCancelled(<PracticeLessonStatus>this.status);
  }

  isNotOccurred(): boolean {
    return !!this.status && isNotOccurred(<PracticeLessonStatus>this.status);
  }

  isRejected(): boolean {
    return !!this.status && isRejected(<PracticeLessonStatus>this.status);
  }

  isNotOccurredFinal(): boolean {
    return (
      !!this.status && isNotOccurredFinal(<PracticeLessonStatus>this.status)
    );
  }

  getStatusDisplayName(): string {
    if (this.isTheoryExamEvent()) {
      if (this.isInThePast()) {
        return 'Abgeschlossen';
      } else {
        return 'Bevorstehend';
      }
    } else if (this.isPracticeExamEvent()) {
      if (this.isExamPassed !== undefined) {
        if (this.isExamPassed) {
          return 'Bestanden';
        } else {
          return 'Nicht bestanden';
        }
      } else {
        if (this.isInThePast() && this.isConfirmedOrCompleted()) {
          return 'Abgeschlossen';
        } else if (!this.isInThePast() && this.isConfirmed()) {
          return 'Bevorstehend';
        }
      }
    }
    return (
      !!this.status && getStatusDisplayName(<PracticeLessonStatus>this.status)
    );
  }

  isEnded() {
    const now = moment();
    const endDate = moment(this.endDate);
    const diff = now.diff(endDate, 'minutes');
    return diff > 0;
  }

  isCompletedByLearner() {
    return (
      !!this.status && isCompletedByLearner(<PracticeLessonStatus>this.status)
    );
  }

  isCancellationReasonInstructorsFault(): boolean {
    return isCancellationReasonInstructorsFault(this.cancellationReason);
  }

  get timeslotInCurrentTimezone(): TimeRange {
    return {
      startTime: {
        hour: this.startDate.getHours(),
        minute: this.startDate.getMinutes(),
      },
      endTime: {
        hour: this.endDate.getHours(),
        minute: this.endDate.getMinutes(),
      },
    };
  }

  isBlockedTime() {
    return this.type === CalendarEventType.BLOCK_TIME;
  }

  isPracticeEvent() {
    return this.type === CalendarEventType.PRACTICE;
  }

  isTheoryEvent() {
    return this.type === CalendarEventType.THEORY;
  }

  isExamEvent() {
    return this.isPracticeExamEvent() || this.isTheoryExamEvent();
  }

  isPracticeExamEvent() {
    return this.type === CalendarEventType.PRACTICE_EXAM;
  }

  isTheoryExamEvent() {
    return this.type === CalendarEventType.THEORY_EXAM;
  }

  get shouldPaymentInfoBeShown(): boolean {
    return this.schoolCanUsePaymentSystem;
  }

  get paymentInCents(): number {
    return this.priceInCents;
  }

  getInstructorPaymentInCents(): number {
    if (!this.shouldPaymentInfoBeShown) {
      return null;
    }
    return (0.75 * this.priceInCents) / 100;
  }

  startDateIsAfterEndDate() {
    return this.startDate.getTime() > this.endDate.getTime();
  }

  isCreatedByLearner(): boolean {
    return this.status === PracticeLessonStatus.CREATED_BY_LEARNER;
  }

  isPracticeLessonCreatedByLearnerStartingTodayOrInThePast(): boolean {
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    return (
      this.isPracticeEvent() &&
      this.isCreatedByLearner() &&
      this.startDate <= today
    );
  }

  isPracticeLessonCreatedByLearnerStartingTodayOrInTheFuture(): boolean {
    return (
      this.isPracticeEvent() &&
      this.isCreatedByLearner() &&
      this.startDate > new Date()
    );
  }

  learnerUserNameLike(name: string): boolean {
    return (
      (this.learnerUser &&
        this.learnerUser
          .getDisplayName()
          .toLowerCase()
          .includes(name.trim().toLowerCase())) ||
      (this.theoryLessonAttendeesList &&
        this.theoryLessonAttendeesList
          .map((attendee) => attendee.getDisplayName().toLowerCase())
          .some((userName) => userName.includes(name.trim().toLowerCase())))
    );
  }

  getEventLength(): number {
    const startDate = moment(this.startDate);
    const endDate = moment(this.endDate);
    const diff = endDate.diff(startDate, 'minutes');
    return diff > 0 ? diff : 0;
  }

  getLearnerLicensesKeys(): string[] {
    if (
      !this.learnerLicenses ||
      (this.learnerLicenses && this.learnerLicenses.length === 0)
    ) {
      return [];
    }

    return this.learnerLicenses.map(
      (learnerLicense) => learnerLicense.license.key
    );
  }

  learnerLicensesDisplayNameWithText(): string {
    if (this.license) {
      return `Klasse ${this.license.key}`;
    }

    const licenseKeys = this.getLearnerLicensesKeys();
    if (!licenseKeys || (licenseKeys && licenseKeys.length === 0)) {
      return '';
    }

    if (licenseKeys.length === 1) {
      return `Klasse ${licenseKeys[0]}`;
    }

    const last = licenseKeys.pop();
    return `Klassen ${licenseKeys.join(', ')} und ${last}`;
  }

  examStatus(): string {
    if (this.isExamArchived) {
      return 'Archiviert';
    }

    if (this.isPending() || this.isRejected()) {
      return getStatusDisplayName(<PracticeLessonStatus>this.status);
    } else if (this.isCancelled()) {
      return 'Abgesagt';
    } else if (this.isNotOccurred()) {
      return 'Nicht stattgefunden';
    } else if (this.isInThePast()) {
      if (this.isExamPassed !== undefined) {
        if (this.isExamPassed) {
          return 'Bestanden';
        } else {
          return 'Nicht bestanden';
        }
      } else {
        return 'Abgeschlossen';
      }
    } else if (!this.isInThePast() && this.isConfirmed()) {
      return 'Bevorstehend';
    }
  }

  examEventStatus(): string {
    if (this.isExamArchived) {
      return 'Archiviert';
    }

    if (this.isDeleted) {
      return 'Abgesagt';
    }

    if (this.isInThePast()) {
      return 'Abgeschlossen';
    }

    return 'Bevorstehend';
  }

  isExamCompletedNotRated(): boolean {
    return (
      this.isConfirmedOrCompleted() &&
      this.isInThePast() &&
      this.isExamPassed === undefined
    );
  }

  get learnerName(): string {
    if (!this.learnerUser) {
      return '';
    }
    return this.learnerUser.getDisplayName();
  }

  getGeneralInfo(): string {
    if (!this.lessonId) {
      return '-';
    }

    if (this.isTheoryEvent()) {
      return `${this.lessonTopic.key} (${this.theoryLessonAttendees} Teilnehmer)`;
    } else if (this.isPracticeEvent() || this.isPracticeExamEvent()) {
      return `${
        this.learnerName
      } (${this.learnerLicensesDisplayNameWithText()})`;
    } else if (this.isTheoryExamEvent()) {
      return `${this.numTheoryExamAttendees} Teilnehmer (Klasse ${this.license?.key})`;
    }
  }

  get hasNoAttendeesForExport(): boolean {
    if (this.isInThePast()) {
      return this.theoryLessonConfirmedAttendees === 0;
    }

    return this.theoryLessonAttendees === 0;
  }
}

export interface OpenPracticeLessonRequest {
  event: VCalendarEvent;
  reject?: boolean;
  delete?: boolean;
  openInCalendar?: boolean;
}
