import { PaymentStatus } from './payment-status.model';
import { PaymentType } from './payment-type.model';
import { TheoryPackageType } from './theory-package-type.model';

export class TheoryPackage {
  key: string;
  learnerId: number;
  numberOfLessons: number;
  savingsPercentage: number;
  packages: Array<string>;
  paymentStatus: PaymentStatus;

  serviceFee: number;
  schoolPaymentType: PaymentType;
  schoolBIC: string;
  schoolIBAN: string;

  type: TheoryPackageType;

  offerPriceInCents: number;

  private readonly priceInCents: number;
  private readonly fullPriceIfBuyingSingleLessonsInCents: number;

  constructor(json) {
    this.key = json.key;
    this.learnerId = +json.learnerId;
    this.priceInCents = +json.priceInCents;
    this.paymentStatus = json.paymentStatus;
    this.numberOfLessons = +json.numberOfLessons;
    this.savingsPercentage = +json.savingsPercentage;
    this.fullPriceIfBuyingSingleLessonsInCents =
      +json.fullPriceIfBuyingSingleLessonsInCents;
    this.packages = json.packages ? json.packages : [];

    this.serviceFee = +json.serviceFee;
    this.schoolPaymentType = json.schoolPaymentType;
    this.schoolBIC = json.schoolBIC;
    this.schoolIBAN = json.schoolIBAN;

    this.type = json.type;

    this.offerPriceInCents = +json.offerPriceInCents;

    // take a guess when api does not deliver the packages
    if (this.packages.length === 0) {
      if (this.numberOfLessons === 18) {
        this.packages = ['A', 'B'];
      } else if (this.numberOfLessons === 14) {
        this.packages = ['B'];
      } else if (this.numberOfLessons === 16) {
        this.packages = ['A'];
      }
    }
  }

  get priceInCentsServiceFeeIncl(): number {
    return this.priceInCents + this.serviceFee;
  }

  get displayPrice(): string {
    return this.roundToUpTo2Decimals(this.priceInCents / 100.0);
  }

  get displayOfferPrice(): string {
    return this.roundToUpTo2Decimals(this.offerPriceInCents / 100.0);
  }

  get fullPriceIfBuyingSingleLessons(): string {
    return this.roundToUpTo2Decimals(
      this.fullPriceIfBuyingSingleLessonsInCents / 100.0
    );
  }

  get packageString(): string {
    return this.packages.join(', ');
  }

  private roundToUpTo2Decimals(val: number): string {
    return val.toFixed(2).replace('.00', '').replace('.', ',');
  }

  isPending(): boolean {
    return this.paymentStatus === PaymentStatus.PENDING;
  }

  isPaid(): boolean {
    return this.paymentStatus === PaymentStatus.PAID;
  }
}
