import { QuestionChoice } from '@models/question-choice.model';
import { Answer } from './question-answer.model';

export class ChoiceAnswer {
  choiceId: number;
  answers: number;

  constructor(json) {
    this.choiceId = +json.choiceId;
    this.answers = +json.answers;
  }
}

export class QuestionStatistics {
  questionId: number;

  timesSeen: number;

  choiceAnswers: ChoiceAnswer[];

  answers: Answer[];

  constructor(json) {
    this.questionId = +json.questionId;
    this.timesSeen = +json.timesSeen;

    this.choiceAnswers = json.choiceAnswers.map(it => new ChoiceAnswer(it));
    this.answers = json.answers?.map(it => new Answer(it)) || [];
  }

  get totalAnswersCount() {
    return this.choiceAnswers.reduce((sum, current) => sum + current.answers, 0);
  }

  getChoiceAnswers(choice: QuestionChoice) {
    const choiceAnswer = this.findChoiceAnswer(choice);
    if (!choiceAnswer) {
      return 0;
    }

    return choiceAnswer.answers;
  }

  choicePercentage(choice: QuestionChoice) {
    const choiceAnswer = this.findChoiceAnswer(choice);
    if (!choiceAnswer) {
      return 0;
    }

    return (choiceAnswer.answers / this.totalAnswersCount) * 100;
  }

  findChoiceAnswer(choice: QuestionChoice): ChoiceAnswer | undefined {
    return this.choiceAnswers.find(it => it.choiceId === choice.id);
  }
}
