import { Address } from '@models/address.model';

export class Branch {
  id: number;
  schoolId: number;
  addressId: number;
  address: Address;
  name: string;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.schoolId = +json.schoolId;
    this.addressId = +json.addressId;
    this.name = json.name;
  }

}
