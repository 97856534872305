export enum PaymentStatus {
  PAID = 'PAID',
  PENDING = 'PENDING',
  NOT_PAID = 'NOT_PAID',
  CANCELLATION_REFUNDED = 'CANCELLATION_REFUNDED',
  LATE_CANCELLATION_REFUNDED = 'LATE_CANCELLATION_REFUNDED',
  REFUNDED = 'REFUNDED',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  ARCHIVED = 'ARCHIVED',
  UNKNOWN = 'UNKNOWN',
}

export function getAllPaymentStatuses(): PaymentStatus[] {
  return [
    PaymentStatus.ARCHIVED,
    PaymentStatus.PAID,
    PaymentStatus.PENDING,
    PaymentStatus.NOT_PAID,
    PaymentStatus.REFUNDED,
    PaymentStatus.LATE_CANCELLATION_REFUNDED,
    PaymentStatus.PARTIALLY_PAID,
    PaymentStatus.UNKNOWN,
  ];
}

export function getDisplayName(status: PaymentStatus) {
  switch (status) {
    case PaymentStatus.PAID:
      return 'Bezahlt';
    case PaymentStatus.PENDING:
      return 'Bezahlung ausstehend';
    case PaymentStatus.NOT_PAID:
      return 'Unbezahlt';
    case PaymentStatus.CANCELLATION_REFUNDED:
      return 'Erstattet';
    case PaymentStatus.LATE_CANCELLATION_REFUNDED:
      return 'Teilweise rückerstattet';
    case PaymentStatus.REFUNDED:
      return 'Erstattet';
    case PaymentStatus.PARTIALLY_PAID:
      return 'Teilweise bezahlt';
    case PaymentStatus.ARCHIVED:
      return 'Archiviert';
    case PaymentStatus.UNKNOWN:
      return 'Unbekannt';
    default:
      return '';
  }
}
