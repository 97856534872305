import { Asset } from './asset.model';
import { UserInfo } from '@models/user-info.model';
import {FahrschuleJaegerSchool, School} from './school.model';
import { PickupLocation } from './location.model';
import { Car } from '@models/car.model';
import {
  TeamMemberDocument,
  TeamMemberDocumentType,
} from './team-member-document.model';
import { TeamMemberSeen, TeamMemberSeenType } from './team-member-seen.model';

export enum TeamMemberPosition {
  PRINCIPAL = 'PRINCIPAL',
  INSTRUCTOR = 'INSTRUCTOR',
  SECRETARY = 'SECRETARY',
  MARKETING = 'MARKETING',
  DISTRIBUTION = 'DISTRIBUTION',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  MANAGEMENT = 'MANAGEMENT',
  EDDY_SUPPORT = 'EDDY_SUPPORT',
}

export class TeamMember {
  id: number;

  userId?: number;
  user?: UserInfo;

  schoolId: number;
  school?: School;

  firstName: string;
  lastName: string;
  email: string;

  phoneNumber: string;
  position: TeamMemberPosition;
  description: string;
  displayInVipProfile: boolean;
  createUserAccount?: boolean;

  signature?: SignatureFile;
  deleteSignature?: boolean;

  isEddyChief: boolean;
  canViewAllTheoryLessonData: boolean;
  seenPriceAdjustmentPopup?: boolean;

  carId?: number;
  car?: Car;

  creationDate: Date;

  licenses: TeamMemberLicense[];

  image?: Asset;
  imagePath: string;
  locations: PickupLocation[];

  documents: TeamMemberDocument[];

  seens: TeamMemberSeen[];

  constructor(json?: any) {
    this.id = 0;

    if (!json) {
      return;
    }

    this.id = +json.id;
    if (json.userId) {
      this.userId = +json.userId;
      this.user = new UserInfo(json.user);
    }
    this.schoolId = +json.schoolId;
    if (json.school) {
      this.school = new School(json.school);
    }
    this.firstName = json.firstName;
    this.lastName = json.lastName;
    this.phoneNumber = json.phoneNumber;
    this.email = json.email;
    this.position = json.position;
    this.description = json.description;
    this.displayInVipProfile = !!json.displayInVipProfile;
    this.imagePath = json.imagePath;

    this.isEddyChief = !!json.isEddyChief;
    this.canViewAllTheoryLessonData = !!json.canViewAllTheoryLessonData;
    this.seenPriceAdjustmentPopup = json.seenPriceAdjustmentPopup ?? false;

    if (json.carId) {
      this.carId = +json.carId;
    }
    if (json.car) {
      this.car = new Car(json.car);
    }

    if (json.createUserAccount) {
      this.createUserAccount = !!json.createUserAccount;
    }

    this.creationDate = new Date(json.creationDate);

    this.licenses = json.licenses;

    if (json.locations) {
      this.locations = json.locations.map((it) => new PickupLocation(it));
    } else {
      this.locations = [];
    }

    if (json.documents) {
      this.documents = json.documents.map((it) => new TeamMemberDocument(it));
    } else {
      this.documents = [];
    }

    this.seens = json.seens;
  }

  isNew(): boolean {
    return !this.id;
  }

  hasUserAccount(): boolean {
    return !!this.userId;
  }

  getFirstName(): string {
    return this.user && this.user.firstName
      ? this.user.firstName
      : this.firstName;
  }

  getLastName(): string {
    return this.user && this.user.lastName ? this.user.lastName : this.lastName;
  }

  getFullName(): string {
    if (!this.getFirstName() || !this.getLastName()) {
      return '';
    }

    return `${this.getFirstName()} ${this.getLastName()}`;
  }

  getEmail(): string {
    return this.user && this.user.email ? this.user.email : this.email;
  }

  isInstructor(): boolean {
    return this.position === TeamMemberPosition.INSTRUCTOR;
  }

  isPrincipalOnly(): boolean {
    return this.position === TeamMemberPosition.PRINCIPAL;
  }

  isPrincipal(): boolean {
    return (
      this.position === TeamMemberPosition.PRINCIPAL ||
      this.position === TeamMemberPosition.EDDY_SUPPORT
    );
  }

  isManagement(): boolean {
    return this.position === TeamMemberPosition.MANAGEMENT;
  }

  isSecretary(): boolean {
    return this.position === TeamMemberPosition.SECRETARY;
  }

  isEddySupport(): boolean {
    return this.position === TeamMemberPosition.EDDY_SUPPORT;
  }

  isPrincipalOrManagement(): boolean {
    return this.isPrincipal() || this.isManagement();
  }

  isPrincipalOrManagementOrSecretaryOrEddySupport(): boolean {
    return (
      this.isPrincipalOrManagement() ||
      this.isSecretary() ||
      this.isEddySupport()
    );
  }

  isDrivingInstructorWithAccount(): boolean {
    return (
      (this.isInstructor() || this.isPrincipalOnly() || this.isManagement()) &&
      this.hasUserAccount()
    );
  }

  getShortenedName() {
    if (!this.getFirstName() || !this.getLastName()) {
      return '';
    }

    return `${this.getFirstName().charAt(0)}. ${this.getLastName()}`;
  }

  hasSignatureUploaded() {
    return (
      this.documents &&
      this.documents.find(
        (doc) => doc.type === TeamMemberDocumentType.SIGNATURE
      )
    );
  }

  getSignatureFileName() {
    const signature = this.hasSignatureUploaded();
    if (!signature && !this.signature) {
      return '';
    }

    if (this.signature) {
      return this.signature.name;
    }

    return `unterschrift_${this.user?.firstName.toLowerCase()}_${this.user?.lastName.toLowerCase()}.${
      signature.mimeTypeExtension
    }`;
  }

  hasSeen(type: TeamMemberSeenType): boolean {
    return this.seens && this.seens.find((seen) => seen.type === type)?.seen;
  }

  isFromJaegerSchool(): boolean {
    return this.schoolId === FahrschuleJaegerSchool;
  }
}

export interface TeamMemberLicense {
  id?: number;

  teamMemberId?: number;
  licenseId: number;
}

export function getTeamMemberPositions(): TeamMemberPosition[] {
  return [
    TeamMemberPosition.PRINCIPAL,
    TeamMemberPosition.INSTRUCTOR,
    TeamMemberPosition.SECRETARY,
    TeamMemberPosition.MARKETING,
    TeamMemberPosition.DISTRIBUTION,
    TeamMemberPosition.CUSTOMER_SERVICE,
    TeamMemberPosition.MANAGEMENT,
    TeamMemberPosition.EDDY_SUPPORT,
  ];
}

export function getTeamMemberPositionsForAccounting(): TeamMemberPosition[] {
  return [
    TeamMemberPosition.PRINCIPAL,
    TeamMemberPosition.SECRETARY,
    TeamMemberPosition.MANAGEMENT,
    TeamMemberPosition.EDDY_SUPPORT,
  ];
}

export function getDisplayName(position: TeamMemberPosition) {
  switch (position) {
    case TeamMemberPosition.PRINCIPAL:
      return 'Fahrschulleiter/in';
    case TeamMemberPosition.INSTRUCTOR:
      return 'Fahrlehrer/in';
    case TeamMemberPosition.SECRETARY:
      return 'Sekretär/in';
    case TeamMemberPosition.MARKETING:
      return 'Marketing';
    case TeamMemberPosition.DISTRIBUTION:
      return 'Vertrieb';
    case TeamMemberPosition.CUSTOMER_SERVICE:
      return 'Kundenservice';
    case TeamMemberPosition.MANAGEMENT:
      return 'Geschäftsleitung';
    case TeamMemberPosition.EDDY_SUPPORT:
      return 'Eddy Support';
    default:
      return '';
  }
}

export class SignatureFile {
  mimeType: string;
  content: string;
  name?: string;

  constructor(json?: any) {
    this.mimeType = json.mimeType;
    this.content = json.base64;
    if (json.name) {
      this.name = json.name;
    }
  }
}
