import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';
import { Injectable } from '@angular/core';

/** Interface for object which can store both:
 * An ActivatedRouteSnapshot, which is useful for determining whether or not you should attach a route (see this.shouldAttach)
 * A DetachedRouteHandle, which is offered up by this.retrieve, in the case that you do want to attach the stored route
 *
 * For more details look at https://stackoverflow.com/questions/41280471/how-to-implement-routereusestrategy-shoulddetach-for-specific-routes-in-angular?answertab=active#tab-top
 */
interface RouteStorageObject {
  snapshot: ActivatedRouteSnapshot;
  handle: DetachedRouteHandle;
}

@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
  NOT_DETACHED_ROUTES = [
    'dashboard',
    'fuehrerschein',
    'fuehrerschein/preise',
    'fahrschueler/fuehrerschein-anfrage',
    '/bookings',
    '/cars',
    '/instructors',
    '/internal',
    '/questions',
    '/learners',
    '/onlinetheorie',
    '/offers',
    '/settings',
  ];

  /**
   * Object which will store RouteStorageObjects indexed by keys
   * The keys will all be a path (as in this.getUrlKey(route))
   * This allows us to see if we've got a route stored for the requested path
   */
  storedRoutes: { [key: string]: RouteStorageObject } = {};

  /**
   * Decides when the route should be stored
   * If the route should be stored, I believe the boolean is indicating to a controller whether or not to fire this.store
   * _When_ it is called though does not particularly matter, just know that this determines whether or not we store the route
   * An idea of what to do here: check the this.getUrlKey(route) to see if it is a path you would like to store
   * @param route This is, at least as I understand it, the route that the user is currently on, and we would like to know if we want to store it
   * @returns boolean indicating that we want to (true) or do not want to (false) store that route
   */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return this.isDetachableRoute(route);
  }

  /**
   * Constructs object of type `RouteStorageObject` to store, and then stores it for later attachment
   * @param route This is stored for later comparison to requested routes, see `this.shouldAttach`
   * @param handle Later to be retrieved by this.retrieve, and offered up to whatever controller is using this class
   */
  store(
    route: ActivatedRouteSnapshot,
    handle: DetachedRouteHandle | null
  ): void {
    const storedRoute: RouteStorageObject = {
      snapshot: route,
      handle: handle,
    };

    if (handle === null) {
      // according to the interface documentation, if handle is null the stored route should be removed
      // https://github.com/angular/angular/blob/master/packages/router/src/route_reuse_strategy.ts#L48
      delete this.storedRoutes[this.getUrlKey(route)];
      return;
    }

    // routes are stored by path - the key is the path name, and the handle is stored under it so that you can only ever have one object stored for a single path
    this.storedRoutes[this.getUrlKey(route)] = storedRoute;
  }

  /**
   * Determines whether or not there is a stored route and, if there is, whether or not it should be rendered in place of requested route
   * @param route The route the user requested
   * @returns boolean indicating whether or not to render the stored route
   */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (!this.isDetachableRoute(route)) {
      return false;
    }

    // this will be true if the route has been stored before
    const canAttach: boolean =
      !!route.routeConfig && !!this.storedRoutes[this.getUrlKey(route)];

    // this decides whether the route already stored should be rendered in place of the requested route, and is the return value
    // at this point we already know that the paths match because the storedResults key is the this.getUrlKey(route)
    // so, if the route.params and route.queryParams also match, then we should reuse the component
    if (canAttach) {
      const paramsMatch: boolean = this.compareObjects(
        route.params,
        this.storedRoutes[this.getUrlKey(route)].snapshot.params
      );
      const queryParamsMatch: boolean = this.compareObjects(
        route.queryParams,
        this.storedRoutes[this.getUrlKey(route)].snapshot.queryParams
      );

      return paramsMatch && queryParamsMatch;
    } else {
      return false;
    }
  }

  /**
   * Finds the locally stored instance of the requested route, if it exists, and returns it
   * @param route New route the user has requested
   * @returns DetachedRouteHandle object which can be used to render the component
   */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    // return null if the path does not have a routerConfig OR if there is no stored route for that routerConfig
    if (!route.routeConfig || !this.storedRoutes[this.getUrlKey(route)]) {
      return null;
    }

    /** returns handle when the key is already stored */
    return this.storedRoutes[this.getUrlKey(route)].handle;
  }

  /**
   * Determines whether or not the current route should be reused
   * @param future The route the user is going to, as triggered by the router
   * @param curr The route the user is currently on
   * @returns boolean basically indicating true if the user intends to leave the current route
   */
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  /**
   * This nasty bugger finds out whether the objects are _traditionally_ equal to each other, like you might assume someone else would have put this function in vanilla JS already
   * One thing to note is that it uses coercive comparison (==) on properties which both objects have, not strict comparison (===)
   * Another important note is that the method only tells you if `compare` has all equal parameters to `base`, not the other way around
   * @param base The base object which you would like to compare another object to
   * @param compare The object to compare to base
   * @returns boolean indicating whether or not the objects have all the same properties and those properties are ==
   */
  private compareObjects(base: any, compare: any): boolean {
    // loop through all properties in base object
    for (const baseProperty in base) {
      // determine if comparrison object has that property, if not: return false
      if (compare.hasOwnProperty(baseProperty)) {
        switch (typeof base[baseProperty]) {
          // if one is object and other is not: return false
          // if they are both objects, recursively call this comparison function
          case 'object':
            if (
              typeof compare[baseProperty] !== 'object' ||
              !this.compareObjects(base[baseProperty], compare[baseProperty])
            ) {
              return false;
            }
            break;
          // if one is function and other is not: return false
          // if both are functions, compare function.toString() results
          case 'function':
            if (
              typeof compare[baseProperty] !== 'function' ||
              base[baseProperty].toString() !== compare[baseProperty].toString()
            ) {
              return false;
            }
            break;
          // otherwise, see if they are equal using coercive comparison
          default:
            if (base[baseProperty] !== compare[baseProperty]) {
              return false;
            }
        }
      } else {
        return false;
      }
    }

    // returns true only after false HAS NOT BEEN returned through all loops
    return true;
  }

  private routeToUrl(route: ActivatedRouteSnapshot): string {
    if (route.url) {
      if (route.url.length) {
        return route.url.join('/');
      } else {
        return '';
        // if (typeof route.component === 'function') {
        //   return `[${route.component.name}]`;
        // } else if (typeof route.component === 'string') {
        //   return `[${route.component}]`;
        // } else {
        //   return `[null]`;
        // }
      }
    } else {
      return '(null)';
    }
  }

  private getChildRouteKeys(route: ActivatedRouteSnapshot): string {
    const url = this.routeToUrl(route);
    return route.children.reduce(
      (fin, cr) => (fin += this.getChildRouteKeys(cr)),
      url
    );
  }

  // the route key needs to include the child routes as well
  // otherwise reusing for routes of lazy loaded modules won't work
  // see: https://stackoverflow.com/questions/41584664/error-cannot-reattach-activatedroutesnapshot-created-from-a-different-route
  private getUrlKey(route: ActivatedRouteSnapshot) {
    let url =
      route.pathFromRoot.map((it) => this.routeToUrl(it)).join('/') + '*';
    url += route.children.map((cr) => this.getChildRouteKeys(cr));

    return url;
  }

  private isDetachableRoute(route: ActivatedRouteSnapshot) {
    const path = this.getUrlKey(route);
    for (
      let counter = 0;
      counter < this.NOT_DETACHED_ROUTES.length;
      counter++
    ) {
      if (path.startsWith(this.NOT_DETACHED_ROUTES[counter])) {
        return false;
      }
    }

    return true;
  }
}
