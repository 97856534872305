import { Component, Input, OnInit } from '@angular/core';
import { License } from '../../../../models/license.model';
import { LicenseService } from '../../../../services/license.service';

@Component({
  selector: 'eddy-license-summary',
  templateUrl: './license-summary.component.html',
  styleUrls: ['./license-summary.component.scss']
})
export class LicenseSummaryComponent implements OnInit {

  @Input() id: number;

  public license: License;

  constructor(private licenseService: LicenseService) {
  }

  async ngOnInit() {
    this.license = await this.licenseService.getLicenseById(this.id).toPromise();
  }

}
