import { Injectable } from '@angular/core';

@Injectable()
export class InstructorsService {

  // constructor(private http: HttpClient,
  //             private authService: AuthService) {
  // }
  //
  //
  // addVendorInstructor(request: CreateVendorInstructorRequest) {
  //   return this.http.post<string>(`${env.api}/vendor/${request.vendor_id}/instructor`, request, this.authService.getHttpOptions())
  //     .pipe(
  //       map(res => res['data']),
  //       catchError(error => throwError(error))
  //     );
  // }
  //
  // getInstructorByID(_id: number) {
  //   return this.http.get<Instructor>(`${env.api}/instructor/${_id}`, this.authService.getHttpOptions())
  //     .pipe(
  //       map(res => res['data']),
  //       catchError(err => throwError(err))
  //     );
  // }
  //
  // getInstructors(_filters: Map<string, string>, _pageNumber, _pageSize) {
  //   const params = _filters.set('pageNumber', '' + _pageNumber).set('pageSize', '' + _pageSize);
  //
  //   return this.http.get<PageListingResponse>(`${env.api}/instructor`, this.authService.getHttpOptions(params))
  //     .pipe(
  //       map(res => res['data']),
  //       catchError(err => throwError(err))
  //     );
  // }
  //
  // deleteInstructor(_id: number) {
  //   return this.http.delete(`${env.api}/instructor/${_id}`, this.authService.getHttpOptions())
  //     .pipe(
  //       catchError(err => throwError(err))
  //     );
  // }
  //
  // updateInstructor(_instructor: Instructor) {
  //   return this.http.put<Instructor>(`${env.api}/instructor`, _instructor, this.authService.getHttpOptions())
  //     .pipe(
  //       map(res => res['data']),
  //       catchError(err => throwError(err))
  //     );
  // }
}
