import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@services/auth.service';
import { ForgotPasswordDialogComponent } from './forgot-password-dialog/forgot-password-dialog.component';
import { OkDialogComponent } from '@modules/shared/dialogs/ok-dialog/ok-dialog.component';
import { MetaService } from '@services/meta.service';
import { NotificationService } from '@services/notification.service';
import { emailValidator } from '@validators/email.validator';
import { SignUpDialog } from '../../dialogs/sign-up/sign-up.dialog';
import { Overlay } from '@angular/cdk/overlay';
import { ActivatedRoute, Router } from '@angular/router';

enum LoginType {
  SCHOOLS = 'SCHOOLS',
  STUDENTS = 'STUDENTS',
}

@Component({
  selector: 'eddy-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;

  accessDenied = false;

  selectedLoginType: LoginType = LoginType.SCHOOLS;
  showPassword: boolean;
  readonly instructorLink = 'https://eddyclub.de/home/du-bist-fahrlehrer/';

  loginIsInProgress = false;

  @ViewChild('email', { read: ElementRef, static: true })
  emailField: ElementRef;

  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private overlay: Overlay,
    private metaService: MetaService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.setMetaTags();
    this.getShouldOpenForgotPasswordDialog();
    this.getShouldOpenSignUpDialog();
  }

  private setMetaTags() {
    const title = 'Mit drivEddy bringst Du Deine Fahrschule ins Internet!';
    const description =
      'Trage hier die Daten Deiner Fahrschule ein und profitiere von unserem Gratis Online Profil ✅ Premium Service ✅ und unserer Fahrschul Software!';

    this.metaService.setMetatags(title, description);
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, emailValidator]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
    });
  }

  onLogin() {
    if (this.form.invalid) {
      return;
    }

    this.loginIsInProgress = true;

    this.authService.login(
      this.form.controls['email'].value,
      this.form.controls['password'].value,
      true,
      () => {
        this.loginIsInProgress = false;
      },
      (denied: any) => {
        this.loginIsInProgress = false;
        if (denied && denied.message === 'account_blocked') {
          this.notificationService.error(
            'Ihr Konto ist gesperrt. Bitte wenden Sie sich an das Support-Team.'
          );
        } else {
          this.notificationService.error(
            'Ungültiges Passwort oder E-Mail nicht registriert.'
          );

          this.accessDenied = true;
        }
      }
    );
  }

  async onForgotPassword() {
    const dialogRef = this.dialog.open(ForgotPasswordDialogComponent, {
      width: '480px',
      height: '345px',
      panelClass: 'custom-dialog-container',
      maxWidth: '99vw',
    });

    const email = await dialogRef.afterClosed().toPromise();
    if (email) {
      try {
        await this.authService.forgotPassword(email).toPromise();

        this.dialog.open(OkDialogComponent, {
          width: '400px',
          maxHeight: '550px',
          panelClass: 'custom-dialog-container',

          data: {
            message: `Wir haben Ihnen eine E-Mail an die angegebene Adresse geschickt. Bitte bestätigen Sie Ihre E-Mail und vergeben Sie ein neues Passwort.`,
          },
        });
      } catch (e) {
        this.snackBar.open(
          'Das Passwort konnte nicht zurückgesetzt werden. Bitte kontaktiere den Support.',
          'Ok',
          { duration: 4000 }
        );
      }
    }
  }

  onSelectSchoolsLogin() {
    this.selectedLoginType = LoginType.SCHOOLS;
  }

  onSelectStudentsLogin() {
    this.selectedLoginType = LoginType.STUDENTS;
  }

  openSignUpDialog() {
    const scrollStrategy = this.overlay.scrollStrategies.reposition();

    const dialogRef = this.dialog.open(SignUpDialog, {
      width: '420px',
      height: 'auto',
      maxWidth: '100%',
      panelClass: ['custom-dialog-container', 'event-dialog', 'sign-up-dialog'],
      scrollStrategy: scrollStrategy,
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  isEmailInvalid() {
    if (this.form.controls['email'].invalid) {
      if (
        this.emailField &&
        this.emailField.nativeElement &&
        this.emailField.nativeElement.value &&
        this.emailField.nativeElement.value !== this.form.value.email
      ) {
        this.form.controls['email'].setValue(
          this.emailField.nativeElement.value
        );
        this.changeDetectorRef.detectChanges();
      }
    }
    return (
      this.form.controls['email'].touched && this.form.controls['email'].invalid
    );
  }

  updateEmail(newValue: string) {
    if (newValue) {
      this.form.controls['email'].setValue(newValue);
    }
  }

  get isFormInvalid(): boolean {
    return this.form && this.form.touched && this.form.invalid;
  }

  getShouldOpenForgotPasswordDialog() {
    const queryParamMap = this.route.snapshot.queryParamMap;
    if (queryParamMap.get('forgotPassword') === 'true') {
      this.onForgotPassword();
    }
  }

  getShouldOpenSignUpDialog() {
    if (this.route.snapshot.data?.breadcrumb === 'register') {
      this.openSignUpDialog();
    }
  }
}
