export class LearnerAddress {
  id: number;
  learnerId: number;

  street: string;
  streetNumber: string;
  postalCode: string;
  locality: string;

  country?: string;
  federalState?: string;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.learnerId = +json.learnerId;

    this.street = json.street;
    this.streetNumber = json.streetNumber;
    this.postalCode = json.postalCode;
    this.locality = json.locality;

    if (json.country) {
      this.country = json.country;
    }
    if (json.federalState) {
      this.federalState = json.federalState;
    }
  }

  isFullyFilled(): boolean {
    return (
      !!this.street &&
      !!this.streetNumber &&
      !!this.postalCode &&
      !!this.locality
    );
  }

  isPartiallyFilled(): boolean {
    return (
      !!this.street ||
      !!this.streetNumber ||
      !!this.postalCode ||
      !!this.locality
    );
  }

  toString(addHTMLLineBreakBeforePostalCode?: boolean) {
    if (addHTMLLineBreakBeforePostalCode) {
      let address = `${this.street} ${this.streetNumber},<br/> ${this.postalCode} ${this.locality}`;
      if (this.federalState) {
        address += `<br/>${this.federalState}`;
      }
      if (this.country) {
        address += `<br/>${this.country}`;
      }

      return address;
    }

    return `${this.street} ${this.streetNumber}, ${this.postalCode} ${this.locality}`;
  }

  get streetWithNumber() {
    return `${this.street}, ${this.streetNumber}`;
  }

  get zipCodeAndLocality() {
    return `${this.postalCode}, ${this.locality}`;
  }
}
