<div class="uploader-main-container"
     [class.two-one]="aspectRatio == 2" [style.height.px]="height" [style.width.px]="width"
     [class.fluid-width]="fluidWidth"
     [class.has-image]="hasImage"
     [class.border-radius-5]="borderRadius == 5"
     [class.border-radius-15]="borderRadius == 15"
>
  <span class="delete-icon" (click)="onDeleteFile($event)" *ngIf="displayDeleteIcon && (hasDocument || hasImage)">
    <i class="fal fa-times"></i>
  </span>
  <div [style.height.px]="height" class="full-width">
    <ngx-file-drop headertext="" (onFileDrop)="dropped($event)" [style.height.px]="height" class="full-width"
                   [class.no-logo-view]="logoViewType === 'REGULAR'"
                   [class.cover-image-view]="logoViewType === 'COVER_IMAGE'"
                   [class.image-logo-view]="logoViewType === 'IMAGE_LOGO' || logoViewType === 'TEXT_LOGO'"
    >
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">

        <input type="file" (change)="fileChangeEvent($event)" class="hidden-input"
               accept="{{fileTypes}}"/>
        <ng-container *ngIf="logoViewType === 'COVER_IMAGE'">
          <img class="cover-image" [src]="coverImage"/>
        </ng-container>
        <ng-container *ngIf="logoViewType === 'REGULAR'">
          <div *ngIf="!hasImage" class="asset-upload d-flex-center">
            <i class="fal fa-plus"></i>
          </div>
        </ng-container>
        <ng-container *ngIf="logoViewType === 'IMAGE_LOGO' && !hasImage">
          <img class="upload-photo" src="/assets/upload-photo.png"/>
          <div class="upload-label">Bild hochladen</div>
        </ng-container>

        <ng-container *ngIf="logoViewType === 'TEXT_LOGO' && !hasImage">
          <img class="upload-photo" src="/assets/upload-logo.png"/>
          <div class="upload-label text-logo-upload-label">Logo hochladen</div>
        </ng-container>

        <div *ngIf="hasImage" [style.height.px]="height" class="full-width">
          <img *ngIf="fileBase64" class="image-preview" [src]="fileBase64"/>
          <img *ngIf="!fileBase64 && selectedFile && selectedFile.path" class="image-preview"
               [src]="selectedFile.path"/>
        </div>
      </ng-template>
    </ngx-file-drop>
    <ng-container *ngIf="hasImage && !displayDeleteIcon">
      <div class="edit-icon-container">
        <i class="edit-icon fal fa-pen"></i>
      </div>
    </ng-container>
  </div>
</div>
