import { catchError, flatMap, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { TheoryLessonTopic } from '@models/theory-lesson-topic.model';
import { TheoryLesson } from '@models/theory-lesson.model';
import { DateUtils } from '@services/date-utils.service';
import {
  convertTimeRangesToStrings,
  convertTimeRangeTimesToStrings,
} from '@models/time-range.model';
import { EditTheoryLessonRequest } from '@models/edit-theory-lesson-request.model';
import { Time, toString as timeToString } from '@models/time.model';
import { TheoryLessonAttendee } from '@models/theory-lesson-attendee.model';
import { TheoryTopicAttendee } from '@models/theory-topic-attendee.model';
import { ChatMessage } from '@models/chat-message.model';
import { AddTheoryLessonRequest } from '@models/add-theory-lesson-request.model';
import { RecurringEventPerDayRequest } from '@models/recurring-event-per-day-request.model';
import { Learner } from '@models/learner.model';
import { PageListingResponse } from '@models/page-listing-response.model';
import { LearnerTheoryLesson } from '@models/learner-theory-lesson.model';
import { TheoryPackage } from '@models/theory-package.model';
import { Ratings } from '@models/ratings.model';
import { DeleteTheoryLessonRequest } from '@models/delete-theory-lesson-request.model';
import { ConfirmAttendeePresenceRequest } from '@models/confirm-attendee-presence-request.model';
import { TheoryLessonPresenceConfirmation } from '@models/theory-lesson-presence-confirmation.model';

import { saveAs as importedSaveAs } from 'file-saver';
import { NotificationService } from './notification.service';
import { OnlineLicenseInfo } from '@models/license.model';
import { AdditionalTheoryLessonAttendance } from '@models/additional_theory_lesson_attendance.model';
import { InstructorTheoryLesson } from '@models/instructor-theory-lesson.model';
import { InstructorTheoryLessonsPackage } from '@models/instructor-theory-lessons-package.model';
import { TheoryLessonVideoAttendee } from '@models/theory-lesson-video-attendee.model';
import { TheoryPackageType } from '@models/theory-package-type.model';

export const errInvalidCapacityDecrease =
  'bad request - cannot decrease the capacity lower then the number of attendees';

export const ONLINE_THEORY_UTM_KEY = 'onlineTheoryUTM';

@Injectable()
export class TheoryLessonService {
  private readonly basePath = `${env.apiSchoolService}`;

  private cachedTheoryTopics: TheoryLessonTopic[];

  constructor(
    private api: ApiService,
    private notificationService: NotificationService
  ) {
    this.cachedTheoryTopics = [];
  }

  getSchoolTheoryTopics(schoolId: number): Observable<TheoryLessonTopic[]> {
    return this.api
      .get<TheoryLessonTopic[]>(
        `${this.basePath}/schools/${schoolId}/theory_lesson_topics`
      )
      .pipe(map((res) => this.mapTheoryLessonTopics(res)));
  }

  getAllTheoryTopics(): Observable<TheoryLessonTopic[]> {
    if (this.cachedTheoryTopics && this.cachedTheoryTopics.length) {
      return of(this.cachedTheoryTopics);
    }

    return this.api
      .get<TheoryLessonTopic[]>(`${this.basePath}/theory_lesson_topics`)
      .pipe(
        map((res) => this.mapTheoryLessonTopics(res)),
        tap((it) => (this.cachedTheoryTopics = it)),
        tap((it) => this.validateTopicsData(it))
      );
  }

  getLicenseABTheoryTopics(
    doNotReloadData?: boolean
  ): Observable<TheoryLessonTopic[]> {
    return this.getLicenseTheoryTopics(['A', 'B'], doNotReloadData);
  }

  getLicenseBTheoryTopics(
    doNotReloadData?: boolean
  ): Observable<TheoryLessonTopic[]> {
    return this.getLicenseTheoryTopics(['B'], doNotReloadData);
  }

  private getLicenseTheoryTopics(
    licenses: string[],
    doNotReloadData?: boolean
  ): Observable<TheoryLessonTopic[]> {
    if (this.cachedTheoryTopics && this.cachedTheoryTopics.length) {
      return of(
        this.extractLicenseTheoryTopics(licenses, this.cachedTheoryTopics)
      );
    }

    if (doNotReloadData) {
      return throwError('the theory topics do not exist');
    }

    // reload the data and search again for the needed theory topics
    return this.getAllTheoryTopics().pipe(
      flatMap(() => this.getLicenseTheoryTopics(licenses, true))
    );
  }

  getTheoryTopic(
    key: string,
    doNotReloadData?: boolean
  ): Observable<TheoryLessonTopic> {
    const theoryTopic = this.getCachedTheoryTopic(key);
    if (theoryTopic) {
      return of(theoryTopic);
    }

    if (doNotReloadData) {
      return throwError('the theory topic does not exist: ' + key);
    }

    // reload the data and search again for the needed theory topic
    return this.getAllTheoryTopics().pipe(
      flatMap(() => this.getTheoryTopic(key, true))
    );
  }

  private mapTheoryLessonTopics(res: TheoryLessonTopic[]): TheoryLessonTopic[] {
    return res.map((it) => new TheoryLessonTopic(it));
  }

  saveTheoryLesson(request: AddTheoryLessonRequest): Observable<TheoryLesson> {
    const req: AddTheoryLessonRequest = JSON.parse(JSON.stringify(request));

    req.startDate = DateUtils.dateOnlyToString(<Date>request.startDate);
    req.timeRange = convertTimeRangeTimesToStrings(req.timeRange);
    req.utcOffsetInMinutes = (<Date>request.startDate).getTimezoneOffset();

    return this.api
      .post(`${this.basePath}/theory_lessons`, req)
      .pipe(map((it) => new TheoryLesson(it)));
  }

  saveTheoryRecurringLessonEvents(
    request: RecurringEventPerDayRequest
  ): Observable<TheoryLesson[]> {
    const req = JSON.parse(JSON.stringify(request));

    req.startDate = DateUtils.dateOnlyToString(<Date>request.startDate);
    req.endDate = DateUtils.dateOnlyToString(<Date>request.endDate);
    req.utcOffsetInMinutes = (<Date>request.startDate).getTimezoneOffset();

    req.timeRangesPerDay = {};
    for (const entry of Array.from(request.timeRangesPerDay.entries())) {
      const day = entry[0];
      const timeRanges = entry[1];

      req.timeRangesPerDay[day] = convertTimeRangesToStrings(timeRanges);
    }

    return this.api
      .post(`${this.basePath}/theory_recurring_lesson_events`, req)
      .pipe(map((res) => this.mapTheoryLessons(res)));
  }

  private mapTheoryLessons(res: TheoryLesson[]) {
    return res.map((it) => new TheoryLesson(it));
  }

  downloadAttendeesFile(
    lessonId: number,
    schoolId?: number,
    includeLearnerId?: number
  ): Observable<Blob> {
    const params = new Map<string, string>();
    if (schoolId) {
      params.set('schoolId', schoolId.toString());
    }
    if (includeLearnerId) {
      params.set('learnerId', includeLearnerId.toString());
    }
    return this.api.getBlob(
      `${this.basePath}/theory_lessons/${lessonId}/attendees_document`,
      params
    );
  }

  downloadAttendeesFilesInBulk(lessonIds: number[]): Observable<any> {
    const filters = new Map<string, string>();
    filters.set('lessons', lessonIds.join(','));
    return this.api
      .getBlob(
        `${this.basePath}/theory_lessons/attendees_document_bulk_export`,
        filters
      )
      .pipe(
        tap((blob: Blob) => importedSaveAs(blob, `teilnehmerlisten.zip`)),
        catchError((err) => {
          this.notificationService.loadError();
          return of(err);
        })
      );
  }

  downloadTheoryLessonProofForLearner(
    theoryLessonAttendeeId: number
  ): Observable<Blob> {
    return this.api.getBlob(
      `${this.basePath}/theory_lesson_attendees/${theoryLessonAttendeeId}/learner_proof`
    );
  }

  deleteTheoryLesson(
    lessonId: number,
    req: DeleteTheoryLessonRequest
  ): Observable<void> {
    return this.api.post(`${this.basePath}/theory_lessons/${lessonId}`, req);
  }

  getTheoryLesson(id: number): Observable<TheoryLesson> {
    return this.api
      .get(`${this.basePath}/theory_lessons/${id}`)
      .pipe(map((it) => new TheoryLesson(it)));
  }

  updateTheoryLesson(request: EditTheoryLessonRequest): Observable<void> {
    const req: EditTheoryLessonRequest = JSON.parse(JSON.stringify(request));
    req.startDate = DateUtils.dateOnlyToString(<Date>request.startDate);
    req.startTime = timeToString(<Time>request.startTime, true);
    req.endTime = timeToString(<Time>request.endTime, true);
    req.utcOffsetInMinutes = (<Date>request.startDate).getTimezoneOffset();
    return this.api.put(`${this.basePath}/theory_lessons/${request.id}`, req);
  }

  createLessonPresenceConfirmations(theoryLessonId: number): Observable<void> {
    return this.api.post(
      `${this.basePath}/theory_lessons/${theoryLessonId}/createLessonPresenceConfirmations`,
      {}
    );
  }

  getLessonPresenceConfirmations(
    lessonId: number
  ): Observable<TheoryLessonPresenceConfirmation[]> {
    return this.api.post<TheoryLessonPresenceConfirmation[]>(
      `${this.basePath}/theory_lessons/${lessonId}/getLessonPresenceConfirmations`,
      {}
    );
  }

  confirmAttendeePresence(
    req: ConfirmAttendeePresenceRequest
  ): Observable<void> {
    return this.api.post(
      `${this.basePath}/theory_lessons/confirmAttendeePresence`,
      req
    );
  }

  confirmLessonWatchedOrPresenceFailure(
    theoryLessonId: number
  ): Observable<void> {
    return this.api.post(
      `${this.basePath}/theory_lessons/${theoryLessonId}/confirm_lesson_watched`,
      {}
    );
  }

  getAttendees(
    lessonId: number,
    schoolId?: number
  ): Observable<TheoryLessonAttendee[]> {
    const params = new Map<string, string>();
    if (schoolId) {
      params.set('schoolId', schoolId.toString());
    }
    return this.api
      .get<TheoryLessonAttendee[]>(
        `${this.basePath}/theory_lessons/${lessonId}/attendees`,
        params
      )
      .pipe(map((res) => this.mapAttendees(res)));
  }

  private mapAttendees(res: TheoryLessonAttendee[]): TheoryLessonAttendee[] {
    return res.map((it) => new TheoryLessonAttendee(it));
  }

  getOfficialAttendeesUserIds(lessonId: number): Observable<number[]> {
    return this.api.get<number[]>(
      `${this.basePath}/theory_lessons/${lessonId}/official_attendees_user_ids`
    );
  }

  confirmAttendance(attendee: TheoryLessonAttendee): Observable<void> {
    return this.api.post(
      `${this.basePath}/theory_lesson_attendees/${attendee.id}/confirm_attendance`,
      {
        TheoryLessonAttendeeID: attendee.id,
        learnerLessonRating: attendee.learnerLessonRating,
        learnerFeedback: attendee.learnerFeedback,
      }
    );
  }

  confirmPresence(attendee: TheoryLessonAttendee): Observable<void> {
    return this.api.post(
      `${this.basePath}/theory_lesson_attendees/${attendee.id}/confirm_presence`,
      {}
    );
  }

  confirmPresenceFailure(attendee: TheoryLessonAttendee): Observable<void> {
    return this.api.post(
      `${this.basePath}/theory_lesson_attendees/${attendee.id}/confirm_presence_failure`,
      {}
    );
  }

  confirmLessonWatched(attendee: TheoryLessonAttendee): Observable<void> {
    return this.api.post(
      `${this.basePath}/theory_lesson_attendees/${attendee.id}/confirm_lesson_watched`,
      {}
    );
  }

  revertConfirmAttendance(attendee: TheoryLessonAttendee): Observable<void> {
    return this.api.post(
      `${this.basePath}/theory_lesson_attendees/${attendee.id}/revert_confirm_attendance`,
      {}
    );
  }

  setAttendeeRemoved(attendee: TheoryLessonAttendee): Observable<void> {
    return this.api.post(
      `${this.basePath}/theory_lesson_attendees/${attendee.id}/set_removed`,
      {}
    );
  }

  markVideoCurrentTime(
    attendee: TheoryLessonAttendee,
    videoCurrentTime: number
  ): Observable<void> {
    return this.api.post(
      `${this.basePath}/theory_lesson_attendees/${attendee.id}/video_current_time`,
      {
        videoCurrentTime: videoCurrentTime,
      }
    );
  }

  deleteTheoryLessonAttendee(attendee: TheoryLessonAttendee): Observable<void> {
    return this.api.delete(
      `${this.basePath}/theory_lesson_attendees/${attendee.id}`
    );
  }

  confirmTopicAttendance(attendee: TheoryTopicAttendee): Observable<void> {
    return this.api.post(`${this.basePath}/theory_lesson_attendees`, attendee);
  }

  saveTheoryTopicAttendee(
    theoryTopicAttendee: TheoryTopicAttendee
  ): Observable<TheoryTopicAttendee> {
    return this.api
      .post(`${this.basePath}/theory_topic_attendees`, theoryTopicAttendee)
      .pipe(map((it) => new TheoryTopicAttendee(it)));
  }

  deleteTheoryTopicAttendee(
    theoryTopicAttendeeId: number
  ): Observable<TheoryTopicAttendee> {
    if (!theoryTopicAttendeeId) {
      return throwError('the theoryTopicAttendeeId must be set');
    }

    return this.api.delete(
      `${this.basePath}/theory_topic_attendees/${theoryTopicAttendeeId}`
    );
  }

  sendChatMessage(
    theoryLesson: TheoryLesson,
    message: string
  ): Observable<ChatMessage> {
    return this.api
      .post(
        `${this.basePath}/theory_lessons/${theoryLesson.id}/chat_messages`,
        { message }
      )
      .pipe(map((it) => new ChatMessage(it)));
  }

  getAllChatMessages(
    theoryLesson: TheoryLesson,
    dateFrom?: Date
  ): Observable<ChatMessage[]> {
    const params = new Map<string, string>();
    if (dateFrom) {
      params.set('dateFrom', '' + dateFrom.toISOString());
    }

    return this.api
      .get(
        `${this.basePath}/theory_lessons/${theoryLesson.id}/chat_messages`,
        params
      )
      .pipe(map((it) => this.mapChatMessages(it)));
  }

  private mapChatMessages(res: ChatMessage[]): ChatMessage[] {
    return res.map((it) => new ChatMessage(it));
  }

  private getCachedTheoryTopic(key: string): TheoryLessonTopic | undefined {
    return this.cachedTheoryTopics.find(
      (it) => it.key.toLowerCase() === key.toLowerCase()
    );
  }

  private extractLicenseTheoryTopics(
    licenses: string[],
    topics: TheoryLessonTopic[]
  ): TheoryLessonTopic[] {
    return topics.filter((it) => {
      const key = it.key.toLowerCase();
      return (
        key.indexOf('g') !== -1 ||
        !!licenses.find((itt) => key.indexOf(itt.toLowerCase()) !== -1)
      );
    });
  }

  getOnlineTheoryLessons(
    topic: TheoryLessonTopic,
    learnerId?: number,
    startDate?: Date,
    endDate?: Date,
    past = false,
    ownLessonsOnly = false,
    includeOfflineLessons = false
  ): Observable<TheoryLesson[]> {
    if (!startDate || !endDate) {
      if (past) {
        endDate = new Date();
        startDate = DateUtils.subtractDays(endDate, 180);
      } else {
        startDate = new Date();
        endDate = DateUtils.addDays(startDate, 90);
      }
    }

    let queryParams: Map<string, string> = new Map()
      .set('startDate', startDate.toISOString())
      .set('endDate', endDate.toISOString())
      .set('onlineLessonsOnly', includeOfflineLessons ? 'false' : 'true')
      .set('ownLessonsOnly', ownLessonsOnly);

    if (learnerId) {
      queryParams = queryParams.set('learnerId', '' + learnerId);
    }
    if (topic !== null) {
      queryParams = queryParams.set('topicKey', topic.key);
    }

    return this.api
      .get(`${this.basePath}/theory_lessons`, queryParams)
      .pipe(map((res) => this.mapTheoryLessons(res)));
  }

  getOnlineTheoryLessonsPaged(
    filters: Map<string, string>,
    pageNumber: number,
    pageSize: number
  ): Observable<PageListingResponse<TheoryLesson>> {
    return this.api
      .getPaged(
        `${this.basePath}/theory_lessons/paged`,
        pageNumber,
        pageSize,
        filters
      )
      .pipe(map((res) => this.mapOnlineTheoryLessonsPaged(res)));
  }

  private mapOnlineTheoryLessonsPaged(
    res: PageListingResponse
  ): PageListingResponse<TheoryLesson> {
    return {
      total: res.total,
      records: res.records.map((it) => new TheoryLesson(it)),
    };
  }

  saveLoggedLearnerTheoryLessonAttendance(
    lesson: TheoryLesson,
    learnerId: number = 0,
    markAsWatched: boolean = false
  ): Observable<any> {
    const lessonChangeMarkAsWatched = {
      LearnerID: learnerId,
      markAsWatched: markAsWatched,
    };
    return this.api.post(
      `${this.basePath}/theory_lessons/${lesson.id}/learners`,
      lessonChangeMarkAsWatched
    );
  }

  deleteLoggedLearnerTheoryLessonAttendance(
    lesson: TheoryLesson
  ): Observable<any> {
    return this.api.delete(
      `${this.basePath}/theory_lessons/${lesson.id}/learners`
    );
  }

  deleteLearnerTheoryLessonAttendance(
    lesson: TheoryLesson,
    learnerId: number
  ): Observable<any> {
    return this.api.delete(
      `${this.basePath}/theory_lessons/${lesson.id}/learners/${learnerId}`
    );
  }

  markAdditionalLessonAsWatched(
    topic: TheoryLessonTopic
  ): Observable<AdditionalTheoryLessonAttendance> {
    return this.api
      .post(
        `${this.basePath}/additional_theory_lesson_topics/${topic.key}/mark_watched`,
        {}
      )
      .pipe(map((it) => new AdditionalTheoryLessonAttendance(it)));
  }

  rateAdditionalLessonAttendance(
    attendance: AdditionalTheoryLessonAttendance
  ): Observable<void> {
    return this.api.post(
      `${this.basePath}/additional_theory_lesson_attendances/${attendance.id}/rate_attendance`,
      {
        rating: attendance.rating,
        feedback: attendance.feedback,
      }
    );
  }

  downloadAdditionalTheoryLessonProofForLearner(
    attendanceId: number
  ): Observable<Blob> {
    return this.api.getBlob(
      `${this.basePath}/additional_theory_lesson_attendances/${attendanceId}/learner_proof`
    );
  }

  markLearnerTheoryVideoCurrentTime(
    topicKey: string,
    videoCurrentTime: number
  ): Observable<void> {
    return this.api.post(
      `${this.basePath}/theory_lesson_video_topics/${topicKey}/video_current_time`,
      {
        videoCurrentTime: videoCurrentTime,
      }
    );
  }

  markLearnerWatchedTheoryLessonVideo(topicKey: string): Observable<void> {
    return this.api.post(
      `${this.basePath}/theory_lesson_video_topics/${topicKey}/mark_lesson_watched`,
      {}
    );
  }

  rateTheoryLessonVideo(attendee: TheoryLessonVideoAttendee): Observable<void> {
    return this.api.post(
      `${this.basePath}/theory_lesson_video_attendees/${attendee.id}/confirm_attendance`,
      {
        rating: attendee.rating,
        feedback: attendee.feedback,
      }
    );
  }

  downloadTheoryLessonVideoProofForLearner(
    attendeeId: number
  ): Observable<Blob> {
    return this.api.getBlob(
      `${this.basePath}/theory_lesson_video_attendees/${attendeeId}/learner_proof`
    );
  }

  isOnlineTheoryRecognized(postalCode: string): Observable<boolean> {
    const queryParams = new Map<string, string>();
    queryParams.set('postalCode', postalCode);
    return this.api
      .get(`${this.basePath}/online_theory_lessons_recognized`, queryParams)
      .pipe(map((it) => !!it));
  }

  isTheoryPackagePaid(learnerKey: string): Observable<boolean> {
    return this.api
      .get(`${env.paymentRootLink}/theorypackages/${learnerKey}/isPaid`)
      .pipe(map((it) => !!it));
  }

  buildTheoryPackagePaymentLink(
    learner: Learner,
    isFromWelcomeScreen?: boolean,
    baseUrl?: string
  ): string {
    const utmParamsString = this.buildUTMParamsString(
      this.getOnlineTheoryUTMParameters()
    );
    let url = `${env.paymentRootLink}/theorypackages/${
      learner.learnerTheoryPackage?.key
    }?redirectUrl=${this.getRedirectUrl(
      utmParamsString,
      false,
      isFromWelcomeScreen,
      baseUrl
    )}`;

    if (utmParamsString) {
      url = `${url}&${utmParamsString}`;
    }

    return url;
  }

  buildTheoryPackagePaymentLinkByKey(
    theoryPackageKey: string,
    offerKey?: string
  ): string {
    const utmParamsString = this.buildUTMParamsString(
      this.getOnlineTheoryUTMParameters()
    );
    let url = `${env.paymentRootLink}/theorypackages/${theoryPackageKey}`;

    if (offerKey) {
      url = `${url}?offerKey=${offerKey}`;
    }

    if (utmParamsString) {
      url = `${url}&${utmParamsString}`;
    }

    return url;
  }

  buildBasicFeePaymentLink(
    learnerLicenseKey: string,
    isFromWelcomeScreen?: boolean,
    baseUrl?: string
  ): string {
    const utmParamsString = this.buildUTMParamsString(
      this.getOnlineTheoryUTMParameters()
    );
    let url = `${
      env.paymentRootLink
    }/basicfees/${learnerLicenseKey}?redirectUrl=${this.getRedirectUrl(
      utmParamsString,
      false,
      isFromWelcomeScreen,
      baseUrl
    )}`;

    if (utmParamsString) {
      url = `${url}&${utmParamsString}`;
    }

    return url;
  }

  buildTheoryLessonPaymentLink(
    selectedLearnerTheoryLesson: LearnerTheoryLesson
  ): string {
    const utmParamsString = this.buildUTMParamsString(
      this.getOnlineTheoryUTMParameters()
    );
    let url = `${selectedLearnerTheoryLesson.paymentLink}`;

    if (utmParamsString) {
      url = `${url}?${utmParamsString}`;
    }
    return url;
  }

  private getRedirectUrl(
    utmParamsString?: string,
    isSingleLessonPayment?: boolean,
    isFromWelcomeScreen?: boolean,
    baseUrl?: string
  ) {
    const base = baseUrl || window.location.href;

    const paymentSuccessParam = isSingleLessonPayment
      ? 'lessonpayment=success'
      : 'payment=success';

    let url = base;
    if (base.indexOf('?') !== -1) {
      url = url.replace('?', `?${paymentSuccessParam}&`);
    } else {
      url = `${url}?${paymentSuccessParam}`;
    }

    if (utmParamsString) {
      url = `${url}&${utmParamsString}`;
    }

    if (isFromWelcomeScreen) {
      url = `${url}&fromwelcome=true`;
    }

    return encodeURIComponent(url);
  }

  private buildUTMParamsString(utmParams): string {
    if (!utmParams) {
      return '';
    }

    let result = '';
    for (const key in utmParams) {
      if (utmParams.hasOwnProperty(key)) {
        const sign = result ? '&' : '';
        result = `${result}${sign}${key}=${utmParams[key]}`;
      }
    }

    return result;
  }

  getOnlineTheoryAttendees(
    schoolId: number,
    pageNumber: number,
    pageSize: number
  ): Observable<PageListingResponse<TheoryLessonAttendee>> {
    return this.api
      .getPaged(
        `${this.basePath}/schools/${schoolId}/online_theory_attendees`,
        pageNumber,
        pageSize
      )
      .pipe(map(this.mapOnlineTheoryAttendeesPaged));
  }

  private mapOnlineTheoryAttendeesPaged(
    res: PageListingResponse
  ): PageListingResponse<TheoryLessonAttendee> {
    return {
      total: res.total,
      records: res.records.map((it) => new TheoryLessonAttendee(it)),
    };
  }

  private validateTopicsData(topics: TheoryLessonTopic[]) {
    setTimeout(() => {
      for (const topic of topics) {
        if (topic.key.indexOf('G') === -1 && topic.key.indexOf('B') === -1) {
          continue;
        }
        if (!topic.isVideoURLValid()) {
          throw new Error(
            'invalid video url for topic: ' + topic.key + ' - ' + topic.videoUrl
          );
        }
      }
    });
  }

  storeOnlineTheoryUTMParameters(utmParameters) {
    if (!window?.localStorage) {
      return;
    }

    localStorage.setItem(ONLINE_THEORY_UTM_KEY, JSON.stringify(utmParameters));
  }

  getOnlineTheoryUTMParameters() {
    const currentUrlUTMParams = this.currentUrlUTMParams();
    if (currentUrlUTMParams) {
      return currentUrlUTMParams;
    }

    if (!window?.localStorage) {
      return {};
    }

    return JSON.parse(localStorage.getItem(ONLINE_THEORY_UTM_KEY));
  }

  private currentUrlUTMParams() {
    if (!window?.location?.href) {
      return null;
    }

    try {
      const url = new URL(window.location.href);
      const urlParams: URLSearchParams = new URLSearchParams(url.search);

      const utmParams = {};
      urlParams.forEach((value, key) => {
        if (key.startsWith('utm')) {
          utmParams[key] = value;
        }
      });

      return utmParams;
    } catch (e) {
      return null;
    }
  }

  getOnlineTheoryRatings(): Observable<Ratings> {
    return this.api
      .get(`${this.basePath}/online_theory_ratings`)
      .pipe(map((it) => new Ratings(it)));
  }

  getLearnerTheoryPackage(
    learnerId: number,
    theoryPackageType?: TheoryPackageType
  ): Observable<TheoryPackage> {
    const queryParams: Map<string, string> = new Map();

    if (theoryPackageType) {
      queryParams.set('packageType', theoryPackageType);
    }

    return this.api
      .get(`${this.basePath}/learners/${learnerId}/theory_package`, queryParams)
      .pipe(map((it) => new TheoryPackage(it)));
  }

  getLearnerTheoryPackages(learnerId: number): Observable<TheoryPackage[]> {
    return this.api
      .get(`${this.basePath}/learners/${learnerId}/theory_packages`)
      .pipe(map(this.mapLearnerTheoryPackages));
  }

  private mapLearnerTheoryPackages(res: TheoryPackage[]): TheoryPackage[] {
    if (!res) {
      return [];
    }
    return res.map((it) => new TheoryPackage(it));
  }

  getLearnerLicenseLessonsInfo(
    learnerId: number,
    licenseKey?: string
  ): Observable<OnlineLicenseInfo> {
    const queryParams: Map<string, string> = new Map();

    if (licenseKey) {
      queryParams.set('licenseKey', encodeURIComponent(licenseKey));
    }

    return this.api
      .get(
        `${this.basePath}/learners/${learnerId}/license_lessons_info`,
        queryParams
      )
      .pipe(map((it) => new OnlineLicenseInfo(it)));
  }

  saveOnlineTheoryChatQuestion(
    lessonId: number,
    videoCurrentTime: number,
    question: string
  ): Observable<any> {
    const body = {
      videoCurrentTime: videoCurrentTime,
      question: question,
    };
    return this.api.post(
      `${env.apiSchoolService}/theory_lessons/${lessonId}/chat_questions`,
      body
    );
  }

  // instructor theory videos requests
  getInstructorTheoryLessons(
    instructorId: number
  ): Observable<InstructorTheoryLesson[]> {
    return this.api
      .get<InstructorTheoryLesson[]>(
        `${this.basePath}/team_members/${instructorId}/theory_lessons`
      )
      .pipe(map((it) => this.mapInstructorTheoryLessons(it)));
  }

  private mapInstructorTheoryLessons(list: InstructorTheoryLesson[]) {
    if (!list) {
      return [];
    }
    return list.map((it) => new InstructorTheoryLesson(it));
  }

  getInstructorTheoryLessonsPackages(
    instructorId: number
  ): Observable<InstructorTheoryLessonsPackage[]> {
    return this.api
      .get<InstructorTheoryLessonsPackage[]>(
        `${this.basePath}/team_members/${instructorId}/theory_lessons_packages`
      )
      .pipe(map((it) => this.mapInstructorTheoryLessonsPackages(it)));
  }

  private mapInstructorTheoryLessonsPackages(
    list: InstructorTheoryLessonsPackage[]
  ) {
    if (!list) {
      return [];
    }
    return list.map((it) => new InstructorTheoryLessonsPackage(it));
  }
}
