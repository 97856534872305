export class TheoryQuestionStats {
    passedMockExams: number;
    failedMockExams: number;
    correctQuestions: number;
    incorrectQuestions: number;
    totalQuestions: number;
    totalAvailableQuestions: number;
    learningStatusPercentage: number;
    learningTimeInSeconds: number;
    learnerStars: number;

    constructor(json: any) {
        this.passedMockExams = +json.passedMockExams;
        this.failedMockExams = +json.failedMockExams;
        this.correctQuestions = +json.correctQuestions;
        this.incorrectQuestions = +json.incorrectQuestions;
        this.totalQuestions = +json.totalQuestions;
        this.totalAvailableQuestions = +json.totalAvailableQuestions;
        this.learningStatusPercentage = +json.learningStatusPercentage;
        this.learningTimeInSeconds = +json.learningTimeInSeconds;
        this.learnerStars = +json.learnerStars;
    }

    get secondsToHoursAndMinutesString(): string {
        const h = Math.floor(this.learningTimeInSeconds / 3600);
        const m = Math.floor(this.learningTimeInSeconds % 3600 / 60);

        const hDisplay = h > 0 ? `${h}s ` : '0s ';
        const mDisplay = m > 0 ? `${m}m` : '0m';
        return hDisplay + mDisplay; 
    }
}

export function emptyTheoryQuestionStats(): TheoryQuestionStats {
    return new TheoryQuestionStats({
        passedMockExams: 0,
        failedMockExams: 0,
        correctQuestions: 0,
        incorrectQuestions: 0,
        totalQuestions: 0,
        totalAvailableQuestions: 0,
        learningStatusPercentage: 0,
        learningTimeInSeconds: 0,
        learnerStars: 0,
});
}
