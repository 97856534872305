import { Language } from '@models/language.model';
import { TeamMember } from '@models/team-member.model';
import { SchoolLicensePricing } from '@models/license.model';
import { BranchReviewDetail } from '@models/branch-review-detail.model';
import { Car } from '@models/car.model';
import { SchoolOpeningHour } from '@models/school-opening-hour.model';
import { Address } from './address.model';
import { SchoolStatus } from '@models/school-status.model';
import { Asset } from '@models/asset.model';
import { Branch } from '@models/branch.model';

export const MAX_SCHOOL_ACTIVE_LEARNERS = 12;
const AppUserSchool = 9164;
export const FahrschuleJaegerSchool = 9305;
export const FahrschuleEddyBildung = 9020;
export const TheoryLessonSwitchDate = new Date(2023, 1, 1);

export class School {
  id: number;

  createdAt: Date;
  updatedAt: Date;

  name: string;
  code: string;

  previewImage?: Asset;
  logo?: Asset;
  previewImagePath: string;
  logoPath: string;

  description: string;
  uri: string;

  hubspotUrl: string;
  agbUrl: string;

  owner: string;
  phone: string;
  website: string;
  email: string;

  active: boolean;
  visible: boolean;
  supported: boolean;

  status: SchoolStatus;

  isOnlineTheoryPartner: boolean;

  maxNumberOfTheoryParticipants?: number;
  maxLearnerCapacity?: number;

  supportedLanguages: Language[];
  teamMembers: TeamMember[];
  ratingDetail: BranchReviewDetail;
  pricings: SchoolLicensePricing[];
  cars: Car[];
  openingHours: SchoolOpeningHour[];
  branches: Branch[];
  firstBranchId?: number;

  address: Address;

  paymentCustomerNumber: string;

  canScheduleLessons: boolean;
  canUsePaymentSystem: boolean;

  taxId: string;
  taxNumber: string;

  isSpecialTestSchool: boolean;
  isOnlineTheoryFreeForLearners: boolean;
  onlySchoolOnlineLessonsVisible: boolean;

  usesHarmonization: boolean;
  isCooperationSchool: boolean;
  isAccountingEnabled: boolean;
  isOnlineTheoryAvailable: boolean;
  isDigitalContractAvailable: boolean;
  isDigitalBookingAndPaymentAvailable: boolean;
  usesManagementSoftware: boolean;
  usesPricing: boolean;
  displayEddyContract: boolean;

  automaticTrainingDocumentEmail: boolean;

  // used for school-list
  locality?: string;
  street?: string;
  postalCode?: string;
  numLearners?: number;
  numOnboardedLearners?: number;
  onboardedLearnersPercentage?: number;
  lastLogin?: Date;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.createdAt = new Date(json.createdAt);
    this.updatedAt = new Date(json.updatedAt);

    this.name = json.name;
    this.code = json.code;
    this.logoPath = json.logoPath;
    this.previewImagePath = json.previewImagePath;
    this.description = json.description;
    this.uri = json.uri;
    this.hubspotUrl = json.hubspotUrl;
    this.agbUrl = json.agbUrl;

    this.owner = json.owner;
    this.phone = json.phone;
    this.website = json.website;
    this.email = json.email;

    this.active = json.active === true || json.active === 'true';
    this.visible = json.visible === true || json.visible === 'true';
    this.supported = json.supported === true || json.supported === 'true';

    this.status = json.status;

    this.isOnlineTheoryPartner = !!json.isOnlineTheoryPartner;

    if (json.maxNumberOfTheoryParticipants) {
      this.maxNumberOfTheoryParticipants = +json.maxNumberOfTheoryParticipants;
    }
    if (json.maxLearnerCapacity) {
      this.maxLearnerCapacity = +json.maxLearnerCapacity;
    }

    this.supportedLanguages = json.supportedLanguages;
    this.teamMembers = json.teamMembers
      ? this.mapTeamMembers(json.teamMembers)
      : [];
    this.ratingDetail = json.ratingDetail;
    this.pricings = json.pricings ? this.mapPricings(json.pricings) : [];
    this.cars = json.cars ? this.mapCars(json.cars) : [];
    this.openingHours = json.openingHours
      ? this.mapOpeningHours(json.openingHours)
      : [];
    this.branches = json.branches ? this.mapBranches(json.branches) : [];
    if (+json.firstBranchId) {
      this.firstBranchId = +json.firstBranchId;
    }

    this.address = new Address(json.address);

    this.paymentCustomerNumber = json.paymentCustomerNumber;

    this.canScheduleLessons = !!json.canScheduleLessons;
    this.canUsePaymentSystem = !!json.canUsePaymentSystem;

    this.taxId = json.taxId;
    this.taxNumber = json.taxNumber;

    this.isSpecialTestSchool = !!json.isSpecialTestSchool;
    this.isOnlineTheoryFreeForLearners = !!json.isOnlineTheoryFreeForLearners;
    this.onlySchoolOnlineLessonsVisible = !!json.onlySchoolOnlineLessonsVisible;

    this.usesHarmonization = !!json.usesHarmonization;
    this.isCooperationSchool = !!json.isCooperationSchool;
    this.isAccountingEnabled = !!json.isAccountingEnabled;
    this.isOnlineTheoryAvailable = !!json.isOnlineTheoryAvailable;
    this.isDigitalContractAvailable = !!json.isDigitalContractAvailable;
    this.isDigitalBookingAndPaymentAvailable =
      !!json.isDigitalBookingAndPaymentAvailable;
    this.usesManagementSoftware = !!json.usesManagementSoftware;
    this.usesPricing = !!json.usesPricing;
    this.displayEddyContract = !!json.displayEddyContract;

    this.automaticTrainingDocumentEmail = !!json.automaticTrainingDocumentEmail;

    this.locality = json.locality;
    this.street = json.street;
    this.postalCode = json.postalCode;
    if (json.numLearners) {
      this.numLearners = +json.numLearners;
    }
    if (json.numOnboardedLearners) {
      this.numOnboardedLearners = +json.numOnboardedLearners;
    }
    if (json.onboardedLearnersPercentage) {
      this.onboardedLearnersPercentage = +json.onboardedLearnersPercentage;
    }
    if (json.lastLogin) {
      this.lastLogin = new Date(json.lastLogin);
    }
  }

  isNonPartner(): boolean {
    return this.status === SchoolStatus.NON_PARTNER;
  }

  isPartner(): boolean {
    return this.status === SchoolStatus.PARTNER;
  }

  isEddyClub(): boolean {
    return this.status === SchoolStatus.EDDY_CLUB;
  }

  isEddyClubOrPartner(): boolean {
    return this.isEddyClub() || this.isPartner();
  }

  isEddyBildungSchool(): boolean {
    return this.id === FahrschuleEddyBildung;
  }

  isContractSchool(): boolean {
    return this.status === SchoolStatus.CONTRACT_SCHOOL;
  }

  isVIPSchool(): boolean {
    return this.status === SchoolStatus.VIP;
  }

  isAppUserSchool(): boolean {
    return this.id === AppUserSchool;
  }

  isCooperation(): boolean {
    return this.isCooperationSchool;
  }

  isAddressFilled(): boolean {
    return this.address.isFilled();
  }

  addressText(addHTMLLineBreakBeforePostalCode?: boolean): string {
    if (!this.isAddressFilled()) {
      return '';
    }

    return this.address.toString(addHTMLLineBreakBeforePostalCode);
  }

  private mapTeamMembers(list: TeamMember[]) {
    return list.map((it) => new TeamMember(it));
  }

  private mapPricings(list: TeamMember[]) {
    return list.map((it) => new SchoolLicensePricing(it));
  }

  private mapCars(list: Car[]) {
    return list.map((it) => new Car(it));
  }

  private mapOpeningHours(list: SchoolOpeningHour[]) {
    return list.map((it) => new SchoolOpeningHour(it));
  }

  private mapBranches(list: SchoolOpeningHour[]) {
    return list.map((it) => new Branch(it));
  }
}
