export enum QuestionStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  ARCHIVED = 'ARCHIVED'
}

export function allQuestionStatuses(): QuestionStatus[] {
  return [QuestionStatus.ACTIVE, QuestionStatus.PAUSED, QuestionStatus.ARCHIVED];
}

export function displayName(status: QuestionStatus): string {
  switch (status) {
    case QuestionStatus.ACTIVE:
      return 'Aktiv';
    case QuestionStatus.PAUSED:
      return 'Paused';
    case QuestionStatus.ARCHIVED:
      return 'Archiviert';
    default:
      return '';
  }
}
