import {EventEmitter, Injectable} from '@angular/core';
import {LicenseService} from '@services/license.service';
import {MatDialog} from '@angular/material/dialog';
import {Overlay} from '@angular/cdk/overlay';
import {NotificationService} from '@services/notification.service';
import {AcceptLearnerPriceListDialog} from '@modules/shared/dialogs/accept-learner-price-list/accept-learner-price-list.dialog';
import {YesNoDialogComponent} from '@modules/shared/dialogs/yes-no-dialog/yes-no-dialog.component';

@Injectable()
export class PriceListService {
  constructor(private licenseService: LicenseService,
              private dialog: MatDialog,
              private overlay: Overlay,
              private notificationService: NotificationService) {
  }

  public acceptPriceList(priceListId: number, priceListActionTaken?: EventEmitter<void>) {
    const scrollStrategy = this.overlay.scrollStrategies.reposition();

    const dialogRef = this.dialog.open(AcceptLearnerPriceListDialog, {
      width: '527px',
      height: 'auto',
      maxWidth: '100%',
      panelClass: ['custom-dialog-container', 'add-school-learner-dialog'],
      data: {
        priceListId: priceListId
      },
      scrollStrategy: scrollStrategy
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res !== undefined && res !== null) {
        if (res) {
          this.licenseService.acceptLearnerLicensePriceListChanges(priceListId)
            .subscribe(() => {
              priceListActionTaken?.emit();
              this.dialog.open(YesNoDialogComponent, {
                width: '527px',
                height: 'auto',
                maxWidth: '100%',
                panelClass: ['custom-dialog-container', 'success-dialog', 'price-list-success-dialog'],
                data: {
                  title: 'Preise bestätigt',
                  messageHtml: 'Vielen Dank dass du den Preisen deiner Fahrschule zugestimmt hast. Wir haben die Preise in deinem Profil hinterlegt.<br /><br /><strong>WICHTIG:</strong> Solltest du <strong>unter</strong> 18 Jahre alt sein, musst du die Preise noch mit einer Vertragsunterschrift von dir und einer erziehungsberechtigten Person bestätigen. Die Verträge findest du in der Aufgabenliste in deinem Dashboard.',
                  yesButtonLabel: 'Zum Dashboard',
                  showNoButton: false,
                  showCheck: true,
                },
                scrollStrategy: scrollStrategy
              });
            }, () => this.notificationService.saveError());
        } else {
          this.licenseService.rejectLearnerLicensePriceListChanges(priceListId)
            .subscribe(() => {
              priceListActionTaken?.emit();
              this.dialog.open(YesNoDialogComponent, {
                width: '527px',
                height: 'auto',
                maxWidth: '100%',
                panelClass: ['custom-dialog-container', 'success-dialog', 'price-list-success-dialog'],
                data: {
                  title: 'Änderungen abgelehnt',
                  message: 'Du hast angegeben, dass du den Preisänderungen deiner Schule nicht zustimmst. Deine Schule wird dich bzgl. des weiteren Vorgehens direkt kontaktieren.',
                  yesButtonLabel: 'Zurück zum Profil',
                  showNoButton: false,
                  showCheck: false,
                },
                scrollStrategy: scrollStrategy
              });
            }, () => this.notificationService.saveError());
        }
      }
    });
  }
}
