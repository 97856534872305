import { AbstractControl, ValidationErrors } from '@angular/forms';

const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9äöü]([äöüA-Za-z0-9-]{0,61}[A-Za-z0-9äöü])?(\.[A-Za-z0-9äöü]([äöüA-Za-z0-9-]{0,61}[A-Za-z0-9äöü])?)*$/;

function isEmptyInputValue(value: any): boolean {
  // we don't check for string here so it also works with arrays
  return value == null || value.length === 0;
}

export function emailValidator(control: AbstractControl): ValidationErrors | null {
  if (isEmptyInputValue(control.value)) {
    return null;  // don't validate empty values to allow optional controls
  }
  return isValidEmail(control.value) ? null : {'email': true};
}

export function isValidEmail(email: string): boolean {
  return EMAIL_REGEXP.test(email);
}
