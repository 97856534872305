import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, throwError } from 'rxjs';

@Injectable()
export class WebNotificationService {
  private readonly icon = 'driveddy.com/favicon.ico';
  private readonly couldNotSendNotificationError = new Error('could not send notification');

  constructor() {
    setTimeout(() => {
      if (navigator?.serviceWorker && typeof navigator?.serviceWorker !== 'undefined') {
        navigator.serviceWorker.register('notifications-sw.js');
      }
    });
  }

  sendNotification(title: string, body: string): Observable<Notification> {
    if (!this.areWebNotificationsSupported()) {
      return EMPTY;
    }

    // check whether notification permissions have already been granted
    if (this.isNotificationPermissionGranted()) {
      // if it's okay, create a notification immediately
      return of(this.generateNotification(title, body));
    } else if (Notification.permission !== 'denied') {
      // otherwise, ask the user for permission
      if (this.checkNotificationPromise()) {
        Notification.requestPermission().then((permission) => {
          // if the user accepts, create a notification
          if (permission === 'granted') {
            return of(this.generateNotification(title, body));
          }
        });
      } else {
        Notification.requestPermission((permission) => {
          // if the user accepts, create a notification
          if (permission === 'granted') {
            return of(this.generateNotification(title, body));
          }
        });
      }
    } else if (Notification.permission === 'denied') {
      return EMPTY;
    }

    // if the user accepts and notification is not created
    if (Notification.permission === 'granted') {
      return throwError(this.couldNotSendNotificationError);
    }

    return EMPTY;
  }

  private checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  }

  areWebNotificationsSupported() {
    return window && 'Notification' in window;
  }

  requestPermission() {
    Notification.requestPermission().then(permission => {
      // if the user accepts, send a test notification
      if (permission === 'granted') {
        this.testNotification();
      }
    });
  }

  private testNotification(): Notification {
    return this.generateNotification('Test', 'Die Benachrichtigungen sind jetzt aktiviert');
  }

  isNotificationPermissionGranted(): boolean {
    if (!this.areWebNotificationsSupported()) {
      return false;
    }

    return Notification.permission === 'granted';
  }

  isNotificationPermissionBlocked(): boolean {
    if (!this.areWebNotificationsSupported()) {
      return false;
    }

    return Notification.permission === 'denied';
  }

  private generateNotification(title: string, body: string): Notification {
    try {
      return new Notification(title,
        {
          body: body,
          icon: this.icon,
          dir: 'auto'
        });
    } catch (e) {
      navigator.serviceWorker.ready.then(function (registration) {
        registration.showNotification(title, {body: body});
      });
      return <any>{};
    }
  }
}
