import { Injectable } from '@angular/core';

@Injectable()
export class SpecialCharactersReplacementService {

  replacementMap: Map<string, string>;

  constructor() {
    this.replacementMap = new Map([
      [ 'Ae', 'Ä' ],
      [ 'ae', 'ä' ],
      [ 'Ue', 'Ü' ],
      [ 'ue', 'ü' ],
      [ 'Oe', 'Ö' ],
      [ 'oe', 'ö' ],
      [ 'ss', 'ß' ],
    ]);
  }

  replaceSpecialCharacters(text: string) {
    if (!text || (typeof text !== 'string')) {
      return text;
    }

    this.replacementMap.forEach((key, value) => {
      text = text.replace(new RegExp(key, 'g'), value);
    });

    return text;
  }

  reАddSpecialCharacters(text: string) {
    if (!text) {
      return text;
    }

    this.replacementMap.forEach((key, value) => {
      if (value !== 'ss') {  // 'ss' shouldn't be replaces with 'ß'
        text = text.replace(new RegExp(value, 'g'), key);
      }
    });

    return text;
  }
}
