import { TheoryTopicFAQ } from '@models/theory-topic-faq.model';
import { Offer } from './offer.model';
import { TheoryLessonTopicSubtitle } from '@models/theory-lesson-topic-subtitle.model';

const numberOfGeneralTheoryTopics = 12;

export class TheoryLessonTopic {
  key: string;
  title: string;
  orderNumber: number;
  orderNumberInGroup: number;

  summary?: string;
  documentUrl?: string;
  factsSheetDocumentUrl?: string;

  faqs: TheoryTopicFAQ[];
  subtitles: TheoryLessonTopicSubtitle[];

  videoUrl: string;
  imageUrl: string;
  coverImageUrl: string;

  offerId?: number;
  offer?: Offer;

  videoDuration?: number;

  isFreeOfCharge: boolean;
  isAdditional: boolean;

  constructor(json: any) {
    this.key = json.key;
    this.title = json.title;
    this.orderNumber = +json.orderNumber;
    this.orderNumberInGroup = +json.orderNumberInGroup;

    if (json.summary) {
      this.summary = json.summary;
    }
    if (json.documentUrl) {
      this.documentUrl = json.documentUrl;
    }
    if (json.factsSheetDocumentUrl) {
      this.factsSheetDocumentUrl = json.factsSheetDocumentUrl;
    }

    this.faqs = this.mapFAQs(json.faqs);
    this.faqs = this.faqs.sort((a, b) => b.id - a.id);

    this.subtitles = this.mapSubtitles(json.subtitles);

    this.videoUrl = json.videoUrl;
    this.imageUrl = json.imageUrl;
    this.coverImageUrl = json.coverImageUrl;

    if (json.offerId) {
      this.offerId = +json.offerId;
    }
    if (json.offer) {
      this.offer = new Offer(json.offer);
    }

    if (json.videoDuration) {
      this.videoDuration = +json.videoDuration;
    }

    this.isFreeOfCharge = !!json.isFreeOfCharge;
    this.isAdditional = !!json.isAdditional;
  }

  private mapFAQs(res: TheoryTopicFAQ[]): TheoryTopicFAQ[] {
    if (!res) {
      return [];
    }

    return res.map((it) => new TheoryTopicFAQ(it));
  }

  private mapSubtitles(
    res: TheoryLessonTopicSubtitle[]
  ): TheoryLessonTopicSubtitle[] {
    if (!res) {
      return [];
    }

    return res.map((it) => new TheoryLessonTopicSubtitle(it));
  }

  string(): string {
    return this.isAdditional ? this.title : `${this.key} - ${this.title}`;
  }

  get bLicenseName(): string {
    return `${this.getBLicenseKey()} - ${this.title}`;
  }

  // when showing the topic keys for license B
  // the names for G1, G2, ..., G12, B1, B2
  // should be shown as B1, B2, ..., B12, B13, B14
  private getBLicenseKey() {
    if (this.key.startsWith('G')) {
      const keyNumber = +this.key.substring(1);
      return `B${keyNumber}`;
    }

    if (this.key.startsWith('B')) {
      const keyNumber = +this.key.substring(1);
      return `B${keyNumber + numberOfGeneralTheoryTopics}`;
    }

    return '';
  }

  isVideoURLValid(): boolean {
    return this.videoUrl?.indexOf('.mp4') !== -1;
  }
}
