import { Asset } from '@models/asset.model';
import { CarModel } from '@models/car-model.model';
import { CarBrand } from '@models/car-brand.model';

export class Car {
  id: number;

  brandId: number;
  brand: CarBrand;

  modelId: number;
  model: CarModel;

  description: string;

  licensePlateNumber: string;

  schoolId: number;

  transmissionType: CarTransmissionType;
  licenses: string[];

  imagePath: string;

  image?: Asset;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.brandId = +json.brandId;
    this.brand = new CarBrand(json.brand);
    this.modelId = +json.modelId;
    this.model = new CarModel(json.model);
    this.description = json.description;
    this.licensePlateNumber = json.licensePlateNumber;
    this.schoolId = +json.schoolId;
    this.transmissionType = json.transmissionType;
    this.licenses = json.licenses;
    this.imagePath = json.imagePath;
  }

  getDisplayName(): string {
    const brandName = this.brand.name;
    const modelName = this.model.name;
    return `${brandName} ${modelName}`;
  }

  detailedDescription(): string {
    const brandName = this.brand.name;
    const modelName = this.model.name;
    return `${brandName} ${modelName}, ${getTransmissionTypeDisplayName(this.transmissionType)}`;
  }
}

export enum CarTransmissionType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL'
}

export function getTransmissionTypes(): CarTransmissionType[] {
  return [CarTransmissionType.AUTOMATIC, CarTransmissionType.MANUAL];
}

export function getTransmissionTypeDisplayName(transmissionType: CarTransmissionType) {
  switch (transmissionType) {
    case CarTransmissionType.AUTOMATIC:
      return 'Automatik';
    case CarTransmissionType.MANUAL:
      return 'Manuell';
    default:
      return '';
  }
}
