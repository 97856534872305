import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { ExportRequest } from '@models/export-request.model';

@Injectable()
export class ExportService {
  constructor(private api: ApiService) {}

  export(
    data: ExportRequest,
    fileName: string,
    onlyCSV = false,
    onlyPDF = false
  ): Observable<any> {
    const params = new Map<string, string>();
    if (onlyCSV) {
      params.set('onlyCSV', `${onlyCSV}`);
    } else if (onlyPDF) {
      params.set('onlyPDF', `${onlyPDF}`);
    }

    return this.api.postBlob(`${env.api}/export`, data, params).pipe(
      tap((blob: Blob) => {
        if (blob.type === 'application/zip') {
          saveAs(blob, `${fileName}.zip`);
        } else if (blob.type === 'text/csv') {
          saveAs(blob, `${fileName}.csv`);
        } else {
          saveAs(blob, `${fileName}.pdf`);
        }
      })
    );
  }
}
