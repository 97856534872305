import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  PracticeLessonStatus,
  getDisplayName,
  getAllPracticeLessonStatuses,
} from '@models/practice-lesson-status.model';

@Component({
  selector: 'eddy-practice-lesson-status-dropdown',
  templateUrl: 'eddy-practice-lesson-status-dropdown.component.html',
  styleUrls: ['./eddy-practice-lesson-status-dropdown.component.scss'],
})
export class EddyPracticeLessonStatusDropdownComponent {
  practiceLessonStatuses: PracticeLessonStatus[] =
    getAllPracticeLessonStatuses();

  @Input() status: PracticeLessonStatus;

  @Input() fullWidth: boolean;

  @Input() roundedCorners: boolean;

  @Input() placeholder = 'Status';

  @Input() class: string;

  @Output() statusChanged: EventEmitter<PracticeLessonStatus> =
    new EventEmitter<PracticeLessonStatus>();

  onChange(status: PracticeLessonStatus) {
    this.statusChanged.emit(status);
  }

  getStatusDisplayName(status: PracticeLessonStatus) {
    return getDisplayName(status);
  }
}
