<div class="close-drawer-button">
  <img id="header-logo" src="./assets/logo-gradient.png" alt="drivEddy" routerLink="/" (click)="closeSidenav()"/>
  <i id="close-button" class="fal fa-times" (click)="toggleSidenav()"></i>
</div>

<div class="drawer-container">

  <ng-container *ngFor="let navRoute of navRoutes">
    <a *ngIf="isInternalRoute(navRoute); else externalRouteBlock" class="nav-link-mobile" routerLink={{navRoute.route}} (click)="closeSidenav()">{{navRoute.content}}</a>
    <ng-template #externalRouteBlock *ngIf="navRoute.type == 'menu' || navRoute.type === undefined">
      <a class="nav-link-mobile" target="_blank" rel="noopener" href={{navRoute.route}}>{{navRoute.content}}</a>
    </ng-template>
  </ng-container>

  <ng-container *ngFor="let navRoute of externalNavRoutes">
    <a class="nav-link-mobile" href={{navRoute.route}}>{{navRoute.content}}</a>
  </ng-container>


  <ng-container *ngIf="(isLoggedIn | async)">
    <ng-container [ngSwitch]="isSchoolEmployee()">
      <ng-container *ngSwitchCase="true">
        <div [class.opened]="showMoreSubmenu">
          <a class="nav-link-mobile" (click)="toggleMoreSubmenu()">
            Account <i class="fas fa-caret-down more-icon"></i>
          </a>
          <ng-container *ngIf="showMoreSubmenu">
            <span class="submenu-item-container" routerLink='/settings/company' (click)="closeSidenav()">
              <i class="fal fa-building"></i>
              <a class="nav-link-mobile">Firma</a>
            </span>
            <span class="submenu-item-container" routerLink='/settings/account' (click)="closeSidenav()">
              <i class="fal fa-user"></i>
              <a class="nav-link-mobile">Konto</a>
            </span>
            <span class="submenu-item-container" routerLink='/login' (click)="onLogout()">
              <i class="fal fa-sign-out-alt"></i>
              <a class="nav-link-mobile">Abmelden</a>
            </span>
          </ng-container>
        </div>
      </ng-container>

      <a *ngSwitchCase="false" class="nav-link-mobile" routerLink='/login' (click)="onLogout()">Abmelden</a>
    </ng-container>


  </ng-container>

  <ng-container *ngIf="!(isLoggedIn | async)">
    <a class="nav-link-mobile" routerLink='/login' (click)="closeSidenav()">Anmelden</a>
  </ng-container>

</div>
