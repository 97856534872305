import { Injectable } from '@angular/core';
import { environment as env } from '@environments/environment';
import { LeadRating } from '@models/lead-rating.model';
import { Lead, LeadStatus } from '@models/lead.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable()
export class LeadService {
  private readonly apiPath = `${env.api}`;

  constructor(private api: ApiService) {
  }

  saveLead(lead: Lead): Observable<Lead> {
    return this.api.post<Lead>(`${this.apiPath}/leads`, lead)
    .pipe(
      map(it => new Lead(it))
    );
  }

  getLeads(filters?: any): Observable<Lead[]> {
    return this.api.get(`${this.apiPath}/leads`, filters)
      .pipe(
        map((res: any[]) => this.mapLeads(res))
      );
  }

  setLeadStatus(leadId: number, status: LeadStatus) {
    return this.api.post(`${this.apiPath}/leads/${leadId}/status`, {status})
    .pipe(
      map(it => new Lead(it))
    );
  }

  rateLead(leadId: number, rating: LeadRating): Observable<Lead> {
    return this.api.post<Lead>(`${this.apiPath}/leads/${leadId}/rating`, rating)
    .pipe(
      map(it => new Lead(it))
    );
  }

  checkLeadExists(email: string, offerId?: number): Observable<boolean> {
    const params = new Map<string, string>();
    params.set('email', encodeURIComponent(email));
    if (offerId) {
      params.set('offerId', offerId.toString());
    }

    return this.api.get<boolean>(`${this.apiPath}/leads/exists`, params);
  }

  private mapLeads(leads: any[]) {
    return leads.map((it: any) => new Lead(it));
  }
}
