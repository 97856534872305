import { PaymentStatus } from './payment-status.model';

export class TheoryLearningPaymentReceipt {
  id: number;
  exists: boolean;
  learnerId: number;
  paymentStatus: PaymentStatus;
  datePaid?: Date;
  paymentLink: string;
  amountInCents: number;

  constructor(json) {
    this.id = +json.id;
    this.exists = !!json.exists;
    this.learnerId = +json.learnerId;
    this.paymentStatus = json.paymentStatus;
    if (json.datePaid) {
      this.datePaid = new Date(json.datePaid);
    }
    this.paymentLink = json.paymentLink;
    this.amountInCents = +json.amountInCents;
  }

  isPaid(): boolean {
    return this.paymentStatus === PaymentStatus.PAID;
  }
}
