import { Component, Input } from '@angular/core';

@Component({
  selector: 'eddy-driving-schools-trust',
  templateUrl: './driving-schools-trust.component.html',
  styleUrls: ['./driving-schools-trust.component.scss']
})
export class DrivingSchoolsTrustComponent {
  @Input() onlineTheoryServiceView: boolean;
}
