<div class="dialog-container">
  <div class="modal-header">
    <h4 class="modal-title">
      <ng-container *ngIf="title">{{ title }}</ng-container>
      <ng-container *ngIf="!title">Bestätigung</ng-container>
    </h4>

    <div class="close-button" (click)="onNo()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>

  <mat-dialog-content>
    <div *ngIf="logoPath" class="provider-logo">
      <img [src]="logoPath"/>
    </div>
    <div *ngIf="showCheck" class="check">
      <i class="fal fa-check"></i>
    </div>
    <div class="content-title" *ngIf="title">{{ title }}</div>
    <div class="modal-body" *ngIf="message">
      {{message}}
    </div>
    <div class="modal-body" *ngIf="messageHtml" [innerHTML]="messageHtml"></div>
  </mat-dialog-content>

  <div class="spacer"></div>

  <mat-dialog-actions>
    <div class="modal-footer">
      <button type="button" class="btn btn-no" *ngIf="showNoButton" (click)="onNo()">{{ noButtonLabel }}</button>
      <button type="button" class="btn btn-yes" (click)="onYes()">{{ yesButtonLabel }}</button>
    </div>
  </mat-dialog-actions>
</div>
