import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'eddy-image-view-dialog',
  templateUrl: './image-view.dialog.html',
  styleUrls: ['./image-view.dialog.scss']
})
export class ImageViewDialog implements OnInit {
  imageURL: string;

  constructor(private dialogRef: MatDialogRef<ImageViewDialog>,
              @Inject(MAT_DIALOG_DATA) private data: any) {
    this.imageURL = this.data.imageURL;
  }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }
}
