import { AssetType } from '@models/asset-type.model';

export interface Asset {
  id: string | undefined;
  type: AssetType | undefined;
  name: string | undefined;
  base64: string | undefined;
  path: string | undefined;
  mimeType?: string;
  linkPath?: string;
  campaignId?: string;
  campaignName?: string;
  advertiserId?: number;
}

export function emptyAsset() {
  return {
    id: null,
    type: null,
    name: null,
    base64: null,
    path: null
  };
}
