<div class="lp-background">
  <img src="/assets/header-tc.png"/>
  <div class="container">
    <div class="row no-side-space">
      <div class="col-12 col-lg-10 offset-lg-1">
        <h1 class="lp-title">Datenschutz für Website</h1>

        <div class="tc-section">
          Im Rahmen der Nutzung dieser Website werden personenbezogene Daten von Ihnen durch uns als den für die
          Datenverarbeitung Verantwortlichen verarbeitet und für die Dauer gespeichert, die zur Erfüllung der
          festgelegten Zwecke und gesetzlicher Verpflichtungen erforderlich ist. Im Folgenden informieren wir Sie
          darüber, um welche Daten es sich dabei handelt, auf welche Weise sie verarbeitet werden und welche Rechte
          Ihnen diesbezüglich zustehen.<br/>
          Personenbezogene Daten sind gemäß Art. 4 Nr. 1 Datenschutzgrundverordnung (DSGVO) alle Informationen, die sich
          auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden “betroffene Person”)
          beziehen.
        </div>

        <br/>
        <h5 class="lp-section-title">1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen</h5>

        <div class="tc-section">
          Diese Datenschutzinformation gilt für die Datenverarbeitung auf der Webseite www.driveddy.com durch den
          Verantwortlichen:<br/>
          DrivEddy GmbH (im Folgenden „DrivEddy“),<br/>
          Monbijouplatz 4<br/>
          10178 Berlin<br/>
          Tel.: +49 30 4036 6610-0<br/>
          E-Mail: info@driveddy.com
        </div>

        <br/><br/>
        <div class="tc-section">
          Datenschutzbeauftragter<br/>
          <div class="lp-small-top-padding">
            Über diese Kontaktdaten erreichen Sie auch unseren Datenschutzbeauftragten die Fresh Compliance GmbH. Falls
            Sie konkrete Fragen zu Ihren Daten, deren Löschung oder Ihren Rechten haben, besteht eine direkte
            Kontaktmöglichkeit zum Datenschutz über die E-Mail-Adresse datenschutz@driveddy.com.
          </div>

          <br/>
          Falls Sie eine schriftliche Anfrage stellen wollen genügt der Zusatz „Datenschutz“.
        </div>

        <h5 class="lp-section-title">2. Verarbeitung personenbezogener Daten und Zwecke der Verarbeitung</h5>
        <div class="tc-section">
          a) Beim Besuch der Website<br/>
          <div class="lp-small-top-padding">
            Sie können die Webseite www.drivEddy.com aufrufen, ohne Angaben zu Ihrer Identität preisgeben zu müssen. Der
            auf Ihrem Endgerät eingesetzte Browser sendet lediglich automatisch Informationen an den Server unserer
            Webseite. Diese Informationen werden temporär in einem sogenannten Logfile gespeichert. Folgende
            Informationen
            werden dabei ohne Ihr Zutun erfasst und nach 60 Tagen automatisch gelöscht:<br/>
            • die IP-Adresse des anfragenden Rechners,<br/>
            • das Datum und Uhrzeit des Zugriffs,<br/>
            • der Name und URL der abgerufenen Datei,<br/>
            • die Website, von der aus der Zugriff erfolgt (Referrer-URL),<br/>
            • der verwendeter Browser,<br/>
            • das Betriebssystem Ihres Rechners,<br/>
            • der Name Ihres Access-Providers,<br/>
            • Herkunftsland, -region des Zugriffs,<br/>
            • Art der Suchmaschine und eingegebenes Suchwort,<br/>
            • Verweildauer und Anzahl der Seiten, die der Nutzer aufgerufen hat,<br/>
            • Seite, auf der der Nutzer die Website wieder verlassen hat.<br/>
            Die aufgelisteten Daten werden durch uns zu folgenden Zwecken verarbeitet:<br/>
            • zur Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,<br/>
            • zur Gewährleistung einer komfortablen Nutzung unserer Website und Optimierung unserer Plattform,<br/>
            • zur Gewährleistung und Auswertung der Systemsicherheit und –stabilität.<br/>
            Die Rechtsgrundlage für die Verarbeitung der IP-Adresse ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser
            berechtigtes Interesse folgt aus dem erwähnten Sicherheitsinteresse und der Erforderlichkeit einer
            störungsfreien Bereitstellung unserer Webseite.<br/>
            Aus der Verarbeitung der IP-Adresse und anderer Information in dem Logfile können wir keine unmittelbaren
            Rückschlüsse auf Ihre Identität ziehen.<br/>
            Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie Analysedienste ein. Nähere Erläuterungen
            dazu erhalten Sie unter den Ziffern 4 und 5 dieser Datenschutzinformation.
          </div>

          <br/><br/>
          b) Bei Einrichtung eines Benutzerkontos<br/>
          <div class="font-bold lp-small-top-padding">aa) Fahrschüler</div>
          <div class="lp-small-top-padding">
            Um Mitglied im Eddy Club zu werden und einen Account einzurichten, ist die Angabe von persönlichen Daten
            erforderlich. Nach Angabe der erforderlichen Daten werden wir den Account erstellen, dafür benötigen wir
            folgende Angaben:
          </div>
          <div class="lp-small-top-padding">
            • Angaben zur gewünschten Führerscheinklasse<br/>
            • Beginn der Fahrschule<br/>
            • Wie auf uns aufmerksam geworden<br/>
            • Persönliche Daten: Name, Geburtsdatum, Geburtsort, Nationalität, Familienstand, Beruf, Kontaktdaten<br/>
            • Kontaktdaten der Erziehungsberechtigten (bei Minderjährigen)<br/>
            • Angaben zu bestehenden Führerscheinen<br/>
            • Angaben zu einer bereits begonnenen Ausbildung<br/>
            • Rechnungsadresse
          </div>

          <br/>
          Die Verarbeitung Ihrer persönlichen Daten erfolgt auf Ihre Anfrage hin und ist nach Art. 6 Abs. 1 S. 1 lit. b
          DSGVO zu den vorgenannten Zwecken für die Nutzung unserer Produkte und somit für die Erfüllung des Vertrags
          und vorvertraglicher Maßnahmen erforderlich.<br/>
          Nach Löschung Ihres Benutzerkontos werden Ihre Daten für die weitere Verwendung automatisch gelöscht, es sei
          denn, dass wir nach Artikel 6 Abs. 1 S. 1 lit. c DSGVO aufgrund von steuer- und handelsrechtlichen
          Aufbewahrungs- und Dokumentationspflichten (aus HGB, StGB oder AO) zu einer längeren Speicherung verpflichtet
          sind oder Sie in die darüber hinausgehende Speicherung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt
          haben.<br/>
          <br/>
          <div class="font-bold">bb) Fahrlehrer</div>
          <div class="lp-small-top-padding">
            Als Fahrlehrer haben Sie die Möglichkeit, sich bei uns ein passwortgeschütztes Benutzerkonto einzurichten,
            in
            dem wir Ihre persönlichen Daten speichern. Dies dient dem Zweck, Ihnen den Zugang zu unserem Angebot zu
            ermöglichen.<br/>
            Wenn Sie bei uns ein passwortgeschütztes Benutzerkonto einrichten möchten, benötigen wir von Ihnen folgende
            Informationen :<br/>
            • eine gültige E-Mail-Adresse,<br/>
            • Ihr Name,<br/>
            • Der Name Ihrer Fahrschule,<br/>
            • Telefonnummer ,<br/>
            • Angaben, wie Sie auf uns aufmerksam geworden sind.<br/>
            Zusätzlich müssen Sie für die Einrichtung eines Benutzerkontos ein selbstgewähltes Passwort angeben.
            Zusammen
            mit Ihrer E-Mail-Adresse oder Ihres Benutzernamens ermöglicht dies den Zugang zu Ihrem Benutzerkonto. In
            Ihrem
            Benutzerkonto können Sie die zu Ihnen gespeicherten Daten jederzeit einsehen und ändern.<br/>
            Die Erhebung dieser Daten erfolgt ,<br/>
            • um Kenntnis zu haben, wer unser Vertragspartner ist;<br/>
            • zur Begründung, inhaltlichen Ausgestaltung, Abwicklung und Änderung des Vertragsverhältnisses mit Ihnen
            über
            Ihre Bestellung;<br/>
            • zur Überprüfung der eingegebenen Daten auf Plausibilität.
          </div>

          <br/>
          Die Verarbeitung Ihrer persönlichen Daten erfolgt auf Ihre Anfrage hin und ist nach Art. 6 Abs. 1 S. 1 lit. b
          DSGVO zu den vorgenannten Zwecken für die Nutzung unserer Produkte und somit für die Erfüllung des Vertrags
          und vorvertraglicher Maßnahmen erforderlich.<br/>
          Nach Löschung Ihres Benutzerkontos werden Ihre Daten für die weitere Verwendung automatisch gelöscht, es sei
          denn, dass wir nach Artikel 6 Abs. 1 S. 1 lit. c DSGVO aufgrund von steuer- und handelsrechtlichen
          Aufbewahrungs- und Dokumentationspflichten (aus HGB, StGB oder AO) zu einer längeren Speicherung verpflichtet
          sind oder Sie in die darüber hinausgehende Speicherung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt
          haben.<br/>

          <br/>
          c) Bei der Teilnahme an unseren Umfragen<br/>
          <div class="lp-small-top-padding">
            Als registrierter Nutzer haben Sie die Möglichkeit, an von und durchgeführten Umfragen teilzunehmen. Neben
            der
            Speicherung der Fragen und Antworten der jeweiligen Umfrage werden die Ergebnisse von uns ausgewertet,
            analysiert und in einem Kundenprofil gespeichert.<br/>
            Sofern Sie in den Empfang unseres Newsletters eingewilligt haben, können wir Ihnen anhand der Angaben, die
            Sie
            in unseren Umfragen machen, gezielte Produktempfehlungen als personalisierte Newsletter zusenden.<br/>
            Eine andere Verwendung oder Weitergabe der Daten an Dritte erfolgt nicht.<br/>
            Die Teilnahme an unseren Umfragen ist freiwillig.<br/>
            Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f DSGVO, um unsere Newsletter zu
            optimieren. Darin ist ein berechtigtes Interesse im Sinne der genannten Vorschrift zu sehen.
          </div>

          <br/>
          d) Bei der Anmeldung zu unserem Newsletter<br/>
          <div class="lp-small-top-padding">
            Sie haben die Möglichkeit sich für den auf unserer Webseite zur Verfügung gestellten Newsletter anzumelden.
            Sofern Sie ausdrücklich eingewilligt haben, verwenden wir Ihre E-Mail-Adresse dafür, Ihnen regelmäßig
            unseren
            personalisierten Newsletter zu übersenden.<br/>
            Für die Anmeldung zu unseren Newsletter ist die Eingabe folgender Daten zwingend erforderlich:<br/>
            • eine gültige E-Mail-Adresse.
          </div>

          <br/>
          Darüber hinaus besteht die Möglichkeit zur Angabe folgender freiwilliger Angaben:<br/>
          • Ihre Telefonnummer<br/>
          • Ihr Name.<br/>

          <br/>
          Um sicher gehen zu können, dass bei der Eingabe der Emailadresse keine Fehler unterlaufen sind, setzen wir das
          sog. Double-Opt-In-Verfahren ein: Nachdem Sie Ihre Email-Adresse in das Anmeldefeld eingegeben haben,
          übersenden wir Ihnen einen Bestätigungslink. Erst wenn Sie diesen Bestätigungslink anklicken, wird Ihre
          Email-Adresse in unseren Verteiler aufgenommen.<br/>
          Die Abmeldung ist jederzeit möglich, z.B. über einen Link am Ende eines jeden Newsletters. Alternativ können
          Sie Ihren Abmeldewunsch gerne auch jederzeit an info@driveddy.com per E-Mail senden.<br/>
          Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO. In keinem Fall verwenden
          wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf ihre Person zu ziehen<br/>

          <br/>
          e) E-Mail-Werbung ohne Anmeldung zum Newsletter und Ihr Widerspruchsrecht
          <div class="lp-small-top-padding">
            Wenn wir Ihre E-Mail-Adresse im Zusammenhang mit dem Verkauf einer Ware oder Dienstleistung erhalten und Sie
            dem nicht widersprochen haben, behalten wir uns vor, Ihnen auf Grundlage von § 7 Abs. 3 UWG regelmäßig
            Angebote zu ähnlichen Produkten, wie den bereits gekauften, aus unserem Sortiment per E-Mail zuzusenden.
            Dies
            dient der Wahrung unserer im Rahmen einer Interessenabwägung überwiegenden berechtigten Interessen an einer
            werblichen Ansprache unserer Kunden.Sie können dieser Verwendung Ihrer E-Mail-Adresse jederzeit durch eine
            Nachricht an die unten beschriebene Kontaktmöglichkeit oder über einen dafür vorgesehenen Link in der
            Werbemail widersprechen, ohne dass hierfür andere als die Übermittlungskosten nach den Basistarifen
            entstehen.
          </div>

          <br/>
          f) Bei Teilnahme an unserem Gewinnspiel
          <div class="lp-small-top-padding">
            Wenn Sie an unserem Gewinnspiel teilnehmen, nutzen wir die von Ihnen angegebenen Daten, um festzustellen,
            dass
            Sie am Gewinnspiel teilgenommen haben, Sie über einen etwaigen Gewinn zu informieren und einen etwaigen
            Gewinn
            an Sie zu verschicken.<br/>
            Für die Anmeldung zu unserem Gewinnspiel ist die Eingabe folgender Daten zwingend erforderlich:<br/>
            · Eine gültige E-Mail Adresse,<br/>
            · Ihr Vorname,<br/>
            · Ihr Nachname,<br/>
            · Ihr Wohnort,<br/>
            · Eine gültige Telefonnummer,<br/>
            · Ihr Geburtsdatum,<br/>
            · Die Auswahl einer der Antwortmöglichkeiten in Bezug auf die Frage, ob Sie bereits einen Führerschein
            haben.<br/>
            Die Verarbeitung von Daten im Rahmen des Gewinnspiels erfolgt auf Ihre Anfrage hin und ist nach Art. 6 Abs.
            1
            S. 1 lit. b DSGVO zu den vorgenannten Zwecken für Ihre Teilnahme an und der Durchführung von Gewinnspielen
            erforderlich.<br/>
            Für den Fall, dass Sie im Rahmen der Teilnahme am Gewinnspiel einen Gewinn beziehen, verwenden wir Ihren
            Namen, um Sie als Gewinner des Gewinnspiels auf unseren unternehmenseigenen Kommunikationskanälen bekannt
            zugeben. Derzeit betrifft dies die folgenden Kommunikationskanäle:<br/>
            · https://www.drivEddy.com/de<br/>
            · Facebook<br/>
            · Instagram<br/>
            Die Verarbeitung der Daten zur Veröffentlichung erfolgt aufgrund unserer berechtigten Interessen gemäß Art.
            6
            Abs. 1 S. 1 lit. f DSGVO Gewinnspiele zu Marketingzwecken einzusetzen zu nutzen.<br/>
            Die Speicherung und Verarbeitung der Daten erfolgt für die Dauer des Nutzungsverhältnisses mit Ihnen, soweit
            Sie nicht einer darüber hinausgehenden Verwendung zugestimmt haben.<br/>
            Wir führen unsere Gewinnspiele mittels der drivEddy GmbH, Monbijouplatz 4, 10178 Berlin,
            Deutschland,
            durch. Die Teilnehmerdaten werden daher in unserem Auftrag verarbeitet.<br/>
            Die drivEddy GmbH verwendet diese Informationen zur Abwicklung von Gewinnspielen in unserem Auftrag.
          </div>

          <br/>
          g) Active Campaign
          <div class="lp-small-top-padding">
            Diese Website nutzt die Dienste von Active Campaign für den Versand von Newslettern. Anbieter ist der
            US-Anbieter ActiveCampaign, LLC, 150 N. Michigan Ave Suite 1230, Chicago, IL, US, USA.
          </div>

          <div class="lp-small-top-padding">
            Active Campaign ist ein Dienst, mit dem u.a. der Versand von Newslettern organisiert und analysiert werden
            kann. Wenn Sie Daten zum Zwecke des Newsletterbezugs eingeben (z.B. E-Mail-Adresse), werden diese auf den
            Servern von Active Campaign in den USA gespeichert.
          </div>

          <div class="lp-small-top-padding">
            Active Campaign verfügt über eine Zertifizierung nach dem “EU-US-Privacy-Shield”. Der “Privacy-Shield” ist
            ein
            Übereinkommen zwischen der Europäischen Union (EU) und den USA, das die Einhaltung europäischer
            Datenschutzstandards in den USA gewährleisten soll.
          </div>

          <div class="lp-small-top-padding">
            Mit Hilfe von Active Campaign können wir unsere Newsletterkampagnen analysieren. Wenn Sie eine mit Active
            Campaign versandte E-Mail öffnen, verbindet sich eine in der E-Mail enthaltene Datei (sog. web-beacon) mit
            den
            Servern von Active Campaign in den USA. So kann festgestellt werden, ob eine Newsletter-Nachricht geöffnet
            und
            welche Links ggf. angeklickt wurden. Außerdem werden technische Informationen erfasst (z.B. Zeitpunkt des
            Abrufs, IP-Adresse, Browsertyp und Betriebssystem). Diese Informationen können nicht dem jeweiligen
            Newsletter-Empfänger zugeordnet werden. Sie dienen ausschließlich der statistischen Analyse von
            Newsletterkampagnen. Die Ergebnisse dieser Analysen können genutzt werden, um künftige Newsletter besser an
            die Interessen der Empfänger anzupassen.
          </div>

          <div class="lp-small-top-padding">
            Wenn Sie keine Analyse durch Active Campaign wollen, müssen Sie den Newsletter abbestellen. Hierfür stellen
            wir in jeder Newsletternachricht einen entsprechenden Link zur Verfügung. Des Weiteren können Sie den
            Newsletter auch direkt auf der Website abbestellen.
          </div>

          <div class="lp-small-top-padding">
            Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können
            diese
            Einwilligung jederzeit widerrufen, indem Sie den Newsletter abbestellen. Die Rechtmäßigkeit der bereits
            erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
          </div>

          <div class="lp-small-top-padding">
            Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer
            Austragung aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters sowohl von unseren
            Servern
            als auch von den Servern von Active Campaign gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert
            wurden (z.B. E-Mail-Adressen für den Mitgliederbereich) bleiben hiervon unberührt.
          </div>

          <div class="lp-small-top-padding">
            Näheres entnehmen Sie den Datenschutzbestimmungen von Active Campaign unter:
            https://www.activecampaign.com/privacy-policy/.
          </div>

          <div class="lp-small-top-padding">
            Link zur Privacy Shield Zertifizierung: https://www.privacyshield.gov/participant?id=a2zt0000000GnH6AAK
          </div>

          <div class="lp-small-top-padding">
            Abschluss eines Data-Processing-Agreements
          </div>

          <div class="lp-small-top-padding">
            Wir haben ein sog. „Data-Processing-Agreement“ mit Active Campaign abgeschlossen, in dem wir Active Campaign
            verpflichten, die Daten unserer Kunden zu schützen und sie nicht an Dritte weiterzugeben.
          </div>

          <br/>
          <div class="lp-small-section-title">Bewerberdaten</div>

          <div class="lp-small-top-padding">
            Wir erheben und verarbeiten die personenbezogenen Daten von Bewerbern zum Zwecke der Durchführung des
            Bewerbungsverfahrens. Die Verarbeitung kann auch elektronisch erfolgen. Dies ist insbesondere dann der Fall,
            wenn ein Bewerber uns entsprechende Bewerbungsunterlagen auf elektronischem Wege, z.B. per E-Mail, zusenden.
            Sofern wir mit einem Bewerber einen Arbeitsvertrag abschließen, werden die übermittelten Daten zum Zwecke
            der Abwicklung des Arbeitsverhältnisses unter Beachtung der gesetzlichen Bestimmungen gespeichert. Kommt es
            zu keiner Anstellung werden die Bewerbungsunterlagen 6 Monate nach Beendigung des Bewerbungsverfahrens
            gelöscht,
            sofern keine anderen berechtigten Interessen von uns der Löschung entgegenstehen. Ein weiteres berechtigtes
            Interesse in diesem Sinne ist beispielsweise die Beweislast in Verfahren nach dem Allgemeinen
            Gleichbehandlungsgesetz.
          </div>

          <br/>
          <div class="lp-small-section-title">Herunterladen der App</div>

          <div class="lp-small-top-padding">
            Beim Herunterladen der mobilen App werden die erforderlichen Informationen an den jeweiligen App-Store
            übertragen, also insbesondere Nutzername, E-Mail-Adresse und Kundennummer Ihres Accounts, Zeitpunkt des
            Downloads, Zahlungsinformationen und die individuelle Gerätekennziffer. Auf diese Datenerhebung haben wir
            keinen Einfluss und sind nicht dafür verantwortlich. Wir verarbeiten die Daten nur, soweit es für das
            Herunterladen der mobilen App auf Ihr mobiles Endgerät notwendig ist. Die Datenübermittlung erfolgt aufgrund
            Ihrer ausdrücklichen Einwilligung und ist technisch notwendig, um die App herunterladen zu können.
          </div>
        </div>

        <h5 class="lp-section-title">3. Weitergabe von personenbezogenen Daten an Dritte</h5>
        <div class="tc-section">
          Soweit dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von
          Vertragsverhältnissen mit Ihnen oder gem. Art. 6 Abs. 1 S. 1 lit. f zur Wahrung unserer Interessen oder der
          Dritter erforderlich ist, werden Ihre personenbezogenen Daten an Dritte weiter gegeben. Die weitergegebenen
          Daten dürfen von dem Dritten ausschließlich zu den genannten Zwecken verwendet werden.<br/>
          Eine Weitergabe von personenbezogenen Daten kommt darüber hinaus allenfalls in Betracht, wenn<br/>
          • Sie gem. Art. 6 Abs. 1 S. 1 lit. a DSGVO Ihre ausdrückliche Einwilligung dazu erteilt haben, sowie<br/>
          • für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung
          besteht.<br/>
          Eine Übermittlung von personenbezogenen Daten an ein Drittland oder eine internationale Organisation ist
          ausgeschlossen.
        </div>

        <h5 class="lp-section-title">4. Cookies</h5>
        <div class="tc-section">
          Wir setzen auf unserer Webseite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser
          automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie
          diese Webseite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren, Trojaner
          oder sonstige Schadsoftware.<br/>
          In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten
          Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität
          erhalten.<br/>
          Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten.
          So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten bereits besucht
          haben.<br/>
          Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für
          einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unserer Webseite
          erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und
          welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.<br/>
          Zum anderen setzen wir Cookies ein, um die Nutzung unserer App statistisch zu erfassen und zum Zwecke der
          Optimierung unseres Angebotes für Sie auszuwerten (siehe Ziffer 5). Diese Cookies ermöglichen es uns, bei
          einem erneuten Besuch unserer App automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies
          werden nach einer jeweils definierten Zeit automatisch gelöscht.<br/>
          Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer berechtigten
          Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.<br/>
          Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass
          keine Cookies auf Ihrem Endgerät gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie
          angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle
          Funktionen unserer App nutzen können.
        </div>

        <h5 class="lp-section-title">5. Webanalyse</h5>
        <div class="tc-section">
          Die im Folgenden aufgeführten und von uns eingesetzten Tracking- und Targeting -Maßnahmen werden auf Grundlage
          des Art. 6 Abs. 1 S. 1 lit. f DSGVO durchgeführt.<br/>
          Mit den zum Einsatz kommenden Tracking-Maßnahmen wollen wir eine bedarfsgerechte Gestaltung und die
          fortlaufende Optimierung unserer Webseite sicherstellen. Zum anderen setzen wir die Tracking-Maßnahmen ein, um
          die Nutzung unserer Webseite statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie
          auszuwerten.<br/>
          Über die zum Einsatz kommenden Targeting-Maßnahmen wollen wir sicherstellen, dass Ihnen nur an Ihren
          tatsächlichen oder vermeintlichen Interessen orientierte Werbung auf Ihren Endgeräten eingeblendet wird.<br/>
          Diese Interessen sind als berechtigt im Sinne der vorgenannten Vorschrift anzusehen.<br/>
          Die jeweiligen Datenverarbeitungszwecke und Datenkategorien sind den entsprechenden Tracking- und
          Targeting-Tools zu entnehmen.<br/>

          <br/>
          a) Google Analytics<br/>
          Wir nutzen in unserer Webseite Google Analytics, einen Webanalysedienst der Google LLC . (1600 Amphitheatre
          Parkway, Mountain View, CA 94043, USA; im Folgenden „Google“). In diesem Zusammenhang werden pseudonymisierte
          Nutzungsprofile erstellt und Cookies (vgl. Ziffer 4) verwendet. Die durch den Cookie erzeugten Informationen
          über Ihre Benutzung dieser Webseite wieBrowser-Typ/-Version,<br/>

          <br/>
          verwendetes Betriebssystem,<br/>
          Referrer-URL (die zuvor besuchte Seite),<br/>
          Hostname des zugreifenden Rechners (IP-Adresse),<br/>
          Uhrzeit der Serveranfrage,<br/>
          werden an einen Server von Google in den USA übertragen und dort gespeichert. Google beachtet die
          Datenschutzbestimmungen des „US-Privacy-Shield“ und ist beim „US-Privacy Shield“-Programm des
          US-Handelsministeriums registriert. Zusätzlich haben wir mit Google einen Auftragsverarbeitungsvertrag für die
          Verwendung von Google Analytics abgeschlossen. Durch diesen Vertrag versichert Google, dass sie die Daten im
          Einklang mit der Datenschutzgrundverordnung verarbeiten und den Schutz der Rechte der betroffenen Person
          gewährleisten.<br/>

          <br/>
          Die Informationen werden verwendet, um die Nutzung der Website auszuwerten, um Reports über die
          Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene
          Dienstleistungen zu Zwecken der Marktforschung und bedarfsgerechten Gestaltung dieser Internetseiten zu
          erbringen.<br/>

          <br/>
          Auch werden diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist
          oder soweit Dritte diese Daten im Auftrag verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit anderen
          Daten von Google zusammengeführt. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht möglich
          ist (IP-Masking).<br/>

          <br/>
          Sie können die Installation der Cookies durch eine entsprechende Einstellung der Browser-Software verhindern.
          Wir weisen jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
          vollumfänglich genutzt werden können.<br/>

          <br/>
          Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website
          bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem
          Sie ein Browser-Add-on herunterladen und installieren.<br/>

          <br/>
          Alternativ zum Browser-Add-on, insbesondere bei Browsern auf mobilen Endgeräten, können Sie die Erfassung
          durch Google Analytics zudem verhindern, indem Sie auf diesen Lin k klicken. Es wird ein Opt-out-Cookie
          gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert. Der Opt-out-Cookie
          gilt nur in diesem Browser und nur für unsere Website und wird auf Ihrem Gerät abgelegt. Löschen Sie die
          Cookies in diesem Browser, müssen Sie das Opt-out-Cookie erneut setzen.<br/>

          <br/>
          Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der Google
          Analytics-Hilfe .<br/>

          <br/>
          c) Google Tag-Manager
          <div class="lp-small-top-padding">
            Auf unseren Webseiten kommt das Tool Google Tag Manager der Google LLC., 1600 Amphitheatre Parkway, Mountain
            View, CA 94043, USA (Im Folgenden: „Google“) zum Einsatz. Mit dem Google Tag Manager verwalten wir die
            Tools,
            über die wir in dieser Datenschutzerklärung informieren. Einzelheiten bezüglich dieser Tools entnehmen Sie
            daher der Information bezüglich des konkreten Tools.
          </div>

          <br/>
          Das Tool Tag Manager selbst (das die Tags implementiert) ist eine cookielose Domain. Das Tool sorgt für die
          Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen. Google Tag Manager greift nicht auf
          diese Daten zu. Wenn auf Domain- oder Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese für alle
          Tracking-Tags bestehen, die mit Google Tag Manager implementiert werden.<br/>

          <br/>
          Weitere Informationen zum Google Tag Manager finden Sie in den Nutzungsrichtlinien für dieses Produkt .<br/>

          <br/>
          d) Google-Firebase
          <div class="lp-small-top-padding">
            Ferner nutzen wir den Google Service Firebase zur Analyse und Kategorisierung von Nutzergruppen sowie zur
            Ausspielung von Push-Mitteilungen. Firebase ist eine Echtzeit-Datenbank, mit der sich Echtzeitinformationen
            in
            die eigene Website einbetten lassen. Hierbei werden die Nutzerdaten anonymisiert an Firebase übermittelt.
            Firebase ist eine Google-Tochter und hat seinen Sitz in San Francisco (CA), USA. Die Datenschutzerklärung
            von
            Firebase finden Sie unter https://www.firebase.com/terms/privacy-policy.html.
          </div>

          <br/>
          e) Facebook Custom Audience
          <div class="lp-small-top-padding">
            Wir setzen Facebook Custom Audiences der Facebook Ireland Limited (4 Grand Canal Square, Dublin 2, Irland)
            ein. Hierbei handelt es sich um einen Marketingdienst von Facebook. Dieser ermöglicht es uns, bestimmten
            Gruppen von pseudonymisierten Besuchern unserer Website, die auch Facebook nutzen, individuell abgestimmte
            und
            interessenbezogene Werbung auf Facebook anzeigen zu lassen.
          </div>

          <br/>
          Auf unserer Website ist ein Facebook Custom Audience-Pixel-Tag integriert. Hierbei handelt es sich um einen
          Java Script-Code, über den nicht-personenbezogene Daten über die Nutzung der Website gespeichert werden. Dazu
          gehören Ihre IP-Adresse, der genutzte Browser sowie Ausgangs- und Zielseite. Diese Informationen werden an
          Server von Facebook in den USA übertragen. Facebook unterliegt dem EU-US Privacy Shield, sodass ein
          angemessenes Datenschutzniveau garantiert wird.<br/>

          <br/>
          Dort wird automatisiert abgeglichen, ob Sie einen Facebook-Cookie gespeichert haben. Über den Facebook-Cookie
          wird automatisch ermittelt, ob Sie zu der für uns relevanten Zielgruppe gehören. Gehören Sie zu der
          Zielgruppe, werden Ihnen bei Facebook entsprechende Anzeigen von uns angezeigt. Bei diesem Vorgang werden Sie
          durch den Abgleich der Daten weder durch uns noch durch Facebook persönlich identifiziert.<br/>

          <br/>
          Sie können der Verwendung des Dienstes Custom Audiences auf der Website von Facebook widersprechen. Nach
          Anmeldung zu Ihrem Facebook-Konto gelangen Sie zu den Einstellungen für Facebook-Werbeanzeigen.<br/>

          <br/>
          Nähere Informationen zum Datenschutz bei Facebook finden Sie in der Datenschutzrichtlinie von Facebook .<br/>

          <br/>
          f) Zopim
          <div class="lp-small-top-padding">
            Als Nutzer unserer Webseite bieten wir Ihnen die Möglichkeit, über ein auf der Website bereitgestelltes
            Nachrichten-Tool mit uns zu kommunizieren. Hierfür nutzen wir Zopim des Anbieters Zendesk Inc., 1019 Market
            St, San Francisco, CA 94103 USA.
          </div>

          <br/>
          Bei der Nutzung unseres Nachrichten-Tools, können Ihre Daten an das Unternehmen Zendesk Inc. übermittelt
          werden.<br/>

          <br/>
          Zendesk versichert, dass personenbezogene Daten umfassend vor unerlaubten Zugriffen geschützt sind. Zendesk
          selbst nutzt Ihre Daten nicht, um sie zu eigenen Zwecken zu kontaktieren oder an Dritte weiterzugeben.<br/>

          <br/>
          Als Unternehmen mit Sitz in den USA beachtet Zendesk die Datenschutzbestimmungen des „US-Privacy-Shield“ und
          ist beim „US-Privacy-Shield“-Programm des US-Handelsministeriums registriert.<br/>

          <br/>
          Im Rahmen der Nutzung unseres Nachrichten-Tools werden Ihre versendeten Nachrichten automatisch von uns
          gescannt und analysiert . Dies erfolgt zum Zwecke<br/>

          <br/>
          der Betrugsprävention,<br/>
          zur Aufdeckung von rechtswidrigen Handlungen und Verstößen gegen unsere Allgemeinen Geschäftsbedingungen<br/>
          sowie zur Verbesserung der Kommunikation und Kundenbetreuung.<br/>
          Die Datenverarbeitung stützen wir auf unsere berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO.
          Die Datenverarbeitung zu den vorgenannten Zwecken gilt gemäß der DSGVO als anerkanntes berechtigtes Interesse.<br/>

          <br/>
          Ihre versendeten und empfangenen Nachrichten können Sie selbst verwalten und auf Antrag durch uns löschen
          lassen. Im Falle eines Betrugsversuchs, einer rechtwidrigen Handlung oder Verstoß gegen die Allgemeinen
          Geschäftsbedingungen können wir die entsprechenden Nachrichten auch nach Ihrem Antrag auf Löschung, auf
          Grundlage unserer berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO, zu Nachweiszwecken und der
          Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen weiter aufbewahren.<br/>

          <br/><br/>
          <div class="lp-small-section-title">Hotjar</div>

          <br/>
          Weiterhin verwenden wir den Webanalysedienst Hotjar der Hotjar Ltd.. Hotjar Ltd. ist ein europäisches
          Unternehmen mit Sitz in Malta (Hotjar Ltd, Level 2, St Julians Business Centre, 3, Elia Zammit Street, St
          Julians STJ 1000, Malta, Europe. Mit diesem Tool lassen sich Bewegungen auf den Websiten, auf denen Hotjar
          eingesetzt wird, nachvollziehen (sog. Heatmaps). So ist beispielsweise erkennbar, wie weit Nutzer scrollen und
          welche Schaltflächen die Nutzer wie oft anklicken. Weiterhin ist es mithilfe des Tools auch möglich, Feedback
          direkt von den Nutzern der Website einzuholen. Auf diese Weise erlangen wir wertvolle Informationen, um unsere
          Websites noch schneller und kundenfreundlicher zu gestalten.<br/>

          <br/>
          Wir achten beim Einsatz dieses Tools besonders auf den Schutz Ihrer personenbezogenen Daten. So können wir nur
          nachvollziehen, welche Schaltflächen angeklickt werden, den Verlauf der Maus, wie weit gescrollt wird, die
          Bildschirmgröße des Gerätes, Gerätetyp und Browserinformation, Geographischer Standpunkt (nur das Land) und
          die bevorzugte Sprache um unsere Website darzustellen. Bereiche der Websites in denen personenbezogene Daten
          von Ihnen oder Dritten angezeigt werden, werden von Hotjar automatisch ausgeblendet und sind somit zu keinem
          Zeitpunkt nachvollziehbar.<br/>

          <br/>
          Hotjar bietet jedem Nutzer die Möglichkeit, mithilfe eines “Do Not Track-Headers” den Einsatz des Tools Hotjar
          zu unterbinden, sodass keine Daten über den Besuch der jeweiligen Website aufgezeichnet werden. Es handelt
          sich hierbei um eine Einstellung die alle üblichen Browser in aktuellen Versionen unterstützen. Hierzu sendet
          Ihr Browser eine Anfrage an Hotjar, mit dem Hinweis das Tracking des jeweiligen Nutzers zu deaktivieren.
          Sollten Sie sipgate-Websites mit unterschiedlichen Browsern/Rechnern nutzen, müssen Sie den “Do Not
          Track-Header” für jeden dieser Browser/Rechner separat einrichten.<br/>

          <br/>
          Eine detailierte Anleitungen mit Informationen zu Ihrem Browser finden Sie unter:<br/>
          https://www.hotjar.com/opt-out<br/>

          <br/>
          Weitere Informationen über Hotjar Ltd. und über das Tool Hotjar finden Sie unter:<br/>
          https://www.hotjar.com<br/>

          <br/>
          Die Datenschutzerklärung der Hotjar Ltd. finden Sie unter:<br/>
          https://www.hotjar.com/privacy<br/>

          <br/>
          Als Schutzmaßnahmen erfolgt eine Auswertung der Daten nicht auf Basis Ihrer Person erfolgt, sondern lediglich
          auf statistischer Basis.<br/>

          <br/>
          Zweck der Nutzung von Hotjar ist die anonymisierte Analyse Ihres Nutzungssverhaltens auf unseren Webseiten.
          Die daraus gewonnen Erkenntnisse helfen dabei, unser Angebot zu verbessern.
        </div>

        <h5 class="lp-section-title">6. Social-Media-Plugins</h5>
        <div class="tc-section">
          Wir setzen auf unserer Website auf Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO Social Plug-ins der sozialen
          Netzwerke Facebook, Instagram, Xing und Linked-In ein, um unser Unternehmen hierüber bekannter zu machen. Der
          dahinterstehende werbliche Zweck ist als berechtigtes Interesse im Sinne der DSGVO anzusehen. Die
          Verantwortung für den datenschutzkonformen Betrieb ist durch deren jeweiligen Anbieter zu gewährleisten. Die
          Einbindung dieser Plug-ins durch uns erfolgt im Wege der sogenannten Zwei-Klick-Methode um Besucher unserer
          Webseite bestmöglich zu schützen.<br/>

          <br/>
          a) Facebook
          <div class="lp-small-top-padding">
            Auf unserer Plattform kommen Social-Media Plugins der Facebook Ireland Limited (4 Grand Canal Square, Dublin
            2, Irland) zum Einsatz, um deren Nutzung persönlicher zu gestalten. Hierfür nutzen wir den „LIKE“ oder
            “TEILEN”-Button. Es handelt sich dabei um ein Angebot von Facebook.
          </div>

          <br/>
          Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält und Sie diesen Plug-in
          selbst aktivieren, baut Ihr Browser eine direkte Verbindung mit den Servern von Facebook auf. Der Inhalt des
          Plugins wird von Facebook direkt an Ihren Browser übermittelt und von diesem in die Webseite eingebunden.<br/>

          <br/>
          Durch die Einbindung der Plugins erhält Facebook die Information, dass Ihr Browser die entsprechende Seite
          unseres Webauftritts aufgerufen hat, auch wenn Sie kein Facebook-Konto besitzen oder gerade nicht bei Facebook
          eingeloggt sind. Diese Information (einschließlich Ihrer IP-Adresse) wird von Ihrem Browser direkt an einen
          Server von Facebook in den USA übermittelt und dort gespeichert.<br/>

          <br/>
          Sind Sie bei Facebook eingeloggt, kann Facebook den Besuch unserer Website Ihrem Facebook-Konto direkt
          zuordnen. Wenn Sie mit den Plugins interagieren, zum Beispiel den „LIKE“ oder “TEILEN”-Button betätigen, wird
          die entsprechende Information ebenfalls direkt an einen Server von Facebook übermittelt und dort gespeichert.
          Die Informationen werden zudem auf Facebook veröffentlicht und Ihren Facebook-Freunden angezeigt.<br/>

          <br/>
          Facebook kann diese Informationen zum Zwecke der Werbung, Marktforschung und bedarfsgerechten Gestaltung der
          Facebook-Seiten benutzen. Hierzu werden von Facebook Nutzungs-, Interessen- und Beziehungsprofile erstellt, z.
          B. um Ihre Nutzung unserer Website im Hinblick auf die Ihnen bei Facebook eingeblendeten Werbeanzeigen
          auszuwerten, andere Facebook-Nutzer über Ihre Aktivitäten auf unserer Website zu informieren und um weitere
          mit der Nutzung von Facebook verbundene Dienstleistungen zu erbringen.<br/>

          <br/>
          Wenn Sie nicht möchten, dass Facebook die über unseren Webauftritt gesammelten Daten Ihrem Facebook-Konto
          zuordnet, müssen Sie sich vor Ihrem Besuch unserer Website bei Facebook ausloggen.<br/>

          <br/>
          Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie
          Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte
          den Datenschutzhinweisen von Facebook.<br/>

          <br/>
          b) Instagram
          <div class="lp-small-top-padding">
            Auf unserer Website werden auch sogenannte Social Plugins („Plugins“) von Instagram verwendet, das von der
            Instagram LLC., 1601 Willow Road, Menlo Park, CA 94025, USA („Instagram“) betrieben wird.
          </div>

          <br/>
          Die Plugins sind mit einem Instagram-Logo beispielsweise in Form einer „Instagram-Kamera“ gekennzeichnet.<br/>

          <br/>
          Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, stellt Ihr Browser eine
          direkte Verbindung zu den Servern von Instagram her. Der Inhalt des Plugins wird von Instagram direkt an Ihren
          Browser übermittelt und in die Seite eingebunden. Durch diese Einbindung erhält Instagram die Information,
          dass Ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen hat, auch wenn Sie kein
          Instagram-Profil besitzen oder gerade nicht bei Instagram eingeloggt sind.<br/>

          <br/>
          Diese Information (einschließlich Ihrer IP-Adresse) wird von Ihrem Browser direkt an einen Server von
          Instagram in die USA übermittelt und dort gespeichert. Sind Sie bei Instagram eingeloggt, kann Instagram den
          Besuch unserer Website Ihrem Instagram-Account unmittelbar zuordnen. Wenn Sie mit den Plugins interagieren,
          zum Beispiel das „Instagram“-Button betätigen, wird diese Information ebenfalls direkt an einen Server von
          Instagram übermittelt und dort gespeichert.<br/>

          <br/>
          Die Informationen werden außerdem auf Ihrem Instagram-Account veröffentlicht und dort Ihren Kontakten
          angezeigt.<br/>

          <br/>
          Wenn Sie nicht möchten, dass Instagram die über unseren Webauftritt gesammelten Daten unmittelbar Ihrem
          Instagram-Account zuordnet, müssen Sie sich vor Ihrem Besuch unserer Website bei Instagram ausloggen.<br/>

          <br/>
          Weitere Informationen hierzu Sie in der Datenschutzerklärung von Instagram.<br/>

          <br/>
          c) YouTube
          <div class="lp-small-top-padding">
            Wir setzen auf unserer Website auf der Grundlage einer Einwilligung nach Art. 6 Abs. 1 S. 1 lit. f DSGVO
            Komponenten (Videos) des Unternehmens YouTube, LLC 901 Cherry Ave., 94066 San Bruno, CA, USA (Im Folgenden:
            „YouTube“), einem Unternehmen der Google LLC., Amphitheatre Parkway, Mountain View, CA 94043, USA (Im
            Folgenden: „Google“), ein.
          </div>

          <br/>
          Hierbei nutzen wir die von YouTube zur Verfügung gestellte Option des „erweiterter Datenschutzmodus”.<br/>

          <br/>
          Wenn Sie eine Seite aufrufen, die über ein eingebettetes Video verfügt, wird eine Verbindung zu den
          YouTube-Servern hergestellt und dabei der Inhalt durch Mitteilung an Ihren Browser auf der Internetseite
          dargestellt.<br/>

          <br/>
          Laut den Angaben von YouTube werden im „erweiterten Datenschutzmodus“ nur dann Ihre Daten – insbesondere
          welche unserer Websites Sie besucht haben sowie gerätespezifische Informationen inklusive der IP-Adresse – an
          den YouTube-Server in den USA übermittelt, wenn Sie das Video anschauen. Durch den Klick auf das Video
          willigen Sie in diese Übermittlung ein.<br/>

          <br/>
          Sind Sie gleichzeitig bei YouTube eingeloggt, werden diese Informationen Ihrem Mitgliedskonto bei YouTube
          zugeordnet. Dies können Sie verhindern, indem Sie sich vor dem Besuch unserer Website von Ihrem Mitgliedskonto
          abmelden.<br/>

          <br/>
          Google beachtet die Datenschutzbestimmungen des „US-Privacy-Shield“ und ist beim „US-Privacy Shield“-Programm
          des US-Handelsministeriums registriert.<br/>

          <br/>
          Weitere Informationen zum Datenschutz im Zusammenhang mit YouTube finden Sie in den Datenschutzbestimmungen
          von Google .<br/>

          <br/>
          d) Social-Login via Facebook-Connect<br/>
          Wir bieten Ihnen die Möglichkeit, sich unter Nutzung ihres Facebook-Kontos für ein Kundenkonto zu
          registrieren. Hierfür verwenden wir Facebook-Connect der Facebook Inc.<br/>

          <br/>
          Wenn Sie sich über Facebook-Connect bei uns registrieren, werden Facebook Profildaten (z.B. Name, Profilbild,
          Alter, Geschlecht, Freundesliste) und auf Facebook öffentliche Daten aus Ihrem Facebook-Profil an uns
          übertragen. Die übertragenen Daten werden nur teilweise zur Erstellung ihres Kundenkontos verwendet. So
          verwenden wir etwa nicht Ihre Freundesliste<br/>

          <br/>
          Zudem werden im Rahmen der Verwendung von Facebook-Connect auch Daten von uns an Facebook gesendet. Facebook
          kann diese Informationen zum Zwecke der Werbung, Marktforschung und bedarfsgerechten Gestaltung der
          Facebook-Seiten benutzen. Hierzu werden von Facebook Nutzungs-, Interessen- und Beziehungsprofile erstellt, z.
          B. um Ihre Nutzung unserer Website im Hinblick auf die Ihnen bei Facebook eingeblendeten Werbeanzeigen
          auszuwerten, andere Facebook-Nutzer über Ihre Aktivitäten auf unserer Website zu informieren und um weitere
          mit der Nutzung von Facebook verbundene Dienstleistungen zu erbringen.<br/>

          <br/>
          Wenn Sie nicht möchten, dass Daten zu Ihrer Person zwischen uns und Facebook ausgetauscht werden, verwenden
          Sie bitte die Registrierungsfunktion (siehe Ziffer 2)<br/>

          <br/>
          Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie
          Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte
          den Datenschutzhinweisen von Facebook.
        </div>

        <h5 class="lp-section-title">7. Betroffenenrechte</h5>
        <div class="tc-section">
          Sie haben das Recht:<br/>

          <br/>
          - gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat
          zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr
          fortführen dürfen;<br/>

          <br/>
          - gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen.
          Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die
          Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante
          Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder
          Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben
          wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf.
          aussagekräftigen Informationen zu deren Einzelheiten verlangen;<br/>

          <br/>
          - gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns
          gespeicherten personenbezogenen Daten zu verlangen;<br/>

          <br/>
          - gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit
          nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer
          rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen erforderlich ist;<br/>

          <br/>
          - gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit
          die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren
          Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung
          eingelegt haben;<br/>

          <br/>
          - gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten,
          gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu
          verlangen und<br/>

          <br/>
          - gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an
          die Aufsichtsbehörde ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes
          wenden.
        </div>

        <h5 class="lp-section-title">8. Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h5>
        <div class="tc-section">
          Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
          Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Artikel 6 Absatz 1 Buchstabe e DSGVO
          (Datenverarbeitung im öffentlichen Interesse) und Artikel 6 Absatz 1 Buchstabe f DSGVO (Datenverarbeitung auf
          der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
          Bestimmung gestütztes Profiling von Artikel 4 Nr. 4 DSGVO.<br/>

          <br/>
          Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir
          können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
          Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen.<br/>

          <br/>
          Sofern sich Ihr Widerspruch gegen eine Verarbeitung von Daten zum Zwecke der Direktwerbung richtet, so werden
          wir die Verarbeitung umgehend einstellen. In diesem Fall ist die Angabe einer besonderen Situation nicht
          erforderlich. Dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.<br/>

          <br/>
          Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an info@driveddy.com
        </div>
        <h5 class="lp-section-title">9. Datensicherheit</h5>
        <div class="tc-section">
          Alle von Ihnen persönlich übermittelten Daten, einschließlich Ihrer Zahlungsdaten, werden mit dem allgemein
          üblichen und sicheren Standard SSL (Secure Socket Layer) übertragen. SSL ist ein sicherer und erprobter
          Standard, der z.B. auch beim Onlinebanking Verwendung findet.<br/>

          <br/>
          Sie erkennen eine sichere SSL-Verbindung unter anderem an dem angehängten s am http (also https://…) in der
          Adressleiste Ihres Browsers oder am Schloss-Symbol im unteren Bereich Ihres Browsers.<br/>

          <br/>
          Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre bei uns
          gespeicherten persönlichen Daten gegen Manipulation, teilweisen oder vollständigen Verlust und gegen
          unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen
          Entwicklung fortlaufend verbessert.
        </div>
        <h5 class="lp-section-title">10. Aktualität und Änderung dieser Datenschutzinformation</h5>
        <div class="tc-section">
          Diese Datenschutzinformation ist aktuell gültig und hat den Stand Oktober 2019.<br/>

          <br/>
          Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher bzw.
          behördlicher Vorgaben kann es notwendig werden, diese Datenschutzinformation zu ändern. Die jeweils aktuelle
          Datenschutzinformation kann jederzeit auf der Website unter https://www.drivEddy.com unter dem Reiter
          Datenschutz (o.ä.) eingesehen und ausgedruckt werden.
        </div>
      </div>
    </div>
  </div>
</div>
