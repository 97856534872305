import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, SecurityContext } from '@angular/core';
import { AffiliateService } from '@services/affiliate.service';
import { AssetType } from '@models/asset-type.model';
import { Asset } from '@models/asset.model';
import { AnalyticsService } from '@modules/analytics/services/analytics.service';
import { URLUtils } from '@services/url-utils.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'eddy-ad',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdComponent {
  private _assetType: AssetType;
  private _asset: Asset;

  @Input() set asset(asset: Asset) {
    setTimeout(() => {
      if (!asset) {
        return;
      }

      this._asset = asset;
      this.changeDetectionRef.detectChanges();
    });
  }

  get asset(): Asset {
    return this._asset;
  }

  @Input() set assetType(assetType: AssetType) {
    if (!assetType) {
      return;
    }

    this.displayed = false;
    this._assetType = assetType;
    this.loadAdFromBackend();
    setTimeout(() => this.changeDetectionRef.detectChanges());
  }

  get assetType() {
    return this._assetType;
  }

  @Input() space: string;

  displayed = false;

  @Output() assetLoadEnd: EventEmitter<boolean> = new EventEmitter();

  constructor(private affiliateService: AffiliateService,
              private analyticsService: AnalyticsService,
              private sanitizer: DomSanitizer,
              private changeDetectionRef: ChangeDetectorRef) {
  }

  onAdClick() {
    this.affiliateService.markAdClick(this.asset, this.space).subscribe();
    this.registerAnalyticsEvent('click');
  }

  onAdLoad() {
    this.displayed = true;
    this.affiliateService.markAdImpression(this.asset, this.space).subscribe();
    this.registerAnalyticsEvent('view');
  }

  private loadAdFromBackend() {
    this.affiliateService.getAd(this.assetType)
      .subscribe(asset => {
        this.asset = asset;
        this.assetLoadEnd.emit(this.asset && !!this.asset.path);
        this.changeDetectionRef.detectChanges();
      }, () => {
        this.assetLoadEnd.emit(false);
        this.changeDetectionRef.detectChanges();
      });
  }

  private registerAnalyticsEvent(type: string) {
    const a = this.asset;
    this.analyticsService.registerEvent({
      category: 'ad',
      action: type,
      label: `camp_${a.campaignId}_customer_${a.advertiserId}_${a.type}`
    });
  }

  isVideo(asset: Asset): boolean {
    return asset.type === AssetType.VIDEO;
  }

  get videoUrl() {
    let url = this.asset.path;
    url = URLUtils.addQueryParamToUrl(url, 'autoplay', '1');
    url = URLUtils.addQueryParamToUrl(url, 'controls', '0');

    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.sanitizer.sanitize(SecurityContext.URL, url)
    );
  }
}
