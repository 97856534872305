import { Injectable } from '@angular/core';
import { SchoolLearnerService } from './school-learner.service';

@Injectable()
export class OnlineTimeLogService {
    private onlineTimeLogInterval;
    private _learnerId: number;
    loggedSchoolLearnerId: number;
    interval = 10 * 60 * 1000;
    startInterval: Date;

    set learnerId(val: number) {
        if (val) {
            this._learnerId = val;
            this.schoolLearnerService.getSchoolLearnerByLearnerId(this._learnerId, true)
            .subscribe(schoolLearner => {
                this.loggedSchoolLearnerId = schoolLearner.id;
                this.startLogOnlineTime();
            });
        }
    }

    get schoolLearnerService(): SchoolLearnerService {
        return this._schoolLearnerService;
    }

    set schoolLearnerService(val: SchoolLearnerService) {
        this._schoolLearnerService = val;
    }

    private _schoolLearnerService: SchoolLearnerService;

    constructor() {}

    logPassedTime() {
        if (this.startInterval && this.loggedSchoolLearnerId) {
            const passedTimeInMs = (new Date()).getTime() - this.startInterval.getTime();
            const passedTimeInSeconds = Math.floor(passedTimeInMs / 1000);
            if (passedTimeInMs > 0 && passedTimeInMs < this.interval) {
                this.schoolLearnerService.addOnlineTime(this.loggedSchoolLearnerId, passedTimeInSeconds).subscribe();
            }
        }
    }

    startLogOnlineTime() {
        if (this.getOnlineTimeStartIntervalLocalStorage()) {
            this.startInterval = this.getOnlineTimeStartIntervalLocalStorage();
            this.logPassedTime();
        }
        this.resetStartInterval();
        this.onlineTimeLogInterval = setInterval(() => {
            this.resetStartInterval();
            this.schoolLearnerService.addOnlineTime(this.loggedSchoolLearnerId, (this.interval / 1000)).subscribe();
        }, this.interval);
    }

    resetStartInterval() {
        this.startInterval = new Date();
        localStorage.setItem('onlineTimeStartInterval', JSON.stringify(this.startIntervalForLearner));
    }

    clearOnlineTimeLogInterval() {
        if (this.onlineTimeLogInterval) {
            clearInterval(this.onlineTimeLogInterval);
            this.onlineTimeLogInterval = void 0;
        }
    }

    clear() {
        this._learnerId = undefined;
        this.loggedSchoolLearnerId = undefined;
        this.startInterval = undefined;
        localStorage.removeItem('onlineTimeStartInterval');
    }

    clearAndLogPassedTime() {
        this.clearOnlineTimeLogInterval();
        this.logPassedTime();
        this.clear();
    }

    clearPassedTime() {
        this.clearOnlineTimeLogInterval();
        this.clear();
    }

    get startIntervalForLearner() {
        return {
            id: this.loggedSchoolLearnerId,
            startInterval: this.startInterval
        };
    }

    private getOnlineTimeStartIntervalLocalStorage() {
        const onlineTimeStartIntervalStr = localStorage.getItem('onlineTimeStartInterval');
        const onlineTimeStartInterval = onlineTimeStartIntervalStr ? JSON.parse(onlineTimeStartIntervalStr) : undefined;
        if (onlineTimeStartInterval && this.loggedSchoolLearnerId && this.loggedSchoolLearnerId === onlineTimeStartInterval.id) {
            return new Date(onlineTimeStartInterval.startInterval);
        }
        return undefined;
      }
}
