import { Component, Input } from '@angular/core';

@Component({
  selector: 'eddy-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent {

  @Input() cardView: boolean;

  @Input() shareUrl: string;

  @Input() shareTitle: string;

  @Input() shareText: string;

  emailShare() {
    if (window && window.location) {
      window.location.href = `mailto:?subject=${this.shareTitle ?? ''}&body=${encodeURIComponent(this.shareText)}`;
    }
  }

  facebookShare() {
    if (window) {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.shareUrl}`, '_blank');
    }
  }

  twitterShare() {
    window.open(`https://twitter.com/share?url=${this.shareUrl}`, '_blank');
  }

  linkedInShare() {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${this.shareUrl}`, '_blank');
  }

  whatsappShare() {
    if (window) {
      window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(this.shareText)}`, '_blank');
    }
  }
}
