export enum SchoolStatus {
  NON_PARTNER = 'NON_PARTNER',
  CONTRACT_SCHOOL = 'CONTRACT_SCHOOL',
  VIP = 'VIP',
  PARTNER = 'PARTNER',
  EDDY_CLUB = 'EDDY_CLUB'
}

export function getDisplayName(status: SchoolStatus): string {
  switch (status) {
    case SchoolStatus.NON_PARTNER:
      return 'Non partner';
    case SchoolStatus.CONTRACT_SCHOOL:
      return 'Vertragsschule';
    case SchoolStatus.VIP:
      return 'VIP';
    case SchoolStatus.PARTNER:
      return 'Partner';
    case SchoolStatus.EDDY_CLUB:
      return 'Eddy Club';
    default:
      return '';
  }
}

export function getAllSchoolStatuses(): SchoolStatus[] {
  return [SchoolStatus.NON_PARTNER, SchoolStatus.VIP, SchoolStatus.CONTRACT_SCHOOL, SchoolStatus.PARTNER, SchoolStatus.EDDY_CLUB];
}

export function getSchoolStatusesAbleToScheduleLessons(): SchoolStatus[] {
  return [SchoolStatus.VIP, SchoolStatus.CONTRACT_SCHOOL, SchoolStatus.PARTNER, SchoolStatus.EDDY_CLUB];
}

export function isEddyVerifiedStatus(status: SchoolStatus): boolean {
  return status === SchoolStatus.EDDY_CLUB
    || status === SchoolStatus.PARTNER
    || status === SchoolStatus.VIP;
}
