import { Component, HostListener, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { SidenavService } from '@services/sidenav.service';
import { isTeamMemberUserRole } from '@models/user.model';
import { NavbarDropdownData } from '@models/navbar-dropdown-data.model';
import { GlobalCommunicationService } from '@services/global-communication.service';
import { NavigationEnd, Router } from '@angular/router';
import { AppRoutes } from '../../app.routes';
import { ScreenWidthService } from '@services/screen-width.service';
import { UserInfo } from '@models/user-info.model';

@Component({
  selector: 'eddy-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  readonly voucherLink = 'https://fahrschule-eddy.de/home/fuehrerschein/gutschein';

  isDesktop: boolean;

  @Input() navRoutes;
  @Input() externalNavRoutes;

  isLoggedIn: Observable<boolean>;
  loggedUser: UserInfo | undefined;

  instructorMenuData: NavbarDropdownData = {
    headerText: 'Account',
    items: [
      {
        label: '',
        separator: true,
        fontColor: 'primary'
      },
      {
        url: '/settings/company',
        label: 'Firma'
      },
      {
        url: '/settings/account',
        label: 'Konto'
      },
      {
        event: 'LOGOUT',
        label: 'Abmelden'
      }
    ]
  };


  lastTopPosition = 0;
  private topBuffer = 20;
  navbarDisplayed = true;
  viewportWidth: number;

  eventLabel = 'home-header';

  private readonly fullStudentPageURL = '/' + AppRoutes.StudentsInfo.root;
  private readonly fullInstructorPageURL = '/' + AppRoutes.SchoolsInfo.root;

  constructor(private authService: AuthService,
              private sidenavService: SidenavService,
              private globalCommunicationService: GlobalCommunicationService,
              private router: Router,
              private screenWidthService: ScreenWidthService) {
    this.screenWidthService.isDesktop().subscribe(isDesktop => this.isDesktop = isDesktop);

    // subscribe to authentication status
    this.isLoggedIn = this.authService.isAuthenticatedSubject.asObservable();
    this.authService.userSubject.subscribe(user => this.loggedUser = user);

    this.trackUserChanges();
    this.trackRouteChanges();
    this.onResize();
  }

  toggleSidenav() {
    this.sidenavService.toggle().then(() => {
    });
  }

  onLogout() {
    this.authService.logout();
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    // if (!this.isDesktop) {
    const isScrollUp = this.isScrollUp();

    if ((this.navbarDisplayed && !isScrollUp) || (!this.navbarDisplayed && isScrollUp)) {
      this.globalCommunicationService.navbarDisplayChanged(isScrollUp);
    }

    this.navbarDisplayed = isScrollUp;
    // }

    this.lastTopPosition = window.scrollY;
  }

  onInstructorMenuEvent(eventID: string) {
    if (eventID === 'LOGOUT') {
      this.onLogout();
    }
  }

  private isScrollUp() {
    return this.lastTopPosition <= this.topBuffer || this.lastTopPosition > window.scrollY;
  }

  isSchoolEmployee() {
    return this.loggedUser && isTeamMemberUserRole(this.loggedUser.role);
  }

  private trackUserChanges() {
    this.authService.userSubject.subscribe(user => {
      this.loggedUser = user;
      if (this.isSchoolEmployee()) {
        this.setInstructorNameInMoreMenu();
      }
    });
  }

  private setInstructorNameInMoreMenu() {
    this.instructorMenuData.items[0].label = `${this.loggedUser.firstName}`;
  }

  @HostListener('window:resize')
  onResize() {
    this.viewportWidth = window.innerWidth;
  }

  private trackRouteChanges() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setEventLabel(event.url);
      }
    });
  }

  private setEventLabel(url: string) {
    if (url === this.fullStudentPageURL) {
      this.eventLabel = 'learner-header';
    } else if (url === this.fullInstructorPageURL) {
      this.eventLabel = 'instructor-header';
    } else {
      this.eventLabel = 'home-header';
    }
  }

  get hasLoggedUser(): boolean {
    return this.loggedUser && this.loggedUser.id > 0;
  }

  isInternalRoute(navRouter: any) {
    return navRouter && navRouter.route &&
      (navRouter.route.indexOf('https') === -1 || navRouter.route.indexOf('http') === -1);
  }
}
