import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InfoCard } from '@models/info-card.model';
import { Router } from '@angular/router';

@Component({
  selector: 'eddy-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent {
  @Input() infoCard: InfoCard;

  @Output() selected: EventEmitter<string> = new EventEmitter<string>();

  constructor(private router: Router) {
  }

  infoCardAction() {
    if (this.infoCard.linkTarget) {
      this.router.navigateByUrl(this.infoCard.linkTarget);
    } else {
      this.selected.emit(this.infoCard.anchorTarget);
    }
  }
}
