import { Component, Input } from '@angular/core';
import { AppRoutes } from '../../app.routes';
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'eddy-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() isOnlineTheoryLicenseBPage: boolean;

  linkedinLink = 'https://www.linkedin.com/company/driveddy-gmbh';
  instagramLink = 'https://www.instagram.com/driveddy.app/';
  facebookLink = 'https://www.facebook.com/driveddy/';
  prBlogLink = 'https://blog.driveddy.com';
  supportLink = 'https://driveddy.zendesk.com/hc/de';
  dvfffLink = 'https://www.dvfff.de/zukuenftige-gestattung-von-online-theorieunterricht-an-fahrschulen-im-rahmen-der-fahrausbildung-zum-erhalt-von-fahrerlaubnissen';

  imprintLink = AppRoutes.Root.imprint;
  learnerTermsAndConditionsLink = AppRoutes.Root.learnerTermsAndConditions;
  instructorTermsAndConditionsLink = AppRoutes.Root.instructorTermsAndConditions;
  partnerSchoolPageLink = `/${AppRoutes.SchoolsInfo.root}/${AppRoutes.SchoolsInfo.partner}`;
  dataPrivacyAppLink = AppRoutes.Root.dataPrivacyApp;
  dataPrivacyWebLink = AppRoutes.Root.dataPrivacyWeb;

  currentYear: number = new Date().getFullYear();

  isLoggedIn: Observable<boolean>;

  constructor(private authService: AuthService) {
    // subscribe to authentication status
    this.isLoggedIn = this.authService.isAuthenticatedSubject.asObservable();
  }
}
