import { Learner } from './learner.model';

export class LearnerTheoryPackage {
  id: number;
  learnerId: number;
  learner?: Learner;
  key: string;

  isCurrent: boolean;

  constructor(json) {
    this.id = +json.id;
    this.learnerId = +json.learnerId;

    if (json.learner) {
      this.learner = new Learner(json.learner);
    }

    this.key = json.key;

    this.isCurrent = !!json.unlocked;
  }
}
