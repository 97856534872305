import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'eddy-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent {
  private readonly strokeDasharray = 440;
  readonly primaryColor = '#27b8a1';
  readonly secondaryColor = '#e1e1e1';

  @Input() completenessPercentage: number;

  @Input() chartSize: number;
  @Input() lineThickness = 10;
  @Input() showLabels = true;

  @Input() chartCenterLabel: number;
  @Input() chartCenterColor = false;

  constructor(private sanitizer: DomSanitizer) {
  }

  get strokeDashoffset(): number {
    return this.strokeDasharray - (this.completenessPercentage / 100) * this.strokeDasharray;
  }

  get donutChartColor(): string {
    if (this.completenessPercentage < 50) {
      return this.primaryColor;
    }

    return this.secondaryColor;
  }

  get slice1Degrees(): number {
    if (this.completenessPercentage < 50) {
      return this.completenessPercentage / 100 * 360 + 90;
    }

    return 90;
  }

  get slice1Color(): string {
    if (this.completenessPercentage < 50) {
      return this.secondaryColor;
    }

    return this.primaryColor;
  }

  get slice1Clip() {
    return this.sanitizer.bypassSecurityTrustStyle(`rect(0 ${this.chartSize}px ${this.chartSize / 2}px 0)`);
  }

  get slice2Degrees(): number {
    if (this.completenessPercentage < 50) {
      return 0;
    }

    return this.completenessPercentage / 100 * 360;
  }

  get slice2Color(): string {
    if (this.completenessPercentage < 50) {
      return this.secondaryColor;
    }

    return this.primaryColor;
  }

  get slice2Clip() {
    return this.sanitizer.bypassSecurityTrustStyle(`rect(0 ${this.chartSize / 2}px ${this.chartSize}px 0)`);
  }
}
