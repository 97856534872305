export class Answer {
  id: number;

  answerDate: Date;

  userId: number;

  choiceId: number;
  openAnswer: string;

  constructor(json) {
    this.id = +json.id;

    this.answerDate = new Date(json.answerDate);

    this.userId = +json.userId;

    this.choiceId = +json.choiceId;
    this.openAnswer = json.openAnswer;
  }
}
