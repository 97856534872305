<nav
  class="navbar"
  [class.shadow]="lastTopPosition > 0"
  [class.hidden]="isDesktop || (!isDesktop && !navbarDisplayed)"
>
  <div class="container header-container">
    <div class="drawer-button" (click)="toggleSidenav()">
      <i class="fal fa-bars fa-lg"></i>
    </div>
  </div>
</nav>
