export interface Time {
  hour: number | undefined;
  minute: number | undefined;
}

export function emptyTime(): Time {
  return {
    hour: undefined,
    minute: undefined,
  };
}

export function nowTime(): Time {
  const now = new Date();

  return {
    hour: now.getHours(),
    minute: now.getMinutes(),
  };
}

export function addMinutes(time: Time, minutes: number): Time {
  // add the new minutes, not allowing the time to overflow
  const newMinutes = Math.min(
    24 * 60 - 1,
    time.hour * 60 + time.minute + minutes
  );
  return {
    hour: Math.floor(newMinutes / 60),
    minute: newMinutes % 60,
  };
}

export function toString(time: Time, includeSeconds?: boolean): string {
  const hour = time.hour < 10 ? `0${time.hour}` : time.hour;
  const minute = time.minute < 10 ? `0${time.minute}` : time.minute;

  const str = `${hour}:${minute}`;
  if (includeSeconds) {
    return `${str}:00`;
  }

  return str;
}

export function isBefore(time: Time, referenceTime: Time): boolean {
  if (time.hour < referenceTime.hour) {
    return true;
  }

  if (time.hour === referenceTime.hour && time.minute < referenceTime.minute) {
    return true;
  }

  return false;
}

export function isFilled(time: Time): boolean {
  return isNumeric(time.hour) && isNumeric(time.minute);
}

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
