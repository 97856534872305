import { AppRoutes } from '../app.routes';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { SpecialCharactersReplacementService } from '@services/special-characters-replacement.service';
import { VSchool } from '@models/v-school.model';
import { SchoolStatus } from '@models/school-status.model';
import { School } from '@models/school.model';
import { SidenavService } from '@services/sidenav.service';

@Injectable()
export class SchoolProfileService {

  constructor(private router: Router,
              private specialCharactersReplacementService: SpecialCharactersReplacementService,
              private sidenavService: SidenavService) {
  }

  goToVSchoolPublicProfile(school: VSchool) {
    const link = this.getVSchoolPublicProfileLink(school);
    this.router.navigateByUrl(link);
  }

  private getVSchoolPublicProfileLink(school: VSchool): string {
    let url = `${AppRoutes.Root.schools}/${this.replaceSpecialCharacters(school.addressLocality)}/${school.uri}`;
    if (school.branchId) {
      url += `?branch=${school.branchId}`;
    }

    return this.encodeUrl(url);
  }

  goToPublicProfile(school: School) {
    const link = this.getPublicProfileLink(school);
    this.sidenavService.close();
    this.router.navigateByUrl(link);
  }

  getPublicProfileLink(school: School): string {
    const locality = this.findLocality(school);

    if (school.status === SchoolStatus.NON_PARTNER) {
      return this.encodeUrl(`${AppRoutes.Root.schools}/${this.replaceSpecialCharacters(locality)}/${school.uri}`);
    }

    const branchId = this.findBranchID(school);

    return this.encodeUrl(`${AppRoutes.Root.vipSchools}/${this.replaceSpecialCharacters(locality)}/${school.uri}?branch=${branchId}`);
  }

  private findLocality(school: School) {
    let locality = school['locality'];
    if (!locality) {
      if (school.branches && school.branches.length > 0) {
        locality = school.address.locality;
      } else if (school.address) {
        locality = school.address.locality;
      }
    }
    return locality;
  }

  private findBranchID(school: School) {
    let branchId = school.firstBranchId;
    if (!branchId) {
      if (school.branches && school.branches.length > 0) {
        branchId = school.branches[0].id;
      }
    }
    return branchId;
  }

  private replaceSpecialCharacters(text: string) {
    return this.specialCharactersReplacementService.replaceSpecialCharacters(text);
  }

  private encodeUrl(url: string) {
    return encodeURI(url).replace(/[()]/g, c => '%' + c.charCodeAt(0).toString(16));
  }
}
