import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class GlobalCommunicationService {

  private containerClassSubject: ReplaySubject<string> = new ReplaySubject<string>();
  private navbarDisplayedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() {
  }

  setContainerClass(cssClass: string) {
    this.containerClassSubject.next(cssClass);
  }

  containerClassChanges(): Observable<string> {
    return this.containerClassSubject.asObservable();
  }

  navbarDisplayChanged(navbarDisplayed: boolean) {
    this.navbarDisplayedSubject.next(navbarDisplayed);
  }

  navbarDisplayChanges(): Observable<boolean> {
    return this.navbarDisplayedSubject.asObservable();
  }
}
