import { TheoryLessonTopic } from '@models/theory-lesson-topic.model';
import { PaymentStatus } from './payment-status.model';
import { environment as env } from '@environments/environment';

export class InstructorTheoryLesson {
  topic: TheoryLessonTopic;
  instructorTheoryTopicId: number;
  instructorTheoryTopicKey: string;
  paymentLink: string;
  paymentStatus: PaymentStatus;
  priceInCents: number;
  datePaid?: Date;

  isInfoPageVideo?: boolean;

  constructor(json) {
    this.topic = new TheoryLessonTopic(json.topic);

    this.instructorTheoryTopicId = +json.instructorTheoryTopicId;
    this.instructorTheoryTopicKey = json.instructorTheoryTopicKey;
    this.paymentLink = json.paymentLink;
    this.paymentStatus = json.paymentStatus;
    this.priceInCents = +json.priceInCents;

    if (json.datePaid) {
      this.datePaid = new Date(json.datePaid);
    }

    if (json.isInfoPageVideo !== undefined) {
      this.isInfoPageVideo = !!json.isInfoPageVideo;
    }
  }

  isPaid(): boolean {
    return this.paymentStatus === PaymentStatus.PAID;
  }

  isPending(): boolean {
    return this.paymentStatus === PaymentStatus.PENDING;
  }

  paymentLinkWithRedirect(): string {
    const redirectUrl = encodeURIComponent(
      `${env.website}/instructors/theorieunterichtsvideo/${this.topic.key}?success=true`
    );

    return `${this.paymentLink}?redirectUrl=${redirectUrl}`;
  }
}

export const compareByTopicNumber = (a, b) => {
  if (a.topic.orderNumber < b.topic.orderNumber) {
    return -1;
  }

  return 1;
};
