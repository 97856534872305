<div class="social-share" *ngIf="!cardView; else cardViewT">
  <div class="email" (click)="emailShare()">
    <i class="fas fa-envelope"></i>
  </div>

  <div class="facebook" (click)="facebookShare()">
    <i class="fab fa-facebook-f"></i>
  </div>

  <div class="whatsapp" (click)="whatsappShare()">
    <i class="fab fa-whatsapp"></i>
  </div>
</div>

<ng-template #cardViewT>
  <div class="social-share-card">
    <div class="social-share-text">
      Teilen:
    </div>
    <div class="social-share-icons">
      <div class="social-share-icon" (click)="facebookShare()">
        <i class="fab fa-facebook-f"></i>
      </div>

      <div class="social-share-icon" (click)="linkedInShare()">
        <i class="fab fa-linkedin"></i>
      </div>

      <div class="social-share-icon" (click)="twitterShare()">
        <i class="fab fa-twitter"></i>
      </div>

      <div class="social-share-icon" (click)="emailShare()">
        <i class="fas fa-envelope"></i>
      </div>
    </div>
  </div>
</ng-template>
