import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Params, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import {
  isLearner,
  isTeamMemberUserRole,
  User,
  USER_ROLE,
} from '@models/user.model';
import { environment as env } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map, tap } from 'rxjs/operators';
import { AppRoutes } from '../app.routes';
import { SidenavService } from '@services/sidenav.service';
import { TeamMember } from '@models/team-member.model';
import { UserInfo } from '@models/user-info.model';
import { Learner } from '@models/learner.model';
import { isPlatformServer } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { SentryUtils } from '@services/sentry-utils.service';
import { OnlineTimeLogService } from './online-time-log.service';

@Injectable()
export class AuthService {
  private readonly localStorageKeyUser = 'user';
  private readonly localStorageKeyTeamMember = 'teamMember';
  private readonly localStorageKeyLearner = 'learner';
  private readonly cookieServiceKey = 'eddy';
  private readonly eddyUserCookieServiceKey = 'eddy_user';

  private token: string;
  private auth_headers: HttpHeaders;
  destinationUrl: string;
  // homeUrl: string;
  operator: User;
  teamMember: TeamMember;
  learner: Learner;

  userSubject = new ReplaySubject<UserInfo>(1);
  teamMemberSubject = new ReplaySubject<TeamMember>(1);
  learnerSubject = new ReplaySubject<Learner>(1);

  isAuthenticatedSubject = new BehaviorSubject<boolean>(!!this.getUser().role);
  private _currentUserId: number;
  private readonly isServer: boolean;

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private sidenavService: SidenavService,
    private onlineTimeLogService: OnlineTimeLogService,
    @Optional() @Inject(REQUEST) private request,
    @Inject(PLATFORM_ID) platformId
  ) {
    this.refreshTheLoggedUserData();
    this.userSubject.next(this.getUser());
    this.isServer = isPlatformServer(platformId);
  }

  public refreshTheLoggedUserData() {
    const user = this.getUser();
    if (!user.id) {
      return;
    }

    if (!this.authTokenExists()) {
      return;
    }

    if (isTeamMemberUserRole(user.role)) {
      this.reloadLoggedTeamMember().subscribe();
    } else if (isLearner(user.role)) {
      this.loadLearner().subscribe();
    }
  }

  private authTokenExists() {
    return (
      (this.auth_headers && this.auth_headers.get('Authorization')) ||
      !!this.cookieService.get(this.cookieServiceKey)
    );
  }

  get currentUserId(): number {
    if (this._currentUserId) {
      return this._currentUserId;
    }

    const user = JSON.parse(localStorage.getItem(this.localStorageKeyUser));
    return user.id;
  }

  getUser(): UserInfo {
    if (this.isServer) {
      let user: string;
      const rawCookies = !!this.request.headers['cookie']
        ? this.request.headers['cookie']
        : '';
      if (rawCookies.indexOf(this.eddyUserCookieServiceKey) !== -1) {
        const userKeySubstring = rawCookies.substring(
          rawCookies.indexOf(this.eddyUserCookieServiceKey) +
            (this.eddyUserCookieServiceKey.length + 1)
        );
        if (userKeySubstring.indexOf(';') !== -1) {
          user = userKeySubstring.substring(0, userKeySubstring.indexOf(';'));
        } else {
          user = userKeySubstring;
        }
      }

      // check if user is authenticated and user object is already in cookies
      if (this.getHeaders() !== null && user) {
        const decodedUser = decodeURIComponent(user);
        const loggedUser = new UserInfo(JSON.parse(decodedUser));
        this.userSubject.next(loggedUser);
        this._currentUserId = loggedUser.id;
        if (isTeamMemberUserRole(loggedUser.role)) {
          this.reloadLoggedTeamMember().subscribe();
        } else if (isLearner(loggedUser.role) && this.getLearner() === null) {
          this.loadLearner().subscribe();
        }
        return loggedUser;
      }
    } else {
      // check if user is authenticated and user object is already in localStorage
      if (
        this.getHeaders() !== null &&
        localStorage.getItem(this.localStorageKeyUser) !== null
      ) {
        return new UserInfo(
          JSON.parse(localStorage.getItem(this.localStorageKeyUser))
        );
      }
    }

    // if there is no user object in localStorage,
    // return an empty dummy user object to prevent errors
    return new UserInfo({
      doi: true,
      id: 0,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      gender: '',
      role: null,
    });
  }

  private getTeamMember(): TeamMember {
    // check if user is authenticated and user object is already in localStorage
    if (
      this.getHeaders() !== null &&
      localStorage.getItem(this.localStorageKeyTeamMember) !== null
    ) {
      return new TeamMember(
        JSON.parse(localStorage.getItem(this.localStorageKeyTeamMember))
      );
    }

    return null;
  }

  private getLearner(): Learner {
    // check if user is authenticated and user object is already in localStorage
    if (
      this.getHeaders() !== null &&
      localStorage.getItem(this.localStorageKeyLearner) !== null
    ) {
      return new Learner(
        JSON.parse(localStorage.getItem(this.localStorageKeyLearner))
      );
    }

    return null;
  }

  getToken(): string {
    return this.cookieService.get(this.cookieServiceKey);
  }

  getHeaders() {
    if (this.auth_headers && this.auth_headers.get('Authorization')) {
      return this.auth_headers;
    }

    let token = this.cookieService.get(this.cookieServiceKey);
    if (this.isServer) {
      const rawCookies = !!this.request.headers['cookie']
        ? this.request.headers['cookie']
        : '';
      if (rawCookies.indexOf(this.cookieServiceKey) !== -1) {
        const authKeySubstring = rawCookies.substring(
          rawCookies.indexOf(this.cookieServiceKey) +
            (this.cookieServiceKey.length + 1)
        );
        if (authKeySubstring.indexOf(';') !== -1) {
          token = authKeySubstring.substring(0, authKeySubstring.indexOf(';'));
        } else {
          token = authKeySubstring;
        }
      }
    }

    if (token) {
      const headers = new HttpHeaders();
      this.auth_headers = headers.append('Authorization', 'Bearer ' + token);
      this.token = token;
      return this.auth_headers;
    }
    return null;
  }

  getHttpOptions(
    _parameters: Map<string, string> = null,
    addAuthorizationHeader: boolean = true,
    responseType?: string
  ): any {
    let params = new HttpParams(); // .set('key', env.googleApiKey);

    if (_parameters) {
      _parameters.forEach(function (value, key) {
        params = params.set(key, value);
      });
    }

    return {
      params: params,
      headers: addAuthorizationHeader ? this.getHeaders() : null,
      responseType: responseType,
    };
  }

  registerToken(_token: string) {
    this.cookieService.delete(this.cookieServiceKey, '/');

    const expiration = new Date();
    expiration.setDate(expiration.getDate() + 1);

    this.cookieService.set(this.cookieServiceKey, _token, expiration, '/');
    this.token = _token;
  }

  registerUserCookie(user: UserInfo) {
    this.cookieService.delete(this.eddyUserCookieServiceKey, '/');

    const expiration = new Date();
    expiration.setDate(expiration.getDate() + 1);

    this.cookieService.set(
      this.eddyUserCookieServiceKey,
      JSON.stringify({ id: user.id, role: user.role }),
      expiration,
      '/'
    );
  }

  login(email: string, pwd: string, redirect: boolean, granted, denied) {
    const params = new HttpParams(); // .set('key', env.googleApiKey);
    return this.http
      .post(
        env.api + '/login',
        { email: email, password: pwd },
        { params: params }
      )
      .pipe(
        map((_response: any) => {
          this.token = _response.data['token'];
          this.operator = _response.data['user'];
          this._currentUserId = this.operator.id;
          localStorage.setItem('user', JSON.stringify(this.operator));

          let headers = new HttpHeaders();
          headers = headers.append('Content-Type', 'application/json');
          this.auth_headers = headers.append(
            'Authorization',
            'Bearer ' + this.token
          );

          this.registerToken(this.token.toString());

          this.isAuthenticatedSubject.next(true);
          const user = this.getUser();
          this.userSubject.next(user);

          this.registerUserCookie(user);

          granted(this.operator);
          return this.operator;
        })
      )
      .subscribe(
        async () => {
          if (isTeamMemberUserRole(this.operator.role)) {
            await this.reloadLoggedTeamMember().toPromise();
          } else if (isLearner(this.operator.role)) {
            await this.loadLearner().toPromise();
          }

          if (redirect && this.homeUrl) {
            if (this.destinationUrl) {
              this.router
                .navigate([this.destinationUrl.split('?')[0]], {
                  queryParams: this.extractQueryParams(this.destinationUrl),
                })
                .then();
            } else {
              let extras = {};
              if (isLearner(this.operator.role)) {
                extras = {
                  queryParams: { openAcceptPriceListChanges: true },
                };
              }
              this.router.navigate([this.homeUrl], extras).then();
            }
            this.destinationUrl = null;
          }
        },
        (_error) => {
          denied(_error.error);
          this.router.navigate([AppRoutes.Root.login]).then();
          throwError(_error);
        }
      );
  }

  logout(doNotDeleteDestinationUrl?: boolean, isAccountDeletion = false) {
    if (isAccountDeletion) {
      this.onlineTimeLogService.clearPassedTime();
    } else {
      this.onlineTimeLogService.clearAndLogPassedTime();
    }

    this.removeToken();

    if (isAccountDeletion) {
      this.router.navigate(['']).then();
    } else {
      this.router.navigate([AppRoutes.Root.login]).then();
    }

    this.sidenavService.close();
    if (!doNotDeleteDestinationUrl) {
      this.destinationUrl = null;
    }
  }

  removeToken() {
    localStorage.removeItem(this.localStorageKeyUser);
    localStorage.removeItem(this.localStorageKeyTeamMember);
    localStorage.removeItem(this.localStorageKeyLearner);

    this.cookieService.delete(this.cookieServiceKey, '/');
    this.cookieService.delete(this.eddyUserCookieServiceKey, '/');
    this.operator = null;
    this.auth_headers = null;
    this.token = null;
    this.isAuthenticatedSubject.next(false);
    this.userSubject.next(this.getUser());
  }

  verifyUserViaCode(code: string) {
    return this.http
      .post<any>(`${env.api}/verify/${code}`, {}, this.getHttpOptions())
      .pipe(
        map((response) => {
          this.markLocalUserAsVerified();
          return response['data'];
        }),
        catchError((error) => throwError(error))
      );
  }

  verifyUserViaHash(hash: string) {
    return this.http
      .post<any>(`${env.api}/verify/hash/${hash}`, {}, this.getHttpOptions())
      .pipe(
        map((response) => {
          this.markLocalUserAsVerified();
          return response['data'];
        }),
        catchError((err) => throwError(err))
      );
  }

  resendVerificationCode() {
    return this.http
      .post<any>(`${env.api}/verify/resend`, {}, this.getHttpOptions())
      .pipe(
        map((res) => res['data']),
        catchError((err) => throwError(err))
      );
  }

  forgotPassword(email: string) {
    return this.http
      .post<any>(
        `${env.api}/user/password/forgot`,
        { email: email },
        this.getHttpOptions()
      )
      .pipe(
        map((res) => res['data']),
        catchError((err) => throwError(err))
      );
  }

  resetPassword(pwd: string, token: string) {
    return this.http
      .post<any>(
        `${env.api}/user/password/reset`,
        {
          password: pwd,
          token: token,
        },
        this.getHttpOptions()
      )
      .pipe(
        map((res) => res['data']),
        catchError((err) => throwError(err))
      );
  }

  resendVerificationCodeForEmail(email: string) {
    return this.http
      .post<any>(
        `${env.api}/verify/resend/email`,
        { email: email },
        this.getHttpOptions()
      )
      .pipe(
        map((res) => res['data']),
        catchError((err) => throwError(err))
      );
  }

  updateUser(user: UserInfo) {
    // map the fields to the underscore notation because the backend system uses that notation
    user['first_name'] = user.firstName;
    user['last_name'] = user.lastName;

    return this.http
      .put<UserInfo>(`${env.api}/user`, user, this.getHttpOptions())
      .pipe(
        map((response) => response['data']),
        tap((res) => {
          localStorage.setItem('user', JSON.stringify(res));
          this.userSubject.next(this.getUser());
        }),
        catchError((error: Response) => throwError(error))
      );
  }

  updatePassword(userId: number, currentPassword: string, newPassword: string) {
    const body = {
      currentPassword: currentPassword,
      newPassword: newPassword,
    };

    return this.http
      .put(`${env.api}/user/${userId}/password`, body, this.getHttpOptions())
      .pipe(
        map((res) => res['data']),
        catchError((error: Response) => throwError(error))
      );
  }

  userOrAccountWithEmailExists(email: string): Observable<boolean> {
    return this.http
      .get<boolean>(
        `${env.api}/user/email/${email}/exists`,
        this.getHttpOptions()
      )
      .pipe(
        map((res) => !!res['data']),
        catchError((error: Response) => throwError(error))
      );
  }

  userHasAnyRole(neededRoles: USER_ROLE[], userRole: USER_ROLE) {
    return neededRoles.indexOf(userRole) !== -1;
  }

  private markLocalUserAsVerified() {
    const user = this.getUser();
    if (!user) {
      return;
    }

    user.doi = true;

    localStorage.setItem(this.localStorageKeyUser, JSON.stringify(user));
    this.userSubject.next(user);
  }

  reloadLoggedTeamMember(updateTeamMemberId?: number): Observable<TeamMember> {
    const currentTeamMember = this.getTeamMember();
    if (
      currentTeamMember &&
      updateTeamMemberId &&
      updateTeamMemberId !== currentTeamMember.id
    ) {
      return;
    }

    return this.http
      .get<TeamMember>(
        `${env.apiSchoolService}/team_members/user/${this.currentUserId}`,
        this.getHttpOptions()
      )
      .pipe(
        map((it) => new TeamMember(it['data'])),
        map((teamMember) => {
          try {
            this.teamMember = teamMember;
            if (this.teamMember) {
              localStorage.setItem(
                this.localStorageKeyTeamMember,
                JSON.stringify(this.teamMember)
              );
              localStorage.setItem(
                this.localStorageKeyUser,
                JSON.stringify(this.teamMember.user)
              );
              this.registerUserCookie(this.teamMember.user);
              this.teamMemberSubject.next(this.teamMember);
              this.userSubject.next(this.teamMember.user);

              return teamMember;
            }

            return null;
          } catch (err) {
            SentryUtils?.captureSentryException(
              new Error('could not parse team member data: ' + err)
            );
            throw err;
          }
        }),
        catchError((err: any) => {
          SentryUtils?.captureSentryException(
            new Error(
              'error while loading team member: ' + err + ': ' + err?.error
            )
          );
          return throwError(err?.error || err);
        })
      );
  }

  private loadLearner(): Observable<Learner> {
    return this.http
      .get<Learner>(
        `${env.apiSchoolService}/learners/user/${this.currentUserId}`,
        this.getHttpOptions()
      )
      .pipe(
        map((it: any) => {
          try {
            return new Learner(it['data']);
          } catch (err) {
            SentryUtils?.captureSentryException(
              new Error('could not parse learner json data: ' + err)
            );
            throw err;
          }
        }),
        map((learner) => {
          try {
            this.learner = learner;
            if (this.learner) {
              localStorage.setItem(
                this.localStorageKeyLearner,
                JSON.stringify(this.learner)
              );
              localStorage.setItem(
                this.localStorageKeyUser,
                JSON.stringify(this.learner.user)
              );
              this.registerUserCookie(this.learner.user);

              this.learnerSubject.next(this.learner);
              this.userSubject.next(this.learner.user);

              return learner;
            }

            return null;
          } catch (err) {
            SentryUtils?.captureSentryException(
              new Error('could not parse learner data: ' + err)
            );
            throw err;
          }
        }),
        catchError((err: any) => {
          SentryUtils?.captureSentryException(
            new Error('error while loading learner: ' + err + ': ' + err?.error)
          );
          return throwError(err?.error || err);
        })
      );
  }

  areCookiesAllowed(): boolean {
    return true;
  }

  get homeUrl(): string {
    const user = this.getUser();

    if (user.role === USER_ROLE.PLATFORM) {
      return env.landingInternal;
    } else if (isTeamMemberUserRole(user.role)) {
      return env.landing;
    } else if (isLearner(user.role)) {
      return env.learnerLanding;
    }

    return '';
  }

  extractQueryParams(url: string): Params {
    if (!url.includes('?')) {
      return {};
    }
    const params = url.split('?')[1];
    const queryParams = params.split('&');
    const queryParamsObj = {};
    queryParams.forEach((qParam) => {
      queryParamsObj[qParam.split('=')[0]] = qParam.split('=')[1];
    });

    return queryParamsObj;
  }
}
