import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  getAllPaymentStatuses,
  getDisplayName,
  PaymentStatus,
} from '@models/payment-status.model';

@Component({
  selector: 'eddy-payment-status-dropdown',
  templateUrl: 'eddy-payment-status-dropdown.component.html',
  styleUrls: ['./eddy-payment-status-dropdown.component.scss'],
})
export class EddyPaymentStatusDropdownComponent {
  paymentStatuses: PaymentStatus[] = getAllPaymentStatuses();

  @Input() status: PaymentStatus;

  @Input() fullWidth: boolean;

  @Input() roundedCorners: boolean;

  @Input() placeholder = 'Zahlungsstatus';

  @Input() class: string;

  @Output() paymentStatusChanged: EventEmitter<PaymentStatus> =
    new EventEmitter<PaymentStatus>();

  onChange(paymentStatus: PaymentStatus) {
    this.paymentStatusChanged.emit(paymentStatus);
  }

  getPaymentStatusDisplayName(paymentStatus: PaymentStatus) {
    return getDisplayName(paymentStatus);
  }
}
