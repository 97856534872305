import * as Sentry from '@sentry/browser';

export class SentryUtils {

  static addSentryBreadcrumb(message: string) {
    try {
      if (Sentry?.addBreadcrumb) {
        Sentry.addBreadcrumb({
          category: 'theory-topic-video',
          message: `${message} [time: ${new Date()}]`,
          level: Sentry.Severity.Info
        });
      }
    } catch (e) {
    }
  }

  static captureSentryEvent(message: string) {
    try {
      if (Sentry?.captureEvent) {
        Sentry.captureEvent({
          message: `${message} [time: ${new Date()}]`,
        });
      }
    } catch (e) {
    }
  }

  static captureSentryException(err: Error) {
    try {
      if (Sentry?.captureException) {
        Sentry.captureException(new Error(`${err} [time: ${new Date()}]`));
      }
    } catch (e) {
    }
  }
}
