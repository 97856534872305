export class AppRoutes {
  static Root = class {
    static signUp = 'signup';
    static login = 'login';
    static register = 'registrieren';
    static user = 'user';
    static learnerRegister = 'student/register';

    static schools = 'fahrschulen';
    static vipSchools = 'vip-fahrschulen';

    static advertising = 'advertising';

    static calendar = 'calendar';
    static students = 'students';
    static settings = 'settings';

    static companySettings = 'settings/company';
    static schoolSettings = 'settings/school';

    static schoolCode = 'school_code/:schoolCode';

    static learnerTermsAndConditions = 'agb';
    static instructorTermsAndConditions = 'agbs-fuer-fahrlehrer';

    static dataPrivacyApp = 'datenschutz-app';
    static dataPrivacyWeb = 'datenschutz-web';
    static imprint = 'impressum';

    static acceptTermsAndConditions = 'accepttermsandconditions';

    static acceptLessons = 'acceptlessons';
    static rejectLessons = 'rejectlessons';

    static acceptOnboardingRequest = 'accept-onboarding-request';

    static downloadLicenseApplication = 'download-license-application';

    static theoryTopicQuestion = 'theory-topic-questions';

    static leadForm = 'lead-form';

    static eddyAcademy = 'online-theorie';

    static onlineTheoryLicenseB = 'theorie-pflichtunterricht-klasse-b';

    static thankYouForYourInterest = 'danke-fuer-dein-interesse';

    static thankYouForYourRegistration = 'danke-fuer-deine-anmeldung';

    static thankYouForYourInquiry = 'danke-fuer-deine-anfrage';

    static purchaseThankYou = 'thank-you';

    static eddyClubSchoolCalendar = 'eddyclub-online-theorie-kalender';
  };

  static StudentsInfo = class {
    static root = 'fahrschueler';
  };

  static SchoolsInfo = class {
    static root = 'fahrlehrer';
    static partner = 'fahrschulpartner';
    static software = 'verwaltungssoftware';
    static onlineTheoryService = 'online-theorie-service';
    static theoryLessonVideos = 'theorie-unterricht';
  };

  static LicenseRequest = class {
    static preise = 'fuehrerschein/preise';
  };

  static Products = class {
    static root = 'products';
    static License = class {
      static edit = 'products/license/edit';
      static add = 'products/license/add';
    };
    static Bundle = class {
      static select = 'products/bundle/select';
      static add = 'products/bundle/add';
      static create = 'products/bundle/create';
    };
  };
  static Internal = class {
    static root = 'internal';
    static schoolsPart = 'schools';
    static learnersPart = 'learners';
    static schools = 'internal/schools';
    static teamMembers = 'internal/teammembers';
    static teamMembersPart = 'teammembers';
    static instructors = 'internal/instructors';
    static instructorsPart = 'instructors';
    static licenses = 'internal/licenses';
    static licensesPart = 'licenses';
    static pricelistsPart = 'pricelists';
    static edit = 'edit';
    static bookings = 'internal/bookings';
    static users = 'internal/users';
    static usersPart = 'users';
    static appFeedbacks = 'internal/appfeedbacks';
    static accounting = 'internal/accounting';
    static theoryProfilePart = 'theoryprofile';
  };

  static VendorConsole = class {
    static dashboard = 'dashboard';
  };

  static InstructorsInternal = class {
    static root = 'instructors';
    static dashboard = 'dashboard';
    static generalInfo = 'general';
    static theoryDashboard = 'theoryprogress';
    static learnerTheoryProfile = 'theoryprogress/:id';
    static licenses = 'licenses';
    static license = 'licenses/:licenseId';
    static pricelists = 'pricelists';
    static pricelist = 'pricelists/:priceListId';
    static colleagues = 'colleagues';
    static colleague = 'colleagues/:id';
    static learners = 'learners';
    static learner = 'learners/:id';
    static data = 'data';
    static learnerDataView = 'learners/:id/data';
    static calendar = 'calendar';
    static bookings = 'bookings';
    static accounting = 'accounting';
    static reports = 'reports';
    static onlineTheoryBookings = 'onlinetheory/bookings';
    static onlineTheoryAttendances = 'onlinetheory/attendances';
    static dayProofs = 'dayproofs';
    static bookingDetails = 'bookings/:bookingId';
    static trainingConfirmations = 'trainingconfirmations';
    static eddyclub = 'eddyclub';
    static eddyclubCalendar = 'eddyclub/calendar';
    static eddyclubSchools = 'eddyclubschools';
    static exams = 'exams';
    static practiceExams = 'practiceexams';
    static theoryExams = 'theoryExams';
    static lessons = 'lessons';
    static theoryLessonVideoDetailsPageWithKey =
      'theorieunterichtsvideo/:topicKey';
    static theoryLessonVideoDetailsPage = 'theorieunterichtsvideo';
  };

  static LearnersInternal = class {
    static root = 'learners';
    static profile = 'profile';
    static theoryLearning = 'theorylearning';
    static lessons = 'lessons';
    static lessonPage = 'lessons/:lessonId';
    static personal = 'personal';
    static priceListChangeOverview = 'pricelistchangeoverview';
    static bookings = 'bookings';
    static schoolProfile = 'schoolProfile';
    static theoryVideos = 'theoryvideos';
    static theoryLessonVideoDetailsPageWithKey = 'theoryvideos/:topicKey';
  };

  static Questions = class {
    static root = 'questions';
  };

  static AppFeedbacks = class {
    static root = 'appfeedbacks';
  };

  static Accounting = class {
    static root = 'accounting';
  };

  static Cars = class {
    static root = 'cars';
  };

  static EddyAcademyLearner = class {
    static root = 'onlinetheorie';
    static additionalTheoryLessonDetailsWithKey =
      'zusatzstofftheoriestunde/:topicKey';
    static additionalTheoryLessonDetailsPage = 'zusatzstofftheoriestunde';
    static theoryLessonDetailsPageWithKey = 'theoriestunde/:topicKey';
    static theoryLessonDetailsPage = 'theoriestunde';
    static dashboardPage = 'dashboard';
    static welcome = 'willkommen';
  };

  static EddyAcademyInstructor = class {
    static root = 'onlinetheorie';
    static theoryLessonDetailsPage = 'theoriestunde';
    static theoryLessonDetailsPageWithId = 'theoriestunde/:id';
    static theoryLessonDetailsGuestPage = 'eddyclubtheoriestunde';
    static theoryLessonDetailsGuestPageWithId = 'eddyclubtheoriestunde/:id';
  };

  static Offers = class {
    static root = 'offers';
  };

  static shared = class {
    static create = 'create';
  };
}
