import { IntegrationFormResponseType } from './integration-form-response-type.model';
import { School } from './school.model';

export class SchoolOnboardingIntegrationFormPreference {
    id?: number;

    schoolId: number;
    school?: School;

    responseType: IntegrationFormResponseType;
    response: string;

    showInProfile: boolean;
    emailNotification: boolean;
    shortVersion: boolean;

    emailsToNotify: string;

    constructor(json?: any) {
      if (!json) {
        return;
      }
      this.id = +json.id;

      this.schoolId = +json.schoolId;
      if (json.school) {
        this.school = new School(json.school);
      }

      this.responseType = json.responseType;
      this.response = json.response;

      this.showInProfile = !!json.showInProfile;
      this.emailNotification = !!json.emailNotification;
      this.shortVersion = !!json.shortVersion;

      this.emailsToNotify = json.emailsToNotify;
    }

    isResponseType(type: IntegrationFormResponseType) {
      return this.responseType === type;
    }

    getResponseForType(type: IntegrationFormResponseType): string {
        if (!this.response || this.responseType !== type) {
          return '';
        }
        return this.response;
    }
}

export function defaultSchoolOnboardingIntegrationFormPreference(schoolId: number) {
  return new SchoolOnboardingIntegrationFormPreference({
    schoolId: schoolId,
    responseType: IntegrationFormResponseType.SUCCESS_MESSAGE,
    response: 'Vielen Dank für deine Anfrage. Wir melden uns schnellstmöglich bei dir.',
    showInProfile: true,
    emailNotification: false,
    shortVersion: false,
  });
}
