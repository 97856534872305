import { Injectable } from '@angular/core';
import { environment as env } from '@environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { DateUtils } from './date-utils.service';

@Injectable()
export class ReportService {
    private readonly apiPath = `${env.apiSchoolService}`;

    constructor(private api: ApiService) {
    }

    generateInstructorWorkloadReport(instructorId: number, dateFrom: Date, dateTo: Date): Observable<Blob> {
        const params = new Map<string, any>();
        params.set('dateFrom', DateUtils.dateOnlyToString(dateFrom));
        params.set('dateTo', DateUtils.dateOnlyToString(dateTo));
        return this.api.getBlob(`${this.apiPath}/reports/instructor_workload/${instructorId}`, params);
      }
}
