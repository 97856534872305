<div class="floating-btn">
  <button class="buy-btn" (click)="onOpenSignUpDialog()">
    Jetzt Online loslegen
  </button>
</div>

<div class="panel panel-1 grey top-panel">
  <div class="container xl-container">
    <h4 class="section-title">Theorie-Pflichtunterricht Führerschein Klasse B -
      Jetzt Online!
    </h4>

    <p class="section-content">
      Über 1.000.000 Fahrschüler warten gerade in Deutschland auf ihren Führerschein-Ausbildungsplatz.<br/>Verkürze jetzt
      <b>deine Wartezeit</b> und spare gleichzeitig <b>bis zu 400€*</b>.
    </p>
  </div>
</div>

<div class="panel panel-2">
  <div class="container xl-container">
    <div class="row no-side-space spacing-between">
      <div class="col-12 col-lg-8 no-side-space">
        <div class="video-wrapper">
          <img [class.display-none]="videoPlaying" src="/assets/academy/buy_package_cover.png"/>
          <button class="play-btn" [class.display-none]="videoPlaying" (click)="playVideo()">
            <i class="fas fa-play"></i>
          </button>

          <eddy-video-player #videoPlayer
                             [class.display-none]="!videoPlaying"
                             videoUrl="https://static.driveddy.com/videos/202103_drivEddy-SalesVideo.mp4"
                             [showControls]="false"
                             (videoEnd)="onOfferVideoEnd()">
          </eddy-video-player>
        </div>

        <ul class="features">
          <li>
            <img class="tick" src="/assets/fancy-check.png" /><span class="item-content">Spare <b>über 400€*</b> bei der
              Eddy Club Fahrschule von drivEddy</span>
          </li>
          <li>
            <img class="tick" src="/assets/fancy-check.png" />
            <span class="item-content">Theorie-Pflichtteil in nur 2 Wochen absolvieren</span>
          </li>
          <li>
            <img class="tick" src="/assets/fancy-check.png" /><span class="item-content">Video + Live-Chat mit
              Fahrlehrern bequem von zu Hause</span>
          </li>
          <li>
            <img class="tick" src="/assets/fancy-check.png" />
            <span class="item-content">100% rechtsgültig und anerkannt</span>
          </li>
        </ul>
      </div>
      <div class="col-12 col-lg-4 no-side-space">
        <div class="right-side">
          <div class="ribbon ribbon-top-right"><span>SPARE 300%</span></div>

          <div class="right-side-content">
            <div class="offer-price">99€</div>
            <div class="real-price">anstatt 399 €</div>

            <ul class="offer-items">
              <li>
                <img class="tick" src="/assets/fancy-check.png" /><span>14 Theoriestunden</span>
              </li>
              <li>
                <img class="tick" src="/assets/fancy-check.png" /><span>Präsentation der Theoriestunde</span>
              </li>
              <li>
                <img class="tick" src="/assets/fancy-check.png" /><span>Faktenblatt und FAQ's</span>
              </li>
              <li>
                <img class="tick" src="/assets/fancy-check.png" /><span>Geld-zurück-Garantie <span
                    class="tooltip-container"
                    tooltip="Schau dir eine Stunde an und entscheide, ob es dir gefällt. Falls du unzufrieden bist, sende uns eine Nachricht und du erhältst dein Geld zurück. Solltest du zwei oder mehr Leistungen in Anspruch nehmen, gehen wir davon aus, dass du zufrieden bist."
                    whiteBackground="true" max-width="300" placement="right"><i
                      class="fa fa-info-circle"></i></span></span>
              </li>
              <li>
                <img class="tick" src="/assets/fancy-check.png" /><span>Gratis Lern-App mit dem offiziellen
                  Fragenkatalog</span>
              </li>
            </ul>

            <!-- <div class="tuv-dekra-logos">
              <img class="dekra" src="/assets/dekra-logo.png" />
              <img class="tuv" src="/assets/tuv-logo.png" />
            </div> -->

            <button class="continue-btn buy-btn" (click)="onOpenSignUpDialog()">
              Jetzt Online loslegen
            </button>
          </div>
        </div>
        <div class="side-text">
          <p class="payment-methods">
            <img src="/assets/payments-logos/payments-klarna.png" />
            <img src="/assets/payments-logos/payments-sofort.png" />
            <img src="/assets/payments-logos/payments-paypal.png" />
            <img src="/assets/payments-logos/payments-cards.png" />
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="panel countdown-section">
  <div class="container xl-container">
    <div class="online-theory-badge" *ngIf="showCurrentSale" [@fadeAnimation]="showCurrentSale">
      <div class="badge-icon">
        {{ currentSale.letter }}
      </div>
      <div>
        <div class="badge-name">{{ currentSale.name }}</div>
        <div class="badge-time">{{ currentSale.time }}</div>
        <div class="badge-text">{{ currentSale.text }}</div>
      </div>
    </div>
    <div class="row no-side-space">
      <div class="col-12 no-side-space">
        <h3 class="countdown-section-header">Angebot gültig für:</h3>
        <div class="countdown-container">
          <eddy-countdown [date]="countdownDate" [minutesLabel]="minutesLabel" [secondsLabel]="secondsLabel" [showOnEnd]="'true'">
          </eddy-countdown>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="panel">
  <div class="container xl-container">
    <div class="row no-side-space testimonials">
      <eddy-online-theory-testimonials [generalTestimonials]="true"></eddy-online-theory-testimonials>
    </div>
  </div>
</div>

<div class="panel panel-4-1 grey">
  <div class="row no-side-space features-wrapper">
    <img class="features-container-image desktop-only-element" src="/assets/academy/car-keys.png" />
    <div class="container xl-container features-container flex-end">
      <div class="feature">
        <div class="feature-icon">
          <img src="assets/academy/online-icon.png" class="feature-icon-online" />
        </div>
        <p class="feature-header">100% online</p>
        <p class="feature-description">
          Teilnahme im interaktiven Live-Stream bequem von zu Hause möglich.
        </p>
      </div>
      <div class="feature">
        <div class="feature-icon">
          <img src="assets/academy/interactiv-icon.png" class="feature-icon feature-icon-interactive" />
        </div>
        <p class="feature-header">100% interaktiv</p>
        <p class="feature-description">
          Zertifizierter Fahrlehrer beantwortet live alle deine Fragen.
        </p>
      </div>
      <div class="feature">
        <div class="feature-icon">
          <img src="assets/academy/accepted-icon.png" class="feature-icon feature-icon-accepted" />
        </div>
        <p class="feature-header">100% rechtsgültig</p>
        <p class="feature-description">
          Deine absolvierten Online Theoriestunden sind 100% rechtsgültig und 1 Jahr lang gültig.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="panel">
  <div class="container xl-container">
    <p class="instructions-title">
      So funktioniert’s:
    </p>
    <div class="row no-side-space instructions-container">
      <div class="instructions-line-container">
        <hr class="instructions-line" />
      </div>
      <div class="step">
        <img src="assets/academy/click-icon.png" class="feature-icon-interactive" />
        <p class="steps-header">Registriere dich</p>
        <p class="steps-description">
          Einfach anmelden und mit 1-Klick deine Stunden auswählen.
        </p>
      </div>
      <div class="step">
        <img src="assets/academy/online-teacher-icon.png" class="feature-icon-interactive" />
        <p class="steps-header">Besuche deine Theoriestunde online</p>
        <p class="steps-description">
          Nutze die Corona Krise & absolviere die Theorie bequem von zu Hause.
        </p>
      </div>
      <div class="instructions-line-container-2">
        <hr class="instructions-line-2" />
      </div>
      <div class="step">
        <img src="assets/academy/certificate-icon.png" class="feature-icon-interactive" />
        <p class="steps-header">Erhalte deinen rechtsgültigen Bescheid</p>
        <p class="steps-description">
          Absolvierte Online Theoriestunden sind 1 Jahr lang gültig.
        </p>
      </div>
      <div class="step last-step">
        <img src="assets/academy/praxis-icon.png" class="feature-icon-interactive" />
        <p class="steps-header">Starte deinen Praxis-Teil</p>
        <p class="steps-description">
          Den praktischen Unterricht kannst du später direkt bei deiner lokalen Fahrschule absolvieren.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="panel panel-3">
  <img class="theory-badge" src="/assets/academy/streaming-mockup-eddy-academy.png" />
  <div class="container xl-container check-online-theory-main-container">

    <div class="row no-side-space">
      <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 check-online-theory-recognized-container">
        <p class="recognized-header">Online-Theorie Unterricht JETZT deutschlandweit möglich!</p>
        <p class="recognized-subheader">
          Jetzt registrieren und kostenlos testen.
        </p>

        <button class="academy-register-btn no-side-margin" (click)="onOpenSignUpDialog()">Jetzt kostenlos registrieren
        </button>
      </div>
    </div>
  </div>
</div>

<div class="panel known-from-panel">
  <div class="container xl-container">
    <eddy-known-from></eddy-known-from>
  </div>
</div>
