export class TheoryQuestionLearner {
    id: number;

    title: string;
    isAnswered: boolean;
    hasCorrectAnswer: boolean;

    constructor(json: any) {
        this.id = +json.id;
        this.title = json.title;
        this.isAnswered = !!json.isAnswered;
        this.hasCorrectAnswer = !!json.hasCorrectAnswer;
    }
}
