import { Injectable } from '@angular/core';
import { environment as env } from '@environments/environment';
import { ChatMessage } from '@models/chat-message.model';
import { Observable, Subscriber, throwError, timer } from 'rxjs';
import { AuthService } from './auth.service';
import { ApiService } from '@services/api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ChatService {
  private streamsMap: Map<string, EventSource>;
  private subjectsMap: Map<string, Observable<ChatMessage>>;
  private readonly apiPath = `${env.apiSSEService}`;
  private readonly schoolsAPIPath = `${env.apiSchoolService}`;
  private readonly EVENT_SOURCE_RECONNECT_TIMEOUT = 4000;
  private readonly MAX_NUMBER_OF_TRIES = 5;

  constructor(private authService: AuthService,
              private api: ApiService) {
    this.streamsMap = new Map<string, EventSource>();
    this.subjectsMap = new Map<string, Observable<ChatMessage>>();
  }

  getStream(channel: string): Observable<ChatMessage> {
    if (this.subjectsExists(channel)) {
      return this.subjectsMap.get(channel);
    }

    return this.createEventSourceAndStreamingSubject(channel);
  }

  private createEventSourceAndStreamingSubject(channel: string): Observable<ChatMessage> {
    return this.connectEventSourceAndStreamingSubject(channel);
  }

  private connectEventSourceAndStreamingSubject(channel: string): Observable<ChatMessage> {
    try {
      if (this.subjectsExists(channel)) {
        return this.subjectsMap.get(channel);
      }

      const observable = new Observable<ChatMessage>(observer => {
        let eventSource: EventSource;
        // reconnect every 9.95 mins, 10 min request timeout is set
        const timerSubscription = timer(0, 597000)
          .subscribe(() => {
            if (this.streamsMap.has(channel)) {
              const oldEventSource = this.streamsMap.get(channel);
              if (oldEventSource.readyState !== EventSource.CLOSED) {
                  // eventSources overlap
                setTimeout(() => oldEventSource?.close(), 300);
              }
            }
            this.subjectsMap.delete(channel);
            this.streamsMap.delete(channel);
            eventSource = this.setupEventSource(channel, observer);
          });

        return () => {
          eventSource?.close();
          timerSubscription.unsubscribe();
          this.subjectsMap.delete(channel);
          if (this.streamsMap.has(channel)) {
            const oldEventSource = this.streamsMap.get(channel);
            if (oldEventSource.readyState !== EventSource.CLOSED) {
              oldEventSource?.close();
            }
          }
          this.streamsMap.delete(channel);
        };
      });
      this.subjectsMap.set(channel, observable);
        
      return observable;
      } catch {
        return throwError('not supported');
      } 
  }

  private subjectsExists(channel: string): boolean {
    return this.subjectsMap.has(channel);
  }

  setupEventSource(channel: string, observer: Subscriber<ChatMessage>, tries: number = 0): EventSource {
    const url = `${this.apiPath}/events?stream=${channel}&authorization=Bearer ${this.authService.getToken()}`;
    const eventSource = new EventSource(url);
    eventSource.onerror = (e) => {
      const lastMessageDate = new Date();
      console.error('EventSource connection failure', e);
      eventSource.close();
      this.subjectsMap.delete(channel);
      this.streamsMap.delete(channel);
      if (tries > this.MAX_NUMBER_OF_TRIES) {
        observer.error();
        return;
      }
      const reconnectInMillis = this.EVENT_SOURCE_RECONNECT_TIMEOUT + this.EVENT_SOURCE_RECONNECT_TIMEOUT * Math.random();
      setTimeout(() => {
        this.setupEventSource(channel, observer, tries + 1);
        this.pushMessages(channel, observer, lastMessageDate);
      }, reconnectInMillis);
    };

    this.streamsMap.set(channel, eventSource);
  
    eventSource.onmessage = (message) => {
      const msg = JSON.parse(message.data);
      if (msg.type === 'keep-alive') {
        return;
      }

      observer.next(new ChatMessage(msg));
    };

    return eventSource;
  }

  getChannelChatMessages(channel: string, dateFrom?: Date): Observable<ChatMessage[]> {
    const params = new Map<string, string>();
    if (dateFrom) {
      params.set('dateFrom', '' + dateFrom.toISOString());
    }

    return this.api.get<ChatMessage[]>(`${this.schoolsAPIPath}/chat_channels/${channel}/messages`, params)
      .pipe(
        map(messages => this.mapMessages(messages))
      );
  }

  private mapMessages(messages: ChatMessage[]): ChatMessage[] {
    return messages.map(it => new ChatMessage(it));
  }

  private pushMessages(channel: string, observer: Subscriber<ChatMessage>, dateFrom?: Date) {
    this.getChannelChatMessages(channel, dateFrom)
      .subscribe(chatMessages => {
        chatMessages.forEach(message => observer.next(message));
      });
  }
}
