export class LearnerTrainingInfo {
  id: number;
  learnerId: number;

  theoryLessonsAttended: number;
  mandatoryLessons: number;
  practiceLessons: number;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.learnerId = +json.learnerId;

    this.theoryLessonsAttended = +json.theoryLessonsAttended;
    this.mandatoryLessons = +json.mandatoryLessons;
    this.practiceLessons = +json.practiceLessons;
  }

  isFilled(): boolean {
    return this.theoryLessonsAttended !== null && this.theoryLessonsAttended !== undefined &&
      this.mandatoryLessons !== null && this.mandatoryLessons !== undefined &&
      this.practiceLessons !== null && this.practiceLessons !== undefined;
  }
}
