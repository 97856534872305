<div class="content-container">
  <h2 class="section-title">
    240+ innovative Fahrschulen vertrauen auf drivEddy
  </h2>
  <p
    class="section-content"
    *ngIf="onlineTheoryServiceView; else softwareViewContent"
  >
    Wir arbeiten eng zusammen, um eine hochwertige Ausbildung zu garantieren.
  </p>
  <ng-template #softwareViewContent>
    <p class="section-content">
      Gemeinsam garantieren wir eine hochwertige Ausbildung in ganz Deutschland.
    </p>
  </ng-template>

  <div class="logos">
    <img class="glenzer" src="/assets/school-logos/glenzer.png" />
    <img class="drive" src="/assets/school-logos/drive.png" />
    <img class="logemann" src="/assets/school-logos/logemann.png" />
    <img class="walter" src="/assets/school-logos/walter.png" />
    <img class="aschmann" src="/assets/school-logos/aschmann.png" />
    <img class="center" src="/assets/school-logos/center.png" />
  </div>
</div>
