export class InstructorDayProof {
  date: Date;
  instructorId: number;
  instructorName: string;
  lengthInMinutes: number;
  numberOfLearners: number;
  includeLearnerId?: number;

  constructor(json) {
    this.date = new Date(json.date);
    this.instructorId = json.instructorId;
    this.instructorName = json.instructorName;
    this.lengthInMinutes = json.lengthInMinutes;
    this.numberOfLearners = json.numberOfLearners;
    if (json.includeLearnerId !== undefined) {
      this.includeLearnerId = +json.includeLearnerId;
    }
  }
}
