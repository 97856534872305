import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { LocationService } from '@services/location.service';
import { Address } from '@models/address.model';

@Component({
  selector: 'eddy-address-search-bar',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './address-search-bar.component.html',
  styleUrls: ['./address-search-bar.component.scss']
})
export class AddressSearchBarComponent {

  // see: https://github.com/tanoy009/ng4-geoautocomplete#installation
  userSettings = {
    inputPlaceholderText: 'Stadt oder Adresse',
    showSearchButton: true,
    geoCountryRestriction: ['de'],
    labels: {
      currentLocationText: 'Aktuellen Standort verwenden',
      recentSearchesText: 'Suchverlauf',
      locationsText: 'Standorte',
      searchPlaceholderText: 'Suchen'
    },
    inputString: '',
    geoTypes: ['(regions)', '(cities)', 'address', 'geocode']
  };

  @Input() set inputString(_inputString: string) {
    this.userSettings.inputString = _inputString;
    this.userSettings = Object.assign({}, this.userSettings);
  }

  @Input() blueSearchButton = true;

  @Output() addressSelected: EventEmitter<Address> = new EventEmitter<Address>();

  constructor(private locationService: LocationService) {
  }

  autoCompleteCallback(selectedData: any) {
    try {
      if (selectedData.data === undefined || selectedData.data === null) {
        return;
      }
      const address = this.locationService.getAddressFromGoogleGeocodeResult(selectedData.data);
      this.addressSelected.emit(address);
    } catch (e) {
      throw (e);
    }
  }

}
