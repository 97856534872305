<div class="dialog-container">
  <div class="modal-header">
    <h4 class="modal-title">
      <ng-container *ngIf="title">{{ title }}</ng-container>
      <ng-container *ngIf="!title">Bestätigung</ng-container>
    </h4>

    <div class="close-button" (click)="onNo()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>

  <mat-dialog-content>
    <div class="modal-body">
      <div class="modal-text">
        Unter nachfolgendem Link kannst du deine Buchung direkt online bezahlen
      </div>

      <div class="d-flex justify-content-center">
        <button type="button" class="success-rounded-btn pay-online-btn" (click)="onPay()">Jetzt online bezahlen</button>
      </div>

      <div class="modal-text mt-3">
        Alternativ kannst du den fälligen Betrag in Höhe von {{ (amountInCents / 100.0) | currency: 'EUR': 'symbol-narrow': '.2-2' }} auch auf folgendes Konto überweisen. Nach Zahlungseingang erhältst du automatisch eine Rechnung per E-Mail.
      </div>

      <div class="payment-details">
        <div>
          Betreff: {{firstName}} {{lastName}}, {{bookingNumber}}
        </div>
        <div>
          IBAN: {{iban}}
        </div>
        <div>
          BIC: {{bic}}
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-yes" (click)="onYes()">Ich bezahle per Überweisung</button>
    </div>

    <div class="modal-text mt-3" *ngIf="hasCredits">
      Du kannst die Buchung auch mit deinem vorhandenen Guthaben bezahlen.
    </div>
  </mat-dialog-content>

  <div class="spacer"></div>

  <mat-dialog-actions *ngIf="hasCredits">
    <div class="modal-footer">
    <button type="button" class="btn btn-yes" (click)="onPayByCredit()">Mit Guthaben bezahlen</button>
    </div>
  </mat-dialog-actions>
</div>
