import { Asset } from './asset.model';
import { OfferPoint } from './offer_point.model';
import { Provider } from './provider.model';

export class Offer {
  id?: number;

  topic: string;
  subtitle: string;
  description: string;

  providerId: number;
  provider?: Provider;

  offerPoints: OfferPoint[];

  imageAssetId: number;
  imageAsset: Asset;

  link?: string;
  linkButtonTitle?: string;

  underageLink?: string;

  numViews?: number;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;

    this.topic = json.topic;
    this.subtitle = json.subtitle;
    this.description = json.description;

    this.providerId = +json.providerId;
    if (json.provider) {
      this.provider = new Provider(json.provider);
    }

    this.offerPoints = json.offerPoints;

    this.imageAssetId = +json.imageAssetId;
    this.imageAsset = json.imageAsset;

    if (json.link) {
      this.link = json.link;
      this.linkButtonTitle = json.linkButtonTitle;
    }

    if (json.underageLink) {
      this.underageLink = json.underageLink;
    }

    this.numViews = +json.numViews;
  }

  get imageSrc(): string {
    if (this.imageAsset.path !== '') {
      return this.imageAsset.path;
    }
    return this.imageAsset.base64;
  }
}
