<mat-sidenav-container #container class="{{ containerClass$ | async }}">
  <mat-sidenav
    *ngIf="!isPageWithoutSideItems"
    #sidenav
    [class.closed]="!sidenav.opened"
    mode="{{ isSideMode ? 'side' : 'over' }}"
    position="start"
    [class.internal-mat-sidenav]="isSchoolEmployeeOrLearner"
    [class.narrow]="!isDesktop || narrowMode"
  >
    <eddy-sidenav
      *ngIf="!isSchoolEmployeeOrLearner"
      [navRoutes]="navRoutes"
      [externalNavRoutes]="externalNavRoutes"
    ></eddy-sidenav>

    <eddy-internal-sidenav
      [class.hidden]="!isSchoolEmployeeOrLearner"
      [routes]="internalRoutes"
      [isOverMode]="!isSideMode"
      [narrowMode]="!isDesktop || narrowMode"
      (narrowModeChange)="onNarrowModeChange($event)"
    >
    </eddy-internal-sidenav>
  </mat-sidenav>

  <mat-sidenav-content #sidenavContent>
    <ng-container *ngIf="!isPageWithoutSideItems && !isPageWithoutHeader">
      <eddy-header
        *ngIf="!isSchoolEmployeeOrLearner"
        [navRoutes]="navRoutes"
        [externalNavRoutes]="externalNavRoutes"
      ></eddy-header>
      <eddy-internal-header *ngIf="isSchoolEmployeeOrLearner">
      </eddy-internal-header>
    </ng-container>
    <div
      class="sticky-footer"
      [class.no-topbar]="isPageWithoutSideItems"
      [class.internal]="isSchoolEmployeeOrLearner"
      [class.internal-no-header]="isDesktop && isSchoolEmployeeOrLearner"
      [class.public]="!isSchoolEmployeeOrLearner && !isAdmin"
      [class.login-page]="isLoginPage"
      [class.set-password-page]="isSetPasswordPage"
      [class.is-thank-you-page]="isThankYouPage"
      [class.is-sign-up-page]="isSignUpPage"
      [class.theory-question-page]="isTheoryQuestionPage"
      style="flex: 1 0 auto !important"
    >
      <router-outlet></router-outlet>
    </div>

    <ng-container
      *ngIf="!isPageWithoutSideItems && !isPageWithoutGenericFooter"
    >
      <eddy-footer
        *ngIf="showFooter"
        [isOnlineTheoryLicenseBPage]="isOnlineTheoryLicenseBPage"
      ></eddy-footer>
    </ng-container>
  </mat-sidenav-content>
</mat-sidenav-container>
