import { SchoolLearnerBillingProduct } from './school-learner-billing-product.model';
import { SchoolLearnerStatus } from './school-learner-status.model';
import { SchoolLearnerType } from './school-learner-type.model';

export class SchoolLearnerAccounting {
  id: number;

  learnerId: number;

  firstName: string;
  lastName: string;
  email: string;

  schoolId: number;
  schoolName?: string;

  learn: string[];
  lastLogin: Date;
  startDate: Date;
  status: SchoolLearnerStatus;
  costsInCents: number;
  attendedLessons: number;
  totalLessons: number;

  type: SchoolLearnerType;
  billingProduct: SchoolLearnerBillingProduct;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.learnerId = +json.learnerId;
    this.firstName = json.firstName;
    this.lastName = json.lastName;
    this.email = json.email;

    this.schoolId = +json.schoolId;
    if (json.schoolName) {
      this.schoolName = json.schoolName;
    }

    this.learn = json.learn;
    this.lastLogin = new Date(json.lastLogin);
    this.startDate = new Date(json.startDate);
    this.status = json.status;
    this.costsInCents = +json.costsInCents;
    this.attendedLessons = +json.attendedLessons;
    this.totalLessons = +json.totalLessons;

    this.type = json.type;
    this.billingProduct = json.billingProduct;
  }

  getDisplayName(): string {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    }

    if (this.firstName) {
      return this.firstName;
    }

    if (this.lastName) {
      return this.lastName;
    }

    if (this.email) {
      return this.email;
    }

    return '';
  }

  learnLicensesDisplayName(): string {
    if (!this.learn.length) {
      return '';
    }

    return this.learn.join(', ');
  }

  isDeleted(): boolean {
    return this.status === SchoolLearnerStatus.DELETED;
  }
}
