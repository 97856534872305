export class LearnerYoudriveData {
  id: number;

  youdriveID?: string;

  salutation: string;
  title: string;
  landline: string;
  birthName: string;
  countryOfBirth: string;
  schoolBranch: string;

  consentEcb: boolean;
  visionTest: boolean;
  firstAidCourse: boolean;
  visualAidsRequired: boolean;
  physicalAndMentalDefects: boolean;

  allocationType: AllocationType;

  existingLicenseKeys: string;
  secondLanguage: string;
  theoryCompleted: boolean;
  practiceCompleted: boolean;

  requested: boolean;
  requestedOn?: Date;

  STVANumber: string;
  TUVReturnNumber: string;
  returnDate?: Date;

  registrationDate?: Date;
  contractDate?: Date;

  note: string;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;

    this.youdriveID = json.youdriveID;

    this.salutation = json.salutation;
    this.title = json.title;
    this.landline = json.landline;
    this.birthName = json.birthName;
    this.countryOfBirth = json.countryOfBirth;
    this.schoolBranch = json.schoolBranch;

    this.consentEcb = !!json.consentEcb;
    this.visionTest = !!json.visionTest;
    this.firstAidCourse = !!json.firstAidCourse;
    this.visualAidsRequired = !!json.visualAidsRequired;
    this.physicalAndMentalDefects = !!json.physicalAndMentalDefects;

    this.allocationType = json.allocationType;

    this.existingLicenseKeys = json.existingLicenseKeys;
    this.secondLanguage = json.secondLanguage;
    this.theoryCompleted = !!json.theoryCompleted;
    this.practiceCompleted = !!json.practiceCompleted;

    this.requested = !!json.requested;
    if (json.requestedOn) {
      this.requestedOn = new Date(json.requestedOn);
    }

    this.STVANumber = json.STVANumber;
    this.TUVReturnNumber = json.TUVReturnNumber;
    if (json.returnDate) {
      this.returnDate = new Date(json.returnDate);
    }

    if (json.registrationDate) {
      this.registrationDate = new Date(json.registrationDate);
    }
    if (json.contractDate) {
      this.contractDate = new Date(json.contractDate);
    }

    this.note = json.note;
  }
}

export enum AllocationType {
  FIRST_PURCHASE = 'FIRST_PURCHASE',
  EXTENSION = 'EXTENSION',
  RECOVERY = 'RECOVERY',
  TRANSFER = 'TRANSFER',
}

export function getDisplayName(allocationType: AllocationType): string {
  switch (allocationType) {
    case AllocationType.FIRST_PURCHASE:
      return 'Ersterwerb';
    case AllocationType.EXTENSION:
      return 'Erweiterung';
    case AllocationType.RECOVERY:
      return 'Wiedererlangung';
    case AllocationType.TRANSFER:
      return 'Umschreibung';
    default:
      return '';
  }
}
