export interface LessonAddress {
  street: string;
  streetNumber: string;
  postalCode: string;
  locality: string;
}

export function toString(address: LessonAddress): string {
  if (!address) {
    return '';
  }

  return `${address.street} ${address.streetNumber}, ${address.postalCode} ${address.locality}`;
}
