import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DateRange } from '@models/date-range.model';
import { DaterangepickerComponent, DaterangepickerDirective } from 'ngx-daterangepicker-material';
import * as moment from 'moment';

const DEFAULT_TIME_SHIFT_IN_DAYS = 7;

@Component({
  selector: 'eddy-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DateRangePickerComponent,
      multi: true
    }
  ]
})
export class DateRangePickerComponent implements ControlValueAccessor, AfterViewInit {
  private _dateRange: DateRange;

  @Input() set dateRange(val: DateRange) {
    this._dateRange = val;

    this.propagateChange(this._dateRange);
  }

  get dateRange() {
    return this._dateRange;
  }

  @Output() dateRangeChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();

  @Input() min?: Date;

  @Input() max?: Date;

  @Input() placeholder = 'Choose date';

  @Input() disabled = false;

  @Input() timeShiftInDays = DEFAULT_TIME_SHIFT_IN_DAYS;

  @ViewChild(DaterangepickerDirective, {static: true}) pickerDirective: DaterangepickerDirective;

  picker: DaterangepickerComponent;

  constructor(private breakpointObserver: BreakpointObserver,
              private changeDetectorRef: ChangeDetectorRef) {
    if (!this.min) {
      this.min = new Date(0);
    }
    if (!this.max) {
      const max = new Date();
      max.setUTCFullYear(3000);
      this.max = max;
    }
  }

  ngAfterViewInit() {
    this.picker = this.pickerDirective.picker;
    this.changeDetectorRef.detectChanges();
  }

  // propagate changes into the custom form control
  propagateChange: (any) => void = (_) => {
  }

  onDateRangeChanged(dateRange: DateRange) {
    this.dateRangeChange.emit(dateRange);
  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    this.dateRange = value;
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  openDateRangePicker(e) {
    this.pickerDirective.open();
  }

  toPrevious() {
    this.shiftDates(-this.timeShiftInDays);
  }

  toNext() {
    this.shiftDates(this.timeShiftInDays);
  }

  private shiftDates(days: number) {
    const startDate = new Date(this.dateRange.startDate);
    const endDate = new Date(this.dateRange.endDate);
    startDate.setDate(startDate.getDate() + days);
    endDate.setDate(endDate.getDate() + days);

    this.dateRange = {
      startDate: startDate,
      endDate: endDate,
    };

    this.onDateRangeChanged(this.dateRange);
  }

  change(dateRange) {
    if (!dateRange || !dateRange.startDate || !dateRange.endDate) {
      return;
    }

    this.dateRange = {
      startDate: dateRange.startDate.toDate(),
      endDate: dateRange.endDate.toDate(),
    };

    this.onDateRangeChanged(this.dateRange);
  }

  get minDate(): moment.Moment {
    return moment(this.min);
  }

  get maxDate(): moment.Moment {
    return moment(this.max);
  }
}
