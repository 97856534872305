import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'eddy-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  _show = false;

  @Input() data: any;

  events = new EventEmitter();

  @HostBinding('class.tooltip') true;

  @HostBinding('style.top') hostStyleTop: string;
  @HostBinding('style.left') hostStyleLeft: string;
  @HostBinding('style.max-width') hostStyleMaxWidth: string;
  @HostBinding('class.tooltip-show') hostClassShow: boolean;
  @HostBinding('class.tooltip-light') whiteBackground: boolean;
  @HostBinding('class.show-info-icon') showInfoIcon: boolean;
  @HostBinding('class.tooltip-shadow') showShadow: boolean;

  @HostListener('transitionend')
  transitionEnd() {
    if (this.show) {
      this.events.emit('shown');
    }
  }

  @Input() set show(value: boolean) {
    if (value) {
      this.setPosition();
    }
    this._show = this.hostClassShow = value;
  }

  get show(): boolean {
    return this._show;
  }

  get placement() {
    return this.data.options.placement;
  }

  get element() {
    return this.data.element;
  }

  get elementPosition() {
    return this.data.elementPosition;
  }

  get options() {
    return this.data.options;
  }

  get value() {
    return this.data.value;
  }

  get tooltipOffset(): number {
    return Number(this.data.options.offset);
  }

  get maxWidth(): number {
    return this.data.options.maxWidth;
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
    this.setPlacementClass();
    this.setStyles();
  }

  setPosition(): void {
    const elementHeight = this.element.offsetHeight;
    const elementWidth = this.element.offsetWidth;
    const tooltipHeight = this.elementRef.nativeElement.clientHeight;
    const tooltipWidth = this.elementRef.nativeElement.offsetWidth;
    const scrollY = window.pageYOffset;
    const tooltip = this.elementRef.nativeElement;

    if (this.placement === 'top') {
      this.hostStyleTop = (this.elementPosition.top + scrollY) - (tooltipHeight + this.tooltipOffset) + 'px';
    }

    if (this.placement === 'bottom') {
      this.hostStyleTop = (this.elementPosition.top + scrollY) + elementHeight + this.tooltipOffset + 'px';
    }

    if (this.placement === 'top' || this.placement === 'bottom') {
      this.hostStyleLeft = (this.elementPosition.left + elementWidth / 2) - tooltipWidth / 2 + 'px';
    }

    if (this.placement === 'left') {
      this.hostStyleLeft = this.elementPosition.left - tooltipWidth - this.tooltipOffset + 'px';
    }

    if (this.placement === 'right') {
      this.hostStyleLeft = this.elementPosition.left + elementWidth + this.tooltipOffset + 'px';
    }

    if (this.placement === 'left' || this.placement === 'right') {
      this.hostStyleTop = (this.elementPosition.top + scrollY) + elementHeight / 2 - tooltip.clientHeight / 2 + 'px';
    }
  }

  setPlacementClass(): void {
    this.renderer.addClass(this.elementRef.nativeElement, 'tooltip-' + this.placement);
  }

  setStyles() {
    this.hostStyleMaxWidth = this.options.maxWidth + 'px';
    this.whiteBackground = this.options.whiteBackground;
    this.showInfoIcon = this.options.showInfoIcon;
    this.showShadow = this.options.showShadow;
  }
}
