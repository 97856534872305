import { PracticeLesson } from './practice-lesson.model';
import { getDisplayName, PaymentStatus } from './payment-status.model';
import { LearnerTheoryTopic } from './learner-theory-topic.model';
import { LessonBookingTheoryPackage } from './lesson-booking-theory-package.model';
import { LessonBookingBasicFee } from './lesson-booking-basic-fee.model';
import { LearnerCredit } from './learner-credit.model';
import { Learner } from './learner.model';
import moment from 'moment';
import { LessonBookingTheoryLearningPaymentReceipt } from './lesson-booking-theory-learning-payment-receipt.model';
import { NumberUtils } from '@services/number-utils.service';
import { DateUtils } from '@services/date-utils.service';
import { YoudriveBooking } from './youdrive-booking.model';
import {
  PracticeLessonStatus,
  getDisplayName as getPracticeLessonStatusDisplayName,
} from './practice-lesson-status.model';

export class LessonBooking {
  practiceLesson?: PracticeLesson;
  learnerTheoryTopic?: LearnerTheoryTopic;
  theoryPackage?: LessonBookingTheoryPackage;
  theoryLearningPaymentReceipt?: LessonBookingTheoryLearningPaymentReceipt;
  basicFee?: LessonBookingBasicFee;
  learnerCredit?: LearnerCredit;
  youdriveBooking?: YoudriveBooking;

  paymentPriceInCents: number;
  paymentStatus: PaymentStatus;
  isCashPayment?: boolean;
  isBankTransferPayment?: boolean;
  isPaidToEddy?: boolean;
  isCashPaymentMethod?: boolean;
  paymentUpdatedAt: Date;
  paymentReceiptNumber?: string;
  refundAmountInCents: number;
  creditsUsedInCents?: number;
  creditsRefundedInCents?: number;
  serviceFeeInCents: number;

  paymentDescription?: string;
  indirectPaymentOrderKey?: string;

  constructor(json) {
    if (!json) {
      return;
    }

    this.paymentPriceInCents = +json.paymentPriceInCents;
    // TODO quick fix of empty status coming from backend
    this.paymentStatus =
      json.paymentStatus === '' ? 'NOT_PAID' : json.paymentStatus;

    if (json?.paymentUpdatedAt) {
      this.paymentUpdatedAt = new Date(json.paymentUpdatedAt);
    }

    if (json.practiceLesson) {
      this.practiceLesson = new PracticeLesson(json.practiceLesson);
      this.isCashPayment = json.practiceLesson.isCashPayment;
      this.isCashPaymentMethod = json.practiceLesson.isCashPayment;
    } else if (json.isCashPayment) {
      this.isCashPayment = !!json.isCashPayment;
    }

    if (json.isBankTransferPayment) {
      this.isBankTransferPayment = !!json.isBankTransferPayment;
    }

    if (json.isPaidToEddy !== undefined) {
      this.isPaidToEddy = !!json.isPaidToEddy;
    }

    if (json.isCashPaymentMethod) {
      this.isCashPaymentMethod = !!json.isCashPaymentMethod;
    }

    if (json.learnerTheoryTopic) {
      this.learnerTheoryTopic = new LearnerTheoryTopic(json.learnerTheoryTopic);
    }

    if (json.theoryPackage) {
      this.theoryPackage = new LessonBookingTheoryPackage(json.theoryPackage);
    }
    if (json.basicFee) {
      this.basicFee = new LessonBookingBasicFee(json.basicFee);
    }
    if (json.theoryLearningPaymentReceipt) {
      this.theoryLearningPaymentReceipt =
        new LessonBookingTheoryLearningPaymentReceipt(
          json.theoryLearningPaymentReceipt
        );
    }
    if (json.learnerCredit) {
      this.learnerCredit = new LearnerCredit(json.learnerCredit);
    }
    if (json.youdriveBooking) {
      this.youdriveBooking = new YoudriveBooking(json.youdriveBooking);
    }

    this.paymentReceiptNumber = json.paymentReceiptNumber;

    if (+json.refundAmountInCents) {
      this.refundAmountInCents = +json.refundAmountInCents;
    }

    if (+json.creditsUsedInCents) {
      this.creditsUsedInCents = +json.creditsUsedInCents;
    }

    if (+json.creditsRefundedInCents) {
      this.creditsRefundedInCents = +json.creditsRefundedInCents;
    }

    this.serviceFeeInCents = +json.serviceFeeInCents;

    if (json.paymentDescription) {
      this.paymentDescription = json.paymentDescription;
    }

    if (json.indirectPaymentOrderKey) {
      this.indirectPaymentOrderKey = json.indirectPaymentOrderKey;
    }
  }

  get id(): number {
    if (this.practiceLesson) {
      return this.practiceLesson.id;
    } else if (this.learnerTheoryTopic) {
      return this.learnerTheoryTopic.id;
    } else if (this.basicFee) {
      return this.basicFee.learnerId;
    } else if (this.learnerCredit) {
      return this.learnerCredit.id;
    } else if (this.theoryLearningPaymentReceipt) {
      return this.theoryLearningPaymentReceipt.id;
    } else if (this.youdriveBooking) {
      return this.youdriveBooking.id;
    }
    return this.theoryPackage.learnerId;
  }

  get key(): string {
    if (this.practiceLesson) {
      return this.practiceLesson.key;
    } else if (this.learnerTheoryTopic) {
      return this.learnerTheoryTopic.key;
    } else if (this.basicFee) {
      return this.basicFee.orderKey;
    } else if (this.learnerCredit) {
      return this.learnerCredit.key;
    } else if (this.theoryLearningPaymentReceipt) {
      return this.theoryLearningPaymentReceipt.orderKey;
    } else if (this.theoryPackage) {
      return this.theoryPackage.orderKey;
    }

    return '';
  }

  get date(): Date {
    if (this.practiceLesson) {
      return this.practiceLesson.startDate;
    } else if (this.youdriveBooking) {
      return this.youdriveBooking.date;
    }
    return this.paymentUpdatedAt;
  }

  get categoryDisplayName(): string {
    if (this.practiceLesson) {
      return this.practiceLesson.getCategoryDisplayName();
    } else if (this.learnerTheoryTopic) {
      if (this.isLearnerTheoryTopicVideo()) {
        return 'Theorie-Unterichtsvideo';
      }
      return 'Theoriestunde';
    } else if (this.basicFee) {
      return 'Grundbetrag';
    } else if (this.learnerCredit) {
      return 'Guthaben';
    } else if (this.theoryLearningPaymentReceipt) {
      return 'Theorie Lernen';
    } else if (this.youdriveBooking) {
      return this.youdriveBooking.getTypeDisplayName();
    }
    return 'Theoriepaket';
  }

  get learner(): Learner {
    if (this.practiceLesson) {
      return this.practiceLesson.learner;
    } else if (this.basicFee) {
      return this.basicFee.learner;
    } else if (this.learnerCredit) {
      return this.learnerCredit.learner;
    } else if (this.learnerTheoryTopic) {
      return this.learnerTheoryTopic.learner;
    } else if (this.theoryPackage) {
      return this.theoryPackage.learner;
    } else if (this.theoryLearningPaymentReceipt) {
      return this.theoryLearningPaymentReceipt.learner;
    } else if (this.youdriveBooking) {
      return this.youdriveBooking.learner;
    }

    return null;
  }

  get priceInCents(): number {
    if (this.practiceLesson) {
      return this.practiceLesson.fullPriceInCents + this.serviceFeeInCents;
    } else if (this.learnerCredit) {
      return this.learnerCredit.amountInCents;
    }
    return this.paymentPriceInCents + this.actualCreditsUsedInCents;
  }

  get amountInCents(): number {
    if (this.practiceLesson) {
      return this.practiceLesson.fullPriceInCents + this.serviceFeeInCents;
    } else if (this.learnerCredit) {
      return this.learnerCredit.amountInCents;
    } else if (this.youdriveBooking) {
      return this.youdriveBooking.fullPriceInCents;
    }
    if (this.creditsUsedInCents) {
      return this.paymentPriceInCents + this.creditsUsedInCents;
    }
    return this.paymentPriceInCents;
  }

  get actualCreditsUsedInCents(): number {
    if (!this.creditsUsedInCents) {
      return 0;
    }

    if (!this.creditsRefundedInCents) {
      return this.creditsUsedInCents;
    }

    return this.creditsUsedInCents - this.creditsRefundedInCents;
  }

  get paidPriceInCents(): number {
    if (!this.paymentPriceInCents && !this.actualCreditsUsedInCents) {
      return 0;
    }
    let paidAmount = this.paymentPriceInCents;
    if (!this.isPaidOrRefunded() && !this.isPartiallyPaid()) {
      paidAmount = 0;
    }

    if (this.actualCreditsUsedInCents) {
      paidAmount += this.actualCreditsUsedInCents;
    }

    if (this.refundAmountInCents > 0) {
      return paidAmount - this.refundAmountInCents;
    }
    return paidAmount;
  }

  get payablePriceInCents(): number {
    return this.priceInCents - this.paidPriceInCents;
  }

  get refundedAmountInCents() {
    if (!this.creditsRefundedInCents) {
      return this.refundAmountInCents;
    }
    return this.refundAmountInCents + this.creditsRefundedInCents;
  }

  getPaymentStatusDisplayName(): string {
    const displayName = getDisplayName(this.paymentStatus);
    if (this.isPaidByCredit) {
      return `${displayName} (Guthaben)`;
    }
    return displayName;
  }

  getPaymentStatusClass(): string {
    switch (this.paymentStatus) {
      case PaymentStatus.PAID:
        return 'payment-status payment-green';
      case PaymentStatus.PENDING:
        return 'payment-status payment-pending';
      case PaymentStatus.NOT_PAID:
        return 'payment-status payment-not-paid';
      case PaymentStatus.REFUNDED:
      case PaymentStatus.CANCELLATION_REFUNDED:
      case PaymentStatus.LATE_CANCELLATION_REFUNDED:
        return 'payment-status payment-refund';
      case PaymentStatus.PARTIALLY_PAID:
        return 'payment-status payment-partial';
      case PaymentStatus.ARCHIVED:
      case PaymentStatus.UNKNOWN:
        return 'payment-status payment-archived';
      default:
        return '';
    }
  }

  getPaymentInfoText(): string {
    if (!this.isPaid()) {
      return '';
    }

    let paymentInfoText;
    if (this.hasPaymentReceiptNumber()) {
      paymentInfoText = `Bezahlt am ${moment(this.paymentUpdatedAt).format(
        'DD.MM.YYYY'
      )} über die Rechnung ${this.paymentReceiptNumber}`;
    } else {
      paymentInfoText = `Bezahlt am ${moment(this.paymentUpdatedAt).format(
        'DD.MM.YYYY'
      )} in der Fahrschule`;
    }
    if (this.paymentDescription && this.paymentDescription !== '') {
      paymentInfoText += ` (${this.paymentDescription})`;
    }
    return paymentInfoText;
  }

  getServiceFeeInfoText(): string {
    return `Der genannte Betrag beinhaltet eine Servicegebühr in Höhe von ${NumberUtils.formatCurrency(
      this.serviceFeeInCents
    )}`;
  }

  get isPaidByCredit(): boolean {
    return (
      this.isPaidOrRefunded() &&
      (this.actualCreditsUsedInCents > 0 || this.creditsRefundedInCents > 0) &&
      this.paymentPriceInCents === 0
    );
  }

  get isPartiallyPaidByCredit(): boolean {
    return (
      this.isPartiallyPaid() &&
      this.actualCreditsUsedInCents > 0 &&
      this.paymentPriceInCents === 0
    );
  }

  getPaymentTypeDisplayName(): string {
    if (this.isBankTransferPayment) {
      return 'Überweisung';
    } else if (this.isPaidByCredit) {
      return 'Guthaben';
    }
    return this.isCashPayment || this.isCashPaymentMethod ? 'Bar' : 'Online';
  }

  isPaid(): boolean {
    return this.paymentStatus === PaymentStatus.PAID;
  }

  isPartiallyPaid(): boolean {
    return this.paymentStatus === PaymentStatus.PARTIALLY_PAID;
  }

  isPaidOrRefunded(): boolean {
    return [
      PaymentStatus.PAID,
      PaymentStatus.REFUNDED,
      PaymentStatus.CANCELLATION_REFUNDED,
    ].includes(this.paymentStatus);
  }

  isRefunded(): boolean {
    return [
      PaymentStatus.REFUNDED,
      PaymentStatus.CANCELLATION_REFUNDED,
    ].includes(this.paymentStatus);
  }

  hasRefund(): boolean {
    return this.refundedAmountInCents > 0;
  }

  isCancelled(): boolean {
    return (
      this.practiceLesson.isCancelled() &&
      this.practiceLesson.isConsideredForCancellationFee
    );
  }

  isArchived(): boolean {
    return this.paymentStatus === PaymentStatus.ARCHIVED;
  }

  isCancelledNotConsideredForFee(): boolean {
    return (
      this.practiceLesson.isCancelled() &&
      !this.practiceLesson.isConsideredForCancellationFee
    );
  }

  shouldShowCancellationFee(): boolean {
    return (
      this.isCancelled() &&
      ((this.practiceLesson.fullPriceInCents > 0 && !this.isRefunded()) ||
        this.paymentStatus === PaymentStatus.CANCELLATION_REFUNDED)
    );
  }

  shouldShowPayment(): boolean {
    return (
      !this.practiceLesson.isCancelled() ||
      (this.isCancelled() &&
        this.practiceLesson.refundAmountInCents !== undefined)
    );
  }

  cancellationFee(): number {
    if (
      !this.isCancelled() ||
      (this.isCancelled() && this.paymentStatus === PaymentStatus.REFUNDED)
    ) {
      return 0;
    }
    if (this.paymentStatus === PaymentStatus.CANCELLATION_REFUNDED) {
      return this.paidPriceInCents;
    }
    return this.practiceLesson.fullPriceInCents;
  }

  isArchiveable(): boolean {
    return (
      !this.isPracticeLesson() &&
      !this.isPaidOrRefunded() &&
      !this.isPartiallyPaid() &&
      !this.isArchived() &&
      (!this.hasPaymentReceiptNumber() || this.isTheoryLearning())
    );
  }

  hasPaymentReceiptNumber(): boolean {
    return this.paymentReceiptNumber && this.paymentReceiptNumber !== '';
  }

  isBasicFee(): boolean {
    return !!this.basicFee;
  }

  isLearnerCredit(): boolean {
    return !!this.learnerCredit;
  }

  isTheoryPackage(): boolean {
    return !!this.theoryPackage;
  }

  isLearnerTheoryTopic(): boolean {
    return !!this.learnerTheoryTopic;
  }

  isLearnerTheoryTopicVideo(): boolean {
    return (
      this.isLearnerTheoryTopic() &&
      this.learnerTheoryTopic.key.startsWith('theory_video_')
    );
  }

  isTheoryLearning(): boolean {
    return !!this.theoryLearningPaymentReceipt;
  }

  isPracticeLesson(): boolean {
    return !!this.practiceLesson;
  }

  isYoudriveBooking(): boolean {
    return !!this.youdriveBooking;
  }

  shouldShowPayOnline(): boolean {
    return (
      this.practiceLesson &&
      (this.practiceLesson.isConfirmedOrCompleted() ||
        this.shouldShowCancellationFee()) &&
      !this.isPaidOrRefunded()
    );
  }

  shouldShowRefundAmountInStatus(): boolean {
    return this.isRefunded() && this.refundedAmountInCents > 0;
  }

  shouldShowRefundAmountInStatusPartiallyPaid(): boolean {
    return this.isPartiallyPaid() && this.hasRefund();
  }

  getRefundedInfoText(): string {
    return `Erstattet ${NumberUtils.formatCurrency(
      this.refundedAmountInCents
    )}`;
  }

  shouldShowCreateRefund(): boolean {
    return (
      this.isPaid() ||
      (this.paymentStatus === PaymentStatus.REFUNDED &&
        this.paidPriceInCents > 0)
    );
  }

  shouldShowCreateRefundLearnerCredit(): boolean {
    return (
      !this.isLearnerCredit() || this.learnerCreditAvailableAmountInCents() > 0
    );
  }

  learnerCreditAvailableAmountInCents(): number {
    if (!this.isLearnerCredit()) {
      return 0;
    }
    if (this.refundedAmountInCents > 0) {
      return (
        this.learnerCredit.availableAmountInCents - this.refundedAmountInCents
      );
    }
    return this.learnerCredit.availableAmountInCents;
  }

  learnerCreditAvailableAmount(): number {
    return this.learnerCreditAvailableAmountInCents() / 100.0;
  }

  get bookingTypeName(): string {
    if (this.practiceLesson) {
      return this.practiceLesson.getReceiptDisplayName();
    } else if (this.learnerTheoryTopic) {
      return 'theoriestunde';
    } else if (this.basicFee) {
      return 'grundbetrag';
    } else if (this.learnerCredit) {
      return 'guthaben';
    } else if (this.theoryLearningPaymentReceipt) {
      return 'aktivierung-theorieapp';
    }
    return 'theoriepaket';
  }

  getReceiptFileName(): string {
    return `${this.paymentReceiptNumber}_${
      this.bookingTypeName
    }_${DateUtils.dateOnlyToString(this.date)}`;
  }

  // practice lesson func
  getPLTopicDisplayName() {
    if (this.practiceLesson) {
      return this.practiceLesson.getTopicDisplayName();
    }
    return this.youdriveBooking.getTypeDisplayName();
  }

  getPLDate(isInstructorView: boolean) {
    if (this.practiceLesson) {
      return isInstructorView
        ? this.practiceLesson.event?.getSchoolStartDate()
        : this.practiceLesson.event?.startDate;
    }
    return this.youdriveBooking.date;
  }

  getPLTimeInterval(isInstructorView: boolean) {
    if (this.practiceLesson) {
      return isInstructorView
        ? this.practiceLesson.event?.getSchoolTimeInterval()
        : this.practiceLesson.event?.getTimeInterval();
    }
    return this.youdriveBooking.getTimeInterval();
  }

  getPLStartTime(isInstructorView: boolean) {
    if (this.practiceLesson) {
      return isInstructorView
        ? this.practiceLesson.event?.getSchoolStartTime()
        : this.practiceLesson.event?.getStartTime();
    }
    return this.youdriveBooking.startTime;
  }

  getPLLengthInMinutes(isInstructorView: boolean) {
    if (this.practiceLesson) {
      return isInstructorView
        ? this.practiceLesson.event?.getSchoolDurationInMinutes()
        : this.practiceLesson.event?.getDurationInMinutes();
    }
    return this.youdriveBooking.getDurationInMinutes();
  }

  getPLCarDisplayName() {
    if (this.practiceLesson) {
      return this.practiceLesson.car?.getDisplayName();
    }
    return this.youdriveBooking.car;
  }

  getPLInstructorName() {
    if (this.practiceLesson) {
      return this.practiceLesson.instructor.getFullName();
    } else if (this.youdriveBooking?.instructorName) {
      return this.youdriveBooking.instructorName;
    }

    return 'n/a';
  }

  getPLInstructorShortenedName() {
    if (this.practiceLesson) {
      return this.practiceLesson.instructorShortenedName();
    }
    return this.youdriveBooking.instructorShortenedName();
  }

  getPLStatusDisplayNamee() {
    if (this.practiceLesson) {
      return this.practiceLesson.getStatusDisplayName();
    }
    return getPracticeLessonStatusDisplayName(PracticeLessonStatus.COMPLETED);
  }

  getPLStatusClass() {
    let practiceLessonStatus = PracticeLessonStatus.COMPLETED;
    if (this.practiceLesson) {
      practiceLessonStatus = this.practiceLesson.status;
    }

    switch (practiceLessonStatus) {
      case PracticeLessonStatus.CREATED_BY_INSTRUCTOR:
      case PracticeLessonStatus.CREATED_BY_LEARNER:
        return 'payment-status payment-refund';
      case PracticeLessonStatus.CONFIRMED_BY_INSTRUCTOR:
      case PracticeLessonStatus.CONFIRMED_BY_LEARNER:
        return 'payment-status status-confirmed';
      case PracticeLessonStatus.COMPLETED_BY_INSTRUCTOR:
      case PracticeLessonStatus.COMPLETED_BY_LEARNER:
        return 'payment-status payment-pending';
      case PracticeLessonStatus.COMPLETED:
        return 'payment-status payment-green';
      case PracticeLessonStatus.REJECTED_BY_INSTRUCTOR:
      case PracticeLessonStatus.REJECTED_BY_LEARNER:
      case PracticeLessonStatus.CANCELLED_BY_INSTRUCTOR:
      case PracticeLessonStatus.CANCELLED_BY_LEARNER:
      case PracticeLessonStatus.NOT_OCCURRED:
      case PracticeLessonStatus.NOT_OCCURRED_LEARNERS_FAULT:
      case PracticeLessonStatus.NOT_OCCURRED_INSTRUCTORS_FAULT:
        return 'payment-status payment-not-paid';
      default:
        return '';
    }
  }

  getPLTopic() {
    if (this.practiceLesson) {
      return this.practiceLesson.topic;
    }
    return this.youdriveBooking.type;
  }

  getPLHasEvent() {
    if (this.practiceLesson) {
      return !!this.practiceLesson.event;
    }
    return !!this.youdriveBooking.startDate;
  }

  getPLStartDateTime() {
    if (this.practiceLesson) {
      return this.practiceLesson.event.startDate.getTime();
    }
    return this.youdriveBooking.startDate.getTime();
  }
}

export const byStartDate = (a: LessonBooking, b: LessonBooking) => {
  if (!a?.practiceLesson?.startDate) {
    return 1;
  }
  if (!b?.practiceLesson?.startDate) {
    return -1;
  }

  const t1 = a.practiceLesson.startDate.getTime();
  const t2 = b.practiceLesson.startDate.getTime();

  if (t1 < t2) {
    return -1;
  }

  if (t1 === t2) {
    return 0;
  }

  return 1;
};
