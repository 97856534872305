import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PageListingResponse } from '@models/page-listing-response.model';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { UserDetail } from '@models/user-detail.model';

@Injectable()
export class UserService {
  constructor(private api: ApiService) {
  }

  getUserDetailsPaged(filters: Map<string, any>,
                      pageNumber: number = 1,
                      pageSize: number = 10): Observable<PageListingResponse<UserDetail>> {
    return this.api.getPaged(`${env.api}/user/paged`, pageNumber, pageSize, filters)
      .pipe(
        map(it => this.mapUserDetails(it))
      );
  }

  private mapUserDetails(res: PageListingResponse<UserDetail>) {
    return {
      total: res.total,
      records: res.records.map(it => new UserDetail(it))
    };
  }
}
