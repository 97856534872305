import { TheoryLessonTopic } from '@models/theory-lesson-topic.model';

export class TheoryTopicAttendee {
  id: number;
  topicKey: string;
  theoryLessonTopic: TheoryLessonTopic;
  learnerId: number;
  createdAt: Date;
  updatedAt: Date;

  constructor(json?: any) {
    if (!json) {
      return;
    }
    if (json.id) {
      this.id = +json.id;
    }
	this.createdAt = json.createdAt;
	this.updatedAt = json.updatedAt;
    this.topicKey = json.topicKey;
    this.theoryLessonTopic = json.theoryLessonTopic;
    if (json.learnerId) {
      this.learnerId = +json.learnerId;
    }
  }
}
