<div class="video-player-container" [class.controls-disabled]="!showControls">
  <div class="video-container">
    <ng-container *ngIf="showAudioSlider">
      <div class="volume-container">
        <input
          class="volume-slider"
          type="range"
          min="0"
          max="10"
          [ngModel]="volume"
          (change)="changeVolume($event.target.value)"
        />
        <i class="fa fa-volume"></i>
      </div>
      <div *ngIf="!isVideoMuted" class="unmute-button" (click)="onMute()">
        <i class="fa fa-volume"></i>Ton aus
      </div>
    </ng-container>
    <ng-container *ngIf="isVideoMuted">
      <div class="unmute-button" (click)="onUnmute()">
        <i class="fa fa-volume-slash"></i>Ton an
      </div>
    </ng-container>
    <video
      *ngIf="showVideo"
      #videoPlayer
      oncontextmenu="return false;"
      [attr.controlslist]="
        isVideoView ? 'nodownload' : 'nodownload nofullscreen'
      "
      [attr.controls]="showControls ? '' : null"
      (ended)="onVideoEnd()"
      (timeupdate)="onTimeUpdate()"
      (play)="onPlay()"
      (pause)="onPause()"
      [attr.crossorigin]="topicSubtitles?.length > 0 ? 'anonymous' : null"
      playsinline
      disablePictureInPicture
    >
      <source [src]="videoUrl" type="video/mp4" />
      <track
        *ngFor="let subtitle of topicSubtitles"
        [label]="subtitle.label"
        kind="subtitles"
        [srclang]="subtitle.language"
        [src]="subtitle.url"
      />
    </video>
    <!-- Video Controls -->
    <div class="video-controls" *ngIf="showSeek && showVideo && videoPlayer">
      <div class="video-time">
        {{ currentTimeMinutesString }}:{{ currentTimeSecondsString }} /
        {{ durationMinutesString }}:{{ durationSecondsString }}
      </div>
      <mat-slider
        class="seek-slider"
        [max]="duration"
        min="0"
        [ngModel]="currentTime"
        (input)="onSeeking($event.value)"
        (change)="onSeek($event.value)"
        aria-labelledby="example-name-label"
      >
      </mat-slider>
      <div
        class="subtitles-button"
        [class.active]="hasActiveSubtitle"
        [matMenuTriggerFor]="appMenu"
        [matMenuTriggerData]="{
          subtitles: videoPlayer?.nativeElement.textTracks
        }"
      >
        <i class="fas fa-closed-captioning"></i>
      </div>
    </div>
    <!-- Video Controls Learner Theory Topic -->
    <div
      class="video-controls-learner"
      *ngIf="
        !isVideoView &&
        topicSubtitles?.length > 0 &&
        !showSeek &&
        showVideo &&
        videoPlayer
      "
    >
      <div
        class="subtitles-button-learner"
        [class.active]="hasActiveSubtitle"
        [matMenuTriggerFor]="appMenu"
        [matMenuTriggerData]="{
          subtitles: videoPlayer?.nativeElement.textTracks
        }"
      >
        <i class="fas fa-closed-captioning"></i>
      </div>
    </div>
  </div>
  <mat-menu
    #appMenu="matMenu"
    xPosition="before"
    yPosition="above"
    class="table-mat-menu-panel"
  >
    <ng-template matMenuContent let-subtitles="subtitles">
      <button
        mat-menu-item
        (click)="setActiveSubtitle(null)"
        class="menu-btn subtitle-menu-btn"
      >
        <div>Off</div>
        <div *ngIf="!hasActiveSubtitle">
          <i class="fa fa-check"></i>
        </div>
      </button>
      <button
        mat-menu-item
        (click)="setActiveSubtitle(subtitle)"
        class="menu-btn subtitle-menu-btn"
        *ngFor="let subtitle of subtitles"
      >
        <div>
          {{ subtitle.label }}
        </div>
        <div *ngIf="subtitle.mode === 'showing'">
          <i class="fa fa-check"></i>
        </div>
      </button>
    </ng-template>
  </mat-menu>
</div>
