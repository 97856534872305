<ng-container *ngIf="isOnlineTheoryActivationCountdown; else normalTimer">
  <div class="countdown" *ngIf="timer">
    <div *ngIf="timer.days" class="countdown-item">
      <div class="countdown-item-time">{{ timer.days }}</div>
      <div class="countdown-item-label">Tage</div>
    </div>
    <div *ngIf="timer.hours" class="countdown-item">
      <div class="countdown-item-time">{{ timer.hours }}</div>
      <div class="countdown-item-label">Stunden</div>
    </div>
    <div *ngIf="!timer.days && timer.minutes" class="countdown-item">
      <div class="countdown-item-time">{{ timer.minutes }}</div>
      <div class="countdown-item-label">Minuten</div>
    </div>

    <div
      *ngIf="showOnEnd && !timer.days && !timer.minutes"
      class="countdown-item"
    >
      <div class="countdown-item-time">0</div>
      <div class="countdown-item-label">Minuten</div>
    </div>
  </div>
</ng-container>

<ng-template #normalTimer>
  <div class="time" *ngIf="timer">
    <span class="time-component" *ngIf="timer.days">{{ timer.days }}t</span>
    <span class="time-component" *ngIf="timer.hours">{{ timer.hours }}h</span>
    <span class="time-component" *ngIf="timer.minutes"
      >{{ timer.minutes }}{{ minutesLabel }}</span
    >
    <span class="time-component" *ngIf="timer.seconds"
      >{{ timer.seconds }}{{ secondsLabel }}</span
    >
  </div>

  <div
    class="time"
    *ngIf="showOnEnd && (!timer || (!timer.minutes && !timer.seconds))"
  >
    <span class="time-component">0{{ secondsLabel }}</span>
  </div>
</ng-template>
