import { isDrivingInstructorUserRole, USER_ROLE } from '@models/user.model';
import moment from 'moment';

export class UserInfo {
  id: number;
  uid: string;

  email: string;
  password: string;

  firstName: string;
  lastName: string;

  birthday?: Date;
  gender: string;
  phone: string;
  role: USER_ROLE;
  doi: boolean;
  accountId?: number;

  salutation?: string;
  title?: string;
  landline?: string;
  birthName?: string;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.uid = json.uid;

    this.email = json.email;
    if (json.password) {
      this.password = json.password;
    }

    if (json.firstName) {
      this.firstName = json.firstName;
    } else if (json.first_name) {
      this.firstName = json.first_name;
    }

    if (json.lastName) {
      this.lastName = json.lastName;
    } else if (json.last_name) {
      this.lastName = json.last_name;
    }

    if (json.birthday) {
      this.birthday = new Date(json.birthday);
    }
    this.gender = json.gender;
    this.phone = json.phone;
    this.role = json.role;
    this.doi = !!json.doi;
    if (json.accountId) {
      this.accountId = json.accountId;
    }

    this.salutation = json.salutation;
    this.title = json.title;
    this.landline = json.landline;
    this.birthName = json.birthName;
  }

  getDisplayName(): string {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    }

    if (this.firstName) {
      return `${this.firstName} (${this.email})`;
    }

    if (this.lastName) {
      return `${this.lastName} (${this.email})`;
    }

    return this.email;
  }

  getShortenedName() {
    return `${this.firstName} ${this.lastName.charAt(0)}.`;
  }

  getInitials(): string {
    if (this.firstName && this.lastName) {
      return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`;
    }

    return '';
  }

  getSearchName(): string {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    }

    if (this.firstName) {
      return this.firstName;
    }

    if (this.lastName) {
      return this.lastName;
    }

    return this.email;
  }

  hasFilledCredentials(): boolean {
    return !!this.email && !!this.password;
  }

  hasAccount(): boolean {
    return this.accountId > 0;
  }

  isPlatformUser(): boolean {
    return this.role === USER_ROLE.PLATFORM;
  }

  isInstructor() {
    return this.role === USER_ROLE.INSTRUCTOR;
  }

  isDrivingInstructor() {
    return isDrivingInstructorUserRole(this.role);
  }

  isLearner() {
    return this.role === USER_ROLE.STUDENT;
  }

  isPrincipalOrManagement(): boolean {
    return [USER_ROLE.PRINCIPAL, USER_ROLE.MANAGEMENT].includes(this.role);
  }

  isPlatformOrPrincipalOrManagement(): boolean {
    return [
      USER_ROLE.PLATFORM,
      USER_ROLE.PRINCIPAL,
      USER_ROLE.MANAGEMENT,
    ].includes(this.role);
  }

  isPlatformOrPrincipalOrManagementOrEddySupportUser(): boolean {
    return [
      USER_ROLE.PLATFORM,
      USER_ROLE.PRINCIPAL,
      USER_ROLE.MANAGEMENT,
      USER_ROLE.EDDY_SUPPORT,
    ].includes(this.role);
  }

  isMale(): boolean {
    return this.gender === 'm';
  }

  isFemale(): boolean {
    return this.gender === 'f';
  }

  age(): string {
    return `${moment().diff(moment(this.birthday), 'years')} Jahre`;
  }
}
