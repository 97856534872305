import { AppRoutes } from './app.routes';
import { MenuRoute } from '@models/menu-route.model';

export const publicRoutes = [
  `/${AppRoutes.StudentsInfo.root}`,
  `/${AppRoutes.SchoolsInfo.root}`,
  `/${AppRoutes.Root.schools}`,
  `/${AppRoutes.Root.vipSchools}`,
  `/${AppRoutes.Root.learnerTermsAndConditions}`,
  `/${AppRoutes.Root.instructorTermsAndConditions}`,
  `/${AppRoutes.Root.dataPrivacyApp}`,
  `/${AppRoutes.Root.dataPrivacyWeb}`,
  `/${AppRoutes.Root.imprint}`,
];

export const NAV_ROUTES_PUBLIC = [
  { content: 'Fahrschul App', route: '/' + AppRoutes.StudentsInfo.root },
  { content: 'Fahrschule Finden', route: '/' + AppRoutes.Root.schools },
  { content: 'Online-Theorie', route: '/' + AppRoutes.Root.eddyAcademy },
  { content: '|', type: 'divider' },
  {
    content: 'Für Fahrlehrer',
    route:
      '/' + AppRoutes.SchoolsInfo.root + '/' + AppRoutes.SchoolsInfo.software,
  },
];

export const NAV_ROUTES_PUBLIC_SCHOOL = [
  {
    content: 'Verwaltungssoftware & App',
    route:
      '/' + AppRoutes.SchoolsInfo.root + '/' + AppRoutes.SchoolsInfo.software,
  },
  {
    content: 'Online-Theorie Service',
    route:
      '/' +
      AppRoutes.SchoolsInfo.root +
      '/' +
      AppRoutes.SchoolsInfo.onlineTheoryService,
  },
  {
    content: 'Theorie-Unterricht',
    route:
      '/' +
      AppRoutes.SchoolsInfo.root +
      '/' +
      AppRoutes.SchoolsInfo.theoryLessonVideos,
  },
  { content: '|', type: 'divider' },
  { content: 'Für Fahrschüler', route: '/' + AppRoutes.StudentsInfo.root },
];

export const NAV_ROUTES_PUBLIC_EXTERNAL = [];

export const NAV_ROUTES_INTERNAL = [
  { content: 'Fahrschulen', route: AppRoutes.Internal.schools },
  { content: 'Affiliate', route: AppRoutes.Root.advertising },
  { content: 'Umfragen', route: AppRoutes.Questions.root },
  { content: 'Users', route: AppRoutes.Internal.users },
  { content: 'App Feedbacks', route: AppRoutes.Internal.appFeedbacks },
  { content: 'Abrechnung', route: AppRoutes.Internal.accounting },
];

export const INSTRUCTOR_BASIC_ROUTES: MenuRoute[] = [
  {
    name: 'Dashboard',
    route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.dashboard}`,
    iconClasses: 'fal fa-tachometer-alt-fastest',
  },
];

export const INSTRUCTOR_BASE_DATA_ROUTE: MenuRoute = {
  name: 'Stammdaten',
  iconClasses: 'fal fa-home',
  children: [
    {
      name: 'Fahrschule',
      route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.generalInfo}`,
      iconClasses: 'fal fa-home',
    },
    // {
    //   name: 'Standorte',
    //   route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.generalInfo}`,
    //   iconClasses: 'fal fa-home'
    // },
    {
      name: 'Team',
      route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.colleagues}`,
      iconClasses: 'fal fa-user-circle',
    },
    {
      name: 'Fahrzeuge',
      route: `/${AppRoutes.Cars.root}`,
      iconClasses: 'fal fa-car',
    },
    {
      name: 'Preislisten',
      route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.pricelists}`,
      iconClasses: 'fal fa-id-card',
    },
    {
      name: 'Firmendaten',
      route: `/${AppRoutes.Root.companySettings}`,
      iconClasses: 'fal fa-building',
    },
    {
      name: 'Digitale Funktionen',
      route: `/${AppRoutes.Root.schoolSettings}`,
      iconClasses: 'fal fa-building',
    },
  ],
};

export const INSTRUCTOR_ACCOUNTING_ROUTE: MenuRoute = {
  name: 'Services',
  subtitle: '(Online-Theorie)',
  route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.accounting}`,
  iconClasses: 'fal fa-video',
};

export const INSTRUCTOR_DAYPROOFS_ROUTE: MenuRoute = {
  name: 'Tagesnachweise',
  route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.dayProofs}`,
  iconClasses: 'fal fa-file-alt',
};

export const INSTRUCTOR_PRACTICE_BOOKINGS_ROUTE: MenuRoute = {
  name: 'Abrechnung',
  route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.bookings}`,
  iconClasses: 'fal fa-steering-wheel',
};

export const INSTRUCTOR_ONLINE_THEORY_BOOKINGS_ROUTE: MenuRoute = {
  name: 'Theorietermine',
  route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.onlineTheoryBookings}`,
  iconClasses: 'fal fa-book',
};

export const INSTRUCTOR_TRAINING_CONFIRMATIONS_ROUTE: MenuRoute = {
  name: 'Ausbildungsnachweise',
  route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.trainingConfirmations}`,
  iconClasses: 'fal fa-file-certificate',
};

export const INSTRUCTOR_REPORTS_ROUTE: MenuRoute = {
  name: 'Berichte',
  route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.reports}`,
  iconClasses: 'fal fa-chart-bar',
};

export const INSTRUCTOR_THEORY_DASHBOARD_ROUTE: MenuRoute = {
  name: 'Theorie Lernen',
  route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.theoryDashboard}`,
  iconClasses: 'fal fa-mobile-android',
};

export const INSTRUCTOR_LESSONS_ROUTE: MenuRoute = {
  name: 'Unterricht',
  route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.lessons}`,
  iconClasses: 'fal fa-users-class',
};

export const INSTRUCTOR_CALENDAR_ROUTE: MenuRoute = {
  name: 'Kalender',
  route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.calendar}`,
  iconClasses: 'fal fa-calendar-alt',
};

export const INSTRUCTOR_EXAM_PLANNING_ROUTE: MenuRoute = {
  name: 'Prüfungsplaner',
  iconClasses: 'fal fa-graduation-cap',
  children: [
    {
      name: 'Prüfungstermine',
      route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.exams}`,
      iconClasses: 'fal fa-graduation-cap',
    },
    {
      name: 'Praxis Prüfungen',
      route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.practiceExams}`,
      iconClasses: 'fal fa-car',
    },
    {
      name: 'Theorie Prüfungen',
      route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.theoryExams}`,
      iconClasses: 'fal fa-pen',
    },
  ],
};

export const INSTRUCTOR_LEARNER_ROUTE: MenuRoute = {
  name: 'Fahrschüler',
  route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.learners}`,
  iconClasses: 'fal fa-users',
};

export const INSTRUCTOR_SCHEDULING_LESSONS_ROUTES: MenuRoute[] = [
  INSTRUCTOR_CALENDAR_ROUTE,
  INSTRUCTOR_EXAM_PLANNING_ROUTE,
  // {
  //   name: ' Prüfungen ',
  //   route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.calendar}`,
  //   iconClasses: 'fal fa-calendar-alt'
  // },
  {
    name: 'Buchungen',
    iconClasses: 'fal fa-th-list',
    children: [
      INSTRUCTOR_DAYPROOFS_ROUTE,
      INSTRUCTOR_PRACTICE_BOOKINGS_ROUTE,
      INSTRUCTOR_ONLINE_THEORY_BOOKINGS_ROUTE,
      INSTRUCTOR_TRAINING_CONFIRMATIONS_ROUTE,
      INSTRUCTOR_REPORTS_ROUTE,
    ],
  },
  {
    name: 'Fahrschüler',
    route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.learners}`,
    iconClasses: 'fal fa-users',
  },
  INSTRUCTOR_THEORY_DASHBOARD_ROUTE,
  INSTRUCTOR_LESSONS_ROUTE,
  // {
  //   name: 'Analysen',
  //   iconClasses: 'fal fa-chart-bar',
  //   children: [
  //     INSTRUCTOR_REPORTS_ROUTE
  //   ]
  // },
];

export const INSTRUCTOR_SCHEDULING_LESSONS_ROUTES_WITH_ACCOUNTING: MenuRoute[] =
  [
    INSTRUCTOR_CALENDAR_ROUTE,
    INSTRUCTOR_EXAM_PLANNING_ROUTE,
    {
      name: 'Buchungen',
      iconClasses: 'fal fa-th-list',
      children: [
        {
          name: 'Tagesnachweise',
          route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.dayProofs}`,
          iconClasses: 'fal fa-file-alt',
        },
        {
          name: 'Abrechnung',
          route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.bookings}`,
          iconClasses: 'fal fa-steering-wheel',
        },
        INSTRUCTOR_ONLINE_THEORY_BOOKINGS_ROUTE,
        INSTRUCTOR_TRAINING_CONFIRMATIONS_ROUTE,
        INSTRUCTOR_ACCOUNTING_ROUTE,
        INSTRUCTOR_REPORTS_ROUTE,
      ],
    },
    {
      name: 'Fahrschüler',
      route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.learners}`,
      iconClasses: 'fal fa-users',
    },
    INSTRUCTOR_THEORY_DASHBOARD_ROUTE,
    INSTRUCTOR_LESSONS_ROUTE,
    // {
    //   name: 'Analysen',
    //   iconClasses: 'fal fa-chart-bar',
    //   children: [
    //     INSTRUCTOR_REPORTS_ROUTE
    //   ]
    // },
  ];

export const INSTRUCTOR_PARTNER_ROUTES: MenuRoute[] = [
  INSTRUCTOR_BASE_DATA_ROUTE,
  {
    name: 'Eddy Club',
    iconClasses: 'far fa-club',
    children: [
      {
        name: 'Allgemeines',
        route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.eddyclub}`,
        iconClasses: 'far fa-club',
      },
      {
        name: 'Online-Theorie Service',
        route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.eddyclubCalendar}`,
        iconClasses: 'fal fa-calendar-alt',
      },
      // {
      //   name: 'Marketing Service etc.',
      //   route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.eddyclub}`,
      //   iconClasses: 'fal fa-calendar-alt'
      // },
    ],
  },
];

export const INSTRUCTOR_EDDY_CLUB_ROUTES: MenuRoute[] = [
  {
    name: 'Online-Theorie',
    route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.onlineTheoryAttendances}`,
    iconClasses: 'fal fa-video',
  },
  INSTRUCTOR_BASE_DATA_ROUTE,
  {
    name: 'Eddy Club',
    route: `/${AppRoutes.InstructorsInternal.root}/${AppRoutes.InstructorsInternal.eddyclubSchools}`,
    iconClasses: 'far fa-club',
  },
];

export const NAV_ROUTES_LEARNER_BASIC: MenuRoute[] = [
  {
    name: 'Übersicht',
    route: `/${AppRoutes.LearnersInternal.root}/${AppRoutes.LearnersInternal.profile}`,
    iconClasses: 'fal fa-tachometer-alt-fastest',
  },
];

export const LEARNER_ONLINE_THEORY_ROUTE: MenuRoute = {
  name: 'Online-Theorie',
  route: `/${AppRoutes.EddyAcademyLearner.root}/${AppRoutes.EddyAcademyLearner.dashboardPage}`,
  iconClasses: 'fal fa-graduation-cap',
};

export const LEARNER_THEORY_LEARNING_ROUTE: MenuRoute = {
  name: 'Theorie Lernen',
  route: `/${AppRoutes.LearnersInternal.root}/${AppRoutes.LearnersInternal.theoryLearning}`,
  iconClasses: 'fal fa-mobile-android',
};

export const LEARNER_THEORY_VIDEOS_ROUTE: MenuRoute = {
  name: 'Unterrichtsvideos',
  route: `/${AppRoutes.LearnersInternal.root}/${AppRoutes.LearnersInternal.theoryVideos}`,
  iconClasses: 'fal fa-video',
};

export const NAV_ROUTES_LEARNER_BASIC_LAST: MenuRoute[] = [
  LEARNER_THEORY_VIDEOS_ROUTE,
  {
    name: 'Buchungen',
    route: `/${AppRoutes.LearnersInternal.root}/${AppRoutes.LearnersInternal.bookings}`,
    iconClasses: 'fal fa-th-list',
  },
  {
    name: 'Persönliche Daten',
    route: `/${AppRoutes.LearnersInternal.root}/${AppRoutes.LearnersInternal.personal}`,
    iconClasses: 'fal fa-user',
  },
  {
    name: 'Angebote',
    route: `/${AppRoutes.Offers.root}`,
    iconClasses: 'fal fa-gift',
  },
  {
    name: 'Fahrschule',
    route: `/${AppRoutes.LearnersInternal.root}/${AppRoutes.LearnersInternal.schoolProfile}`,
    iconClasses: 'fal fa-home',
  },
];
