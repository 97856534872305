import { Day } from '@models/day.model';
import { SchoolOpeningHourType } from '@models/school-opening-hour-type.model';

export class SchoolOpeningHour {
  id: number;
  schoolId: number;

  day: Day;
  type: SchoolOpeningHourType;
  fromHour: number;
  fromMinute: number;
  toHour: number;
  toMinute: number;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.schoolId = +json.schoolId;
    this.day = json.day;
    this.type = json.type;
    this.fromHour = +json.fromHour;
    this.fromMinute = +json.fromMinute;
    this.toHour = +json.toHour;
    this.toMinute = +json.toMinute;
  }

  isValid(): boolean {
    return this.timesInRange() && this.fromIsLessThanTo();
  }

  private timesInRange(): boolean {
    return (this.fromHour >= 0 && this.fromHour < 24)
      && (this.fromMinute >= 0 && this.fromMinute < 60)
      && (this.toHour >= 0 && this.toHour < 24)
      && (this.toMinute >= 0 && this.toMinute < 60);
  }

  private fromIsLessThanTo() {
    return (this.fromHour < this.toHour)
      || ((this.fromHour === this.toHour) && (this.fromMinute < this.toMinute));
  }

  get isTheoryOpeningHour(): boolean {
    return this.type === SchoolOpeningHourType.THEORY;
  }
}
