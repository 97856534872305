export class Messages {
  static Errors = class {
    static StartDateBeforeEndDate = 'Das Startdatum muss vor dem Enddatum liegen';
    static EventDurationExceeded = 'Die Fahrstunde darf höchstens 4 Stunden dauern';
    static EventDurationMustBeSpecified = 'Die Ereignisdauer muss angegeben werden';
    static EventDurationDoesNotFitIntoTimeRange = 'Du hast einen Zeitraum erstellt, der länger ist als deine Verfügbarkeit. Bitte gehe einen Schritt zurück und wähle eine längere Verfügbarkeit aus.';
    static LessonTopicMustBeSpecified = 'Die Art der Theoriestunde muss angegeben werden';
    static OverlappingEvents = 'Du hast in diesem Zeitraum bereits eine oder mehrere Stunde(n) angelegt. Bitte wählen einen anderen Zeitraum aus.';
    static PracticeLessonIsInThePast = 'Die Fahrstunde liegt in der Vergangenheit';
    static ProfileAlreadyCompleted = 'Die Anmeldung war bereits abgeschlossen';
    static ForbiddenError = 'Sie dürfen die Daten nicht speichern';
    static MaximumCapacityMustBeSpecified = 'Die Maximal Kapazität muss angegeben werden';
    static InstructorMustBeSelected = 'Es muss ein Fahrlehrer ausgewählt werden!';
    static InvalidCapacityDecrease = 'Es hast mehr Teilnehmer für deine Theoriestunde als deine geänderte Raum-Kapazität hergibt.';
    static AddressMustBeFullySpecified = 'Die Standort der Theoriestunde ist nicht vollständig angegeben';
    static ResendInvitationError = 'konnte die Einladung nicht erneut senden';
    static SendOnboardingReminderError = 'Die Erinnerungsmail konnte nicht versendet werden';
    static NoDayTimeRanges = 'Jeder ausgewählte Tag muss mindestens einen Zeitraum haben';
    static NoPlatformLicensePriceSet = 'Die Lizenz kann nicht zugewiesen werden, da noch keine Lizenzgebühr in deinen Account-Einstellungen hinterlegt wurde. Bei Fragen wende dich bitte an den drivEddy Support.';
    static CouldNotSendEmail = 'Die E-Mail konnte nicht gesendet werden';
  };

  static StudentsInfo = class {
    static root = 'fahrschueler';
  };

  static SchoolsInfo = class {
    static root = 'fahrlehrer';
  };

  static LicenseRequest = class {
    static root = 'fuehrerschein';
    static preise = 'fuehrerschein/preise';
    static findSchool = 'fuehrerschein/fahrschule';
    static preiseRouteSegments = ['fuehrerschein', 'preise'];
  };

  static Products = class {
    static root = 'products';
    static License = class {
      static edit = 'products/license/edit';
      static add = 'products/license/add';
    };
    static Bundle = class {
      static select = 'products/bundle/select';
      static add = 'products/bundle/add';
      static create = 'products/bundle/create';
    };
  };
  static Internal = class {
    static root = 'internal';
    static schoolsPart = 'schools';
    static schools = 'internal/schools';
    static teamMembers = 'internal/teammembers';
    static teamMembersPart = 'teammembers';
    static instructors = 'internal/instructors';
    static instructorsPart = 'instructors';
    static licenses = 'internal/licenses';
    static licensesPart = 'licenses';
    static edit = 'edit';
    static bookings = 'internal/bookings';
    static users = 'internal/users';
    static appFeedbacks = 'internal/appfeedbacks';
  };

  static VendorConsole = class {
    static dashboard = 'dashboard';
  };

  static InstructorsInternal = class {
    static root = 'instructors';
    // static dashboard = 'dashboard';
    static generalInfo = 'general';
    static licenses = 'licenses';
    static license = 'licenses/:licenseId';
    static colleagues = 'colleagues';
    static colleague = 'colleagues/:id';
    static learners = 'learners';
    static calendar = 'calendar';
  };

  static Questions = class {
    static root = 'questions';
  };

  static Bookings = class {
    static root = 'bookings';
    static overview = 'overview';
  };

  static AppFeedbacks = class {
    static root = 'appfeedbacks';
  };

  static Cars = class {
    static root = 'cars';
  };

  static shared = class {
    static create = 'create';
  };
}
