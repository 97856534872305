import { Component, Inject } from '@angular/core';
import { Language } from '@models/language.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SchoolService } from '@services/school.service';

@Component({
  selector: 'eddy-school-language-supported-dialog',
  templateUrl: './school-language-supported-dialog.component.html',
  styleUrls: ['./school-language-supported-dialog.component.scss']
})
export class SchoolLanguageSupportedDialogComponent {

  school_id: number;
  supported_languages: Language[] = [];

  availableLanguages: Language[];

  constructor(public dialogRef: MatDialogRef<SchoolLanguageSupportedDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private schoolService: SchoolService) {
    this.supported_languages = data.supported_languages || [];

    this.availableLanguages = data.all_languages;
    this.school_id = data.school_id;
  }

  onChoose(_language: Language) {
    this.dialogRef.close(_language);
  }

  onClose() {
    this.dialogRef.close(this.supported_languages);
  }

  isSupported(_language_id: number) {
    const result = this.supported_languages.filter(function (language) {
      return language.id === _language_id;
    }).length;
    return result > 0;
  }

  onSupportChange(language) {

    if (this.isSupported(language.id)) {
      this.supported_languages = this.supported_languages.filter(function (oldLanguage) {
        return language.id !== oldLanguage.id;
      });

      if (this.school_id) {
        this.schoolService.removeLanguageFromSchool(language.id, this.school_id).subscribe();
      }
    } else {
      this.supported_languages.push(language);
    }
  }

}
