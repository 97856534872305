<ng-select
  class="custom-height-40 {{ class }}"
  [class.full-width]="fullWidth"
  [class.rounded-corners]="roundedCorners"
  [items]="paymentTypes"
  (change)="onChange($event)"
  [clearable]="true"
  placeholder="Bezahlung"
  notFoundText=""
>
  <ng-template ng-option-tmp let-item="item">
    <div class="select-value-wrapper">
      {{ getPaymentTypeDisplayName(item) }}
    </div>
  </ng-template>
  <ng-template ng-label-tmp let-item="item">
    <div class="select-value-wrapper">
      {{ getPaymentTypeDisplayName(item) }}
    </div>
  </ng-template>
</ng-select>
