import { ApiService } from '@services/api.service';
import { environment as env } from '@environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ZoomService {
  constructor(private api: ApiService) {
  }

  getSignature(meetingNumber: string, role: number): Observable<string> {
    const params: Map<string, string> = new Map();
    params.set('meetingNumber', meetingNumber);
    params.set('role', role.toString());
    return this.api.get(`${env.apiSchoolService}/zoom_signature`, params);
  }
}
