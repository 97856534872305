import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class MetaService {
  private readonly titleAlertsRegex: RegExp = new RegExp(/^\(\d+\)/);

  constructor(private title: Title,
              private meta: Meta) {
  }

  setTitle(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({property: 'og:title', content: title});
    this.meta.updateTag({name: 'twitter:title', content: title});
  }

  private setDescription(description: string) {
    this.meta.updateTag({name: 'description', content: description});
    this.meta.updateTag({property: 'og:description', content: description});
    this.meta.updateTag({name: 'twitter:description', content: description});
  }

  private setOpenGraphUrl(url?: string) {
    if (!url) {
      if (!window || !window.location) {
        return;
      }

      url = window.location.href;
    }

    this.meta.updateTag({property: 'og:url', content: url});
  }

  private setOpenGraphImage(ogImage?: string) {
    if (!ogImage) {
      return;
    }

    this.meta.updateTag({property: 'og:image', content: ogImage});
  }

  setMetatags(title: string, description: string, url?: string, ogImage?: string) {
    this.setTitle(title);
    this.setDescription(description);
    this.setOpenGraphUrl(url);
    this.setOpenGraphImage(ogImage);
  }

  updateAlertsCounterInTitle(alerts: number, resetPreviousValue?: boolean) {
    const existingTitle = this.title.getTitle();
    let alertsCount = alerts;

    const matchedAlertsPart = existingTitle.match(this.titleAlertsRegex);
    const hasAlerts = matchedAlertsPart?.length;

    if (hasAlerts && !resetPreviousValue) {
      const matchedString = matchedAlertsPart[0];
      alertsCount += +matchedString.substring(1, matchedString.length - 1);
    }

    if (hasAlerts) {
      this.setTitle(existingTitle.replace(this.titleAlertsRegex, `(${alertsCount})`));
    } else {
      this.setTitle(`(${alertsCount}) ${existingTitle}`);
    }
  }

  removeAlertsCounterFromTitle() {
    this.setTitle(this.title.getTitle().replace(this.titleAlertsRegex, '').trim());
  }
}
