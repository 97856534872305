<!-- removed input option [matDatepickerFilter]="disableSunday"-->
<div class="datepicker-wrapper" [class.fluid]="widthStyle == 'fluid'">
  <input class="datepicker" [placeholder]="placeholder" readonly (click)="datePicker.open()"
         [matDatepicker]="datePicker" name="dateCtrl"
         [min]="min" [max]="max"
         (dateChange)="onDateChanged($event.value)"
         [disabled]="disabled"
  />
  <i class="fal fa-calendar-alt datepicker-icon" [class.disabled]="disabled" (click)="datePicker.open()"></i>
  <mat-datepicker [touchUi]="isMobile && !touchUiDisabled" #datePicker [disabled]="disabled"
  ></mat-datepicker>
</div>
