import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { PracticeLesson } from '@models/practice-lesson.model';
import { saveAs } from 'file-saver';
import { PageListingResponse } from '@models/page-listing-response.model';

@Injectable()
export class PracticeLessonService {
  private readonly basePath = `${env.apiSchoolService}`;

  constructor(private api: ApiService) {}

  getPracticeLesson(practiceLessonId: number): Observable<PracticeLesson> {
    return this.api
      .get<PracticeLesson>(
        `${this.basePath}/practice_lessons/${practiceLessonId}`
      )
      .pipe(map((it) => new PracticeLesson(it)));
  }

  getPracticeLessonRequestsPaged(
    teamMemberId: number,
    pageNumber: number,
    pageSize: number,
    filters?: Map<string, any>
  ): Observable<PageListingResponse<PracticeLesson>> {
    return this.api
      .getPaged(
        `${this.basePath}/team_members/${teamMemberId}/practice_lesson_requests`,
        pageNumber,
        pageSize,
        filters
      )
      .pipe(map(this.mapPracticeLessonsPaged));
  }

  private mapPracticeLessonsPaged(
    res: PageListingResponse
  ): PageListingResponse<PracticeLesson> {
    return {
      total: res.total,
      records: res.records.map((it) => new PracticeLesson(it)),
    };
  }

  markPracticeLessonAsPaidInCash(
    practiceLessonId: number,
    isPaidByBankTransfer: boolean,
    description: string,
    isPaidToEddy: boolean
  ): Observable<void> {
    return this.api.post<void>(
      `${this.basePath}/practice_lessons/${practiceLessonId}/paid_in_cash`,
      { isPaidByBankTransfer, description, isPaidToEddy }
    );
  }

  markPracticeLessonAsPaidInCashWithCustomInvoice(
    practiceLessonId: number,
    invoiceDocumentBase64: string
  ): Observable<void> {
    return this.api.post<void>(
      `${this.basePath}/practice_lessons/${practiceLessonId}/mark_as_paid_with_invoice`,
      {
        invoiceDocumentBase64,
      }
    );
  }

  markLessonAsCompleted(practiceLessonId: number): Observable<void> {
    return this.api.post<void>(
      `${this.basePath}/practice_lessons/${practiceLessonId}/completed`,
      {}
    );
  }

  downloadInstructorInvoice(practiceLesson: PracticeLesson): Observable<any> {
    return this.api
      .getBlob(
        `${this.basePath}/practice_lessons/${practiceLesson.id}/instructor_invoice`
      )
      .pipe(
        tap((blob: Blob) => {
          saveAs(
            blob,
            `${practiceLesson.event.getTimeInterval()}-rechnung.pdf`
          );
        })
      );
  }

  downloadLessonProof(practiceLesson: PracticeLesson): Observable<Blob> {
    return this.api
      .getBlob(
        `${this.basePath}/practice_lessons/${practiceLesson.id}/instructor_proof`
      )
      .pipe(
        tap((blob: Blob) => {
          saveAs(blob, `${practiceLesson.getTimeInterval()}-nachweis.pdf`);
        })
      );
  }

  downloadLessonRatings(practiceLesson: PracticeLesson): Observable<Blob> {
    return this.api
      .getBlob(
        `${this.basePath}/practice_lessons/${practiceLesson.id}/ratings_document`
      )
      .pipe(
        tap((blob: Blob) => {
          saveAs(blob, `${practiceLesson.getTimeInterval()}-bewertung.pdf`);
        })
      );
  }

  acceptPracticeLessonByKey(
    lessonKey: string,
    fromInstructor: boolean
  ): Observable<PracticeLesson> {
    return this.api
      .post<PracticeLesson>(
        `${this.basePath}/practice_lessons/key/${lessonKey}/accept?fromInstructor=${fromInstructor}`,
        {}
      )
      .pipe(map((it) => new PracticeLesson(it)));
  }

  rejectPracticeLessonByKey(
    lessonKey: string,
    fromInstructor: boolean
  ): Observable<PracticeLesson> {
    return this.api
      .post<PracticeLesson>(
        `${this.basePath}/practice_lessons/key/${lessonKey}/reject?fromInstructor=${fromInstructor}`,
        {}
      )
      .pipe(map((it) => new PracticeLesson(it)));
  }

  sendPaymentReminders(lessonIds: number[]): Observable<void> {
    return this.api.post<void>(
      `${this.basePath}/practice_lessons/send_payment_reminders`,
      { practiceLessonIds: lessonIds }
    );
  }

  buildPracticeLessonPaymentLink(
    practiceLesson: PracticeLesson,
    redirect = true
  ): string {
    if (!redirect) {
      return `${env.paymentRootLink}/lessonbookings/${practiceLesson.key}`;
    }
    return `${env.paymentRootLink}/lessonbookings/${
      practiceLesson.key
    }?redirectUrl=${this.getPaymentRedirectUrl(true)}`;
  }

  private getPaymentRedirectUrl(
    isSingleLessonPayment?: boolean,
    baseUrl?: string
  ) {
    const base = baseUrl || window.location.href;

    const paymentSuccessParam = isSingleLessonPayment
      ? 'lessonpayment=success'
      : 'payment=success';

    let url = base;
    if (base.indexOf('?') !== -1) {
      url = url.replace('?', `?${paymentSuccessParam}&`);
    } else {
      url = `${url}?${paymentSuccessParam}`;
    }

    return encodeURIComponent(url);
  }
}
