import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDatepicker } from '@angular/material/datepicker';


@Component({
  selector: 'eddy-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatepickerComponent,
      multi: true
    }
  ]
})
export class DatepickerComponent implements ControlValueAccessor, AfterViewInit {
  private _date: Date;

  @Input() set date(val: Date) {
    if (this.min && val < this.min) {
      return;
    }
    if (this.max && val > this.max) {
      return;
    }
    this._date = val;

    this.propagateChange(this._date);
    this.updateDatepickerValue();
  }

  get date() {
    return this._date;
  }

  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();

  @Input() min: Date;

  @Input() max: Date;

  @Input() widthStyle: 'fixed' | 'fluid' = 'fixed';

  @Input() placeholder = '';

  @Input() disabled = false;

  @Input() touchUiDisabled = false;

  @ViewChild('datePicker', {static: true}) datepicker: MatDatepicker<Date>;

  constructor(private breakpointObserver: BreakpointObserver) {
  }

  ngAfterViewInit() {
    this.updateDatepickerValue();
  }

  // propagate changes into the custom form control
  propagateChange: (any) => void = (_) => {
  }

  onDateChanged(date: Date) {
    this.date = date;
    this.dateChange.emit(this.date);
  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    this.date = value;
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  private updateDatepickerValue() {
    if (!this.datepicker) {
      return;
    }

    setTimeout(() => {
      this.datepicker.select(this.date);
    }, 0);
  }

  disableSunday(d: Date) {
    if (d.getDay() !== 0) {
      return d;
    }
  }

}
