<div>
  <div class="row no-side-space">
    <div class="rating">
      <eddy-rating-stars
        [rating]="ratings?.rating"
        [hollowStars]="true"
        [alternativeStarsColor]="true"
        [showRatingNumber]="false"
        starSize="16"
        [spaceBetweenStars]="true"
        additionalLabelTextPosition="none"
        [readOnly]="true"
        [displayInline]="true"
      >
      </eddy-rating-stars>
      <div class="rating-text">
        Bewertung: {{ ratings?.rating | number : '1.1-2' }}/5 ({{
          ratings?.numberOfRatings
        }}
        Rezensionen)
      </div>
    </div>
  </div>
  <div class="row no-side-space rating-containers-row">
    <div class="rating-container">
      <div class="rating-text">
        Ich habe meinen Theoriepflichtteil bei drivEddy absolviert und war mega
        zufrieden. Der Vorgang war super einfach und schnell. Jetzt bin ich
        meinem Führerschein einen großen Schritt näher gekommen und starte
        kommende Woche mit der ersten Praxisstunde bei meiner Fahrschule um die
        Ecke.
      </div>
      <div class="rating-source"><b>Patrick S.</b>, Fahrschüler</div>
      <i class="fas fa-quote-right"></i>
    </div>
    <div class="rating-container">
      <div class="rating-text">
        Wir sind sehr begeistert von drivEddy und insbesondere von der schnellen
        und zuverlässigen Betreuung. Auch unsere Fahrschülerinnen, Fahrschüler
        und deren Eltern sind sehr zufrieden mit dem Onlineunterricht. Wir
        freuen uns auf die weitere Zusammenarbeit, die vor allem in Zeiten von
        Corona, sehr wertvoll ist.
      </div>
      <div class="rating-source"><b>Sabrina S.</b>, Fahrschule Stoll</div>
      <i class="fas fa-quote-right"></i>
    </div>

    <div class="rating-container">
      <div class="rating-text">
        Uns wurde drivEddy von einer befreundeten Fahrschule empfohlen. Zunächst
        waren wir skeptisch, haben aber schnell gemerkt, dass es ein genialer,
        einzigartiger Service ist. Super Betreuung, sehr faire Preise und ein
        reibungsloser Ablauf der Online-Theorie. <br />
        Das sehr positive Feedback meiner Fahrschüler rundet die Sache perfekt
        ab. Vielen Dank!
      </div>
      <div class="rating-source"><b>Rainer K.</b>, Fahrschule Kliersdrive</div>
      <i class="fas fa-quote-right"></i>
    </div>
  </div>
</div>
