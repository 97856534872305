export enum PaymentPreference {
  LICENSE_PACKAGE = 'LICENSE_PACKAGE',
  PAY_ON_INSTALLMENTS = 'PAY_ON_INSTALLMENTS',
  CREDIT_CARD = 'CREDIT_CARD',
  CASH = 'CASH',
}

export function getAllPaymentPreferences() {
  return [
    PaymentPreference.CREDIT_CARD,
    PaymentPreference.CASH,
    PaymentPreference.LICENSE_PACKAGE,
    PaymentPreference.PAY_ON_INSTALLMENTS,
  ];
}

export function getDisplayName(paymentPreference: PaymentPreference): string {
  switch (paymentPreference) {
    case PaymentPreference.LICENSE_PACKAGE:
      return 'Führerscheinpaket kaufen';
    case PaymentPreference.PAY_ON_INSTALLMENTS:
      return 'Auf Raten bezahlen';
    case PaymentPreference.CREDIT_CARD:
      return 'Kreditkarte';
    case PaymentPreference.CASH:
      return 'Bar';
    default:
      return '';
  }
}
