export enum WorkStatus {
  PUPIL = 'PUPIL',
  STUDENT = 'STUDENT',
  APPRENTICE = 'APPRENTICE',
  EMPLOYED = 'EMPLOYED',
  SELFEMPLOYED = 'SELFEMPLOYED',
  OTHER = 'OTHER'
}

export function getWorkDisplayName(status: WorkStatus): string {
  switch (status) {
    case WorkStatus.PUPIL:
      return 'Schule';
    case WorkStatus.STUDENT:
      return 'Studium';
    case WorkStatus.APPRENTICE:
      return 'Ausbildung';
    case WorkStatus.EMPLOYED:
      return 'Angestellt';
    case WorkStatus.SELFEMPLOYED:
      return 'Selbständig';
    case WorkStatus.OTHER:
      return 'Sonstiges';
    default:
      return '';
  }
}

export function getAllWorkStatuses(): WorkStatus[] {
  return [WorkStatus.PUPIL, WorkStatus.STUDENT, WorkStatus.APPRENTICE, WorkStatus.EMPLOYED, WorkStatus.SELFEMPLOYED, WorkStatus.OTHER];
}
