import { LearnerDocumentType } from './learner-document-type.model';

export class LearnerDocument {
  id: number;
  createdAt?: Date;
  licenseId?: number;
  learnerLicenseId?: number;
  schoolLicensePricingId?: number;
  fileType: LearnerDocumentType;
  displayName: string;
  icon: string;
  fileName: string;
  description?: string;
  button?;
  enabled?: boolean;
  noUpload?: boolean;

  constructor(json) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }
    if (json.licenseId) {
      this.licenseId = +json.licenseId;
    }
    if (json.learnerLicenseId) {
      this.learnerLicenseId = +json.learnerLicenseId;
    }
    if (json.schoolLicensePricingId) {
      this.schoolLicensePricingId = +json.schoolLicensePricingId;
    }
    this.fileType = json.fileType;
    this.displayName = json.displayName;
    this.icon = json.icon;
    this.fileName = json.fileName;
    if (json.description) {
      this.description = json.description;
    }
    if (json.enabled) {
      this.enabled = json.enabled;
    } else {
      this.enabled = false;
    }
    if (json.noUpload) {
      this.noUpload = json.noUpload;
    } else {
      this.noUpload = false;
    }
    if (json.button && json.button !== null) {
      this.button = json.button;
      if (this.button.icon === undefined) {
        this.button.icon = false;
      }
    } else {
      this.button = null;
    }
  }

  isUploaded(): boolean {
    return !!this.id;
  }
}
