<div class="lp-background">
  <img src="/assets/header-tc.png"/>
  <div class="container">
    <div class="row no-side-space">
      <div class="col-12 col-lg-10 offset-lg-1">
        <h1 class="lp-title">drivEddy App - AGB für Fahrschüler
        </h1>

        <div class="updated-date">Aktualisiert am 20.12.2021</div>

        <h5 class="lp-section-title">1. Geltungsbereich und Anwendungsbereich</h5>
        <div class="tc-section">
          <strong>1.1</strong> Die DrivEddy GmbH, Monbijouplatz 4, 10178 Berlin (im Folgenden
          <strong>„DrivEddy“</strong>) vermittelt und administriert über ihre Plattform auf der Internetseite
          www.driveddy.de sowie über ihre mobile
          (internetbasierte) Applikation (zusammen im Folgenden <strong>„Plattform“</strong>) als sog.
          SaaS-Dienstleistung (Nutzung der
          Plattform) über das Medium Internet Fahrschule und Fahrschüler, und bietet zudem eine Fahrschulsuchmaschine an
          (zusammen im Folgenden <strong>„Service“</strong>). Diese Allgemeinen Geschäftsbedingungen (im Folgenden
          <strong>„AGB“</strong>) gelten für die
          Nutzung der Plattform durch die Nutzer in ihrer Eigenschaft als Fahrschüler (im Folgenden <strong>„Fahrschüler“</strong>).
          Nutzer in ihrer Eigenschaft als Anbieter von Fahrschulleistungen (im Folgenden <strong>„Fahrlehrer“</strong>)
          und/oder in ihrer
          Eigenschaft als Betreiber einer Fahrschule (im Folgenden <strong>„Fahrschule“</strong>) – soweit im Folgenden
          keine
          Differenzierung zwischen Fahrlehrer und Fahrschule notwendig ist, werden diese gemeinsam als <strong>„Anbieter“</strong>
          beschrieben – gehen das Vertragsverhältnis mit DrivEddy über separate AGB ein. Sofern im Folgenden
          undifferenziert von <strong>Nutzern</strong> die Rede ist, beschreibt dieser Begriff die Gesamtheit von
          Anbietern und
          Fahrschüler.
          <br/><br/>
          <strong>1.2</strong> Die jeweils aktuellen AGB sind auf der Webseite der Plattform unter
          https://www.driveddy.com/agb-und-datenschutz abrufbar. DrivEddy ist jederzeit berechtigt, diese AGB zu ändern
          oder zu
          ergänzen, es sei denn, dass dies für die Fahrschüler nicht zumutbar ist. Hierzu benachrichtigt DrivEddy die
          Fahrschüler rechtzeitig über die Änderungen (schriftlich oder per E-Mail) und veröffentlicht diese auf der
          Plattform. Fehlt es an einem Widerspruch des Fahrschülers bezüglich der Änderungen der AGB, der innerhalb von
          sechs (6) Wochen nach der Benachrichtigung erfolgen muss, gelten die geänderten AGB als vom jeweiligen
          Fahrschüler angenommen. In der Benachrichtigung wird der Fahrschüler auf sein Widerspruchsrecht und die
          Bedeutung der Widerspruchsfrist ausdrücklich hingewiesen. Im Falle des Widerspruchs des Fahrschülers gegen die
          Änderung oder Ergänzung der AGB ist DrivEddy berechtigt, den Nutzungsvertrag-Plattform auf Grundlage dieser
          AGB gegenüber dem jeweiligen Fahrschüler mit einer Frist von vier (4) Wochen zu kündigen.
          <br/><br/>
          <strong>1.3</strong> Die Nutzung der Plattform erfolgt ausschließlich auf der Grundlage dieser AGB, soweit
          zwischen DrivEddy und dem Fahrschüler im Einzelfall nicht ausdrücklich und schriftlich eine andere
          Vereinbarung getroffen wurde. Entgegenstehende, ergänzende oder abweichende Bedingungen des Fahrschülers
          werden nicht Vertragsbestandteil, es sei denn, dies wird zwischen den Parteien ausdrücklich schriftlich
          vereinbart.
          <br/><br/>
          <strong>1.4</strong> Diese AGB können als PDF-Datei über die Browserfunktion angezeigt und abgespeichert
          werden. Die Fahrschüler sind auch zum Ausdruck berechtigt. Der Fahrschüler kann die AGB jederzeit auf der
          Internetseite von DrivEddy abrufen, ausdrucken sowie speichern.
          <br/><br/>
          <strong>1.5</strong> Nicht Gegenstand dieser AGB ist die Erbringung von Fahrschulleistungen.
        </div>


        <h5 class="lp-section-title">2. Vertragsgestand und Nutzungsrechtseinräumung</h5>
        <div class="tc-section">
          <strong>2.1</strong> Vertragsgegenstand dieser AGB ist die Nutzungseinräumung des Service von DrivEddy für
          seine Nutzer (Anbieter und Fahrschüler) auf der Plattform. Es ist zu beachten, dass ggf. einzelne Leistungen
          des Services ggf. nur auf der Webseite Browserbasiert oder auf der mobilen Applikation verfügbar sein kann.
          Hierauf wird auf der Plattform einzeln hingewiesen.
          <br/><br/>
          <strong>2.2</strong> Auf der Plattform, dessen Nutzung diesen AGB als Nutzungsvertrag unterliegt,
		  gibt DrivEddy seinen Nutzern mittels Zugang auf die Plattform eine Übersicht über die verschiedenen
		  Leistungsbereiche der Plattform. DrivEddy tritt als bloßer Betreiber der Plattform auf und ermöglicht es
		  den Anbietern in Bezug auf Fahrschulleistungen eigene Angebote zu veröffentlichen und ermöglicht es den
		  Fahrschülern diese Angebote einzusehen und gegebenenfalls Kontakt mit dem jeweiligen Anbieter aufzunehmen.
		  Darüber hinaus stellt DrivEddy verschiedene Funktionalitäten zur Verfügung, die die bessere Zusammenarbeit
		  zwischen Anbieter und Fahrschüler ermöglichen soll. DrivEddy wird selbst aber nicht Partei eines
		  Vertrags zur Erbringung von Fahrschulleistungen. DrivEddy betreibt darüber Hinaus eine Fahrschule
		  (Eddy Bildung GmbH), die eigene Fahrschulleistungen erbringen kann. DrivEddy bietet parallel hierzu
		  auch Online-Theorie Inhalte für die Fahrschüler zur Theorieausbildung als Plattform an, welche von den
		  jeweiligen Fahrschulen der Fahrschüler zur Darstellung auf der Plattform freigegeben worden sind
		  (im Folgenden „<strong>Online-Theorie</strong>“). Dem Fahrschüler wird hier folgender Hinweis hierzu erteilt.
		  DrivEddy kann nicht gewährleisten oder garantieren, dass die Online-Theorie Inhalte im Rahmen der
		  jeweiligen Fahrschulausbildung von staatlicher Seite oder von Seiten der Fahrschule anerkannt wird.
		  Dies muss der Fahrschüler vorab mit der jeweiligen Fahrschule klären. DrivEddy stellt hierzu eine
		  Plattform und Software für Fahrschulen, die ihre eigenen Inhalte live und aufgezeichnet darstellen können.
		  Die Eddy Bildung GmbH besitzt darüber hinaus eine rechtlich anerkannte Ausnahmegenehmigung. Der Aktuelle Stand bzw. die Gültigkeit der 
      Online-Theorie Genehmigung kann unter <a href="mailto:prenzlauerberg@eddy.club">prenzlauerberg@eddy.club</a> angefragt werden.
      Fahrschüler, die über diese Fahrschule ihren Theorie Unterricht online absolvieren,
		  erhalten folglich auch eine gesetzlich anerkannte Ausnahmegenehmigung, die 2 Jahre lang gültig ist.
		  Weiter stellt DrivEddy den Nutzern die Plattform nur zur Nutzung zur Verfügung und gestattet den Nutzer
		  hierauf zuzugreifen. Weder Quellcode noch Objektcode der Software werden dem Nutzer überlassen.
		  Der Nutzer erhält lediglich die technische Möglichkeit und Berechtigung, auf die Software, welche
		  auf einem zentralen Server gehostet ist, mittels Internet zu zugreifen und zu nutzen.
		  Um diese Plattform nutzen zu können müssen sich alle Nutzer wie folgt auf Grundlage dieser AGB registrieren.

          <br/><br/>

          <strong>2.3</strong> DrivEddy räumt dem Nutzer das nicht ausschließliche und nicht übertragbare Recht ein, den
          in dieser Allgemeinen Geschäftsbedingung bezeichneten Service während der Dauer des
          Nutzungsvertrages-Plattform im Rahmen der Nutzung der Dienstleistungen der Software bestimmungsgemäß zu
          nutzen. Der Nutzer darf die Software nur bearbeiten, soweit dies durch die bestimmungsgemäße Benutzung der
          Software laut jeweils aktueller Leistungsbeschreibung abgedeckt ist. Der Nutzer darf die Software nur
          vervielfältigen, soweit dies durch die bestimmungsgemäße Benutzung der Software laut jeweils aktueller
          Leistungsbeschreibung abgedeckt ist. Zur notwendigen Vervielfältigung zählt das Laden der Software in den
          Arbeitsspeicher auf dem Server von DrivEddy, nicht jedoch die auch nur vorübergehende Installation oder das
          Speichern der Software auf Datenträgern (wie etwa Festplatten oÄ) der vom Nutzer eingesetzten Hardware. Der
          Nutzer ist nicht berechtigt, die Software Dritten entgeltlich oder unentgeltlich zur Nutzung zur Verfügung zu
          stellen. Eine Weitervermietung der Software wird dem Nutzer somit ausdrücklich nicht gestattet.
        </div>


        <h5 class="lp-section-title">3. Registrierung, Benutzerkonto und Fahrschülerdaten</h5>
        <div class="tc-section">
          <strong>3.1</strong> Damit der Fahrschüler die Plattform nutzen kann, muss er sich auf Grundlage dieser AGB
          registrieren. Hierzu stellt der Anbieter dem Fahrschüler einen sog. Registrierungslink per E-Mail zur
          Verfügung. Über den Online-Registrierungsprozess kann sich der Fahrschüler auf der Plattform wie folgt
          anmelden. Die Absendung der Online-Registrierung (durch das klicken auf den Button „verbindlich Anmelden“
          (<strong>Button-Klick</strong>)) als Fahrschüler über die Plattform stellt eine rechtsverbindliche Offerte zum
          Vertragsschluss
          gegenüber DrivEddy auf Grundlage dieser AGB dar. Der Fahrschüler wird per E-Mail über den Eingang seiner
          Registrierungsanfrage informiert. Die E-Mail enthält einen Aktivierungscode. Der Fahrschüler muss seine
          Registrierung durch eingeben des Aktivierungscodes bestätigen. Hierdurch kommt jedoch noch kein Vertrag mit
          DrivEddy zu Stande. Ein Vertrag mit DrivEddy kommt erst dann zu Stande, wenn DrivEddy dem Fahrschüler in einer
          Bestätigungsmail den Vertragsschluss bestätigt oder das Benutzerkonto durch DrivEddy freigeschaltet wird (im
          Folgenden <strong>„Nutzungsvertrag-Plattform“</strong>). Es obliegt jedoch DrivEddy in ihrem eigenen Ermessen
          zu entscheiden,
          ob der jeweilige Fahrschüler freigeschaltet wird bzw. ob ein Nutzungsvertrag-Plattform mit diesem
          abgeschlossen wird. DrivEddy richtet für den Fahrschüler im Falle einer erfolgreichen Registrierung ein
          individuelles und persönliches Benutzerkonto ein. Der Fahrschüler erhält als Benutzernamen seinen in der
          Plattform eingetragenen Namen und ein individuelles Passwort um sich anzumelden. Das Passwort ist geheim zu
          halten und der Zugang zu dem Benutzerkonto sorgfältig zu sichern. Bis zum verbindlichen Abschluss der
          Registrierung (siehe oben, Button-Klick) kann der Fahrschüler seine Eingaben im elektronischen Anmeldeprozess
          über die üblichen Eingabegeräte korrigieren. Der Fahrschüler kann mögliche Eingabefehler durch aufmerksames
          Lesen der auf dem Bildschirm dargestellten Informationen erkennen. Ggfs. ist die Veränderung der
          Darstellungsgröße des Browsers hilfreich für die bessere Erkennbarkeit von Eingabefehlern.
          <br/><br/>
          <strong>3.2</strong> Der Nutzungsvertrag-Plattform regelt die Rechte und Pflichten der Anbieter in Bezug auf
          die Nutzung der Plattform. Eine Registrierung als Anbieter ist nur unbeschränkt geschäftsfähigen natürlichen
          Personen und in Bezug auf Fahrschulen auch in der Form als juristische Person erlaubt. Der Fahrschüler ist
          ohne ausdrückliche Zustimmung von DrivEddy nicht berechtigt, sein Benutzerkonto auf eine dritte Person zu
          übertragen. Der Fahrschüler ist ohne ausdrückliche Zustimmung von DrivEddy nicht berechtigt, Dritten den
          Zugang zu seinem Benutzerkonto unter Nutzung seiner Login-Daten zu ermöglichen, außer es handelt sich um die
          gesetzlichen Vertreter (Eltern). Der Fahrschüler ist verpflichtet, DrivEddy umgehend zu informieren, wenn es
          Anhaltspunkte dafür gibt, dass sein Benutzerkonto von Dritten missbraucht wurde. Kommt der Fahrschüler dieser
          Verpflichtung nicht nach, haftet er für sämtliche Aktivitäten, die unter Verwendung seines Benutzerkontos
          stattfinden.
          <br/><br/>
          <strong>3.3</strong> Der Anbieter wird hiermit ausdrücklich insbesondere darauf hingewiesen, dass…
          <ol class="alphabetical">
            <li>…das vermittelte Fahrausbildungsverhältnis direkt zwischen dem jeweiligen Anbieter und dem Fahrschüler
              zustande kommt und keinerlei vertragliche Verflechtungen von Seiten DrivEddy’s in Bezug auf das
              vermittelte Fahrausbildungsverhältnis existieren.
            </li>
          </ol>
          <br/><br/>
          <strong>3.4</strong> Der Fahrschüler sichert bei der Registrierung auf der Plattform gegenüber DrivEddy
          ausdrücklich zu und garantiert im Sinne eines selbständigen Garantieversprechens, …
          <ol class="alphabetical">
            <li>… dass alle angegebenen Daten (insbesondere aber nicht abschließend: Name, Vorname, Geschlecht,
              Geburtsdatum, ob bereits ein Führerschein vorhanden ist (wenn ja, welcher vorhanden ist), welchen
              Führerschein der Fahrschüler machen möchte, Wohnanschrift (Straße, Hausnummer, Postleitzahl, Ort),
              Telefonnummer, E-Mail-Adresse) im Wege des Registrierungsprozesses wahr und vollständig sind.
            </li>
            <li>… dass er (i) eine natürlichen Personen ist, (ii) im Zeitpunkt der Registrierung mindestens vierzehn
              (14) Jahre alt ist und (iii) für den Fall, dass er minderjährig ist über eine gültige Zustimmung seines
              gesetzlichen Vertreters für die Registrierung auf dieser Plattform besitzt.
            </li>
            <li>… dass er eigenverantwortlich Änderungen bzw. Ergänzungen seiner Daten (insbesondere E-Mail-Adresse,
              Telefonnummer, Bankdaten, Kontaktdaten) und Angaben hierzu unverzüglich DrivEddy per Änderung im Account
              der Plattform mitteilt.
            </li>
            <li>… dass er eine Weitergabe der persönlichen Anmeldedaten (Benutzername und Passwort) an Dritte
              verhindern. Der Anbieter haftet für die verursachten Schäden, die DrivEddy durch den Verlust oder die
              Weitergabe der Anmeldedaten entstanden ist.
            </li>
          </ol>
          <strong>3.5</strong> Die Verfahren dieser AGB haben insbesondere aber nicht abschließend das Ziel, dass die
          Informationen über den Fahrschüler stets aktuell sind. Sollte daher durch Änderungen an den
          Benutzerkonto-Details des Fahrschülers eine Aktualisierung der Daten auf der Plattform notwendig werden, ist
          der Fahrschüler verpflichtet, diese anzuzeigen und/oder selber auf der Plattform zu aktualisieren. Die Pflicht
          besteht auch dann, wenn eine Datenänderung in ein öffentliches Register eingetragen wird. Der Fahrschüler ist
          verpflichtet, DrivEddy jederzeit auf Nachfrage Nachweise für die Richtigkeit und Aktualität seiner Daten
          vorzulegen. DrivEddy behält es sich vor, jederzeit das Vorliegen entsprechender Nachweise zu überprüfen.
          DrivEddy behält sich das Recht vor, jederzeit und ohne vorhergehender Mahnung aus ihrem freiem Ermessen das
          Profil des Fahrschülers zu entfernen oder den Zugriff auf dieses zu sperren, um aus ihrer Sicht unzulässige,
          nicht aktuelle, diesen AGB widersprechen oder schädliche Profile der Fahrschüler zu verhindern, wobei DrivEddy
          nicht für Konsequenzen haftet, die sich aus dem erfolglosem Versuchs, ein solches Profil zu entfernen,
          ergeben.
          <br/><br/>
          <strong>3.6</strong> Es besteht keine Verpflichtung von DrivEddy die Plattform für alle Endgeräte oder zu
          jeder Zeit zur Verfügung zu stellen. DrivEddy darf jedoch dem Fahrschüler Nachrichten senden, um diesen über
          Neuheiten und Weiterentwicklungen zu informieren. DrivEddy behält sich das Recht vor, die Plattform –
          insbesondere die Software – technisch und inhaltlich zu ändern. DrivEddy und ihre Partner können
          servicerelevante Informationen per Plattform um Zusatzinformationen oder Werbung z.B. von Sponsoren ergänzen.
          Mitteilungen über Marketingaktivitäten von DrivEddy (im Folgenden <strong>„Newsletter“</strong>) erhält der
          Fahrschüler nur,
          wenn er sich mit dem Empfang des Newsletters einverstanden erklärt hat. Der Fahrschüler kann den Bezug des
          Newsletters jederzeit dadurch beenden, dass er im Newsletter auf das Feld „Newsletter abbestellen“ klickt. In
          diesem Fall wird die E-Mail-Adresse des Fahrschülers aus dem Newsletter- Verteiler gelöscht und das Fehlen der
          Einwilligung im Nutzerbereich vermerkt.
          <br/><br/>
          <strong>3.7</strong> Der Fahrschüler räumt DrivEddy zum Zwecke der Veröffentlichung seines Benutzerkontos und
          zum Zwecke der Teilnahme an der Plattform und zum Zwecke des Bewerbens der Plattform ein einfaches,
          unentgeltliches räumlich, zeitlich und inhaltlich unbeschränktes, unwiderrufliches und auf Dritte
          übertragbares und unterlizenzierbares Nutzungsrecht an den eingestellten Inhalten ein. DrivEddy ist jederzeit
          berechtigt, die Inhalte insoweit zu vervielfältigen, zu bearbeiten und zu verwerten. Dies schließt
          insbesondere das Vervielfältigungsrecht, das Verbreitungsrecht und das Recht der öffentlichen Wiedergabe,
          insbesondere das Recht der öffentlichen Zugänglichmachung, mit ein. Der Fahrschüler verzichtet auf das Recht
          zur Urhebernennung. DrivEddy ist es insbesondere gestattet, das Benutzerkonto, einschließlich der enthaltenen
          Inhalte, ganz oder teilweise zu Werbezwecken zu nutzen, um für die Plattform oder die Nutzung der Plattform zu
          bewerben. Sofern DrivEddy selber Inhalte (insbesondere Texte und Fotografien) für das Benutzerkonto des
          Fahrschülers anfertigt oder über einen Dritten anfertigen lässt, stehen die Rechte an diesen Inhalten
          ausschließlich DrivEddy zu. Durch die Veröffentlichung dieser Inhalte auf dem Profil der Fahrschüler entstehen
          für den jeweiligen Fahrschüler keinerlei selbständige Nutzungsrechte an diesen Inhalten. Insbesondere darf der
          Fahrschüler diese Inhalte außerhalb der Plattform nicht benutzen oder diese Inhalte lokal auf seinem Computer
          speichern.
          <br/><br/>
          <strong>3.8</strong> Bei unsachgemäßer Nutzung und nutzungswidrigen Verhaltens ist DrivEddy jederzeit
          berechtigt, den jeweiligen Nutzungsvertrag-Plattform gegenüber dem Fahrschüler unverzüglich zu beenden und ihm
          die weitere Benutzung der Plattform (Nutzungsvertrag-Plattform) zu untersagen. Ein Fall der besonders
          rechtswidrigen Nutzung liegt insbesondere aber nicht abschließend immer dann vor, wenn der Anbieter die
          Plattform in derart benutzt, dass er damit diffamierend, obszön, ruchlos, pornografisch, eindeutig sexuell, in
          Förderung von Gewalt oder Herabsetzung einer Gruppe aufgrund von Rasse oder ethnischer Herkunft, Religion,
          Behinderung, Geschlecht, Alter, Veteranenstatus und / oder sexueller Orientierung / Geschlechtsidentität
          handelt, oder Minderjährige ausbeutet bzw. schädigt (besonders rechtswidrige Nutzung).
        </div>

        <h5 class="lp-section-title">4. Buchen und Verwalten von Fahrschulleistungen über die Plattform</h5>
        <div class="tc-section">
          <strong>4.1</strong> Neben den sonstigen Leistungen des Service von DrivEddy auf der Plattform auf Grundlage
          dieser AGB geht es um das Buchen und Verwalten von Fahrschulleistungen, wie nachfolgend geregelt.
          <br/><br/>
          <strong>4.2</strong> Nach der Vermittlung eines passenden Anbieters für den Fahrschüler wird unter Anwesenheit
          der Vertragsparteien des Fahrschulausbildungsvertrags bei dem Anbieter vor Ort für die Erbringung von
          Fahrschulleistungen ein Ausbildungsvertrag unterzeichnet, nachdem der Fahrschüler entsprechend dem FahrlG
          aufgeklärt wurde (im Folgenden <strong>„Ausbildungsvertrag“</strong>). Der Ausbildungsvertrag kann
          weiterführende oder gänzlich
          andere Bedingungen enthalten und liegt im ausschließlichen Verantwortungsbereich des Anbieters.
          <br/><br/>
          <strong>4.3</strong> Erst nach Abschluss des Ausbildungsvertrags können Fahrschüler über den Buchungsprozess
          auf der Plattform weitere Fahrschulleistungen mit dem jeweiligen Anbieter buchen (im Folgenden <strong>„Buchung“</strong>).
          Die
          Buchung einer praktischen Fahrstunde ist nach Unterschrift des Ausbildungsvertrages sowie nach erfolgreicher
          Absolvierung des Sehtests erlaubt bzw. möglich. Die entsprechende Funktion innerhalb der Plattform wird
          freigeschaltet, sobald die entsprechenden Dokumente durch den jeweiligen Anbieter am entsprechenden
          Fahrschülerprofil bzw. Benutzerkonto des Fahrschülers gespeichert worden sind. Der Fahrschüler wählt auf der
          Plattform ein Produkt des Anbieters aus und kann den Erwerb der Buchung über DrivEddy anfragen. DrivEddy wird
          diese Anfrage dann kurzfristig prüfen. DrivEddy vermittelt hier den Vertragsschluss zwischen dem Anbieter und
          dem Fahrschüler. Das Vertragsverhältnis in Bezug auf die Buchung kommt ausschließlich zwischen dem jeweiligen
          Fahrschüler und dem jeweiligen Anbieter zustande. Welche Produkte entsprechend seiner Kriterien verfügbar
          sind, kann der jeweilige Fahrschüler auf der Plattform erkennen. Die Anzeigeangebote auf der Plattform stellen
          kein verbindliches Angebot auf den Abschluss eines Vertrags über die Erbringung von Fahrschulleistungen dar.
          Vielmehr handelt es sich um eine unverbindliche Aufforderung, Angebote durch den Fahrschüler an den Anbieter
          zu richten (sog. invitatio ad offerendum). Dem Fahrschüler ist bewusst, dass für die Inanspruchnahme der durch
          von den Anbietern angebotenen Leistungen in der Regel Kosten entstehen. Die jeweiligen Preise der
          Fahrschulleistungen werden von dem jeweiligen unabhängigen Anbieter festgelegt und sind im Vorfeld per
          Ausbildungsvertrag verbindlich vereinbart. Die auf der Plattform genannten Preise enthalten die gesetzliche
          Steuer und sonstige Preisbestandteile, die bei dem Anbieter erhoben werden. Gibt der Fahrschüler eine
          verbindliche Buchungsanfrage ab (über den Button der Plattform <i>„zahlungspflichtig buchen“</i>
          (<strong>Button-Klick-Buchung</strong>)), wird DrivEddy dem Fahrschüler den Eingang der Anfrage per Plattform
          und E-Mail
          bestätigen (im Folgenden <strong>„Bestellbestätigung“</strong>) und den Anbieter über die Anfrage informieren
          (im Folgenden
          <strong>„Anfrage“</strong>). Dazu wird dem Fahrlehrer der Benutzername, Vor- und Nachname und die Rufnummer
          des Fahrschülers in
          der Anfrage übermittelt. Die Bestellbestätigung begründet noch keine rechtsverbindliche Buchung. Eine
          rechtsverbindliche Buchung kommt erst zustande, wenn der Anbieter die Anfrage des Fahrschülers annimmt,
          spätestens aber mit dem Beginn der Leistungserbringung. Minderjährige Personen bedürfen für Buchungen der
          Zustimmung ihres gesetzlichen Vertreters. Bis zum verbindlichen Abschluss der Buchung (siehe oben,
          Button-Klick-Buchung) kann der Fahrschüler seine Eingaben im elektronischen Buchungsprozess über die üblichen
          Eingabegeräte korrigieren. Der Fahrschüler kann mögliche Eingabefehler durch aufmerksames Lesen der auf dem
          Bildschirm dargestellten Informationen erkennen. Ggfs. ist die Veränderung der Darstellungsgröße des Browsers
          hilfreich für die bessere Erkennbarkeit von Eingabefehlern.
          <br/><br/>
          <strong>4.4</strong> Sofern die Fahrschulleistungen theoretische Fahrstunden betrifft, ist die Buchung erst
          nach Zahlung des Grundbetrags gemäß des Preisaushangs bzw. Ausbildungsvertrags des jeweiligen Anbieters durch
          den Fahrschüler erlaubt bzw. möglich. Die entsprechende Funktion innerhalb der App wird freigeschaltet, sobald
          die entsprechenden Dokumente von dem Anbieter am entsprechenden Fahrschülerprofil bzw. Benutzerkonto
          gespeichert worden sind.
          <br/><br/>
          <strong>4.5</strong> DrivEddy wird die vermittelten Leistungen im Namen und der Rechnung des Anbieters
          gegenüber dem Fahrschüler in Rechnung stellen.
          <br/><br/>
          <strong>4.6</strong> Die maximale Anzahl der Buchung von praktischen Fahrstunden als Produkt pro Fahrschüler
          pro Anbieter beträgt 2 x 75 Minuten pro Tag und maximal 4 x 75 Minuten pro Woche. Die maximale Anzahl der
          Gesamtbuchungen (bei allen Anbietern der Plattform) von praktischen Fahrstunden als Produkt pro Fahrschüler
          pro Woche beträgt 12 x 75 Minuten. Der Fahrschüler darf pro Tag maximal 180 Minuten Theoriefahrstunde (2
          Doppel-Theorieblöcke) buchen.
          <br/><br/>
          <strong>4.7</strong> Die per Buchung verbindlich vereinbarten Leistungen sind entgeltlich. Das Entgelt und die
          Zahlungsmodalität sind in Ziffer 5 dieser AGB geregelt.
          <br/><br/>
          <strong>4.8</strong> Der Fahrschüler kann verbindliche Buchungen gemäß den Bedingungen des Ausbildungsvertrags
          (in Relation zum Anbieter) entsprechend stornieren. Ein Stornierungsentgelt wird ggf. gem. Ziff. 5 dieser AGB
          in diesem Fall fällig.
        </div>


        <h5 class="lp-section-title">5. Entgelt, Abrechnung und Zahlung</h5>
        <div class="tc-section">
          <strong>5.1</strong> Die Nutzung der Plattform an sich erfolgt für den Fahrschüler grundsätzlich
          unentgeltlich, sofern nachfolgend nichts Abweichendes geregelt ist.
          <br/><br/>
          <strong>5.2</strong> Für den Fall der Stornierung einer verbindlich gebuchten Fahrstunde (Buchung) durch den
          Fahrschüler ist DrivEddy berechtigt, in jedem Fall eine Aufwandsentschädigung in Höhe von EUR 2,50 von dem
          Fahrschüler zu verlangen und in Rechnung zu stellen. Dem Fahrschüler bleibt der Nachweis vorbehalten, ein
          Schaden sei nicht oder in wesentlich geringerer Höhe entstanden. Ein etwaiges darüber hinausgehendes
          Stornierungs-Entgelt gegenüber dem Anbieter ergibt sich aus dem jeweiligen Ausbildungsvertrag zwischen
          Anbieter und Fahrschüler.
          <br/><br/>
          <strong>5.3</strong> Im Fall einer verbindlichen Buchung gem. Ziffer 4.1 bis Ziffer 4.8 (insbesondere Ziffer
          4.7) dieser AGB entsteht ein Entgelt entsprechend der Buchung zu Gunsten des Anbieters, wie im Buchungsprozess
          ausgewiesen. Etwaige Provisionen, die auf Grund der Buchung entstehen trägt ausschließlich der Anbieter. Im
          Fall einer verbindlichen Buchung gem. Ziffer 4.9 dieser AGB in Bezug auf Online-Theorie entsteht ein Entgelt
          entsprechend der Buchung (in Höhe von EUR 99,00 (Brutto)) zu Gunsten von DrivEddy, wie im Buchungsprozess
          ausgewiesen.
          <br/><br/>
          <strong>5.4</strong> Die Entgelte gemäß Ziffer 5 sind alles Brutto-Beträge (sprich inklusive der gesetzlichen
          Umsatzsteuer). Im Falle einer Buchung des Fahrschülers über die Plattform zahlt der Fahrschüler an den
          Online-Payment-Dienstleister (sofortige Fälligkeit), sofern diese Zahlungsmodalität vom Fahrschüler gewählt
          worden ist, das Buchungs-Entgelt (mit Ausnahme der Online-Theorie) an diesen Dienstleister und erst nach
          erfolgreichem Abschluss des gebuchten Produkts zahlt dieser Dienstleister das Buchungs-Entgelt nach
          Provisionsabzug gem. Ziffer 5 dieser AGB an den Anbieter aus. Als erfolgreicher Abschluss gilt es, wenn der
          Leistungsempfang durch den Fahrschüler in der App bestätigt wurde und der Anbieter die Umsetzung des Produkts
          Online bewertet hat. Im Fall der Online-Theorie zahlt der Dienstleister das Entgelt für die Online-Theorie
          direkt an DrivEddy aus. Im Fall der Barzahlung erfolgt die Zahlung unmittelbar an den Anbieter vor der
          Umsetzung des gebuchten Produkts. In diesem Fall erfolgt der Versand der Einzelrechnung an den Anbieter am
          Folgetag, sofern der Anbieter den Erhalt des Geldes bestätigt hat. Die verschiedenen Zahlungsmodalitäten
          ergeben sich aus dem Buchungsprozess und den FAQ der Plattform. Sofern möglich und gewählt ist DrivEddy
          berechtigt, die im Rahmen der Buchung angegebene Kreditkarte oder sonstige durch den Fahrschüler angegebenen
          Zahlungsmethoden zu verwenden. DrivEddy weist den Fahrschüler hiermit darauf hin, dass er bevollmächtigt ist,
          im Namen und für Rechnung des Anbieters Rechnungen zu stellen und Zahlungen abwickeln zu lassen. Der
          Fahrschüler kann Fragen zu der jeweiligen Rechnung unter folgender Adresse stellen und/oder Einwände aufgrund
          der jeweiligen Rechnung erheben: https://calendly.com/driveddy/fahrstunden-ruckerstattung?month=2020-02.
          <br/><br/>
          <strong>5.5</strong> Der Anbieter erhält nach jeder Buchung einen elektronischen Beleg. Einsprüche gegen die
          in Rechnung gestellten Vermittlungsgebühren müssen vom Anbieter innerhalb von sechs (6) Wochen nach Erhalt der
          Rechnung geltend gemacht werden. Das Unterlassen gilt jeweils als Genehmigung der Rechnung. Der Anbieter kann
          auch nach Fristablauf eine Berichtigung der Rechnung verlangen, ist dann aber für die Unrichtigkeit der
          Rechnung beweispflichtig.
        </div>


        <h5 class="lp-section-title">6. Allgemeine Pflichten des Fahrschülers</h5>
        <div class="tc-section">
          <strong>6.1</strong> Der Fahrschüler verpflichtet sich, bei der Nutzung der Plattform und der Veröffentlichung
          von Inhalten auf der Plattform geltendes Recht (z. B. Wettbewerbs- und Strafrecht, E-Commerce-Vorschriften,
          Fernabsatzrecht, gesetzliche Informationspflichten) zu beachten und keine Rechte Dritter (z.B. Marken-,
          Namens-, Urheber- oder Datenschutzrechte) zu verletzen. Der Fahrschüler stellt insbesondere sicher, dass einer
          Veröffentlichung von Bildern und Inhalten auf der Plattform keine Rechte Dritter entgegenstehen.
          <br/><br/>
          <strong>6.2</strong> Der Fahrschüler verpflichtet sich, DrivEddy unverzüglich über Vertragsschlüsse zu
          Anbietern in Kenntnis zu setzen, wenn der zugrundeliegende Kontakt über die Plattform vermittelt wurde. Ein
          Kontakt gilt als über die Plattform vermittelt, wenn der Erstkontakt zwischen Fahrschüler und Anbieter über
          die Plattform erfolgt oder der Fahrschüler auf den Anbieter über die Plattform aufmerksam wurde.
          <br/><br/>
          <strong>6.3</strong> Der Fahrschüler muss jedwede Tätigkeit unterlassen, die geeignet ist, den Betrieb der
          Plattform oder der dahinterstehenden technischen Infrastruktur zu beeinträchtigen und/oder übermäßig zu
          belasten. Dazu zählen insbesondere aber nicht abschließend:
          <ol class="alphabetical">
            <li>die Verwendung von Software, Skripten oder Datenbanken in Verbindung mit der Nutzung der Plattform;</li>
            <li>
              das automatische Auslesen, Blockieren, Überschreiben, Modifizieren, Kopieren von Daten und/oder sonstigen
              Inhalten, soweit dies nicht für die ordnungsgemäße Nutzung der Plattform erforderlich ist;
            </li>
            <li>
              die Verbreitung und/oder öffentliche Wiedergabe von jedweden Inhalten der Plattform ohne Einwilligung von
              DrivEddy.
            </li>
          </ol>
          <strong>6.4</strong> Fahrschüler dürfen Angebote auf der Plattform ausschließlich mittels der von DrivEddy
          angebotenen Suchmasken suchen. Nicht statthaft ist die Angebotssuche unter Umgehung der Suchmasken,
          insbesondere durch Verwendung von automatisierter Suchsoftware, die auf die Datenbanken von DrivEddy zugreift.
          <br/><br/>
          <strong>6.5</strong> Der Fahrschüler darf die durch Abfragen und Nutzung der Plattform gewonnenen oder auf der
          Plattform zugänglich gemachten Daten und Inhalte weder vollständig, noch teilweise oder auszugsweise verwenden
          <ol class="alphabetical">
            <li>zum Aufbau einer eigenen Datenbank in jeder medialen Form und/oder</li>
            <li>für eine gewerbliche Datenverwertung oder Auskunftserteilung und/oder</li>
            <li>für eine sonstige gewerbliche Verwertung.</li>
          </ol>
          Dies gilt nicht, soweit es sich um die eigenen Daten des Fahrschülers handelt.
          <br/><br/>
          <strong>6.6</strong> Der Fahrschüler darf über die Plattform keine Nachrichten werbenden Inhalte ohne eine
          Einwilligung des Empfängers versenden (Spam-Nachrichten).
          <br/><br/>
          <strong>6.7</strong> Sollte es bei der Nutzung der Plattform oder seiner Funktionalitäten zu Störungen kommen,
          wird der Fahrschüler DrivEddy von diesen Störungen unverzüglich in Kenntnis setzen. Gleiches gilt, wenn der
          Fahrschüler Kenntnis über von Dritten veröffentlichte Angebote oder Inhalte erlangt, die offensichtlich gegen
          geltendes Recht oder Rechte Dritter verstoßen.
        </div>

        <h5 class="lp-section-title">7. Unterbrechung | Beeinträchtigung der Erreichbarkeit der Plattform</h5>
        <div class="tc-section">
          <strong>7.1</strong> Anpassungen, Änderungen und Ergänzungen der vertragsgegenständlichen Plattform sowie
          Maßnahmen, die der Feststellung und Behebung von Funktionsstörungen dienen, werden nur dann zu einer
          vorübergehenden Unterbrechung oder Beeinträchtigung der Erreichbarkeit führen, wenn dies aus technischen
          Gründen zwingend notwendig ist.
          <br/><br/>
          <strong>7.2</strong> Die Überwachung der Grundfunktionen der Software erfolgt täglich. Die Wartung der
          Plattform ist grundsätzlich von Montag bis Freitag 09:00 – 18:00 Uhr gewährleistet. Bei schweren Fehlern – die
          Nutzung der Plattform ist nicht mehr möglich bzw. ernstlich eingeschränkt – erfolgt die Wartung binnen drei
          (3) Stunden ab Kenntnis oder Information durch DrivEddy.
          <br/><br/>
          <strong>7.3</strong> Sofern die Fehlerbehebung nicht innerhalb von zwölf (12) Stunden möglich sein sollte,
          wird DrivEddy den Fahrschüler davon binnen vierundzwanzig (24) Stunden unter Angabe von Gründen sowie des
          Zeitraums, der für die Fehlerbeseitigung voraussichtlich zu veranschlagen ist, per E-Mail verständigen.
          <br/><br/>
          <strong>7.4</strong> Die Verfügbarkeit des jeweils vereinbarten Services nach diesem Vertrag beträgt 98,5 % im
          Jahresdurchschnitt einschließlich Wartungsarbeiten, jedoch darf die Verfügbarkeit nicht länger als zwei
          Kalendertage in Folge beeinträchtigt oder unterbrochen sein.
        </div>

        <h5 class="lp-section-title">8. Vertragsdauer, Kündigung, Sperrung</h5>
        <div class="tc-section">
          <strong>8.1</strong> Der Nutzungsvertrag-Plattform wird auf unbestimmte Zeit geschlossen.
          <br/><br/>
          <strong>8.2</strong> Dieser Nutzungsvertrag-Plattform kann von jeder Partei mit einer Frist von einem (1)
          Monat ab dem Zugang der Kündigung per Benutzerkonto oder per E-Mail ordentlich gekündigt werden.
          <br/><br/>
          <strong>8.3</strong> Dieser Nutzungsvertrag-Plattform kann darüber hinaus von jeder Partei ohne Einhaltung
          einer Frist aus wichtigem Grund schriftlich gekündigt werden.
          <br/>
          Ein wichtiger Grund, liegt insbesondere, aber nicht ausschließlich vor, wenn…
          <ol class="alphabetical">
            <li>
              der Fahrschüler fällige Zahlungen trotz Mahnung und Nachfristsetzung nicht leistet oder die vertraglichen
              Bestimmungen über die Nutzung der Plattform verletzt.
            </li>
            <li>
              über das Vermögen des Fahrschülers das Insolvenzverfahren eröffnet oder die Eröffnung des
              Insolvenzverfahrens mangels Masse abgelehnt wird oder ein ähnlich schwerwiegendes Verfahren gegen den
              Fahrschüler eingeleitet wird.
            </li>
            <li>
              der Fahrschüler seine wesentlichen Vertragspflichten oder eine andere wesentliche Bestimmung dieser AGB
              verletzt, sofern dieser Vertragsbruch nicht innerhalb von sieben (7) Kalendertagen nach Aufforderung durch
              DrivEddy abgestellt wird.
            </li>
            <li>
              der Fahrschüler seinen Leistungspflichten aus den jeweiligen Buchungen gegenüber dem Anbieter nicht
              nachkommt. Hierfür bedarf es ausdrücklich keiner Abmahnung.
            </li>
            <li>
              der Fahrschüler gegen die Zusicherung nach Ziffer 3.4 dieser AGB nach Abmahnung unter einer
              Besserungsfristsetzung von zwei (2) Wochen weiterhin verstößt.
            </li>
          </ol>
          <br/><br/>
          <strong>8.4</strong> Nach Vertragsende des Nutzungsvertrags-Plattform hat der Fahrschüler keinen Zugang mehr
          zum Account und zu seinem Profil. Sein Profil wird dann auch deaktiviert und ist von keinem anderen
          Fahrschüler oder den Anbietern auf der Plattform mehr sichtbar. DrivEddy ist für die Sicherung der im Account
          und dem Profil hinterlegten Informationen nicht verantwortlich. Der Fahrschüler ist daher angehalten,
          rechtzeitig vor Ende der Vertragslaufzeit die für ihn relevanten Daten sofern nach diesen AGB zulässig auf
          einem von DrivEddy unabhängigem Speichermedium zu sichern, was jedoch die Regelung gem. Ziffer 3.7 dieser AGB
          unangetastet lässt.
        </div>

        <h5 class="lp-section-title">9. Haftung</h5>
        <div class="tc-section">
          <strong>9.1</strong> DrivEddy haftet unbeschränkt für Vorsatz und grobe Fahrlässigkeit sowie nach Maßgabe des
          Produkthaftungsgesetzes. Für leichte Fahrlässigkeit haftet DrivEddy bei Schäden aus der Verletzung des Lebens,
          des Körpers und der Gesundheit von Personen.
          <br/><br/>
          <strong>9.2</strong> Bei leichter Fahrlässigkeit haftet DrivEddy jedoch nur im Falle der Verletzung einer
          wesentlichen Vertragspflicht, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst
          ermöglicht und auf deren Einhaltung der Fahrschüler regelmäßig vertrauen darf (Kardinalpflicht). Die Haftung
          für leichte Fahrlässigkeit ist der Höhe nach beschränkt auf die bei Vertragsschluss vorhersehbaren Schäden,
          mit deren Entstehung typischerweise gerechnet werden muss. Diese Haftungsbeschränkung gilt auch zugunsten von
          Erfüllungsgehilfen von DrivEddy.
          <br/><br/>
          <strong>9.3</strong> Darüber hinaus tragen die Anbieter und die Fahrschüler bei der Nutzung des Service und
          der Plattform von DrivEddy jeweils auf eigenes Risiko. Vom Fahrschüler bzw. Anbieter verursachte Schäden trägt
          der Fahrschüler bzw. der Anbieter im direkten Verhältnis zum Anbieter bzw. Fahrschüler.
        </div>

        <h5 class="lp-section-title">10. Freistellungsanspruch und weiterführende Rechte</h5>
        <div class="tc-section">
          <strong>10.1</strong> Der Fahrschüler stellt DrivEddy und deren Mitarbeiter bzw. Beauftragten von sämtlichen
          Ansprüchen Dritter frei für den Fall der Inanspruchnahme wegen vermeintlicher oder tatsächlicher
          Rechtsverletzungen und/oder Verletzung von Rechten Dritter, die im Zusammenhang mit der Nutzung der Plattform
          und/oder der Veröffentlichung von Inhalten, insbesondere von Bildern, des Fahrschülers von Dritten geltend
          gemacht werden.
          <br/><br/>
          <strong>10.2</strong> Der Fahrschüler verpflichtet sich, alle etwaigen Kosten, die DrivEddy durch die
          Inanspruchnahme Dritter entstehen, zu ersetzen. Zu den erstattungsfähigen Kosten zählen auch die Kosten einer
          angemessenen Rechtsverfolgung und Rechtsverteidigung, die DrivEddy zur Abwehr von Ansprüchen Dritter entstehen
          sollten. DrivEddy informiert in diesem Falle den betroffenen Fahrschüler unverzüglich über vorzunehmende
          Maßnahmen der Rechtsverteidigung.
          <br/><br/>
          <strong>10.3</strong> Der Fahrschüler stellt DrivEddy insbesondere aber nicht abschließend von allen
          Ansprüchen frei, die Dritte gegen DrivEddy wegen der vom Fahrschüler eingestellten Inhalte geltend machen. Der
          Fahrschüler ersetzt DrivEddy jeglichen Schaden, den DrivEddy wegen der Verletzung von Rechten Dritter, die
          hierauf beruhen, entsteht, einschließlich etwaiger Kosten der Rechtsverteidigung. Der Fahrschüler unterstützt
          DrivEddy bei der Rechtsverteidigung.
          <br/><br/>
          <strong>10.4</strong> DrivEddy ist berechtigt, Inhalte ganz oder teilweise von der Plattform zu entfernen und
          zu löschen, wenn diese den in diesen AGB festgelegten Anforderungen nicht entsprechen.
        </div>

        <h5 class="lp-section-title">11. Datenschutz und -Sicherheit</h5>
        <div class="tc-section">
          <strong>11.1</strong> DrivEddy ist sich der Sensibilität personenbezogener Daten bewusst und beachtet beim
          Umgang mit personenbezogenen Daten der Anbieter und der Fahrschüler die gesetzlichen Vorschriften über den
          Datenschutz. Rechtsgrundlage dafür sind insbesondere aber nicht abschließend das Telekommunikationsgesetz
          (TMG), das Bundesdatenschutzgesetz (BDSG) sowie das Teledienstdatenschutzgesetz (TDDSG). Für weitere
          Informationen wird auf die Datenschutzerklärung von DrivEddy auf der Internetseite und Angaben der Plattform
          verwiesen.
          <br/><br/>
          <strong>11.2</strong> DrivEddy ist es gestattet, die angegebenen personenbezogenen Daten zu erheben, zu
          verarbeiten, zu speichern und zu nutzen, soweit dies zum Zweck der Durchführung des
          Nutzungsvertrages-Plattform und des Service der Plattform auf Grundlage dieser AGB notwendig ist. Diese Daten
          werden dann für den Service durch DrivEddy verwendet.
          <br/><br/>
          <strong>11.3</strong> DrivEddy gibt – sofern in diesem Vertrag nicht anders geregelt – in keinem Fall
          personenbezogene Daten unbefugt weiter. Zudem erteilt DrivEddy den Anbietern und Fahrschülern gemäß den
          gesetzlichen Bestimmungen unentgeltlich und unverzüglich Auskunft über die bei ihr gespeicherten
          personenbezogenen Daten.
        </div>

        <h5 class="lp-section-title">12. Schlussbestimmungen</h5>
        <div class="tc-section">
          <strong>12.1</strong> Sofern der Fahrschüler als Verbraucher (gem. § 13 BGB) seinen allgemeinen Gerichtsstand
          nicht in Deutschland hat, ist ausschließlicher Gerichtsstand für sämtliche Streitigkeiten aus dieser
          Rechtsbeziehung das für den Geschäftssitz von DrivEddy zuständige Gericht. Sofern der Fahrschüler Unternehmer
          (gem. § 14 BGB) ist, ist ausschließlicher Gerichtsstand für sämtliche Streitigkeiten aus dieser
          Rechtsbeziehung das für den Geschäftssitz von DrivEddy zuständige Gericht.
          <br/><br/>
          <strong>12.2</strong> Auf diese Rechtsbeziehungen ist ausschließlich das Recht der Bundesrepublik Deutschland
          anwendbar.
          <br/><br/>
          <strong>12.3</strong> Die Parteien sind sich darüber bewusst, dass die Software Export- und
          Importbeschränkungen unterliegen kann. Insbesondere können Genehmigungspflichten bestehen bzw. kann die
          Nutzung der Software oder damit verbundener Technologien im Ausland Beschränkungen unterliegen. DrivEddy wird
          die anwendbaren Export- und Importkontrollvorschriften der Bundesrepublik Deutschland und der Europäischen
          Union, sowie alle anderen einschlägigen Vorschriften einhalten. Die Vertragserfüllung durch DrivEddy steht
          unter dem Vorbehalt, dass der Erfüllung keine Hindernisse aufgrund von nationalen und internationalen
          Vorschriften des Export- und Importrechts sowie keine sonstigen gesetzlichen Vorschriften entgegenstehen.
          <br/><br/>
          <strong>12.4</strong> Die EU-Kommission stellt im Internet unter folgendem Link eine Plattform zur
          Online-Streitbeilegung bereit: https://ec.europa.eu/consumers/odr. Diese Plattform dient als Anlaufstelle zur
          außergerichtlichen Beilegung von Streitigkeiten aus Online-Kauf- oder Dienstleistungsverträgen, an denen ein
          Verbraucher beteiligt ist. DrivEddy ist zur Teilnahme an einem Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
          <br/><br/>
          <strong>12.5</strong> Sollte eine dieser Bestimmungen unwirksam oder undurchführbar sein oder werden, so wird
          dadurch die Wirksamkeit der übrigen Bestimmungen nicht berührt. Anstelle der unwirksamen bzw. nicht
          durchführbaren Bestimmung soll eine Regelung treten, die im Rahmen des rechtlich Möglichen dem Willen der
          Parteien am nächsten kommt. Das gleiche gilt im Falle einer Regelungslücke.
        </div>
      </div>
    </div>
  </div>
</div>
