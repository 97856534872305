export enum SourceOfReference {
  PERSONAL_RECOMMENDATION = 'PERSONAL_RECOMMENDATION',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  GOOGLE = 'GOOGLE',
  NEWSPAPER = 'NEWSPAPER',
  SEEN_CAR = 'SEEN_CAR',
  FLYERS = 'FLYERS',
  POSTER_ADVERTISING = 'POSTER_ADVERTISING',
  MISCELLANEOUS = 'MISCELLANEOUS',
}

export function getDisplayName(sourceOfReference: SourceOfReference): string {
  switch (sourceOfReference) {
    case SourceOfReference.PERSONAL_RECOMMENDATION:
      return 'Persönliche Empfehlung';
    case SourceOfReference.SOCIAL_MEDIA:
      return 'Social Media';
    case SourceOfReference.GOOGLE:
      return 'Google';
    case SourceOfReference.NEWSPAPER:
      return 'Zeitung';
    case SourceOfReference.SEEN_CAR:
      return 'Fahrschulauto (gesehen)';
    case SourceOfReference.FLYERS:
      return 'Flyer';
    case SourceOfReference.POSTER_ADVERTISING:
      return 'Plakatwerbung';
    case SourceOfReference.MISCELLANEOUS:
      return 'Sonstiges';
    default:
      return '';
  }
}
