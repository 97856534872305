import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private errorCounter = 0;
  private readonly MAX_RELOAD_ATTEMPTS = 5;
  private readonly chunkFailedMessage = /Loading chunk [\d]+ failed/;

  handleError(error: any): void {
    if (!error || !error.message) {
      return;
    }

    if (this.errorCounter > this.MAX_RELOAD_ATTEMPTS) {
      return;
    }

    if (error.message.indexOf('expected expression, got') !== -1
      || error.message.indexOf('ChunkLoadError') !== -1
      || this.chunkFailedMessage.test(error.message)) {
      this.errorCounter++;
      window.location.reload();
    }
  }
}
