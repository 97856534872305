import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@services/auth.service';

@Injectable()
export class OnlyUnauthenticatedUsersGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authService.getUser();

    if (user && user.id) {
      this.router.navigateByUrl(this.authService.homeUrl || '');
      return false;
    }

    return true;
  }
}
