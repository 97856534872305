const MAX_POINTS_PER_ITEM = 6;

export class LessonContentItem {
  id: number;

  contentId: number;

  orderNumber: number;
  title: string;

  constructor(json) {
    this.id = +json.id;

    this.contentId = +json.contentId;

    this.orderNumber = +json.orderNumber;
    this.title = json.title;
  }
}


export class EddyClubRatingItem {
  id: number;

  ratingId: number;

  lessonContentItemId: number;
  lessonContentItem: LessonContentItem;

  points: number;

  constructor(json) {
    this.id = +json.id;

    this.ratingId = +json.ratingId;

    this.lessonContentItemId = +json.lessonContentItemId;
    if (json.lessonContentItem) {
      this.lessonContentItem = new LessonContentItem(json.lessonContentItem);
    }

    this.points = +json.points || 0;
  }
}

export class PracticeLessonEddyClubRating {
  id: number;

  practiceLessonId: number;

  positiveNotes?: string;
  negativeNotes?: string;
  otherNotes?: string;
  description?: string;

  items: EddyClubRatingItem[];
  isExamPassed?: boolean;

  constructor(json) {
    this.id = +json.id;

    this.practiceLessonId = +json.practiceLessonId;

    this.positiveNotes = json.positiveNotes;
    this.negativeNotes = json.negativeNotes;
    this.otherNotes = json.otherNotes;
    this.description = json.description;
    this.isExamPassed = json.isExamPassed;

    this.items = json.items ? json.items.map(it => new EddyClubRatingItem(it)) : [];
  }

  ratingText(): string {
    return `${this.receivedPoints()}/${this.totalPoints()} Punkten`;
  }

  private receivedPoints(): number {
    return this.items.reduce((sum, current) => sum + current.points, 0);
  }

  private totalPoints(): number {
    return this.items.length * MAX_POINTS_PER_ITEM;
  }

  colorHex(): string {
    const pointsRatio = this.totalPoints() === 0 ? 0 : this.receivedPoints() / this.totalPoints();

    if (pointsRatio < 0.5) {
      return '#dd6060';
    }

    if (pointsRatio < 0.8) {
      return '#f2d95c';
    }

    return '#a1d863';
  }
}
