import { Component, Input } from '@angular/core';
import { PostInfo } from '@models/post-info.model';

@Component({
  selector: 'eddy-post-info',
  templateUrl: './post-info.component.html',
  styleUrls: ['./post-info.component.scss']
})
export class PostInfoComponent {
  @Input() postInfo: PostInfo;
}
