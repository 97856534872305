import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  getAllPaymentTypes,
  getDisplayName,
  PaymentType,
} from '@models/payment-type.model';

@Component({
  selector: 'eddy-payment-type-dropdown',
  templateUrl: 'eddy-payment-type-dropdown.component.html',
  styleUrls: ['./eddy-payment-type-dropdown.component.scss'],
})
export class EddyPaymentTypeDropdownComponent {
  paymentTypes: PaymentType[] = getAllPaymentTypes();

  @Input() fullWidth: boolean;

  @Input() roundedCorners: boolean;

  @Input() class: string;

  @Output() paymentTypeChanged: EventEmitter<PaymentType> =
    new EventEmitter<PaymentType>();

  onChange(paymentType: PaymentType) {
    this.paymentTypeChanged.emit(paymentType);
  }

  getPaymentTypeDisplayName(paymentType: PaymentType) {
    return getDisplayName(paymentType);
  }
}
