import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';


@Injectable()
export class ConnectionService implements OnDestroy {
  private stateChangeEventEmitter = new EventEmitter<boolean>();

  private currentState = window.navigator.onLine;
  private offlineSubscription: Subscription;
  private onlineSubscription: Subscription;

  constructor() {
    this.checkNetworkState();
  }

  private checkNetworkState() {
    this.onlineSubscription = fromEvent(window, 'online').subscribe(() => {
      this.currentState = true;
      this.emitEvent();
    });

    this.offlineSubscription = fromEvent(window, 'offline').subscribe(() => {
      this.currentState = false;
      this.emitEvent();
    });
  }

  private emitEvent() {
    this.stateChangeEventEmitter.emit(this.currentState);
  }

  ngOnDestroy(): void {
    try {
      this.offlineSubscription.unsubscribe();
      this.onlineSubscription.unsubscribe();
    } catch (e) {
    }
  }

  /**
   * Monitor the network connection status by subscribing to this observer. If you set "reportCurrentState" to "false" then
   * function will not report current status of the connections when initially subscribed.
   * @param reportCurrentState Report current state when initial subscription. Default is "true"
   */
  monitor(reportCurrentState = true): Observable<boolean> {
    return reportCurrentState ?
      this.stateChangeEventEmitter.pipe(
        debounceTime(500),
        startWith(this.currentState),
      )
      :
      this.stateChangeEventEmitter.pipe(
        debounceTime(500)
      );
  }
}
