import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SignUpDialog } from '../../dialogs/sign-up/sign-up.dialog';
import { Overlay } from '@angular/cdk/overlay';
import { TheoryLessonService } from '@services/theory-lesson.service';
import { TheoryLessonTopic } from '@models/theory-lesson-topic.model';
import { ScreenWidthService } from '@services/screen-width.service';
import { MetaService } from '@services/meta.service';
import { AppRoutes } from '../../app.routes';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  templateUrl: './eddy-academy.page.html',
  styleUrls: ['./eddy-academy.page.scss']
})
export class EddyAcademyPage implements OnInit {
  readonly loginPageUrl = `/${AppRoutes.Root.login}`;

  theoryLessonTopics: TheoryLessonTopic[];
  visibilityAt = 0;
  theoryReady: boolean;
  private isDesktop: boolean;
  private onlineTheoryImagePath = 'https://driveddy.com/assets/online-theory.png';

  isOnlineTheoryRecognized: boolean | undefined = undefined;
  postalCode: string;

  constructor(private theoryLessonService: TheoryLessonService,
              private screenWidthService: ScreenWidthService,
              private dialog: MatDialog,
              private overlay: Overlay,
              private metaService: MetaService,
              private route: ActivatedRoute) {
    this.setMetaTags();
    this.screenWidthService.isDesktop().subscribe(isDesktop => this.isDesktop = isDesktop);

    this.theoryLessonService.getLicenseABTheoryTopics().subscribe(topics => {
      this.theoryLessonTopics = topics;
      this.setTopicsVisibility();
    });

    this.theoryReady = false;
  }

  ngOnInit() {
    this.storeUTMParameters(this.loadUTMParameters(this.route.snapshot.queryParamMap));
  }

  private setMetaTags() {
    const title = 'Theorieunterricht Online besuchen';
    const description = 'Live Online-Theorie ✅ Live-Chat ✅ Exklusive Lernmaterialien ✅ 14 Theoriestunden ✅ nur 14,20 pro Stunde ➤ Jetzt kostenlos registrieren und loslegen!';

    this.metaService.setMetatags(title, description, null, this.onlineTheoryImagePath);
  }

  setTopicsVisibility() {
    for (let i = 0; i < this.theoryLessonTopics.length; i++) {
      // all topics should be visible at the same time on small screens
      this.theoryLessonTopics[i]['visible'] = (!this.isDesktop || (i >= this.visibilityAt && i <= this.visibilityAt + 2));
    }
  }

  updateVisibleTopics(moveRight: boolean) {
    if (moveRight && (this.visibilityAt + 3) < this.theoryLessonTopics.length) {
      this.visibilityAt++;
    } else if (!moveRight && this.visibilityAt > 0) {
      this.visibilityAt--;
    }
    this.setTopicsVisibility();
  }

  onOpenSignUpDialog() {
    const scrollStrategy = this.overlay.scrollStrategies.reposition();

    const dialogRef = this.dialog.open(SignUpDialog, {
      width: '420px',
      height: 'auto',
      maxWidth: '100%',
      panelClass: ['custom-dialog-container', 'event-dialog', 'availability-dialog', 'sign-up-dialog'],
      // panelClass: ['custom-dialog-container', 'sign-up-dialog'],
      scrollStrategy: scrollStrategy,
      data: {
        postalCode: this.postalCode,
      }
    });

    dialogRef.afterClosed().subscribe(() => {
    });
  }

  onPostalCodeChange(postalCode: string) {
    this.isOnlineTheoryRecognized = undefined;
    this.postalCode = postalCode;
    this.theoryLessonService.isOnlineTheoryRecognized(postalCode)
      .subscribe(isOnlineTheoryRecognized => this.isOnlineTheoryRecognized = isOnlineTheoryRecognized,
        () => this.isOnlineTheoryRecognized = false);
  }

  private loadUTMParameters(queryParamMap: ParamMap) {
    const utmKeys = queryParamMap.keys.filter(key => this.isUTMParameter(key));

    const utmMap = {};
    for (const utmKey of utmKeys) {
      utmMap[utmKey] = queryParamMap.get(utmKey);
    }

    return utmMap;
  }

  private isUTMParameter(key: string) {
    return key?.startsWith('utm');
  }

  private storeUTMParameters(utmParameters) {
    this.theoryLessonService.storeOnlineTheoryUTMParameters(utmParameters);
  }
}
