import { CalendarEvent } from '@models/calendar-event.model';
import { Learner } from '@models/learner.model';
import { School } from '@models/school.model';
import { TeamMember } from '@models/team-member.model';
import {
  getDisplayName,
  PracticeLessonTopic,
} from '@models/practice-lesson-topic.model';
import {
  getDisplayName as getStatusDisplayName,
  isCancelled,
  isCancelledByInstructor,
  isCancelledByLearner,
  isCompleted,
  isConfirmedOrCompleted,
  isNotOccurred,
  PracticeLessonStatus,
} from '@models/practice-lesson-status.model';
import { Address } from './address.model';
import { Car } from './car.model';
import { PracticeLessonRating } from '@models/practice-lesson-rating.model';
import {
  getTopicTypeDisplayName,
  StandardizedPracticeTopic,
} from '@models/standardized-practice-topic.model';
import { PickupLocation } from './location.model';
import { LessonAddress, toString } from './lesson-address.model';
import { PracticeLessonEddyClubRating } from './practice-lesson-eddy-club-rating.model';
import { License, PriceListSettingPaymentType } from './license.model';
import {
  getDisplayName as getLessonTypeDisplayName,
  LessonType,
} from './lesson-type.model';
import moment from 'moment';

export const NUMBER_OF_STANDARDIZED_LESSONS = 33;
export const HARMONIZATION_PRACTICE_LESSON_DURATION_IN_MINUTES = 45;

export class PracticeLesson {
  id: number;

  key: string;

  type: LessonType;

  eventId?: number;
  event?: CalendarEvent;

  topic?: PracticeLessonTopic;

  standardizedPracticeTopicId?: number;
  standardizedPracticeTopic?: StandardizedPracticeTopic;

  status: PracticeLessonStatus;
  rating?: PracticeLessonRating;
  eddyClubRating?: PracticeLessonEddyClubRating;

  pickupLocationId?: number;
  pickupLocation?: PickupLocation;
  customLocation?: Address;

  learnerId: number;
  learner: Learner;

  instructor: TeamMember;

  school?: School;

  carId: number;
  car: Car;

  licenseId?: number;
  license?: License;

  examPaymentType?: PriceListSettingPaymentType;

  private readonly priceInCents: number;

  refundAmountInCents?: number;
  isPaid?: boolean;

  isCashPayment: boolean;
  isExam: boolean;
  isConsideredForCancellationFee: boolean;

  learnerMessage?: string;
  learnerMessageDate?: Date;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;

    this.key = json.key;

    this.type = json.type;

    if (json.eventId) {
      this.eventId = +json.eventId;
    }
    if (json.event) {
      this.event = new CalendarEvent(json.event);
    }

    if (json.topic) {
      this.topic = json.topic;
    }
    if (json.standardizedPracticeTopicId) {
      this.standardizedPracticeTopicId = +json.standardizedPracticeTopicId;
    }
    if (json.standardizedPracticeTopic) {
      this.standardizedPracticeTopic = new StandardizedPracticeTopic(
        json.standardizedPracticeTopic
      );
    }

    this.status = json.status;

    if (json.pickupLocationId) {
      this.pickupLocationId = +json.pickupLocationId;
    }
    if (json.pickupLocation) {
      this.pickupLocation = new PickupLocation(json.pickupLocation);
    }
    if (json.customLocation) {
      this.customLocation = new Address(json.customLocation);
    }

    if (json.rating) {
      this.rating = new PracticeLessonRating(json.rating);
    }

    if (json.eddyClubRating) {
      this.eddyClubRating = new PracticeLessonEddyClubRating(
        json.eddyClubRating
      );
    }

    this.learnerId = +json.learnerId;
    this.learner = new Learner(json.learner);

    this.instructor = new TeamMember(json.instructor);

    if (json.school) {
      this.school = new School(json.school);
    }

    if (json.carId) {
      this.carId = +json.carId;
    }

    if (json.car) {
      this.car = new Car(json.car);
    }

    if (json.licenseId) {
      this.licenseId = +json.licenseId;
    }
    if (json.license) {
      this.license = new License(json.license);
    }

    if (json.examPaymentType) {
      this.examPaymentType = json.examPaymentType;
    }

    this.priceInCents = +json.priceInCents;

    if (json.refundAmountInCents) {
      this.refundAmountInCents = +json.refundAmountInCents;
    }

    if (json.isPaid !== undefined) {
      this.isPaid = !!json.isPaid;
    }

    this.isCashPayment = !!json.isCashPayment;

    this.isExam = !!json.isPracticeExam;

    this.isConsideredForCancellationFee = !!json.isConsideredForCancellationFee;

    if (json.learnerMessage) {
      this.learnerMessage = json.learnerMessage;
    }
    if (json.learnerMessageDate) {
      this.learnerMessageDate = new Date(json.learnerMessageDate);
    }
  }

  getStatusDisplayName(): string {
    return getStatusDisplayName(this.status);
  }

  getCategoryDisplayName(useTopicDisplayName = false): string {
    if (isConfirmedOrCompleted(this.status)) {
      if (useTopicDisplayName) {
        return this.getTopicDisplayName();
      }
      if (this.isExamType()) {
        return getLessonTypeDisplayName(this.type);
      }
      return 'Fahrstunde';
    } else if (isCancelledByInstructor(this.status)) {
      return 'Stornierung Fahrlehrer';
    } else if (isCancelledByLearner(this.status)) {
      return 'Stornierung Fahrschüler';
    }
    return 'Stornierung';
  }

  getReceiptDisplayName(): string {
    if (isConfirmedOrCompleted(this.status)) {
      return 'fahrstunde';
    }
    return 'stornogebuehr';
  }

  getTopicDisplayName(): string {
    if (this.type === LessonType.THEORY_EXAM) {
      return 'Theoretische Prüfung';
    }
    if (this.standardizedPracticeTopic) {
      return this.standardizedPracticeTopic.getDisplayName();
    }
    return getDisplayName(this.topic);
  }

  isExamType(): boolean {
    return [LessonType.THEORY_EXAM, LessonType.PRACTICE_EXAM].includes(
      this.type
    );
  }

  getDurationInMinutes(): number {
    if (!this.event) {
      return null;
    }
    return this.event.getDurationInMinutes();
  }

  getTimeInterval(): string {
    if (!this.event) {
      return null;
    }
    return this.event.getTimeInterval();
  }

  getWeekDayDisplayName(): string {
    if (!this.event) {
      return null;
    }
    return this.event.getWeekDayDisplayName();
  }

  getSchoolDurationInMinutes(): number {
    if (!this.event) {
      return null;
    }
    return this.event.getSchoolDurationInMinutes();
  }

  getSchoolTimeInterval(): string {
    if (!this.event) {
      return null;
    }
    return this.event.getSchoolTimeInterval();
  }

  getSchoolWeekDayDisplayName(): string {
    if (!this.event) {
      return null;
    }
    return this.event.getSchoolWeekDayDisplayName();
  }

  pickupLocationString(): string {
    return toString(this.address);
  }

  isCancelled(): boolean {
    return isCancelled(this.status);
  }

  isNotOccurred(): boolean {
    return isNotOccurred(this.status);
  }

  isConfirmedOrCompleted(): boolean {
    return isConfirmedOrCompleted(this.status);
  }

  isCompletedByInstructor(): boolean {
    return this.status === PracticeLessonStatus.COMPLETED_BY_INSTRUCTOR;
  }

  isRejectedRequest(): boolean {
    return this.status === PracticeLessonStatus.REJECTED_BY_INSTRUCTOR;
  }

  isAcceptedRequest(): boolean {
    return (
      this.status !== PracticeLessonStatus.REJECTED_BY_INSTRUCTOR &&
      this.status !== PracticeLessonStatus.DELETED
    );
  }

  instructorShortenedName(): string {
    if (!this.instructor) {
      return '';
    }

    return this.instructor.getShortenedName();
  }

  get shouldPaymentInfoBeShown(): boolean {
    return this.school?.canUsePaymentSystem;
  }

  get fullPriceInCents(): number {
    return this.priceInCents;
  }

  get refundCancellationFee(): number {
    if (!this.refundAmountInCents) {
      return this.priceInCents;
    }
    return this.priceInCents - this.refundAmountInCents;
  }

  getTotalPrice(): number {
    return this.fullPriceInCents / 100;
  }

  getInstructorPayment(): number {
    if (!this.shouldPaymentInfoBeShown) {
      return null;
    }
    return (0.75 * this.priceInCents) / 100;
  }

  getStandardizedTopicTitle(): string {
    if (!this.standardizedPracticeTopic) {
      return '';
    }

    return this.standardizedPracticeTopic.title;
  }

  getStandardizedTopicTypeDisplayName(): string {
    if (!this.standardizedPracticeTopic) {
      return '';
    }

    return getTopicTypeDisplayName(this.standardizedPracticeTopic.type);
  }

  isFullyCompleted(): boolean {
    return isCompleted(this.status);
  }

  hasTimeSlot(): boolean {
    return !!this.event;
  }

  get startDate(): Date {
    if (!this.event) {
      return null;
    }

    return this.event.startDate;
  }

  get startTime() {
    if (!this.event) {
      return '';
    }

    return this.event.getStartTime();
  }

  get endDate() {
    if (!this.event) {
      return null;
    }

    return this.event.endDate;
  }

  get endTime() {
    if (!this.event) {
      return '';
    }

    return this.event.getEndTime();
  }

  get address(): LessonAddress | null {
    if (this.pickupLocation) {
      return {
        street: this.pickupLocation.street,
        streetNumber: this.pickupLocation.streetNumber,
        postalCode: this.pickupLocation.postalCode,
        locality: this.pickupLocation.locality,
      };
    }

    if (this.customLocation) {
      return {
        street: this.customLocation.street,
        streetNumber: this.customLocation.streetNumber,
        postalCode: this.customLocation.postalCode,
        locality: this.customLocation.locality,
      };
    }

    return null;
  }

  getStandardizedTopicLevelTitle(): string {
    if (
      !this.standardizedPracticeTopic ||
      !this.standardizedPracticeTopic.level
    ) {
      return '';
    }

    return this.standardizedPracticeTopic.level.title;
  }

  get isEddyClubLesson(): boolean {
    return !!this.learner && this.learner.isEddyClubLearner;
  }

  get isHarmonizationLesson(): boolean {
    return !!this.learner && this.learner.usesHarmonization;
  }

  isInThePast(): boolean {
    return this.startDate && this.startDate.getTime() < Date.now();
  }

  get hasHardPaymentDeadlinePassed(): boolean {
    return this.isInThePast() && moment().diff(this.endDate, 'days') > 14;
  }

  getOrderKey(): string {
    return `lesson_${this.key}`;
  }
}

const overlappingEventsError = 'bad request - overlaps with an existing event';

export function isOverlappingEventsError(err): boolean {
  return (
    err &&
    (err.message === overlappingEventsError ||
      err.error === overlappingEventsError)
  );
}
