<div class="container" *ngIf="!isOnlineTheoryPackage || theoryVideosPackage">
  <div class="content-padding">
    <h1 class="section-title mb-4">Juhuu, dein Kauf war erfolgreich!</h1>

    <div class="content-text">
      Klasse, dass du dich für das <b>{{productName}}</b> für
      <b>{{productPrice}}&euro;</b>
      entschieden hast.<br />Bei Fragen kannst du dich gerne jederzeit unter
      <a href="mailto:support@driveddy.com" class="content-link"
        >support@drivEddy.com</a
      >
      an uns wenden.
    </div>
  </div>

  <ng-container
    *ngIf="isOnlineTheoryPackage && theoryVideosPackage && !theoryVideosPackage.isPaid()"
  >
    <div class="content-padding">
      <div class="content-text my-4">
        <b>Bevor du weiterziehst, haben wir noch etwas für dich:</b>
      </div>
      <h2 class="section-title underlined desktop-only-element">
        Sonderangebot:
      </h2>
    </div>
    <div class="special-offer-container">
      <div class="theory-videos-cont">
        <div class="image desktop-only-element">
          <img src="/assets/signup-wizard/card_theorie-video-mock-up.png" />
        </div>
        <div>
          <div class="section-title-cont">
            <div>
              <h2 class="section-title underlined mobile-only-element">
                Sonderangebot:
              </h2>
              <h3 class="font-weight-bold">
                {{ theoryVideosPackage.numberOfLessons }} x 90 Minuten
                Theorie-Unterrichtvideos
              </h3>
            </div>
            <div class="image mobile-only-element">
              <img src="/assets/signup-wizard/card_theorie-video-mock-up.png" />
            </div>
          </div>

          <div class="content-text my-4">
            Du kannst dir jetzt exklusiv für nur
            <b>{{ theoryVideosPackage.displayOfferPrice }} Euro</b> alle
            <b>{{ theoryVideosPackage.numberOfLessons }}</b> Video’s in HD
            Qualität mit insgesamt
            <b>{{theoryVideosTotalMins}}</b> Unterrichtsstoff sichern, damit du
            die Live-Unterrichte jederzeit noch einmal anschauen und
            nachbereiten kannst.
          </div>
          <div class="offer-btn-container">
            <button
              class="success-rounded-btn"
              (click)="toTheoryVideosPaymentPage()"
            >
              Für {{ theoryVideosPackage.displayOfferPrice }} kaufen
            </button>
            <button
              class="success-rounded-btn ghost-btn"
              (click)="onOfferRejected()"
            >
              Nein, Danke
            </button>
          </div>
        </div>
      </div>
      <div>
        <eddy-ratings-and-reviews></eddy-ratings-and-reviews>
      </div>
    </div>
    <div
      class="online-theory-badge"
      *ngIf="showCurrentSale"
      [@fadeAnimation]="showCurrentSale"
    >
      <div class="badge-icon">{{ currentSale.letter }}</div>
      <div>
        <div class="badge-name">{{ currentSale.name }}</div>
        <div class="badge-time">{{ currentSale.text }}</div>
        <div class="badge-text">{{ currentSale.time }}</div>
      </div>
    </div>
  </ng-container>
  <div class="content-padding">
    <div *ngIf="isOnlineTheoryOrAllInOnePackage">
      <h2 class="section-title instructions-title">Deine nächsten Schritte</h2>
      <div class="row no-side-space instructions-container">
        <div class="instructions-line-container">
          <hr class="instructions-line" />
        </div>
        <div class="step">
          <img
            src="assets/academy/click-icon.png"
            class="feature-icon-interactive"
          />
          <h4 class="steps-header">Lade die drivEddy Theorie-App herrunter</h4>
          <p class="steps-description">
            Hier kannst du mit dem offiziellen Fragenkatalog der TÜV / Dekra
            lernen.
          </p>
        </div>
        <div class="step">
          <img
            src="assets/academy/online-teacher-icon.png"
            class="feature-icon-interactive"
          />
          <h4 class="steps-header">Buche deine erste Theoriestunde</h4>
          <p class="steps-description">
            Du kannst deine Stunden in beliebiger Reihenfolge buchen und
            besuchen.
          </p>
        </div>
        <div class="step last-step">
          <img
            src="assets/academy/certificate-icon.png"
            class="feature-icon-interactive"
          />
          <h4 class="steps-header">Erhalte deinen rechtsgültigen Bescheid</h4>
          <p class="steps-description">
            Absolvierte Online Theoriestunden sind 2 Jahre lang gültig.
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="isTheoryVideosPackage">
      <h2 class="section-title instructions-title">Deine nächsten Schritte</h2>
      <div class="row no-side-space instructions-container">
        <div class="instructions-line-container">
          <hr class="instructions-line" />
        </div>
        <div class="step">
          <img
            src="assets/academy/click-icon.png"
            class="feature-icon-interactive"
          />
          <h4 class="steps-header">Lade unsere Theorie- App herrunter</h4>
          <p class="steps-description">
            Du kannst die Video’s jederzeit und beliebig oft anschauen.
          </p>
        </div>
        <div class="step">
          <img
            src="assets/academy/online-teacher-icon.png"
            class="feature-icon-interactive"
          />
          <h4 class="steps-header">Schau dir dein erstes Theorie-Video an</h4>
          <p class="steps-description">
            Du kannst die Video’s jederzeit und beliebig oft anschauen.
          </p>
        </div>
        <div class="step last-step">
          <img
            src="assets/academy/certificate-icon.png"
            class="feature-icon-interactive"
          />
          <h4 class="steps-header">Erhalte dein Teilnahme Zertifikat</h4>
          <p class="steps-description">
            Sobald du deine Stunde besucht hast, erhältst du eine
            Teilnehmerurkunde.
          </p>
        </div>
      </div>
    </div>
    <div
      class="continue-btn-cont"
      [class.btn-cont-margin-top]="!isOnlineTheoryOrAllInOnePackage && !isTheoryVideosPackage"
    >
      <button class="success-rounded-btn" (click)="onContinue()">
        Los geht’s
      </button>
    </div>
  </div>
</div>
