import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { SchoolService } from '@services/school.service';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { TeamMember } from '@models/team-member.model';
import { UserInfo } from '@models/user-info.model';
import { Learner } from '@models/learner.model';
import { School } from '@models/school.model';

@Injectable()
export class AccountService {

  private selectedSchoolSubject = new BehaviorSubject<School>(null);
  private currentUser = new BehaviorSubject<UserInfo>(null);
  private currentTeamMember = new BehaviorSubject<TeamMember>(null);
  private currentLearner = new ReplaySubject<Learner>(1);

  constructor(private authService: AuthService,
              private schoolService: SchoolService) {
    this.authService.teamMemberSubject.subscribe(teamMember => {
      if (teamMember && teamMember.id) {
        this.currentTeamMember.next(teamMember);
      }
    });

    this.authService.learnerSubject.subscribe(learner => {
      if (learner && learner.id) {
        this.currentLearner.next(learner);
      }
    });

    this.authService.userSubject.subscribe(user => this.currentUser.next(user));

    this.getCurrentTeamMember().subscribe(teamMember => {
      if (teamMember.schoolId) {
        this.schoolService.getSchoolById(teamMember.schoolId).subscribe(school => {
          this.selectedSchoolSubject.next(school);
        });
      }
    });
  }

  getSelectedSchool(): Observable<School> {
    return this.selectedSchoolSubject
      .asObservable()
      .pipe(
        filter(value => value !== null),
        distinctUntilChanged()
      );
  }

  getCurrentUser(): Observable<UserInfo> {
    return this.currentUser
      .asObservable()
      .pipe(
        filter(value => value !== null),
        distinctUntilChanged()
      );
  }

  getCurrentTeamMember(): Observable<TeamMember> {
    return this.currentTeamMember
      .asObservable()
      .pipe(
        filter(value => value !== null),
        distinctUntilChanged()
      );
  }

  getCurrentLearner(): Observable<Learner> {
    return this.currentLearner
      .asObservable()
      .pipe(
        filter(value => value !== null),
        distinctUntilChanged((v1, v2) => v1.id === v2.id),
      );
  }
}
