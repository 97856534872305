import { Learner } from './learner.model';
import { PaymentType } from './payment-type.model';

export class LearnerCredit {
  id: number;

  createdAt: Date;
  updatedAt: Date;

  key: string;

  learnerId: number;
  learner?: Learner;

  comment: string;
  paymentType: PaymentType;

  amountInCents: number;
  usedAmountInCents: number;
  refundedAmountInCents: number;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.createdAt = new Date(json.createdAt);
    this.updatedAt = new Date(json.updatedAt);

    this.key = json.key;

    this.learnerId = +json.learnerId;
    if (json.learner) {
      this.learner = new Learner(json.learner);
    }

    this.comment = json.comment;
    this.paymentType = json.paymentType;

    this.amountInCents = +json.amountInCents;
    this.usedAmountInCents = +json.usedAmountInCents;
    this.refundedAmountInCents = +json.refundedAmountInCents;
  }

  get availableAmountInCents(): number {
    return this.amountInCents - this.usedAmountInCents;
  }
}
