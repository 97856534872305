import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TheoryLessonTopic } from '@models/theory-lesson-topic.model';

@Component({
  selector: 'eddy-academy-lesson',
  templateUrl: './academy-lesson.component.html',
  styleUrls: ['./academy-lesson.component.scss']
})
export class AcademyLessonComponent {
  @Input() theoryTopic: TheoryLessonTopic;

  @Output() cardClicked: EventEmitter<void> = new EventEmitter();

  onCardClick() {
    this.cardClicked.emit();
  }
}
