import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment as env } from '../../environments/environment';
import { AuthService } from './auth.service';
import { Learner } from '@models/learner.model';


@Injectable()
export class StudentsService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
  }

  getLearnerByEmail(email: string): Observable<Learner> {
    return this.http.get<Learner[]>(`${env.apiSchoolService}/learners/email/${email}`, this.authService.getHttpOptions())
      .pipe(
        map((res: any) => res['data']),
        catchError((err: any) => throwError(err))
      );
  }
}
