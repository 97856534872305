import { School } from './school.model';

export class SchoolTheoryLearningPreference {
    id?: number;
  
    schoolId: number;
    school?: School;
  
    isActive: boolean;
    isOnlineBilling: boolean;
    priceInCents?: number;
  
    constructor(json?: any) {
      if (!json) {
        return;
      }
      this.id = +json.id;
  
      this.schoolId = +json.schoolId;
      if (json.school) {
        this.school = new School(json.school);
      }

      this.isActive = !!json.isActive;
      this.isOnlineBilling = !!json.isOnlineBilling;
      if(+json.priceInCents) {
        this.priceInCents = +json.priceInCents;
      }
    }
  
    getPriceInCentsFormatted(): string {
      if (!this.isOnlineBilling) {
        return '';
      }
      return (this.priceInCents / 100.0).toFixed(2);
    }
}
