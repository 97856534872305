import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Location, PopStateEvent, } from '@angular/common';

@Injectable()
export class ScrollTopService {
  private lastPoppedUrl: string | undefined;
  private yScrollStack: number[] = [];

  constructor(private router: Router,
              private location: Location) {
  }

  /**
   * Start subscribing to page route events
   */
  public start(): void {
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });

    this.router.events.subscribe((ev: any) => {
      const contentContainer = document.querySelector('.mat-sidenav-content') || window;
      if (ev instanceof NavigationStart) {
        if (ev.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY);
        }
      } else if (ev instanceof NavigationEnd) {
        if (!contentContainer || !contentContainer.scrollTo) {
          return;
        }

        if (ev.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          contentContainer.scrollTo(0, this.yScrollStack.pop());
        } else {
          // TODO on edge, scrollTo() doesn't work - https://stackoverflow.com/questions/51517324/scrollto-method-doesnt-work-in-edge
          contentContainer.scrollTo(0, 0);
        }
      }
    });
  }
}
