<div class="dialog-container">
  <div id="dialog-title-container" mat-dialog-title>
    <div class="user-plus-icon-container">
      <i class="fas fa-money-check"></i>
    </div>

    <span (click)="onQuit()" class="dialog-close-button">
      <i class="fal fa-times"></i>
    </span>
  </div>
  <mat-dialog-content>
    <div class="main-content">
      <div class="dialog-header">
      Geänderte Preisliste bestätigen
      </div>

      <div class="description-text">
        Deine Fahrschule hat dir eine geänderte Preisliste zugewiesen. Um mit deiner Ausbildung fortfahren zu können, bestätige diese bitte.
        Solltest du ablehnen, wird deine Ausbildung pausiert und deine Fahrschule darüber informiert.<br />
        <br />
        Solltest du die Änderungen vorher überprüfen wollen, klicke bitte auf "Änderungen überprüfen".
      </div>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="dialog-actions space-between">
      <button type="submit" class="primary-btn" (click)="acceptPriceList()">Bestätigen</button>
      <button type="button" class="primary-btn ghost-btn red" (click)="rejectPriceList()">Ablehnen</button>
    </div>
    <div class="dialog-actions center">
      <span class="decline-btn" (click)="goToCheckPriceList()">Änderungen überprüfen</span>
    </div>
  </mat-dialog-actions>
</div>
