<ng-container *ngIf="asset && asset.path && !isVideo(asset)">
  <a class="link-wrapper" [href]="asset.linkPath || 'javascript:void(0)'" target="_blank" (click)="onAdClick()"
     [class.displayed]="displayed">
    <img [src]="asset.path" alt="img" (load)="onAdLoad()"
         [class.medium-rectangle-img]="asset.type == 'MEDIUM_RECTANGLE'"
         [class.leaderboard-mobile-img]="asset.type == 'LEADERBOARD_MOBILE'"
         [class.leaderboard-desktop-img]="asset.type == 'LEADERBOARD_DESKTOP'"
    />
    <img class="aff-marker" src="/assets/aff-symbol-web.png"/>
  </a>
</ng-container>

<ng-container *ngIf="asset && asset.path && isVideo(asset)">
  <iframe width="100%" height="100%" [src]="videoUrl" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope;"
          allowfullscreen></iframe>
</ng-container>
