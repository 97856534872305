import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, } from '@angular/router';

import { AuthService } from './auth.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AccountService } from '@services/account.service';
import { environment as env } from '@environments/environment';

@Injectable()
export class AuthSchoolStatusGuard implements CanActivateChild {

  constructor(private authService: AuthService,
              private accountService: AccountService,
              private router: Router) {
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!route.data.schoolStatuses) {
      return true;
    }

    this.authService.destinationUrl = state.url;

    return this.accountService.getSelectedSchool().pipe(
      map(school => {
        if (!school || route.data.schoolStatuses.indexOf(school.status) === -1) {
          this.router.navigate([env.landing]).then();
          return false;
        }
        return true;
      }),
      catchError(() => of(false))
    );
  }
}
