import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@services/notification.service';
import { LearnerLicense } from '@models/learner.model';
import { School } from '@models/school.model';
import { LicenseService } from '@services/license.service';
import { License, LicensePriceList } from '@models/license.model';
import { Messages } from 'app/app.messages';
import { LearnerPaymentFlag } from '@models/learner-payment-flag.model';

enum Step {
  CHOOSE_LICENSE = 'CHOOSE_LICENSE',
  ACTIVATE_ONLINE_THEORY = 'ACTIVATE_ONLINE_THEORY',
}

@Component({
  templateUrl: './add-learner-license.dialog.html',
  styleUrls: ['./add-learner-license.dialog.scss'],
})
export class AddLearnerLicenseDialog implements OnInit {
  readonly school: School;
  readonly learnerId: number;
  readonly isFastlaneLearner: boolean;

  activeStep: Step;

  chooseLearnerLicenseForm: FormGroup;
  licensePaymentFlagForm: FormGroup;
  activateLicenseOnlineTheoryForm: FormGroup;
  activateTheoryVideosForm: FormGroup;

  priceList: LicensePriceList;
  licenses: License[];
  licensePricing: number;
  theoryVideosPricing: number;
  parentLicensePricing: number;

  isEddyClubLearner: boolean;
  areAllTheoryLessonsBookable = false;

  paymentFlags = LearnerPaymentFlag.getUnconditionalPaymentFlags();

  constructor(
    private dialogRef: MatDialogRef<AddLearnerLicenseDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
    private licenseService: LicenseService
  ) {
    this.activeStep = Step.CHOOSE_LICENSE;
    this.priceList = data.priceList;
    this.licenses = data.licenses;
    this.school = data.school;
    this.learnerId = data.learnerId;
    this.isFastlaneLearner = data.isFastlaneLearner;
  }

  ngOnInit() {
    this.setupForms();
  }

  onSubmit() {
    if (this.formIsInvalid) {
      this.notificationService.formDataInvalidError();
      return;
    }

    switch (this.activeStep) {
      case Step.CHOOSE_LICENSE:
        this.chooseLearnerLicense();
        break;
      case Step.ACTIVATE_ONLINE_THEORY:
        this.addLearnerLicense();
        break;
    }
  }

  onLearnerLicenseChange() {
    this.areAllTheoryLessonsBookable = false;
    this.licensePricing = null;
    this.parentLicensePricing = null;
    this.paymentFlags = LearnerPaymentFlag.getUnconditionalPaymentFlags();
    this.licensePaymentFlagForm.controls.paymentFlag.setValue(null);

    const chosenLicense: License =
      this.chooseLearnerLicenseForm.controls.license.value;

    if (!chosenLicense) {
      return;
    }

    if (this.priceList) {
      this.paymentFlags = this.priceList.getPaymentFlagsForLicense(
        chosenLicense.id
      );
    }

    if (this.chosenLicenseNotFullyBookable) {
      this.licenseService
        .getPlatformSchoolLicensePricing(this.school.id, chosenLicense.id)
        .subscribe(
          (pricing) => {
            this.licensePricing = this.isFastlaneLearner
              ? pricing.fastlaneAmountInCents
              : pricing.amountInCents;
            this.theoryVideosPricing = pricing.theoryVideosAmountInCents;
          },
          () =>
            this.notificationService.error(
              Messages.Errors.NoPlatformLicensePriceSet
            )
        );
      this.licenseService
        .getPlatformSchoolLicensePricing(
          this.school.id,
          this.getChosenLicenseParentId()
        )
        .subscribe(
          (pricing) => {
            this.parentLicensePricing = this.isFastlaneLearner
              ? pricing.fastlaneAmountInCents
              : pricing.amountInCents;
          },
          () =>
            this.notificationService.error(
              Messages.Errors.NoPlatformLicensePriceSet
            )
        );
    }
  }

  private chooseLearnerLicense() {
    const chosenLicense: License =
      this.chooseLearnerLicenseForm.controls.license.value;

    if (!chosenLicense) {
      return;
    }

    if (chosenLicense && !chosenLicense.hasOnlineTheory) {
      this.setActivateOnlineTheory(false);
      this.changeActiveStep(Step.ACTIVATE_ONLINE_THEORY);
      return;
    }
    if (this.school.isOnlineTheoryAvailable) {
      this.setActivateOnlineTheory(true);
    }
    if (!this.licensePricing) {
      this.licenseService
        .getPlatformSchoolLicensePricing(this.school.id, chosenLicense.id)
        .subscribe(
          (pricing) => {
            this.licensePricing = this.isFastlaneLearner
              ? pricing.fastlaneAmountInCents
              : pricing.amountInCents;
            this.theoryVideosPricing = pricing.theoryVideosAmountInCents;
            this.changeActiveStep(Step.ACTIVATE_ONLINE_THEORY);
          },
          () =>
            this.notificationService.error(
              Messages.Errors.NoPlatformLicensePriceSet
            )
        );
    } else {
      this.changeActiveStep(Step.ACTIVATE_ONLINE_THEORY);
    }
  }

  get isOnlineTheoryActivated(): boolean {
    return this.activateLicenseOnlineTheoryForm.value.activateOnlineTheory;
  }

  get isTheoryVideosActivated(): boolean {
    return this.activateTheoryVideosForm.value.activateTheoryVideos;
  }

  private setActivateOnlineTheory(activateOnlineTheory: boolean) {
    this.activateLicenseOnlineTheoryForm.controls[
      'activateOnlineTheory'
    ].setValue(activateOnlineTheory);
    this.activateLicenseOnlineTheoryForm.controls[
      'activateOnlineTheory'
    ].updateValueAndValidity();
  }

  toggleOnlineTheoryActivated() {
    if (!this.chosenLicenseHasOnlineTheory) {
      return;
    }
    const currentValue =
      this.activateLicenseOnlineTheoryForm.value.activateOnlineTheory;
    this.setActivateOnlineTheory(!currentValue);
  }

  toggleTheoryVideosActivated() {
    const currentValue =
      this.activateTheoryVideosForm.value.activateTheoryVideos;
    this.activateTheoryVideosForm.controls['activateTheoryVideos'].setValue(
      !currentValue
    );
  }

  onAreAllTheoryLessonsBookableChange(checked: boolean) {
    this.areAllTheoryLessonsBookable = checked;
    if (checked && !this.parentLicensePricing) {
      this.licenseService
        .getPlatformSchoolLicensePricing(
          this.school.id,
          this.getChosenLicenseParentId()
        )
        .subscribe(
          (pricing) => {
            this.parentLicensePricing = this.isFastlaneLearner
              ? pricing.fastlaneAmountInCents
              : pricing.amountInCents;
          },
          () =>
            this.notificationService.error(
              Messages.Errors.NoPlatformLicensePriceSet
            )
        );
    }
  }

  onQuit() {
    this.closeDialog(null);
  }

  toPreviousStep() {
    switch (this.activeStep) {
      case Step.CHOOSE_LICENSE:
        return;
      case Step.ACTIVATE_ONLINE_THEORY:
        this.chooseLearnerLicenseForm.controls.license.setValue('');
        this.paymentFlags = LearnerPaymentFlag.getUnconditionalPaymentFlags();
        this.licensePaymentFlagForm.controls.paymentFlag.setValue(null);
        this.activeStep = Step.CHOOSE_LICENSE;
        return;
    }
  }

  private closeDialog(result) {
    this.dialogRef.close(result);
  }

  get buttonText() {
    switch (this.activeStep) {
      case Step.CHOOSE_LICENSE:
        return 'Lizenzen auswählen';
      case Step.ACTIVATE_ONLINE_THEORY:
        return 'Lizenz hinzufügen';
      default:
        return '';
    }
  }

  private setupForms() {
    this.chooseLearnerLicenseForm = new FormGroup({
      license: new FormControl('', [Validators.required]),
    });

    this.licensePaymentFlagForm = new FormGroup({
      paymentFlag: new FormControl(),
    });

    this.activateLicenseOnlineTheoryForm = new FormGroup({
      activateOnlineTheory: new FormControl(true),
    });

    this.activateTheoryVideosForm = new FormGroup({
      activateTheoryVideos: new FormControl(false),
    });
  }

  private addLearnerLicense() {
    const license = this.chooseLearnerLicenseForm.controls.license.value;
    const paymentFlag = this.licensePaymentFlagForm.controls.paymentFlag.value;
    const activateLicenseOnlineTheory =
      this.activateLicenseOnlineTheoryForm.value.activateOnlineTheory;
    const activateTheoryVideos =
      this.activateTheoryVideosForm.value.activateTheoryVideos;

    const learnerLicense: LearnerLicense = {
      learnerId: this.learnerId,
      licenseId: license.id,
      license: license,
      isOnlineTheoryActivated:
        license.hasOnlineTheory && activateLicenseOnlineTheory,
      areTheoryVideosActivated: activateTheoryVideos,
      areAllTheoryLessonsBookable: this.areAllTheoryLessonsBookable,
      paymentFlag: paymentFlag,
    };

    this.closeDialog(learnerLicense);
  }

  private changeActiveStep(step: Step) {
    this.activeStep = step;
  }

  get chosenLicenseNotFullyBookable(): boolean {
    const chosenLicense: License =
      this.chooseLearnerLicenseForm.controls.license.value;

    if (!chosenLicense) {
      return false;
    }

    return [
      'AM',
      'Mofa',
      'A (B vorhanden)',
      'B (A vorhanden)',
      'B (A1 vorhanden)',
    ].includes(chosenLicense.key);
  }

  get parentLicenseAdditionalFee(): number {
    return Math.max(0, this.parentLicensePricing - this.licensePricing);
  }

  getChosenLicenseParentId(): number {
    const chosenLicense: License =
      this.chooseLearnerLicenseForm.controls.license.value;

    if (!chosenLicense) {
      return undefined;
    }

    let parentKey = chosenLicense.group.toString();

    if (chosenLicense.key === 'Mofa') {
      parentKey = 'B (Nur Grundstoffe)';
    }

    return this.licenses.find((license) => license.key === parentKey)?.id;
  }

  getPaymentFlagDisplayName(paymentFlag: LearnerPaymentFlag): string {
    return LearnerPaymentFlag.getDisplayName(paymentFlag);
  }

  get chosenLicenseHasOnlineTheory() {
    const chosenLicense: License =
      this.chooseLearnerLicenseForm.controls.license.value;
    return chosenLicense && chosenLicense.hasOnlineTheory;
  }

  get formIsInvalid() {
    switch (this.activeStep) {
      case Step.CHOOSE_LICENSE:
        return this.chooseLearnerLicenseForm.invalid;
      case Step.ACTIVATE_ONLINE_THEORY:
        return false;
      default:
        return true;
    }
  }
}
