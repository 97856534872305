import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { PageListingResponse } from '@models/page-listing-response.model';
import { AdCampaign, AdCampaignStatus } from '@models/ad-campaign.model';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { Advertiser } from '@models/advertiser.model';

@Injectable()
export class AdvertisingService {
  private readonly campaignsPath = `${env.apiEnrichment}/campaigns`;

  constructor(private api: ApiService) {
  }

  getCampaigns(filters: Map<string, any>,
               pageNumber: number = 1,
               pageSize: number = 10): Observable<PageListingResponse<AdCampaign>> {
    return this.api.getPaged(`${this.campaignsPath}/paged`, pageNumber, pageSize, filters)
      .pipe(
        map(this.mapAds)
      );
  }

  saveCampaign(campaign: AdCampaign): Observable<AdCampaign> {
    return this.api.post<AdCampaign>(this.campaignsPath, campaign)
      .pipe(
        map(res => new AdCampaign(res))
      );
  }

  getCampaign(id: string): Observable<AdCampaign> {
    return this.api.get<AdCampaign>(`${this.campaignsPath}/${id}`)
      .pipe(
        map(res => new AdCampaign(res)));
  }

  getCities() {
    return this.api.get(`${env.apiEnrichment}/cities`);
  }

  getAdvertisers() {
    return this.api.get(`${env.apiEnrichment}/advertisers`);
  }

  private mapAds(res: PageListingResponse): PageListingResponse<AdCampaign> {
    return {
      total: res.total,
      records: res.records.map(it => new AdCampaign(it))
    };
  }

  updateCampaignStatus(campaignID: string, status: AdCampaignStatus): Observable<AdCampaign> {
    const body = {'status': status};
    return this.api.patch<AdCampaign>(`${this.campaignsPath}/${campaignID}`, body)
      .pipe(
        map(res => new AdCampaign(res)));
  }

  saveAdvertiser(customer: Advertiser): Observable<Advertiser> {
    return this.api.post<Advertiser>(`${env.apiEnrichment}/advertisers`, customer);
  }
}
