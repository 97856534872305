<div class="input-cont">
  <div *ngIf="label" class="form-label">
    {{ label }}{{ required ? '*' : '' }}
  </div>
  <ng-select
    [formControlName]="controlName"
    [placeholder]="placeholder"
    [multiple]="multiple"
    class="custom-height-40 ng-select ng-select-single"
    [class.ng-select-focused]="isFocused"
    [clearable]="clearable"
    (change)="onSelectionChange($event)"
    (focus)="onFocus()"
    (blur)="onBlur()"
  >
    <ng-container *ngIf="yet">
      <ng-option *ngFor="let option of options" [value]="option.value">
        {{ option.selected }} {{ option.viewValue }}
      </ng-option>
    </ng-container>
  </ng-select>
  <div style="display: none" *ngIf="!yet">
    <ng-content></ng-content>
  </div>
</div>
