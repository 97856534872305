import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'eddy-gallery-card',
  templateUrl: './gallery-card.component.html',
  styleUrls: ['./gallery-card.component.scss']
})
export class GalleryCardComponent {
  items: number[];

  selectedItem = 1;

  @Input() set itemsCount(itemsCount: number) {
    this.setupItemsArray(itemsCount);
  }

  @Input() justifyPageLinks = 'center';

  @Output() selectItem: EventEmitter<number> = new EventEmitter();

  private setupItemsArray(itemsCount: number) {
    const arr = [];

    for (let i = 0; i < itemsCount; i++) {
      arr.push(i + 1);
    }

    this.items = arr;
  }

  onSelectItem(item: number) {
    this.selectedItem = item;
    this.selectItem.emit(this.selectedItem - 1);
  }

  goToPreviousItem() {
    if (this.selectedItem <= 1) {
      return;
    }

    this.selectedItem--;
    this.selectItem.emit(this.selectedItem - 1);
  }

  goToNextItem() {
    if (this.selectedItem >= this.items.length) {
      return;
    }

    this.selectedItem++;
    this.selectItem.emit(this.selectedItem - 1);
  }
}
