<div class="container">
  <div class="login-container">
    <div class="form-container-left">
      <div class="welcome-container">
        <div class="welcome-header">Willkommen,</div>
        <div class="login-header">Logge Dich ein!</div>
      </div>
      <div class="form-container">
        <form novalidate [formGroup]="form" class="form" (keyup.enter)="onLogin()">
          <div class="form-group schools-login-container">
            <div class="input-container" [class.invalid-password]="isEmailInvalid()">
              <i class="fal fa-envelope mail-icon"></i>
              <div class="input-title">E-mail</div>
              <input #email (change)="updateEmail($event.target.value)" (input)="updateEmail($event.target.value)"
                       class="form-control-input" type="email" autocomplete="username" formControlName="email" placeholder="Gebe Deine E-mail-Adresse ein">
            </div>
            <div class="input-container">
              <i class="fal fa-lock-alt lock-icon"></i>
              <div class="input-title">Passwort</div>
              <input class="form-control-input" id="passwordField" type="{{showPassword ? 'text' : 'password'}}" formControlName="password"
                     autocomplete="current-password" placeholder="Gebe Passwort ein">
              <i (click)="togglePassword()" class="fal eye-icon" [class.fa-eye]="showPassword" [class.fa-eye-slash]="!showPassword"></i>
            </div>
            <div class="forgot-password float-right">
              <a (click)="onForgotPassword()" href="javascript:void(0)"
                 analytics
                 analyticsCategory="link"
                 analyticsAction="forgot-password"
                 analyticsLabel="login-field"
              >Passwort vergessen?</a>
            </div>
            <button type="button" class="success-rounded-btn" (click)="onLogin()"
                    [disabled]="isFormInvalid || loginIsInProgress"
                    [class.disabled]="isFormInvalid || loginIsInProgress"
            >
              Anmelden
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="form-container-right">
      <div class="right-container-content">
        <div class="new-here-title">Neu hier?</div>
        <div class="new-here-student-text">
          Hier findest Du alle Infos zu unserer App und Deinem Führerschein mit dem Eddy Club
        </div>
        <a class="white-rounded-btn" (click)="openSignUpDialog()">Für Fahrschüler</a>
        <div class="new-here-instructor-text">
          Werde jetzt Partner vom Eddy Club und erhalte die drivEddy Software gratis für Deine Fahrschule!
        </div>
        <a class="white-rounded-btn" [href]="instructorLink">Für Fahrlehrer</a>
      </div>
    </div>
  </div>
</div>

<div>

</div>
