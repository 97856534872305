import { TimeRange } from '@models/time-range.model';
import { Day, getDisplayName } from './day.model';
import { toString } from './time-range.model';

export class LessonAttendanceFrequency {
  id: number;
  learnerId: number;

  days: Day[];
  timeRanges: TimeRange[];
  frequency: string;

  constructor(json?) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.learnerId = +json.learnerId;

    this.days = json.days;
    this.timeRanges = json.timeRanges;
    this.frequency = json.frequency;
  }

  get daysOfWeekDisplayText(): string {
    if (!this.days || this.days.length === 0) {
      return '-';
    }

    return this.days.map((day) => getDisplayName(day)).join(', ');
  }

  get timeRangesDisplayText(): string {
    if (!this.timeRanges || this.timeRanges.length === 0) {
      return '-';
    }

    return this.timeRanges.map((timeRange) => toString(timeRange)).join(', ');
  }
}
