import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Asset, emptyAsset } from '@models/asset.model';
import { MatDialog } from '@angular/material/dialog';
import { ImageCroppingDialog } from '@modules/shared/dialogs/image-cropping/image-cropping.dialog';

export enum LogoViewType {
  REGULAR = 'REGULAR',
  IMAGE_LOGO = 'IMAGE_LOGO',
  TEXT_LOGO = 'TEXT_LOGO',
  COVER_IMAGE = 'COVER_IMAGE'
}

export const IMAGE_MEDIA_TYPES = 'image/x-png,image/jpeg';
export const PDF_MEDIA_TYPE = 'application/pdf';
// tslint:disable
const DOCUMENT_MEDIA_TYPES = 'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

@Component({
  selector: 'eddy-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploaderComponent {
  private _selectedFile: Asset;

  @Input() set selectedFile(selectedFile: Asset) {
    this._selectedFile = selectedFile;
    if (!this._selectedFile) {
      this.fileBase64 = null;
    } else if (this._selectedFile.base64) {
      this.fileBase64 = this.inlineImageBase64Prefix + this._selectedFile.base64;
    }
  }

  get selectedFile(): Asset {
    return this._selectedFile;
  }

  @Input() height: number;// = 130;
  @Input() width: number; // = 130;

  @Input() fluidWidth = false;

  @Input() aspectRatio: number;

  @Input() resizeToWidth: number;

  @Input() displayImageCropper: boolean;

  @Input() borderRadius: 0 | 5 | 15 = 0;

  private _uploaderType: 'IMAGE' | 'DOCUMENT' = 'IMAGE';
  fileTypes = IMAGE_MEDIA_TYPES;

  @Input() set uploaderType(uploaderType) {
    this._uploaderType = uploaderType;
    this.setupFileTypes();
  }

  get uploaderType() {
    return this._uploaderType;
  }

  private _logoViewType: LogoViewType = LogoViewType.REGULAR;

  @Input() set logoViewType(logoViewType: LogoViewType) {
    this._logoViewType = logoViewType;
    // if (this._logoViewType === LogoViewType.IMAGE_LOGO) {
    //   this.height = null;
    //   this.width = null;
    // }
  }

  get logoViewType() {
    return this._logoViewType;
  }

  @Input() coverImage: string = null;

  @Input() displayDeleteIcon = false;

  private fileBase64: string;

  @Output() fileChanged: EventEmitter<Asset> = new EventEmitter();

  @Output() deleteFile: EventEmitter<void> = new EventEmitter();

  private readonly base64Prefix = 'base64,';
  private readonly inlineImageBase64Prefix = 'data:image/png;base64,';

  constructor(private dialog: MatDialog,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  fileChangeEvent(event: any): void {
    this.handleFileChange(event.target.files[0]);
  }

  clearFile() {
    this.selectedFile = null;
    this.changeDetectorRef.detectChanges();
  }

  private handleFileChange(file) {
    if (!file) {
      this.clearFile();
      this.fileChanged.emit(this.selectedFile);
      return;
    }
    if (!this.selectedFile) {
      this.selectedFile = emptyAsset();
    }
    this.selectedFile.name = file ? file.name : '';

    if (file && file.type) {
      this.selectedFile.mimeType = file.type;
    }

    if (!this.displayImageCropper) {
      this.loadImage(file);
    } else {
      this.setupImageCroppingDialog(file);
    }
  }

  private loadImage(file) {
    this.getBase64(file).then((base64: string) => {
      this.fileBase64 = base64;
      base64 = base64.substring(base64.indexOf(this.base64Prefix) + this.base64Prefix.length);

      this.selectedFile.base64 = base64;

      this.fileChanged.emit(this.selectedFile);
    });
  }

  private setupImageCroppingDialog(imageFileChanged) {
    const dialogRef = this.dialog.open(ImageCroppingDialog, {
      width: '1000px',
      maxWidth: '100%',
      // height: '600px',
      // panelClass: 'custom-dialog-container',
      data: {imageChangedEvent: event, imageFileChanged: imageFileChanged, aspectRatio: this.aspectRatio, resizeToWidth: this.resizeToWidth}
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.fileBase64 = result.croppedImageBase64;
          const base64 = this.fileBase64.substring(this.fileBase64.indexOf(this.base64Prefix) + this.base64Prefix.length);

          this.selectedFile.base64 = base64;
          this.fileChanged.emit(this.selectedFile);
        } else {
          this.clearFile();
        }
      });
  }

  dropped(event) {
    event.files[0].fileEntry.file(file => this.handleFileChange(file));
  }

  get hasImage(): boolean {
    return this.uploaderType === 'IMAGE' && (this.fileBase64 != null || (this.selectedFile && (this.selectedFile.path != null && this.selectedFile.path !== '')));
  }

  get hasDocument(): boolean {
    return this.uploaderType === 'DOCUMENT' && (this.fileBase64 != null ||
      (this.selectedFile && (this.selectedFile.base64 !== null || (this.selectedFile.path != null && this.selectedFile.path !== ''))));
  }

  onDeleteFile(e) {
    e.preventDefault();
    this.deleteFile.emit();
  }

  private getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  private setupFileTypes() {
    if (this.uploaderType === 'IMAGE') {
      this.fileTypes = IMAGE_MEDIA_TYPES;
    } else {
      this.fileTypes = DOCUMENT_MEDIA_TYPES;
    }
  }
}
