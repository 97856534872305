import { LearnerGuardianAddress } from '@models/learner-guardian-address.model';
import { isValidEmail } from '@validators/email.validator';
import { isValidPhoneNumber } from '@validators/phone.validator';

export class LearnerGuardian {
  id: number;
  learnerId: number;

  firstName: string;
  lastName: string;
  phoneNumber: string;
  dateOfBirth: Date | string;
  email: string;

  address: LearnerGuardianAddress;

  constructor(json) {
    if (!json) {
      this.address = new LearnerGuardianAddress();
      return;
    }

    this.id = +json.id;
    this.learnerId = +json.learnerId;

    this.firstName = json.firstName;
    this.lastName = json.lastName;
    this.phoneNumber = json.phoneNumber;
    this.dateOfBirth = new Date(json.dateOfBirth);
    this.email = json.email;
    this.address = new LearnerGuardianAddress(json.address);
  }

  isFilled(): boolean {
    return (
      !!this.firstName &&
      !!this.lastName &&
      !!this.phoneNumber &&
      !!this.dateOfBirth &&
      !!this.email &&
      this.isAddressFilled()
    );
  }

  isFilledAndValid(): boolean {
    return this.isFilled() && this.isEmailValid() && this.isPhoneNumberValid();
  }

  isEmailValid(): boolean {
    return isValidEmail(this.email);
  }

  isPhoneNumberValid(): boolean {
    return isValidPhoneNumber(this.phoneNumber);
  }

  private isAddressFilled(): boolean {
    return this.address && this.address.isFilled();
  }

  addressText(addHTMLLineBreakBeforePostalCode?: boolean): string {
    if (!this.isAddressFilled()) {
      return '';
    }

    return this.address.toString(addHTMLLineBreakBeforePostalCode);
  }
}
