export class TrainingConfirmationSchoolLearner {
  id: number;

  learnerId: number;

  firstName: string;
  lastName: string;
  email: string;

  schoolId: number;
  schoolName?: string;

  licenses: string[];
  startDate: Date;
  numberOfAttendedLessons: number;

  constructor(json?: any) {
      if (!json) {
        return;
      }

      this.id = +json.id;
      this.learnerId = +json.learnerId;
      this.firstName = json.firstName;
      this.lastName = json.lastName;
      this.email = json.email;

      this.schoolId = +json.schoolId;
      if (json.schoolName) {
        this.schoolName = json.schoolName;
      }

      this.licenses = json.licenses;
      this.startDate = new Date(json.startDate);
      this.numberOfAttendedLessons = +json.numberOfAttendedLessons;
  }

  getDisplayName(): string {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    }

    if (this.firstName) {
      return this.firstName;
    }

    if (this.lastName) {
      return this.lastName;
    }

    if (this.email) {
      return this.email;
    }

    return '';
  }

  learnLicensesDisplayName(): string {
    if (!this.licenses.length) {
      return '';
    }

    return this.licenses.join(', ');
  }
}
