export class TheoryLessonTopicSubtitle {
  theoryLessonTopicKey: string;

  label: string;
  language: string;
  url: string;

  constructor(json: any) {
    this.theoryLessonTopicKey = json.theoryLessonTopicKey;

    this.label = json.label;
    this.language = json.language;
    this.url = json.url;
  }
}
