import { TheoryLesson } from '@models/theory-lesson.model';
import { Learner } from '@models/learner.model';
import { PaymentStatus } from '@models/payment-status.model';

export enum TheoryLessonAttendeeStatus {
  REQUESTED = 'REQUESTED',
  PRESENT = 'PRESENT',
  PRESENCE_FAILURE = 'PRESENCE_FAILURE',
  LESSON_WATCHED = 'LESSON_WATCHED',
  CONFIRMED = 'CONFIRMED',
  REMOVED = 'REMOVED',
  REJECTED = 'REJECTED'
}

export function getDisplayName(status: TheoryLessonAttendeeStatus): string {
  switch (status) {
    case TheoryLessonAttendeeStatus.REQUESTED:
      return 'Angefragt';
    case TheoryLessonAttendeeStatus.PRESENT:
      return 'Anwesend';
    case TheoryLessonAttendeeStatus.PRESENCE_FAILURE:
      return 'Nicht anwesend';
    case TheoryLessonAttendeeStatus.LESSON_WATCHED:
      return 'Abgeschlossen';
    case TheoryLessonAttendeeStatus.CONFIRMED:
      return 'Bestätigt';
    case TheoryLessonAttendeeStatus.REJECTED:
      return 'Abgelehnt';
    case TheoryLessonAttendeeStatus.REMOVED:
      return 'Entfernt';
    default:
      return '';
  }
}

export class TheoryLessonAttendee {
  id: number;

  theoryLessonId: number;
  theoryLesson?: TheoryLesson;

  learnerId: number;
  learner?: Learner;

  status: TheoryLessonAttendeeStatus;
  learnerLessonRating: number;
  learnerFeedback: string;

  videoCurrentTimeInSeconds?: number;

  paymentStatus?: PaymentStatus;

  numPresenceConfirmations?: number;

  constructor(json) {
    this.id = +json.id;

    this.theoryLessonId = +json.theoryLessonId;
    if (json.theoryLesson) {
      this.theoryLesson = new TheoryLesson(json.theoryLesson);
    }

    this.learnerId = +json.learnerId;
    if (json.learner) {
      this.learner = new Learner(json.learner);
    }

    this.status = json.status;
    if (json.learnerLessonRating) {
      this.learnerLessonRating = +json.learnerLessonRating;
    }
    this.learnerFeedback = json.learnerFeedback;

    if (json.videoCurrentTimeInSeconds) {
      this.videoCurrentTimeInSeconds = +json.videoCurrentTimeInSeconds;
    }
    if (json.paymentStatus) {
      this.paymentStatus = json.paymentStatus;
    }
    if (!isNaN(+json.numPresenceConfirmations)) {
      this.numPresenceConfirmations = +json.numPresenceConfirmations;
    }
  }

  isInThePast(): boolean {
    return this.theoryLesson.event.startDate.getTime() < Date.now();
  }

  isWatchedOrConfirmed(): boolean {
    return this.isWatched() || this.isConfirmed();
  }

  isConfirmed(): boolean {
    return this.status === TheoryLessonAttendeeStatus.CONFIRMED;
  }

  isRequested(): boolean {
    return this.status === TheoryLessonAttendeeStatus.REQUESTED;
  }

  isPresent(): boolean {
    return this.status === TheoryLessonAttendeeStatus.PRESENT;
  }

  isWatched(): boolean {
    return this.status === TheoryLessonAttendeeStatus.LESSON_WATCHED;
  }

  isFailedPresence(): boolean {
    return this.status === TheoryLessonAttendeeStatus.PRESENCE_FAILURE;
  }

  isPresentOrFailedPresence(): boolean {
    return this.status === TheoryLessonAttendeeStatus.PRESENT || this.status === TheoryLessonAttendeeStatus.PRESENCE_FAILURE;
  }

  isRemoved(): boolean {
    return this.status === TheoryLessonAttendeeStatus.REMOVED;
  }

  isPaid(): boolean {
    return this.paymentStatus === PaymentStatus.PAID;
  }

  isLate(): boolean {
    return this.status === TheoryLessonAttendeeStatus.REQUESTED && this.secondsSinceLessonStart > (10 * 60);
  }

  get secondsSinceLessonStart() {
    return Math.floor((new Date().getTime() - this.theoryLesson.startDate.getTime()) / 1000);
  }

  get instructorFullName(): string {
    if (!this.theoryLesson) {
      return '';
    }

    return this.theoryLesson.instructorFullName;
  }

  get learnerDisplayName(): string {
    if (!this.learner) {
      return '';
    }

    return this.learner.getDisplayName();
  }


  get startDate(): Date | null {
    if (!this.theoryLesson) {
      return null;
    }

    return this.theoryLesson.startDate;
  }

  get endDate(): Date | null {
    if (!this.theoryLesson) {
      return null;
    }

    return this.theoryLesson.endDate;
  }

  get videoCurrentTime(): string {
    if (!this.videoCurrentTimeInSeconds) {
      return '';
    }

    return `${this.videoCurrentTimeMinutesString}:${this.videoCurrentTimeSecondsString}`;
  }

  private get videoCurrentTimeMinutesString(): string {
    return '' + Math.floor(this.videoCurrentTimeInSeconds / 60);
  }

  private get videoCurrentTimeSecondsString(): string {
    const seconds = Math.floor(this.videoCurrentTimeInSeconds % 60);
    if (seconds < 10) {
      return `0${seconds}`;
    }

    return `${seconds}`;
  }
}

export class TheoryLessonAttendeeUser {
  firstName: string;
  lastName: string;
  email: string;

  constructor(json?: any) {
    this.email = json.email;

    if (json.firstName) {
      this.firstName = json.firstName;
    }
    if (json.lastName) {
      this.lastName = json.lastName;
    }
  }

  getDisplayName(): string {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    }

    if (this.firstName) {
      return `${this.firstName} (${this.email})`;
    }

    if (this.lastName) {
      return `${this.lastName} (${this.email})`;
    }

    return this.email;
  }
}
