import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'eddy-image-cropping-dialog',
  templateUrl: './image-cropping.dialog.html',
  styleUrls: ['./image-cropping.dialog.scss']
})
export class ImageCroppingDialog {
  imageChangedEvent: any = '';
  private croppedImageBase64: string;
  aspectRatio: number;
  resizeToWidth: number;
  imageFileChanged: any;

  constructor(
    public dialogRef: MatDialogRef<ImageCroppingDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.imageChangedEvent = data.imageChangedEvent;
    this.imageFileChanged = data.imageFileChanged;
    this.aspectRatio = data.aspectRatio;
    this.resizeToWidth = data.resizeToWidth;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImageBase64 = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    // show message
  }

  closeDialog() {
    this.dialogRef.close({
      'croppedImageBase64': this.croppedImageBase64
    });
  }
}

