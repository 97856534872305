import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PageListingResponse } from '@models/page-listing-response.model';
import { BlobWithFileName } from '@models/blob-with-filename.model';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  get<T = any>(
    url: string,
    queryParams?: Map<string, string>,
    addAuthorizationHeader: boolean = true
  ): Observable<T> {
    return this.http
      .get<T>(
        url,
        this.authService.getHttpOptions(queryParams, addAuthorizationHeader)
      )
      .pipe(
        map((res: any) => res['data']),
        catchError((err: any) => {
          this.checkUnauthorized(err);
          return throwError(err.error);
        })
      );
  }

  getBlob<Blob>(
    url: string,
    queryParams?: Map<string, string>,
    addAuthorizationHeader: boolean = true
  ): Observable<Blob> {
    const httpOptions: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType: 'blob';
      withCredentials?: boolean;
    } = this.authService.getHttpOptions(
      queryParams,
      addAuthorizationHeader,
      'blob'
    );

    return this.http.get(url, httpOptions).pipe(
      map((res: any) => res),
      catchError((err: any) => {
        this.checkUnauthorized(err);
        return throwError(err.error);
      })
    );
  }

  getBlobWithFileName<Blob>(
    url: string,
    queryParams?: Map<string, string>,
    addAuthorizationHeader: boolean = true
  ): Observable<BlobWithFileName> {
    const httpOptions: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType: 'blob';
      withCredentials?: boolean;
    } = this.authService.getHttpOptions(
      queryParams,
      addAuthorizationHeader,
      'blob'
    );

    httpOptions.observe = 'response' as 'body';

    return this.http.get(url, httpOptions).pipe(
      map((res: any) => {
        const filename = res.headers
          .get('content-disposition')
          .split(';')[1]
          .split('=')[1]
          .replace(/\"/g, '');
        const blobWithFileName: BlobWithFileName = {
          blob: res.body,
          fileName: filename,
        };
        return blobWithFileName;
      }),
      catchError((err: any) => {
        this.checkUnauthorized(err);
        return throwError(err.error);
      })
    );
  }

  postBlob<Blob>(
    url: string,
    body: any,
    queryParams?: Map<string, string>,
    addAuthorizationHeader: boolean = true
  ): Observable<Blob> {
    const httpOptions: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType: 'blob';
      withCredentials?: boolean;
    } = this.authService.getHttpOptions(
      queryParams,
      addAuthorizationHeader,
      'blob'
    );

    return this.http.post(url, body, httpOptions).pipe(
      map((res: any) => res),
      catchError((err: any) => {
        this.checkUnauthorized(err);
        return throwError(err.error);
      })
    );
  }

  getPaged<T = any>(
    url: string,
    pageNumber: number,
    pageSize: number,
    filters?: Map<string, any>
  ): Observable<PageListingResponse<T>> {
    if (!filters) {
      filters = new Map<string, any>();
    }
    const params = filters
      .set('pageNumber', '' + pageNumber)
      .set('pageSize', '' + pageSize);

    return this.http.get<T>(url, this.authService.getHttpOptions(params)).pipe(
      map((res: any) => res['data']),
      catchError((err: any) => {
        this.checkUnauthorized(err);
        return throwError(err.error);
      })
    );
  }

  put<T = any>(url: string, body: any): Observable<T> {
    return this.http.put<T>(url, body, this.authService.getHttpOptions()).pipe(
      map((res: any) => res['data']),
      catchError((err: any) => {
        this.checkUnauthorized(err);
        return throwError(err.error);
      })
    );
  }

  post<T = any>(url: string, body: any, parameters: Map<string, string> = null): Observable<T> {
    return this.http.post<T>(url, body, this.authService.getHttpOptions(parameters)).pipe(
      map((res: any) => res['data']),
      catchError((err: any) => {
        this.checkUnauthorized(err);
        return throwError(err.error);
      })
    );
  }

  postFile<T = any>(url: string, body: any): Observable<T> {
    const httpOptions = this.authService.getHttpOptions();
    return this.http.post<T>(url, body, httpOptions).pipe(
      map((res: any) => res['data']),
      catchError((err: any) => {
        this.checkUnauthorized(err);
        return throwError(err.error);
      })
    );
  }

  delete<T = any>(url: string): Observable<T> {
    return this.http.delete<T>(url, this.authService.getHttpOptions()).pipe(
      map((res: any) => res['data']),
      catchError((err: any) => {
        this.checkUnauthorized(err);
        return throwError(err.error);
      })
    );
  }

  patch<T = any>(url: string, body: any): Observable<T> {
    return this.http
      .patch<T>(url, body, this.authService.getHttpOptions())
      .pipe(
        map((res: any) => res['data']),
        catchError((err: any) => {
          this.checkUnauthorized(err);
          return throwError(err.error);
        })
      );
  }

  patchFile<T = any>(url: string, body: any): Observable<T> {
    const httpOptions = this.authService.getHttpOptions();
    return this.http.patch<T>(url, body, httpOptions).pipe(
      map((res: any) => res['data']),
      catchError((err: any) => {
        this.checkUnauthorized(err);
        return throwError(err.error);
      })
    );
  }

  head<T = any>(
    url: string,
    queryParams?: Map<string, string>,
    addAuthorizationHeader: boolean = true
  ): Observable<T> {
    const httpOptions: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    } = this.authService.getHttpOptions(queryParams, addAuthorizationHeader);

    return this.http.head<T>(url, httpOptions);
  }

  private checkUnauthorized(err: any) {
    if (err?.status === 401) {
      this.authService.logout();
    }
  }
}
