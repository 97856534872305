import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'eddy-ok-dialog',
  templateUrl: './ok-dialog.component.html',
  styleUrls: ['./ok-dialog.component.scss'],
})
export class OkDialogComponent implements OnInit {
  message: string;
  messageHtml: string;
  info: string;
  buttonLabel: string;
  title: string;
  disableCloseBtn: boolean;
  imgSrc: string;

  constructor(
    private dialogRef: MatDialogRef<OkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.message = this.data.message;
    this.messageHtml = this.data.messageHtml;
    this.buttonLabel = this.data.buttonLabel || 'OK';
    this.info = this.data.info || false;
    this.title = this.data.title || 'Informationen';
    this.disableCloseBtn = this.data.disableCloseBtn;
    this.imgSrc = this.data.imgSrc;
  }

  ngOnInit() {}

  closeDialog(val: any) {
    this.dialogRef.close(val);
  }
}
