<div class="dialog-container">
	<div id="title-container" mat-dialog-title>
		
		<ng-container *ngIf="stepNumber == 0">
			<div class="step-headline">Willkommen</div>
		</ng-container>
		<ng-container *ngIf="stepNumber == 1">
			<div class="step-headline">Vertrag unterschreiben</div>
		</ng-container>
		<ng-container *ngIf="stepNumber == 2">
			<div class="step-headline">Vertrag erfolgreich unterschrieben</div>
		</ng-container>
		<ng-container *ngIf="stepNumber == 3">
			<div class="step-headline">Perfekt</div>
		</ng-container>
		<span (click)="closeDialog(null)" class="close-button">
			<i class="fal fa-times"></i>
		</span>
	</div>
	<mat-dialog-content>
		<div id="main-content-step1" [hidden]="stepNumber != 0">
			<div class="video">
				<eddy-video-player #videoPlayer
					videoUrl="https://static.driveddy.com/videos/drivEddy_b2c_vertragsfeature.mp4"
					[showControls]="true">
				</eddy-video-player>
			</div>
			<div class="step-content">
				Wir freuen uns, dass du deine Ausbildung <ng-container *ngIf="contractType === 'CONTRACT'; else schoolContract">mit uns absolvierst</ng-container><ng-template #schoolContract>mit der Fahrschule {{ schoolName }} absolvierst</ng-template>.<br>
				Im nächsten Schritt überprüfe bitte deinen Ausbildungsvertrag und unterschreibe ihn, <br>
				damit wir dir einen gültigen Ausbildungsnachweis ausstellen können.<br>
				<br>
				Bei Fragen kannst du dich gerne jederzeit unter <br>
				<strong>+49 (0)30 4036 6610-0</strong> an uns wenden.
			</div>
		</div>
		<div id="main-content-step2" [hidden]="stepNumber != 1">
			<div class="preview-container">
				<iframe #previewContainer 
					src="" 
					type="application/pdf" 
					style="overflow: auto;" 
					type="application/pdf"
					frameborder="0"
					
				></iframe>
			</div>
		</div>
		<div id="main-content-step3" [hidden]="stepNumber != 2">
			<div class="error-text">
				Leider gab es einen Fehler beim Generieren des Vertrags.<br>
				Bitte versuche es erneut
			</div>
		</div>
		<div id="main-content-step4" [hidden]="stepNumber != 3">
			<div class="content">
				Vielen Dank für deine Unterschrift. Wir haben den Vertrag in deinem Profil unter Dokumente abgespeichert, wo du ihn jederzeit einsehen kannst. Viele Erfolg bei deiner Theorie.
			</div>
			<div class="download-contract">
				<div class="icon"><img src="/assets/icons/sign_contract.svg"></div>
				<div class="label">
					<span class="label-title">Vertrag</span>
					<span class="label-date">{{ contractDate }}</span>
				</div>
				<div class="button">
					<button (click)="downloadContract()" class="blue-cornerd-btn">Download</button>
				</div>
			</div>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions id="buttons-container" *ngIf="stepNumber != 1">
		<ng-container *ngIf="stepNumber == 0">
			<ng-container *ngIf="generatingContract; else toContractBtn">
				<button class="center disabled generating" disabled="disabled">Vertrag wird generiert</button>
			</ng-container>
			<ng-template #toContractBtn>
				<button (click)="nextStep()" class="center">Zum Vertrag</button>
			</ng-template>
		</ng-container>
		<ng-container *ngIf="stepNumber == 2">
			<button (click)="nextStep(1)">Erneut versuchen</button>
		</ng-container>
		<ng-container *ngIf="stepNumber == 3">
			<button (click)="closeDialog(true)" class="center">Zurück zum Dashboard</button>
		</ng-container>
	</mat-dialog-actions>
	<mat-dialog-actions id="signature-container" [hidden]="stepNumber != 1">
		<div class="signature-row">
			<div class="signature-field learner-signature" #canvasSignatureLearnerContainer>
				<button class="clear-signature" (click)="clearSignatureLearner()" ><i class="fas fa-eraser"></i></button>
				<div class="signature-label">Bitte hier unterschreiben</div>
				<canvas #canvasSingatureLearner ></canvas>
			</div>
			<div class="signature-field guardian-signature" [hidden]="learner.isOver18()" #canvasSignatureGuardianContainer>
				<button class="clear-signature" (click)="clearSignatureGuardian()" ><i class="fas fa-eraser"></i></button>
				<div class="signature-label">Unterschrift Erziehungsberechtigter</div>
				<canvas #canvasSingatureGuardian></canvas>
			</div>
			<div class="continue-box button-container">
				<div>
					Deine Daten stimmen nicht? <br>
					Dann passe sie hier an <a href="/learners/personal"><i class="fal fa-arrow-right"></i></a><br>
					Du brauchst Hilfe? Ruf uns an: <br>
					<strong>+49 (0)30 4036 6610-0</strong>
				</div>
				<button (click)="contractSigned()" [class.generating]="generatingContract" [disabled]="submitContractDisabled">Vertrag abschicken</button>
			</div>
		</div>
	</mat-dialog-actions>
</div>
