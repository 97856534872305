import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NotificationService {
  private readonly DEFAULT_SAVE_SUCCESS_MESSAGE = 'Änderungen gespeichert.';
  private readonly DEFAULT_SAVE_ERROR_MESSAGE = 'Änderungen wurden nicht gespeichert, bitte erneut versuchen.';
  private readonly DEFAULT_LOAD_ERROR_MESSAGE = 'Ein interner Serverfehler ist aufgetreten.';
  private readonly DEFAULT_FORM_DATA_INVALID_ERROR = 'Die Formulardaten sind nicht gültig';

  constructor(private toastr: ToastrService) {
  }

  saveSuccess() {
    this.success(this.DEFAULT_SAVE_SUCCESS_MESSAGE);
  }

  success(message: string) {
    this.toastr.success(message, undefined, this.toastrConfig);
  }

  saveError() {
    this.error(this.DEFAULT_SAVE_ERROR_MESSAGE);
  }

  error(message: string) {
    this.toastr.error(message, undefined, this.toastrConfig);
  }

  loadError() {
    this.error(this.DEFAULT_LOAD_ERROR_MESSAGE);
  }

  warning(message: string) {
    this.toastr.warning(message, undefined, this.toastrConfig);
  }

  formDataInvalidError() {
    this.error(this.DEFAULT_FORM_DATA_INVALID_ERROR);
  }

  private get toastrConfig() {
    return {
      timeOut: 2500,
      progressBar: true,
    };
  }
}
