export enum SchoolLearnerStatus {
  ONBOARDING_REQUESTED = 'ONBOARDING_REQUESTED',
  INCOMPLETE_PROFILE = 'INCOMPLETE_PROFILE',
  WAITING_FOR_CONTRACT_SIGNING = 'WAITING_FOR_CONTRACT_SIGNING',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
  COMPLETED = 'COMPLETED',
  DELETED = 'DELETED'
}

export namespace SchoolLearnerStatus {
  export function isBeforeSigningContract(status: SchoolLearnerStatus) {
    return status === SchoolLearnerStatus.INCOMPLETE_PROFILE
      || status === SchoolLearnerStatus.WAITING_FOR_CONTRACT_SIGNING;
  }

  export function isArchivedStatus(status: SchoolLearnerStatus) {
    return status === SchoolLearnerStatus.ARCHIVED;
  }

  export function isCompletedStatus(status: SchoolLearnerStatus) {
    return status === SchoolLearnerStatus.COMPLETED;
  }

  export function isIncompleteProfileStatus(status: SchoolLearnerStatus) {
    return status === SchoolLearnerStatus.INCOMPLETE_PROFILE;
  }

  export function getAllSchoolLearnerStatuses(): SchoolLearnerStatus[] {
    return [SchoolLearnerStatus.INCOMPLETE_PROFILE,
    SchoolLearnerStatus.WAITING_FOR_CONTRACT_SIGNING,
    SchoolLearnerStatus.CONTRACT_SIGNED,
    SchoolLearnerStatus.ACTIVE,
    SchoolLearnerStatus.INACTIVE,
    SchoolLearnerStatus.ARCHIVED,
    SchoolLearnerStatus.COMPLETED,
    ];
  }

  export function getDisplayName(status: SchoolLearnerStatus): string {
    switch (status) {
      case SchoolLearnerStatus.INCOMPLETE_PROFILE:
        return 'Profil unvollständig';
      case SchoolLearnerStatus.WAITING_FOR_CONTRACT_SIGNING:
        return 'Warten auf Vertragsunterzeichnung';
      case SchoolLearnerStatus.CONTRACT_SIGNED:
        return 'Vertrag unterzeichnet';
      case SchoolLearnerStatus.ACTIVE:
        return 'Aktiv';
      case SchoolLearnerStatus.INACTIVE:
        return 'Inaktiv';
      case SchoolLearnerStatus.ARCHIVED:
        return 'Archiviert';
      case SchoolLearnerStatus.COMPLETED:
        return 'Abgeschlossen';
      case SchoolLearnerStatus.DELETED:
        return 'Gelöscht';
      default:
        return '';
    }
  }

  export function getBackgroundHexColor(status: SchoolLearnerStatus): string {
    switch (status) {
      case SchoolLearnerStatus.INCOMPLETE_PROFILE:
        return '#2b8dd6';
      case SchoolLearnerStatus.WAITING_FOR_CONTRACT_SIGNING:
        return '#27b8a1';
      case SchoolLearnerStatus.CONTRACT_SIGNED:
        return '#303f60';
      case SchoolLearnerStatus.ACTIVE:
        return '#f2d63d';
      case SchoolLearnerStatus.INACTIVE:
        return '#666';
      case SchoolLearnerStatus.ARCHIVED:
        return '#484848';
      case SchoolLearnerStatus.COMPLETED:
        return '#33c78f';
      default:
        return '';
    }
  }

  export function getFontHexColor(status: SchoolLearnerStatus): string {
    switch (status) {
      case SchoolLearnerStatus.INCOMPLETE_PROFILE:
      case SchoolLearnerStatus.WAITING_FOR_CONTRACT_SIGNING:
      case SchoolLearnerStatus.CONTRACT_SIGNED:
      case SchoolLearnerStatus.INACTIVE:
      case SchoolLearnerStatus.ARCHIVED:
      case SchoolLearnerStatus.COMPLETED:
        return '#fff';
      case SchoolLearnerStatus.ACTIVE:
        return '#303f60';
      default:
        return '';
    }
  }
}
