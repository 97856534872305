<div class="app-buttons-container {{alignment}} {{theme}} {{lineType}}">
  <div>
    <button (click)="openAppStoreApp()"
            analytics
            analyticsCategory="ios"
            analyticsAction="download"
            [analyticsLabel]="eventLabel">
      <div class="mobile-store">
      <span>
        <i class="fab fa-apple store-icon"></i>
      </span>
        <div class="download-on">Download on the</div>
        <br/>
        <div class="mobile-app-store">App Store</div>
      </div>
    </button>
  </div>
  <div id="spacer"></div>
  <div id="google-play">
    <button (click)="openGooglePlayApp()"
            analytics
            analyticsCategory="android"
            analyticsAction="download"
            [analyticsLabel]="eventLabel">
      <div class="mobile-store google-mobile-store">
      <span>
        <i class="fab fa-google-play store-icon"></i>
      </span>
        <div class="download-on">Get it on</div>
        <br/>
        <div class="mobile-app-store google-play">Google Play</div>
      </div>
    </button>
  </div>
</div>
