<div class="d-flex">
  <i class="fal fa-chevron-left select-previous" (click)="toPrevious()"></i>

  <input [(ngModel)]="dateRange" type="text" class="range-input" readonly ngxDaterangepickerMd
         [minDate]="minDate"
         [maxDate]="maxDate"
         [placeholder]="placeholder" (change)="change($event)"/>

  <div class="datepicker-icon-wrapper">
    <i class="fal fa-calendar-alt datepicker-icon" (click)="openDateRangePicker($event)"></i>
  </div>

  <i class="fal fa-chevron-right select-next" (click)="toNext()"></i>
</div>
