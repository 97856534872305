import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {TheoryLessonService} from '@services/theory-lesson.service';
import {Ratings} from '@models/ratings.model';

@Component({
  selector: 'eddy-online-theory-testimonials',
  templateUrl: './online-theory-testimonials.component.html',
  styleUrls: ['./online-theory-testimonials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnlineTheoryTestimonialsComponent implements OnInit {
  @Input() generalTestimonials: boolean;

  ratings?: Ratings;

  constructor(
    private theoryLessonService: TheoryLessonService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.theoryLessonService.getOnlineTheoryRatings()
      .subscribe(ratings => {
        this.ratings = ratings;
        this.changeDetectorRef.detectChanges();
      });
  }
}
