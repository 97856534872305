import { Component, EventEmitter, Output, Input } from '@angular/core';
import { getAllLearnerTypes, getDisplayName, LearnerType } from '@models/learner-type.model';

@Component({
  selector: 'eddy-learner-type-dropdown',
  templateUrl: 'eddy-learner-type-dropdown.component.html',
  styleUrls: ['./eddy-learner-type-dropdown.component.scss']
})
export class EddyLearnerTypeDropdownComponent {

  learnerTypes: LearnerType[] = getAllLearnerTypes();

  @Input() fullWidth: boolean;

  @Output() learnerTypeChanged: EventEmitter<LearnerType> = new EventEmitter<LearnerType>();

  onChange(learnerType: LearnerType) {
    this.learnerTypeChanged.emit(learnerType);
  }

  getLearnerTypeDisplayName(learnerType: LearnerType) {
    return getDisplayName(learnerType);
  }
}
