export class Address {
  id: number;

  label: string;
  lat: number;
  lng: number;

  streetNumber: string;
  street: string;
  postalCode: string;
  locality: string;
  sublocality: string;
  administrativeAreaLevel1: string;
  country: string;
  cityCode: string;
  countryCode: string;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.label = json.label;
    this.lat = +json.lat;
    this.lng = +json.lng;

    this.street = json.street;
    this.streetNumber = json.streetNumber;
    this.postalCode = json.postalCode;
    this.locality = json.locality;
    this.sublocality = json.sublocality;
    this.administrativeAreaLevel1 = json.administrativeAreaLevel1;
    this.country = json.country;
    this.cityCode = json.cityCode;
    this.countryCode = json.countryCode;
  }

  isFilled(): boolean {
    return !!this.street && !!this.streetNumber && !!this.postalCode && !!this.locality;
  }

  toString(addHTMLLineBreakBeforePostalCode?: boolean): string {
    if (addHTMLLineBreakBeforePostalCode) {
      return `${this.street} ${this.streetNumber},<br/> ${this.postalCode} ${this.locality}`;
    }

    return `${this.street} ${this.streetNumber}, ${this.postalCode} ${this.locality}`;
  }

  get latLng(): string {
    return `${this.lat}, ${this.lng}`;
  }
}

export function emptyAddress(): Address {
  const newAddress = new Address();
  newAddress.street = '';
  newAddress.streetNumber = '';
  newAddress.postalCode = '';
  newAddress.locality = '';
  newAddress.country = 'Deutschland';

  return newAddress;
}
