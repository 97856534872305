import { emptyTime, isBefore, isFilled as isTimeFilled, Time, toString as timeToString } from '@models/time.model';

export interface TimeRange {
  startTime: Time | string;
  endTime: Time | string;
}

export function emptyTimeRange(): TimeRange {
  return {
    startTime: emptyTime(),
    endTime: emptyTime(),
  };
}

export function toString(timeRange: TimeRange): string {
  const startTimeString = (typeof timeRange.startTime === 'string') ? timeRange.startTime : timeToString(<Time>timeRange.startTime);
  const endTimeString = (typeof timeRange.endTime === 'string') ? timeRange.endTime : timeToString(<Time>timeRange.endTime);

  return `${startTimeString} - ${endTimeString}`;
}

export function isFilled(timeRange: TimeRange): boolean {
  return isTimeFilled(<Time>timeRange.startTime) && isTimeFilled(<Time>timeRange.endTime);
}

const MINUTES_IN_DAY = 1440;

export function getLengthInMinutes(timeRange: TimeRange): number {
  const startTime = <Time>timeRange.startTime;
  const endTime = <Time>timeRange.endTime;

  const startMinutes = startTime.hour * 60 + startTime.minute;
  const endMinutes = endTime.hour * 60 + endTime.minute;

  const diff = endMinutes - startMinutes;

  if (isNaN(diff)) {
    return 0;
  }

  if (!isBefore(startTime, endTime)) {
    return MINUTES_IN_DAY - Math.abs(diff);
  }

  return diff;
}

export function convertTimeRangesToStrings(timeRanges: TimeRange[]): TimeRange[] {
  return timeRanges.map(timeRange => convertTimeRangeTimesToStrings(timeRange));
}

export function convertTimeRangeTimesToStrings(timeRange: TimeRange): TimeRange {
  return {
    startTime: timeToString(<Time>timeRange.startTime, true),
    endTime: timeToString(<Time>timeRange.endTime, true),
  };
}

