import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from '@validators/email.validator';

@Component({
  selector: 'eddy-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialogComponent implements OnInit {

  form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
  ) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', [emailValidator, Validators.required]),
    });
  }

  onReset() {
    this.dialogRef.close(this.form.value.email);
  }

  onQuit() {
    this.dialogRef.close(null);
  }

}
