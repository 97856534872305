import { Component, Input } from '@angular/core';

@Component({
  selector: 'eddy-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss']
})
export class AccordionItemComponent {

  @Input() open = true;

  constructor() {
  }

  toggleOpenState() {
    this.open = !this.open;
  }
}
