import { Address } from './address.model';
import { LearnerPaymentFlag } from './learner-payment-flag.model';

export class LicenseItem {
  id: number;

  licenseId: number;
  title: string;
  subtitle: string;
  order: number;

  readonly?: boolean;

  constructor(json) {
    this.id = +json.id;
    this.licenseId = +json.licenseId;
    this.title = json.title;
    this.subtitle = json.subtitle;
    this.order = +json.order;

    if (json.readonly !== undefined) {
      this.readonly = !!json.readonly;
    }
  }

  get isSettingsItem() {
    return (
      this.title === 'Sonstiges' &&
      (this.isFirstAidItem || this.isSightTestItem)
    );
  }

  get isFirstAidItem() {
    return this.subtitle.startsWith('- Erstehilfe bei');
  }

  get isSightTestItem() {
    return this.subtitle.startsWith('- Sehtest bei');
  }
}

export class SchoolLicenseItemPrice {
  id: number;

  schoolLicensePricingId: number;

  licenseItem: LicenseItem;

  priceInCents: number;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.schoolLicensePricingId = +json.schoolLicensePricingId;
    this.licenseItem = new LicenseItem(json.licenseItem);
    this.priceInCents = +json.priceInCents;
  }
}

export class License {
  id: number;
  group: LicenseGroup;
  key: string;
  title: string;
  description: string;
  minimumAgeDescription: string;
  includedLicenseKeys: string[];
  requiredLicenseKeys: string[];
  hasOnlineTheory: boolean;
  order: number;

  items: LicenseItem[];

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.group = json.group;
    this.key = json.key;
    this.title = json.title;
    this.description = json.description;
    this.minimumAgeDescription = json.minimumAgeDescription;
    this.includedLicenseKeys = json.includedLicenseKeys;
    this.requiredLicenseKeys = json.requiredLicenseKeys;
    this.hasOnlineTheory = json.hasOnlineTheory;
    this.order = +json.order;
    this.items = this.mapItems(json.items || []);
  }

  hasLicenseB(): boolean {
    return (
      (this.group === 'B' ||
        this.key === 'A+B' ||
        this.key === 'A1+B' ||
        this.key === 'A2+B') &&
      this.key !== 'B196'
    );
  }

  private mapItems(items): LicenseItem[] {
    return items.map((it) => new LicenseItem(it));
  }
}

export enum LicenseGroup {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  LT = 'L+T',
}

export class SchoolLicensePricing {
  id: number;

  licensePriceListId: number;
  schoolId?: number;

  licenseId: number;
  license: License;

  displayOnSchoolProfile: boolean;

  prices: SchoolLicenseItemPrice[];
  oldPrices?: SchoolLicenseItemPrice[];

  editablePrices: boolean;

  activeTo?: Date;
  isAccepted?: boolean;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.licensePriceListId = +json.licensePriceListId;
    if (json.schoolId !== undefined) {
      this.schoolId = +json.schoolId;
    }
    this.licenseId = +json.licenseId;
    this.license = new License(json.license);

    this.displayOnSchoolProfile = !!json.displayOnSchoolProfile;

    this.prices = this.mapPrices(json.prices || []);
    if (json.oldPrices) {
      this.oldPrices = this.mapPrices(json.oldPrices || []);
    }

    this.editablePrices = !!json.editablePrices;

    if (json.activeTo !== undefined) {
      this.activeTo = new Date(json.activeTo);
    }

    if (json.isAccepted !== undefined) {
      this.isAccepted = !!json.isAccepted;
    }
  }

  private mapPrices(prices) {
    return prices.map((it) => new SchoolLicenseItemPrice(it));
  }

  get licenseKey(): string {
    return this.license?.key;
  }

  isPending(): boolean {
    return this.isAccepted === undefined || this.isAccepted === false;
  }

  isCurrent(): boolean {
    return this.activeTo === undefined && this.isAccepted === true;
  }

  getAvailablePaymentFlags(): LearnerPaymentFlag[] {
    const paymentFlags = LearnerPaymentFlag.getUnconditionalPaymentFlags();
    if (!this.prices || this.prices.length === 0) {
      return paymentFlags;
    }

    if (
      this.prices.find(
        (price) =>
          price.licenseItem.subtitle ===
          '- für die allgemeinen Aufwendungen einschließlich des Unterrichts'
      )
    ) {
      paymentFlags.push(LearnerPaymentFlag.FULL_PACKAGE);
    }
    if (
      this.prices.find(
        (price) =>
          price.licenseItem.subtitle ===
          '- für die allgemeinen Aufwendungen einschließlich des Unterrichts bei Fahrschulwechsel'
      )
    ) {
      paymentFlags.push(LearnerPaymentFlag.SCHOOL_CHANGER);
    }
    if (
      this.prices.find(
        (price) =>
          price.licenseItem.subtitle ===
          '- für die allgemeinen Aufwendungen einschließlich des Unterrichts als Sonderpreis'
      )
    ) {
      paymentFlags.push(LearnerPaymentFlag.FAMILY_AND_FRIENDS);
    }

    return paymentFlags;
  }

  getLicenseTabWidth(): number {
    return Math.min(this.license.key.length * 9 + 32, 120);
  }
}

export enum PricingsStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export class LicensePriceList {
  id: number;
  createdAt: Date;
  updatedAt: Date;

  schoolId?: number;
  schoolLearnerId?: number;

  originalSchoolLicensePriceListId?: number;

  name: string;
  displayOnSchoolProfile: boolean;
  isStandard: boolean;

  pricings: SchoolLicensePricing[];

  activeTo?: Date;
  isAccepted?: boolean;
  pricingsStatus: PricingsStatus;
  numAssignedLearners?: number;

  learnerName?: string;

  copyPriceList?: boolean;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.createdAt = new Date(json.createdAt);
    this.updatedAt = new Date(json.updatedAt);
    if (json.schoolId) {
      this.schoolId = +json.schoolId;
    }
    if (json.schoolLearnerId) {
      this.schoolLearnerId = +json.schoolLearnerId;
    }

    if (json.originalSchoolLicensePriceListId) {
      this.originalSchoolLicensePriceListId =
        +json.originalSchoolLicensePriceListId;
    }

    this.name = json.name;
    this.displayOnSchoolProfile = !!json.displayOnSchoolProfile;
    this.isStandard = !!json.isStandard;

    this.pricings = this.mapPricings(json.pricings || []);

    if (json.activeTo) {
      this.activeTo = new Date(json.activeTo);
    }
    if (json.isAccepted !== undefined) {
      this.isAccepted = !!json.isAccepted;
    }
    this.pricingsStatus = json.pricingsStatus;

    if (json.numAssignedLearners) {
      this.numAssignedLearners = +json.numAssignedLearners;
    }
    if (json.learnerName) {
      this.learnerName = json.learnerName;
    }
    if (json.copyPriceList) {
      this.copyPriceList = !!json.copyPriceList;
    }
  }

  get isActive(): boolean {
    return !this.activeTo || this.activeTo === null;
  }

  getLicenses(): License[] {
    if (!this.pricings || this.pricings.length === 0) {
      return [];
    }
    return this.pricings.map((pricing) => pricing.license);
  }

  getActiveLicenses(): License[] {
    if (!this.pricings || this.pricings.length === 0) {
      return [];
    }
    const activePricings = this.pricings.filter(
      (pricing) => pricing.displayOnSchoolProfile
    );
    if (!activePricings || activePricings.length === 0) {
      return [];
    }
    return activePricings.map((pricing) => pricing.license);
  }

  getLicenseIds(): number[] {
    if (!this.pricings || this.pricings.length === 0) {
      return [];
    }
    return this.pricings.map((pricing) => pricing.licenseId);
  }

  getLicenseKeysString(): string {
    if (!this.pricings || this.pricings.length === 0) {
      return 'n/a';
    }
    return this.pricings.map((pricing) => pricing.licenseKey).join(', ');
  }

  getPaymentFlagsForLicense(licenseId: number): LearnerPaymentFlag[] {
    const pricingForLicense = this.pricings?.find(
      (pricing) => pricing.licenseId === licenseId && pricing.isCurrent()
    );
    if (!pricingForLicense) {
      return LearnerPaymentFlag.getUnconditionalPaymentFlags();
    }

    return pricingForLicense.getAvailablePaymentFlags();
  }

  getPricingIdForLicense(licenseId: number): number {
    const pricingForLicense = this.pricings?.find(
      (pricing) => pricing.licenseId === licenseId
    );
    if (!pricingForLicense) {
      return null;
    }

    return pricingForLicense.id;
  }

  getLicensesTabWidth(): number {
    if (!this.pricings || this.pricings.length === 0) {
      return 0;
    }
    let totalWidth = 0;
    this.pricings.forEach(
      (pricing) => (totalWidth += pricing.getLicenseTabWidth())
    );
    return totalWidth;
  }

  hasPendingOrRejectedPricings(): boolean {
    return [PricingsStatus.PENDING, PricingsStatus.REJECTED].includes(
      this.pricingsStatus
    );
  }

  hasPendingPricingForLicense(licenseId: number): boolean {
    const pricingForLicense = this.pricings?.find(
      (pricing) => pricing.licenseId === licenseId && pricing.isPending()
    );
    if (!pricingForLicense) {
      return false;
    }

    return true;
  }

  private mapPricings(pricings: SchoolLicensePricing[]) {
    return pricings.map(
      (it: SchoolLicensePricing) => new SchoolLicensePricing(it)
    );
  }
}

export enum LicensePriceListsSettingType {
  RESPONSIBLE_AUTHORITY = 'RESPONSIBLE_AUTHORITY',
  FIRST_AID = 'FIRST_AID',
  SIGHT_TEST = 'SIGHT_TEST',
}

export enum PriceListSettingPaymentType {
  DEBIT_FROM_SCHOOL = 'DEBIT_FROM_SCHOOL',
  LEARNER_TRANSFER = 'LEARNER_TRANSFER',
}

export class ResponsibleAuthority {
  id: number;

  name: string;
  postalCode: string;
  street: string;
  streetNumber: string;
  locality: string;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;

    this.name = json.name;
    this.postalCode = json.postalCode;
    this.street = json.street;
    this.streetNumber = json.streetNumber;
    this.locality = json.locality;
  }

  toString(): string {
    return `${this.name}, ${this.street} ${this.streetNumber}, ${this.postalCode} ${this.locality}`;
  }

  addressToString(): string {
    return `${this.street} ${this.streetNumber}, ${this.postalCode} ${this.locality}`;
  }

  address(): Address {
    return new Address({
      street: this.street,
      streetNumber: this.streetNumber,
      postalCode: this.postalCode,
      locality: this.locality,
    });
  }
}

export class LicensePriceListsSetting {
  id: number;

  schoolId: number;

  type: LicensePriceListsSettingType;
  provider: string;
  link?: string;
  paymentType?: PriceListSettingPaymentType;

  responsibleAuthorityId?: number;
  responsibleAuthority?: ResponsibleAuthority;

  priceInCents?: number;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.schoolId = +json.schoolId;

    this.type = json.type;
    this.provider = json.provider;
    if (json.link) {
      this.link = json.link;
    }
    if (json.paymentType) {
      this.paymentType = json.paymentType;
    }

    if (json.responsibleAuthorityId) {
      this.responsibleAuthorityId = +json.responsibleAuthorityId;
      this.responsibleAuthority = new ResponsibleAuthority(
        json.responsibleAuthority
      );
    }

    if (json.priceInCents !== undefined) {
      this.priceInCents = +json.priceInCents;
    }
  }

  getResponsibleAuthorityDisplayName(): string {
    if (!this.responsibleAuthority) {
      return this.provider;
    }

    return `${this.provider}-${this.responsibleAuthority.name}`;
  }

  getPriceFormatted(): string {
    if (this.priceInCents === undefined) {
      return '';
    }
    return (this.priceInCents / 100.0).toFixed(2);
  }
}

export function getPriceListsSettingsProviders(
  type: LicensePriceListsSettingType
): string[] {
  switch (type) {
    case LicensePriceListsSettingType.RESPONSIBLE_AUTHORITY:
      return ['TÜV', 'DEKRA'];
    case LicensePriceListsSettingType.FIRST_AID:
      return ['Johanniter von drivEddy', 'Eigene Fahrschule'];
    case LicensePriceListsSettingType.SIGHT_TEST:
      return ['Apollo', 'Eigene Fahrschule'];
  }
}

export interface UpdateLicensePriceListsSettingsRequest {
  schoolId: number;
  standardPriceListId: number;
  settings: LicensePriceListsSetting[];
}

export interface LearnerHasPendingLicensePriceList {
  hasPendingPriceList: boolean;
  pendingPriceListId: number;
  areChangesRejected: boolean;
}

export class PlatformSchoolLicensePricing {
  id: number;

  schoolId: number;

  licenseId: number;
  license: License;

  amountInCents: number;
  fastlaneAmountInCents: number;

  theoryVideosAmountInCents: number;

  activeFrom?: Date;
  activeTo?: Date;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.schoolId = +json.schoolId;

    this.licenseId = +json.licenseId;
    this.license = new License(json.license);

    this.amountInCents = +json.amountInCents;
    this.fastlaneAmountInCents = +json.fastlaneAmountInCents;

    this.theoryVideosAmountInCents = +json.theoryVideosAmountInCents;

    this.activeFrom = new Date(json.activeFrom);

    if (json.activeTo) {
      this.activeTo = new Date(json.activeTo);
    }
  }
}

export function getOnlineTheoryLicenses(licenses: License[]) {
  return licenses.filter((license) => license.hasOnlineTheory);
}

export class OnlineLicenseInfo {
  total: number;
  totalAvailable: number;
  generalTopics?: number;
  aTopics?: number;
  bTopics?: number;
  beTopics?: number;
  platformDashboardText: string;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.total = +json.total;
    this.totalAvailable = +json.totalAvailable;
    if (json.generalTopics) {
      this.generalTopics = +json.generalTopics;
    }
    if (json.aTopics) {
      this.aTopics = +json.aTopics;
    }
    if (json.bTopics) {
      this.bTopics = +json.bTopics;
    }
    if (json.beTopics) {
      this.beTopics = +json.beTopics;
    }
    this.platformDashboardText = json.platformDashboardText;
  }

  get topics(): string[] {
    const res = [];
    if (this.aTopics) {
      res.push('A');
    }
    if (this.bTopics) {
      res.push('B');
    }
    if (this.beTopics) {
      res.push('BE');
    }
    if (res.length === 0 && this.generalTopics) {
      res.push('G');
    }

    return res;
  }

  hasBookableLessonsLimit() {
    return this.total < this.totalAvailable;
  }
}

export const defaultOnlineLicenseInfo =
  'Du kannst täglich (Mo-Fr) mit deiner Theorieausbildung starten und dann wahlweise deine Grundstoffe und klassenspezifischen Unterrichtseinheiten absolvieren. Die Reihenfolge ist dir überlassen. Viel Spaß!';
