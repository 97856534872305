export class LearnerLessonsPreference {
  id?: number;

  learnerId: number;

  maxTheoryLessonsPerWeek: number;
  maxTheoryLessonsPerDay?: number;

  basicTheoryTopicsPercentage: number;
  specificTheoryTopicsPercentage: number;

  constructor(json?: any) {
    if (!json) {
      return;
    }
    this.id = +json.id;

    this.learnerId = +json.learnerId;

    this.maxTheoryLessonsPerWeek = +json.maxTheoryLessonsPerWeek;
    if (json.maxTheoryLessonsPerDay !== undefined) {
      this.maxTheoryLessonsPerDay = +json.maxTheoryLessonsPerDay;
    }

    this.basicTheoryTopicsPercentage = +json.basicTheoryTopicsPercentage;
    this.specificTheoryTopicsPercentage = +json.specificTheoryTopicsPercentage;
  }

  hasUnlimitedTheoryLessonsPerWeek(): boolean {
    return !this.maxTheoryLessonsPerWeek;
  }
}
