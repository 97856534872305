import { VendorBankDetail } from '@models/vendor-bank-detail.model';
import { Address, emptyAddress } from '@models/address.model';

export class VendorLegal {
  companyName: string;
  email: string;
  vendorId: number | null;
  managingDirector: string;
  website: string;
  phone: string;
  bankDetail?: VendorBankDetail;
  billAddress?: Address;
  id?: number;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    if (json.id) {
      this.id = +json.id;
    }

    this.companyName = json.companyName;
    this.email = json.email;
    this.vendorId = json.vendorId;
    this.managingDirector = json.managingDirector;
    this.website = json.website;
    this.phone = json.phone;
    this.bankDetail = new VendorBankDetail(json.bankDetail);
    this.billAddress = new Address(json.billAddress);
  }

  static empty(schoolName?: string) {
    return new VendorLegal({companyName: schoolName});
  }

  getOrCreateBillAddress(): Address {
    if (this.billAddress) {
      return this.billAddress;
    }

    this.billAddress = emptyAddress();

    return this.billAddress;
  }
}

