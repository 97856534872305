import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'eddy-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements AfterViewInit {

  @ViewChild('searchTermInput', {static: true}) searchTermInput: ElementRef;

  @Input() debounceTime = 200;

  @Input() placeholder = '';

  @Input() roundedCorners: boolean;

  @Input() size: 'normal' | 'large' = 'normal';

  @Output() valueChanged: EventEmitter<string> = new EventEmitter();

  ngAfterViewInit() {
    fromEvent(this.searchTermInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        tap(e => {
          const searchTerm = e['target']['value'];
          this.valueChanged.emit(searchTerm);
        })).subscribe();
  }

  get isLargeSize(): boolean {
    return this.size === 'large';
  }
}
