import { LearnerPaymentFlag } from './learner-payment-flag.model';

export class BasicFee {
    learnerLicenseKey: string;
    learnerId: number;
    licenseId: number;
    licenseKey: string;
    paymentFlag: LearnerPaymentFlag;
    private readonly priceInCents: number;

    paymentOrderKey: string;
    isPaid: boolean;
  
    constructor(json) {
      this.learnerLicenseKey = json.learnerLicenseKey;
      this.learnerId = +json.learnerId;
      this.licenseId = +json.licenseId;
      this.licenseKey = json.licenseKey;
      if (json.paymentFlag) {
        this.paymentFlag = json.paymentFlag;
      }
      this.priceInCents = +json.priceInCents;

      this.paymentOrderKey = json.paymentOrderKey;
      this.isPaid = !!json.isPaid;
    }
  
    get displayPrice(): string {
      return this.roundToUpTo2Decimals(this.priceInCents / 100.0);
    }
  
    private roundToUpTo2Decimals(val: number): string {
      return val.toFixed(2).replace('.00', '').replace('.', ',');
    }
}
