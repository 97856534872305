import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Asset } from '@models/asset.model';
import { AssetType } from '@models/asset-type.model';

@Component({
  selector: 'eddy-file-downloader',
  templateUrl: './file-downloader.component.html',
  styleUrls: ['./file-downloader.component.scss']
})
export class FileDownloaderComponent {

  @Input() width: number;

  @Input() height: number;

  @Input() file: Asset;

  @Output() downloadFile: EventEmitter<Asset> = new EventEmitter<Asset>();

  onDownloadFile() {
    this.downloadFile.emit(this.file);
  }

  get documentCoverImagePath(): String {
    if (!this.file || !this.file.name) {
      return null;
    }

    if (this.file.type === AssetType.TERMS_AND_CONDITIONS) {
      return '/assets/agb-doc.png';
    }

    const n = this.file.name;
    if (n.endsWith('.pdf')) {
      return '/assets/pdf-doc.png';
    } else if (n.endsWith('.doc') || n.endsWith('.docx')) {
      return '/assets/word-doc.png';
    } else if (n.endsWith('.xls') || n.endsWith('.xlsx')) {
      return '/assets/excel-doc.png';
    }

    return null;
  }
}
