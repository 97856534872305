import { MonthYear } from './month-year.model';

export class MonthlyRevenue {
  monthYear: MonthYear;
  revenueInCents: number;

  constructor(json) {
    this.monthYear = new MonthYear(json.monthYear);
    this.revenueInCents = +json.revenueInCents;
  }
}
