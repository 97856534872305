<div class="main-footer" *ngIf="!(isLoggedIn | async)">

  <div class="footer-top container">
    <ng-container *ngIf="isOnlineTheoryLicenseBPage; else regularFooter">
      <div class="footnotes">
        <div class="footnote">*Bei 500 Euro Grundgebühr in Fahrschulen inklusive Offline-Theorie vor Ort.</div>
        <div class="footnote">** drivEddy übernimmt keine Garantie dafür, dass Online-Theorie in dem jeweiligen
          Bundesland, in welchem Du
          wohnhaft bist, zur Zeit anerkannt wird. Bitte informiere dich über den aktuellen Stand bei der zuständigen
          Landesbehörde. Wir kämpfen selbstverständlich weiter dafür, dass Online Theorie zukünftig in
          allen Bundesländern
          gleichermaßen akzeptiert wird. Neuigkeiten hierzu findet ihr auch auf der Seite des Verbandes <a
            href="https://www.dvfff.de" target="_blank" rel="noopener">www.dvfff.de</a>.
        </div>
      </div>
      <div class="footer-widgets">
        <div class="footer-widget">
          <img class="logo" src="/assets/logo-dark.png" />
        </div>

        <div class="footer-widget">
          <ul class="widget-list">
            <li class="widget-item">
              <a [routerLink]="dataPrivacyAppLink">Datenschutz für App</a>
            </li>
            <li class="widget-item">
              <a [routerLink]="dataPrivacyWebLink">Datenschutz für Website</a>
            </li>
          </ul>
        </div>

        <div class="footer-widget">
          <ul class="widget-list">
            <li class="widget-item">
              <a [routerLink]="imprintLink">Impressum</a>
            </li>
          </ul>
        </div>

        <div class="footer-widget">
          <div class="copyright">© {{ currentYear }} drivEddy GmbH. All right reserved.</div>
        </div>
      </div>
    </ng-container>
    <ng-template #regularFooter>
      <div class="footer-widgets">
        <div class="footer-widget">
          <img class="logo" src="/assets/logo-dark.png" />
          <a [href]="dvfffLink" target="_blank" rel="noopener"><img class="logo mt-3"
              src="/assets/dvfff-label.png" /></a>
        </div>

        <div class="footer-widget">
          <ul class="widget-list">
            <li class="widget-item">
              <a [href]="prBlogLink">Blog & Presse</a>
            </li>

            <li class="widget-item">
              <a [routerLink]="imprintLink">Impressum</a>
            </li>

            <li class="widget-item">
              <a [href]="supportLink" target="_blank" rel="noopener">Support</a>
            </li>
          </ul>
        </div>

        <div class="footer-widget">
          <ul class="widget-list">
            <li class="widget-item">
              <a [routerLink]="learnerTermsAndConditionsLink">AGB's für Fahrschüler</a>
            </li>
            <li class="widget-item">
              <a [routerLink]="instructorTermsAndConditionsLink">AGB’s für Fahrlehrer</a>
            </li>
            <li class="widget-item">
              <a [routerLink]="partnerSchoolPageLink">Fahrschulpartner werden</a>
            </li>
          </ul>
        </div>

        <div class="footer-widget">
          <ul class="widget-list">
            <li class="widget-item">
              <a [routerLink]="dataPrivacyAppLink">Datenschutz für App</a>
            </li>
            <li class="widget-item">
              <a [routerLink]="dataPrivacyWebLink">Datenschutz für Website</a>
            </li>
          </ul>
        </div>

        <div class="footer-widget">
          <div class="social-media">
            <a [href]="instagramLink" target="_blank" rel="noopener"><i class="fab fa-instagram"></i></a>
            <a [href]="facebookLink" target="_blank" rel="noopener"><i class="fab fa-facebook-f"></i></a>
            <a [href]="linkedinLink" target="_blank" rel="noopener"><i class="fab fa-linkedin-in"></i></a>
          </div>
          <div class="copyright">© {{ currentYear }} drivEddy GmbH. All right reserved.</div>
        </div>

      </div>
    </ng-template>
  </div>
</div>
