import { isPlatformServer } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ScreenWidthService } from '@services/screen-width.service';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes } from 'app/app.routes';
import { TheoryLessonService } from '@services/theory-lesson.service';
import { TheoryPackage } from '@models/theory-package.model';
import { TheoryPackageType } from '@models/theory-package-type.model';
import { Meta } from '@angular/platform-browser';

@Component({
  templateUrl: './purchase-thank-you.page.html',
  styleUrls: ['./purchase-thank-you.page.scss'],
  animations: [
    // fade-in/fade-out animation.
    trigger('fadeAnimation', [
      transition('void => true', [
        style({ opacity: 0, bottom: -86 }),
        animate('600ms ease-in', style({ opacity: 1, bottom: 12 })),
      ]),
      transition('* => void', [
        animate('600ms ease-out', style({ opacity: 0, bottom: -86 })),
      ]),
    ]),
  ],
})
export class PurchaseThankYouPage implements OnInit, OnDestroy {
  private readonly isServer: boolean;

  productName: string;
  productPrice: string;
  learnerId: number;
  offerKey: string;

  theoryVideosPackage: TheoryPackage;

  isDesktop: boolean;

  currentSale: any;
  showCurrentSale: boolean;

  readonly currentSales = [
    {
      letter: 'T',
      name: 'Tina H.',
      time: 'Innerhalb der letzten 6 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'S',
      name: 'Sabrina M.',
      time: 'Innerhalb der letzten 6 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'P',
      name: 'Patrick N.',
      time: 'Innerhalb der letzten 6 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'K',
      name: 'Katharina L.',
      time: 'Innerhalb der letzten 6 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'S',
      name: 'Sebastian N.',
      time: 'Innerhalb der letzten 6 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'T',
      name: 'Tobias H.',
      time: 'Innerhalb der letzten 6 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'S',
      name: 'Steffanie L.',
      time: 'Innerhalb der letzten 6 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'S',
      name: 'Sabine S.',
      time: 'Innerhalb der letzten 6 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'P',
      name: 'Patrizia L.',
      time: 'Innerhalb der letzten 12 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'G',
      name: 'Gerald O.',
      time: 'Innerhalb der letzten 12 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'D',
      name: 'Daniela K.',
      time: 'Innerhalb der letzten 12 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'R',
      name: 'Raphael M.',
      time: 'Innerhalb der letzten 12 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'N',
      name: 'Niklas D.',
      time: 'Innerhalb der letzten 12 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'S',
      name: 'Sabrina S.',
      time: 'Innerhalb der letzten 12 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'M',
      name: 'Martin M.',
      time: 'Innerhalb der letzten 12 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'C',
      name: 'Carola L.',
      time: 'Innerhalb der letzten 3 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'U',
      name: 'Ulrike K.',
      time: 'Innerhalb der letzten 3 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'M',
      name: 'Marrike Sch.',
      time: 'Innerhalb der letzten 3 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'S',
      name: 'Stefan S.',
      time: 'Innerhalb der letzten 3 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'O',
      name: 'Oliver F.',
      time: 'Innerhalb der letzten 3 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'M',
      name: 'Marco T.',
      time: 'Innerhalb der letzten 24 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'L',
      name: 'Lea Sch.',
      time: 'Innerhalb der letzten 24 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'L',
      name: 'Leonie M.',
      time: 'Innerhalb der letzten 24 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
    {
      letter: 'E',
      name: 'Elsa P.',
      time: 'Innerhalb der letzten 24 Stunden',
      text: 'Hat das Sonderangebot in Anspruch gekauft',
    },
  ];

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(
    private theoryLessonService: TheoryLessonService,
    private router: Router,
    private route: ActivatedRoute,
    private screenWidthService: ScreenWidthService,
    private meta: Meta,
    @Inject(PLATFORM_ID) platformId
  ) {
    this.meta.addTag({ name: 'robots', content: 'noindex' });
    this.isServer = isPlatformServer(platformId);
    this.screenWidthService
      .isDesktop()
      .subscribe((isDesktop) => (this.isDesktop = isDesktop));
    this.getCurrentPurchaseInfo();
  }

  ngOnInit() {
    if (!this.isServer) {
      this.startCurrentSalesInterval();
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getCurrentPurchaseInfo() {
    const queryParamMap = this.route.snapshot.queryParamMap;
    if (queryParamMap.get('product')) {
      this.productName = this.getProductNameFromQueryParam(
        queryParamMap.get('product')
      );
    }
    if (queryParamMap.get('price')) {
      this.productPrice = this.extractAmountBeforeEuro(
        queryParamMap.get('price')
      );
    }
    if (queryParamMap.get('learnerId')) {
      this.learnerId = +queryParamMap.get('learnerId');
      if (this.learnerId && this.isOnlineTheoryPackage) {
        this.loadLearnerTheoryPackage();
      }
    }
    if (queryParamMap.get('key')) {
      this.offerKey = queryParamMap.get('key');
    }
  }

  loadLearnerTheoryPackage() {
    this.theoryLessonService
      .getLearnerTheoryPackage(this.learnerId, TheoryPackageType.THEORY_VIDEOS)
      .subscribe((theoryPackage) => {
        this.theoryVideosPackage = theoryPackage;
      });
  }

  toTheoryVideosPaymentPage() {
    window.location.href =
      this.theoryLessonService.buildTheoryPackagePaymentLinkByKey(
        this.theoryVideosPackage.key,
        this.offerKey
      );
  }

  onOfferRejected() {
    this.router.navigate([
      '/',
      AppRoutes.LearnersInternal.root,
      AppRoutes.LearnersInternal.profile,
    ]);
  }

  onContinue() {
    if (this.isTheoryVideosPackage || this.isTheoryVideo) {
      this.router.navigate([
        '/',
        AppRoutes.LearnersInternal.root,
        AppRoutes.LearnersInternal.theoryVideos,
      ]);
    } else {
      this.router.navigate([
        '/',
        AppRoutes.EddyAcademyLearner.root,
        AppRoutes.EddyAcademyLearner.dashboardPage,
      ]);
    }
  }

  startCurrentSalesInterval() {
    timer(1000, 21200)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.currentSale =
          this.currentSales[this.randomInteger(0, this.currentSales.length)];
        this.showCurrentSale = true;
        setTimeout(() => {
          this.showCurrentSale = false;
        }, 6200);
      });
  }

  get isOnlineTheoryPackage(): boolean {
    return this.productName === 'Online-Theorie Paket';
  }

  get isAllInOneTheoryPackage(): boolean {
    return this.productName === 'All-in-one Kombi-Paket';
  }

  get isOnlineTheoryOrAllInOnePackage(): boolean {
    return this.isOnlineTheoryPackage || this.isAllInOneTheoryPackage;
  }

  get isTheoryVideosPackage(): boolean {
    return this.productName === 'Theorie Video-Paket';
  }

  get isTheoryVideo(): boolean {
    return this.productName.startsWith('Theorie-Unterichtsvideo ');
  }

  get theoryVideosTotalMins(): string {
    return `${this.theoryVideosPackage.numberOfLessons * 90}min`;
  }

  private randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  private extractAmountBeforeEuro(inputString: string) {
    const parts = inputString.split('euro');

    const result = parts[0].trim();

    return result;
  }

  private getProductNameFromQueryParam(product: string) {
    if (product.startsWith('theorie-unterichtsvideo-')) {
      return `Theorie-Unterichtsvideo ${this.fetchLessonAndUppercase(product)}`;
    } else if (product.startsWith('theoriestunde-')) {
      return `Theoriestunde ${this.fetchLessonAndUppercase(product)}`;
    } else {
      switch (product) {
        case 'all-in-one-kombi-paket':
          return 'All-in-one Kombi-Paket';
        case 'online-theorie-paket':
          return 'Online-Theorie Paket';
        case 'theorie-video-paket':
          return 'Theorie Video-Paket';
        default:
          return '';
      }
    }
  }

  private fetchLessonAndUppercase(str: string) {
    const parts = str.split('-');
    const lastPart = parts[parts.length - 1];
    return lastPart.toUpperCase();
  }
}
