import { Component, Input, OnInit } from '@angular/core';
import { PracticeLessonRating } from '@models/practice-lesson-rating.model';
import { PracticeLesson } from '@models/practice-lesson.model';
import { PracticeLessonEddyClubRating } from '@models/practice-lesson-eddy-club-rating.model';
import { NotificationService } from '@services/notification.service';
import { PracticeLessonService } from '@services/practice-lesson.service';

@Component({
  selector: 'eddy-booking-driver-notes',
  templateUrl: './booking-driver-notes.component.html',
  styleUrls: ['./booking-driver-notes.component.scss']
})
export class BookingDriverNotes implements OnInit {
  lessonRatingsInProgress: boolean;

  @Input() practiceLesson: PracticeLesson;

  constructor(
    private practiceLessonService: PracticeLessonService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
    console.log(this.practiceLesson);
  }

  get rating(): PracticeLessonRating | undefined {
    return this.practiceLesson?.rating;
  }

  get eddyClubRating(): PracticeLessonEddyClubRating | undefined {
    return this.practiceLesson?.eddyClubRating;
  }

  onDownloadLessonRatings() {
    if (this.lessonRatingsInProgress) {
      return;
    }

    this.lessonRatingsInProgress = true;
    this.practiceLessonService.downloadLessonRatings(this.practiceLesson)
      .subscribe(() => this.lessonRatingsInProgress = false, () => {
        this.lessonRatingsInProgress = false;
        this.notificationService.loadError();
      });
  }
}
