import { CalendarEventType } from '@models/calendar-event-type.model';
import { getDayOfWeek, getDisplayName } from '@models/day.model';
import { DateUtils } from '@services/date-utils.service';
import { CalendarEventException } from './calendar-event-exception.model';
import { TeamMember } from './team-member.model';

export class CalendarEvent {
  id: number;

  ownerSchoolId: number;
  ownerTeamMemberId?: number;
  ownerTeamMember?: TeamMember;

  type: CalendarEventType;

  startDate: Date;
  endDate: Date;

  calendarEventException?: CalendarEventException;

  parentEventId?: number;

  recurringPatternId?: number;

  constructor(json?: any) {
    if (json === undefined || json === null) {
      return;
    }

    if (json.id) {
      this.id = +json.id;
    }

    if (json?.ownerSchoolId) {
      this.ownerSchoolId = +json.ownerSchoolId;
    }
    if (json?.ownerTeamMemberId) {
      this.ownerTeamMemberId = +json.ownerTeamMemberId;
    }
    if (json?.ownerTeamMember) {
      this.ownerTeamMember = new TeamMember(json.ownerTeamMember);
    }
    this.type = json.type;

    if (json?.startDate) {
      this.startDate = new Date(json.startDate);
    }
    if (json?.endDate) {
      this.endDate = new Date(json.endDate);
    }

    if (json?.calendarEventException) {
      this.calendarEventException = new CalendarEventException(
        json.calendarEventException
      );
    }

    if (json?.parentEventId) {
      this.parentEventId = +json.parentEventId;
    }
    if (json?.recurringPatternId) {
      this.recurringPatternId = +json.recurringPatternId;
    }
  }

  getDurationInMinutes(): number {
    const diff = Math.abs(this.endDate.getTime() - this.startDate.getTime());
    return Math.floor(diff / 1000 / 60);
  }

  getDurationInSeconds(): number {
    const diff = Math.abs(this.endDate.getTime() - this.startDate.getTime());
    return Math.floor(diff / 1000);
  }

  getStartTime(): string {
    if (!this.startDate) {
      return '';
    }
    return DateUtils.getTimeString(this.startDate);
  }

  getEndTime(): string {
    if (!this.endDate) {
      return '';
    }
    return DateUtils.getTimeString(this.endDate);
  }

  getTimeInterval(): string {
    return `${this.getStartTime()} - ${this.getEndTime()}`;
  }

  getWeekDayDisplayName(): string {
    return getDisplayName(getDayOfWeek(this.startDate));
  }

  getSchoolStartDate(): Date {
    if (!this.calendarEventException) {
      return this.startDate;
    }
    return this.calendarEventException.startDate;
  }

  getSchoolEndDate(): Date {
    if (!this.calendarEventException) {
      return this.endDate;
    }
    return this.calendarEventException.endDate;
  }

  getSchoolStartTime(): string {
    if (!this.calendarEventException) {
      return this.getStartTime();
    }
    return this.calendarEventException.getStartTime();
  }

  getSchoolEndTime(): string {
    if (!this.calendarEventException) {
      return this.getEndTime();
    }
    return this.calendarEventException.getEndTime();
  }

  getSchoolTimeInterval(): string {
    if (!this.calendarEventException) {
      return this.getTimeInterval();
    }
    return this.calendarEventException.getTimeInterval();
  }

  getSchoolWeekDayDisplayName(): string {
    if (!this.calendarEventException) {
      return this.getWeekDayDisplayName();
    }
    return this.calendarEventException.getWeekDayDisplayName();
  }

  getSchoolDurationInMinutes(): number {
    if (!this.calendarEventException) {
      return this.getDurationInMinutes();
    }
    return this.calendarEventException.getDurationInMinutes();
  }
}
