import moment from 'moment';
import { School } from './school.model';

export class SchoolBilling {
    id: number;

    createdAt: Date;
    updatedAt: Date;

    schoolId: number;
    school: School;

    amountInCents: number;
    creditsUsedInCents: number;
    billingMonth: Date;

    billingConfirmed: boolean;
    isOnline: boolean;

    key: string;

    originalReceiptNumber: string;
    receiptNumber?: string;

    constructor(json?: any) {
        if (!json) {
          return;
        }

        this.id = +json.id;
        this.createdAt = new Date(json.createdAt);
        this.updatedAt = new Date(json.updatedAt);

        this.schoolId = +json.schoolId;
        if (json.school) {
            this.school = new School(json.school);
        }

        this.amountInCents = +json.amountInCents;
        this.creditsUsedInCents = +json.creditsUsedInCents;
        this.billingMonth = new Date(json.billingMonth);

        this.billingConfirmed = !!json.billingConfirmed;
        this.isOnline = !!json.isOnline;

        this.key = json.key;

        this.receiptNumber = json.receiptNumber;
        this.originalReceiptNumber = json.originalReceiptNumber;
    }

    get payableNetPriceInCents(): number {
        return this.amountInCents - this.creditsUsedInCents;
    }

    get billingMonthMonth(): number {
        return moment(this.billingMonth).month();
    }

    get billingMonthYear(): number {
        return moment(this.billingMonth).year();
    }

    getPaymentTypeDisplayName(): string {
        return this.isOnline ? 'Online' : 'Überweisung';
    }
}
