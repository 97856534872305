export enum PaymentType {
  ONLINE = 'ONLINE',
  CASH = 'CASH',
  ALL = 'ALL',
  VOUCHER = 'VOUCHER',
  BANK_TRANSFER = 'BANK_TRANSFER',
  CASH_EDDY = 'CASH_EDDY',
  BANK_TRANSFER_EDDY = 'BANK_TRANSFER_EDDY',
}

export function getAllPaymentTypes(): PaymentType[] {
  return [PaymentType.ONLINE, PaymentType.CASH, PaymentType.BANK_TRANSFER];
}

export function getDisplayName(type: PaymentType) {
  switch (type) {
    case PaymentType.ONLINE:
      return 'Online';
    case PaymentType.CASH:
      return 'Bar';
    case PaymentType.BANK_TRANSFER:
      return 'Überweisung';
    default:
      return '';
  }
}
