<div class="info-video-player-container">
  <button class="info-button" (click)="toggleOpenPlayer()">
    <i class="fas fa-info-circle"></i>
  </button>

  <div *ngIf="videoShown" class="video-container">
    <video width="247" height="169" controls autoplay muted controlslist="nodownload" disablePictureInPicture>
      <source [src]="videoUrl" type="video/mp4">
    </video>
  </div>
</div>
