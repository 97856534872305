import { Component, Input } from '@angular/core';

export const APP_STORE_LINK = 'https://itunes.apple.com/de/app/driveddy-fahrschüler/id1390360574?ls=1&mt=8';
export const GOOGLE_PLAY_LINK = 'https://play.google.com/store/apps/details?id=com.driveddy.learner.and.live';

@Component({
  selector: 'eddy-app-buttons',
  templateUrl: './app-buttons.component.html',
  styleUrls: ['./app-buttons.component.scss']
})
export class AppButtonsComponent {

  @Input() theme: 'brand-primary' | 'white' = 'brand-primary';
  @Input() lineType: 'bold' | 'normal' = 'normal';
  @Input() alignment: 'centered' | 'normal' = 'normal';

  @Input() eventLabel: string;

  openAppStoreApp() {
    window.open(APP_STORE_LINK, '_blank');
  }

  openGooglePlayApp() {
    window.open(GOOGLE_PLAY_LINK, '_blank');
  }
}
