import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'eddy-slim-spinner',
  templateUrl: './slim-spinner.component.html',
  styleUrls: ['./slim-spinner.component.scss']
})
export class SlimSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
