export class LearnerGuardianAddress {
  id: number;
  learnerGuardianId: number;

  street: string;
  streetNumber: string;
  postalCode: string;
  locality: string;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.learnerGuardianId = +json.learnerGuardianId;

    this.street = json.street;
    this.streetNumber = json.streetNumber;
    this.postalCode = json.postalCode;
    this.locality = json.locality;
  }

  isFilled(): boolean {
    return !!this.street && !!this.streetNumber && !!this.postalCode && !!this.locality;
  }

  toString(addHTMLLineBreakBeforePostalCode?: boolean) {
    if (addHTMLLineBreakBeforePostalCode) {
      return `${this.street} ${this.streetNumber},<br/> ${this.postalCode} ${this.locality}`;
    }

    return `${this.street} ${this.streetNumber}, ${this.postalCode} ${this.locality}`;
  }
}
