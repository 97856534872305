import { UserLocation } from '@models/user-location.model';
import { SchoolLearnerStatus } from './school-learner-status.model';
import { USER_ROLE } from './user.model';

export class UserDetail {
  firstName: string;
  lastName: string;
  email: string;
  creationDate: Date;
  lastLogin?: Date;
  schoolId?: number;
  schoolName: string;
  schoolLearnerStatus: SchoolLearnerStatus;
  licenses: string[];
  role: USER_ROLE;

  studentId?: number;
  schoolLearnerId?: number;
  instructorId?: number;

  location?: UserLocation;

  hasTrainingDocument: boolean;

  constructor(json) {
    this.firstName = json.firstName;
    this.lastName = json.lastName;
    this.email = json.email;
    this.creationDate = new Date(json.creationDate);
    this.lastLogin = new Date(json.lastLogin);
    this.schoolId = json.schoolId;
    this.schoolName = json.schoolName;
    this.licenses = json.licenses;
    this.role = json.role;

    this.schoolLearnerStatus = json.schoolLearnerStatus;

    this.studentId = json.studentId ? +json.studentId : undefined;
    this.schoolLearnerId = json.schoolLearnerId
      ? +json.schoolLearnerId
      : undefined;
    this.instructorId = json.instructorId ? +json.instructorId : undefined;

    this.location = json.location ? new UserLocation(json.location) : undefined;

    this.hasTrainingDocument = !!json.hasTrainingDocument;
  }

  isLearner(): boolean {
    return this.role === USER_ROLE.STUDENT && !!this.studentId;
  }

  isPlatformUser(): boolean {
    return this.role === USER_ROLE.PLATFORM;
  }

  isInstructor() {
    return this.role === USER_ROLE.INSTRUCTOR;
  }

  hasSchool() {
    return !!this.schoolLearnerId;
  }

  isIncompleteProfile() {
    return (
      this.isLearner() &&
      this.schoolLearnerStatus === SchoolLearnerStatus.INCOMPLETE_PROFILE
    );
  }
}
