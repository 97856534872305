<div class="user-icon-cont" [class.narrow]="narrowMode">
  <div
    *ngIf="isSchoolEmployee"
    (click)="$event.stopPropagation()"
    [matMenuTriggerFor]="appMenu"
    [matMenuTriggerData]="{ data: instructorMenuData }"
    class="open-menu-btn"
  >
    <img
      *ngIf="loggedTeamMember && loggedTeamMember.imagePath; else emptyAvatar"
      class="avatar"
      [src]="loggedTeamMember.imagePath"
    />
    <ng-template #emptyAvatar>
      <img class="avatar" src="/assets/eddy-avatar-empty.png" />
    </ng-template>

    <div *ngIf="!narrowMode" class="user-text">
      {{ instructorMenuData.headerText }}
    </div>
  </div>

  <div
    *ngIf="isLearner"
    (click)="$event.stopPropagation()"
    [matMenuTriggerFor]="appMenu"
    [matMenuTriggerData]="{ data: learnerMenuData }"
    class="open-menu-btn"
  >
    <img class="avatar" src="/assets/eddy-avatar-empty.png" />
    <div *ngIf="!narrowMode" class="user-text">
      {{ learnerMenuData.headerText }}
    </div>
  </div>
</div>

<mat-menu #appMenu="matMenu" class="table-mat-menu-panel mb-1">
  <ng-template matMenuContent let-data="data">
    <ng-container *ngFor="let item of data.items">
      <span [class]="item.fontColor">
        <a
          *ngIf="item.url"
          [routerLink]="item.url"
          mat-menu-item
          class="menu-btn"
          ><i *ngIf="item.icon" class="fal {{ item.icon }}"></i>
          {{ item.label }}</a
        >
        <a
          *ngIf="item.event"
          (click)="onMenuEvent(item.event)"
          href="javascript:void(0)"
          mat-menu-item
          class="menu-btn"
          ><i *ngIf="item.icon" class="fal {{ item.icon }}"></i>
          {{ item.label }}</a
        >
        <a *ngIf="!item.url && !item.event" mat-menu-item class="menu-btn"
          ><i *ngIf="item.icon" class="fal {{ item.icon }}"></i>
          {{ item.label }}</a
        >
        <hr *ngIf="item.separator" />
      </span>
    </ng-container>
  </ng-template>
</mat-menu>
