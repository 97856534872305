export enum LearnerPaymentFlag {
  VOUCHER_CODE = 'VOUCHER_CODE',
  SCHOOL_CHANGER = 'SCHOOL_CHANGER',
  FULL_PACKAGE = 'FULL_PACKAGE',
  FAMILY_AND_FRIENDS = 'FAMILY_AND_FRIENDS',
  ONLINE_THEORY = 'ONLINE_THEORY',
}

export namespace LearnerPaymentFlag {
  export function getAllLearnerPaymentFlags(): LearnerPaymentFlag[] {
    return [
      LearnerPaymentFlag.VOUCHER_CODE,
      LearnerPaymentFlag.ONLINE_THEORY,
      LearnerPaymentFlag.SCHOOL_CHANGER,
      LearnerPaymentFlag.FULL_PACKAGE,
      LearnerPaymentFlag.FAMILY_AND_FRIENDS,
    ];
  }

  export function getUnconditionalPaymentFlags(): LearnerPaymentFlag[] {
    return [LearnerPaymentFlag.ONLINE_THEORY];
  }

  export function getDisplayName(status: LearnerPaymentFlag) {
    switch (status) {
      case LearnerPaymentFlag.VOUCHER_CODE:
        return 'Gutscheincode (keine Grundgebühr)';
      case LearnerPaymentFlag.SCHOOL_CHANGER:
        return 'Wechsler';
      case LearnerPaymentFlag.FULL_PACKAGE:
        return 'Kompletter Fahrschüler';
      case LearnerPaymentFlag.FAMILY_AND_FRIENDS:
        return 'Sonderpreis';
      case LearnerPaymentFlag.ONLINE_THEORY:
        return 'Nur Online-Theorie';
      default:
        return '';
    }
  }

  export function isNoPaymentPaymentFlag(
    learnerPaymentFlag: LearnerPaymentFlag
  ): boolean {
    return [
      LearnerPaymentFlag.VOUCHER_CODE,
      LearnerPaymentFlag.ONLINE_THEORY,
    ].includes(learnerPaymentFlag);
  }
}
