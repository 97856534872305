import { getDayOfWeek, getDisplayName } from '@models/day.model';
import { DateUtils } from '@services/date-utils.service';

export class CalendarEventException {
  id: number;

  eventId: number;

  isRescheduled: boolean;
  isCancelled: boolean;

  startDate: Date;
  endDate: Date;

  constructor(json?: any) {
    if (json === undefined || json === null) {
      return;
    }

    if (json.id) {
      this.id = +json.id;
    }

    if (json?.eventId) {
      this.eventId = +json.eventId;
    }

    if (json?.startDate) {
      this.startDate = new Date(json.startDate);
    }
    if (json?.endDate) {
      this.endDate = new Date(json.endDate);
    }
  }

  getDurationInMinutes(): number {
    const diff = Math.abs(this.endDate.getTime() - this.startDate.getTime());
    return Math.floor(diff / 1000 / 60);
  }

  getDurationInSeconds(): number {
    const diff = Math.abs(this.endDate.getTime() - this.startDate.getTime());
    return Math.floor(diff / 1000);
  }

  getStartTime(): string {
    if (!this.startDate) {
      return '';
    }
    return DateUtils.getTimeString(this.startDate);
  }

  getEndTime(): string {
    if (!this.endDate) {
      return '';
    }
    return DateUtils.getTimeString(this.endDate);
  }

  getTimeInterval(): string {
    return `${this.getStartTime()} - ${this.getEndTime()}`;
  }

  getWeekDayDisplayName(): string {
    return getDisplayName(getDayOfWeek(this.startDate));
  }
}
