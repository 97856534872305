import { Component, Input } from '@angular/core';
import { Language } from 'app/models/language.model';

@Component({
  selector: 'eddy-school-languages',
  templateUrl: './school-languages.component.html',
  styleUrls: ['./school-languages.component.scss']
})
export class SchoolLanguagesComponent {

  @Input() languages: Language[];

  @Input() iconWidth = 19;
}
