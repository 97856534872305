<div class="content-container">
  <h5 class="known-from-title">Bekannt aus</h5>

  <div class="logos">
    <img src="/assets/company-logos/mdr.png"/>
    <img src="/assets/company-logos/ngin-mobility.png"/>
    <img src="/assets/company-logos/handelsblattlogo.png"/>
    <img src="/assets/company-logos/auto-bild.png"/>
    <img src="/assets/company-logos/wirtschafts-woche.png"/>
    <img class="tagesspiegel" src="/assets/company-logos/tagesspiegel-logo.png"/>
    <img src="/assets/company-logos/sueddeutsche-zeitung.png"/>
  </div>
</div>
