import { Injectable } from '@angular/core';
import { asapScheduler, Observable, of, scheduled, throwError } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { catchError, map, tap } from 'rxjs/operators';
import {
  AcceptOnboardingRequest,
  SaveSchoolExistingLearner,
  SaveSchoolNewLearner,
  SchoolLearner,
} from '@models/school-learner.model';
import { Learner } from '@models/learner.model';
import { NumberOfSchoolLearners } from '@models/number-of-school-learners.model';
import { SchoolLearnerStatus } from '@models/school-learner-status.model';
import { SchoolLearnerAccountingPageListingResponse } from '@models/school-learner-accounting-page-listing-response.model';
import { SchoolLearnerAccounting } from '@models/school-learner-accounting.model';
import { SchoolLearnerExport } from '@models/school-learner-export.model';
import { saveAs } from 'file-saver';
import { PageListingResponse } from '@models/page-listing-response.model';
import { NumberOfSchoolLearnersByStatus } from '@models/number-of-school-learners-by-status.model';
import { TrainingConfirmationSchoolLearnerPageListingResponse } from '@models/training-confirmation-school-learner-page-listing-response.model';
import { TrainingConfirmationSchoolLearner } from '@models/training-confirmation-school-learner.model';
import { BlobWithFileName } from '@models/blob-with-filename.model';
import { LearnerExamType } from '@models/learner-exam-type.model';
import { OnboardingStats } from '@models/graph-stats/onboarding_stats.model';

@Injectable()
export class SchoolLearnerService {
  private readonly apiPath = `${env.apiSchoolService}`;

  constructor(private api: ApiService) {}

  saveSchoolLearner(
    schoolLearner: SchoolLearner,
    completeProfile: boolean,
    schoolOrInstructorUpdate = false,
    isFromOnboarding = false,
    isFromOnlineTheory?: boolean
  ): Observable<SchoolLearner> {
    const sl: any = this.removeEmptyEntities(
      new SchoolLearner(JSON.parse(JSON.stringify(schoolLearner)))
    );
    sl['completeProfile'] = completeProfile;
    sl['schoolOrInstructorUpdate'] = schoolOrInstructorUpdate;
    sl['isFromOnboarding'] = isFromOnboarding;
    sl['isFromOnlineTheory'] = isFromOnlineTheory;

    return this.api
      .post<SchoolLearner>(`${this.apiPath}/school_learners`, sl)
      .pipe(map((it) => new SchoolLearner(it)));
  }

  private removeEmptyEntities(sl: SchoolLearner): SchoolLearner {
    if (!sl.learner) {
      return sl;
    }

    if (!sl.learner?.address?.isPartiallyFilled()) {
      sl.learner.address = undefined;
    }

    if (!sl.learner?.billingAddress?.isFilled()) {
      sl.learner.billingAddress = undefined;
    }

    if (!sl.learner?.trainingInfo?.isFilled()) {
      sl.learner.trainingInfo = undefined;
    }

    if (!sl.learner?.existingLicenseInfo?.areMandatoryFieldsFilled()) {
      sl.learner.existingLicenseInfo = undefined;
    }

    if (!sl.learner?.guardian?.isFilled()) {
      sl.learner.guardian = undefined;
    }

    return sl;
  }

  private mapSchoolLearners(schoolLearners: any[]) {
    return schoolLearners.map((it: any) => new SchoolLearner(it));
  }

  getSchoolLearners(
    schoolId: number,
    filters?: any
  ): Observable<SchoolLearner[]> {
    return this.api
      .get(`${this.apiPath}/schools/${schoolId}/learners`, filters)
      .pipe(map((res: any[]) => this.mapSchoolLearners(res)));
  }

  getSchoolLearnersPaged(
    schoolId: number,
    pageNumber: number,
    pageSize: number,
    filters?: any
  ): Observable<PageListingResponse<SchoolLearner>> {
    return this.api
      .getPaged(
        `${this.apiPath}/schools/${schoolId}/learners/paged`,
        pageNumber,
        pageSize,
        filters
      )
      .pipe(map(this.mapSchoolLearnersPaged));
  }

  private mapSchoolLearnersPaged(
    res: PageListingResponse
  ): PageListingResponse<SchoolLearner> {
    return {
      total: res.total,
      records: res.records.map((it) => new SchoolLearner(it)),
    };
  }

  resendSchoolLearnerInvitation(schoolLearnerId: number): Observable<void> {
    return this.api.post<void>(
      `${this.apiPath}/school_learners/${schoolLearnerId}/resend_invitation`,
      {}
    );
  }

  sendSchoolLearnerOnboardingReminder(
    schoolLearnerId: number
  ): Observable<void> {
    return this.api.post<void>(
      `${this.apiPath}/school_learners/${schoolLearnerId}/send_onboarding_reminder`,
      {}
    );
  }

  newLearnerHasSchool(newLearnerEmail: string): Observable<boolean> {
    return this.api
      .head(`${this.apiPath}/learners/email/${newLearnerEmail}/school`)
      .pipe(
        map(() => true),
        catchError((err) => {
          if (err && err.status === 404) {
            return scheduled<boolean>([false], asapScheduler);
          }
          return throwError(err);
        })
      );
  }

  learnerHasSchool(learnerId: number): Observable<boolean> {
    return this.api.head(`${this.apiPath}/learners/${learnerId}/school`).pipe(
      map(() => true),
      catchError((err) => {
        if (err && err.status === 404) {
          return scheduled<boolean>([false], asapScheduler);
        }
        return throwError(err);
      })
    );
  }

  connectExistingLearnerToSchool(
    schoolLearner: SaveSchoolExistingLearner
  ): Observable<SchoolLearner> {
    return this.api.post(
      `${this.apiPath}/schools/${schoolLearner.schoolId}/learners`,
      schoolLearner
    );
  }

  connectNewLearnerToSchool(
    schoolLearner: SaveSchoolNewLearner
  ): Observable<SchoolLearner> {
    return this.api.post(
      `${this.apiPath}/schools/${schoolLearner.schoolId}/learners`,
      schoolLearner
    );
  }

  acceptSchoolOnboardingRequest(
    schoolLearnerId: number,
    request: AcceptOnboardingRequest
  ): Observable<SchoolLearner> {
    return this.api.post(
      `${this.apiPath}/school_learners/${schoolLearnerId}/accept_onboarding_request`,
      request
    );
  }

  acceptSchoolOnboardingRequestByLearnerKey(
    learnerKey: string
  ): Observable<SchoolLearner> {
    return this.api
      .post(
        `${this.apiPath}/school_learners/key/${learnerKey}/accept_onboarding_request`,
        {}
      )
      .pipe(map((it) => new SchoolLearner(it)));
  }

  getInstructorLearners(instructorId: number): Observable<Learner[]> {
    return this.api
      .get<Learner[]>(`${this.apiPath}/instructors/${instructorId}/learners`)
      .pipe(map((res: any[]) => this.mapLearners(res)));
  }

  private mapLearners(res: any[]): Learner[] {
    return res.map((it) => new Learner(it));
  }

  getSchoolLearner(code: string): Observable<SchoolLearner> {
    if (!code) {
      return of<SchoolLearner>(null);
    }

    return this.api
      .get<SchoolLearner>(`${this.apiPath}/school_learners/code/${code}`)
      .pipe(map((it: any) => new SchoolLearner(it)));
  }

  getSchoolLearnerByLearnerId(
    learnerId: number,
    onlySchoolLearner = false,
    preloadSchoolAddress = false
  ): Observable<SchoolLearner> {
    const params = new Map<string, string>();
    if (onlySchoolLearner) {
      params.set('onlySchoolLearner', '' + onlySchoolLearner);
    }
    if (preloadSchoolAddress) {
      params.set('preloadSchoolAddress', '' + preloadSchoolAddress);
    }

    return this.api
      .get<SchoolLearner>(
        `${this.apiPath}/school_learners/learner/${learnerId}`,
        params
      )
      .pipe(map((it: any) => new SchoolLearner(it)));
  }

  getSchoolLearnerById(
    id: number,
    onlySchoolLearner = false,
    preloadSchoolAddress = false
  ): Observable<SchoolLearner> {
    const params = new Map<string, string>();
    if (onlySchoolLearner) {
      params.set('onlySchoolLearner', '' + onlySchoolLearner);
    }
    if (preloadSchoolAddress) {
      params.set('preloadSchoolAddress', '' + preloadSchoolAddress);
    }

    return this.api
      .get<SchoolLearner>(`${this.apiPath}/school_learners/${id}`, params)
      .pipe(map((it: any) => new SchoolLearner(it)));
  }

  getSchoolLearnerForLicenseApplicationByUserKey(
    userKey: string
  ): Observable<SchoolLearner> {
    return this.api
      .get<SchoolLearner>(`${this.apiPath}/school_learners/user_key/${userKey}`)
      .pipe(map((it: any) => new SchoolLearner(it)));
  }

  deleteSchoolLearner(schoolLearner: SchoolLearner): Observable<void> {
    return this.api.delete<void>(
      `${this.apiPath}/school_learners/${schoolLearner.id}`
    );
  }

  archiveSchoolLearner(schoolLearner: SchoolLearner): Observable<void> {
    return this.api.post<void>(
      `${this.apiPath}/school_learners/${schoolLearner.id}/archive`,
      {}
    );
  }

  undoArchiveSchoolLearner(schoolLearner: SchoolLearner): Observable<void> {
    return this.api.post<void>(
      `${this.apiPath}/school_learners/${schoolLearner.id}/archive/undo`,
      {}
    );
  }

  setSchoolLearnerAsCompleted(schoolLearner: SchoolLearner): Observable<void> {
    return this.api.post<void>(
      `${this.apiPath}/school_learners/${schoolLearner.id}/setAsCompleted`,
      {}
    );
  }

  undoSetSchoolLearnerAsCompleted(
    schoolLearner: SchoolLearner
  ): Observable<void> {
    return this.api.post<void>(
      `${this.apiPath}/school_learners/${schoolLearner.id}/setAsCompleted/undo`,
      {}
    );
  }

  setSchoolLearnerIsTestAccount(
    schoolLearnerId: number,
    isTestAccount: boolean
  ): Observable<void> {
    return this.api.post<void>(
      `${this.apiPath}/school_learners/${schoolLearnerId}/setIsTestAccount`,
      { isTestAccount }
    );
  }

  setSchoolLearnerOnboardingStatsVisibility(
    schoolLearnerId: number,
    isVisibleOnOnboardingStats: boolean
  ): Observable<void> {
    return this.api.post<void>(
      `${this.apiPath}/school_learners/${schoolLearnerId}/setOnboardingStatsVisibility`,
      { isVisibleOnOnboardingStats }
    );
  }

  getNumberOfSchoolLearnersByStatus(
    schoolId: number
  ): Observable<NumberOfSchoolLearnersByStatus> {
    return this.api.get<NumberOfSchoolLearnersByStatus>(
      `${this.apiPath}/schools/${schoolId}/numberOfSchoolLearnersByStatus`
    );
  }

  getNumberOfSchoolLearners(
    schoolId: number,
    status: SchoolLearnerStatus,
    year: number,
    month: number
  ): Observable<NumberOfSchoolLearners> {
    const params = new Map<string, string>();
    params
      .set('status', '' + status)
      .set('year', '' + year)
      .set('month', '' + month);

    return this.api.get<NumberOfSchoolLearners>(
      `${this.apiPath}/schools/${schoolId}/numberOfSchoolLearners`,
      params
    );
  }

  getNumberOfSchoolLearnersAll(
    status: SchoolLearnerStatus,
    year: number,
    month: number
  ): Observable<NumberOfSchoolLearners> {
    const params = new Map<string, string>();
    params
      .set('status', '' + status)
      .set('year', '' + year)
      .set('month', '' + month);

    return this.api.get<NumberOfSchoolLearners>(
      `${this.apiPath}/numberOfSchoolLearners`,
      params
    );
  }

  getSchoolLearnersForAccounting(
    schoolId: number,
    filters: Map<string, any>,
    pageNumber: number,
    pageSize: number
  ): Observable<SchoolLearnerAccountingPageListingResponse> {
    if (!filters) {
      filters = new Map<string, any>();
    }
    const params = filters
      .set('pageNumber', '' + pageNumber)
      .set('pageSize', '' + pageSize);

    return this.api
      .get<SchoolLearnerAccountingPageListingResponse>(
        `${env.apiSchoolService}/schools/${schoolId}/learners/accounting`,
        params
      )
      .pipe(map((it) => this.mapSchoolLearnersForAccounting(it)));
  }

  getSchoolLearnersForAccountingAll(
    filters: Map<string, any>,
    pageNumber: number,
    pageSize: number
  ): Observable<SchoolLearnerAccountingPageListingResponse> {
    if (!filters) {
      filters = new Map<string, any>();
    }
    const params = filters
      .set('pageNumber', '' + pageNumber)
      .set('pageSize', '' + pageSize);

    return this.api
      .get<SchoolLearnerAccountingPageListingResponse>(
        `${env.apiSchoolService}/learners/accounting`,
        params
      )
      .pipe(map((it) => this.mapSchoolLearnersForAccounting(it)));
  }

  private mapSchoolLearnersForAccounting(
    res: SchoolLearnerAccountingPageListingResponse
  ) {
    return {
      total: res.total,
      totalCostsInCents: res.totalCostsInCents,
      records: res.records.map((it) => new SchoolLearnerAccounting(it)),
    };
  }

  getSchoolLearnersForExport(
    schoolId: number,
    month: number,
    year: number
  ): Observable<SchoolLearnerExport[]> {
    const params = new Map<string, string>();
    if (year && month) {
      params.set('year', '' + year).set('month', '' + month);
    }

    return this.api.get<SchoolLearnerExport[]>(
      `${this.apiPath}/schools/${schoolId}/getSchoolLearnersForExport`,
      params
    );
  }

  getSchoolLearnerExportDocument(
    schoolId: number,
    month: number,
    year: number
  ): Observable<any> {
    const params = new Map<string, string>();
    if (year && month) {
      params.set('year', '' + year).set('month', '' + month);
    }

    return this.api
      .getBlobWithFileName(
        `${this.apiPath}/schools/${schoolId}/getSchoolLearnerExportDocument`,
        params
      )
      .pipe(
        tap((blobWithFileName: BlobWithFileName) => {
          saveAs(blobWithFileName.blob, blobWithFileName.fileName);
        })
      );
  }

  getAllSchoolLearnerExportDocument(
    month: number,
    year: number
  ): Observable<any> {
    const params = new Map<string, string>();
    if (year && month) {
      params.set('year', '' + year).set('month', '' + month);
    }

    return this.api
      .getBlob(`${this.apiPath}/schools/getSchoolLearnerExportDocument`, params)
      .pipe(
        tap((blob: Blob) => {
          saveAs(blob, `${month}/${year}-SchoolLearnersExport.pdf`);
        })
      );
  }

  getSchoolLearnerExportCsv(
    schoolId: number,
    month: number,
    year: number
  ): Observable<any> {
    const params = new Map<string, string>();
    if (year && month) {
      params.set('year', '' + year).set('month', '' + month);
    }

    return this.api
      .getBlobWithFileName(
        `${this.apiPath}/schools/${schoolId}/getSchoolLearnerExportCsv`,
        params
      )
      .pipe(
        tap((blobWithFileName: BlobWithFileName) => {
          saveAs(blobWithFileName.blob, blobWithFileName.fileName);
        })
      );
  }

  getAllSchoolLearnerExportCsv(month: number, year: number): Observable<any> {
    const params = new Map<string, string>();
    if (year && month) {
      params.set('year', '' + year).set('month', '' + month);
    }

    return this.api
      .getBlob(`${this.apiPath}/schools/getSchoolLearnerExportCsv`, params)
      .pipe(
        tap((blob: Blob) => {
          saveAs(blob, `${month}/${year}-SchoolLearnersExport.csv`);
        })
      );
  }

  addOnlineTime(
    schoolLearnerId: number,
    timeToAddInSeconds: number
  ): Observable<any> {
    return this.api.post(
      `${this.apiPath}/school_learners/${schoolLearnerId}/addOnlineTime`,
      {
        timeToAddInSeconds: timeToAddInSeconds,
      }
    );
  }

  getTrainingConfirmationLearners(
    filters: Map<string, any>,
    pageNumber: number,
    pageSize: number
  ): Observable<TrainingConfirmationSchoolLearnerPageListingResponse> {
    if (!filters) {
      filters = new Map<string, any>();
    }
    const params = filters
      .set('pageNumber', '' + pageNumber)
      .set('pageSize', '' + pageSize);

    return this.api
      .get<TrainingConfirmationSchoolLearnerPageListingResponse>(
        `${env.apiSchoolService}/team_members/training_confirmation_learners`,
        params
      )
      .pipe(map((it) => this.mapTrainingConfirmationLearners(it)));
  }

  private mapTrainingConfirmationLearners(
    res: TrainingConfirmationSchoolLearnerPageListingResponse
  ) {
    return {
      total: res.total,
      records: res.records.map(
        (it) => new TrainingConfirmationSchoolLearner(it)
      ),
    };
  }

  getLearnerHasExamPriceSet(
    learnerId: number,
    licenseId: number,
    schoolId: number,
    examType: LearnerExamType
  ): Observable<boolean> {
    const params = new Map<string, string>()
      .set('licenseId', licenseId.toString())
      .set('schoolId', schoolId.toString())
      .set('examType', examType);

    return this.api
      .get(`${this.apiPath}/learners/${learnerId}/has_exam_price_set`, params)
      .pipe(map((it) => !!it));
  }

  getSchoolLearnersOnboardingStats(
    schoolId: number,
    params: Map<string, string>
  ): Observable<OnboardingStats> {
    return this.api.get<OnboardingStats>(
      `${this.apiPath}/schools/${schoolId}/onboarding_stats`,
      params
    );
  }

  getSchoolLearnersOnboardingStatsList(
    schoolId: number,
    pageNumber: number,
    pageSize: number,
    params: Map<string, string>
  ): Observable<PageListingResponse<SchoolLearner>> {
    return this.api
      .getPaged(
        `${this.apiPath}/schools/${schoolId}/onboarding_stats_list`,
        pageNumber,
        pageSize,
        params
      )
      .pipe(map(this.mapSchoolLearnersPaged));
  }

  getSchoolLearnersBirthdays(
    schoolId: number,
    params?: Map<string, string>
  ): Observable<SchoolLearner[]> {
    return this.api
      .get(`${this.apiPath}/schools/${schoolId}/learner_birthdays_list`, params)
      .pipe(map((res: any[]) => this.mapSchoolLearners(res)));
  }

  isNotFoundError(err): boolean {
    return (
      err &&
      (err === 'not found' ||
        (err.message && err.message === 'not found') ||
        (err.error && err.error === 'not found'))
    );
  }
}
