<div class="gallery-card">
  <i class="change-card-link previous-link fa fa-chevron-left"
     [class.hidden]="selectedItem <= 1"
     (click)="goToPreviousItem()"></i>
  <ng-content></ng-content>
  <i class="change-card-link next-link fa fa-chevron-right"
     [class.hidden]="selectedItem >= items.length"
     (click)="goToNextItem()"></i>

  <hr/>

  <div class="page-link-container" [class.justify-links-center]="justifyPageLinks === 'center'">
    <div *ngFor="let item of items" class="page-link" [class.selected]="item == selectedItem"
         (click)="onSelectItem(item)">
      {{ item }}
    </div>
  </div>
</div>
