import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { Question } from '@models/question.model';
import { QuestionStatus } from '@models/question-status.model';
import { map } from 'rxjs/operators';
import { QuestionStatistics } from '@models/question-statistics.model';
import { UserSurveyStats } from '@models/user-survey-stats.model';
import { PageListingResponse } from '@models/page-listing-response.model';
import { UserQuestion } from '@models/user-question.model';

@Injectable()
export class QuestionService {
  private readonly questionsPath = `${env.apiSurveyQuestions}`;

  constructor(private api: ApiService) {
  }

  getAllQuestions(): Observable<Question[]> {
    const params = new Map<string, any>();
    params.set('active', true);
    params.set('paused', true);
    params.set('archived', true);

    return this.api.get(`${this.questionsPath}/questions`, params)
      .pipe(
        map(questions => this.mapQuestions(questions))
      );
  }


  getQuestions(filters: Map<string, any>): Observable<Question[]> {
    return this.api.get(`${this.questionsPath}/questions`, filters)
      .pipe(
        map(questions => this.mapQuestions(questions))
      );
  }

  saveQuestion(question: Question): Observable<Question> {
    return this.api.post<Question>(`${this.questionsPath}/questions`, question);
  }

  getQuestion(id: number): Observable<Question> {
    return this.api.get<Question>(`${this.questionsPath}/questions/${id}`)
      .pipe(
        map(it => new Question(it))
      );
  }

  updateQuestionStatus(questionID: number, status: QuestionStatus): Observable<Question> {
    const body = {'status': status};
    return this.api.patch<Question>(`${this.questionsPath}/questions/${questionID}`, body)
      .pipe(
        map(res => new Question(res)));
  }

  getCities() {
    return this.api.get(`${this.questionsPath}/cities`);
  }

  private mapQuestions(questions) {
    return questions.map(it => new Question(it));
  }

  getQuestionStatistics(questionId: number): Observable<QuestionStatistics> {
    return this.api.get<QuestionStatistics>(`${this.questionsPath}/questions/${questionId}/statistics`)
      .pipe(
        map(it => new QuestionStatistics(it))
      );
  }

  reorderQuestions(questionIds: number[]): Observable<any> {
    return this.api.post<Question>(`${this.questionsPath}/questions/reorder`, {questionIds: questionIds});
  }

  getUserSurveyQuestionsPaged(learnerId: number, pageNumber: number, pageSize: number, filters?: Map<string, string>): Observable<PageListingResponse<UserQuestion>> {
    return this.api.getPaged(`${this.questionsPath}/learnerQuestions/${learnerId}`, pageNumber, pageSize, filters)
      .pipe(
        map(this.mapUserSurveyQuestionsPaged)
      );
  }

  getUserSurveyStats(learnerId: number): Observable<UserSurveyStats>  {
    return this.api.get<UserSurveyStats>(`${this.questionsPath}/learnerSurveyStats/${learnerId}`)
      .pipe(
        map(it => new UserSurveyStats(it))
      );
  }

  private mapUserSurveyQuestionsPaged(res: PageListingResponse): PageListingResponse<UserQuestion> {
    return {
      total: res.total,
      records: res.records.map(it => new UserQuestion(it))
    };
  }
}
