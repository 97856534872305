<div class="lp-background">
  <img src="/assets/header-tc.png"/>
  <div class="container">
    <div class="row no-side-space">
      <div class="col-12 col-lg-10 offset-lg-1">
        <h1 class="lp-title">Datenschutz für App</h1>

        <p class="tc-section">Im Rahmen der Nutzung dieser Website werden personenbezogene Daten von Ihnen durch uns als
          den für die
          Datenverarbeitung Verantwortlichen verarbeitet und für die Dauer gespeichert, die zur Erfüllung der
          festgelegten Zwecke und gesetzlicher Verpflichtungen erforderlich ist. Im Folgenden informieren wir Sie
          darüber, um welche Daten es sich dabei handelt, auf welche Weise sie verarbeitet werden und welche Rechte
          Ihnen diesbezüglich zustehen.
          <br/>
          Personenbezogene Daten sind gemäß Art. 4 Nr. 1 Datenschutzgrundverordnung
          (DSGVO) alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im
          Folgenden “betroffene Person”) beziehen.
        </p>
        <h5 class="lp-section-title">1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen</h5>
        <p class="tc-section">Diese Datenschutzinformation gilt für die Datenverarbeitung auf der Webseite <a
          href="http://www.driveddy.com" class="external-link" rel="nofollow">www.driveddy.com</a> durch den
          Verantwortlichen:
          <br/>
          DrivEddy GmbH (im Folgenden „DrivEddy“),
          <br/>
          Monbijouplatz 4
          <br/>
          10478 Berlin
          <br/>
          Tel.:&nbsp;+49 30 4036 6610-0
          <br/>
          E-Mail: <a href="mailto:info@driveddy.com" class="external-link"
                     rel="nofollow">info@driveddy.com</a></p>

        <div class="tc-section">
          <br/>
          Datenschutzbeauftragter
          <div class="lp-small-top-padding">
            Der/die betriebliche Datenschutzbeauftragte von Fresh Compliance ist unter der o.g.
            Anschrift, zu Hd.
            Abteilung Datenschutz, bzw. unter <a href="mailto:datenschutz@driveddy.com" class="external-link"
                                                 rel="nofollow">datenschutz@driveddy.com</a>&nbsp;erreichbar.
          </div>
        </div>
        <h5 class="lp-section-title">
          2. Verarbeitung und Speicherdauer personenbezogener Daten sowie Art und Zweck deren Verwendung
        </h5>
        <div class="tc-section">
          a) Beim Besuch der DrivEddy-App
          <div class="lp-small-top-padding">
            Üblicherweise werden Daten des Endgerätes erhoben, welches die App öffnet. Dabei handelt es sich um
            folgende Gerätespezifische Informationen:
            <br/>
            • Device-ID
            <br/>
            • Mac-Adresse
            <br/>
            • IMEI
            <br/>
            • IMSI
            <br/>
            • UDID
            <br/>
            •
            IDFA
            <br/>
            • Android-Werbe-ID
            <br/>
            Einige Zugriffsrechte werden bei Android – Geräten von der App vor
            der Installation eingefordert. Diese Berechtigungen werden nur bei Android Geräten ab 6.0 oder höher
            erfragt.
            <br/><br/>
            Nach der Installation wird bei iOs-Geräten die optionale Aktivierung von Push-, Mikro- oder
            Diktierfunktion erfragt. Die Push-Funktion wird genutzt, damit Nachrichten von der App auf Ihr Gerät
            gesendet und dort priorisiert dargestellt werden können. Die Mikro- und Diktierfunktion wird für die
            Sprachsuche verwendet. Weiterhin werden folgende Berechtigungen eingeholt:
            <br/>
            • Internet
            <br/>
            • Write external
            storage (für den Zugriff auf Bilddateien)
            <br/>
            • Access Network State (z.B. ob das Gerät offline ist, u.a. für
            Google Analytics)
            <br/>
            • Vibrate (für Push Benachrichtigungen)
          </div>
        </div>
        <div class="tc-section lp-small-top-padding">
          Zweck der Datenverarbeitung ist es, Ihnen zielgerichtete Push-Nachrichten sowie
          In-App-Kommunikation
          zusenden zu können. Android-Geräte haben die standardmäßige Voreinstellung, dass Push-Nachrichten
          automatisch aktiviert sind. Diese Standardeinstellung kann während des Registrierungsvorgangs und jederzeit
          in der App im Profil unter „Einstellungen“ widerrufen werden. Apple-Geräte haben die standardmäßige
          Voreinstellung, dass Push-Nachrichten automatisch deaktiviert sind, sollten Sie den Erhalt von
          Push-Nachrichten wünschen, so aktivieren Sie hier bitte diese Funktion im Profil unter „Einstellungen“.
          <br/><br/>
          Die Datenverarbeitung Ihrer personenbezogenen Daten erfolgt auf der Grundlage des Art. 6 Abs. 1 S. 1 lit. b
          DSGVO zur Erfüllung des Vertrags zur Nutzung der DrivEddy-App.
          <br/>
          Zudem erfolgt die Verarbeitung auf
          Grundlage unserer berechtigten Interessen nach Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unsere Interessen ergeben
          sich als berechtigt im Sinne der vorgenannten Vorschrift und folgen aus den oben aufgelisteten Zwecken zur
          Datenverarbeitung.
        </div>
        <div class="tc-section">
          <br/>
          b) Bei Einrichtung eines Nutzerkontos
          <div class="lp-small-top-padding">
            <div>Registrierung/ Anlegen User-Account</div>
            <strong>Die DrivEddy-App kann grundsätzlich ohne Registrierung genutzt werden. Wenn Sie
              jedoch alle
              Funktionen der App nutzen möchten, können Sie sich als Nutzer registrieren lassen.
              <br/>
              Hierfür haben Sie
              die
              Möglichkeit, sich bei uns ein passwortgeschütztes Benutzerkonto einzurichten, in dem wir Ihre persönlichen
              Daten speichern. Dies dient dem Zweck, Ihnen </strong>den Zugang zu unserem Angebot zu
            ermöglichen.<strong>
            <br/>
          </strong>Wenn Sie bei uns ein passwortgeschütztes Benutzerkonto einrichten möchten,
            benötigen wir von Ihnen folgende Informationen:<strong>
            <br/>
          </strong>• eine gültige E-Mail-Adresse<strong>
            <br/>
          </strong>
          </div>


          <div class="tc-section lp-small-top-padding">Zusätzlich müssen Sie für die Einrichtung eines Benutzerkontos
            ein
            selbstgewähltes Passwort angeben.
            Zusammen mit Ihrer E-Mail-Adresse oder Ihres Benutzernamens ermöglicht dies den Zugang zu Ihrem
            Benutzerkonto. In Ihrem Benutzerkonto können Sie die zu Ihnen gespeicherten Daten jederzeit einsehen und
            ändern.
          </div>

          <div>
            <br/>
            Social Sign-In
          </div>
          <div>Neben der manuellen Registrierung bieten wir Ihnen die Möglichkeit, sich direkt mit ihrem bestehenden
            Benutzerkontos eines sozialen Netzwerks von ausgewählten Anbietern bei uns
            anzumelden.
          </div>

          <div>
            <br/>
            Registrierung/Anmeldung über Facebook
          </div>

          <div>
            Wir bieten Ihnen die Möglichkeit, sich über Ihr Facebook-Konto bei uns zu registrieren bzw. anzumelden.
            Wenn
            Sie hiervon Gebrauch machen, erhalten wir die für die Registrierung bzw. Anmeldung benötigten Daten (z. B.
            E-Mail-Adresse, Name) von Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA („Facebook“).
            <br/>
            Wir haben keinen Einfluss auf den Umfang der von Facebook mittels des Facebook-Login erhobenen Daten. Wenn
            Sie nicht möchten, dass Facebook im Zusammenhang mit Ihrer Nutzung unseres Online-Angebots Daten über Sie
            erhebt und für eigene Zwecke nutzt, sollten Sie den Facebook-Login nicht verwenden.
            <br/>
            Weitere Informationen über Zweck und Umfang der Erhebung sowie die weitere Verarbeitung und Nutzung Ihrer
            Daten durch Facebook sowie zu Ihren Rechten und
          </div>

          <div>
            <br/>
            Registrierung/Anmeldung über Apple
          </div>

          <div>
            Wir bieten Ihnen die Möglichkeit, sich über Ihr Apple-Konto bei uns zu registrieren bzw. anzumelden. Wenn
            Sie hiervon Gebrauch machen, erhalten wir die für die Registrierung bzw. Anmeldung benötigten Daten (z. B.
            E-Mail-Adresse, Name) von Apple Inc., One Apple Park Way, Cupertino, California, USA, 95014 („Apple“).
            <br/>
            Wir haben keinen Einfluss auf den Umfang der von Apple mittels des Apple-Login erhobenen Daten. Wenn Sie
            nicht möchten, dass Apple im Zusammenhang mit Ihrer Nutzung unseres Online-Angebots Daten über Sie erhebt
            und für eigene Zwecke nutzt, sollten Sie den Apple-Login nicht verwenden.
            <br/>
            Weitere Informationen über Zweck und Umfang der Erhebung sowie die weitere Verarbeitung und Nutzung Ihrer
            Daten durch Apple sowie zu Ihren Rechten und Einstellungsmöglichkeiten zum Schutz Ihrer Daten finden Sie
            in
            den Datenschutzhinweisen von Apple.
            <br/>
            Als Schutzmaßnahme erfolgt die Übermittlung der von Ihnen eingegebenen Daten über eine verschlüsselte
            Verbindung der jeweiligen Plattform. Wir verwenden die Anmeldung nicht, um auf persönliche Daten wie
            Freundeslisten oder Kontakte zuzugreifen oder diese für eigene Zwecke zu speichern. Welche Daten die
            sozialen Netzwerke im Zuge der Anmeldung erfassen oder wie Daten verknüpft werden, ist uns nicht bekannt.
            Zweck der abgefragten Daten ist die Anmeldung über ein bestehendes Benutzerkontos zur Nutzung erweiterter
            Funktionen auf der Webseite. Die Anmeldung über die sozialen Netzwerke erfolgt freiwillig und kann
            jederzeit
            widerrufen bzw. das Benutzerkonto abgemeldet werden. Rechtsgrundlage ist Ihre Einwilligung in Einklang mit
            den europäischen Datenschutzanforderungen aus Art. 6 Abs. 1 lit. a DSGVO.
          </div>

          <div>
            <br/>
            Freiwillige Datenangaben im Registrierungsprozess
          </div>

          <br/>
          Darüber hinaus besteht die Möglichkeit zur Angabe folgender freiwilliger Angaben:
          <br/>
          • Name,
          <br/>
          • Geburtsdatum (Alter),
          <br/>
          • Standort,
          <br/>
          • Geschlecht

          <div class="tc-section lp-small-top-padding"><strong>Die Erhebung dieser Daten erfolgt,
            <br/>
            • um Kenntnis zu haben, wer unser Vertragspartner ist;
            <br/>
            • zur Begründung, inhaltlichen Ausgestaltung, Abwicklung und Änderung des Vertragsverhältnisses mit Ihnen
            über
            Ihre Bestellung;
            <br/>
            • zur Überprüfung der eingegebenen Daten auf Plausibilität.</strong></div>
          <div class="tc-section lp-small-top-padding">Die Verarbeitung Ihrer persönlichen Daten erfolgt auf Ihre
            Anfrage
            hin und ist nach Art. 6
            Abs. 1 S. 1 lit.
            b DSGVO zu den vorgenannten Zwecken für die Nutzung unserer Produkte und somit für die Erfüllung des
            Vertrags und vorvertraglicher Maßnahmen erforderlich.
            <br/>
            Nach Löschung Ihres Benutzerkontos werden Ihre
            Daten für die weitere Verwendung automatisch gelöscht, es sei denn, dass wir nach Artikel 6 Abs. 1 S. 1 lit.
            c DSGVO aufgrund von steuer- und handelsrechtlichen Aufbewahrungs- und Dokumentationspflichten (aus HGB,
            StGB oder AO) zu einer längeren Speicherung verpflichtet sind oder Sie in die darüber hinausgehende
            Speicherung nach Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben.
          </div>


        </div>

        <div class="tc-section">
          <br/>
          c) Ausbildungsdaten

          <div>
            <div>Es werden Ausbildungsdaten erhoben:</div>
            • Anzahl der insgesamt, richtig und falsch beantworteten Fragen (u.a. nach Themen-Kategorie)
            <br/>
            • Lernzeit in der App
            <br/>
            • Anzahl der Test Simulationen
            <br/>
            • Anzahl der gebuchte, abgesagt und absolvierten Theorie- und Praxisstunden
            <br/>
            • Anzahl der Bezahlvorgänge
            <br/>
            • Angaben zu bereits absolvierten Führerscheinklassen
            <br/>
            • Angaben zur gewünschten Führerscheinklasse
          </div>
        </div>

        <div class="tc-section">
          <br/>
          d) Bei der Teilnahme an unseren Umfragen
          <div class="lp-small-top-padding">
            Als registrierter Nutzer haben Sie die Möglichkeit, an den in unserer App durchgeführten
            Umfragen
            teilzunehmen. Neben der Speicherung der Fragen und Antworten der jeweiligen Umfrage werden die Ergebnisse
            von uns ausgewertet, analysiert und in einem Kundenprofil gespeichert.
            <br/>
            Sofern Sie in den Empfang unseres
            Newsletters eingewilligt haben, können wir Ihnen anhand der Angaben, die Sie in unseren Umfragen machen,
            gezielte Produktempfehlungen als personalisierte Newsletter zusenden.
            <br/>
            Die Teilnahme an unseren Umfragen ist freiwillig.
            <br/>
            Die
            Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f DSGVO, um unsere Newsletter zu
            optimieren. Darin ist ein berechtigtes Interesse im Sinne der genannten Vorschrift zu sehen.
          </div>
        </div>
        <div class="tc-section">
          <br/>
          e) Bei der Anmeldung zu unserem Newsletter
          <div class="lp-small-top-padding">
            Sie haben die Möglichkeit sich für den in unserer App zur Verfügung gestellten Newsletter
            anzumelden.
            Sofern Sie ausdrücklich eingewilligt haben, verwenden wir Ihre E-Mail-Adresse dafür, Ihnen regelmäßig
            unseren personalisierten Newsletter zu übersenden.
            <br/>
            Für die Anmeldung zu unseren Newsletter ist die
            Eingabe folgender Daten zwingend erforderlich:
            <br/>
            • eine gültige E-Mail-Adresse.
          </div>
        </div>
        <div class="tc-section">Darüber hinaus besteht die Möglichkeit zur Angabe folgender freiwilliger Angaben:
          <br/>
          • Ihr
          Geschlecht,
          <br/>
          •
          Ihr Name.
        </div>
        <div class="tc-section">Um sicher gehen zu können, dass bei der Eingabe der Emailadresse keine Fehler
          unterlaufen
          sind, setzen wir
          das sog. Double-Opt-In-Verfahren ein: Nachdem Sie Ihre Email-Adresse in das Anmeldefeld eingegeben haben,
          übersenden wir Ihnen einen Bestätigungslink. Erst wenn Sie diesen Bestätigungslink anklicken, wird Ihre
          Email-Adresse in unseren Verteiler aufgenommen.
          <br/>
          Die Abmeldung ist jederzeit möglich, z.B. über einen Link
          am Ende eines jeden Newsletters. Alternativ können Sie Ihren Abmeldewunsch gerne auch jederzeit an
          <a href="mailto:info@driveddy.com" class="external-link" rel="nofollow">info@driveddy.com</a> per E-Mail
          senden.
          <br/>
          Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. a DSGVO. In keinem Fall
          verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf ihre Person zu ziehen.
        </div>

        <h5 class="lp-section-title">3. Weitergabe von personenbezogenen Daten an Dritte</h5>
        <div class="tc-section">Soweit dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die
          Abwicklung von
          Vertragsverhältnissen mit Ihnen oder gem. Art. 6 Abs. 1 S. 1 lit. f zur Wahrung unserer Interessen oder der
          Dritter erforderlich ist, werden Ihre personenbezogenen Daten an Dritte weiter gegeben. Die weitergegebenen
          Daten dürfen von dem Dritten ausschließlich zu den genannten Zwecken verwendet werden.
          <br/>
          Eine Weitergabe
          von personenbezogenen Daten kommt darüber hinaus allenfalls in Betracht, wenn
          <br/>
          • Sie gem. Art. 6 Abs. 1 S.
          1 lit. a DSGVO Ihre ausdrückliche Einwilligung dazu erteilt haben, sowie
          <br/>
          • für den Fall, dass für die
          Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht.
          <br/>
          Eine Übermittlung
          von personenbezogenen Daten an ein Drittland oder eine internationale Organisation ist ausgeschlossen.
        </div>

        <h5 class="lp-section-title">4. Cookies</h5>
        <div class="tc-section">Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die
          Ihr Browser
          automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn
          Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an, enthalten keine Viren,
          Trojaner oder sonstige Schadsoftware.
          <br/>
          In dem Cookie werden Informationen abgelegt, die sich jeweils im
          Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch
          unmittelbar Kenntnis von Ihrer Identität erhalten.
          <br/>
          Der Einsatz von Cookies dient einerseits dazu, die
          Nutzung unseres Angebots für Sie angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um
          zu erkennen, dass Sie einzelne Seiten unserer App bereits besucht haben.
          <br/>
          Darüber hinaus setzen wir
          ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten
          festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut, um unsere
          Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben
          und Einstellungen sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.
          <br/>
          Zum anderen setzen
          wir Cookies ein, um die Nutzung unserer App statistisch zu erfassen und zum Zwecke der Optimierung unseres
          Angebotes für Sie auszuwerten (siehe Ziffer 5). Diese Cookies ermöglichen es uns, bei einem erneuten Besuch
          unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer
          jeweils definierten Zeit automatisch gelöscht.
          <br/>
          Die durch Cookies verarbeiteten Daten sind für die
          genannten Zwecke zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit.
          f DSGVO erforderlich.
          <br/>
          Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser
          jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis
          erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu
          führen, dass Sie nicht alle Funktionen unserer App nutzen können.
        </div>

        <h5 class="lp-section-title">5. Webanalyse</h5>
        <div class="tc-section">Die im Folgenden aufgeführten und von uns eingesetzten Tracking- und Targeting-Maßnahmen
          werden auf
          Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO durchgeführt.
          <br/>
          Mit den zum Einsatz kommenden
          Tracking-Maßnahmen wollen wir eine bedarfsgerechte Gestaltung und die fortlaufende Optimierung unserer App
          sicherstellen. Zum anderen setzen wir die Tracking-Maßnahmen ein, um die Nutzung unserer App statistisch zu
          erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten.
          <br/>
          Über die zum Einsatz
          kommenden Targeting-Maßnahmen wollen wir sicherstellen, dass Ihnen nur an Ihren tatsächlichen oder
          vermeintlichen Interessen orientierte Werbung auf Ihren Endgeräten eingeblendet wird.
          <br/>
          Diese Interessen
          sind als berechtigt im Sinne der vorgenannten Vorschrift anzusehen.
          <br/>
          Die jeweiligen
          Datenverarbeitungszwecke und Datenkategorien sind den entsprechenden Tracking- und Targeting-Tools zu
          entnehmen.
        </div>
        <div class="tc-section">
          <br/>
          a) Google Analytics
          <div class="lp-small-top-padding">
            Wir nutzen in unserer App Google Analytics, einen Webanalysedienst der Google LLC. (1600
            Amphitheatre
            Parkway, Mountain View, CA 94043, USA; im Folgenden „Google“). Google verwendet Cookies. In dem Cookie
            werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät
            ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten. Die
            durch das Cookie erzeugten Informationen über die Benutzung des Onlineangebotes durch die Nutzer werden in
            der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
            <br/>
            Google wird diese
            Informationen in unserem Auftrag benutzen, um die Nutzung unserer App durch die Nutzer auszuwerten, um
            Reports über die Aktivitäten innerhalb dieser App zusammenzustellen und um weitere mit der Nutzung dieser
            App verbundene Dienstleistungen uns gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten
            pseudonyme Nutzungsprofile der Nutzer erstellt werden.
            <br/>
            Wir setzen Google Analytics nur mit aktivierter
            IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer wird von Google innerhalb von Mitgliedstaaten
            der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
            gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen
            und dort gekürzt. Die vom Nutzer übermittelte IP-Adresse wird nicht mit anderen Daten von Google
            zusammengeführt.
            <br/>
            Google beachtet die Datenschutzbestimmungen des US-EU Safe-Harbor-Abkommen und ist bei
            den entsprechenden “Safe Harbor”-Programmen des US-Handelsministeriums registriert.
            <br/>
            Sie können die
            Installation der Cookies durch eine entsprechende Einstellung der Browser-Software verhindern. Wir weisen
            jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser App vollumfänglich
            genutzt werden können.
            <br/>
            Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf
            Ihre Nutzung der App bezogenen Daten (ggf. inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch
            Google verhindern, indem Sie ein Browser-Add-on herunterladen und installieren.
            <br/>
            Alternativ zum
            Browser-Add-on, insbesondere bei Browsern auf mobilen Endgeräten, können Sie die Erfassung durch Google
            Analytics zudem verhindern, indem Sie auf diesen Link klicken. Es wird ein Opt-out-Cookie gesetzt, das die
            zukünftige Erfassung Ihrer Daten beim Besuch dieser App verhindert. Der Opt-out-Cookie gilt nur in diesem
            Browser und nur für unsere App und wird auf Ihrem Gerät abgelegt. Löschen Sie die Cookies in diesem Browser,
            müssen Sie das Opt-out-Cookie erneut setzen.
            <br/>
            Weitere Informationen zum Datenschutz im Zusammenhang mit
            Google Analytics finden Sie etwa in der Google Analytics-Hilfe.
          </div>
        </div>

        <div class="tc-section">
          <br/>
          b) Google-Firebase
          <div class="lp-small-top-padding">
            Ferner nutzen wir den Google Service Firebase zur Analyse und Kategorisierung von
            Nutzergruppen sowie zur
            Ausspielung von Push-Mitteilungen. Firebase ist eine Echtzeit-Datenbank, mit der sich Echtzeitinformationen
            in die eigene Website einbetten lassen. Hierbei werden die Nutzerdaten anonymisiert an Firebase übermittelt.
            Firebase ist eine Google-Tochter und hat seinen Sitz in San Francisco (CA), USA. Die Datenschutzerklärung
            von Firebase finden Sie unter <a href="https://www.firebase.com/terms/privacy-policy.html"
                                             class="external-link" rel="nofollow">https://www.firebase.com/terms/privacy-policy.html</a>.
          </div>
        </div>
        <div class="tc-section">
          <br/>
          c) Facebook Custom Audience
          <div class="lp-small-top-padding">
            Wir setzen Facebook Custom Audiences der Facebook Ireland Limited (4 Grand Canal Square,
            Dublin 2, Irland)
            ein. Hierbei handelt es sich um einen Marketingdienst von Facebook. Dieser ermöglicht es uns, bestimmten
            Gruppen von pseudonymisierten Besuchern unserer App, die auch Facebook nutzen, individuell abgestimmte und
            interessenbezogene Werbung auf Facebook anzeigen zu lassen.
            <br/>
            Auf unserer App ist ein Facebook Custom
            Audience-Pixel-Tag integriert. Hierbei handelt es sich um einen Java Script-Code, über den
            nicht-personenbezogene Daten über die Nutzung der App gespeichert werden. Dazu gehören Ihre IP-Adresse, der
            genutzte Browser sowie Ausgangs- und Zielseite. Diese Informationen werden an Server von Facebook in den USA
            übertragen. Facebook unterliegt dem EU-US Privacy Shield, sodass ein angemessenes Datenschutzniveau
            garantiert wird.
            <br/>
            Dort wird automatisiert abgeglichen, ob Sie einen Facebook-Cookie gespeichert haben.
            Über den Facebook-Cookie wird automatisch ermittelt, ob Sie zu der für uns relevanten Zielgruppe gehören.
            Gehören Sie zu der Zielgruppe, werden Ihnen bei Facebook entsprechende Anzeigen von uns angezeigt. Bei
            diesem Vorgang werden Sie durch den Abgleich der Daten weder durch uns noch durch Facebook persönlich
            identifiziert.
            <br/>
            Sie können der Verwendung des Dienstes Custom Audiences auf der Website von Facebook
            widersprechen. Nach Anmeldung zu Ihrem Facebook-Konto gelangen Sie zu den Einstellungen für
            Facebook-Werbeanzeigen.
            <br/>
            Nähere Informationen zum Datenschutz bei Facebook finden Sie in der
            Datenschutzrichtlinie von Facebook.
          </div>
        </div>

        <h5 class="lp-section-title">6. Betroffenenrechte</h5>
        <div class="tc-section">
          Sie haben das Recht:
          <br/>
          • gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung
          jederzeit gegenüber
          uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte,
          für die Zukunft nicht mehr fortführen dürfen;
          <br/>
          • gemäß Art. 15 DSGVO Auskunft über Ihre von uns
          verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die
          Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber
          denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf
          Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines
          Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das
          Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen
          Informationen zu deren Einzelheiten verlangen;
          <br/>
          • gemäß Art. 16 DSGVO unverzüglich die Berichtigung
          unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;
          <br/>
          • gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit
          nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung
          einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung
          oder Verteidigung von Rechtsansprüchen erforderlich ist;
          <br/>
          • gemäß Art. 18 DSGVO die Einschränkung der
          Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen
          bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht
          mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
          benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
          <br/>
          • gemäß Art.
          20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen
          und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu
          verlangen und
          <br/>
          • gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können
          Sie sich hierfür an die Aufsichtsbehörde ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres
          Unternehmenssitzes wenden.
        </div>

        <h5 class="lp-section-title">7. Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h5>
        <div class="tc-section">Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben,
          jederzeit gegen die
          Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Artikel 6 Absatz 1 Buchstabe e DSGVO
          (Datenverarbeitung im öffentlichen Interesse) und Artikel 6 Absatz 1 Buchstabe f DSGVO (Datenverarbeitung
          auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf
          diese Bestimmung gestütztes Profiling von Artikel 4 Nr. 4 DSGVO.
          <br/>
          Legen Sie Widerspruch ein, werden wir
          Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe
          für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die
          Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
          <br/>
          Sofern sich Ihr
          Widerspruch gegen eine Verarbeitung von Daten zum Zwecke der Direktwerbung richtet, so werden wir die
          Verarbeitung umgehend einstellen. In diesem Fall ist die Angabe einer besonderen Situation nicht
          erforderlich. Dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
          <br/>
          Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an <a
            href="mailto:datenschutz@driveddy.com"
            class="external-link"
            rel="nofollow">datenschutz@driveddy.com</a>
        </div>

        <h5 class="lp-section-title">8. Datensicherheit</h5>
        <div class="tc-section">Wir bedienen uns um geeigneter technischer und organisatorischer Sicherheitsmaßnahmen,
          um Ihre bei uns gespeicherten persönlichen Daten gegen Manipulation, teilweisen oder vollständigen Verlust und
          gegen unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der
          technologischen Entwicklung fortlaufend verbessert.
        </div>

        <h5 class="lp-section-title">9. Aktualität und Änderung dieser Datenschutzinformation</h5>
        <div class="tc-section">Diese Datenschutzinformation ist aktuell gültig und hat den Stand Juni 2019.
          <br/>
          Durch
          die Weiterentwicklung unserer App und Angebote darüber oder aufgrund geänderter gesetzlicher bzw. behördlicher
          Vorgaben kann es notwendig werden, diese Datenschutzinformation zu ändern. Die jeweils aktuelle
          Datenschutzinformation kann jederzeit unter&nbsp;<a href="https://www.driveddy.com" class="external-link"
                                                              rel="nofollow">https://www.driveddy.com</a>
          im Reiter Datenschutz (o.ä.)&nbsp;von Ihnen abgerufen und ausgedruckt werden.
        </div>
      </div>
    </div>
  </div>
</div>
