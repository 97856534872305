import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { AppRoutes } from '../../../app.routes';
import { emailValidator } from '@validators/email.validator';

@Component({
  selector: 'eddy-user-doi',
  templateUrl: './user-doi.component.html',
  styleUrls: ['./user-doi.component.scss'],
})
export class UserDoiComponent implements OnInit {
  accepted: boolean;

  formIsValid = true;
  loading = false;

  codeFormGroup: FormGroup;
  emailFormGroup: FormGroup;

  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    switch (this.activatedRoute.snapshot.queryParams['accepted']) {
      case 'true':
        this.accepted = true;
        break;
      case 'false':
        this.accepted = false;
        break;
    }

    this.codeFormGroup = new FormGroup({
      code: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ]),
    });

    this.emailFormGroup = new FormGroup({
      email: new FormControl('', [Validators.required, emailValidator]),
    });
  }

  onVerifyDoiCode() {
    this.loading = true;
    this.authService.verifyUserViaCode(this.codeFormGroup.value.code).subscribe(
      _res => {
        this.loading = false;
        this.accepted = true;
      },
      _fail => {
        this.loading = false;
        this.accepted = false;
        this.formIsValid = false;
      }
    );
  }

  onResendDoiCode() {
    this.loading = true;
    this.authService.resendVerificationCode().subscribe(_res => {
      this.loading = false;
      this.accepted = undefined;
      this.notifyAboutResend();
    });
  }

  onContinue() {
    this.router.navigate([AppRoutes.VendorConsole.dashboard]).then(_closed => {
    });
  }

  onResendEmail() {
    this.loading = true;
    this.authService
      .resendVerificationCodeForEmail(this.emailFormGroup.value.email)
      .subscribe(_res => {
        this.loading = false;
        this.accepted = undefined;
        this.notifyAboutResend();
      });
  }

  notifyAboutResend() {
    this.snackBar.open('Bestätigungscode zugesendet', 'Ok', {duration: 2000});
  }
}
