import { School } from '@models/school.model';

export class EddyClubSchoolDetails {
  school: School;
  freePracticeLessonEvents: number;
  activeLearners: number;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.school = new School(json.school);
    this.freePracticeLessonEvents = +json.freePracticeLessonEvents;
    this.activeLearners = +json.activeLearners;
  }
}
