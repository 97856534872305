import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { throwError } from 'rxjs';


import { environment as env } from '../../environments/environment';

import { City } from '@models/city.model';

import { AuthService } from './auth.service';

@Injectable()

export class CityService {

  constructor(private http: HttpClient,
              private authService: AuthService) {
  }

  getTopCities() {
    return this.http.get<City[]>(`${env.api}/city/top`, this.authService.getHttpOptions())
      .pipe(
        map(res => res['data']),
        catchError(err => throwError(err))
      );
  }
}
