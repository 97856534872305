import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';

@Injectable()
export class ScreenWidthService {

  constructor(private breakpointObserver: BreakpointObserver) {
  }

  isXSScreen(): Observable<boolean> {
    return this.breakpointObserver
      .observe(['(max-width: 350px)'])
      .pipe(
        map((state: BreakpointState) => state.matches)
      );
  }

  isTablet(): Observable<boolean> {
    return this.breakpointObserver
      .observe(['(min-width: 769px) and (max-width: 1199px)'])
      .pipe(
        map((state: BreakpointState) => state.matches)
      );
  }

  isDesktop(): Observable<boolean> {
    return this.breakpointObserver
      .observe(['(min-width: 768px)'])
      .pipe(
        map((state: BreakpointState) => state.matches)
      );
  }

  isLargeDesktop(): Observable<boolean> {
    return this.breakpointObserver
      .observe(['(min-width: 1600px)'])
      .pipe(
        map((state: BreakpointState) => state.matches)
      );
  }

  isExtraLargeDesktop(): Observable<boolean> {
    return this.breakpointObserver
      .observe(['(min-width: 1921px)'])
      .pipe(
        map((state: BreakpointState) => state.matches)
      );
  }

  isXXLargeDesktop(): Observable<boolean> {
    return this.breakpointObserver
      .observe(['(min-width: 2400px)'])
      .pipe(
        map((state: BreakpointState) => state.matches)
      );
  }
}
