<div class="dialog-container">
  <div class="close-button" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </div>
  <div class="video-wrapper">
    <img [class.display-none]="videoPlaying" [src]="thumbnailImgSrc"/>
    <button class="play-btn" [class.display-none]="videoPlaying" (click)="playVideo()">
      <i class="fas fa-play"></i>
    </button>

    <eddy-video-player #videoPlayer
                        [class.display-none]="!videoPlaying"
                        [videoUrl]="videoUrl"
                        [showControls]="showControls"
                        (videoEnd)="onVideoEnd()">
    </eddy-video-player>
  </div>
  <div class="demo-btn-container" *ngIf="buttonLabel">
    <button class="demo-btn" (click)="onButton()"><i *ngIf="buttonIcon" class="{{buttonIcon}} margin-right-15"></i>{{ buttonLabel }}
    </button>
  </div>
</div>
