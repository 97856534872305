import { AbstractControl, ValidationErrors } from '@angular/forms';

export function requiredNonBlank(control: AbstractControl): ValidationErrors | null {
  return isNotBlank(control.value) ? null : {'emptyValue': control.value};
}

export function isNotBlank(input: any): boolean {
  if (!input) {
    return false;
  }

  if (input.trim && input.trim().length === 0) {
    return false;
  }

  return true;
}
