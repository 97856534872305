import { Credit } from './credit.model';

export class Refund {
    id: number;

    createdAt: Date;
    updatedAt: Date;

    creditId: number;
    schoolLearnerId?: number;

    credit?: Credit;
    reverted: boolean;

    refundDocumentId?: number;
    refundDocument?: any;

    receiptNumber: string;
    originalInvoiceNumber?: string;

    constructor(json?: any) {
        if (!json) {
          return;
        }
    
        this.id = +json.id;
        this.createdAt = new Date(json.createdAt);
        this.updatedAt = new Date(json.updatedAt);

        this.creditId = +json.creditId;
        if (json.schoolLearnerId) {
            this.schoolLearnerId = +json.schoolLearnerId;
        }

        if (json.credit) {
            this.credit = new Credit(json.credit);
        }
        this.reverted = !!json.reverted;

        if (json.refundDocumentId) {
            this.refundDocumentId = +json.refundDocumentId;
        }
        if (json.refundDocument) {
            this.refundDocument = json.refundDocument;
        }

        this.receiptNumber = json.receiptNumber;
        this.originalInvoiceNumber = json.originalInvoiceNumber;
    }
}
