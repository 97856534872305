<ng-select class="learner-type-select custom-height-32"
           [class.full-width]="fullWidth"
           [items]="learnerTypes" placeholder="Fahrschüler"
           (change)="onChange($event)" [clearable]="true" notFoundText="">
  <ng-template ng-option-tmp let-item="item">
    <div class="select-value-wrapper">
      <div class="learner-type-indicator {{item.toLowerCase()}}"></div>
      {{ getLearnerTypeDisplayName(item) }}
    </div>
  </ng-template>
  <ng-template ng-label-tmp let-item="item">
    <div class="select-value-wrapper">
      <div class="learner-type-indicator {{item.toLowerCase()}}"></div>
      {{ getLearnerTypeDisplayName(item) }}
    </div>
  </ng-template>
</ng-select>
