export class LearnerAccountBalance {
  accountBalanceInCents: number;
  availableCreditsInCents: number;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.accountBalanceInCents = +json.accountBalanceInCents;
    this.availableCreditsInCents = +json.availableCreditsInCents;
  }

  isZero() {
    return this.accountBalanceInCents === 0;
  }

  isNegative() {
    return this.accountBalanceInCents < 0;
  }

  realBalanceInCents(): number {
    if (this.isNegative() && this.availableCreditsInCents > 0) {
      return Math.min(
        this.accountBalanceInCents + this.availableCreditsInCents,
        0
      );
    }

    return null;
  }
}
