export interface AdEvent {
  eventType: AdEventType;
  campaignId: string;
  campaignName: string;
  adId: string;
  source: string;
  space: string;
}

export enum AdEventType {
  AD_IMPRESSION = 'AD_IMPRESSION',
  AD_CLICK = 'AD_CLICK'
}

const websiteSourceName = 'website';

export function buildAdImpressionEvent(campaignId: string, campaignName: string, adId: string, space: string): AdEvent {
  return {
    eventType: AdEventType.AD_IMPRESSION,
    campaignId: campaignId,
    campaignName: campaignName,
    adId: adId,
    source: websiteSourceName,
    space: space,
  };
}

export function buildAdClickEvent(campaignId: string, campaignName: string, adId: string, space: string): AdEvent {
  return {
    eventType: AdEventType.AD_CLICK,
    campaignId: campaignId,
    campaignName: campaignName,
    adId: adId,
    source: websiteSourceName,
    space: space,
  };
}
