import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
} from '@angular/forms';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'eddy-mat-select',
  templateUrl: './mat-select.component.html',
  styleUrls: ['./mat-select.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class MatSelectComponent implements AfterViewInit {
  @Input() label: string;

  @Input() controlName: string;

  @Input() control: FormControl;

  @Input() placeholder = 'bitte auswählen';

  @Input() disabled = false;

  @Input() required = false;

  @Input() multiple = false;

  @Output() selectionChange: EventEmitter<any> = new EventEmitter();

  @ContentChildren(MatOption) queryOptions: QueryList<MatOption>;

  options: any[];
  yet: boolean;
  isFocused: boolean;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.options = this.queryOptions.map((x) => {
      return { value: x.value, viewValue: x.viewValue };
    });
    setTimeout(() => {
      this.yet = true;
      this.changeDetectorRef.detectChanges();
    });
  }

  resetValue() {
    this.control.setValue('');
  }

  onBlur() {
    setTimeout(() => (this.isFocused = false), 10);
  }

  onSelectionChange(value: any) {
    this.selectionChange.emit(value);
  }
}
