import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SignUpDialog } from '../../dialogs/sign-up/sign-up.dialog';
import { Overlay } from '@angular/cdk/overlay';
import { TheoryLessonService } from '@services/theory-lesson.service';
import { ScreenWidthService } from '@services/screen-width.service';
import { MetaService } from '@services/meta.service';
import { AppRoutes } from '../../app.routes';
import { ActivatedRoute, ParamMap } from '@angular/router';
import moment from 'moment';
import { VideoPlayerComponent } from '@modules/shared/components/video-player/video-player.component';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { isPlatformServer } from '@angular/common';

@Component({
  templateUrl: './online-theory-license-b.page.html',
  styleUrls: ['./online-theory-license-b.page.scss'],
  animations: [
    // fade-in/fade-out animation.
    trigger('fadeAnimation', [
      transition('void => true', [
        style({ opacity: 0, bottom: -86 }),
        animate('600ms ease-in', style({ opacity: 1, bottom: 12 }))
      ]),
      transition('* => void', [
        animate('600ms ease-out', style({ opacity: 0, bottom: -86 }))
      ]),
    ])
  ]
})
export class OnlineTheoryLicenseBPage implements OnInit {
  private readonly isServer: boolean;
  readonly loginPageUrl = `/${AppRoutes.Root.login}`;
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  visibilityAt = 0;
  private isDesktop: boolean;
  private onlineTheoryImagePath = 'https://driveddy.com/assets/online-theory.png';

  countdownDate: Date;
  currentSale: any;
  showCurrentSale: boolean;

  minutesLabel: string;
  secondsLabel: string;

  @ViewChild('videoPlayer') videoPlayer: VideoPlayerComponent;

  videoPlaying: boolean;

  currentSales = [
    {
      letter: 'T',
      name: 'Tina H.',
      time: 'Innerhalb der letzten 6 Stunden',
      text: 'Hat seinen Online-Theorieunterricht gestartet'
    },
    {
      letter: 'S',
      name: 'Sabrina M.',
      time: 'Innerhalb der letzten 6 Stunden',
      text: 'Hat seinen Online-Theorieunterricht gestartet'
    },
    {
      letter: 'P',
      name: 'Patrick N.',
      time: 'Innerhalb der letzten 6 Stunden',
      text: 'Hat seinen Online-Theorieunterricht gestartet'
    },
    {
      letter: 'K',
      name: 'Katharina L.',
      time: 'Innerhalb der letzten 6 Stunden',
      text: 'Hat seinen Online-Theorieunterricht gestartet'
    },
    {
      letter: 'S',
      name: 'Sebastian N.',
      time: 'Innerhalb der letzten 6 Stunden',
      text: 'Hat seinen Online-Theorieunterricht gestartet'
    },
    {
      letter: 'T',
      name: 'Tobias H.',
      time: 'Innerhalb der letzten 6 Stunden',
      text: 'Hat seinen Online-Theorieunterricht gestartet'
    },
    {
      letter: 'S',
      name: 'Steffanie L.',
      time: 'Innerhalb der letzten 6 Stunden',
      text: 'Hat seinen Online-Theorieunterricht gestartet'
    },
    {
      letter: 'S',
      name: 'Sabine S.',
      time: 'Innerhalb der letzten 6 Stunden',
      text: 'Hat seinen Online-Theorieunterricht gestartet'
    },
    {
      letter: 'P',
      name: 'Patrizia L.',
      time: 'Innerhalb der letzten 12 Stunden',
      text: 'Hat seinen Online-Theorieunterricht gestartet'
    },
    {
      letter: 'G',
      name: 'Gerald O.',
      time: 'Innerhalb der letzten 12 Stunden',
      text: 'Hat Führerschein Paket B gekauft'
    },
    {
      letter: 'D',
      name: 'Daniela K.',
      time: 'Innerhalb der letzten 12 Stunden',
      text: 'Hat Führerschein Paket B gekauft'
    },
    {
      letter: 'R',
      name: 'Raphael M.',
      time: 'Innerhalb der letzten 12 Stunden',
      text: 'Hat Führerschein Paket A gekauft'
    },
    {
      letter: 'N',
      name: 'Niklas D.',
      time: 'Innerhalb der letzten 12 Stunden',
      text: 'Hat Führerschein Paket B gekauft'
    },
    {
      letter: 'S',
      name: 'Sabrina S.',
      time: 'Innerhalb der letzten 12 Stunden',
      text: 'Hat Führerschein Paket A gekauft'
    },
    {
      letter: 'M',
      name: 'Martin M.',
      time: 'Innerhalb der letzten 12 Stunden',
      text: 'Hat Führerschein Paket B gekauft'
    },
    {
      letter: 'C',
      name: 'Carola L.',
      time: 'Innerhalb der letzten 3 Stunden',
      text: 'Hat Führerschein Paket B gekauft'
    },
    {
      letter: 'U',
      name: 'Ulrike K.',
      time: 'Innerhalb der letzten 3 Stunden',
      text: 'Hat Führerschein Paket B gekauft'
    },
    {
      letter: 'M',
      name: 'Marrike Sch.',
      time: 'Innerhalb der letzten 3 Stunden',
      text: 'Hat Führerschein Paket A gekauft'
    },
    {
      letter: 'S',
      name: 'Stefan S.',
      time: 'Innerhalb der letzten 3 Stunden',
      text: 'Hat Führerschein Paket B gekauft'
    },
    {
      letter: 'O',
      name: 'Oliver F.',
      time: 'Innerhalb der letzten 3 Stunden',
      text: 'Hat Führerschein Paket A gekauft'
    },
    {
      letter: 'M',
      name: 'Marco T.',
      time: 'Innerhalb der letzten 24 Stunden',
      text: 'Hat Führerschein Paket B gekauft'
    },
    {
      letter: 'L',
      name: 'Lea Sch.',
      time: 'Innerhalb der letzten 24 Stunden',
      text: 'Hat seinen Online-Theorieunterricht gestartet'
    },
    {
      letter: 'L',
      name: 'Leonie M.',
      time: 'Innerhalb der letzten 24 Stunden',
      text: 'Hat seinen Online-Theorieunterricht gestartet'
    },
    {
      letter: 'E',
      name: 'Elsa P.',
      time: 'Innerhalb der letzten 24 Stunden',
      text: 'Hat seinen Online-Theorieunterricht gestartet'
    },
  ];

  constructor(private theoryLessonService: TheoryLessonService,
              private screenWidthService: ScreenWidthService,
              private dialog: MatDialog,
              private overlay: Overlay,
              private metaService: MetaService,
              private route: ActivatedRoute,
              @Inject(PLATFORM_ID) platformId) {
    this.isServer = isPlatformServer(platformId);
    this.setMetaTags();
    this.screenWidthService.isDesktop().subscribe(isDesktop => {
      this.isDesktop = isDesktop;
      this.minutesLabel = this.isDesktop ? ' Minuten' : ' Min';
      this.secondsLabel = this.isDesktop ? ' Sekunden' : ' Sek';
    });
  }

  ngOnInit() {
    this.countdownDate = moment().add(15, 'minutes').toDate();
    if (!this.isServer) {
      this.startCurrentSalesInterval();
    }
    this.storeUTMParameters(this.loadUTMParameters(this.route.snapshot.queryParamMap));
  }

  startCurrentSalesInterval() {
    timer(1000, 21200)
    .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.currentSale = this.currentSales[this.randomInteger(0, this.currentSales.length)];
        this.showCurrentSale = true;
        setTimeout(() => {
          this.showCurrentSale = false;
        }, 6200);
      });
  }

  private randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  private setMetaTags() {
    const title = 'Theorieunterricht Online besuchen';
    const description = 'Live Online-Theorie ✅ Live-Chat ✅ Exklusive Lernmaterialien ✅ 14 Theoriestunden ✅ nur 14,20 pro Stunde ➤ Jetzt kostenlos registrieren und loslegen!';

    this.metaService.setMetatags(title, description, null, this.onlineTheoryImagePath);
  }

  onOpenSignUpDialog() {
    const scrollStrategy = this.overlay.scrollStrategies.reposition();

    const dialogRef = this.dialog.open(SignUpDialog, {
      width: '420px',
      height: 'auto',
      maxWidth: '100%',
      panelClass: ['custom-dialog-container', 'event-dialog', 'availability-dialog', 'sign-up-dialog'],
      // panelClass: ['custom-dialog-container', 'sign-up-dialog'],
      scrollStrategy: scrollStrategy,
      data: {
        redirectToTheoryPackagePayment: true,
        preselectedLicenseKey: 'B',
        postalCode: '10437',
      }
    });

    dialogRef.afterClosed().subscribe(() => {
    });
  }

  playVideo() {
    this.videoPlaying = true;
    this.videoPlayer.play();
  }

  onOfferVideoEnd() {
    this.videoPlaying = false;
  }

  private loadUTMParameters(queryParamMap: ParamMap) {
    const utmKeys = queryParamMap.keys.filter(key => this.isUTMParameter(key));

    const utmMap = {};
    for (const utmKey of utmKeys) {
      utmMap[utmKey] = queryParamMap.get(utmKey);
    }

    return utmMap;
  }

  private isUTMParameter(key: string) {
    return key?.startsWith('utm');
  }

  private storeUTMParameters(utmParameters) {
    this.theoryLessonService.storeOnlineTheoryUTMParameters(utmParameters);
  }
}
