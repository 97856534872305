<div class="input-cont">
  <div *ngIf="label" class="form-label">
    {{ label }}{{ required ? '*' : '' }}
  </div>
  <mat-form-field appearance="outline">
    <input
      matInput
      [formControlName]="controlName"
      [placeholder]="placeholder"
      [readonly]="readonly"
      (focus)="onFocus()"
      (blur)="onBlur()"
    />
    <div
      matSuffix
      *ngIf="hasValue && isFocused"
      class="suffix-cont"
      (mousedown)="resetValue()"
    >
      <i class="fa fa-times-circle"></i>
    </div>
  </mat-form-field>
</div>
