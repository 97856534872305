<nav
  class="navbar"
  [class.shadow]="lastTopPosition > 0"
  [class.hidden]="!navbarDisplayed"
>
  <div class="container header-container xl-container">
    <div class="drawer-button" (click)="toggleSidenav()">
      <i class="fal fa-bars fa-lg"></i>
    </div>

    <a class="navbar-brand" routerLink="/">
      <img
        class="media-object header-logo"
        src="./assets/logo-gradient.png"
        alt="drivEddy - die Fahrschul App - Logo"
      />
    </a>

    <ul class="nav justify-content-end link-list">
      <ng-container *ngFor="let navRoute of navRoutes">
        <li
          class="nav-item link-active"
          *ngIf="navRoute.type == 'menu' || navRoute.type === undefined"
        >
          <a
            *ngIf="isInternalRoute(navRoute); else externalRouteBlock"
            class="nav-link"
            routerLink="{{ navRoute.route }}"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: false }"
            >{{ navRoute.content }}</a
          >
          <ng-template #externalRouteBlock>
            <a
              class="nav-link"
              target="_blank"
              rel="noopener"
              href="{{ navRoute.route }}"
              >{{ navRoute.content }}</a
            >
          </ng-template>
        </li>
        <li class="nav-item-divider" *ngIf="navRoute.type == 'divider'"></li>

        <li class="nav-item-spacer"></li>
      </ng-container>

      <ng-container *ngFor="let navRoute of externalNavRoutes">
        <li class="nav-item link-active">
          <a class="nav-link" href="{{ navRoute.route }}">{{
            navRoute.content
          }}</a>
        </li>

        <li class="nav-item-spacer"></li>
      </ng-container>

      <!-- displays if the user is logged in -->
      <li class="nav-item link-active" *ngIf="isLoggedIn | async">
        <div [ngSwitch]="isSchoolEmployee()">
          <eddy-navbar-dropdown
            *ngSwitchCase="true"
            [data]="instructorMenuData"
            (event)="onInstructorMenuEvent($event)"
          >
          </eddy-navbar-dropdown>
          <a
            *ngSwitchCase="false"
            class="nav-link"
            routerLink
            (click)="onLogout()"
            >Abmelden</a
          >
        </div>
      </li>
    </ul>

    <!-- displays if the user is NOT logged in -->
    <ng-container *ngIf="viewportWidth >= 1200">
      <ng-container *ngIf="!(isLoggedIn | async)">
        <li class="nav-item link-active login-item-container">
          <!-- <a class="voucher-link" target="_blank" rel="noopener" [href]="voucherLink">Du hast einen Gutschein?</a> -->
          <div class="login-item">
            <a class="nav-link" routerLink="/login">Anmelden</a>
          </div>
        </li>
      </ng-container>
    </ng-container>
  </div>
</nav>
