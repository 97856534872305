<div *ngIf="practiceLesson?.isHarmonizationLesson&&eddyClubRating&&!practiceLesson.isExam">
  <div class="card-title">
    Notizen von der Fahrstunde 11
  </div>
  <div class="row feedback-row no-side-space">
    <div class="feedback-profile-picture" [style.background-color]="eddyClubRating.colorHex()"></div>
    <div class="feedback-text-wrapper">
      <div class="feedback-text">
        {{ eddyClubRating.ratingText() }}

        <div class="feedback-text-light">Bewertung der Fahrstunde</div>
      </div>

      <div>
        <div class="download-icon-wrapper"
             [class.disabled]="lessonRatingsInProgress"
             (click)="onDownloadLessonRatings()">
          <span class="download-icon">
            <i class="fal fa-file-alt"></i>
          </span>
          <span class="download-label">
            Herunterladen
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-xs-12">
        <ng-container *ngIf="eddyClubRating.positiveNotes">
          <div class="notes-label">Was wurde super gemacht?</div>
          <div>{{ eddyClubRating.positiveNotes }}</div>
        </ng-container>

        <ng-container *ngIf="eddyClubRating.negativeNotes">
          <div class="notes-label">Was hat nicht so gut geklappt und sollte nächste Stunde beachtet werden?</div>
          <div>{{ eddyClubRating.negativeNotes }}</div>
        </ng-container>

        <ng-container *ngIf="eddyClubRating.otherNotes">
          <div class="notes-label">Sonstige Anmerkungen</div>
          <div>{{ eddyClubRating.otherNotes }}</div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div *ngIf="practiceLesson?.isExam && ((eddyClubRating&&eddyClubRating.isExamPassed!==null) || (rating&&rating.isExamPassed!==null))">
  <div class="card-title">
    Praktische Prüfung
  </div>
  <div class="row feedback-row no-side-space">

    <div class="feedback-profile-picture"
         [style.background-color]="practiceLesson?.eddyClubRating.isExamPassed===true ? '#a1d863': '#dd6060'"></div>
    <div class="feedback-text-wrapper">
      <div class="feedback-text">
        <ng-container *ngIf="practiceLesson?.eddyClubRating.isExamPassed===true">
          Bestanden
        </ng-container>
        <ng-container *ngIf="practiceLesson?.eddyClubRating.isExamPassed===false">
          Nicht bestanden
        </ng-container>

        <div class="feedback-text-light">Bewertung der Prüfung</div>
      </div>
    </div>

  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-xs-12">

        <ng-container *ngIf="practiceLesson.rating.description">
          <div>{{ practiceLesson.rating.description }}</div>
        </ng-container>

        <ng-container *ngIf="eddyClubRating.positiveNotes">
          <div class="notes-label">Was wurde super gemacht?</div>
          <div>{{ eddyClubRating.positiveNotes }}</div>
        </ng-container>

        <ng-container *ngIf="eddyClubRating.negativeNotes">
          <div class="notes-label">Was hat nicht so gut geklappt und sollte nächste Stunde beachtet werden?</div>
          <div>{{ eddyClubRating.negativeNotes }}</div>
        </ng-container>

      </div>
    </div>
  </div>
</div>

<div *ngIf="!practiceLesson?.isHarmonizationLesson && !practiceLesson?.isExam && rating">
  <div class="card-title">
    Notizen von der Fahrstunde
  </div>
  <div class="row feedback-row no-side-space">
    <div class="feedback-profile-picture" [style.background-color]="rating.colorHex()"></div>
    <div class="feedback-text-wrapper">
      <div class="feedback-text">
        {{rating.ratingText()}}
        <span class="feedback-text-light">(Feedback vom Fahrlehrer)</span>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-xs-12">
        {{rating.description}}
      </div>
    </div>
  </div>
</div>
