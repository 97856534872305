<div class="academy-lesson-container" (click)="onCardClick()">
  <img class="academy-lesson-img" [src]="theoryTopic.imageUrl"/>
  <div class="academy-lesson-header">
    <span title="{{theoryTopic.string()}}" class="lesson-title">{{theoryTopic.string()}}</span>
    <span class="description">Eddy Online-Theorie</span>
  </div>
  <div class="academy-lesson-price">
    <!--<span class="old-price"><s>14,90 €</s></span> --><span class="new-price">14,20 €</span>
  </div>
  <div *ngIf="theoryTopic.key == 'A1' || theoryTopic.key == 'A2' || theoryTopic.key == 'A3' || theoryTopic.key == 'A4'" class="louis-badge">
    <img src="assets/partners/louis-logo.png" class="badge-logo" />
  </div>
</div>
