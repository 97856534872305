import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  templateUrl: './price-adjustment.dialog.html',
  styleUrls: ['./price-adjustment.dialog.scss']
})
export class PriceAdjustmentDialog {
  private school: string;

  constructor(
    private dialogRef: MatDialogRef<PriceAdjustmentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.school = data.school;
  }

  private closeDialog() {
    this.dialogRef.close(true);
  }

  onQuit() {
    this.closeDialog();
  }

  getSchoolName(): string {
    return this.school;
  }

  confirm(): void {
    this.closeDialog();
  }
}
