import { ApplicationRef, ComponentFactoryResolver, Directive, ElementRef, EmbeddedViewRef, EventEmitter, HostListener, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { defaultOptions, TooltipOptions } from './options';

export interface AdComponent {
  data: any;
  show: boolean;
  close: boolean;
  events: any;
}

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements OnInit, OnDestroy {
  componentRef: any;
  elementPosition: any;
  _options: TooltipOptions = defaultOptions;
  _defaultOptions: any;
  componentSubscribe: any;

  /* tslint:disable:no-input-rename */
  @Input('tooltip') tooltipValue: string;

  /* tslint:enable */

  @Input('options') set options(value: TooltipOptions) {
    if (value && defaultOptions) {
      this._options = value;
    }
  }

  get options(): TooltipOptions {
    return this._options;
  }

  private _displayAlways = false;

  @Input() set displayAlways(displayAlways: boolean) {
    this._displayAlways = displayAlways;
    if (displayAlways) {
      this.show();
    }
  }

  get displayAlways() {
    return this._displayAlways;
  }

  @Input('placement') set placement(value: 'top' | 'right' | 'bottom' | 'left') {
    if (value) {
      this._options.placement = value;
    }
  }

  @Input('display') set display(value: boolean) {
    if (typeof (value) === 'boolean') {
      this._options['display'] = value;
    }
  }

  @Input() tooltipDisabled: boolean;

  @Input('max-width') set maxWidth(value: number) {
    if (value) {
      this._options.maxWidth = value;
    }
  }

  @Input('trigger') set trigger(value: 'click' | 'hover') {
    if (value) {
      this._options.trigger = value;
    }
  }

  @Input() set showInfoIcon(showInfoIcon: boolean) {
    this._options.showInfoIcon = showInfoIcon;
  }

  @Input() set whiteBackground(whiteBackground: boolean) {
    this._options.whiteBackground = whiteBackground;
  }

  @Input() set showShadow(showShadow: boolean) {
    this._options.showShadow = showShadow;
  }

  @Input() set destroyOnMouseLeave(destroyOnMouseLeave: boolean) {
    this._options.destroyOnMouseLeave = destroyOnMouseLeave;
  }

  get isTooltipDestroyed() {
    return this.componentRef && this.componentRef.hostView.destroyed;
  }

  @Output() events: EventEmitter<any> = new EventEmitter<any>();

  constructor(private elementRef: ElementRef,
              private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector) {
  }

  @HostListener('focusin')
  @HostListener('mouseenter')
  onMouseEnter() {
    if (!this.isDisplayOnHover) {
      return;
    }

    this.show();
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  onMouseLeave() {
    if (this.options.destroyOnMouseLeave) {
      this.destroyTooltip();
    }
  }

  @HostListener('click')
  onClick() {
    if (this.isDisplayOnClick === false) {
      return;
    }

    this.show();
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    // hide the tooltip on click outside of the component
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.destroyTooltip();
    }
  }

  ngOnInit(): void {
    this.applyOptionsDefault(defaultOptions, this.options);
    window.addEventListener('scroll', () => this.scroll(), true);
  }

  scroll() {
    this.destroyTooltip(true);
  }

  ngOnDestroy(): void {
    this.destroyTooltip(true);

    if (this.componentSubscribe) {
      this.componentSubscribe.unsubscribe();
    }

    window.removeEventListener('scroll', this.scroll, true);
  }

  getElementPosition(): void {
    this.elementPosition = this.elementRef.nativeElement.getBoundingClientRect();
  }

  createTooltip(): void {
    this.getElementPosition();

    window.setTimeout(() => {
      this.appendComponentToBody(TooltipComponent);
    }, 0);

    window.setTimeout(() => {
      this.showTooltipElem();
    }, 0);
  }

  destroyTooltip(forceDestroy: boolean = false): void {
    if (!forceDestroy && this.displayAlways) {
      return;
    }

    if (this.isTooltipDestroyed === false) {
      this.hideTooltip();

      if (!this.componentRef || this.isTooltipDestroyed) {
        return;
      }

      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.events.emit('hidden');
    }
  }

  showTooltipElem(): void {
    (<AdComponent>this.componentRef.instance).show = true;
    this.events.emit('show');
  }

  hideTooltip(): void {
    if (!this.componentRef || this.isTooltipDestroyed) {
      return;
    }
    (<AdComponent>this.componentRef.instance).show = false;
    this.events.emit('hide');
  }

  appendComponentToBody(component: any): void {
    this.componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);

    (<AdComponent>this.componentRef.instance).data = {
      value: this.tooltipValue,
      element: this.elementRef.nativeElement,
      elementPosition: this.elementPosition,
      options: this.options
    };
    this.appRef.attachView(this.componentRef.hostView);
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.componentSubscribe = (<AdComponent>this.componentRef.instance).events.subscribe((event: any) => this.handleEvents(event));
  }

  get isDisplayOnHover(): boolean {
    if (this.options.trigger !== 'hover') {
      return false;
    }

    if (this.isMobile) {
      return false;
    }

    return true;
  }

  get isDisplayOnClick(): boolean {
    if (this.options.trigger !== 'click' && this.isMobile === false) {
      return false;
    }

    return true;
  }

  get isMobile() {
    let check = false;
    navigator.maxTouchPoints ? check = true : check = false;
    return check;
  }

  applyOptionsDefault(defaults: TooltipOptions, options: TooltipOptions): void {
    this._defaultOptions = Object.assign({}, defaults);
    this.options = Object.assign(this._defaultOptions, options);
  }

  handleEvents(event: any) {
    if (event === 'shown') {
      this.events.emit('shown');
    }
  }

  public show() {
    if (this.tooltipDisabled) { return; }
    if (!this.componentRef || this.isTooltipDestroyed) {
      this.createTooltip();
    } else if (!this.isTooltipDestroyed) {
      this.showTooltipElem();
    }
  }

  public hide() {
    this.destroyTooltip();
  }
}
