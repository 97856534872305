import { TheoryLessonTopic } from './theory-lesson-topic.model';

export class AdditionalTheoryLessonAttendance {
    id: number;

    createdAt?: Date;

    learnerId: number;

    topicKey: string;
    topic: TheoryLessonTopic;

    rating?: number;
    feedback?: string;

    isWatched: boolean;

    constructor(json) {
        this.id = +json.id;

        if (json.createdAt) {
            this.createdAt = new Date(json.createdAt);
        }

        this.learnerId = +json.learnerId;

        this.topicKey = json.topicKey;
        this.topic = new TheoryLessonTopic(json.topic);

        if (json.rating) {
            this.rating = +json.rating;
        }
        this.feedback = json.feedback;

        this.isWatched = !!json.isWatched;
    }

    isRated(): boolean {
        return this.rating !== undefined;
    }
}
