<div class="donut-chart"
     [style.width.px]="chartSize"
     [style.height.px]="chartSize"
     [style.background]="donutChartColor"
>
  <div class="slice one"
       style.-webkit-transform="rotate({{slice1Degrees}}deg)"
       style.transform="rotate({{slice1Degrees}}deg)"
       [style.clip]="slice1Clip"
       [style.background]="slice1Color"
  >
  </div>
  <div class="slice two"
       style.-webkit-transform="rotate({{slice2Degrees}}deg)"
       style.transform="rotate({{slice2Degrees}}deg)"
       [style.clip]="slice2Clip"
       [style.background]="slice2Color"
  >
  </div>
  <div class="chart-center"
       [style.top.px]="lineThickness"
       [style.left.px]="lineThickness"
       [style.width.px]="chartSize - (lineThickness * 2)"
       [style.height.px]="chartSize - (lineThickness * 2)"
       [style.background]="chartCenterColor">
    <span *ngIf="showLabels" class="donut-text"
          [style.line-height.px]="chartSize - (lineThickness * 2)"
    >{{chartCenterLabel}}</span>
  </div>
</div>
