export class TheoryTopicFAQ {
  id: number;
  question: string;
  answer: string;
  isVideoFAQ: boolean;

  constructor(json: any) {
    this.id = +json.id;
    this.question = json.question;
    this.answer = json.answer;
    this.isVideoFAQ = !!json.isVideoFAQ;
  }
}
