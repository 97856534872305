import { LearnerPaymentFlag } from './learner-payment-flag.model';
import { Learner } from './learner.model';

export class LessonBookingBasicFee {
    learnerLicenseKey: string;
    learnerId: number;
    learner?: Learner;
    licenseId: number;
    licenseKey: string;
    paymentFlag: LearnerPaymentFlag;
    orderKey: string;

    constructor(json?: any) {
        if (!json) {
          return;
        }

        this.learnerLicenseKey = json.learnerLicenseKey;
        this.learnerId = +json.learnerId;
        if (json.learner) {
          this.learner = new Learner(json.learner);
        }
        this.licenseId = +json.licenseId;
        this.licenseKey = json.licenseKey;

        this.paymentFlag = json.paymentFlag;
        this.orderKey = json.orderKey;
    }
}
