import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { Car } from '@models/car.model';
import { map } from 'rxjs/operators';
import { CarBrand } from '@models/car-brand.model';
import { CarModel } from '@models/car-model.model';

@Injectable()
export class CarService {
  private readonly carsPath = `${env.apiSchoolService}/cars`;

  constructor(private api: ApiService) {
  }

  saveCar(car: Car): Observable<Car> {
    return this.api.post<Car>(this.carsPath, car);
  }

  getCar(schoolId: number, carId: number): Observable<Car> {
    return this.api.get<Car>(`${env.apiSchoolService}/schools/${schoolId}/cars/${carId}`)
      .pipe(
        map((it: Car) => new Car(it))
      );
  }

  private mapCars(cars: Car[]) {
    return cars.map((it: Car) => new Car(it));
  }

  deleteCar(id: number): Observable<void> {
    return this.api.delete(`${this.carsPath}/${id}`);
  }

  getSchoolCars(schoolID: number, filters: Map<string, any> = new Map()): Observable<Car[]> {
    return this.api.get(`${env.apiSchoolService}/schools/${schoolID}/cars`, filters)
      .pipe(
        map((cars: Car[]) => this.mapCars(cars))
      );
  }

  getCarBrands(schoolId: number): Observable<CarBrand[]> {
    return this.api.get(`${env.apiSchoolService}/schools/${schoolId}/car_brands`)
      .pipe(
        map((brands: CarBrand[]) => this.mapCarBrands(brands))
      );
  }

  getAllCarBrands(): Observable<CarBrand[]> {
    return this.api.get(`${env.apiSchoolService}/car_brands`)
      .pipe(
        map(brands => this.mapCarBrands(brands))
      );
  }

  private mapCarBrands(brands: CarBrand[]) {
    return brands.map((it: CarBrand) => new CarBrand(it));
  }

  saveCarBrand(schoolId: number, carBrand: CarBrand): Observable<CarBrand> {
    return this.api.post(`${env.apiSchoolService}/schools/${schoolId}/car_brands`, carBrand)
      .pipe(
        map((it: CarBrand) => new CarBrand(it))
      );
  }

  saveCarModel(schoolId: number, carBrandId: number, carModel: CarModel): Observable<CarBrand> {
    return this.api.post(`${env.apiSchoolService}/schools/${schoolId}/car_brands/${carBrandId}/models`, carModel)
      .pipe(
        map((it: CarBrand) => new CarBrand(it))
      );
  }
}
