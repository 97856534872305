import { Injectable } from '@angular/core';
import { NotificationService } from '@services/notification.service';

@Injectable()
export class CalendlyService {
  constructor(private notificationService: NotificationService) {
  }

  bookAppointment(vendorName: string = '') {
    if (!this.canOpenCalendly()) {
      return;
    }

    // @ts-ignore
    Calendly.showPopupWidget(`https://calendly.com/driveddy/15-min?a2=${vendorName}`);
  }

  instructorApply() {
    if (!this.canOpenCalendly()) {
      return;
    }

    // @ts-ignore
    Calendly.showPopupWidget('https://calendly.com/driveddy/instructor_apply');
  }

  vipSchoolProfile() {
    if (!this.canOpenCalendly()) {
      return;
    }

    // @ts-ignore
    Calendly.showPopupWidget('https://calendly.com/driveddy/vip-fahrschule-profil');
  }

  eddyClubContact() {
    if (!this.canOpenCalendly()) {
      return;
    }

    // @ts-ignore
    Calendly.showPopupWidget('https://calendly.com/eddy-club/eddy-club-vorstellung');
  }

  contactSupport(vendorName: string = '') {
    if (!this.canOpenCalendly()) {
      return;
    }

    // @ts-ignore
    Calendly.showPopupWidget(`https://calendly.com/driveddy/support?a1=${vendorName}`);
  }

  instructorsCongress() {
    if (!this.canOpenCalendly()) {
      return;
    }

    // @ts-ignore
    Calendly.showPopupWidget('https://calendly.com/eddy-club/fahrlehrerkongress-stand8-treffen');
  }

  private canOpenCalendly(): boolean {
    try {
      // @ts-ignore
      const c = Calendly;
    } catch (e) {
      this.notificationService.error('Bitte deaktiviere deinen Werbeblocker, um diese Anwendung nutzen zu können.');
      return false;
    }

    return true;
  }
}
