import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';

@Injectable()
export class RecaptchaService {

  constructor(private api: ApiService) {
  }

  submitReCaptchaResponse(response: string): Observable<boolean> {
    const body = {response: response};
    return this.api.post<boolean>(`${env.api}/recaptcha`, body);
  }
}
