export class TheoryCategory {
    id: number;

    title: string;
    code: string;

    a: boolean;
    a1: boolean;
    a2: boolean;
    am: boolean;
    mofa: boolean;
    b: boolean;
    c: boolean;
    ce: boolean;
    c1: boolean;
    d: boolean;
    d1: boolean;
    l: boolean;
    t: boolean;
    g: boolean;

    parent?: string;

    constructor(json: any) {
        this.id = +json.id;

        this.title = json.title;
        this.code = json.code;

        this.a = !!json.a;
        this.a1 = !!json.a1;
        this.a2 = !!json.a2;
        this.am = !!json.am;
        this.mofa = !!json.mofa;
        this.b = !!json.b;
        this.c = !!json.c;
        this.ce = !!json.ce;
        this.c1 = !!json.c1;
        this.d = !!json.d;
        this.d1 = !!json.d1;
        this.l = !!json.l;
        this.t = !!json.t;
        this.g = !!json.g;

        if (json.parent) {
            this.parent = json.parent;
        }
    }

    get titleWithTypeDisplayName(): string {
        if (this.parent) {
            return this.title;
        }
        return this.g ? `Basis: ${this.title}` : `Zusatzstoff: ${this.title}`;
    }
}
