import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { AppRoutes } from '../../app.routes';
import { postInfos } from './post-infos.config';

enum LicensePageSection {
  DRIVEDDY_SERVICE = 'DRIVEDDY_SERVICE',
  RELEVANT_TOPICS = 'RELEVANT_TOPICS',
  TIPS = 'TIPS'
}

@Component({
  templateUrl: './find-driving-school.page.html',
  styleUrls: ['./find-driving-school.page.scss']
})
export class FindDrivingSchoolPage implements AfterViewInit {
  preisePageLink = `/${AppRoutes.LicenseRequest.preise}`;
  schoolsPageLink = `/${AppRoutes.Root.schools}`;

  isDesktop = window.screen.width > 768;

  @ViewChild('driveddyServicePanel', { read: ElementRef }) driveddyServicePanel: ElementRef;
  @ViewChild('relevantTopicsPanel', { read: ElementRef }) relevantTopicsPanel: ElementRef;
  @ViewChild('tipsPanel', { read: ElementRef }) tipsPanel: ElementRef;

  private sectionToElement: Map<string, ElementRef<any>>;

  sectionAccordionOpened = false;

  postInfosList = postInfos;

  constructor(private scrollToService: ScrollToService) {
  }

  ngAfterViewInit() {
    setTimeout(() => this.fillSectionToElementMap(), 50);
  }

  private fillSectionToElementMap() {
    this.sectionToElement = new Map<LicensePageSection, ElementRef<any>>();

    this.sectionToElement.set(LicensePageSection.DRIVEDDY_SERVICE, this.driveddyServicePanel);
    this.sectionToElement.set(LicensePageSection.RELEVANT_TOPICS, this.relevantTopicsPanel);
    this.sectionToElement.set(LicensePageSection.TIPS, this.tipsPanel);
  }

  jumpToSection(section: string) {
    this.sectionAccordionOpened = false;
    // allow time for the mobile accordion header to close itself before navigating to the section
    const timeout = this.isDesktop ? 0 : 100;

    setTimeout(() => {
      const elementRef = this.sectionToElement.get(section);
      if (!elementRef) {
        return;
      }

      this.scrollToService.scrollTo({
        target: elementRef,
        offset: this.isDesktop ? -116 : 0
      });
    }, timeout);
  }

  onAnchorsAccordionToggle() {
    this.sectionAccordionOpened = !this.sectionAccordionOpened;
  }
}
