import {Component, Input} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

@Component({
  selector: 'eddy-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss']
})
export class BackComponent {

  @Input() title: string;
  @Input() path: any[];

  constructor(
    private location: Location,
    private router: Router,
  ) { }

  goBack() {
    if (this.path) {
      this.router.navigate(this.path);
    } else {
      this.location.back();
    }
  }
}
