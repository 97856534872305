<div class="row info-card" *ngIf="infoCard"
     [style.border-top-color]="infoCard.color"
     [style.height.px]="infoCard.height">
  <div class="col-12">
    <h3 class="title" [innerHTML]="infoCard.title"></h3>
    <span class="content" [innerHTML]="infoCard.content"></span>
    <div *ngIf="infoCard.buttonText">
      <button *ngIf="!infoCard.analyticsEvent" class="button" (click)="infoCardAction()">{{ infoCard.buttonText }}</button>

      <button *ngIf="infoCard.analyticsEvent" class="button" (click)="infoCardAction()"
              analytics
              [analyticsCategory]="infoCard.analyticsEvent.category"
              [analyticsAction]="infoCard.analyticsEvent.action"
              [analyticsLabel]="infoCard.analyticsEvent.label"
      >{{ infoCard.buttonText }}</button>
    </div>
  </div>
  <div *ngIf="infoCard.cssClasses" class="icon-container">
    <i [class]="infoCard.cssClasses" [style.color]="infoCard.color"></i>
  </div>
  <div class="bottom-right-text" *ngIf="infoCard.bottomRightText" [style.color]="infoCard.color">
    {{ infoCard.bottomRightText }}
  </div>
  <div class="bottom-right-img" [style.width.px]="infoCard.imageWidth" *ngIf="infoCard.imagePath">
    <img [src]="infoCard.imagePath"  />
  </div>
</div>
