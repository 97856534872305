<div class="floating-academy-register-btn-container">
  <button class="academy-register-btn" (click)="onOpenSignUpDialog()">
    Jetzt kostenlos registrieren
  </button>

  <a [routerLink]="loginPageUrl" class="text-link-white">
    Login mit drivEddy Account
  </a>
</div>

<div class="panel panel-1 grey top-panel">
  <div class="container xl-container">
    <h1 class="section-title">
      Online-Theorieunterricht Klasse A & B<br />
      - Jetzt Pflichtunterricht online absolvieren
    </h1>

    <p class="section-content">
      Einfach anmelden und mit einem Klick deine Online-Theoriestunden für den
      Motorrad- und Autoführerschein absolvieren.
    </p>

    <ul class="features">
      <li>
        <img class="tick" src="/assets/fancy-check.png" /><span
          class="item-content"
          >Video + Live-Chat mit Fahrlehrern, bequem von zu Hause</span
        >
      </li>
      <li>
        <img class="tick" src="/assets/fancy-check.png" />
        <span class="item-content">Bis zu 10 Termine pro Woche</span>
      </li>
      <li>
        <img class="tick" src="/assets/fancy-check.png" /><span
          class="item-content"
          >41% höhere Erfolgschance bei der Theorieprüfung*</span
        >
      </li>
      <li>
        <img class="tick" src="/assets/fancy-check.png" />
        <span class="item-content">100% rechtsgültig und anerkannt**</span>
      </li>
      <li class="with-logo">
        <img class="tick" src="/assets/fancy-check.png" />
        <div>
          <span class="item-content">Gratis Theorie App</span>
          <!-- <img src="/assets/dekra-logo.png" alt="Logo der DEKRA" /><img
            src="/assets/tuv-logo.png"
            alt="Logo des TÜV Süd"
          /> -->
        </div>
      </li>
    </ul>

    <div class="academy-register-btn-container">
      <button class="academy-register-btn" (click)="onOpenSignUpDialog()">
        Jetzt kostenlos registrieren
      </button>

      <a [routerLink]="loginPageUrl" class="secondary-btn ghost-btn">
        Login mit drivEddy Account
      </a>
    </div>

    <div class="academy-school-exists-container">
      Du hast schon eine Fahrschule?
      <div class="badge-icon">
        <i class="fas fa-info-circle"></i>
      </div>
      <div class="badge-text">
        Du hast schon eine Fahrschule? Super! Frag einfach deinen Fahrlehrer ob
        deine Fahrschule schon registriert ist. Fahrschulen können sich
        kostenfrei registrieren und die drivEddy Online-Theorie mit dir
        gemeinsam nutzen!
      </div>
    </div>
    <eddy-known-from></eddy-known-from>
  </div>
</div>

<div class="panel panel-2">
  <div class="container xl-container">
    <div class="row no-side-space spacing-between">
      <div class="col-md-3 no-side-space text-container">
        <div class="side-text">
          <h4 class="header-side-text">
            Führerscheinklasse A und B - Jede Einzel-Theoriestunde (90 Min) für
            je nur 14,20 Euro!
          </h4>
          <p class="description-side-text">
            Du bezahlst nur für die Pflichtstunden, die du noch brauchst oder
            machen möchtest. Nur 14,20 € für je 90 Minuten.
          </p>
          <p class="payment-methods">
            <img src="/assets/payments-logos/payments-klarna.png" />
            <img src="/assets/payments-logos/payments-sofort.png" />
            <img src="/assets/payments-logos/payments-paypal.png" />
            <img src="/assets/payments-logos/payments-cards.png" />
          </p>
        </div>
      </div>
      <div class="col-md-9 no-side-space text-container">
        <div class="academy-lessons">
          <img
            class="desktop-only-element are-you-ready"
            src="/assets/academy/are-you-ready.png"
          />
          <div class="slide-left" (click)="updateVisibleTopics(false)">
            <i class="fal fa-arrow-left"></i>
          </div>
          <eddy-academy-lesson
            *ngFor="let theoryTopic of theoryLessonTopics"
            [theoryTopic]="theoryTopic"
            class="academy-lesson"
            [class.display-none]="!theoryTopic['visible']"
            (cardClicked)="onOpenSignUpDialog()"
          ></eddy-academy-lesson>
          <div class="slide-right" (click)="updateVisibleTopics(true)">
            <i class="fal fa-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="row no-side-space spacing-between allianz-section">
      <div class="allianz-header">
        Inkl. Gratis Zusatzinhalte von unserem Versicherungspartner Allianz rund
        um das Thema Fahren & Sicherheit
      </div>
      <div class="allianz-logo">
        <img src="/assets/partners/allianz-logo.png" />
      </div>
    </div>
  </div>
</div>

<div class="panel panel-3">
  <div class="container xl-container check-online-theory-main-container">
    <img
      class="theory-badge desktop-only-element"
      src="/assets/badge.png"
      alt="Online Theorieunterricht
mit Live-Video und Chat"
    />
    <div class="row no-side-space">
      <div
        class="col-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2 check-online-theory-recognized-container"
      >
        <h2 class="recognized-header">
          Online-Theorieunterricht JETZT möglich**
        </h2>
        <p class="recognized-subheader">
          Einfach über drivEddy anmelden, Theoriestunden buchen, online von zu
          Hause am Unterricht teilnehmen und gültigen Ausbildungsnachweis
          erhalten.
        </p>

        <button
          class="academy-register-btn no-side-margin"
          (click)="onOpenSignUpDialog()"
        >
          Jetzt kostenlos registrieren
        </button>
      </div>
    </div>
  </div>
</div>

<div class="panel">
  <div class="container xl-container">
    <div class="row no-side-space">
      <eddy-online-theory-testimonials
        [generalTestimonials]="true"
      ></eddy-online-theory-testimonials>
    </div>
  </div>
</div>

<div class="panel panel-4-1">
  <div class="row no-side-space features-wrapper">
    <img
      class="features-container-image desktop-only-element"
      src="/assets/academy/car-keys.png"
    />
    <div class="container xl-container features-container flex-end">
      <div class="feature">
        <div class="feature-icon">
          <img
            src="assets/academy/online-icon.png"
            class="feature-icon-online"
          />
        </div>
        <div>
          <h4 class="feature-header">100% online</h4>
          <p class="feature-description">
            Teilnahme im interaktiven Live-Stream bequem von zu Hause möglich.
          </p>
        </div>
      </div>
      <div class="feature">
        <div class="feature-icon">
          <img
            src="assets/academy/interactiv-icon.png"
            class="feature-icon feature-icon-interactive"
          />
        </div>
        <div>
          <h4 class="feature-header">100% interaktiv</h4>
          <p class="feature-description">
            Zertifizierter Fahrlehrer beantwortet alle deine Fragen.
          </p>
        </div>
      </div>
      <div class="feature">
        <div class="feature-icon">
          <img
            src="assets/academy/accepted-icon.png"
            class="feature-icon feature-icon-accepted"
          />
        </div>
        <div>
          <h4 class="feature-header" *ngIf="theoryReady">100% anerkannt</h4>
          <h4 class="feature-header" *ngIf="!theoryReady">
            100% prüfungsrelevant
          </h4>
          <p class="feature-description" *ngIf="theoryReady">
            Starte nach der Krise direkt mit dem Praxisteil bei einer Fahrschule
            deiner Wahl.
          </p>
          <p class="feature-description" *ngIf="!theoryReady">
            Inhalte entsprechen zu 100% den gesetzlichen Bestimmungen.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container xl-container">
    <div class="row no-side-space spacing-between dashboard-panel desktop">
      <div class="side-description">
        <h2 class="section-title">
          Optimale Prüfungsvorbereitung: bequem vom Sofa!
        </h2>
        <p class="side-description-text">
          Unsere geprüften und zertifizierten Fahrlehrer vermitteln dir im
          Rahmen eines Live-Streams alle relevanten Inhalte.
        </p>
        <p class="side-description-text">
          Stelle dem Fahrlehrer über Live-Chat alle Fragen und sie werden direkt
          beantwortet.
        </p>
      </div>
      <img
        class="dashboard-img"
        src="assets/academy/streaming-mockup-eddy-academy.png"
      />
    </div>

    <div class="row no-side-space spacing-between dashboard-panel mobile">
      <div class="side-description">
        <h2 class="section-title">
          Optimale Prüfungsvorbereitung: bequem vom Sofa!
        </h2>
        <img
          class="dashboard-img"
          src="assets/academy/dashboard-mockup-eddy-academy.png"
        />
        <p class="side-description-text">
          Unsere geprüften und zertifizierten Fahrlehrer vermitteln dir im
          Rahmen eines Live-Streams alle relevanten Inhalte.
        </p>
        <p class="side-description-text">
          Frage den Fahrlehrer über Live-Chat alle Fragen und er beantwortet sie
          dir direkt!
        </p>
      </div>
    </div>
  </div>
</div>

<div class="panel grey">
  <div class="container xl-container">
    <div class="row no-side-space spacing-between desktop">
      <img
        class="dashboard-img"
        src="assets/academy/dashboard-mockup-eddy-academy.png"
      />
      <div class="side-description">
        <h2 class="section-title">
          Zertifizierte Theoriestunden bei Top-Fahrlehrern online
        </h2>
        <p class="side-description-text">
          Im Rahmen von 12 Grundstoff Theoriestunden sowie den
          klassenspezifischen A (4 Stück) und B (2 Stück) Theoriestunden lernst
          du von top ausgebildeten Fahrlehrern alle relevanten Inhalte in je 90
          Minuten.
        </p>
      </div>
    </div>
    <div class="row no-side-space spacing-between mobile">
      <div class="side-description">
        <h2 class="section-title">Online Theoriestunden bei Top-Fahrlehrern</h2>
        <img
          class="dashboard-img"
          src="assets/academy/dashboard-mockup-eddy-academy.png"
        />
        <p class="side-description-text">
          Im Rahmen von 14 Theoriestunden à 90 Minuten lernst du alle relevanten
          Inhalte.
        </p>
        <p class="side-description-text">
          Im Anschluss erhältst du eine Teilnahmebescheinigung.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="panel">
  <div class="container xl-container">
    <h2 class="instructions-title">So funktioniert’s:</h2>
    <div class="row no-side-space instructions-container">
      <div class="instructions-line-container">
        <hr class="instructions-line" />
      </div>
      <div class="step">
        <img
          src="assets/academy/click-icon.png"
          class="feature-icon-interactive"
        />
        <h4 class="steps-header">Registriere dich mit wenigen Klicks</h4>
        <p class="steps-description">
          Sichere dir einen Platz in unseren limitierten Online-Kursen.
        </p>
      </div>
      <div class="step">
        <img
          src="assets/academy/online-teacher-icon.png"
          class="feature-icon-interactive"
        />
        <h4 class="steps-header">Besuche die Theoriestunden online</h4>
        <p class="steps-description">
          Absolviere die Theorie bequem von zu Hause.
        </p>
      </div>
      <div class="step">
        <img
          src="assets/academy/certificate-icon.png"
          class="feature-icon-interactive"
        />
        <h4 class="steps-header">Erhalte deinen Ausbildungsnachweis</h4>
        <p class="steps-description">
          Absolvierte Theoriestunden sind 2 Jahre lang gültig.
        </p>
      </div>
      <div class="step last-step">
        <img
          src="assets/academy/praxis-icon.png"
          class="feature-icon-interactive"
        />
        <h4 class="steps-header">Starte den Praxis-Teil nach der Theorie.</h4>
        <p class="steps-description">
          Starte mit gefestigtem Vorwissen deinen Praxisunterricht bei deiner
          lokalen Fahrschule.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="panel green-section">
  <div class="container xl-container">
    <div class="row no-side-space">
      <div class="col-12 no-side-space">
        <h2 class="green-section-header">Jetzt Theorie online absolvieren</h2>
        <div class="register-btn-container">
          <button class="register-btn" (click)="onOpenSignUpDialog()">
            Jetzt kostenlos registrieren
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="panel footnote">
  * Die Durchfallrate von mit drivEddy unterrichteten Fahrschüler:innen in der
  Theorie-Prüfung liegt bei nur 7% (statistische Erhebung unter 1.000
  Fahrschüler:innen Absolvent:innen der drivEddy Online-Theorie). Der
  bundesweite Durchschnitt liegt bei 34,1% laut Statista (2020): Durchfallquote
  bei theoretischen Führerscheinprüfungen in Deutschland von 2010 bis 2020.<br />
  ** drivEddy übernimmt keine Garantie dafür, dass Online-Theorie in dem
  jeweiligen Bundesland, in welchem du wohnhaft bist, zur Zeit anerkannt wird.
  Bitte informiere dich über den aktuellen Stand bei der zuständigen
  Landesbehörde. Wir kämpfen selbstverständlich weiter dafür, dass Online
  Theorie zukünftig in allen Bundesländern gleichermaßen akzeptiert wird.<br />Neuigkeiten
  hierzu findest du auch auf der Seite des Verbands
  <a href="www.dvfff.de" target="_blank">www.dvfff.de</a>.
</div>
