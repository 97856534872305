export enum LearnerType {
  EDDY_CLUB = 'EDDY_CLUB',
  REGULAR = 'REGULAR'
}

export function getDisplayName(learnerType: LearnerType): string {
  if (learnerType === LearnerType.EDDY_CLUB) {
    return 'Eddy Club';
  }

  if (learnerType === LearnerType.REGULAR) {
    return 'Meine Fahrschüler';
  }

  return '';
}

export function getAllLearnerTypes(): LearnerType[] {
  return [LearnerType.EDDY_CLUB, LearnerType.REGULAR];
}
