export class URLUtils {
  static addQueryParamToUrl(url: string, paramName: string, paramValue: string): string {
    let sign = '&';
    if (url.indexOf('?') === -1) {
      sign = '?';
    }

    return `${url}${sign}${paramName}=${paramValue}`;
  }

}
