export enum QuestionType {
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  OPEN_ANSWER_SHORT = 'OPEN_ANSWER_SHORT',
  OPEN_ANSWER_LONG = 'OPEN_ANSWER_LONG',
  OPEN_ANSWER_NUMBERS_ONLY = 'OPEN_ANSWER_NUMBERS_ONLY',
}

export function allQuestionTypes(): QuestionType[] {
  return [QuestionType.SINGLE_CHOICE, QuestionType.OPEN_ANSWER_SHORT, QuestionType.OPEN_ANSWER_LONG, QuestionType.OPEN_ANSWER_NUMBERS_ONLY];
}

export function displayName(type: QuestionType): string {
  switch (type) {
    case QuestionType.SINGLE_CHOICE:
      return 'Klassische Umfrage';
    case QuestionType.OPEN_ANSWER_SHORT:
      return 'Freitext Frage (kurz)';
    case QuestionType.OPEN_ANSWER_LONG:
      return 'Freitext Frage (lang)';
    case QuestionType.OPEN_ANSWER_NUMBERS_ONLY:
      return 'Freitext Frage (kurz, nur Zahlen)';
  }
}

export function description(type: QuestionType): string {
  switch (type) {
    case QuestionType.SINGLE_CHOICE:
      return 'Eine Frage + Bild mit Antwortmöglichkeiten';
    case QuestionType.OPEN_ANSWER_SHORT:
      return 'Eine Frage + offenes Textfeld';
    case QuestionType.OPEN_ANSWER_LONG:
      return 'Eine Frage + offenes Textfeld';
    case QuestionType.OPEN_ANSWER_NUMBERS_ONLY:
      return 'Eine Frage + offenes Textfeld';
  }
}
