import { UserInfo } from '@models/user-info.model';
import { isTeamMemberUserRole } from '@models/user.model';

interface ChatMessagePayload {
  message: string;
  action?: string;
  data: object;
  learnerId?: number;
}

export class ChatMessage {
  id: number;

  type: string;
  userId: number;
  user?: UserInfo;

  date: Date;
  content: string;
  payload: ChatMessagePayload;

  votes: number;
  isUpvoted: boolean;

  constructor(messageJson: any) {
    if (!messageJson) {
      return;
    }

    this.id = +messageJson.id;

	if (messageJson.content.startsWith('$ctrl$')) {
		this.type = 'control';
		this.payload = JSON.parse(messageJson.content.substr(6));
		if(this.payload.message !== undefined && this.payload.message !== null) {
			messageJson.content = this.payload.message;
		} else {
			messageJson.content = 'Steuersignal';
		}
	} else if (messageJson.content.startsWith('$reply$')) {
    this.type = 'reply';
    this.payload = JSON.parse(messageJson.content.substr(7));
		if(this.payload.message !== undefined && this.payload.message !== null) {
			messageJson.content = this.payload.message;
		}
  }  else if (messageJson.content.startsWith('$log$')) {
    this.type = 'log';
    this.payload = JSON.parse(messageJson.content.substr(5));
		messageJson.content = 'Log';
  } else {
		this.type = 'message';
	}

    this.userId = +messageJson.userId;
    if (messageJson.user) {
      this.user = new UserInfo(messageJson.user);
    }

    this.date = new Date(messageJson.date);
    this.content = messageJson.content;

    this.votes = messageJson.votes ? +messageJson.votes : 0;

  }

  get isMessageFromSchoolEmployee(): boolean {
    return !!(this.user && isTeamMemberUserRole(this.user.role));
  }

  isMessageFromGuestSchoolEmployee(lessonInstructorUserId?: number): boolean {
    if (!lessonInstructorUserId) {
      return false;
    }
    return !!(this.user && isTeamMemberUserRole(this.user.role) && this.userId !== lessonInstructorUserId);
  }

  get isReplyMessage(): boolean {
    return this.type && this.type === 'reply';
  }

  getContent() {
	return this.content;
  }

  getPayload() {
	  return this.payload;
  }

  toggleUpVote() {
    if (this.isUpvoted) {
      this.isUpvoted = false;
      this.votes--;
    } else {
      this.isUpvoted = true;
      this.votes++;
    }
  }
}
