<div class="lp-background">
  <img src="/assets/header-tc.png"/>
  <div class="container">
    <div class="row no-side-space">
      <div class="col-12 col-lg-10 offset-lg-1">
        <h1 class="lp-title">drivEddy App - AGB für Fahrlehrer</h1>

        <h5 class="lp-section-title">1. Geltungsbereich und Anwendungsbereich</h5>
        <div class="tc-section">
          <strong>1.1</strong> Die DrivEddy GmbH, Monbijouplatz 4, 10178 Berlin (im Folgenden „DrivEddy“)
          vermittelt und administriert über ihre Plattform auf der Internetseite drivEddy.com / www.driveddy.de sowie
          über ihre mobile (internetbasierte) Applikation (zusammen im Folgenden „Plattform“) als sog.
          SaaS-Dienstleistung (Nutzung der Plattform) über das Medium Internet Fahrschule und Fahrschüler, und bietet
          zudem eine Fahrschulsuchmaschine an (zusammen im Folgenden „Service“). Die Plattform bietet die Infrastruktur
          für den Betrieb einer Fahrschule und allen damit verbundenen Geschäfte. Diese Allgemeinen Geschäftsbedingungen
          (im Folgenden „AGB“) gelten für die Nutzung der Plattform durch die Nutzer in ihrer Eigenschaft als Anbieter
          von Fahrschulleistungen (im Folgenden „Fahrlehrer“) und/oder in ihrer Eigenschaft als Betreiber einer
          Fahrschule (im Folgenden „Fahrschule“). Soweit im Folgenden keine Differenzierung zwischen Fahrlehrer und
          Fahrschule notwendig ist, werden diese gemeinsam als „Anbieter“ beschrieben. Die Nutzer in Ihrer Eigenschaft
          als Fahrschüler (im Folgenden „Fahrschüler“) gehen das Vertragsverhältnis mit DrivEddy über separate AGB ein.
          Sofern im Folgenden undifferenziert von Nutzern die Rede ist, beschreibt dieser Begriff die Gesamtheit von
          Anbietern und Fahrschüler.
          <br/><br/>
          <strong>1.2</strong> Die jeweils aktuellen AGB sind auf der Webseite der Plattform unter
          https://www.driveddy.com/datenschutz-app abrufbar. DrivEddy ist jederzeit berechtigt, diese AGB zu ändern oder
          zu
          ergänzen, es sei denn, dass dies für die Anbieter nicht zumutbar ist. Hierzu benachrichtigt DrivEddy die
          Anbieter rechtzeitig über die Änderungen (schriftlich oder per E-Mail) und veröffentlicht diese auf der
          Plattform. Fehlt es an einem Widerspruch des Anbieters bezüglich der Änderungen der AGB, der innerhalb von
          sechs (6) Wochen nach der Benachrichtigung erfolgen muss, gelten die geänderten AGB als vom jeweiligen
          Anbieter angenommen. In der Benachrichtigung wird der Anbieter auf sein Widerspruchsrecht und die Bedeutung
          der Widerspruchsfrist ausdrücklich hingewiesen. Im Falle des Widerspruchs des Anbieters gegen die Änderung
          oder Ergänzung der AGB ist DrivEddy berechtigt, den Nutzungsvertrag-Plattform auf Grundlage dieser AGB
          gegenüber dem jeweiligen Anbieter mit einer Frist von vier (4) Wochen zu kündigen.
          <br/><br/>
          <strong>1.3</strong> Die Nutzung der Plattform erfolgt ausschließlich auf der Grundlage dieser AGB, soweit
          zwischen DrivEddy und den Anbietern im Einzelfall nicht ausdrücklich und schriftlich eine andere Vereinbarung
          getroffen wurde. Entgegenstehende, ergänzende oder abweichende Bedingungen des Anbieters werden nicht
          Vertragsbestandteil, es sei denn, dies wird zwischen den Parteien ausdrücklich schriftlich vereinbart.
          <br/><br/>
          <strong>1.4</strong> DrivEddy tritt als bloßer Betreiber der Plattform und App auf und ermöglicht es Anbietern
          von Fahrschulleistungen (im Folgenden „Fahrlehrern“), eigene Angebote zu veröffentlichen und Fahrschülern
          diese Angebote einzusehen und gegebenenfalls Kontakt mit einem Fahrlehrer aufzunehmen. Darüber hinaus stellt
          DrivEddy verschiedene Funktionalitäten zur Verfügung, um die Zusammenarbeit zwischen Fahrlehrer und
          Fahrschüler zu ermöglichen, wird selbst aber nicht Partei eines Vertrags zur Erbringung von
          Fahrschulleistungen.
          <br/><br/>
          <strong>1.5</strong> Die Anbieter
          sind auch zum Ausdruck berechtigt. Der Anbieter kann die AGB jederzeit auf der Internetseite von DrivEddy
          abrufen, ausdrucken sowie speichern.
          <br/><br/>
          <strong>1.6</strong> Nicht Gegenstand dieser AGB ist die Erbringung von Fahrschulleistungen.
        </div>

        <h5 class="lp-section-title">2. Vertragsgegenstand</h5>
        <div class="tc-section">
          <strong>2.1</strong> Vertragsgegenstand dieser AGB ist die Nutzungseinräumung des Services von DrivEddy für
          seine Nutzer (Anbieter und Fahrschüler) auf der Plattform.
          <br/><br/>
          <strong>2.2</strong> Auf der Plattform, dessen Nutzung diesen AGB als Nutzungsvertrag unterliegt, gibt
          DrivEddy seinen Nutzern mittels Zugang auf die Plattform eine Übersicht über die verschiedenen
          Leistungsbereiche der Plattform. DrivEddy tritt als bloßer Betreiber der Plattform auf und ermöglicht es den
          Anbietern in Bezug auf Fahrschulleistungen eigene Angebote zu veröffentlichen und ermöglicht es den
          Fahrschülern diese Angebote einzusehen, und gegebenenfalls Kontakt mit dem jeweiligen Anbieter aufzunehmen.
          Darüber hinaus stellt DrivEddy verschiedene Funktionalitäten zur Verfügung, die die bessere Zusammenarbeit
          zwischen Anbieter und Fahrschüler ermöglichen soll. DrivEddy wird selbst aber nicht Partei eines Vertrags zur
          Erbringung von Fahrschulleistungen. Der genaue und aktuelle Leistungsumfang der Plattform ergibt aus den
          Beschreibungen des folgenden Links (LINK).www.driveddy.com) DrivEddy stellt den Nutzern die Plattform nur zur
          Nutzung zur Verfügung und gestattet den Nutzer hierauf zuzugreifen. Weder Quellcode noch Objektcode der
          Software werden dem Nutzer überlassen. Der Nutzer erhält lediglich die technische Möglichkeit und
          Berechtigung, auf die Software, welche auf einem zentralen Server gehostet ist, mittels Internet zu zugreifen
          und zu nutzen. Um diese Plattform nutzen zu können müssen sich alle Nutzer wie folgt auf Grundlage dieser AGB
          registrieren.
          <br/><br/>
          <strong>2.3</strong> DrivEddy sieht für unterschiedliche Anbieter unterschiedliche Nutzer-Kategorien vor,
          welchen in Bezug auf den Funktionsumfang des Service bzw. der Plattform unterschiedliche Nutzungsrechte
          zugeordnet werden (im Folgenden „Nutzer-Kategorie“). Das Ermessen, einen bestimmten Anbieter im Einzelfall
          einer bestimmten Nutzer-Kategorie zuzuordnen und damit den Funktionsumfang zu definieren, obliegt
          ausschließlich DrivEddy. Details des unterschiedlichen Funktionsumfangs ergibt sich aus dem Link zu den
          Leistungsumfang der Plattform gem. Ziff. 2.2 dieser AGB.
        </div>


        <h5 class="lp-section-title">3. Registrierung, Benutzerkonto und Anbieterdaten</h5>
        <div class="tc-section">
          <strong>3.1</strong> Damit der Anbieter die Plattform nutzen kann, muss er sich auf Grundlage dieser AGB
          registrieren. Über den Online-Registrierungsprozess kann sich der Anbieter auf der Plattform wie folgt
          anmelden. Die Absendung der Online-Registrierung als Anbieter stellt eine rechtsverbindliche Offerte zum
          Vertragsschluss gegenüber DrivEddy auf Grundlage dieser AGB dar. Der Anbieter wird per E-Mail über den Eingang
          seiner Registrierungsanfrage informiert. Im Anschluss versendet DrivEddy nach Prüfung der Anfrage eine E-Mail
          an den Anbieter, welche einen Aktivierungslink enthält. Der Anbieter muss seine Registrierung durch Anklicken
          des Aktivierungslinks aktivieren. Hierdurch kommt jedoch noch kein Vertrag mit DrivEddy zu Stande. Ein Vertrag
          mit DrivEddy kommt erst zu Stande, wenn DrivEddy dem Anbieter in einer Bestätigungsmail den Vertragsschluss
          bestätigt oder das Benutzerkonto durch DrivEddy freigeschaltet wird (im Folgenden
          „Nutzungsvertrag-Plattform“). Es obliegt jedoch DrivEddy in ihrem eigenen Ermessen zu entscheiden, ob der
          jeweilige Anbieter freigeschaltet wird bzw. ob ein Nutzungsvertrag-Plattform mit diesem abgeschlossen wird.
          DrivEddy richtet für den Anbieter im Falle einer erfolgreichen Registrierung ein individuelles und
          persönliches Benutzerkonto ein. Der Anbieter wählt einen Benutzernamen und ein individuelles Passwort. Das
          Passwort ist geheim zu halten und der Zugang zu dem Benutzerkonto sorgfältig zu sichern.
          <br/><br/>
          <strong>3.2</strong> Der Nutzungsvertrag-Plattform regelt die Rechte und Pflichten der Anbieter in Bezug auf
          die Nutzung der Plattform. Eine Registrierung als Anbieter ist nur unbeschränkt geschäftsfähigen natürlichen
          Personen und in Bezug auf Fahrschulen auch in der Form als juristische Person erlaubt. Der Anbieter ist ohne
          ausdrückliche Zustimmung von DrivEddy nicht berechtigt, sein Benutzerkonto auf eine dritte Person zu
          übertragen. Der Anbieter ist nicht berechtigt, die Zugangsdaten zu seinem Benutzerkonto an Dritte
          weiterzugeben.
          <br/><br/>
          <strong>3.3</strong> Der Anbieter wird hiermit ausdrücklich insbesondere darauf hingewiesen, dass…
          <ol class="alphabetical">
            <li>…das vermittelte Fahrausbildungsverhältnis direkt zwischen dem jeweiligen Anbieter und dem Fahrschüler
              zustande kommt und keinerlei vertragliche Verflechtungen von Seiten DrivEddy’s in Bezug auf das
              vermittelte Fahrausbildungsverhältnis existieren.
            </li>
            <li>… die auf der Plattform eingestellten Inhalte und Inserate vor der Veröffentlichung durch DrivEddy nicht
              inhaltlich geprüft wird.
            </li>
          </ol>

          <br/>

          <strong>3.4</strong> Der Anbieter sichert bei der Registrierung auf der Plattform gegenüber DrivEddy
          ausdrücklich zu und garantiert im Sinne eines selbständigen Garantieversprechens, …
          <ol class="alphabetical">
            <li>…dass alle angegebenen Daten (insbesondere aber nicht abschließend: Name, Vorname, E-Mail-Adresse,
              Telefonnummer, Geschäftsadresse (Straße, PLZ, Land), im Falle einer juristischen Person deren Firmierung)
              – „Anbieterdaten“ – im Wege des Registrierungsprozesses wahr und vollständig sind.
            </li>
            <li>…dass er volljährig oder eine juristische Person ist.</li>
            <li>…dass er eigenverantwortlich Änderungen bzw. Ergänzungen seiner Daten (insbesondere E-Mail-Adresse,
              Telefonnummer, Bankdaten, Kontaktdaten) und Angaben hierzu unverzüglich DrivEddy per Änderung im Account
              der Plattform mitteilt.
            </li>
            <li>…dass er eine Weitergabe der persönlichen Anmeldedaten (Benutzername und Passwort) an Dritte verhindern.
              Der Anbieter haftet für die verursachten Schäden, die DrivEddy durch den Verlust oder die Weitergabe der
              Anmeldedaten entstanden ist.
            </li>
            <li>…dass er über alle erforderlichen Genehmigungen bzw. Erlaubnisse verfügt, die für die Tätigkeit als
              Fahrlehrer und/oder Fahrschule notwendig sind, insbesondere über eine gültige Fahrlehrererlaubnis bzw.
              Anwärterbefugnis nach dem Fahrlehrergesetz (FahrlG) bzw. im Falle einer Fahrschule zusätzliche eine
              Fahrschulerlaubnis zu verfügen.
            </li>
            <li>…dass der veröffentlichte Preisaushang in der Plattform von DrivEddy stets der für den Anbieter
              tatsächlich aktuelle und für die Ausbildungsverträge anzuwendende Preisaushang ist.
            </li>
            <li>…dass er die angebotenen Fahrschulleistungen auch tatsächlich und in der angebotenen Form erbringen
              kann.
            </li>
          </ol>

          <br/>

          <strong>3.5</strong> Die Verfahren dieser AGB haben insbesondere aber nicht abschließend das Ziel, dass die
          Informationen über den Anbieter und seine Leistungen stets aktuell sind und so nah an die Wirklichkeit
          reichen, dass der Fahrschüler einen möglichst realistischen Einblick über die Leistungsdetails des Anbieters
          bekommt. Sollte daher durch Änderungen an und in den Leistungsdetails oder den Details über den Anbieter eine
          Aktualisierung der Daten auf der Plattform notwendig werden, ist der Anbieter verpflichtet, diese anzuzeigen
          und/oder selber auf der Plattform zu aktualisieren. Die Pflicht besteht auch dann, wenn eine Datenänderung in
          ein öffentliches Register (z.B. Handelsregister) eingetragen wird. Der Anbieter ist verpflichtet, DrivEddy
          jederzeit auf Nachfrage Nachweise für die Richtigkeit und Aktualität der Fahrlehrerdaten vorzulegen. DrivEddy
          behält es sich vor, jederzeit das Vorliegen entsprechender Nachweise zu überprüfen. Darüber hinaus hat ein
          angestellter Fahrlehrer ggf. sicherzustellen, dass sein Arbeitgeber der Nutzung zustimmt. DrivEddy behält sich
          das Recht vor, jederzeit und ohne vorhergehender Mahnung aus ihrem freiem Ermessen ein oder alle Profil(e) des
          Anbieters zu entfernen oder den Zugriff auf diese(n) zu sperren, um aus ihrer Sicht unzulässige, nicht
          aktuelle, diesen AGB widersprechen oder schädliche Profile des Anbieters zu verhindern, wobei DrivEddy nicht
          für Konsequenzen haftet, die sich aus dem erfolglosem Versuchs, ein solches Profil zu entfernen, ergeben.

          <br/><br/>

          <strong>3.6</strong> Solange der Anbieter auf der Plattform registriert ist, ist DrivEddy hiermit berechtigt
          und bevollmächtigt:
          <ol class="roman">
            <li>bei den jeweiligen zuständigen Behörden zu prüfen, ob die notwendigen Genehmigungen bzw. Erlaubnisse
              Ziffer 3.4 Buchst. e) dieser AGB verfügt (im Folgenden „Prüfungs-Vollmacht“),
            </li>
            <li>für den Anbieter in dessen Vollmacht und dessen Namen die Rechnung für die Fahrschulleistungen gegenüber
              dem Fahrschüler für Rechnung des Anbieters auf Grundlage dieser AGB abzurechnen und Zahlungen zu
              veranlassen. Die Prüfungs-Vollmacht kann jederzeit vom Anbieter gegenüber DrivEddy widerrufen werden. Im
              Fall des Widerrufs der Prüfungs-Vollmacht ist DrivEddy jedoch berechtigt, alle betreffenden Profile und
              Leistungen des Anbieters von der Plattform zu entfernen (im Folgenden „Rechnungs-Vollmacht“) und
            </li>
            <li>Vertragsschlussvollmacht</li>
          </ol>

          <br/>

          <strong>3.7</strong> Der Anbieter ist verpflichtet, DrivEddy umgehend zu informieren, wenn es Anhaltspunkte
          dafür gibt, dass sein Benutzerkonto von Dritten missbraucht wurde. Kommt der Anbieter dieser Verpflichtung
          nicht nach, haftet er für sämtliche Aktivitäten, die unter Verwendung seines Benutzerkontos stattfinden.
          <br/><br/>
          <strong>3.8</strong> Es besteht keine Verpflichtung von DrivEddy die Plattform für alle Endgeräte oder zu
          jeder Zeit zur Verfügung zu stellen. DrivEddy darf jedoch dem Anbieter Nachrichten senden, um diesen über
          Neuheiten und Weiterentwicklungen zu informieren. DrivEddy behält sich das Recht vor, die Plattform –
          insbesondere die Software – technisch und inhaltlich zu ändern. DrivEddy und ihre Partner können
          servicerelevante Informationen per Plattform um Zusatzinformationen oder Werbung z.B. von Sponsoren ergänzen.
          Mitteilungen über Marketingaktivitäten von DrivEddy (im Folgenden („Newsletter“)) erhält der Anbieter nur,
          wenn er sich mit dem Empfang des Newsletters einverstanden erklärt hat. Der Anbieter kann den Bezug des
          Newsletters jederzeit dadurch beenden, dass er im Newsletter auf das Feld „Newsletter abbestellen“ klickt. In
          diesem Fall wird die E-Mail-Adresse des Anbieters aus dem Newsletter- Verteiler gelöscht und das Fehlen der
          Einwilligung im Nutzerbereich vermerkt.
          <br/><br/>
          <strong>3.9</strong> Der Anbieter räumt DrivEddy zum Zwecke der Veröffentlichung von Angeboten (Bewerben
          seiner Leistungen) und zum Zwecke des Bewerbens der Plattform ein einfaches, unentgeltliches, räumlich,
          zeitlich und inhaltlich unbeschränktes, unwiderrufliches und auf Dritte übertragbares und unterlizenzierbares
          Nutzungsrecht an den eingestellten Inhalten ein. DrivEddy ist jederzeit berechtigt, die Inhalte insoweit zu
          vervielfältigen, zu bearbeiten und zu verwerten. Dies schließt insbesondere das Vervielfältigungsrecht, das
          Verbreitungsrecht und das Recht der öffentlichen Wiedergabe, insbesondere das Recht der öffentlichen
          Zugänglichmachung, mit ein. Der Anbieter verzichtet auf das Recht zur Urhebernennung. DrivEddy ist es
          insbesondere gestattet, die Angebote, einschließlich der enthaltenen Inhalte, ganz oder teilweise zu
          Werbezwecken zu nutzen, um für die Plattform oder die Nutzung der Plattform zu werben und zur Erfüllung des
          Nutzungsvertrages-Plattform. Sofern DrivEddy selber Inhalte (insbesondere Texte und Fotografien) für das
          Inserat/Profil des Anbieters anfertigt oder über einen Dritten anfertigen lässt, stehen die Rechte an diesen
          Inhalten ausschließlich DrivEddy zu. Durch die Veröffentlichung dieser Inhalte auf dem Profil der Anbieter
          entstehen für den jeweiligen Anbieter keinerlei selbständige Nutzungsrechte an diesen Inhalten. Insbesondere
          darf der Anbieter diese Inhalte außerhalb der Plattform nicht benutzen oder diese Inhalte lokal auf seinem
          Computer speichern. DrivEddy ist zudem auch berechtigt, die Inhalte zum Zwecke der Werbung für DrivEddy auch
          an Vertragspartner zu übermitteln, welche die Inhalte auf den von ihnen betriebenen Plattform (gemeinsam mit
          den dort platzierten Werbemitteln) anzeigen.
          <br/><br/>
          <strong>3.10</strong> Bei unsachgemäßer Nutzung und nutzungswidrigen Verhaltens ist DrivEddy jederzeit
          berechtigt, den jeweiligen Nutzungsvertrag-Plattform gegenüber dem Anbieter unverzüglich zu beenden und ihm
          die weitere Benutzung der Plattform (Nutzungsvertrag-Plattform) zu untersagen. Ein Fall der besonders
          rechtswidrigen Nutzung liegt insbesondere aber nicht abschließend immer dann vor, wenn der Anbieter die
          Plattform in derart benutzt, dass er damit diffamierend, obszön, ruchlos, pornografisch, eindeutig sexuell, in
          Förderung von Gewalt oder Herabsetzung einer Gruppe aufgrund von Rasse oder ethnischer Herkunft, Religion,
          Behinderung, Geschlecht, Alter, Veteranenstatus und / oder sexueller Orientierung / Geschlechtsidentität
          handelt, oder Minderjährige ausbeutet bzw. schädigt (besonders rechtswidrige Nutzung).
        </div>


        <h5 class="lp-section-title">4. Buchung von Fahrschulleistungen über die Plattform</h5>
        <div class="tc-section">
          <strong>4.1</strong> Sofern die jeweilige Nutzer-Kategorie des Anbieters diesen Funktionsumfang zulässt, ist
          die Buchung von Fahrschulleistungen über die Plattform wie folgt für diesen Anbieter möglich. Voraussetzung
          hierfür ist jedoch, dass der Anbieter mit dem Tochterunternehmen von DrivEddy der Eddy Bildung GmbH
          (eingetragen im Handelsregister des Amtsgericht Berlin (Charlottenburg) unter der Registernummer: HRB – im
          Folgenden auch „Eddy Bildung“) einen Partnervertrag (Kooperation) rechtsverbindlich abgeschlossen hat.
          Neben den sonstigen Leistungen des Service von DrivEddy auf der Plattform auf Grundlage dieser AGB geht es um
          das Buchen und Verwalten von Fahrschulleistungen, wie nachfolgend geregelt.
          <br/><br/>
          <strong>4.2</strong> Nach der Vermittlung eines passenden Anbieters für den Fahrschüler wird unter Anwesenheit
          der Vertragsparteien des Fahrschulausbildungsvertrags bei dem Anbieter vor Ort für die Erbringung von
          Fahrschulleistungen ein Ausbildungsvertrag unterzeichnet, nachdem der Fahrschüler entsprechend dem FahrlG
          aufgeklärt wurde (im Folgenden „Ausbildungsvertrag“). Der Ausbildungsvertrag kann weiterführende oder gänzlich
          andere Bedingungen enthalten und liegt im ausschließlichen Verantwortungsbereich des Anbieters.
          <br/><br/>
          <strong>4.3</strong> Erst nach Abschluss des Ausbildungsvertrags können Fahrschüler über den Buchungsprozess
          auf der Plattform weitere Fahrschulleistungen mit dem jeweiligen Anbieter buchen (im Folgenden „Buchung“). Die
          Buchung einer praktischen Fahrstunde ist nach Unterschrift des Ausbildungsvertrages sowie nach erfolgreicher
          Absolvierung des Sehtests erlaubt bzw. möglich. Die entsprechende Funktion innerhalb der Plattform wird
          freigeschaltet, sobald die entsprechenden Dokumente durch den jeweiligen Anbieter am entsprechenden
          Fahrschülerprofil bzw. Benutzerkonto des Fahrschülers gespeichert worden sind. Der Fahrschüler wählt auf der
          Plattform ein Produkt des Anbieters aus und kann den Erwerb der Buchung über DrivEddy anfragen. DrivEddy wird
          diese Anfrage dann kurzfristig prüfen. DrivEddy vermittelt hier den Vertragsschluss zwischen dem Anbieter und
          dem Fahrschüler. Das Vertragsverhältnis in Bezug auf die Buchung kommt ausschließlich zwischen dem jeweiligen
          Fahrschüler und dem jeweiligen Anbieter zustande. Welche Produkte entsprechend seiner Kriterien verfügbar
          sind, kann der jeweilige Fahrschüler auf der Plattform erkennen. Die Anzeigeangebote auf der Plattform stellen
          kein verbindliches Angebot auf den Abschluss eines Vertrags über die Erbringung von Fahrschulleistungen dar.
          Vielmehr handelt es sich um eine unverbindliche Aufforderung, Angebote durch den Fahrschüler an den Anbieter
          zu richten (sog. invitatio ad offerendum). Dem Fahrschüler ist bewusst, dass für die Inanspruchnahme der durch
          von den Anbietern angebotenen Leistungen in der Regel Kosten entstehen. Die jeweiligen Preise der
          Fahrschulleistungen werden von dem jeweiligen unabhängigen Anbieter festgelegt und sind im Vorfeld per
          Ausbildungsvertrag verbindlich vereinbart. Die auf der Plattform genannten Preise enthalten die gesetzliche
          Steuer und sonstige Preisbestandteile, die bei dem Anbieter erhoben werden. Gibt der Fahrschüler eine
          verbindliche Buchungsanfrage ab (über den Button der Plattform „zahlungspflichtig buchen“), wird DrivEddy dem
          Fahrschüler den Eingang der Anfrage per Plattform und E-Mail bestätigen (im Folgenden „Bestellbestätigung“)
          und den Anbieter über die Anfrage informieren (im Folgenden „Anfrage“). Dazu wird dem Fahrlehrer der
          Benutzername, Vor- und Nachname und die Rufnummer des Fahrschülers in der Anfrage übermittelt. Die
          Bestellbestätigung begründet noch keine rechtsverbindliche Buchung. Eine rechtsverbindliche Buchung kommt erst
          zustande, wenn der Anbieter die Anfrage des Fahrschülers annimmt, spätestens aber mit dem Beginn der
          Leistungserbringung. Minderjährige Personen bedürfen für Buchungen der Zustimmung ihres gesetzlichen
          Vertreters.
          <br/><br/>
          <strong>4.4</strong> Sofern die Fahrschulleistungen theoretische Fahrstunden betrifft, ist die Buchung erst
          nach Zahlung des Grundbetrags gemäß des Preisaushangs bzw. Ausbildungsvertrags des jeweiligen Anbieters durch
          den Fahrschüler erlaubt bzw. möglich. Die entsprechende Funktion innerhalb der App wird freigeschaltet, sobald
          die entsprechenden Dokumente von dem Anbieter am entsprechenden Fahrschülerprofil bzw. Benutzerkonto
          gespeichert worden sind.
          <br/><br/>
          <strong>4.5</strong> DrivEddy wird die vermittelten Leistungen im Namen und der Rechnung des Anbieters
          gegenüber dem Fahrschüler in Rechnung stellen.
          <br/><br/>
          <strong>4.6</strong> Die maximale Anzahl der Buchung von praktischen Fahrstunden als Produkt pro Fahrschüler
          pro Anbieter beträgt 2 x 75 Minuten pro Tag und maximal 4 x 75 Minuten pro Woche. Die maximale Anzahl der
          Gesamtbuchungen (bei allen Anbietern der Plattform) von praktischen Fahrstunden als Produkt pro Fahrschüler
          pro Woche beträgt 12 x 75 Minuten. Der Fahrschüler darf pro Tag maximal 180 Minuten Theoriefahrstunde (2
          Doppel-Theorieblöcke) buchen.
          <br/><br/>
          <strong>4.7</strong> Die per Buchung verbindlich vereinbarten Leistungen sind entgeltlich. Im Falle einer
          Buchung des Fahrschülers über die Plattform zahlt der Fahrschüler an den Online-Payment-Dienstleister, sofern
          diese Zahlungsmodalität vom Fahrschüler gewählt worden ist, das Buchungs-Entgelt an diesen Dienstleister und
          erst nach erfolgreichem Abschluss des gebuchten Produkts zahlt dieser Dienstleister das Buchungs-Entgelt nach
          Provisionsabzug gem. Ziffer 5 dieser AGB an den Anbieter aus. Als erfolgreicher Abschluss gilt es, wenn der
          Leistungsempfang durch den Fahrschüler in der App bestätigt wurde und der Anbieter die Umsetzung des Produkts
          Online bewertet hat. Im Fall der Barzahlung erfolgt die Zahlung unmittelbar an den Anbieter vor der Umsetzung
          des gebuchten Produkts. In diesem Fall erfolgt der Versand der Einzelrechnung an den Anbieter am Folgetag,
          sofern der Anbieter den Erhalt des Geldes bestätigt hat.
          <br/><br/>
          <strong>4.8</strong> Der Fahrschüler kann verbindliche Buchungen gemäß den Bedingungen des Ausbildungsvertrags
          (in Relation zum Anbieter) entsprechend stornieren. Ein Stornierungsentgelt wird ggf. gem. Ziff. 5 dieser AGB
          in diesem Fall fällig.
        </div>


        <h5 class="lp-section-title">5. Entgelt, Abrechnung und Zahlung</h5>
        <div class="tc-section">
          <strong>5.1</strong> Die Nutzung der Plattform erfolgt grundsätzlich unentgeltlich, sofern der Anbieter auf
          Grund seiner Nutzer-Kategorie insbesondere aber nicht abschließend nicht die Kalender und Buchungsfunktion der
          Plattform nutzen kann.
          <br/><br/>
          <strong>5.2</strong> Für den Fall, dass der Anbieter auf Grund seiner Nutzer-Kategorie insbesondere aber nicht
          abschließend die Buchungsfunktion der Plattform gem. Ziffer 4 dieser AGB nutzen kann, entsteht zwar nicht
          gegenüber DrivEddy eine Entgeltpflicht jedoch auf Grundlage des Partnervertrages (Kooperation) gegenüber dem
          Eddy Bildung. Nach dieser Vereinbarung steht dem Eddy Bildung für jede verbindliche Buchung, die nicht
          storniert worden ist, eine Provision zu. Der Eddy Bildung teilt hierzu für die Abwicklung der Zahlungen
          eine Kopie dieses Partnervertrages (Kooperation) insbesondere wegen der Provisionshöhe mit. Diese Provision
          wird dann im Zahlungsprozess entsprechend hinterlegt.
          <br/><br/>
          <strong>5.3</strong> In Bezug auf die Durchführung des Online-Payments gem. Ziffer 5.3 dieser AGB ist
          festzuhalten, dass etwaige Provisionen des Eddy Bildung direkt abgezogen und mit Versandt der
          Gutschriftsrechnung und dem Beleg am Folgetag des erfolgreichen Abschlusses des Produkts gem. Ziffer 4.7
          dieser AGB weitergeleitet werden. Im Fall einer Zahlung der Buchung außerhalb des Online-Payments gelten die
          Zahlungsmodalitäten und Abrechnungen gem. den Vereinbarungen aus dem Partnervertrag (Kooperation). Die
          Zahlungsabwicklung innerhalb der Plattform erfolgt auf das hinterlegte Konto des Anbieters.
          <br/><br/>
          <strong>5.4</strong> Für den Fall der Stornierung einer verbindlich gebuchten Fahrstunde (Buchung) durch den
          Fahrschüler ist der Anbieter gegenüber dem Fahrschüler berechtigt, eine entsprechende Aufwandsentschädigung
          gemäß dem Ausbildungsvertrag von dem Fahrschüler zu verlangen und in Rechnung zu stellen. Dem Fahrschüler
          bleibt der Nachweis vorbehalten, ein Schaden sei nicht oder in wesentlich geringerer Höhe entstanden. Ein
          Stornierungs-Entgelt gegenüber DrivEddy schuldet der Anbieter nicht. Im Falle eines Online-Payments durch den
          Fahrschüler wird die Stornierungsgebühr des Fahrlehrers von dem vorausentrichtetem Buchungsentgelt, welches
          dem Grunde nach an den Fahrschüler zurücküberwiesen wird, in Abzug gebracht und an den Anbieter ausgezahlt.
          <br/><br/>
          <strong>5.5</strong> Der Anbieter erhält nach jeder Buchung einen elektronischen Beleg. Einsprüche gegen die
          in Rechnung gestellten Vermittlungsgebühren müssen vom Anbieter innerhalb von sechs (6) Wochen nach Erhalt der
          Rechnung geltend gemacht werden. Das Unterlassen gilt jeweils als Genehmigung der Rechnung. Der Anbieter kann
          auch nach Fristablauf eine Berichtigung der Rechnung verlangen, ist dann aber für die Unrichtigkeit der
          Rechnung beweispflichtig.
        </div>


        <h5 class="lp-section-title">6. Allgemeine Pflichten des Anbieters und Verantwortlichkeit für Inhalte</h5>
        <div class="tc-section">
          <strong>6.1</strong> Der Anbieter verpflichten sich, bei der Erstellung von Angeboten und der Veröffentlichung
          von Inhalten auf der Plattform geltendes Recht (z. B. Wettbewerbs- und Strafrecht, E-Commerce-Vorschriften,
          Fernabsatzrecht, gesetzliche Informationspflichten) zu beachten und keine Rechte Dritter (z. B. Marken-,
          Namens-, Urheber- oder Datenschutzrechte) zu verletzen. Der Anbieter stellt insbesondere sicher, dass einer
          Veröffentlichung von Bildern und Inhalten auf der Plattform keine Rechte Dritter entgegenstehen. Dem Anbieter
          ist es insbesondere verboten, Leistungen, deren Angebot, Verkauf oder Erwerb gegen gesetzliche Vorschriften
          oder Rechte Dritter verstößt, auf der Plattform anzubieten bzw. entsprechende Angebote auf der Plattform zu
          veröffentlichen.
          <br/><br/>
          <strong>6.2</strong> Der Anbieter verpflichtet sich, DrivEddy unverzüglich über Vertragsschlüsse zu
          Fahrschülern in Kenntnis zu setzen, wenn der zugrundeliegende Kontakt über die Plattform vermittelt wurde. Ein
          Kontakt gilt als über die Plattform vermittelt, wenn der Erstkontakt zwischen Fahrschüler und Anbieter über
          die Plattform erfolgt oder der Fahrschüler auf den Anbieter über die Plattform aufmerksam wurde. Für jeden
          Fall der Zuwiderhandlung gegen diese Verpflichtung zahlt der Anbieter an DrivEddy eine Vertragsstrafe in Höhe
          von EUR 1.500,00. Dem Anbieter bleibt der Nachweis vorbehalten, ein Schaden sei nicht oder in wesentlich
          geringerer Höhe entstanden.
          <br/><br/>
          <strong>6.3</strong> Der Anbieter muss jedwede Tätigkeit unterlassen, die geeignet ist, den Betrieb der
          Plattform oder der dahinterstehenden technischen Infrastruktur zu beeinträchtigen und/oder übermäßig zu
          belasten. Dazu zählen insbesondere:
          <ol class="alphabetical">
            <li>die Verwendung von Software, Skripten oder Datenbanken in Verbindung mit der Nutzung der Plattform;</li>
            <li>das automatische Auslesen, Blockieren, Überschreiben, Modifizieren, Kopieren von Daten und/oder
              sonstigen Inhalten, soweit dies nicht für die ordnungsgemäße Nutzung der Plattform erforderlich ist;
            </li>
            <li>die Verbreitung und/oder öffentliche Wiedergabe von jedweden Inhalten der Plattform ohne Einwilligung
              von DrivEddy.
            </li>
          </ol>

          <br/>

          <strong>6.4</strong> Anbieter dürfen Angebote auf der Plattform ausschließlich mittels der von DrivEddy
          angebotenen Suchmasken suchen. Nicht statthaft ist die Angebotssuche unter Umgehung der Suchmasken,
          insbesondere durch Verwendung von automatisierter Suchsoftware, die auf die Datenbanken von DrivEddy zugreift.
          <br/><br/>
          <strong>6.5</strong> Der Anbieter darf die durch Abfragen und Nutzung der Plattform gewonnenen oder auf der
          Plattform zugänglich gemachten Daten und Inhalte weder vollständig, noch teilweise oder auszugsweise verwenden
          <ol class="alphabetical">
            <li>zum Aufbau einer eigenen Datenbank in jeder medialen Form und/oder</li>
            <li>für eine gewerbliche Datenverwertung oder Auskunftserteilung und/oder</li>
            <li>für eine sonstige gewerbliche Verwertung.</li>
          </ol>
          <br/>
          Dies gilt nicht, soweit es sich um die eigenen Daten des Anbieters handelt.
          <br/><br/>
          <strong>6.6</strong> Der Anbieter darf über die Plattform keine Nachrichten werbenden Inhalts ohne eine
          Einwilligung des Empfängers versenden (Spam-Nachrichten).
          <br/><br/>
          <strong>6.7</strong> Sollte es bei der Nutzung der Plattform oder seiner Funktionalitäten zu Störungen kommen,
          wird der Anbieter DrivEddy von diesen Störungen unverzüglich in Kenntnis setzen. Gleiches gilt, wenn der
          Anbieter Kenntnis über von Dritten veröffentlichte Angebote oder Inhalten erlangt, die offensichtlich gegen
          geltendes Recht oder Rechte Dritter verstoßen.
          <br/><br/>
          <strong>6.8</strong> Der Anbieter ist für sämtliche Inhalte, die er auf der Plattform einstellt, allein
          verantwortlich. Dies gilt insbesondere, aber nicht ausschließlich für die vom Anbieter im Rahmen des Angebots
          seiner Produkte gemachten Beschreibungen, die Angaben zu seiner Person sowie die hinterlegten Bilddateien
          (gemeinsam im Folgenden „Inserat“). Der Anbieter wird die Inhalte vor der Einstellung sorgfältig prüfen. Eine
          Überprüfung durch DrivEddy kann bedarfsweise erfolgen. Der Anbieter hat sicher zu stellen, dass die von ihm
          inserierte Produkte richtig und vollständig beschrieben werden. Dabei hat er mindestens diejenigen Angaben zu
          machen, die von DrivEddy als verpflichtend gekennzeichnet sind.
          <br/><br/>
          <strong>6.9</strong> Der Anbieter versichert und gewährleistet durch die Einstellung eines Inserates, dass er
          zur Leistungserbringung dieser Produkte und zur Verwendung der Inhalte der Produktbeschreibungen berechtigt
          ist. Dies umfasst insbesondere, aber nicht ausschließlich, das Recht zur öffentlichen Zugänglichmachung und
          Verbreitung sowie der dafür erforderlichen Vervielfältigung und Bearbeitung. Darüber hinaus hat er sicher zu
          stellen, dass…
          <br/><br/>
          <ol class="alphabetical">
            <li>die Inserate und sonstigen Inhalte sich ausschließlich auf die angebotenen Produkte beziehen;</li>
            <li>Persönlichkeitsrechte Dritter nicht verletzen;</li>
            <li>nicht diskriminierend gegen Rassen, beleidigend, schikanierend, sittenwidrig oder sexuell freizügig
              sind;
            </li>
            <li>keine Informationen oder Daten enthalten, zu deren Übertragung der Anbieter vertraglich und/oder nach
              geltendem Recht nicht berechtigt ist (z.B. Geschäftsgeheimnisse, geschützte oder vertrauliche
              Informationen, von denen sie im Rahmen von Beschäftigungsverhältnissen Kenntnis erhalten haben oder die
              einer Vertraulichkeitsvereinbarung unterfallen) und
            </li>
            <li>nicht (auch nicht mittelbar) in Rechte Dritter, insbesondere Urheberrechte, Leistungsschutzrechte oder
              verwandte Schutzrechte, Warenzeichenrechte, Markenrechte, Patentrechte, Gebrauchs- oder
              Geschmacksmusterrechte eingreifen.
            </li>
          </ol>

          <br/>

          <strong>6.10</strong> Der Anbieter verpflichtet sich (soweit erforderlich), Hinweise auf Urheberschaft,
          Copyright oder ähnliche Rechte bei Einstellung der Inhalte wahrheitsgemäß und vollständig anzugeben.
        </div>


        <h5 class="lp-section-title">7. Unterbrechung | Beeinträchtigung der Erreichbarkeit der Plattform</h5>
        <div class="tc-section">
          <strong>7.1</strong> Anpassungen, Änderungen und Ergänzungen der vertragsgegenständlichen Plattform sowie
          Maßnahmen, die der Feststellung und Behebung von Funktionsstörungen dienen, werden nur dann zu einer
          vorübergehenden Unterbrechung oder Beeinträchtigung der Erreichbarkeit führen, wenn dies aus technischen
          Gründen zwingend notwendig ist.
          <br/><br/>
          <strong>7.2</strong> Die Überwachung der Grundfunktionen der Software erfolgt täglich. Die Wartung der
          Plattform ist grundsätzlich von Montag bis Freitag 09:00 – 18:00 Uhr gewährleistet. Bei schweren Fehlern – die
          Nutzung der Plattform ist nicht mehr möglich bzw. ernstlich eingeschränkt – erfolgt die Wartung binnen drei
          (3) Stunden ab Kenntnis oder Information durch DrivEddy.
          <br/><br/>
          <strong>7.3</strong> Sofern die Fehlerbehebung nicht innerhalb von zwölf (12) Stunden möglich sein sollte,
          wird DrivEddy den Anbieter davon binnen vierundzwanzig (24) Stunden unter Angabe von Gründen sowie des
          Zeitraums, der für die Fehlerbeseitigung voraussichtlich zu veranschlagen ist, per E-Mail verständigen.
          <br/><br/>
          <strong>7.4</strong> Die Verfügbarkeit des jeweils vereinbarten Services nach diesen AGB beträgt 98,5 % im
          Jahresdurchschnitt einschließlich Wartungsarbeiten, jedoch darf die Verfügbarkeit nicht länger als zwei
          Kalendertage in Folge beeinträchtigt oder unterbrochen sein.
        </div>


        <h5 class="lp-section-title">8. Vertragsdauer, Kündigung, Sperrung</h5>
        <div class="tc-section">
          <strong>8.1</strong> Der Nutzungsvertrag-Plattform wird auf unbestimmte Zeit geschlossen.
          <br/><br/>
          <strong>8.2</strong> Dieser Nutzungsvertrag-Plattform kann von jeder Partei mit einer Frist von einem (1)
          Monat ab dem Zugang der Kündigung per Benutzerkonto oder per E-Mail ordentlich gekündigt werden.
          <br/><br/>
          <strong>8.3</strong> Dieser Nutzungsvertrag-Plattform kann darüber hinaus von jeder Partei ohne Einhaltung
          einer Frist aus wichtigem Grund schriftlich gekündigt werden.
          Ein wichtiger Grund, liegt insbesondere, aber nicht ausschließlich vor, wenn…
          <ol class="alphabetical">
            <li>der Anbieter fällige Zahlungen trotz Mahnung und Nachfristsetzung nicht leistet oder die vertraglichen
              Bestimmungen über die Nutzung der Plattform verletzt.
            </li>
            <li>der Anbieter liquidiert oder über das Vermögen des Anbieters das Insolvenzverfahren eröffnet oder die
              Eröffnung des Insolvenzverfahrens mangels Masse abgelehnt wird oder ein ähnlich schwerwiegendes Verfahren
              gegen den Anbieter eingeleitet wird.
            </li>
            <li>der Anbieter seine wesentlichen Vertragspflichten oder eine andere wesentliche Bestimmung dieser AGB
              verletzt, sofern dieser Vertragsbruch nicht innerhalb von sieben (7) Kalendertagen nach Aufforderung durch
              DrivEddy abgestellt wird.
            </li>
            <li>der Anbieter die Inhalte und/oder Details der Produkte verändert oder Ähnliches ohne, dass der Anbieter
              DrivEddy hierüber informiert hat bzw. die Änderungen auf der Plattform einzupflegen.
            </li>
            <li>der Anbieter seinen Leistungspflichten aus den jeweiligen Buchungen gegenüber den Fahrschülern nicht
              nachkommt. Hierfür bedarf es ausdrücklich keiner Abmahnung.
            </li>
            <li>der Anbieter gegen die Zusicherung nach Ziffer 3.4 dieser AGB nach Abmahnung unter einer
              Besserungsfristsetzung von zwei (2) Wochen weiterhin verstößt.
            </li>
            <li>der Anbieter die Vollmachten gemäß Ziffer 3.6 dieser AGB gegenüber DrivEddy widerrufen hat.</li>
            <li>der Anbieter im Nachhinein (nach der Registrierung auf der Plattform) seine Genehmigungen bzw.
              Erlaubnisse gem. Ziffer 3.4 Buchst. e) dieser AGB verliert.
            </li>
          </ol>
          <br/>
          <strong>8.4</strong> Nach Vertragsende des Nutzungsvertrags-Plattform hat der Anbieter keinen Zugang mehr zum
          Account und zu seinem Profil. Sein Profil wird dann auch deaktiviert und ist von keinem Fahrschüler auf der
          Plattform mehr sichtbar. DrivEddy ist für die Sicherung der im Account und dem Profil hinterlegten
          Informationen nicht verantwortlich. Der Anbieter ist daher angehalten, rechtzeitig vor Ende der
          Vertragslaufzeit die für ihn relevanten Daten sofern nach diesen AGB zulässig auf einem von DrivEddy
          unabhängigem Speichermedium zu sichern, was jedoch die Regelung gem. Ziffer 3.9 dieser AGB unangetastet lässt.
          <br/><br/>
          Für den Fall, dass die Fahrschule eine Ausbildung einseitige Vorzeit kündigt, oder eine Reduzierung des
          beauftragten Leistungsumfangs beansprucht wird, werden die nicht mehr zu erbringenden Leistungen mit einer
          Ausfallpauschale in Höhe von 100% der infolge der Kündigung nicht zur Entstehung gelangten Leistungsansprüche.

        </div>


        <h5 class="lp-section-title">9. Haftung</h5>
        <div class="tc-section">
          <strong>9.1</strong> DrivEddy haftet unbeschränkt für Vorsatz und grobe Fahrlässigkeit sowie nach Maßgabe des
          Produkthaftungsgesetzes. Für leichte Fahrlässigkeit haftet DrivEddy bei Schäden aus der Verletzung des Lebens,
          des Körpers und der Gesundheit von Personen.
          <br/><br/>
          <strong>9.2</strong> Bei leichter Fahrlässigkeit haftet DrivEddy jedoch nur im Falle der Verletzung einer
          wesentlichen Vertragspflicht, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst
          ermöglicht und auf deren Einhaltung der Fahrschüler regelmäßig vertrauen darf (Kardinalpflicht). Die Haftung
          für leichte Fahrlässigkeit ist der Höhe nach beschränkt auf die bei Vertragsschluss vorhersehbaren Schäden,
          mit deren Entstehung typischerweise gerechnet werden muss. Diese Haftungsbeschränkung gilt auch zugunsten von
          Erfüllungsgehilfen von DrivEddy.
          <br/><br/>
          <strong>9.3</strong> Darüber hinaus tragen die Anbieter und die Fahrschüler bei der Nutzung des Service und
          der Plattform von DrivEddy jeweils auf eigenes Risiko. Vom Fahrschüler bzw. Anbieter verursachte Schäden trägt
          der Fahrschüler bzw. der Anbieter im direkten Verhältnis zum Anbieter bzw. Fahrschüler.
        </div>


        <h5 class="lp-section-title">10. Freistellungsanspruch und weiterführende Rechte</h5>
        <div class="tc-section">
          <strong>10.1</strong> Der Anbieter stellt DrivEddy und deren Mitarbeiter bzw. Beauftragten von sämtlichen
          Ansprüchen Dritter frei für den Fall der Inanspruchnahme wegen vermeintlicher oder tatsächlicher
          Rechtsverletzungen und/oder Verletzung von Rechten Dritter, die im Zusammenhang mit der Nutzung der Plattform
          und/oder der Veröffentlichung von Angeboten, insbesondere von Bildern, des Anbieters von Dritten geltend
          gemacht werden.
          <br/><br/>
          <strong>10.2</strong> Der Anbieter verpflichtet sich, alle etwaigen Kosten, die DrivEddy durch die
          Inanspruchnahme Dritter entstehen, zu ersetzen. Zu den erstattungsfähigen Kosten zählen auch die Kosten einer
          angemessenen Rechtsverfolgung und Rechtsverteidigung, die DrivEddy zur Abwehr von Ansprüchen Dritter entstehen
          sollten. DrivEddy informiert in diesem Falle den betroffenen Anbieter unverzüglich über vorzunehmende
          Maßnahmen der Rechtsverteidigung.
          <br/><br/>
          <strong>10.3</strong> Der Anbieter stellt DrivEddy insbesondere aber nicht abschließend von allen Ansprüchen
          frei, die Dritte gegen DrivEddy wegen der vom Anbieter eingestellten Inhalte geltend machen. Der Anbieter
          ersetzt DrivEddy jeglichen Schaden, den DrivEddy wegen der Verletzung von Rechten Dritter, die hierauf
          beruhen, entsteht, einschließlich etwaiger Kosten der Rechtsverteidigung. Der Anbieter unterstützt DrivEddy
          bei der Rechtsverteidigung.
          <br/><br/>
          <strong>10.4</strong> DrivEddy ist berechtigt, Inhalte ganz oder teilweise von der Plattform zu entfernen und
          zu löschen, wenn diese den in diesen AGB festgelegten Anforderungen nicht entsprechen.
        </div>

        <h5 class="lp-section-title">11. Datenschutz und -Sicherheit</h5>
        <div class="tc-section">
          <strong>11.1</strong> DrivEddy ist sich der Sensibilität personenbezogener Daten bewusst und beachtet beim
          Umgang mit personenbezogenen Daten der Anbieter und der Fahrschüler die gesetzlichen Vorschriften über den
          Datenschutz. Rechtsgrundlage dafür sind insbesondere aber nicht abschließend das Telekommunikationsgesetz
          (TMG), das Bundesdatenschutzgesetz (BDSG) sowie das Teledienstdatenschutzgesetz (TDDSG). Für weitere
          Informationen wird auf die Datenschutzerklärung von DrivEddy auf der Internetseite und Angaben der Plattform
          verwiesen.
          <br/><br/>
          <strong>11.2</strong> DrivEddy ist es gestattet, die angegebenen personenbezogenen Daten zu erheben, zu
          verarbeiten, zu speichern und zu nutzen, soweit dies zum Zweck der Durchführung des
          Nutzungsvertrages-Plattform und des Service der Plattform auf Grundlage dieser AGB notwendig ist. Diese Daten
          werden dann für den Service durch DrivEddy verwendet.
          <br/><br/>
          <strong>11.3</strong> DrivEddy gibt – sofern in diesem Vertrag nicht anders geregelt – in keinem Fall
          personenbezogene Daten unbefugt weiter. Zudem erteilt DrivEddy den Anbietern und Fahrschülern gemäß den
          gesetzlichen Bestimmungen unentgeltlich und unverzüglich Auskunft über die bei ihr gespeicherten
          personenbezogenen Daten.
        </div>

        <h5 class="lp-section-title">12. Schlussbestimmungen</h5>
        <div class="tc-section">
          <strong>12.1</strong> Sofern der Anbieter als Verbraucher (gem. § 13 BGB) seinen allgemeinen Gerichtsstand
          nicht in Deutschland hat, ist ausschließlicher Gerichtsstand für sämtliche Streitigkeiten aus dieser
          Rechtsbeziehung das für den Geschäftssitz von DrivEddy zuständige Gericht. Sofern der Anbieter Unternehmer
          (gem. § 14 BGB) ist, ist ausschließlicher Gerichtsstand für sämtliche Streitigkeiten aus dieser
          Rechtsbeziehung das für den Geschäftssitz von DrivEddy zuständige Gericht.
          <br/><br/>
          <strong>12.2</strong> Auf diese Rechtsbeziehungen ist ausschließlich das Recht der Bundesrepublik Deutschland
          anwendbar.
          <br/><br/>
          <strong>12.3</strong> Sollte eine dieser Bestimmungen unwirksam oder undurchführbar sein oder werden, so wird
          dadurch die Wirksamkeit der übrigen Bestimmungen nicht berührt. Anstelle der unwirksamen bzw. nicht
          durchführbaren Bestimmung soll eine Regelung treten, die im Rahmen des rechtlich Möglichen dem Willen der
          Parteien am nächsten kommt. Das gleiche gilt im Falle einer Regelungslücke.
        </div>
      </div>
    </div>
  </div>
</div>
