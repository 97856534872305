import { Directive, HostListener, Input } from '@angular/core';
import { AnalyticsService } from '@modules/analytics/services/analytics.service';

@Directive({
  selector: '[analytics]'
})
export class AnalyticsDirective {

  @Input() analyticsCategory: string;

  @Input() analyticsAction: string;

  @Input() analyticsLabel: string;

  @Input() analyticsValue: number;


  constructor(private analyticsService: AnalyticsService) {

  }

  @HostListener('click') onClick() {
    this.analyticsService.registerEvent({
      'category': this.analyticsCategory,
      'action': this.analyticsAction,
      'label': this.analyticsLabel,
      'value': +this.analyticsValue
    });
  }
}
