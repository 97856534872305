import { Asset } from '@models/asset.model';
import { CarModel } from '@models/car-model.model';

export class CarBrand {
  id: number;
  name: string;
  imagePath: string;
  isPopularBrand: boolean;

  image?: Asset;

  models: CarModel[];

  constructor(json?: any) {
    if (!json) {
      this.models = [];
      return;
    }

    this.id = +json.id;
    this.name = json.name;
    this.imagePath = json.imagePath;
    this.isPopularBrand = !!json.isPopularBrand;
    this.models = this.mapModels(json.models || []);
  }

  private mapModels(models: CarModel[]) {
    return models.map(it => new CarModel(it));
  }
}
