<div class="dialog-container">
  <div class="modal-header">
    <h4 class="modal-title">
      Account bereits vorhanden
    </h4>
  </div>
</div>

<mat-dialog-content>
  <div class="modal-body" >
    Mit dem von dir verwendeten Link wurde das Onboarding bereits abgeschlossen. 
    <br/><br/>
    Du kannst dich mit deinen Benutzerdaten einloggen. Solltest du diese nicht mehr zur Hand haben, kannst du hier auch jederzeit ein neues Passwort anfordern.
  </div>
</mat-dialog-content>

<div class="spacer"></div>

<mat-dialog-actions>
  <div class="modal-footer">
    <div type="button" class="btn-forgot" (click)="onForgotPassword()">Passwort vergessen?</div>
    <div type="button" class="btn-login" (click)="onLogin()">Anmelden</div>
  </div>
</mat-dialog-actions>
