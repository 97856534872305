import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Component } from '@angular/core';
import { BreadCrumb } from '@models/breadcrumb.model';
import { Observable } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'eddy-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {
  breadcrumbs$: Observable<BreadCrumb[]>;
  currentUrl: string;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.breadcrumbs$ = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
        map(event => {
          this.currentUrl = event['url'];
          return this.buildBreadCrumb(this.activatedRoute.root);
        }),
        filter(it => !!it)
      );
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '',
                  breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {
    // If no routeConfig is avalailable we are on the root path
    const label = route.routeConfig && route.routeConfig.data && route.routeConfig.data['breadcrumb'] ? route.routeConfig.data['breadcrumb'] : null;
    const path = route.routeConfig ? route.routeConfig.path : '';
    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    const nextUrl = `${url}${path}/`;
    const breadcrumb = {
      label: label,
      url: nextUrl,
      params: {}
    };

    let newBreadcrumbs;
    if (label === null) {
      newBreadcrumbs = breadcrumbs;
    } else {
      newBreadcrumbs = [...breadcrumbs, breadcrumb];
    }

    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return newBreadcrumbs;
  }
}
