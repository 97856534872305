import { CancellationFeeType } from './cancellation-fee-type.model';
import { PaymentType } from './payment-type.model';
import { School } from './school.model';
// import { environment as env } from '@environments/environment';

export class SchoolLessonsPreference {
  id?: number;

  schoolId: number;
  school?: School;

  paymentType: PaymentType;
  cancellationFeeType: CancellationFeeType;
  cancellationFee: number;
  maxTheoryLessonsPerWeek: number;
  maxTheoryLessonsPerDay?: number;

  theoryLessonsFlexibleBooking: boolean;
  basicTheoryTopicsPercentage: number;
  specificTheoryTopicsPercentage: number;

  bookingsServiceFeePercentage: number;
  practiceLessonsServiceFeePercentage: number;

  usesPaymentReminderStructure: boolean;

  constructor(json?: any) {
    if (!json) {
      return;
    }
    this.id = +json.id;

    this.schoolId = +json.schoolId;
    if (json.school) {
      this.school = new School(json.school);
    }

    this.paymentType = json.paymentType;
    this.cancellationFeeType = json.cancellationFeeType;
    this.cancellationFee = +json.cancellationFee;
    this.maxTheoryLessonsPerWeek = +json.maxTheoryLessonsPerWeek;
    if (json.maxTheoryLessonsPerDay !== undefined) {
      this.maxTheoryLessonsPerDay = +json.maxTheoryLessonsPerDay;
    }

    this.theoryLessonsFlexibleBooking = !!json.theoryLessonsFlexibleBooking;
    this.basicTheoryTopicsPercentage = +json.basicTheoryTopicsPercentage;
    this.specificTheoryTopicsPercentage = +json.specificTheoryTopicsPercentage;

    this.bookingsServiceFeePercentage = +json.bookingsServiceFeePercentage;
    this.practiceLessonsServiceFeePercentage =
      +json.practiceLessonsServiceFeePercentage;

    this.usesPaymentReminderStructure = !!json.usesPaymentReminderStructure;
  }

  hasUnlimitedTheoryLessonsPerWeek(): boolean {
    return !this.maxTheoryLessonsPerWeek;
  }

  isCancellationFeeType(type: CancellationFeeType) {
    return this.cancellationFeeType === type;
  }

  getCancellationFeeFormatted(type: CancellationFeeType): string {
    if (!this.cancellationFee || this.cancellationFeeType !== type) {
      return '';
    }
    if (type === CancellationFeeType.FIXED) {
      return (this.cancellationFee / 100.0).toFixed(2);
    }
    return this.cancellationFee.toString();
  }
}

export function defaultSchoolLessonsPreference(school: School) {
  let bookingsServiceFeePercentage = 2.5;
  let practiceLessonsServiceFeePercentage = 5;
  // if (env.environmentName === 'live') {
  //   bookingsServiceFeePercentage = 0;
  //   practiceLessonsServiceFeePercentage = 0;
  // }
  return new SchoolLessonsPreference({
    schoolId: school?.id,
    paymentType: school?.isDigitalBookingAndPaymentAvailable
      ? PaymentType.ALL
      : PaymentType.CASH,
    cancellationFeeType: CancellationFeeType.PERCENTAGE,
    cancellationFee: 10,
    theoryLessonsFlexibleBooking: true,
    basicTheoryTopicsPercentage: 100,
    specificTheoryTopicsPercentage: 100,
    bookingsServiceFeePercentage: bookingsServiceFeePercentage,
    practiceLessonsServiceFeePercentage: practiceLessonsServiceFeePercentage,
    usesPaymentReminderStructure: false,
  });
}
