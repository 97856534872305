import { Injectable } from '@angular/core';
import { environment as env } from '@environments/environment';
import { Offer } from '@models/offer.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable()
export class OfferService {
  constructor(private api: ApiService) {}

  getOffers(): Observable<Offer[]> {
    return this.api
      .get(`${env.apiOffers}`)
      .pipe(map((res: any[]) => this.mapOffers(res)));
  }

  getOffer(
    offerId: number,
    fromAdditionalTheoryLesson?: boolean,
    fromEmail?: boolean,
    originTheoryTopicKey?: string
  ): Observable<Offer> {
    const params = new Map<string, string>();
    if (fromAdditionalTheoryLesson) {
      params.set('fromAdditionalTheoryLesson', '' + fromAdditionalTheoryLesson);
    } else if (fromEmail) {
      params.set('fromEmail', '' + fromEmail);
    }
    if (originTheoryTopicKey) {
      params.set('originTheoryTopicKey', '' + originTheoryTopicKey);
    }

    return this.api
      .get(`${env.apiOffers}/${offerId}`, params)
      .pipe(map((it) => new Offer(it)));
  }

  saveLeadFormViewed(): Observable<void> {
    return this.api.post<void>(`${env.apiOffers}/lead-form-viewed`, {});
  }

  private mapOffers(offers: any[]) {
    return offers.map((it: any) => new Offer(it));
  }
}
