import {Learner} from '@models/learner.model';

export class PostalCodeInfo {
  zipcode: string;
  city: string;
  state: string;
  community: string;
  latitude: number;
  longitude: number;

  regionHasDigitalApplicationForm: boolean;
  regionHasDigitalApplicationFormBF17: boolean;

  constructor(json) {
    this.zipcode = json.zipcode;
    this.city = json.city;
    this.state = json.state;
    this.community = json.community;
    this.latitude = json.latitude;
    this.longitude = json.longitude;

    this.regionHasDigitalApplicationForm =
      !!json.regionHasDigitalApplicationForm;
    this.regionHasDigitalApplicationFormBF17 =
      !!json.regionHasDigitalApplicationFormBF17;
  }

  isNeckarOdenwaldKreisRegion() {
    return this.community === 'Neckar-Odenwald-Kreis';
  }

  regionHasDigitalApplicationFormForLearner(learner: Learner): boolean {
    return (
      (learner.hasLicenseBF17 &&
        this.regionHasDigitalApplicationFormBF17) ||
      this.regionHasDigitalApplicationForm
    );
  }
}
