import { Link } from './link.model';
import { TheoryQuestion } from './theory-question.model';

export class TheoryTopicQuestion {
  id: number;

  theoryTopicKey: string;
  theoryQuestionId: number;
  theoryQuestion?: TheoryQuestion;

  correctLinkId: number;
  correctLink?: Link;

  constructor(json: any) {
    this.id = +json.id;

    this.theoryTopicKey = json.theoryTopicKey;
    this.theoryQuestionId = +json.theoryQuestionId;
    if (json.theoryQuestion) {
      this.theoryQuestion = new TheoryQuestion(json.theoryQuestion);
    }

    this.correctLinkId = +json.correctLinkId;
    this.correctLink = json.correctLink;
  }

  get linkUrl(): string {
    return this.correctLink?.url;
  }

  get linkLabel(): string {
    return this.correctLink?.label;
  }
}
