import { formatNumber } from '@angular/common';

export class NumberUtils {
  static padStart(value: number, maxSize: number, padValue: string): string {
    return ('' + value).padStart(maxSize, padValue);
  }

  static formatCurrency(amountInCents: number, convert = true): string {
    if (convert) {
      return `${formatNumber(amountInCents / 100.0, 'de_DE', '.2-2')} €`;
    }

    return `${formatNumber(amountInCents, 'de_DE', '.2-2')} €`;
  }
}
