import { Injectable } from '@angular/core';
import { environment as env } from '@environments/environment';
import { Observable, throwError } from 'rxjs';
import { buildAdClickEvent, buildAdImpressionEvent } from '@models/ad-event.model';
import { Asset } from '@models/asset.model';
import { AssetType } from '@models/asset-type.model';
import { ApiService } from '@services/api.service';

@Injectable()
export class AffiliateService {

  constructor(private api: ApiService) {
  }

  getAd(assetType: AssetType): Observable<Asset> {
    if (!assetType) {
      return throwError('assetType must be set');
    }

    const params = new Map<string, string>();
    params.set('assetType', assetType);

    return this.api.get<Asset>(`${env.apiAdSelector}/serve-ad`, params);
  }

  getAds(assetTypes: AssetType[]): Observable<Asset[]> {
    if (!assetTypes || assetTypes.length === 0) {
      return throwError('assetTypes must be set');
    }

    const params = new Map<string, string>();
    params.set('assetTypes', assetTypes.join('|'));

    return this.api.get<Asset[]>(`${env.apiAdSelector}/serve-ads`, params);
  }

  markAdImpression(asset: Asset, space: string): Observable<void> {
    const body = buildAdImpressionEvent(asset.campaignId, asset.campaignName, asset.id, space);
    return this.eventTracking(body);
  }

  markAdClick(asset: Asset, space: string): Observable<void> {
    const body = buildAdClickEvent(asset.campaignId, asset.campaignName, asset.id, space);
    return this.eventTracking(body);
  }

  private eventTracking(body) {
    body.adBlockerEnabled = (<any>window).adBlockerEnabled;
    return this.api.post(`${env.apiEventTracking}/`, body);
  }
}
