import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppRoutes } from 'app/app.routes';

@Component({
  selector: 'eddy-account-exists-dialog',
  templateUrl: './account-exists.dialog.html',
  styleUrls: ['./account-exists.dialog.scss']
})
export class AccountExistsDialog implements OnInit {
 
  constructor(
    private dialogRef: MatDialogRef<AccountExistsDialog>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  onForgotPassword() {
    this.dialogRef.close();
    this.router.navigate([AppRoutes.Root.login], {queryParams: {forgotPassword: true}});
  }

  onLogin() {
    this.dialogRef.close();
    this.router.navigate([AppRoutes.Root.login]);
  }

}
