export class UserSurveyStats {
    answeredQuestions: number;
    questionsSeen: number;
    answerRate: number;
    totalQuestions: number;

    constructor(json: any) {
        this.answeredQuestions = +json.answeredQuestions;
        this.questionsSeen = +json.questionsSeen;
        this.answerRate = +json.answerRate;
        this.totalQuestions = +json.totalQuestions;
    }
}

export function emptyUserSurveyStats(): UserSurveyStats {
    return new UserSurveyStats({
        answeredQuestions: 0,
        questionsSeen: 0,
        answerRate: 0,
        totalQuestions: 0
    });
}
