import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  templateUrl: './popup-widget.dialog.html',
  styleUrls: ['./popup-widget.dialog.scss']
})
export class PopupWidgetDialog {
  iframeSrc: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<PopupWidgetDialog>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.iframeSrc = this.safe(this.data.url);
  }

  safe(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onClose() {
    this.dialogRef.close();
  }
}
