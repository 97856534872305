import { connect, ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from './api.service';
import { VideoChatNumberOfParticipants } from '@models/video-chat-number-of-participants.model';

interface AuthToken {
    accessToken: string;
    clientName: string;
    roomId: number;
    roomName: string;
    roomSID: string;
}

export interface NamedRoom {
    id: string;
    name: string;
    maxParticipants?: number;
    participantCount: number;
}

export type Rooms = NamedRoom[];

@Injectable()
export class VideoChatService {
    $roomsUpdated: Observable<boolean>;

    private roomBroadcast = new ReplaySubject<boolean>();

    constructor(private readonly api: ApiService) {
        this.$roomsUpdated = this.roomBroadcast.asObservable();
    }

    async joinRoom(tracks: LocalTrack[], lessonId: number, isPrivateVideoChat: boolean, previousRoomName: string): Promise<[Room, string]> {
        let room: Room = null;
        let connectError: string = null;
        try {
            const params = new Map<string, any>();
            params.set('isPrivateVideoChat', isPrivateVideoChat);
            if (!isPrivateVideoChat && previousRoomName) {
                params.set('previousRoomName', previousRoomName);
            }

            const response = await this.api.get<AuthToken>(`${env.api}/twilio/joinRoom/${lessonId}`, params).toPromise();

            const name = response.roomSID;
            const token = response.accessToken;
            room = await connect(
                token, {
                    name,
                    tracks,
                    dominantSpeaker: true
                } as ConnectOptions);
        } catch (error) {
            console.error(`Unable to connect to Room: ${error.error}`);
            connectError = error.error;
        } finally {
            if (room) {
                this.roomBroadcast.next(true);
            }
        }

        return [room, connectError];
    }

    async joinRooms(tracks: LocalTrack[], lessonId: number, roomParticipantsLimit: number = 40) {
        const rooms: Room[] = [];
        try {
            const response = await this.api.get<AuthToken[]>(`${env.api}/twilio/joinRooms/${lessonId}/${roomParticipantsLimit}`).toPromise();
            for (const authToken of response) {
                const name = authToken.roomSID;
                const token = authToken.accessToken;
                const room = await connect(
                    token, {
                        name,
                        tracks,
                        dominantSpeaker: true
                    } as ConnectOptions);
                rooms.push(room);
            }
        } catch (error) {
            console.error(`Unable to connect to Room: ${error.message}`);
        } finally {
            if (rooms.length > 0) {
                this.roomBroadcast.next(true);
            }
        }

        return rooms;
    }

    getNumberOfParticipantsInLesson(theoryLessonId: number): Observable<VideoChatNumberOfParticipants> {
        return this.api.get<VideoChatNumberOfParticipants>(`${env.api}/twilio/numberOfParticipants/${theoryLessonId}`);
    }

    async createPrivateChatRoom(tracks: LocalTrack[], lessonId: number) {
        let room: Room = null;
        try {
            const response = await this.api.post<AuthToken>(`${env.api}/twilio/createPrivateChatRoom/${lessonId}`, {}).toPromise();

            const name = response.roomSID;
            const token = response.accessToken;
            room = await connect(
                token, {
                    name,
                    tracks,
                    dominantSpeaker: true
                } as ConnectOptions);
        } catch (error) {
            console.error(`Unable to connect to Private Room: ${error.message}`);
        } finally {
            if (room) {
                this.roomBroadcast.next(true);
            }
        }

        return [room];
    }

    nudge() {
        this.roomBroadcast.next(true);
    }
}
