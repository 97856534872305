import {
  ErrorHandler,
  Injectable,
  Injector,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrModule } from 'ngx-toastr';

import { Nl2BrPipeModule } from 'nl2br-pipe';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha';

import { SchoolService } from '@services/school.service';
import { AuthGuard } from '@services/auth-guard.service';
import { AuthRoleGuard } from '@services/auth-role-guard.service';
import { OnlyUnauthenticatedUsersGuard } from '@services/only-unauthenticated-users.guard.service';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { AuthService } from '@services/auth.service';

import { LoginComponent } from './components/login/login.component';

import { StudentsService } from '@services/students.service';

import { LicenseService } from '@services/license.service';

import { InstructorsService } from '@services/instructors.service';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SidenavService } from '@services/sidenav.service';

import { LocationService } from '@services/location.service';

import { ScrollTopService } from '@services/scroll-to-top.service';

import { ModuleRouting } from './app.routing';

import { RouteReuseStrategy, UrlSerializer } from '@angular/router';
import { CustomReuseStrategy } from './reuse-strategy';

import { registerLocaleData } from '@angular/common';
import localeZh from '@angular/common/locales/de';

import { SchoolLanguageSupportedDialogComponent } from './components/schools/school-language-supported-dialog/school-language-supported-dialog.component';

import { ForgotPasswordDialogComponent } from './components/login/forgot-password-dialog/forgot-password-dialog.component';
import { StudentRegisterInfoComponent } from './components/students/student-register-info/student-register-info.component';
import { CityService } from '@services/city.service';
import { GlobalCommunicationService } from '@services/global-communication.service';
import { SpecialCharactersReplacementService } from '@services/special-characters-replacement.service';
import { UserDoiComponent } from './components/user/user-doi/user-doi.component';
import { AddressBuilderService } from '@services/address-builder.service';
import { SharedModule } from '@modules/shared/platform/shared.module';
import { BrowserModule, HammerModule, Title } from '@angular/platform-browser';
import { CalendlyService } from '@services/calendly.service';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { AdvertisingService } from '@services/advertising.service';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { AffiliateService } from '@services/affiliate.service';
import { ApiService } from '@services/api.service';
import { UserService } from '@services/user.service';
import { RecaptchaService } from '@services/recaptcha.service';
import { SchoolProfileService } from '@services/school-profile.service';
import { MetaService } from '@services/meta.service';
import { ScreenWidthService } from '@services/screen-width.service';
import { QuestionService } from '@services/question.service';
import { FindDrivingSchoolPage } from './pages/find-driving-school/find-driving-school.page';
import { CarService } from '@services/car.service';
import { TeamMemberService } from '@services/team-member.service';
import { InternalSidenavComponent } from './components/internal-sidenav/internal-sidenav.component';
import { InternalHeaderComponent } from './components/internal-header/internal-header.component';
import { SchoolOpeningHourService } from '@services/school-opening-hour.service';
import { NotificationService } from '@services/notification.service';
import { AppFeedbackService } from '@services/app-feedback.service';
import { SchoolLearnerService } from '@services/school-learner.service';
import { AccountService } from '@services/account.service';
import { LanguageService } from '@services/language.service';
import { CalendarService } from '@services/calendar.service';
import { TheoryLessonService } from '@services/theory-lesson.service';
import { LessonBookingService } from '@services/lesson-booking.service';
import { LearnerService } from '@services/learner.service';
import { PracticeLessonService } from '@services/practice-lesson.service';
import { AuthSchoolStatusGuard } from '@services/auth-school-status-guard.service';
import { LearnerTermsAndConditionsPage } from './pages/learner-terms-and-conditions/learner-terms-and-conditions.page';
import { InstructorTermsAndConditionsPage } from './pages/instructor-terms-and-conditions/instructor-terms-and-conditions.page';
import { DataPrivacyAppPage } from './pages/data-privacy-app/data-privacy-app.page';
import { DataPrivacyWebPage } from './pages/data-privacy-web/data-privacy-web.page';
import { ImprintPage } from './pages/imprint/imprint.page';
import { CoronavirusInfoComponent } from './components/coronavirus-info/coronavirus-info.component';
import { EddyAcademyPage } from './pages/eddy-academy/eddy-academy.page';
import { OnlineTheoryLicenseBPage } from './pages/online-theory-license-b/online-theory-license-b.page';
import { InternalUserIconComponent } from './components/internal-user-icon/internal-user-icon.component';

import * as Sentry from '@sentry/browser';
import { environment as env } from '@environments/environment';
import { ZoomService } from '@services/zoom.service';
import { ChatService } from '@services/chat.service';

import { AcademyLessonComponent } from './components/academy-lesson/academy-lesson.component';
import { SignUpDialog } from './dialogs/sign-up/sign-up.dialog';
import { ThankYouForYourInterestPage } from './pages/thank-you-for-your-interest/thank-you-for-your-interest.page';
import { ThankYouForYourRegistrationPage } from './pages/thank-you-for-your-registration/thank-you-for-your-registration.page';
import { ThankYouForYourInquiryPage } from './pages/thank-you-for-your-inquiry/thank-you-for-your-inquiry.page';
import { PurchaseThankYouPage } from './pages/purchase-thank-you/purchase-thank-you.page';
import { LowerCaseUrlSerializer } from '@services/url-serializer';
import { Breadcrumb, BreadcrumbHint } from '@sentry/types/dist/breadcrumb';
import { AutoCompleteSearchService } from '@services/auto-complete.service';
import { GlobalErrorHandler } from '@services/global-error-handler';
import { WebNotificationService } from '@services/web-notification.service';
import { ConnectionService } from '@services/connection.service';
import { DeviceService } from '@services/device.service';
import { VideoChatService } from '@services/videochat.service';
import { StorageService } from '@services/storage.service';
import { LeadService } from '@services/lead.service';

import { version } from '@environments/../../package.json';
import { OnlineTimeLogService } from '@services/online-time-log.service';
import { AuthTeamMemberPositionGuard } from '@services/auth-team-member-position-guard.service';
import { ReportService } from '@services/report.service';
import { AppTheoryQuestionService } from '@services/app-theory-question.service';
import { PayoutService } from '@services/payout.service';
import { OfferService } from '@services/offer.service';
import { ExportService } from '@services/export.service';
import {PriceListService} from '@services/price-list.service';

registerLocaleData(localeZh);

@Injectable()
export class MatPaginatorIntlGermany extends MatPaginatorIntl {
  itemsPerPageLabel = 'Einträge pro Seite ';
  nextPageLabel = 'Nächste Seite';
  previousPageLabel = 'Vorherige Seite';

  getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '0 von ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' von ' + length;
  };
}

const dialogs = [
  SignUpDialog,

  SchoolLanguageSupportedDialogComponent,

  ForgotPasswordDialogComponent,
];

const modules = [
  BrowserModule.withServerTransition({ appId: 'serverApp' }),
  SharedModule,
  ScrollToModule.forRoot(),
  RecaptchaModule,
];

const components = [
  BreadcrumbsComponent,

  HeaderComponent,
  InternalHeaderComponent,
  InternalUserIconComponent,
  FooterComponent,

  LoginComponent,

  StudentRegisterInfoComponent,

  SidenavComponent,
  InternalSidenavComponent,

  UserDoiComponent,

  CoronavirusInfoComponent,

  AcademyLessonComponent,
];

const pages = [
  AppComponent,
  FindDrivingSchoolPage,
  LearnerTermsAndConditionsPage,
  InstructorTermsAndConditionsPage,
  DataPrivacyAppPage,
  DataPrivacyWebPage,
  ImprintPage,
  EddyAcademyPage,
  OnlineTheoryLicenseBPage,
  ThankYouForYourInterestPage,
  ThankYouForYourRegistrationPage,
  ThankYouForYourInquiryPage,
  PurchaseThankYouPage,
];

const services = [
  Title,
  AuthGuard,
  AuthRoleGuard,
  OnlyUnauthenticatedUsersGuard,
  AuthSchoolStatusGuard,
  AuthTeamMemberPositionGuard,
  MatSnackBar,

  AuthService,
  AccountService,
  LanguageService,

  SchoolService,
  CityService,
  LicenseService,

  UserService,
  StudentsService,
  InstructorsService,

  SidenavService,
  LocationService,
  ScrollTopService,
  GlobalCommunicationService,
  SpecialCharactersReplacementService,
  AddressBuilderService,

  CalendlyService,
  AdvertisingService,
  AffiliateService,
  RecaptchaService,
  SchoolProfileService,
  MetaService,
  ScreenWidthService,
  QuestionService,
  AppTheoryQuestionService,
  CarService,
  TeamMemberService,
  SchoolOpeningHourService,
  NotificationService,
  AppFeedbackService,
  SchoolLearnerService,
  CalendarService,
  TheoryLessonService,
  LessonBookingService,
  LearnerService,
  PracticeLessonService,
  ZoomService,
  ChatService,
  AutoCompleteSearchService,
  CookieService,
  WebNotificationService,
  ConnectionService,
  LeadService,
  OfferService,

  DeviceService,
  VideoChatService,
  StorageService,

  OnlineTimeLogService,

  ReportService,

  PayoutService,

  ExportService,

  ApiService,
  PriceListService,
];

if (env.environmentName !== 'dev') {
  const excludedErrors = ['sse-service', 'network-error', 'network error'];
  Sentry.init({
    release: version,
    environment: env.environmentName,
    dsn: 'https://d5c727c5a25443b7b4a1777a62b45836@o923539.ingest.sentry.io/5871613',
    beforeSend: function (event, hint): any {
      const isNonErrorException =
        (!!event.exception?.values &&
          event.exception?.values[0].value.startsWith(
            'Non-Error exception captured'
          )) ||
        (hint.originalException &&
          hint.originalException['message']?.startsWith(
            'Non-Error exception captured'
          ));

      if (isNonErrorException) {
        // We want to ignore those kind of errors
        return null;
      }

      const isExcludedError =
        (event.exception?.values &&
          event.exception?.values[0].value &&
          excludedErrors.find(
            (it) =>
              event.exception?.values &&
              event.exception?.values[0].value.indexOf(it) !== -1
          )) ||
        (hint?.originalException &&
          hint?.originalException['message'] &&
          excludedErrors.find(
            (it) => (<any>hint?.originalException)['message'].indexOf(it) !== -1
          ));
      if (isExcludedError) {
        // don't log excluded errors
        return;
      }

      return event;
    },
    beforeBreadcrumb: function (
      breadcrumb: Breadcrumb,
      hint?: BreadcrumbHint
    ): Breadcrumb | null {
      if (
        breadcrumb.data &&
        breadcrumb.data.url &&
        breadcrumb.data.url.indexOf(env.apiSSEService) !== -1
      ) {
        return null;
      }

      return breadcrumb;
    },
  });
}

@Injectable()
export class SentryErrorHandler extends GlobalErrorHandler {
  private accountService: AccountService;
  private excludedErrors = ['sse-service', 'network-error', 'network error'];

  constructor(injector: Injector) {
    super();
    setTimeout(
      () => (this.accountService = injector.get(AccountService)),
      1000
    );
    setTimeout(() => {
      if (this.accountService) {
        this.accountService.getCurrentUser().subscribe((user) => {
          if (user?.id) {
            Sentry.configureScope(function (scope) {
              scope.setUser({ id: user.id + '' });
            });
          }
        });
      }
    }, 3000);
  }

  handleError(error: any) {
    super.handleError(error);

    if (env.environmentName === 'dev') {
      console.error(error.originalError || error);
      return;
    }

    if (this.accountService) {
      this.accountService.getCurrentUser().subscribe((user) => {
        Sentry.configureScope(function (scope) {
          scope.setUser({ id: user.id + '' });
        });
      });
    }

    const err = error.originalError || error;
    if (err) {
      if (
        (err.indexOf &&
          this.excludedErrors.find((it) => err.indexOf(it) !== -1)) ||
        (err.error &&
          err.error.indexOf &&
          this.excludedErrors.find((it) => err.error.indexOf(it) !== -1))
      ) {
        // don't log excluded errors
        return;
      }

      Sentry.captureException(err);
    }
  }
}

@NgModule({
  declarations: [...dialogs, ...components, ...pages],
  imports: [
    ModuleRouting,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),

    Nl2BrPipeModule,

    ...modules,

    HammerModule,
  ],
  providers: [
    ...services,

    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlGermany },

    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE', // 'de-DE' for Germany, 'fr-FR' for France ...
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6Lc8230UAAAAAHCoi-pClQsUpALLZ8YViaUVSn7g',
      } as RecaptchaSettings,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
