<div class="card internal-page-card">
  <div *ngIf="(!editMode && !editDisabled)" class="edit-btn-container" (click)="onEdit()">
    <i class="fal fa-pencil"></i>
  </div>

  <div *ngIf="(editMode && !editDisabled)" class="edit-btn-container" (click)="onViewOnly()">
    <i class="fal fa-eye"></i>
  </div>

  <ng-content></ng-content>
</div>
