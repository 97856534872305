import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { environment as env } from '@environments/environment';

import { LearnerLearningStatus } from '@models/learner-learning-status.model';
import { PageListingResponse } from '@models/page-listing-response.model';
import { TheoryCategory } from '@models/theory-category.model';
import { TheoryQuestionLearner } from '@models/theory-question-learner.model';
import { TheoryQuestionStatsByCategory } from '@models/theory-question-stats-by-category.model';
import { TheoryQuestionStats } from '@models/theory-question-stats.model';
import { TheoryQuestion } from '@models/theory-question.model';
import { TheoryTopicQuestion } from '@models/theory-topic-question.model';
import { ApiService } from './api.service';
import { SaveTheoryExamResult } from '@models/save-theory-exam-result.model';

@Injectable()
export class AppTheoryQuestionService {
  private readonly apiPath = `${env.api}`;

  constructor(private api: ApiService) {}

  getTheoryTopicQuestion(id: number): Observable<TheoryTopicQuestion> {
    return this.api
      .get(`${this.apiPath}/app_theory_topic_questions/${id}`)
      .pipe(map((it) => new TheoryTopicQuestion(it)));
  }

  saveTheoryExamResult(req: SaveTheoryExamResult): Observable<void> {
    return this.api.post(`${this.apiPath}/insert_exam_result`, req);
  }

  getHardestTheoryQuestionsPaged(
    pageNumber: number,
    pageSize: number,
    schoolId?: number,
    learnerId?: number,
    filters?: Map<string, string>
  ): Observable<PageListingResponse<TheoryQuestion>> {
    if (!filters) {
      filters = new Map();
    }
    if (schoolId) {
      filters.set('schoolId', '' + schoolId);
    }
    if (learnerId) {
      filters.set('learnerId', '' + learnerId);
    }

    return this.api
      .getPaged(
        `${this.apiPath}/app_theory_questions/hardestTheoryQuestions`,
        pageNumber,
        pageSize,
        filters
      )
      .pipe(map(this.mapTheoryQuestionsPaged));
  }

  getHardestTheoryQuestionsExportDocument(
    schoolId?: number,
    learnerId?: number,
    filters?: Map<string, string>
  ): Observable<any> {
    if (!filters) {
      filters = new Map();
    }
    if (schoolId) {
      filters.set('schoolId', '' + schoolId);
    }
    if (learnerId) {
      filters.set('learnerId', '' + learnerId);
    }

    return this.api
      .getBlob(
        `${this.apiPath}/app_theory_questions/hardestTheoryQuestionsExportDocument`,
        filters
      )
      .pipe(
        tap((blob: Blob) => {
          saveAs(blob, `Schwierigste Fragen-Export.pdf`);
        })
      );
  }

  getHardestTheoryQuestionsExportCsv(
    schoolId?: number,
    learnerId?: number,
    filters?: Map<string, string>
  ): Observable<any> {
    if (!filters) {
      filters = new Map();
    }
    if (schoolId) {
      filters.set('schoolId', '' + schoolId);
    }
    if (learnerId) {
      filters.set('learnerId', '' + learnerId);
    }

    return this.api
      .getBlob(
        `${this.apiPath}/app_theory_questions/hardestTheoryQuestionsExportCsv`,
        filters
      )
      .pipe(
        tap((blob: Blob) => {
          saveAs(blob, `Schwierigste Fragen-Export.csv`);
        })
      );
  }

  getTheoryQuestionStats(
    schoolId?: number,
    learnerId?: number,
    filters?: any
  ): Observable<TheoryQuestionStats> {
    if (!filters) {
      filters = new Map();
    }
    if (schoolId) {
      filters.set('schoolId', '' + schoolId);
    }
    if (learnerId) {
      filters.set('learnerId', '' + learnerId);
    }
    return this.api
      .get(`${this.apiPath}/app_theory_questions/stats`, filters)
      .pipe(map((it) => new TheoryQuestionStats(it)));
  }

  getTheoryQuestionStatsByCategory(
    schoolId?: number,
    learnerId?: number,
    filters?: any
  ): Observable<TheoryQuestionStatsByCategory[]> {
    if (!filters) {
      filters = new Map();
    }
    if (schoolId) {
      filters.set('schoolId', '' + schoolId);
    }
    if (learnerId) {
      filters.set('learnerId', '' + learnerId);
    }

    return this.api
      .get(`${this.apiPath}/app_theory_questions/statsByCategory`, filters)
      .pipe(
        map((res: any[]) =>
          res ? res.map((it) => new TheoryQuestionStatsByCategory(it)) : res
        )
      );
  }

  getAllQuestionsForLearnerPaged(
    learnerId: number,
    pageNumber: number,
    pageSize: number
  ): Observable<PageListingResponse<TheoryQuestionLearner>> {
    const params: Map<string, string> = new Map();
    params.set('learnerId', '' + learnerId);

    return this.api
      .getPaged(
        `${this.apiPath}/app_theory_questions/learner`,
        pageNumber,
        pageSize,
        params
      )
      .pipe(map(this.mapTheoryQuestionsLearnerPaged));
  }

  getSchoolLearnersLearningStatusesPaged(
    schoolId: number,
    pageNumber: number,
    pageSize: number,
    filters?: Map<string, string>
  ): Observable<PageListingResponse<LearnerLearningStatus>> {
    if (!filters) {
      filters = new Map();
    }
    filters.set('schoolId', '' + schoolId);

    return this.api
      .getPaged(
        `${this.apiPath}/app_theory_questions/learningStatuses`,
        pageNumber,
        pageSize,
        filters
      )
      .pipe(map(this.mapSchoolLearnersLearningStatusesPaged));
  }

  getExamReadyLearners(
    schoolId: number,
    filters?: Map<string, string>
  ): Observable<LearnerLearningStatus[]> {
    if (!filters) {
      filters = new Map();
    }
    filters.set('schoolId', '' + schoolId);

    return this.api
      .get(`${this.apiPath}/app_theory_questions/examReadyLearners`, filters)
      .pipe(map(this.mapLearnerLearningStatuses));
  }

  private mapLearnerLearningStatuses(learnerLearningStatuses: any[]) {
    return learnerLearningStatuses.map(
      (it: any) => new LearnerLearningStatus(it)
    );
  }

  getAllTheoryCategories(mainOnly?: boolean): Observable<TheoryCategory[]> {
    const filters = new Map();

    if (mainOnly) {
      filters.set('mainOnly', mainOnly);
    }

    return this.api
      .get<TheoryCategory[]>(`${this.apiPath}/app_theory_categories`, filters)
      .pipe(
        map((res: TheoryCategory[]) => res.map((it) => new TheoryCategory(it)))
      );
  }

  private mapTheoryQuestionsPaged(
    res: PageListingResponse
  ): PageListingResponse<TheoryQuestion> {
    return {
      total: res.total,
      records: res.records.map((it) => new TheoryQuestion(it)),
    };
  }

  private mapTheoryQuestionsLearnerPaged(
    res: PageListingResponse
  ): PageListingResponse<TheoryQuestionLearner> {
    return {
      total: res.total,
      records: res.records.map((it) => new TheoryQuestionLearner(it)),
    };
  }

  private mapSchoolLearnersLearningStatusesPaged(
    res: PageListingResponse
  ): PageListingResponse<LearnerLearningStatus> {
    return {
      total: res.total,
      records: res.records.map((it) => new LearnerLearningStatus(it)),
    };
  }
}
