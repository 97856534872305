<ng-container *ngIf="postInfo">
  <a [href]="postInfo.link" target="_blank" rel="noopener">
    <div class="post-info-wrapper">
      <img class="post-image" [src]="postInfo.imagePath"/>
      <div class="post-text">
        <div class="post-title">
          {{ postInfo.title }}
        </div>
        <div class="post-metadata">
          <div class="d-flex">
            <div class="author-icon">
              <img src="/assets/eddy-avatar.png"/>
            </div>
            <div class="author-metadata">
              <div class="author-name">Eddy</div>
              <div class="author-type">Fahrlehrer</div>
            </div>
          </div>

          <div class="reading-time">
            <span class="reading-minutes">{{ postInfo.readingMinutes }}</span>&nbsp;Min Lesedauer
          </div>
        </div>
      </div>
    </div>
  </a>
</ng-container>
