import { UserInfo } from '@models/user-info.model';
import {
  getDisplayName as getSourceOfReferenceDisplayName,
  SourceOfReference,
} from './source-of-reference.model';
import {
  getDisplayName as getMaritalStatusDisplayName,
  MaritalStatus,
} from './marital-status.model';
import {
  getDisplayName as getPaymentPreferenceDisplayName,
  PaymentPreference,
} from './payment-preference.model';
import {
  getWorkDisplayName as getWorkStatusDisplayName,
  WorkStatus,
} from '@models/work-status.model';
import { LearnerGuardian } from './learner-guardian.model';
import { LearnerExistingLicenseInfo } from './learner-existing-license-info.model';
import { LearnerTrainingInfo } from './learner-training-info.model';
import { LearnerBillingAddress } from '@models/learner-billing-address.model';
import { LearnerAddress } from './learner-address.model';
import * as moment from 'moment';
import { NotificationPreferences } from '@models/notification-preferences.model';
import { LessonAttendanceFrequency } from './learner-lesson-attendance-frequency.model';
import { License } from './license.model';
import { LearnerDrivingCompanion } from './learner-driving-companion.model';
import { LearnerPaymentFlag } from './learner-payment-flag.model';
import { LearnerTheoryPackage } from './learner-theory-package.model';
import { LearnerLessonsPreference } from './learner-lessons-preference.model';
import { LearnerYoudriveData } from './learner-youdrive-data.model';
import { LearnerDocument } from './learner-document.model';
import { LearnerDocumentType } from './learner-document-type.model';
import { LearnerSeen, LearnerSeenType } from './learner-seen.model';

export class Learner {
  id: number;
  createdAt: Date;
  updatedAt: Date;

  key: string;

  userId: number;
  user: UserInfo;

  licenses: LearnerLicense[];

  paymentCustomerNumber: string;

  isEddyClubLearner: boolean;
  usesHarmonization: boolean;
  desiredLicenseStartDate?: Date;

  sourceOfReference?: SourceOfReference;
  sourceOfReferencePerson?: string;

  placeOfBirth?: string;
  nationality?: string;
  job?: WorkStatus;
  maritalStatus?: MaritalStatus;
  address?: LearnerAddress;
  guardian?: LearnerGuardian;
  drivingCompanion?: LearnerDrivingCompanion;
  existingLicenseInfo?: LearnerExistingLicenseInfo;
  paymentPreference?: PaymentPreference;
  billingAddress?: LearnerBillingAddress;
  trainingInfo?: LearnerTrainingInfo;
  notificationPreferences: NotificationPreferences;
  lessonAttendanceFrequency?: LessonAttendanceFrequency;
  learnerLessonsPreference?: LearnerLessonsPreference;

  isTheoryCompleted: boolean;
  canBookTheoryLessons: boolean;
  canBookPracticeLessons: boolean;
  canInstructorBookPracticeLessons: boolean;

  sawEddyAcademyWelcomePage: boolean;
  openedGoogleRating: boolean;
  isTheoryLearningActive: boolean;
  meetsOnlineTheoryRequirements?: boolean;

  drivingLicenseApplicationDownloaded: boolean;

  fastlaneSchoolBookingOnly: boolean;

  learnerTheoryPackage?: LearnerTheoryPackage;

  documents?: LearnerDocument[];

  countryOfBirth?: string;

  consentEcb?: boolean;
  visionTest?: boolean;
  firstAidCourse?: boolean;
  visualAidsRequired?: boolean;
  physicalAndMentalDefects?: boolean;

  secondLanguage?: string;

  applicationSubmitted?: boolean;
  applicationSubmittedOn?: Date;

  STVANumber?: string;
  TUVReturnNumber?: string;
  returnDate?: Date;

  note?: string;

  youdriveData?: LearnerYoudriveData;

  seens: LearnerSeen[];

  constructor(json?: any) {
    if (!json) {
      return;
    }

    this.id = +json.id;
    this.createdAt = new Date(json.createdAt);
    this.updatedAt = new Date(json.updatedAt);

    this.key = json.key;

    this.userId = json.userId;
    this.user = new UserInfo(json.user);

    if (json.licenses && json.licenses !== null && json.licenses.length > 0) {
      this.licenses = this.mapLearnerLicenses(json.licenses);
    } else {
      this.licenses = [];
    }
    this.paymentCustomerNumber = json.paymentCustomerNumber;
    this.isEddyClubLearner = !!json.isEddyClubLearner;
    this.usesHarmonization = !!json.usesHarmonization;
    if (json.desiredLicenseStartDate) {
      this.desiredLicenseStartDate = new Date(json.desiredLicenseStartDate);
    }
    if (json.sourceOfReference) {
      this.sourceOfReference = json.sourceOfReference;
    }
    if (json.sourceOfReferencePerson) {
      this.sourceOfReferencePerson = json.sourceOfReferencePerson;
    }
    if (json.placeOfBirth) {
      this.placeOfBirth = json.placeOfBirth;
    }
    if (json.nationality) {
      this.nationality = json.nationality;
    }
    if (json.job) {
      this.job = json.job;
    }
    if (json.maritalStatus) {
      this.maritalStatus = json.maritalStatus;
    }
    this.address = new LearnerAddress(json.address);
    this.guardian = new LearnerGuardian(json.guardian);
    this.drivingCompanion = new LearnerDrivingCompanion(json.drivingCompanion);
    if (json.existingLicenseInfo) {
      this.existingLicenseInfo = new LearnerExistingLicenseInfo(
        json.existingLicenseInfo
      );
    }
    if (json.paymentPreference) {
      this.paymentPreference = json.paymentPreference;
    }
    this.billingAddress = new LearnerBillingAddress(json.billingAddress);
    if (json.trainingInfo) {
      this.trainingInfo = new LearnerTrainingInfo(json.trainingInfo);
    }
    if (json.lessonAttendanceFrequency) {
      this.lessonAttendanceFrequency = new LessonAttendanceFrequency(
        json.lessonAttendanceFrequency
      );
    }
    if (json.learnerLessonsPreference) {
      this.learnerLessonsPreference = new LearnerLessonsPreference(
        json.learnerLessonsPreference
      );
    }

    this.notificationPreferences = json.notificationPreferences;

    this.isTheoryCompleted = !!json.isTheoryCompleted;
    this.canBookTheoryLessons = !!json.canBookTheoryLessons;
    this.canBookPracticeLessons = !!json.canBookPracticeLessons;
    this.canInstructorBookPracticeLessons =
      !!json.canInstructorBookPracticeLessons;

    this.sawEddyAcademyWelcomePage = !!json.sawEddyAcademyWelcomePage;
    this.openedGoogleRating = !!json.openedGoogleRating;
    this.isTheoryLearningActive = !!json.isTheoryLearningActive;
    if (json.meetsOnlineTheoryRequirements !== undefined) {
      this.meetsOnlineTheoryRequirements = !!json.meetsOnlineTheoryRequirements;
    }

    this.drivingLicenseApplicationDownloaded =
      !!json.drivingLicenseApplicationDownloaded;

    this.fastlaneSchoolBookingOnly = !!json.fastlaneSchoolBookingOnly;

    if (json.learnerTheoryPackage) {
      this.learnerTheoryPackage = new LearnerTheoryPackage(
        json.learnerTheoryPackage
      );
    }

    if (json.documents) {
      this.documents = json.documents
        ? this.mapLearnerDocuments(json.documents)
        : [];
    }

    this.countryOfBirth = json.countryOfBirth;

    if (json.consentEcb !== undefined) {
      this.consentEcb = !!json.consentEcb;
    }
    if (json.visionTest !== undefined) {
      this.visionTest = !!json.visionTest;
    }
    if (json.firstAidCourse !== undefined) {
      this.firstAidCourse = !!json.firstAidCourse;
    }
    if (json.visualAidsRequired !== undefined) {
      this.visualAidsRequired = !!json.visualAidsRequired;
    }
    if (json.physicalAndMentalDefects !== undefined) {
      this.physicalAndMentalDefects = !!json.physicalAndMentalDefects;
    }

    this.secondLanguage = json.secondLanguage;

    if (json.applicationSubmitted !== undefined) {
      this.applicationSubmitted = !!json.applicationSubmitted;
    }
    if (json.applicationSubmittedOn) {
      this.applicationSubmittedOn = new Date(json.applicationSubmittedOn);
    }

    this.STVANumber = json.STVANumber;
    this.TUVReturnNumber = json.TUVReturnNumber;
    if (json.returnDate) {
      this.returnDate = new Date(json.returnDate);
    }

    this.note = json.note;

    if (json.youdriveData) {
      this.youdriveData = new LearnerYoudriveData(json.youdriveData);
    }

    this.seens = json.seens;
  }

  get salutation() {
    return this.user.salutation;
  }

  get title() {
    return this.user.title;
  }

  get firstName() {
    return this.user.firstName;
  }

  get lastName() {
    return this.user.lastName;
  }

  get birthName() {
    return this.user.birthName;
  }

  getDisplayName(): string {
    return this.user.getDisplayName();
  }

  getInitials(): string {
    return this.user.getInitials();
  }

  isOver18(): boolean {
    if (!this.user.birthday) {
      return false;
    }

    const now = new Date();
    return moment(now).diff(moment(this.user.birthday), 'years', true) >= 18;
  }

  isPersonalDataFilled(): boolean {
    return (
      !!this.user.firstName &&
      !!this.user.lastName &&
      !!this.user.birthday &&
      !!this.placeOfBirth
    );
  }

  isJobFilled(): boolean {
    return !!this.job;
  }

  isNationalityFilled(): boolean {
    return !!this.nationality;
  }

  isMaritalStatusFilled(): boolean {
    return !!this.maritalStatus;
  }

  isAddressFilled(): boolean {
    return this.address.isFullyFilled();
  }

  isPhoneNumberFilled(): boolean {
    return !!this.user.phone;
  }

  isPhoneNumberFilledCorrectly(): boolean {
    return !!this.user.phone && /^\+?[0-9\- ]+$/.test(this.user.phone);
  }

  isSourceOfReferenceFilled(): boolean {
    if (!this.sourceOfReference) {
      return false;
    }
    return this.sourceOfReference === SourceOfReference.PERSONAL_RECOMMENDATION
      ? !!this.sourceOfReferencePerson
      : true;
  }

  hasExistingLicenseInfo(): boolean {
    return !!this.existingLicenseInfo;
  }

  hasTrainingInfo(): boolean {
    return !!this.trainingInfo;
  }

  hasPaymentPreference(): boolean {
    return !!this.paymentPreference;
  }

  isBillingAddressFilled(): boolean {
    return !!this.billingAddress && this.billingAddress.isFilled();
  }

  hasLicenses(): boolean {
    return this.licenses && this.licenses.length > 0;
  }

  hasMultipleLicenses(): boolean {
    return this.licenses?.length > 1;
  }

  hasUserWithAccount(): boolean {
    return !!this.user && !!this.user.id && this.user.hasAccount();
  }

  isEddyClubLearnerDisplayName(): string {
    if (this.isEddyClubLearner) {
      return 'Eddy Club';
    }

    return 'Eigene Fahrschule';
  }

  learnLicensesDisplayName(): string {
    if (!this.licenses || (this.licenses && this.licenses.length === 0)) {
      return '';
    }

    return this.licenses
      .map((learnerLicense) => learnerLicense.license.key)
      .join(', ');
  }

  learnLicensesDisplayNameWithText(): string {
    const licenseKeys = this.getLearnerLicensesKeys();
    if (!licenseKeys || (licenseKeys && licenseKeys.length === 0)) {
      return '';
    }

    if (licenseKeys.length === 1) {
      return `Klasse ${licenseKeys[0]}`;
    }

    const last = licenseKeys.pop();
    return `Klassen ${licenseKeys.join(', ')} und ${last}`;
  }

  existingLicensesDisplayName(): string {
    if (
      this.hasExistingLicenseInfo() &&
      this.existingLicenseInfo.existingLicenseKey.length > 0
    ) {
      return this.existingLicenseInfo.existingLicenseKey.join(', ');
    }

    return '';
  }

  getLearnerLicensesObjects(): License[] {
    if (!this.licenses || (this.licenses && this.licenses.length === 0)) {
      return [];
    }

    return this.licenses.map((learnerLicense) => learnerLicense.license);
  }

  getLearnerLicenses(separator = ', '): string {
    if (!this.licenses || (this.licenses && this.licenses.length === 0)) {
      return '';
    }

    return this.licenses
      .map((learnerLicense) => learnerLicense.license.key)
      .join(separator);
  }

  getLearnerLicensesKeys(): string[] {
    if (!this.licenses || (this.licenses && this.licenses.length === 0)) {
      return [];
    }

    return this.licenses.map((learnerLicense) => learnerLicense.license.key);
  }

  getLearnerLicenseByKey(key: string): LearnerLicense {
    if (!this.licenses || (this.licenses && this.licenses.length === 0)) {
      return null;
    }

    return this.licenses.find((learnerLicense) => learnerLicense.key === key);
  }

  getLearnerLicenseByLicenseKey(licenseKey: string): LearnerLicense {
    if (!this.licenses || (this.licenses && this.licenses.length === 0)) {
      return null;
    }

    return this.licenses.find(
      (learnerLicense) => learnerLicense.license.key === licenseKey
    );
  }

  getisLearnerLicenseOnlineTheoryActivated(licenseKey: string): boolean {
    if (!this.licenses || (this.licenses && this.licenses.length === 0)) {
      return false;
    }

    return this.licenses.find(
      (learnerLicense) => learnerLicense.license.key === licenseKey
    )?.isOnlineTheoryActivated;
  }

  get onlineLicensesKeys(): string[] {
    if (!this.licenses || (this.licenses && this.licenses.length === 0)) {
      return [];
    }

    return this.licenses
      .filter((learnerLicense) => learnerLicense.license.hasOnlineTheory)
      .map((learnerLicense) => learnerLicense.license.key);
  }

  hasOnlineTheoryActive(): boolean {
    if (!this.licenses || (this.licenses && this.licenses.length === 0)) {
      return false;
    }
    return this.licenses.some(
      (learnerLicense) => learnerLicense.isOnlineTheoryActivated
    );
  }

  hasTheoryVideosActive(): boolean {
    if (!this.licenses || (this.licenses && this.licenses.length === 0)) {
      return false;
    }
    return this.licenses.some(
      (learnerLicense) => learnerLicense.areTheoryVideosActivated
    );
  }

  getSourceOfReferenceText(): string {
    if (!this.sourceOfReference) {
      return '-';
    }

    if (
      this.sourceOfReference === SourceOfReference.PERSONAL_RECOMMENDATION &&
      this.sourceOfReferencePerson
    ) {
      return `${getSourceOfReferenceDisplayName(this.sourceOfReference)}, ${
        this.sourceOfReferencePerson
      }`;
    }

    return getSourceOfReferenceDisplayName(this.sourceOfReference);
  }

  get getMaritalStatusText(): string {
    if (!this.maritalStatus) {
      return '-';
    }
    return getMaritalStatusDisplayName(this.maritalStatus);
  }

  get getWorkStatusText(): string {
    if (!this.job) {
      return '-';
    }

    return getWorkStatusDisplayName(this.job);
  }

  addressText(addHTMLLineBreakBeforePostalCode?: boolean): string {
    if (!this.isAddressFilled()) {
      return '';
    }

    return this.address.toString(addHTMLLineBreakBeforePostalCode);
  }

  get paymentPreferenceText(): string {
    if (!this.paymentPreference) {
      return '-';
    }

    return getPaymentPreferenceDisplayName(this.paymentPreference);
  }

  get phoneNumber(): string {
    return this.user.phone;
  }

  get landline(): string {
    return this.user.landline;
  }

  get dateOfBirth(): Date | null {
    return this.user.birthday;
  }

  private numberOfCompletedSections(): number {
    let counter = 0;

    if (this.user && this.user.id) {
      counter++;
    }
    counter++; // always count sourceOfReference
    /*if (this.sourceOfReference) {
      counter++;
    }/** */
    if (this.placeOfBirth) {
      counter++;
    }
    if (this.nationality) {
      counter++;
    }
    if (this.job) {
      counter++;
    }
    // if (this.maritalStatus) {
    //   counter++;
    // }
    if (this.address) {
      counter++;
    }
    if (this.isOver18() && this.guardian) {
      counter++;
    }
    if (this.paymentPreference) {
      counter++;
    }

    return counter;
  }

  private numberOfTotalSections(): number {
    return this.isOver18() ? 7 : 6;
  }

  get profileCompletenessPercentage(): number {
    return (
      (this.numberOfCompletedSections() / this.numberOfTotalSections()) * 100
    );
  }

  get profileCompletenessPercentageRounded(): number {
    return Math.floor(this.profileCompletenessPercentage);
  }

  get hasLicenseBF17(): boolean {
    return !!this.licenses?.find((license) => license.license?.key === 'BF17');
  }

  hasLicense(licenseId: number): boolean {
    return !!this.licenses?.find((license) => license.licenseId === licenseId);
  }

  hasLicenseByKey(licenseKey: string): boolean {
    return !!this.licenses?.find(
      (license) => license.license?.key === licenseKey
    );
  }

  get hasPayablePaymentFlag(): boolean {
    if (this.licenses?.length === 0) {
      return false;
    }

    return this.licenses.some(
      (license) =>
        license.paymentFlag &&
        !LearnerPaymentFlag.isNoPaymentPaymentFlag(license.paymentFlag)
    );
  }

  get licensePaymentFlags(): string {
    if (this.licenses?.length === 0) {
      return '';
    }

    const paymentFlags = [];

    this.licenses.forEach((license) => {
      if (license.paymentFlag) {
        paymentFlags.push(
          `${LearnerPaymentFlag.getDisplayName(license.paymentFlag)} (${
            license.license.key
          })`
        );
      }
    });

    return paymentFlags.join(', ');
  }

  get paidLicensePaymentFlags(): string {
    if (this.licenses?.length === 0) {
      return '';
    }

    const paymentFlags = [];

    this.licenses.forEach((license) => {
      if (license.paymentFlag && license.basicFeePaid) {
        paymentFlags.push(
          `${LearnerPaymentFlag.getDisplayName(license.paymentFlag)} (${
            license.license.key
          })`
        );
      }
    });

    return paymentFlags.join(', ');
  }

  get allBasicFeesPaid(): boolean {
    if (this.licenses?.length === 0) {
      return true;
    }

    return this.licenses.every((license) => license.basicFeePaid);
  }

  get basicFeesPartiallyPaid(): boolean {
    if (this.allBasicFeesPaid) {
      return false;
    }
    if (this.licenses?.length === 0) {
      return true;
    }

    return this.licenses.some((license) => license.basicFeePaid);
  }

  get theoryPackageKey(): string {
    if (this.learnerTheoryPackage) {
      return `theory_package_${this.learnerTheoryPackage.key}`;
    }
    return `theory_package_${this.key}`;
  }

  getContractDate(): Date {
    const contractDocument = this.documents
      ?.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
      .find((doc) =>
        [
          LearnerDocumentType.CONTRACT,
          LearnerDocumentType.SCHOOL_CONTRACT,
        ].includes(doc.fileType)
      );

    return contractDocument?.createdAt;
  }

  hasSeen(type: LearnerSeenType): boolean {
    return this.seens && this.seens.find((seen) => seen.type === type)?.seen;
  }

  private mapLearnerDocuments(list: LearnerDocument[]) {
    return list.map((it) => new LearnerDocument(it));
  }

  private mapLearnerLicenses(licenses: LearnerLicense[]) {
    return licenses.map((it) => new LearnerLicense(it));
  }
}

export class LearnerLicense {
  id?: number;
  key?: string;

  learnerId?: number;
  licenseId: number;
  license?: License;

  isOnlineTheoryActivated: boolean;
  areAllTheoryLessonsBookable?: boolean;

  paymentFlag?: LearnerPaymentFlag;

  basicFeePaid?: boolean;

  isActive?: boolean;

  onlineTheoryActivatedDate?: Date;

  areTheoryVideosActivated: boolean;
  theoryVideosActivatedDate?: Date;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    if (json.id) {
      this.id = +json.id;
    }
    if (json.key) {
      this.key = json.key;
    }

    if (json.learnerId) {
      this.learnerId = +json.learnerId;
    }
    this.licenseId = +json.licenseId;
    if (json.license) {
      this.license = new License(json.license);
    }

    if (json.isOnlineTheoryActivated !== undefined) {
      this.isOnlineTheoryActivated = !!json.isOnlineTheoryActivated;
    }
    if (json.areAllTheoryLessonsBookable !== undefined) {
      this.areAllTheoryLessonsBookable = !!json.areAllTheoryLessonsBookable;
    }

    if (json.paymentFlag) {
      this.paymentFlag = json.paymentFlag;
    }

    if (json.basicFeePaid !== undefined) {
      this.basicFeePaid = !!json.basicFeePaid;
    }

    if (json.isActive !== undefined) {
      this.isActive = !!json.isActive;
    }

    if (json.onlineTheoryActivatedDate) {
      this.onlineTheoryActivatedDate = new Date(json.onlineTheoryActivatedDate);
    }

    if (json.areTheoryVideosActivated !== undefined) {
      this.areTheoryVideosActivated = !!json.areTheoryVideosActivated;
    }
    if (json.theoryVideosActivatedDate) {
      this.theoryVideosActivatedDate = new Date(json.theoryVideosActivatedDate);
    }
  }
}

export const automaticContractGenerationRolloutDate = new Date('2022-06-17');

export interface LearnerProfileUpdateChildrenSubjectData {
  documents?: boolean;
  theoryLessons?: boolean;
}
