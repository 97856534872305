<div class="panel panel-1">
  <div class="container xl-container panel-1-container">
    <div class="panel-1-left">
      <h4 class="section-title">Danke für deine<br> Anfrage!</h4>
      <p class="section-content">
        Wir freuen uns auf das persönliche Gespräch mit dir!
        <br><br>
        Dein Team von drivEddy & dem Eddy Club
			</p>
      <div class="partner-header">Offizielle Partner von drivEddy und dem Eddy Club</div>
      <div class="partner-logos">
        <img src="/assets/partners/johaniter-logo.png"/>
        <img src="/assets/partners/apollo-logo.png"/>
        <img src="/assets/partners/merzedes-logo.png"/>
      </div>
    </div>
  </div>
</div>
