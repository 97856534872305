<div class="rating card-panel {{additionalLabelTextPosition}}"
     [class.alternative-text-color]="alternativeTextColor"
     [class.alternative-stars-color]="alternativeStarsColor"
     [class.update-mode]="!readOnly"
     [class.space-between-stars]="spaceBetweenStars"
     [class.display-inline]="displayInline">
  <div class="stars-panel">
    <div class="stars-container">
      <span class="actual-stars-container">
        <ng-container *ngFor="let star of stars; let idx = index">
          <ng-container [ngSwitch]="star">
            <i *ngSwitchCase="1" class="fas fa-star"
               [style.font-size.px]="starSize"
               (click)="onStarClick(idx+1)"></i>
            <i *ngSwitchCase="0.5" class="fas fa-star-half"
               [style.font-size.px]="starSize"
               (click)="onStarClick(idx+1)"></i>
          </ng-container>
        </ng-container>
      </span>
      <span class="actual-stars-container empty-stars-container">
        <ng-container *ngFor="let star of stars; let idx = index">
          <i class="fa-star empty-star" [class.ghost]="star == 1 || star == 0.5"
             [class.fas]="!hollowStars" [class.fal]="hollowStars"
             [style.font-size.px]="starSize"
             (click)="onStarClick(idx+1)">
          </i>
        </ng-container>
      </span>
    </div>
    <div *ngIf="showRatingNumber" class="stars-number" [style.font-size.px]="starSize">{{ rating | number: '1.1' }}
    </div>
  </div>
  <div *ngIf="additionalLabelText" class="additional-label-rating-text" [class.lower]="starSize > 14">
    {{ additionalLabelText }}
  </div>
</div>
