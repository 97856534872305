export class PickupLocation {
  id: number;
  streetNumber: string;
  street: string;
  postalCode: string;
  locality: string;
  latitude: number;
  longitude: number;

  constructor(json: any) {
    if (!json) {
      this.id = 0;
      return;
    }
    this.id = +json.id;
    this.streetNumber = json.streetNumber;
    this.street = json.street;
    this.postalCode = json.postalCode;
    this.locality = json.locality;
    this.latitude = json.latitude;
    this.longitude = json.longitude;
  }

  getAddress() {
    return `${this.street} ${this.streetNumber}, ${this.postalCode} ${this.locality}`;
  }

  isValid(): boolean {
    return !(this.locality === '' || this.streetNumber === '' || this.postalCode === '' || this.street === '');
  }

  hasLatLng(): boolean {
    return !!this.latitude && !!this.longitude;
  }

  get latLng(): string {
    return `${this.latitude}, ${this.longitude}`;
  }
}
