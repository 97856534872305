import { Injectable } from '@angular/core';
import { Address } from '@models/address.model';
import { SpecialCharactersReplacementService } from './special-characters-replacement.service';
import { ParamMap } from '@angular/router';

@Injectable()
export class AddressBuilderService {

  constructor(private specialCharactersReplacementService: SpecialCharactersReplacementService) {
  }

  buildQueryParamsMap(address: Address) {
    const queryParams = {};
    if (address.street) {
      queryParams['street'] = address.street;
    }

    if (address.streetNumber) {
      queryParams['street_number'] = address.streetNumber;
    }

    if (address.postalCode) {
      queryParams['postal_code'] = address.postalCode;
    }

    if (address.lat) {
      queryParams['lat'] = address.lat;
    }

    if (address.lng) {
      queryParams['lng'] = address.lng;
    }

    return queryParams;
  }

  addAddressQueryData(baseUrl: string, address: Address) {
    const queryParams = this.buildQueryParamsMap(address);

    let sign = '?';
    for (const key of Object.keys(queryParams)) {
      if (!queryParams[key]) {
        continue;
      }

      baseUrl = `${baseUrl}${sign}${key}=${encodeURIComponent(this.specialCharactersReplacementService.replaceSpecialCharacters(queryParams[key]))}`;
      sign = '&';
    }

    return baseUrl;
  }


  fillAddressQueryStringData(address: Address, queryParamsMap: ParamMap) {
    const street = queryParamsMap.get('street');
    const streetNumber = queryParamsMap.get('street_number');
    const postalCode = queryParamsMap.get('postal_code');
    const lat = +queryParamsMap.get('lat');
    const lng = +queryParamsMap.get('lng');


    if (street) {
      address.street = street;
    }

    if (streetNumber) {
      address.streetNumber = streetNumber;
    }

    if (postalCode) {
      address.postalCode = postalCode;
    }

    if (lat && lng) {
      address.lat = lat;
      address.lng = lng;
    }
  }
}
