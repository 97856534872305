import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { catchError, map, tap } from 'rxjs/operators';
import { TeamMember } from '@models/team-member.model';
import { PageListingResponse } from '@models/page-listing-response.model';
import { InstructorDayProof } from '@models/instructor-day-proof.model';
import { saveAs as importedSaveAs } from 'file-saver';
import { DateUtils } from '@services/date-utils.service';
import { NotificationService } from '@services/notification.service';
import { MonthlyRevenue } from '@models/monthly-revenue.model';
import { InstructorRevenue } from '@models/instructor-revenue.model';
import { TeamMemberSeenType } from '@models/team-member-seen.model';

@Injectable()
export class TeamMemberService {
  private readonly apiPath = `${env.apiSchoolService}`;

  constructor(
    private api: ApiService,
    private notificationService: NotificationService
  ) {}

  getSchoolTeam(schoolId: number): Observable<TeamMember[]> {
    return this.api
      .get(`${this.apiPath}/schools/${schoolId}/team_members`)
      .pipe(map((teamMembers) => this.mapTeamMembers(teamMembers)));
  }

  saveTeamMember(teamMember: TeamMember): Observable<TeamMember> {
    return this.api
      .post<TeamMember>(
        `${this.apiPath}/schools/${teamMember.schoolId}/team_members`,
        teamMember
      )
      .pipe(map((it) => new TeamMember(it)));
  }

  getTeamMember(
    schoolId: number,
    teamMemberId: number
  ): Observable<TeamMember> {
    return this.api
      .get<TeamMember>(
        `${this.apiPath}/schools/${schoolId}/team_members/${teamMemberId}`
      )
      .pipe(map((it) => new TeamMember(it)));
  }

  private mapTeamMembers(teamMembers: TeamMember[]) {
    return teamMembers.map((it) => new TeamMember(it));
  }

  getSchoolTeamPaged(
    schoolId: number,
    filters: any,
    pageNumber: number,
    pageSize: number
  ) {
    return this.api
      .getPaged(
        `${this.apiPath}/schools/${schoolId}/team_members/paged`,
        pageNumber,
        pageSize,
        filters
      )
      .pipe(map(this.mapTeamMembersPaged));
  }

  private mapTeamMembersPaged(
    res: PageListingResponse
  ): PageListingResponse<TeamMember> {
    return {
      total: res.total,
      records: res.records.map((it) => new TeamMember(it)),
    };
  }

  deleteTeamMember(schoolId: number, teamMemberId: number): Observable<void> {
    return this.api.delete(
      `${this.apiPath}/schools/${schoolId}/team_members/${teamMemberId}`
    );
  }

  getEddyClubAndPartnerInstructors(schoolId: number): Observable<TeamMember[]> {
    return this.api
      .get(`${this.apiPath}/schools/${schoolId}/eddy_club_instructors`)
      .pipe(map((teamMembers) => this.mapTeamMembers(teamMembers)));
  }

  getInstructorDayProofs(
    filters: Map<string, any>
  ): Observable<InstructorDayProof[]> {
    return this.api
      .get(`${this.apiPath}/team_members/day_proofs`, filters)
      .pipe(map((dayProofs) => this.mapDayProofs(dayProofs)));
  }

  private mapDayProofs(dayProofs: InstructorDayProof[]): InstructorDayProof[] {
    return dayProofs.map((it) => new InstructorDayProof(it));
  }

  downloadDayProof(dayProof: InstructorDayProof): Observable<any> {
    const date = DateUtils.dateOnlyToString(dayProof.date);
    const params = new Map<string, string>();
    if (dayProof.includeLearnerId) {
      params.set('includeLearnerId', dayProof.includeLearnerId.toString());
    }
    return this.api
      .getBlob(
        `${this.apiPath}/team_members/${dayProof.instructorId}/day_proofs/${date}/content`,
        params
      )
      .pipe(
        tap((blob: Blob) => importedSaveAs(blob, `Tagesnachweis ${date}.pdf`)),
        catchError((err) => {
          this.notificationService.loadError();
          return of(err);
        })
      );
  }

  downloadDayProofsInBulk(dayProofs: InstructorDayProof[]): Observable<any> {
    return this.api
      .postBlob(
        `${this.apiPath}/team_members/day_proofs/bulk_export`,
        this.mapInstructorDayProofsForExport(dayProofs)
      )
      .pipe(
        tap((blob: Blob) => importedSaveAs(blob, `tagesnachweis.zip`)),
        catchError((err) => {
          this.notificationService.loadError();
          return of(err);
        })
      );
  }

  private mapInstructorDayProofsForExport(dayProofs: InstructorDayProof[]) {
    return dayProofs.map((dayProof) => {
      return {
        instructorId: dayProof.instructorId,
        date: DateUtils.dateOnlyToString(dayProof.date),
      };
    });
  }

  downloadInstructorBill(
    instructorId: number,
    month?: number,
    year?: number
  ): Observable<any> {
    const queryParams = new Map<string, string>();
    if (month && year) {
      queryParams.set('month', month.toString());
      queryParams.set('year', year.toString());
    }

    return this.api
      .getBlob(
        `${this.apiPath}/team_members/${instructorId}/bills`,
        queryParams
      )
      .pipe(
        tap((blob: Blob) =>
          importedSaveAs(blob, `${this.billName(month, year)}.pdf`)
        ),
        catchError((err) => {
          this.notificationService.loadError();
          return of(err);
        })
      );
  }

  private billName(month?: number, year?: number): string {
    if (month && year) {
      return `Abrechnung ${month}-${year}`;
    }

    return 'Alle Abrechnungen';
  }

  getInstructorMonthlyRevenues(
    instructorId: number
  ): Observable<MonthlyRevenue[]> {
    return this.api
      .get(`${this.apiPath}/team_members/${instructorId}/monthly_revenues`)
      .pipe(map((monthlyRevenues) => this.mapMonthlyRevenues(monthlyRevenues)));
  }

  getSchoolInstructorsMonthlyRevenues(
    schoolId: number
  ): Observable<MonthlyRevenue[]> {
    return this.api
      .get(`${this.apiPath}/schools/${schoolId}/instructors_monthly_revenues`)
      .pipe(map((monthlyRevenues) => this.mapMonthlyRevenues(monthlyRevenues)));
  }

  private mapMonthlyRevenues(monthlyRevenues: any): MonthlyRevenue[] {
    return monthlyRevenues.map((it) => new MonthlyRevenue(it));
  }

  getInstructorRevenue(
    instructorId: number,
    dateFrom: Date,
    dateTo: Date
  ): Observable<InstructorRevenue> {
    const params = new Map<string, any>();
    params.set('startDate', DateUtils.dateOnlyToString(dateFrom));
    params.set('endDate', DateUtils.dateOnlyToString(dateTo));
    return this.api
      .get(`${this.apiPath}/team_members/${instructorId}/revenue`, params)
      .pipe(map((it) => new InstructorRevenue(it)));
  }

  getSchoolInstructorsRevenue(
    schoolId: number,
    dateFrom: Date,
    dateTo: Date
  ): Observable<InstructorRevenue> {
    const params = new Map<string, any>();
    params.set('startDate', DateUtils.dateOnlyToString(dateFrom));
    params.set('endDate', DateUtils.dateOnlyToString(dateTo));
    return this.api
      .get(`${this.apiPath}/schools/${schoolId}/instructors_revenue`, params)
      .pipe(map((it) => new InstructorRevenue(it)));
  }

  getInstructorSignature(instructorId: number): Observable<Blob> {
    return this.api.getBlob(
      `${this.apiPath}/team_members/${instructorId}/signature`
    );
  }

  // seen
  markPriceAdjustmentPopupAsSeen(teamMemberId: number): Observable<any> {
    return this.api.put(
      `${this.apiPath}/team_members/${teamMemberId}/seen_price_adjustment_popup`,
      {}
    );
  }

  markSeen(teamMemberId: number, type: TeamMemberSeenType): Observable<void> {
    return this.api.post<void>(
      `${this.apiPath}/team_members/${teamMemberId}/mark_seen`,
      { type }
    );
  }
}
