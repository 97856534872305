import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { AppRoutes } from '../app.routes';
import { Injectable } from '@angular/core';

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  private includedUrls = [
    AppRoutes.Root.signUp,
    AppRoutes.Root.login,
    AppRoutes.Root.learnerTermsAndConditions,
    AppRoutes.Root.instructorTermsAndConditions,
    AppRoutes.Root.dataPrivacyApp,
    AppRoutes.Root.dataPrivacyWeb,
    AppRoutes.Root.imprint,
    AppRoutes.Root.eddyAcademy,
    AppRoutes.Root.thankYouForYourInterest,
    AppRoutes.Root.thankYouForYourRegistration,
  ];

  parse(url: string): UrlTree {
    if (!this.includedUrls.find(it => url === it)) {
      return super.parse(url);
    }
    return super.parse(url.toLowerCase());
  }
}
