import { QuestionStatus } from '@models/question-status.model';
import { QuestionChoice } from '@models/question-choice.model';
import { City } from '@models/city.model';
import { Asset, emptyAsset } from '@models/asset.model';
import { QuestionType } from '@models/question-type.model';

export class Question {
  id: number;

  orderNumber: number;
  name: string;
  status: QuestionStatus;
  imageUrl: string;

  type: QuestionType;

  choices: QuestionChoice[];

  ageFrom: number;
  ageTo: number;

  englishLanguage: boolean;
  germanLanguage: boolean;

  location: string;
  locationCities?: City[];

  males: boolean;
  females: boolean;

  isMandatory: boolean;

  timesSeen: number;
  timesAnswered: number;
  imageAsset: Asset;

  constructor(json) {
    this.id = 0;
    this.choices = [];

    if (!json) {
      return;
    }

    this.id = +json.id;

    this.orderNumber = +json.orderNumber;
    this.name = json.name;
    this.status = json.status;

    this.imageUrl = json.imageUrl;
    this.imageAsset = emptyAsset();
    this.imageAsset.path = json.imageUrl;

    this.type = json.type;
    this.choices = json.choices;

    this.ageFrom = +json.ageFrom;
    this.ageTo = +json.ageTo;

    this.englishLanguage = !!json.englishLanguage;
    this.germanLanguage = !!json.germanLanguage;

    this.males = !!json.males;
    this.females = !!json.females;

    this.location = json.location;
    this.locationCities = json.locationCities;

    this.isMandatory = !!json.isMandatory;

    this.timesSeen = +json.timesSeen;
    this.timesAnswered = +json.timesAnswered;
  }

  static emptyQuestion(): Question {
    return new Question(null);
  }

  responseRate(): number {
    if (!this.timesSeen) {
      return 0;
    }

    return Math.min(1, this.timesAnswered / this.timesSeen) * 100;
  }

  clone(): Question {
    const question = new Question(null);

    question.id = null;
    question.status = QuestionStatus.PAUSED;

    question.choices = this.choices.map(it => it);

    question.name = this.name;

    question.imageUrl = this.imageUrl;

    question.ageFrom = +this.ageFrom;
    question.ageTo = +this.ageTo;

    question.englishLanguage = !!this.englishLanguage;
    question.germanLanguage = !!this.germanLanguage;

    question.males = !!this.males;
    question.females = !!this.females;

    question.location = this.location;
    question.locationCities = this.locationCities;

    question.isMandatory = !!this.isMandatory;

    return question;
  }

  isSingleChoiceQuestion(): boolean {
    return this.type === QuestionType.SINGLE_CHOICE;
  }
}
