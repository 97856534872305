<div class="dialog-container signup-page">
  <div id="dialog-title-container" mat-dialog-title>
    <h3 class="question">
      Wähle deine Führerscheinlizenz
    </h3>
    <span (click)="onQuit()" class="dialog-close-button">
      <i class="fal fa-times"></i>
    </span>
  </div>
  <mat-dialog-content>
    <ng-container *ngIf="allALicenses.length > 0">
      <div class="separator">Klasse A</div>
      <div class="row no-side-space">
        <div class="col-12 no-side-space licenses-container">
          <div *ngFor="let license of allALicenses" class="radio-button">
            <mat-checkbox [value]="license" (change)="onSelectedLicenseChange($event)" [checked]="isChecked(license.key) || isIncludedInSelected(license.key)" [disabled]="isIncludedInSelected(license.key)">Klasse {{ license.key }}</mat-checkbox>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="allBLicenses.length > 0">
      <div class="separator">Klasse B</div>
      <div class="row no-side-space">
        <div class="col-12 no-side-space licenses-container">
          <div *ngFor="let license of allBLicenses" class="radio-button">
            <mat-checkbox [value]="license" (change)="onSelectedLicenseChange($event)" [checked]="isChecked(license.key) || isIncludedInSelected(license.key)" [disabled]="isIncludedInSelected(license.key)">Klasse {{ license.key }}</mat-checkbox>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="allCLicenses.length > 0">
      <div class="separator">Klasse C</div>
      <div class="row no-side-space">
        <div class="col-12 no-side-space licenses-container">
          <div *ngFor="let license of allCLicenses" class="radio-button">
            <mat-checkbox [value]="license" (change)="onSelectedLicenseChange($event)" [checked]="isChecked(license.key) || isIncludedInSelected(license.key)" [disabled]="isIncludedInSelected(license.key)">Klasse {{ license.key }}</mat-checkbox>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="allDLicenses.length > 0">
      <div class="separator">Klasse D</div>
      <div class="row no-side-space">
        <div class="col-12 no-side-space licenses-container">
          <div *ngFor="let license of allDLicenses" class="radio-button">
            <mat-checkbox [value]="license" (change)="onSelectedLicenseChange($event)" [checked]="isChecked(license.key) || isIncludedInSelected(license.key)" [disabled]="isIncludedInSelected(license.key)">Klasse {{ license.key }}</mat-checkbox>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="allLTLicenses.length > 0">
      <div class="separator">Klasse L & T</div>
      <div class="row no-side-space">
        <div class="col-12 no-side-space licenses-container">
          <div *ngFor="let license of allLTLicenses" class="radio-button">
            <mat-checkbox [value]="license" (change)="onSelectedLicenseChange($event)" [checked]="isChecked(license.key) || isIncludedInSelected(license.key)" [disabled]="isIncludedInSelected(license.key)">Klasse {{ license.key }}</mat-checkbox>
          </div>
        </div>
      </div>
    </ng-container>

  </mat-dialog-content>

  <div class="spacer"></div>

  <mat-dialog-actions class="dialog-actions">
    <button type="submit"
            class="primary-btn"
            (click)="onSubmit()">
      Hinzufügen
    </button>
  </mat-dialog-actions>
</div>
