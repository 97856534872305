<div class="dropdown" [class.dark]="theme === 'dark'">
  <div class="header-container">
    <button class="drop-btn nav-link">{{data.headerText}}</button>
    <i *ngIf="theme === 'dark'" class="fas fa-sort-down dropdown-icon"></i>
  </div>
  <div class="dropdown-content">
    <ng-container *ngFor="let item of data.items">
      <span [class]="item.fontColor">
        <a *ngIf="item.url" [routerLink]="item.url">{{item.label}}</a>
        <a *ngIf="item.event" (click)="emitEvent(item.event)" href="javascript:void(0)">{{item.label}}</a>
        <a *ngIf="!item.url && !item.event">{{item.label}}</a>
        <hr *ngIf="item.separator"/>
      </span>
    </ng-container>
  </div>
</div>
