/// <reference types="@types/googlemaps" />
import { catchError, map } from 'rxjs/operators';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { asapScheduler, Observable, Observer, scheduled, throwError } from 'rxjs';
import { Address } from '@models/address.model';
import { environment as env } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { isPlatformServer } from '@angular/common';

class GoogleAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

@Injectable()
export class LocationService {
  geocoder: any; // : google.maps.Geocoder;

  private readonly isServer: boolean;

  constructor(private http: HttpClient,
              private authService: AuthService,
              @Inject(PLATFORM_ID) platformId: any) {
    this.isServer = isPlatformServer(platformId);
    if (!this.isServer) {
      this.geocoder = new google.maps.Geocoder();
    }
  }

  codeAddress(address: string): Observable<any[]> {
    if (this.isServer) {
      return scheduled([], asapScheduler);
    }

    return new Observable((observer: Observer<google.maps.GeocoderResult[]>) => {
      // Invokes geocode method of Google Maps API geocoding.
      this.geocoder.geocode({address: address}, (
        (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
          if (status === google.maps.GeocoderStatus.OK) {
            observer.next(results);
            observer.complete();
          } else {
            observer.error(status);
          }
        })
      );
    });
  }

  getAddressById(_id: number) {

    return this.http.get<Address>(`${env.api}/address/${_id}`, this.authService.getHttpOptions())
      .pipe(
        map(
          (_response: any) => {
            return _response['data'];
          }),
        catchError(
          (_error: any) => {
            return throwError(_error);
          })
      );
  }

  updateAddressCoordinates(_address: Address): Promise<Address> {
    const query = _address.street + ', ' +
      _address.streetNumber + ' ' +
      _address.postalCode + ', ' +
      _address.locality + ', Deutschland';

    return new Promise(done => {
      this.codeAddress(query).subscribe(_googleAddress => {

          if (_googleAddress.length > 0) {
            const tempAddress = this.getAddressFromGoogleGeocodeResult(_googleAddress[0]);
            _address.lat = tempAddress.lat;
            _address.lng = tempAddress.lng;

            _address.country = tempAddress.country;
            _address.countryCode = tempAddress.countryCode;
            _address.cityCode = tempAddress.cityCode;
            _address.administrativeAreaLevel1 = tempAddress.administrativeAreaLevel1;

          }
          done(_address);
        },
        _fail => {
        }
      );
    });
  }

  getAddressFromGoogleGeocodeResult(_googleAddress: any): Address {

    const address = new Address();
    address.street = this.getValue(_googleAddress.address_components, 'route').long_name;
    address.streetNumber = this.getValue(_googleAddress.address_components, 'street_number').long_name;

    address.countryCode = this.getValue(_googleAddress.address_components, 'country').short_name;
    address.country = this.getValue(_googleAddress.address_components, 'country').long_name;

    address.locality = this.getValue(_googleAddress.address_components, 'locality').long_name;

    address.cityCode = this.getValue(_googleAddress.address_components, 'locality').short_name;

    address.administrativeAreaLevel1 = this.getValue(_googleAddress.address_components, 'administrative_area_level_1').short_name;

    address.postalCode = this.getValue(_googleAddress.address_components, 'postal_code').long_name;

    if (typeof _googleAddress.geometry.location.lat === 'function') {
      address.lat = _googleAddress.geometry.location.lat();
      address.lng = _googleAddress.geometry.location.lng();
    } else {
      address.lat = _googleAddress.geometry.location.lat;
      address.lng = _googleAddress.geometry.location.lng;
    }

    return address;
  }

  private getValue(_addressComponents: any[], _key: string): GoogleAddressComponent {
    for (let index = 0; index < _addressComponents.length; index++) {
      if (_addressComponents[index].types.indexOf(_key) >= 0) {
        return _addressComponents[index];
      }
    }

    return new GoogleAddressComponent();
  }
}
