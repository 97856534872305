import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavbarDropdownData } from '@models/navbar-dropdown-data.model';

@Component({
  selector: 'eddy-navbar-dropdown',
  templateUrl: './navbar-dropdown.component.html',
  styleUrls: ['./navbar-dropdown.component.scss']
})
export class NavbarDropdownComponent {
  @Input() data: NavbarDropdownData;

  @Input() theme: 'light' | 'dark' = 'light';

  @Output() event: EventEmitter<string> = new EventEmitter<string>();

  emitEvent(eventID: string) {
    this.event.emit(eventID);
  }
}
