import { Learner, LearnerLicense } from './learner.model';
import {School, TheoryLessonSwitchDate} from '@models/school.model';
import { TeamMember } from '@models/team-member.model';
import { SchoolLearnerStatus } from './school-learner-status.model';
import { SchoolLearnerOnboardingRequestChannel } from './school-learner-onboarding-request-channel.model';
import { LearnerPaymentFlag } from './learner-payment-flag.model';
import { PickupLocation } from './location.model';
import { LicensePriceList, PricingsStatus } from './license.model';
import { DateUtils } from '@services/date-utils.service';
import { PostalCodeInfo } from './postal-code-info.model';

export class SchoolLearner {
  id?: number;
  createdAt: Date;
  updatedAt: Date;

  schoolId: number;
  school?: School;

  instructorId?: number;
  instructor?: TeamMember;

  code: string;

  learnerId?: number;
  learner?: Learner;

  newLearnerEmail: string;
  targetLicenseId?: number;
  targetLicenseKey?: string;
  activateTargetLicenseOnlineTheory?: boolean;
  activateTargetLicenseOnlineTheoryDate?: Date;
  targetLicenseAllTheoryLessonsBookable?: boolean;

  activateTheoryLearning?: boolean;
  activateTargetLicenseTheoryVideos?: boolean;

  startDate: Date;
  endDate?: Date;

  status: SchoolLearnerStatus;
  onboardingRequestChannel?: SchoolLearnerOnboardingRequestChannel;

  preferredPickupLocationId?: number;
  preferredPickupLocation?: PickupLocation;

  paymentFlag?: LearnerPaymentFlag;
  packagePaid?: boolean;

  isEddyClubLearner: boolean;
  isOnlineTheoryLearner: boolean;
  usesHarmonization: boolean;
  isFastlaneLearner: boolean;

  canBookPracticeLessons: boolean;
  usesDynamicPricing: boolean;

  isTestAccount: boolean;

  schoolLicensePriceListId?: number;
  licensePriceList?: LicensePriceList;
  pendingLicensePriceList?: LicensePriceList;

  shouldSignSchoolContract: boolean;

  confirmedTheoryLessons?: number;
  totalTheoryLessons?: number;
  totalAvailableTheoryLessons?: number;

  licensePriceListName?: string;

  postalCodeInfo?: PostalCodeInfo;

  constructor(json?: any) {
    if (!json) {
      return;
    }
    this.id = +json.id;
    this.createdAt = new Date(json.createdAt);
    this.updatedAt = new Date(json.updatedAt);

    this.schoolId = +json.schoolId;
    if (json.school) {
      this.school = new School(json.school);
    }

    if (json.instructorId) {
      this.instructorId = +json.instructorId;
    }
    if (json.instructor) {
      this.instructor = new TeamMember(json.instructor);
    }

    this.code = json.code;

    if (+json.learnerId) {
      this.learnerId = +json.learnerId;
    }
    this.learner = new Learner(json.learner);

    this.newLearnerEmail = json.newLearnerEmail;
    if (+json.targetLicenseId) {
      this.targetLicenseId = +json.targetLicenseId;
      this.targetLicenseKey = json.targetLicenseKey;
      this.targetLicenseAllTheoryLessonsBookable =
        !!json.targetLicenseAllTheoryLessonsBookable;
    }

    if (json.activateTargetLicenseOnlineTheory !== undefined) {
      this.activateTargetLicenseOnlineTheory =
        !!json.activateTargetLicenseOnlineTheory;
    }

    if (json.activateTargetLicenseOnlineTheoryDate) {
      this.activateTargetLicenseOnlineTheoryDate = new Date(
        json.activateTargetLicenseOnlineTheoryDate
      );
    }

    if (json.activateTheoryLearning !== undefined) {
      this.activateTheoryLearning = !!json.activateTheoryLearning;
    }

    if (json.activateTargetLicenseTheoryVideos !== undefined) {
      this.activateTargetLicenseTheoryVideos =
        !!json.activateTargetLicenseTheoryVideos;
    }

    this.startDate = new Date(json.startDate);
    this.endDate = undefined;
    if (json.endDate) {
      this.endDate = new Date(json.endDate);
    }

    this.status = json.status;
    this.onboardingRequestChannel = json.onboardingRequestChannel;

    if (+json.preferredPickupLocationId) {
      this.preferredPickupLocationId = +json.preferredPickupLocationId;
    }
    if (json.preferredPickupLocation) {
      this.preferredPickupLocation = new PickupLocation(
        json.preferredPickupLocation
      );
    }

    if (json.paymentFlag) {
      this.paymentFlag = json.paymentFlag;
    }
    if (json.packagePaid) {
      this.packagePaid = !!json.packagePaid;
    }

    this.isEddyClubLearner = !!json.isEddyClubLearner;
    this.isOnlineTheoryLearner = !!json.isOnlineTheoryLearner;
    this.usesHarmonization = !!json.usesHarmonization;
    this.isFastlaneLearner = !!json.isFastlaneLearner;

    this.canBookPracticeLessons = !!json.canBookPracticeLessons;
    this.usesDynamicPricing = !!json.usesDynamicPricing;

    this.isTestAccount = !!json.isTestAccount;

    if (json.schoolLicensePriceListId) {
      this.schoolLicensePriceListId = +json.schoolLicensePriceListId;
    }
    if (json.licensePriceList) {
      this.licensePriceList = new LicensePriceList(json.licensePriceList);
    }
    if (json.pendingLicensePriceList) {
      this.pendingLicensePriceList = new LicensePriceList(
        json.pendingLicensePriceList
      );
    }

    this.shouldSignSchoolContract = !!json.shouldSignSchoolContract;

    if (+json.confirmedTheoryLessons) {
      this.confirmedTheoryLessons = +json.confirmedTheoryLessons;
    }
    if (json.totalTheoryLessons !== undefined) {
      this.totalTheoryLessons = +json.totalTheoryLessons;
    }
    if (json.totalAvailableTheoryLessons !== undefined) {
      this.totalAvailableTheoryLessons = +json.totalAvailableTheoryLessons;
    }

    if (json.licensePriceListName) {
      this.licensePriceListName = json.licensePriceListName;
    }

    if (json.postalCodeInfo) {
      this.postalCodeInfo = json.postalCodeInfo;
    }
  }

  isPartnerOrContractOrVIPSchoolOwnLearner(): boolean {
    return (
      !!(
        this.school?.isPartner() ||
        this.school?.isContractSchool() ||
        this.school?.isVIPSchool()
      ) && !this.isEddyClubLearner
    );
  }

  isPartnerLearner(): boolean {
    return !!this.school?.isPartner();
  }

  isPartnerOwnLearner(): boolean {
    return !!this.school?.isPartner() && !this.isEddyClubLearner;
  }

  get schoolUsesDigitalManagementSoftware(): boolean {
    return this.school?.usesManagementSoftware ?? false;
  }

  isPartnerLearnerWithOnlineTheoryActive(): boolean {
    return !!this.school?.isPartner() && this.learner?.hasOnlineTheoryActive();
  }

  isPartnerSchoolEddyClubLearner(): boolean {
    return !!(this.school?.isPartner() && this.isEddyClubLearner);
  }

  isEddyClubSchoolLearner(): boolean {
    return !!(this.school?.isEddyClub() && this.isEddyClubLearner);
  }

  isExistingLearner(): boolean {
    return !!this.learner?.id;
  }

  isExistingLearnerWithAccount(): boolean {
    return this.isExistingLearner() && !!this.learner?.hasUserWithAccount();
  }

  isLearnerOver18(): boolean {
    return !!this.learner?.isOver18();
  }

  isEddyClubLearnerDisplayName(): string {
    // if the learner is created, load the 'is eddy club learner' status from there
    // if not, load it from the school learner entity
    if (this.learner?.id) {
      return this.learner.isEddyClubLearnerDisplayName();
    }

    return this._isEddyClubLearnerDisplayName();
  }

  private _isEddyClubLearnerDisplayName(): string {
    if (this.isEddyClubLearner) {
      return 'Eddy Club';
    }

    return 'Eigene Fahrschule';
  }

  schoolDisplayName(): string {
    if (!this.school) {
      return '';
    }

    if (this.school.isEddyBildungSchool() && this.startDate && this.startDate > TheoryLessonSwitchDate) {
      return 'Fahrschule Jäger';
    }

    return this.school.name;
  }

  learnLicensesDisplayName(): string | undefined {
    return this.learner?.learnLicensesDisplayName();
  }

  hasOnlineTheoryActive(): boolean {
    return (
      this.learner?.hasOnlineTheoryActive() &&
      this.school?.isOnlineTheoryAvailable
    );
  }

  hasTheoryVideosActive(): boolean {
    return this.learner?.hasTheoryVideosActive();
  }

  isOnlineTheoryActivationPending(): boolean {
    if (!this.activateTargetLicenseOnlineTheoryDate) {
      return false;
    }

    return (
      DateUtils.startOfDate(this.activateTargetLicenseOnlineTheoryDate) >
      new Date()
    );
  }

  isIncompleteProfile(): boolean {
    return this.status === SchoolLearnerStatus.INCOMPLETE_PROFILE;
  }

  onboardingStatus(): string {
    if (this.status === SchoolLearnerStatus.ONBOARDING_REQUESTED) {
      return 'Offen';
    }

    return 'Hinzugefügt';
  }

  getOnboardingStatusClass(): string {
    if (this.status === SchoolLearnerStatus.ONBOARDING_REQUESTED) {
      return 'requested-status';
    }

    return 'onboarded-status';
  }

  canSelectInstructorDuringOnboarding(): boolean {
    return !!this.school?.isEddyClub() && this.isEddyClubLearner;
  }

  hasSchoolAndInstructor(): boolean {
    return (
      (!!this.school || !!this.schoolId) &&
      (!!this.instructor || !!this.instructorId)
    );
  }

  getOnboardingRequestChannelDisplayName(): string {
    return SchoolLearnerOnboardingRequestChannel.getDisplayName(
      this.onboardingRequestChannel
    );
  }

  hasBookableTheoryLessonsLimit() {
    if (!this.totalTheoryLessons || !this.totalAvailableTheoryLessons) {
      return false;
    }
    return this.totalTheoryLessons < this.totalAvailableTheoryLessons;
  }

  hasPendingLicensePriceListChanges(): boolean {
    return (
      !!this.pendingLicensePriceList ||
      this.licensePriceList?.hasPendingOrRejectedPricings()
    );
  }

  arePriceListChangesPending(): boolean {
    return (
      (this.pendingLicensePriceList &&
        this.pendingLicensePriceList.isAccepted === undefined) ||
      this.licensePriceList?.pricingsStatus === PricingsStatus.PENDING
    );
  }

  get pendingLicensePriceListId(): number {
    if (this.licensePriceList?.hasPendingOrRejectedPricings()) {
      return this.licensePriceList.id;
    } else {
      return this.pendingLicensePriceList?.id;
    }
  }

  get pendingLicensePriceListName(): string {
    if (this.licensePriceList?.hasPendingOrRejectedPricings()) {
      return this.licensePriceList.name;
    } else {
      return this.pendingLicensePriceList?.name;
    }
  }

  get isWaitingForContractSigning(): boolean {
    return this.status === SchoolLearnerStatus.WAITING_FOR_CONTRACT_SIGNING;
  }

  get showCompleteProfile(): boolean {
    return this.learner?.profileCompletenessPercentageRounded < 100;
  }

  get showSignContract(): boolean {
    if (this.learner?.profileCompletenessPercentageRounded < 100) return false;
    if (!this.isWaitingForContractSigning) return false;

    return true;
  }

  get isTheoryLearningActive(): boolean {
    if (!this.learner) {
      return false;
    }
    return this.learner.isTheoryLearningActive;
  }

  get hasRejectedLicensePriceListChanges(): boolean {
    if (!this.pendingLicensePriceList && !this.licensePriceList) {
      return false;
    }
    return (
      this.pendingLicensePriceList?.isAccepted === false ||
      this.licensePriceList?.pricingsStatus === PricingsStatus.REJECTED
    );
  }

  get onboardingStatsDate(): Date {
    if (
      this.status !== SchoolLearnerStatus.ONBOARDING_REQUESTED &&
      this.status !== SchoolLearnerStatus.INCOMPLETE_PROFILE
    ) {
      return this.startDate;
    }

    return this.createdAt;
  }
}

export interface SaveSchoolExistingLearner {
  schoolId: number;
  instructorId: number;
  learnerId: number;
  isEddyClubLearner: boolean;
  usesHarmonization: boolean;
  isFastlaneLearner: boolean;
}

export interface SaveSchoolNewLearner {
  schoolId: number;
  instructorId: number;
  learnerEmail: string;
  isEddyClubLearner: boolean;
  usesHarmonization: boolean;
  targetLicenseId: number;
  activateTargetLicenseOnlineTheory: boolean;
  activateTargetLicenseOnlineTheoryDate?: Date;
  targetLicenseAllTheoryLessonsBookable: boolean;
  paymentFlag?: LearnerPaymentFlag;
  isFastlaneLearner: boolean;
  activateTheoryLearning: boolean;
  activateTargetLicenseTheoryVideos: boolean;
  canBookPracticeLessons: boolean;
  usesDynamicPricing: boolean;
  schoolLicensePriceListId?: number;
}

export interface AcceptOnboardingRequest {
  instructorId: number;
  targetLicenseId?: number;
  activateTargetLicenseOnlineTheory?: boolean;
  activateTargetLicenseOnlineTheoryDate?: Date;
  targetLicenseAllTheoryLessonsBookable?: boolean;
  paymentFlag?: LearnerPaymentFlag;
  activateTheoryLearning?: boolean;
  activateTargetLicenseTheoryVideos?: boolean;
  canBookPracticeLessons?: boolean;
  usesDynamicPricing?: boolean;
  schoolLicensePriceListId?: number;
}
