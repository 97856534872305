import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  getSpecialPracticeLessonTopics,
  PracticeLessonTopic,
  getDisplayName,
} from '@models/practice-lesson-topic.model';

@Component({
  selector: 'eddy-practice-lesson-topic-dropdown',
  templateUrl: 'eddy-practice-lesson-topic-dropdown.component.html',
  styleUrls: ['./eddy-practice-lesson-topic-dropdown.component.scss'],
})
export class EddyPracticeLessonTopicDropdownComponent {
  practiceLessonTopics: PracticeLessonTopic[] =
    getSpecialPracticeLessonTopics();

  @Input() topic: PracticeLessonTopic;

  @Input() fullWidth: boolean;

  @Input() roundedCorners: boolean;

  @Input() placeholder = 'Kategorie';

  @Input() class: string;

  @Output() topicChanged: EventEmitter<PracticeLessonTopic> =
    new EventEmitter<PracticeLessonTopic>();

  onChange(topic: PracticeLessonTopic) {
    this.topicChanged.emit(topic);
  }

  getTopicDisplayName(topic: PracticeLessonTopic) {
    return getDisplayName(topic);
  }
}
