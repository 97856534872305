import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '@services/auth.service';
import { SidenavService } from '@services/sidenav.service';
import { isLearner, isTeamMemberUserRole, USER_ROLE } from '@models/user.model';
import { ScrollTopService } from '@services/scroll-to-top.service';
import { GlobalCommunicationService } from '@services/global-communication.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CalendlyService } from '@services/calendly.service';
import {
  INSTRUCTOR_ACCOUNTING_ROUTE,
  INSTRUCTOR_BASIC_ROUTES,
  INSTRUCTOR_EDDY_CLUB_ROUTES,
  INSTRUCTOR_ONLINE_THEORY_BOOKINGS_ROUTE,
  INSTRUCTOR_PARTNER_ROUTES,
  INSTRUCTOR_SCHEDULING_LESSONS_ROUTES,
  INSTRUCTOR_SCHEDULING_LESSONS_ROUTES_WITH_ACCOUNTING,
  NAV_ROUTES_INTERNAL,
  NAV_ROUTES_PUBLIC,
  NAV_ROUTES_PUBLIC_SCHOOL,
  NAV_ROUTES_PUBLIC_EXTERNAL,
  publicRoutes,
  INSTRUCTOR_DAYPROOFS_ROUTE,
  INSTRUCTOR_PRACTICE_BOOKINGS_ROUTE,
  INSTRUCTOR_REPORTS_ROUTE,
  INSTRUCTOR_THEORY_DASHBOARD_ROUTE,
  INSTRUCTOR_CALENDAR_ROUTE,
  INSTRUCTOR_LEARNER_ROUTE,
  NAV_ROUTES_LEARNER_BASIC,
  LEARNER_ONLINE_THEORY_ROUTE,
  LEARNER_THEORY_LEARNING_ROUTE,
  NAV_ROUTES_LEARNER_BASIC_LAST,
  INSTRUCTOR_BASE_DATA_ROUTE,
} from './app.menu';
import { environment as env } from '@environments/environment';
import { isPlatformServer, Location } from '@angular/common';
import { MenuRoute } from '@models/menu-route.model';
import { ScreenWidthService } from '@services/screen-width.service';
import { DateAdapter } from '@angular/material/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { UserInfo } from '@models/user-info.model';
import { AccountService } from '@services/account.service';
import { School } from '@models/school.model';
import { AppRoutes } from './app.routes';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { MetaService } from '@services/meta.service';
import { OnlineTimeLogService } from '@services/online-time-log.service';
import { SchoolLearnerService } from '@services/school-learner.service';
import { Learner } from '@models/learner.model';

@Component({
  selector: 'eddy-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  readonly coronavirusInstructorMoreInfoLink =
    'https://fahrschule-eddy.de/home/services/krisenmanagement/';
  private readonly headerHeightInPixels = 92;

  @ViewChild('sidenav') public sidenav: MatSidenav;

  externalNavRoutes = [];
  navRoutes = [];
  containerClass$: Observable<string>;
  showFooter = true;

  floatingSupportOpened = false;
  private readonly isServer: boolean;

  isSchoolEmployeeOrLearner = false;
  isAdmin = false;
  internalRoutes: MenuRoute[] = [];

  isPublicRoute = true;
  isDesktop: boolean;
  narrowMode: boolean;

  private user: UserInfo;
  private school: School;
  private learner: Learner;

  isPublicMenu = true;
  isSignUpPage = false;
  isAcceptLessonPage = false;
  isRejectLessonPage = false;
  isAcceptOnboardingRequestPage = false;
  isDownloadLicenseApplicationPage = false;
  isTheoryQuestionPage = false;
  isLoginPage = false;
  isSetPasswordPage = false;
  isThankYouPage = false;
  isPurchaseThankYouPage = false;
  isLandingPage = false;
  isOnlineTheoryLicenseBPage = false;
  isLeadFormPage = false;
  isAcceptTermsAndConditionsPage = false;
  isWelcomePage = false;

  isPageWithoutGenericFooter = false;

  coronavirusInfoBannerClosed: boolean;
  private currentUrl: string;

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private sidenavService: SidenavService,
    private scrollToTopService: ScrollTopService,
    private globalCommunicationService: GlobalCommunicationService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private calendlyService: CalendlyService,
    private screenWidthService: ScreenWidthService,
    @Inject(PLATFORM_ID) platformId,
    private dateAdapter: DateAdapter<any>,
    private config: NgSelectConfig,
    private scrollToService: ScrollToService,
    private metaService: MetaService,
    private schoolLearnerService: SchoolLearnerService,
    private onlineTimeLogService: OnlineTimeLogService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.trackIsDesktopChange();

    this.dateAdapter.setLocale('de-de');

    this.isServer = isPlatformServer(platformId);

    // subscribe to user object
    this.authService.userSubject.subscribe((user) => {
      this.user = user;
      this.isSchoolEmployeeOrLearner =
        isTeamMemberUserRole(user.role) || isLearner(user.role);
      this.isAdmin = user.role === USER_ROLE.PLATFORM;
      this.refreshSidenavOpenStatus();
      this.initializeNavLinks();
    });

    this.accountService.getCurrentLearner().subscribe((learner) => {
      this.learner = learner;
      this.initializeNavLinks();
    });

    this.accountService.getSelectedSchool().subscribe((school) => {
      this.school = school;
      this.initializeNavLinks();
    });

    this.onlineTimeLogService.schoolLearnerService = this.schoolLearnerService;

    if (!this.isServer) {
      this.accountService.getCurrentLearner().subscribe((learner) => {
        this.onlineTimeLogService.learnerId = learner.id;
      });
    }

    this.containerClass$ =
      this.globalCommunicationService.containerClassChanges();
    this.trackRouteChanges();

    this.config.notFoundText = 'nicht gefunden';

    // if the cookies banner is shown, the coronavirus banner should be hidden
    this.coronavirusInfoBannerClosed = !this.authService.areCookiesAllowed();
  }

  private trackIsDesktopChange() {
    this.screenWidthService.isDesktop().subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
      this.refreshSidenavOpenStatus();
    });
  }

  onNarrowModeChange(narrowMode: boolean) {
    this.narrowMode = narrowMode;
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  toggleSupportMenu(e) {
    this.floatingSupportOpened = !this.floatingSupportOpened;

    e.preventDefault();
    return false;
  }

  onAsk() {
    this.calendlyService.bookAppointment();
  }

  get isPageWithoutSideItems(): boolean {
    return (
      this.isSignUpPage ||
      this.isAcceptLessonPage ||
      this.isRejectLessonPage ||
      this.isAcceptOnboardingRequestPage ||
      this.isDownloadLicenseApplicationPage ||
      this.isLeadFormPage ||
      this.isAcceptTermsAndConditionsPage ||
      this.isWelcomePage ||
      this.isPurchaseThankYouPage
    );
  }

  get isPageWithoutHeader(): boolean {
    return this.isOnlineTheoryLicenseBPage;
  }

  get isSchoolMenu(): boolean {
    if (this.router.url.startsWith(`/${AppRoutes.SchoolsInfo.root}`)) {
      return true;
    }
    return false;
  }

  getPublicNavRoutes() {
    if (this.isSchoolMenu) {
      return NAV_ROUTES_PUBLIC_SCHOOL;
    } else {
      return NAV_ROUTES_PUBLIC;
    }
  }

  private trackRouteChanges() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.isPublicMenu) {
          this.navRoutes = this.getPublicNavRoutes();
        }

        if (!event.url.startsWith('/fahrschulen')) {
          this.globalCommunicationService.setContainerClass('');
        }

        this.isSignUpPage = event.url.startsWith(`/${AppRoutes.Root.signUp}`);
        this.isAcceptLessonPage = event.url.startsWith(
          `/${AppRoutes.Root.acceptLessons}`
        );
        this.isRejectLessonPage = event.url.startsWith(
          `/${AppRoutes.Root.rejectLessons}`
        );
        this.isAcceptOnboardingRequestPage = event.url.startsWith(
          `/${AppRoutes.Root.acceptOnboardingRequest}`
        );
        this.isDownloadLicenseApplicationPage = event.url.startsWith(
          `/${AppRoutes.Root.downloadLicenseApplication}`
        );
        this.isTheoryQuestionPage = event.url.startsWith(
          `/${AppRoutes.Root.theoryTopicQuestion}`
        );
        this.isLoginPage = event.url.startsWith(`/${AppRoutes.Root.login}`);
        this.isSetPasswordPage = event.url.startsWith(
          `/${AppRoutes.Root.user}/password/reset`
        );
        this.isThankYouPage =
          event.url.startsWith(`/${AppRoutes.Root.thankYouForYourInterest}`) ||
          event.url.startsWith(
            `/${AppRoutes.Root.thankYouForYourRegistration}`
          );
        this.isPurchaseThankYouPage = event.url.startsWith(
          `/${AppRoutes.Root.purchaseThankYou}`
        );
        this.isLandingPage = event.url === '/';
        this.isOnlineTheoryLicenseBPage = event.url.startsWith(
          `/${AppRoutes.Root.onlineTheoryLicenseB}`
        );
        this.isLeadFormPage = event.url.startsWith(
          `/${AppRoutes.Root.leadForm}`
        );
        this.isAcceptTermsAndConditionsPage = event.url.startsWith(
          `/${AppRoutes.Root.acceptTermsAndConditions}`
        );
        this.isWelcomePage = event.url.startsWith(
          `/${AppRoutes.LearnersInternal.root}/${AppRoutes.EddyAcademyLearner.welcome}`
        );
        this.isPageWithoutGenericFooter =
          event.url ===
          `/${AppRoutes.EddyAcademyLearner.root}/${AppRoutes.EddyAcademyLearner.dashboardPage}`;
        this.changeDetectorRef.detectChanges();
        this.sidenavService.setSidenav(this.sidenav);
        this.refreshSidenavOpenStatus();

        // reset the view to the top on each route change
        if (window.scrollTo) {
          window.scrollTo(0, 0);
        }

        // hide the footer on the calendar page
        this.showFooter = event.url !== '/calendar';

        this.sendGAPageView(event.urlAfterRedirects);

        this.checkIsPublicRoute(event.urlAfterRedirects);

        if (window && window.location && window.location.hash) {
          let offset = this.headerHeightInPixels;
          if (!this.isDesktop) {
            offset += 300;
          }
          this.scrollToService.scrollTo({
            target: window.location.hash.substring(1),
            offset: offset,
          });
        }

        const currentUrlUTMParams = this.currentUrlUTMParams;

        if (currentUrlUTMParams) {
          const urlTree = this.router.createUrlTree([], {
            relativeTo: this.route,
            queryParams: currentUrlUTMParams,
            queryParamsHandling: 'merge',
            preserveFragment: true,
          });

          this.location.replaceState(urlTree.toString());
        }

        this.currentUrl = window.location.href;
      }
    });
  }

  public ngOnInit(): void {
    this.scrollToTopService.start();
    // store sidenav to service
    this.sidenavService.setSidenav(this.sidenav);
  }

  // initialize correct routes based on user role
  private initializeNavLinks = () => {
    if (!this.user) {
      this.externalNavRoutes = NAV_ROUTES_PUBLIC_EXTERNAL;
      this.navRoutes = NAV_ROUTES_PUBLIC;
    }

    if (
      !this.user ||
      (isTeamMemberUserRole(this.user.role) && !this.school) ||
      (isLearner(this.user.role) && !this.learner)
    ) {
      return;
    }

    this.isPublicMenu = false;
    switch (this.user.role) {
      case USER_ROLE.PLATFORM:
        this.navRoutes = NAV_ROUTES_INTERNAL;
        this.externalNavRoutes = [];
        break;
      case USER_ROLE.INSTRUCTOR:
        this.navRoutes = [];
        this.externalNavRoutes = [];
        this.internalRoutes = this.getInstructorInternalRoutes();
        break;
      case USER_ROLE.PRINCIPAL:
      case USER_ROLE.MANAGEMENT:
      case USER_ROLE.EDDY_SUPPORT:
        this.navRoutes = [];
        this.externalNavRoutes = [];
        if (!!this.school.isAccountingEnabled) {
          this.internalRoutes =
            this.getInstructorInternalRoutesWithAccounting();
        } else {
          this.internalRoutes = this.getInstructorInternalRoutes();
        }
        break;
      case USER_ROLE.SECRETARY:
        this.navRoutes = [];
        this.externalNavRoutes = [];
        if (!!this.school.isAccountingEnabled) {
          this.internalRoutes = this.getSecretaryInternalRoutesWithAccounting();
        } else {
          this.internalRoutes = this.getSecretaryInternalRoutes();
        }
        break;
      case USER_ROLE.DISTRIBUTION:
      case USER_ROLE.CUSTOMER_SERVICE:
        this.navRoutes = [];
        this.externalNavRoutes = [];
        this.internalRoutes =
          this.getNonInstructorSchoolEmployeeInternalRoutes();
        break;
      case USER_ROLE.STUDENT:
        this.navRoutes = [];
        this.externalNavRoutes = [];
        this.internalRoutes = this.getLearnerInternalRoutes();
        break;

      default:
        this.isPublicMenu = true;
        this.externalNavRoutes = NAV_ROUTES_PUBLIC_EXTERNAL;
        this.navRoutes = this.getPublicNavRoutes();
    }
  };

  private getInstructorInternalRoutes() {
    const routes: MenuRoute[] = [...INSTRUCTOR_BASIC_ROUTES];

    if (this.school.canScheduleLessons) {
      routes.push(...INSTRUCTOR_SCHEDULING_LESSONS_ROUTES);
    }

    if (this.school.isPartner()) {
      routes.push(...INSTRUCTOR_PARTNER_ROUTES);
    }

    if (this.school.isEddyClub()) {
      routes.push(...INSTRUCTOR_EDDY_CLUB_ROUTES);
    }

    /*if (env.environmentName === 'live') {
      return routes.filter(route => route.name !== 'Theorie Lernen');
  }*/

    return routes;
  }

  private getInstructorInternalRoutesWithAccounting() {
    const routes: MenuRoute[] = [...INSTRUCTOR_BASIC_ROUTES];

    if (this.school.canScheduleLessons) {
      routes.push(...INSTRUCTOR_SCHEDULING_LESSONS_ROUTES_WITH_ACCOUNTING);
    }

    if (this.school.isPartner()) {
      routes.push(...INSTRUCTOR_PARTNER_ROUTES);
    }

    if (this.school.isEddyClub()) {
      routes.push(...INSTRUCTOR_EDDY_CLUB_ROUTES);
    }

    /*if (env.environmentName === 'live') {
      return routes.filter(route => route.name !== 'Theorie Lernen');
  }*/

    return routes;
  }

  private getLearnerInternalRoutes() {
    const routes: MenuRoute[] = [...NAV_ROUTES_LEARNER_BASIC];

    if (this.learner.hasOnlineTheoryActive()) {
      routes.push(LEARNER_ONLINE_THEORY_ROUTE);
    }

    if (this.learner.isTheoryLearningActive) {
      routes.push(LEARNER_THEORY_LEARNING_ROUTE);
    }

    routes.push(...NAV_ROUTES_LEARNER_BASIC_LAST);

    return routes;
  }

  private getSecretaryInternalRoutes() {
    /*if (env.environmentName === 'live') {
      return [...INSTRUCTOR_BASIC_ROUTES, INSTRUCTOR_DAYPROOFS_ROUTE, INSTRUCTOR_PRACTICE_BOOKINGS_ROUTE, INSTRUCTOR_ONLINE_THEORY_BOOKINGS_ROUTE, INSTRUCTOR_REPORTS_ROUTE];
  }*/
    return [
      ...INSTRUCTOR_BASIC_ROUTES,
      INSTRUCTOR_CALENDAR_ROUTE,
      INSTRUCTOR_DAYPROOFS_ROUTE,
      INSTRUCTOR_PRACTICE_BOOKINGS_ROUTE,
      INSTRUCTOR_ONLINE_THEORY_BOOKINGS_ROUTE,
      INSTRUCTOR_REPORTS_ROUTE,
      INSTRUCTOR_THEORY_DASHBOARD_ROUTE,
      INSTRUCTOR_LEARNER_ROUTE,
      INSTRUCTOR_BASE_DATA_ROUTE,
    ];
  }

  private getSecretaryInternalRoutesWithAccounting() {
    /*if (env.environmentName === 'live') {
      return [...INSTRUCTOR_BASIC_ROUTES, INSTRUCTOR_DAYPROOFS_ROUTE, INSTRUCTOR_PRACTICE_BOOKINGS_ROUTE, INSTRUCTOR_ONLINE_THEORY_BOOKINGS_ROUTE, INSTRUCTOR_ACCOUNTING_ROUTE, INSTRUCTOR_REPORTS_ROUTE];
  }*/
    return [
      ...INSTRUCTOR_BASIC_ROUTES,
      INSTRUCTOR_CALENDAR_ROUTE,
      INSTRUCTOR_DAYPROOFS_ROUTE,
      INSTRUCTOR_PRACTICE_BOOKINGS_ROUTE,
      INSTRUCTOR_ONLINE_THEORY_BOOKINGS_ROUTE,
      INSTRUCTOR_ACCOUNTING_ROUTE,
      INSTRUCTOR_REPORTS_ROUTE,
      INSTRUCTOR_THEORY_DASHBOARD_ROUTE,
      INSTRUCTOR_LEARNER_ROUTE,
      INSTRUCTOR_BASE_DATA_ROUTE,
    ];
  }

  private getNonInstructorSchoolEmployeeInternalRoutes() {
    /*if (env.environmentName === 'live') {
      return [...INSTRUCTOR_BASIC_ROUTES, INSTRUCTOR_ONLINE_THEORY_BOOKINGS_ROUTE];
  }*/
    return [
      ...INSTRUCTOR_BASIC_ROUTES,
      INSTRUCTOR_ONLINE_THEORY_BOOKINGS_ROUTE,
      INSTRUCTOR_THEORY_DASHBOARD_ROUTE,
      INSTRUCTOR_BASE_DATA_ROUTE,
    ];
  }

  private sendGAPageView(url: string) {
    if (this.isServer) {
      return;
    }

    // @ts-ignore
    if (gtag === undefined) {
      return;
    }

    // @ts-ignore
    gtag('config', env.gtagTrackingKey, {
      page_path: url,
    });
  }

  private checkIsPublicRoute(route: string) {
    this.isPublicRoute =
      publicRoutes.findIndex((it) => route.startsWith(it)) !== -1;
    this.refreshSidenavOpenStatus();
  }

  private refreshSidenavOpenStatus() {
    if (this.isSideMode && this.sidenav) {
      this.sidenav.open();
    }
  }

  get isSideMode() {
    return this.isDesktop && this.isSchoolEmployeeOrLearner;
  }

  onCookieBannerDismissed() {
    this.coronavirusInfoBannerClosed = false;
  }

  onCloseCoronavirusInfoBanner() {
    this.coronavirusInfoBannerClosed = true;
  }

  onCoronavirusLearnerMoreInfo() {
    this.router.navigate(['/', AppRoutes.Root.eddyAcademy]);
  }

  onCoronavirusInstructorMoreInfo() {
    window.location.href = this.coronavirusInstructorMoreInfoLink;
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(evt: KeyboardEvent) {
    return this.disableBackNavigationOnBackspacePress(evt);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(): void {
    this.metaService.removeAlertsCounterFromTitle();
  }

  private disableBackNavigationOnBackspacePress(evt: KeyboardEvent) {
    if (evt.key === 'Backspace') {
      let doPrevent = true;
      const types = [
        'text',
        'password',
        'file',
        'search',
        'email',
        'number',
        'date',
        'color',
        'datetime',
        'datetime-local',
        'month',
        'range',
        'search',
        'tel',
        'time',
        'url',
        'week',
      ];
      const target = <HTMLInputElement>evt.target;

      const disabled =
        target.disabled || (<HTMLInputElement>evt.target).readOnly;
      if (!disabled) {
        if (target.isContentEditable) {
          doPrevent = false;
        } else if (target.nodeName === 'INPUT') {
          let type = target.type;
          if (type) {
            type = type.toLowerCase();
          }
          if (types.indexOf(type) > -1) {
            doPrevent = false;
          }
        } else if (target.nodeName === 'TEXTAREA') {
          doPrevent = false;
        }
      }
      if (doPrevent) {
        evt.preventDefault();
        return false;
      }
    }
  }

  get currentUrlUTMParams() {
    if (!this.currentUrl) {
      return null;
    }

    try {
      const url = new URL(this.currentUrl);
      const urlParams: URLSearchParams = new URLSearchParams(url.search);

      const utmParams = {};
      urlParams.forEach((value, key) => {
        if (key.startsWith('utm')) {
          utmParams[key] = value;
        }
      });

      return utmParams;
    } catch (e) {
      return null;
    }
  }
}
