export class UserQuestion {
    questionId: number;
    name: string;
    isAnswered: boolean;

    constructor(json: any) {
        this.questionId = +json.questionId;
        this.name = json.name;
        this.isAnswered = !!json.isAnswered;
    }
}
