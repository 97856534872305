import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  templateUrl: './thank-you-for-your-registration.page.html',
  styleUrls: ['./thank-you-for-your-registration.page.scss']
})
export class ThankYouForYourRegistrationPage {
  constructor(private router: Router) {
  }

  goToHomePage() {
    this.router.navigate(['/']);
  }
}
