<div class="container">
  <div class="left-container">
    <div class="title">Hol dir die Fahrschul App</div>
    <div class="title subtitle">Die smarte Lösung für Fahrschüler, Fahrlehrer und Fahrschule</div>

    <div class="info-container">
      <div class="image-container"></div>
      <div class="info">
        <div class="info-text">
          <div>Dank unserer leicht verständlichen,</div>
          <div>intuitiven App, sowie der zuverlässigen</div>
          <div>Software, ist sie ideal für eine</div>
          <div>erfolgreiche Zusammenarbeit</div>
          <div>zwischen Schüler, Lehrer und Schule.</div>
        </div>

        <div class="row buttons-row">
          <eddy-app-buttons theme="white" lineType="bold"></eddy-app-buttons>
        </div>
      </div>
    </div>
  </div>

  <div class="right-container">
    <div class="usp-container">
      <div class="icon-container">
        <div class="icon">
          <i class="fal fa-handshake"></i>
        </div>
      </div>

      <div class="text-container">
        <div class="usp-title">Finde deine Fahrschule</div>
        <div class="usp-text">Finde deine passende Fahrschule über drivEddy anhand deines Standortes, deiner Wünsche und Vorstellungen.</div>
      </div>
    </div>

    <div class="usp-container">
      <div class="text-container">
        <div class="usp-title">Sehe deinen Fortschritt</div>
        <div class="usp-text" style="padding: 0 20px 0 0;">drivEddy dokumentiert deinen Fortschritt, so weißt du zu jeder Zeit, was du bereits erfolgreich gemeistert hast und was du noch zu erledigen hast.</div>
      </div>

      <div class="icon-container">
        <div class="icon">
          <i class="fal fa-search"></i>
        </div>
      </div>
    </div>

    <div class="usp-container">
      <div class="icon-container">
        <div class="icon">
          <i class="fal fa-chalkboard-teacher"></i>
        </div>
      </div>

      <div class="text-container">
        <div class="usp-title">Alle Termine auf einen Blick</div>
        <div class="usp-text">Mit drivEddy hast du alle wichtigen Termine an einem Ort. Mit unserem smarten Terminkalender buchst du ganz einfach Stunden und behältst den Überblick.</div>
      </div>
    </div>
  </div>
</div>
