import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PriceListService} from '@services/price-list.service';

@Component({
  selector: 'eddy-changed-price-list-card',
  templateUrl: './changed-price-list-card.component.html',
  styleUrls: ['./changed-price-list-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangedPriceListCardComponent implements OnInit {
   /** Subject that emits when the component has been destroyed. */
   private _onDestroy = new Subject<void>();

  @Input() priceListId: number;

  @Input() openAcceptPriceListDialog$: Observable<boolean> = new Observable();

  @Input() hasRejectedChanges: boolean;

  @Output() priceListActionTaken: EventEmitter<void> = new EventEmitter();

  constructor(
    private priceListService: PriceListService,
) {
  }
  ngOnInit(): void {
    this.openAcceptPriceListDialog$.pipe(
      takeUntil(this._onDestroy)
    )
      .subscribe(res => {
        if (res) {
          this.onAcceptPriceList();
        }
      });
  }

  onAcceptPriceList() {
    this.priceListService.acceptPriceList(this.priceListId, this.priceListActionTaken);
  }
}
