import { TheoryLessonTopic } from '@models/theory-lesson-topic.model';
import { TheoryLessonAttendee } from '@models/theory-lesson-attendee.model';
import { TheoryTopicAttendee } from '@models/theory-topic-attendee.model';
import { PaymentStatus } from './payment-status.model';
import { AdditionalTheoryLessonAttendance } from './additional_theory_lesson_attendance.model';
import moment from 'moment';
import { YoudriveBooking } from './youdrive-booking.model';
import { TheoryLessonVideoAttendee } from './theory-lesson-video-attendee.model';

export class LearnerTheoryLesson {
  topic: TheoryLessonTopic;
  attendee?: TheoryLessonAttendee;
  externalAttendee?: TheoryTopicAttendee;
  additionalLessonAttendance?: AdditionalTheoryLessonAttendance;
  youdriveAttendee?: YoudriveBooking;
  learnerTheoryTopicId: number;
  learnerTheoryTopicKey: string;
  paymentLink: string;
  paymentStatus: PaymentStatus;

  videoAttendee: TheoryLessonVideoAttendee;
  learnerTheoryTopicVideoKey: string;
  videoPaymentLink: string;
  videoPaymentStatus: PaymentStatus;
  videoPriceInCents: number;

  isNext?: boolean;
  isBookable: boolean;

  constructor(json) {
    this.topic = new TheoryLessonTopic(json.topic);
    if (json.attendee) {
      this.attendee = new TheoryLessonAttendee(json.attendee);
    }
    if (json.externalAttendee) {
      this.externalAttendee = new TheoryTopicAttendee(json.externalAttendee);
    }
    if (json.additionalLessonAttendance) {
      this.additionalLessonAttendance = new AdditionalTheoryLessonAttendance(
        json.additionalLessonAttendance
      );
    }
    if (json.youdriveAttendee) {
      this.youdriveAttendee = new YoudriveBooking(json.youdriveAttendee);
    }
    this.learnerTheoryTopicId = +json.learnerTheoryTopicId;
    this.learnerTheoryTopicKey = json.learnerTheoryTopicKey;
    this.paymentLink = json.paymentLink;
    this.paymentStatus = json.paymentStatus;

    if (json.videoAttendee) {
      this.videoAttendee = new TheoryLessonVideoAttendee(json.videoAttendee);
    }
    this.learnerTheoryTopicVideoKey = json.learnerTheoryTopicVideoKey;
    this.videoPaymentLink = json.videoPaymentLink;
    this.videoPaymentStatus = json.videoPaymentStatus;
    this.videoPriceInCents = +json.videoPriceInCents;

    if (typeof json.isNext !== 'undefined') {
      this.isNext = !!json.isNext;
    }
    this.isBookable = !!json.isBookable;
  }

  isConfirmed(): boolean {
    return (
      (!!this.attendee && this.attendee.isConfirmed()) ||
      !!this.externalAttendee ||
      !!this.youdriveAttendee
    );
  }

  isRequested(): boolean {
    return !!this.attendee && this.attendee.isRequested();
  }

  isPresent(): boolean {
    return !!this.attendee && this.attendee.isPresent();
  }

  isWatched(): boolean {
    return !!this.attendee && this.attendee.isWatched();
  }

  isFailedPresence(): boolean {
    return !!this.attendee && this.attendee.isFailedPresence();
  }

  isRemoved(): boolean {
    return !!this.attendee && this.attendee.isRemoved();
  }

  isWatchedOrConfirmed(): boolean {
    return this.isWatched() || this.isConfirmed();
  }

  isPresentOrWatchedOrConfirmed(): boolean {
    return this.isPresent() || this.isWatched() || this.isConfirmed();
  }

  isLate(): boolean {
    return !!this.attendee && this.attendee.isLate();
  }

  isPaid(): boolean {
    return this.paymentStatus === PaymentStatus.PAID;
  }

  isPending(): boolean {
    return this.paymentStatus === PaymentStatus.PENDING;
  }

  isInThePast(): boolean {
    return this.attendee && this.attendee.isInThePast();
  }

  isExternalAttendee(): boolean {
    return !!this.externalAttendee;
  }

  isYoudriveAttendee(): boolean {
    return !!this.youdriveAttendee;
  }

  isVideoAttendee(): boolean {
    return !!this.videoAttendee;
  }

  isCompleted(): boolean {
    return (
      this.isConfirmed() ||
      this.isExternalAttendee() ||
      this.isYoudriveAttendee()
    );
  }

  hasLesson(): boolean {
    return !!this.attendee;
  }

  isLessonOwnerSchoolId(schoolId: number): boolean {
    return (
      this.hasLesson() &&
      this.attendee.theoryLesson?.event?.ownerSchoolId === schoolId
    );
  }

  isLessonNotOlderThanMonths(months: number): boolean {
    const now = moment();
    const lessonStartDate = this.attendee.startDate;

    return now.diff(lessonStartDate, 'months', true) <= months;
  }

  get instructorFullName(): string {
    if (!this.attendee) {
      return '';
    }

    return this.attendee.instructorFullName;
  }

  get startDate(): Date {
    if (!this.attendee) {
      return null;
    }

    return this.attendee.startDate;
  }

  get endDate(): Date {
    if (!this.attendee) {
      return null;
    }

    return this.attendee.endDate;
  }

  get isFreeOfCharge(): boolean {
    return this.topic.isFreeOfCharge;
  }

  get isPureLiveStream(): boolean {
    if (!this.attendee) {
      return false;
    }

    return this.attendee.theoryLesson?.isPureLiveStream;
  }

  get isAdditionalLesson(): boolean {
    return this.topic.isAdditional || !!this.additionalLessonAttendance;
  }

  isVideoPaid(): boolean {
    return this.videoPaymentStatus === PaymentStatus.PAID;
  }

  isVideoPaymentPending(): boolean {
    return this.videoPaymentStatus === PaymentStatus.PENDING;
  }

  get lessonVideoWatchedDate(): Date {
    if (!this.videoAttendee) {
      return null;
    }

    return this.videoAttendee.lessonWatchedDate;
  }

  isVideoConfirmed(): boolean {
    return !!this.videoAttendee && this.videoAttendee.isConfirmed();
  }

  isVideoPresent(): boolean {
    return !!this.videoAttendee && this.videoAttendee.isPresent();
  }

  isVideoWatched(): boolean {
    return !!this.videoAttendee && this.videoAttendee.isWatched();
  }

  shouldVideoCompletedBeShown(): boolean {
    return (
      !this.attendee &&
      !this.isExternalAttendee() &&
      !this.isYoudriveAttendee() &&
      !this.isAdditionalLesson &&
      !!this.videoAttendee &&
      (this.videoAttendee.isWatched() || this.videoAttendee.isConfirmed())
    );
  }
}

export const compareByStartDateAndTopicNumber = (a, b) => {
  if (a.hasLesson() && b.hasLesson()) {
    const aStartDate = a.attendee.theoryLesson.startDate;
    const bStartDate = b.attendee.theoryLesson.startDate;
    return aStartDate.getTime() < bStartDate.getTime() ? -1 : 1;
  }

  if (a.topic.orderNumber < b.topic.orderNumber) {
    return -1;
  }

  return 1;
};
