<div class="dialog-container">
  <div id="dialog-title-container" mat-dialog-title>
    <div
      *ngIf="activeStep == 'CHOOSE_LICENSE'; else backButtonView"
      class="user-plus-icon-container"
    >
      <i class="fas fa-user-plus"></i>
    </div>
    <ng-template #backButtonView>
      <ng-container *ngIf="activeStep !== 'SUCCESS_MESSAGE'">
        <div
          class="user-plus-icon-container clickable"
          (click)="toPreviousStep()"
        >
          <i class="fa fa-arrow-left dialog-back-btn"></i>
        </div>
      </ng-container>
    </ng-template>

    <span (click)="onQuit()" class="dialog-close-button">
      <i class="fal fa-times"></i>
    </span>
  </div>
  <mat-dialog-content>
    <div class="main-content" [ngSwitch]="activeStep">
      <ng-container *ngSwitchCase="'CHOOSE_LICENSE'">
        <form
          novalidate
          [formGroup]="chooseLearnerLicenseForm"
          (submit)="onSubmit()"
        >
          <div class="circle-container">
            <div class="circle first dark"></div>
            <div class="circle-line"></div>
            <div class="circle last light"></div>
          </div>

          <div class="description-text mt-40">
            Bitte wähle die Lizenz, die der/die Fahrschüler:in bei dir
            absolvieren soll. Die Lizenz wird dem/der Fahrschüler:in automatisch
            zugewiesen.
          </div>

          <div class="dialog-header">Welche Lizenz wird absolviert?</div>

          <ng-select
            formControlName="license"
            [clearable]="false"
            dropdownPosition="top"
            placeholder="Lizenzen auswählen"
            class="dialog-select custom-height-45"
            (change)="onLearnerLicenseChange()"
          >
            <ng-option *ngFor="let license of licenses" [value]="license">
              Klasse {{ license.key }}
            </ng-option>
          </ng-select>
        </form>

        <ng-container *ngIf="chosenLicenseNotFullyBookable">
          <div class="eddy-club-learner-checkbox">
            <mat-checkbox
              class="driveddy-mat-checkbox"
              (change)="onAreAllTheoryLessonsBookableChange($event.checked)"
            >
              Alle Theorie-Stunden zur Buchung freischalten

              <span
                class="tooltip-container ml-1"
                tooltip="Bei dieser Erweiterung müssen normalerweise weniger Kurse besucht werden. Wenn du diese Checkbox aktivierst, können deine Fahrschüler:innen in der Online-Theorie alle verfügbaren Kurse buchen, um eine noch umfassendere Ausbildung zu erhalten. Bitte beachte, bei Aktivierung wird dir für die zusätzlich buchbaren Stunden eine Zusatzgebühr in Höhe von {{ parentLicenseAdditionalFee / 100.0 | currency : 'EUR': 'symbol-narrow': '.2-2' }} abgerechnet"
                whiteBackground="true"
                destroyOnMouseLeave="true"
                max-width="300"
                placement="top"
                ><i class="fa fa-info-circle"></i
              ></span>
            </mat-checkbox>
          </div>
        </ng-container>

        <div
          [hidden]="!school.isEddyClubOrPartner() || !school.usesManagementSoftware"
        >
          <div class="dialog-header">Art des Fahrschülers</div>
          <form novalidate [formGroup]="licensePaymentFlagForm">
            <ng-select
              formControlName="paymentFlag"
              [clearable]="true"
              dropdownPosition="top"
              placeholder="Art des Fahrschülers auswählen"
              class="dialog-select custom-height-45"
            >
              <ng-option
                *ngFor="let paymentFlag of paymentFlags"
                [value]="paymentFlag"
              >
                {{ getPaymentFlagDisplayName(paymentFlag) }}
              </ng-option>
            </ng-select>
          </form>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'ACTIVATE_ONLINE_THEORY'">
        <form
          novalidate
          [formGroup]="activateLicenseOnlineTheoryForm"
          (submit)="onSubmit()"
        >
          <div class="circle-container">
            <div class="circle first light"></div>
            <div class="circle-line"></div>
            <div class="circle last dark"></div>
          </div>

          <ng-container *ngIf="chooseLicenseSkipped; else licenseChosen">
            <div class="description-text mt-40">
              Für diese Lizenz bieten wir leider keine Online-Theorie an. Daher
              musst du den Theorie-Unterricht in deinen Räumlichkeiten selbst
              stattfinden lassen.
            </div>
            <div class="description-text">
              Hierzu lege einfach einen Theorietermin in deinem Kalender an, der
              von deinen Fahrschüler:innen gebucht werden kann.
            </div>
          </ng-container>

          <ng-template #licenseChosen>
            <ng-container
              *ngIf="!chosenLicenseHasOnlineTheory; else hasOnlineTheory"
            >
              <div class="description-text mt-40">
                Für diese Lizenz bieten wir leider keine Online-Theorie an.
                Daher musst du den Theorie-Unterricht in deinen Räumlichkeiten
                selbst stattfinden lassen.
              </div>
              <div class="description-text">
                Hierzu lege einfach einen Theorietermin in deinem Kalender an,
                der von deinen Fahrschüler:innen gebucht werden kann.
              </div>
              <ng-container
                *ngTemplateOutlet="onboardingOptions"
              ></ng-container>
            </ng-container>

            <ng-template #hasOnlineTheory>
              <div class="mt-40">
                <ng-container
                  *ngTemplateOutlet="onboardingOptions"
                ></ng-container>
              </div>
            </ng-template>
          </ng-template>

          <ng-template #onboardingOptions>
            <div class="description-text mb-4">
              Für diese Lizenz kannst du folgende Leistungen freischalten:
            </div>
            <div class="license-options">
              <div
                class="license-option"
                [ngClass]="{'selected' : isOnlineTheoryActivated, 'disabled': !chosenLicenseHasOnlineTheory}"
                (click)="toggleOnlineTheoryActivated()"
              >
                <div class="option-title">Online-Theorieservice</div>
                <div class="option-cont">
                  <div class="option-lbl" *ngIf="chosenLicenseHasOnlineTheory">
                    {{ (areAllTheoryLessonsBookable ? (licensePricing +
                    parentLicenseAdditionalFee) : licensePricing) / 100.0 |
                    currency : 'EUR': 'symbol-narrow': '.2-2' }}
                  </div>
                  <div class="option"></div>
                </div>
              </div>

              <div
                class="license-option"
                [ngClass]="{'selected' : isTheoryVideosActivated, 'disabled': !chosenLicenseHasOnlineTheory}"
                (click)="toggleTheoryVideosActivated()"
              >
                <div class="option-title">Theorie-Unterrichtsvideos</div>
                <div class="option-cont">
                  <div class="option-lbl" *ngIf="chosenLicenseHasOnlineTheory">
                    {{ theoryVideosPricing / 100.0 | currency : 'EUR':
                    'symbol-narrow': '.2-2' }}
                  </div>
                  <div class="option"></div>
                </div>
              </div>
            </div>
          </ng-template>
        </form>
      </ng-container>
    </div>
  </mat-dialog-content>

  <!--  <div class="spacer"></div>-->

  <mat-dialog-actions class="dialog-actions">
    <button
      type="submit"
      class="primary-btn"
      [class.disabled]="formIsInvalid"
      [disabled]="formIsInvalid"
      (click)="onSubmit()"
    >
      {{ buttonText }}
    </button>
  </mat-dialog-actions>
</div>
