import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TheoryLessonTopicSubtitle } from '@models/theory-lesson-topic-subtitle.model';
import * as Sentry from '@sentry/browser';
import moment, { Moment } from 'moment';

@Component({
  selector: 'eddy-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPlayerComponent {
  private _videoUrl: string;

  showVideo: boolean;
  private videoTimeInSeconds: number;
  private autoplayFailedEmitted: boolean;

  currentTime: number;
  seekingOffset: Moment;

  @Input() isVideoView: boolean;

  @Input() topicSubtitles: TheoryLessonTopicSubtitle[];

  @Input() set videoUrl(videoUrl: string) {
    this.addSentryBreadcrumb(`set videoUrl: ${videoUrl}`);

    if (!videoUrl) {
      return;
    }

    if (this._videoUrl === videoUrl) {
      if (Sentry) {
        Sentry.addBreadcrumb({
          category: 'video-player',
          message: `same video-url, skipping: ${this._videoUrl}`,
          level: Sentry.Severity.Info,
        });
        return;
      }
    }

    this._videoUrl = videoUrl;

    // force the video tag to be regenerated to make sure that the video url is updated
    this.showVideo = false;
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.showVideo = true;
        this.changeDetectorRef.detectChanges();
      }, 10);
    });

    setTimeout(() => {
      if (Sentry) {
        Sentry.addBreadcrumb({
          category: 'video-player',
          message: `video-url updated: ${this._videoUrl}`,
          level: Sentry.Severity.Info,
        });
      }
    });

    setTimeout(() => this.throwErrorIfInvalidVideoUrl());
  }

  @Input() showControls: boolean;

  @Input() showSeek: boolean;

  @Input() showAudioSlider: boolean;

  @ViewChild('videoPlayer', { read: ElementRef, static: false })
  videoPlayer: ElementRef;

  @Output() videoEnd: EventEmitter<void> = new EventEmitter();

  @Output() videoSeek: EventEmitter<number> = new EventEmitter();

  @Output() autoplayFailed: EventEmitter<void> = new EventEmitter();

  @Output() videoPlay: EventEmitter<void> = new EventEmitter();

  @Output() videoPause: EventEmitter<void> = new EventEmitter();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  get videoUrl(): string {
    return this._videoUrl;
  }

  get isVideoPlaying(): boolean {
    if (!this.videoPlayer || !this.videoPlayer.nativeElement) {
      return false;
    }

    return !this.videoPlayer.nativeElement.paused;
  }

  private isFunction(v) {
    return typeof v === 'function';
  }

  play(videoTimeInSeconds?: number) {
    if (videoTimeInSeconds !== undefined) {
      this.videoTimeInSeconds = videoTimeInSeconds;
    }
    if (this.videoTimeInSeconds !== undefined) {
      if (this.isFunction(this.videoPlayer.nativeElement.currentTime)) {
        this.videoPlayer.nativeElement.currentTime(this.videoTimeInSeconds);
        this.addSentryBreadcrumb(
          `setting video current time via method: ${this.videoTimeInSeconds}`
        );
      } else {
        this.videoPlayer.nativeElement.currentTime = this.videoTimeInSeconds;
        this.addSentryBreadcrumb(
          `setting video current time: ${this.videoTimeInSeconds}`
        );
      }
    }

    const promise = this.videoPlayer.nativeElement.play();
    this.videoPlayer.nativeElement.oncanplaythrough = () => {
      if (
        !this.videoPlayer.nativeElement.currentTime &&
        this.videoTimeInSeconds &&
        isFinite(this.videoTimeInSeconds)
      ) {
        this.videoPlayer.nativeElement.currentTime = this.videoTimeInSeconds;
        this.addSentryBreadcrumb(
          `setting video current time in oncanplaythrough: ${this.videoTimeInSeconds}`
        );
      }
      this.addSentryBreadcrumb('video oncanplaythrough');
    };

    this.videoPlayer.nativeElement.onsuspend = () => {
      if (
        !this.isVideoPlaying &&
        !this.autoplayFailedEmitted &&
        moment().isAfter(this.seekingOffset)
      ) {
        this.autoplayFailedEmitted = true;
        this.autoplayFailed.emit();
        this.changeDetectorRef.detectChanges();
        this.addSentryBreadcrumb(`showing manual play button in onsuspend`);
      }
    };

    if (promise !== undefined) {
      promise
        .then((_) => {
          this.autoplayFailedEmitted = false;
          this.changeDetectorRef.detectChanges();
          this.addSentryBreadcrumb('video play started');
          // Autoplay started!
        })
        .catch((error) => {
          if (error.name === 'NotAllowedError') {
            this.attemptMutedPlay();
            this.addSentryBreadcrumb(
              'initial play failed, attempting muted play: ' + error
            );
          } else {
            setTimeout(() => {
              throw new Error('failed initial play: ' + error);
            });
          }
        });
    }
  }

  get isVideoMuted(): boolean {
    if (!this.videoPlayer || !this.videoPlayer.nativeElement) {
      return false;
    }

    return this.videoPlayer.nativeElement.muted;
  }

  pause() {
    this.seekingOffset = moment().add(1, 'seconds');
    this.videoPlayer.nativeElement.pause();
    this.addSentryBreadcrumb('video paused');
  }

  onVideoEnd() {
    this.videoEnd.emit();
    this.addSentryBreadcrumb('video ended');
  }

  onSeeking(seekValue: number) {
    this.seekingOffset = moment().add(2, 'seconds');
    // Update the video time
    this.videoPlayer.nativeElement.currentTime = seekValue;
  }

  onSeek(seekValue: number) {
    // Update the video time
    this.seekingOffset = moment().add(2, 'seconds');
    this.videoPlayer.nativeElement.currentTime = seekValue;
    this.changeDetectorRef.detectChanges();

    this.videoSeek.emit(this.videoPlayer.nativeElement.currentTime);
  }

  onTimeUpdate() {
    if (!this.showSeek) {
      return;
    }
    this.currentTime = this.videoPlayer.nativeElement.currentTime;
    this.changeDetectorRef.detectChanges();
  }

  onPlay() {
    this.videoPlay.emit();
  }

  onPause() {
    this.videoPause.emit();
  }

  getCurrentTime() {
    if (!this.videoPlayer?.nativeElement) {
      return 0;
    }
    return Math.ceil(this.videoPlayer.nativeElement.currentTime);
  }

  setCurrentTime(videoTimeInSeconds: number) {
    this.videoTimeInSeconds = videoTimeInSeconds;

    if (this.isFunction(this.videoPlayer.nativeElement.currentTime)) {
      this.videoPlayer.nativeElement.currentTime(this.videoTimeInSeconds);
      this.addSentryBreadcrumb(
        `setting video current time via method: ${this.videoTimeInSeconds}`
      );
    } else {
      this.videoPlayer.nativeElement.currentTime = this.videoTimeInSeconds;
      this.addSentryBreadcrumb(
        `setting video current time: ${this.videoTimeInSeconds}`
      );
    }
  }

  onUnmute() {
    if (!this.videoPlayer || !this.videoPlayer.nativeElement) {
      return;
    }

    this.videoPlayer.nativeElement.muted = false;
    this.changeDetectorRef.detectChanges();
    this.addSentryBreadcrumb('video unmuted');
  }

  onMute() {
    if (!this.videoPlayer || !this.videoPlayer.nativeElement) {
      return;
    }

    this.videoPlayer.nativeElement.muted = true;
    this.changeDetectorRef.detectChanges();
    this.addSentryBreadcrumb('video muted');
  }

  changeVolume(volume: number) {
    if (!this.videoPlayer || !this.videoPlayer.nativeElement) {
      return;
    }
    return (this.videoPlayer.nativeElement.volume = volume / 10);
  }

  get volume(): number {
    if (!this.videoPlayer || !this.videoPlayer.nativeElement) {
      return 10;
    }
    if (this.isVideoMuted) {
      return 0;
    }
    return this.videoPlayer.nativeElement.volume * 10;
  }

  private attemptMutedPlay() {
    // Show something in the UI that the video is muted
    this.videoPlayer.nativeElement.muted = true;
    const promise = this.videoPlayer.nativeElement.play();
    this.changeDetectorRef.detectChanges();
    if (promise) {
      promise
        .then((_) => {
          this.autoplayFailedEmitted = false;
          this.changeDetectorRef.detectChanges();
          this.addSentryBreadcrumb('muted play started');
          // Autoplay started!
        })
        .catch((error) => {
          if (error.name === 'NotAllowedError') {
            this.autoplayFailedEmitted = true;
            this.autoplayFailed.emit();
            this.changeDetectorRef.detectChanges();
            this.addSentryBreadcrumb(
              'muted play failed, showing manual play button: ' + error
            );
          } else {
            setTimeout(() => {
              throw new Error('muted play error: ' + error);
            });
          }
        });
    }
  }

  setActiveSubtitle(subtitle: TextTrack): void {
    if (!this.videoPlayer || !this.videoPlayer.nativeElement) {
      return;
    }
    const textTracks: TextTrackList = this.videoPlayer.nativeElement.textTracks;
    for (let i = 0; i < textTracks.length; i++) {
      if (textTracks[i].language === subtitle?.language) {
        textTracks[i].mode = 'showing';
      } else {
        textTracks[i].mode = 'disabled';
      }
    }
  }

  get hasActiveSubtitle(): boolean {
    if (!this.videoPlayer || !this.videoPlayer.nativeElement) {
      return false;
    }
    const textTracks: TextTrackList = this.videoPlayer.nativeElement.textTracks;
    for (let i = 0; i < textTracks.length; i++) {
      if (textTracks[i].mode === 'showing') {
        return true;
      }
    }

    return false;
  }

  get currentTimeMinutesString(): string {
    return '' + Math.floor(this.currentTime / 60);
  }

  get currentTimeSecondsString(): string {
    const seconds = Math.floor(this.currentTime % 60);
    if (seconds < 10) {
      return `0${seconds}`;
    }

    return `${seconds}`;
  }

  get durationMinutesString(): string {
    return '' + Math.floor(this.videoPlayer.nativeElement.duration / 60);
  }

  get durationSecondsString(): string {
    const seconds = Math.floor(this.videoPlayer.nativeElement.duration % 60);
    if (seconds < 10) {
      return `0${seconds}`;
    }

    return `${seconds}`;
  }

  get duration(): number {
    if (!this.videoPlayer?.nativeElement) {
      return 0;
    }
    return this.videoPlayer.nativeElement.duration;
  }

  private throwErrorIfInvalidVideoUrl() {
    if (!this.videoUrl) {
      throw new Error('invalid video url - empty');
    }
    if (this.videoUrl.indexOf('mp4') === -1) {
      throw new Error('invalid video url: ' + this.videoUrl);
    }
  }

  private addSentryBreadcrumb(message: string) {
    if (Sentry?.addBreadcrumb) {
      Sentry.addBreadcrumb({
        category: 'video-player',
        message: message,
        level: Sentry.Severity.Info,
      });
    }
  }
}
