import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input } from '@angular/core';

@Component({
  selector: 'eddy-info-video-player',
  templateUrl: './info-video-player.component.html',
  styleUrls: ['./info-video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoVideoPlayerComponent {
  @Input() videoUrl: string;

  videoShown: boolean;

  constructor(private elementRef: ElementRef) {
  }

  toggleOpenPlayer() {
    this.videoShown = !this.videoShown;
  }

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    // hide the video on click outside of the component
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.videoShown = false;
    }
  }
}
