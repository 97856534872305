<div class="dialog-container">
  <div id="title-container" mat-dialog-title>
    Passwort zurücksetzen
    <span (click)="onQuit()" class="close-button">
      <i class="fal fa-times"></i>
    </span>
  </div>
  <mat-dialog-content>
    <div id="main-content">
      <form novalidate [formGroup]="form">
        <div id="description">
          Bitte gebe deine E-Mail-Adresse an um dein Passwort zurückzusetzen.
        </div>
        <input
          id="email"
          type="text"
          placeholder="E-Mail"
          class="form-control"
          formControlName="email"
        />
      </form>
    </div>
  </mat-dialog-content>

  <div class="spacer"></div>

  <mat-dialog-actions id="buttons-container">
    <button id="quit-button" class="" (click)="onQuit()">Abbrechen</button>
    <button
      id="confirm-button"
      class="save-button flot-right"
      [class.disabled]="form.invalid"
      [disabled]="form.invalid"
      (click)="onReset()"
    >
      Bestätigen
    </button>
  </mat-dialog-actions>
</div>
