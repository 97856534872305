import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, } from '@angular/router';
import { AppRoutes } from '../app.routes';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const _te = this.authService.getHeaders();
    if (_te) {
      return true;
    }

    this.authService.destinationUrl = state.url;
    this.router.navigate([AppRoutes.Root.login]);
    return false;
  }
}
