import { User } from './user.model';

export class AppFeedback {
  id: number;
  user: User;
  updatedAt: Date;

  stars?: number;
  feedbackText?: string;
  likesApp: boolean | undefined;

  constructor(json?: any) {
    this.id = +json.id;
    this.user = new User(json.user);

    this.updatedAt = new Date(json.updatedAt);

    this.stars = json.stars;
    this.feedbackText = json.feedbackText;
    this.likesApp = json.likesApp;
  }
}
