import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'eddy-view-edit-card',
  templateUrl: './view-edit-card.component.html',
  styleUrls: ['./view-edit-card.component.scss']
})
export class ViewEditCardComponent {
  @Input() editMode: boolean;
  @Input() editDisabled: boolean;
  @Output() editModeChange: EventEmitter<boolean> = new EventEmitter();

  constructor() {
  }

  onEdit() {
    this.editMode = true;
    this.editModeChange.emit(this.editMode);
  }

  onViewOnly() {
    this.editMode = false;
    this.editModeChange.emit(this.editMode);
  }
}
