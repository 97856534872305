<div
  class="testimonial-container"
  [class.general-testimonials]="generalTestimonials"
>
  <h2 class="testimonial-title">Stimmen von unseren Fahrschülern</h2>
  <div class="testimonial-rating" *ngIf="ratings">
    <eddy-rating-stars
      [rating]="ratings.rating"
      [hollowStars]="true"
      [alternativeStarsColor]="true"
      [showRatingNumber]="false"
      starSize="16"
      [spaceBetweenStars]="true"
      additionalLabelTextPosition="none"
      [readOnly]="true"
      [displayInline]="true"
    >
    </eddy-rating-stars>
    <span class="ratings-additional-text"
      >Bewertung: {{ ratings.rating | number : '1.1-2' }} /5 ({{
        ratings.numberOfRatings
      }}
      Rezensionen)</span
    >
  </div>

  <div *ngIf="generalTestimonials" class="row no-side-space testimonials-row">
    <div class="col-12 col-lg-4 testimonial-card">
      <div class="testimonial-card-outer-wrapper">
        <img class="testimonial-quotes" src="/assets/quotes-white.png" />
        <div class="testimonial-card-container">
          <div class="testimonial-text">
            Ich habe meinen Theoriepflichtteil bei drivEddy absolviert und war
            mega zufrieden. Der Vorgang war super einfach und schnell. Jetzt bin
            ich meinem Führerschein einen großen Schritt näher gekommen und
            starte kommende Woche mit der ersten Praxisstunde bei meiner
            Fahrschule um die Ecke.
          </div>
          <div class="testimonial-metadata">
            <div>
              <div class="testimonial-author">Patrick S., 21 Jahre</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-4 testimonial-card">
      <div class="testimonial-card-outer-wrapper">
        <img class="testimonial-quotes" src="/assets/quotes-white.png" />
        <div class="testimonial-card-container">
          <div class="testimonial-text">
            Finde es sehr gut! :) Man kann entspannt von zuhause aus die
            Theoriestunden absolvieren. Vor allem für Mütter eine tolle
            einzigartige Möglichkeit ohne Stress die Theoriestunden zu
            absolvieren. Macht weiter so!
          </div>
          <div class="testimonial-metadata">
            <div>
              <div class="testimonial-author">Berglind W., 28 Jahre</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-4 testimonial-card">
      <div class="testimonial-card-outer-wrapper">
        <img class="testimonial-quotes" src="/assets/quotes-white.png" />
        <div class="testimonial-card-container">
          <div class="testimonial-text">
            Ich bin sehr gut durch die Stunde gekommen. Dadurch das ich die
            Präsentation parallel geöffnet hatte, war es mir möglich die Notizen
            und Tipp's direkt an der richtigen Stelle einzufügen. Einfach
            klasse!
          </div>
          <div class="testimonial-metadata">
            <div>
              <div class="testimonial-author">Lucy H., 20 Jahre</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!generalTestimonials" class="row no-side-space testimonials-row">
    <div class="col-12 col-lg-4 testimonial-card">
      <div class="testimonial-card-outer-wrapper">
        <img class="testimonial-quotes" src="/assets/quotes-white.png" />
        <div class="testimonial-card-container">
          <div class="testimonial-text">
            Finde es sehr gut! :) Man kann entspannt von zuhause aus die
            Theoriestunden absolvieren. Vor allem für Mütter eine tolle
            einzigartige Möglichkeit ohne Stress die Theoriestunden zu
            absolvieren. Macht weiter so!
          </div>
          <div class="testimonial-metadata">
            <div>
              <div class="testimonial-author">Berglind W.</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-4 testimonial-card">
      <div class="testimonial-card-outer-wrapper">
        <img class="testimonial-quotes" src="/assets/quotes-white.png" />
        <div class="testimonial-card-container">
          <div class="testimonial-text">
            Alles sehr gut erklärt und die Videodemonstrationen sind auch sehr
            hilfreich.
          </div>
          <div class="testimonial-metadata">
            <div>
              <div class="testimonial-author">Franziska Sch.</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-4 testimonial-card">
      <div class="testimonial-card-outer-wrapper">
        <img class="testimonial-quotes" src="/assets/quotes-white.png" />
        <div class="testimonial-card-container">
          <div class="testimonial-text">
            Ich bin sehr gut durch die Stunde gekommen. Dadurch das ich die
            Präsentation parallel geöffnet hatte, war es mir möglich die Notizen
            und Tipp's direkt an der richtigen Stelle anzufügen. Einfach klasse!
          </div>
          <div class="testimonial-metadata">
            <div>
              <div class="testimonial-author">Lucy H.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
