import { Injectable } from '@angular/core';
import { asapScheduler, Observable, scheduled } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { map } from 'rxjs/operators';
import { SchoolOpeningHour } from '@models/school-opening-hour.model';
import { SchoolOpeningHourType } from '@models/school-opening-hour-type.model';
import { Day } from '@models/day.model';

@Injectable()
export class SchoolOpeningHourService {
  private readonly apiPath = `${env.apiSchoolService}`;

  constructor(private api: ApiService) {
  }

  getSchoolOpeningHours(schoolID: number): Observable<SchoolOpeningHour[]> {
    if (!schoolID) {
      return scheduled([], asapScheduler);
    }

    return this.api.get<SchoolOpeningHour[]>(`${this.apiPath}/schools/${schoolID}/opening_hours`)
      .pipe(
        map(it => this.mapOpeningHours(it))
      );
  }

  private mapOpeningHours(openingHours: SchoolOpeningHour[]) {
    return openingHours.map(it => new SchoolOpeningHour(it));
  }

  saveSchoolOpeningHours(schoolID: number, openingHours: SchoolOpeningHour[]): Observable<SchoolOpeningHour[]> {
    return this.api.post<SchoolOpeningHour[]>(`${this.apiPath}/schools/${schoolID}/opening_hours`, openingHours)
      .pipe(
        map(it => this.mapOpeningHours(it))
      );
  }

  parseOpeningHours(openingHours: SchoolOpeningHour[]) {

    const openingHoursPerTypeAndDay = new Map();

    for (const openingHour of openingHours) {
      this.addOpeningHourToMap(openingHoursPerTypeAndDay, openingHour);
    }

    return openingHoursPerTypeAndDay;
  }

  addOpeningHourToMap(openingHoursPerTypeAndDay: Map<SchoolOpeningHourType, Map<Day, SchoolOpeningHour[]>>, openingHour: SchoolOpeningHour) {
    const type = openingHour.type;
    const day = openingHour.day;

    let openingHoursPerDay: Map<Day, SchoolOpeningHour[]> = openingHoursPerTypeAndDay.get(type);
    if (!openingHoursPerDay) {
      openingHoursPerDay = new Map();
    }

    let dayOpeningHours = openingHoursPerDay.get(day);
    if (!dayOpeningHours) {
      dayOpeningHours = [];
    }
    dayOpeningHours.push(openingHour);

    openingHoursPerDay.set(day, dayOpeningHours);
    openingHoursPerTypeAndDay.set(type, openingHoursPerDay);
  }

  removeOpeningHourFromMap(openingHoursPerTypeAndDay: Map<SchoolOpeningHourType, Map<Day, SchoolOpeningHour[]>>, openingHour: SchoolOpeningHour) {
    // tslint:disable
    outerLoop:
      for (const type of Array.from(openingHoursPerTypeAndDay.keys())) {
        const openingHoursPerDay = openingHoursPerTypeAndDay.get(<SchoolOpeningHourType>type);

        for (const day of Array.from(openingHoursPerDay.keys())) {
          const dayOpeningHours = openingHoursPerDay.get(<Day>day);
          const idx = dayOpeningHours.findIndex(it => it === openingHour);
          if (idx !== -1) {
            dayOpeningHours.splice(idx, 1);
            break outerLoop;
          }
        }
      }
  }

  updateOpeningHourInMap(openingHoursPerTypeAndDay: Map<SchoolOpeningHourType, Map<Day, SchoolOpeningHour[]>>, openingHour: SchoolOpeningHour) {
    // tslint:disable
    outerLoop:
      for (const type of Array.from(openingHoursPerTypeAndDay.keys())) {
        const openingHoursPerDay = openingHoursPerTypeAndDay.get(<SchoolOpeningHourType>type);

        for (const day of Array.from(openingHoursPerDay.keys())) {
          const dayOpeningHours = openingHoursPerDay.get(<Day>day);
          const idx = dayOpeningHours.findIndex(it => it === openingHour);
          if (idx !== -1) {
            dayOpeningHours[idx] = openingHour;
            break outerLoop;
          }
        }
      }
  }
}
