import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { License, LicenseGroup } from '@models/license.model';

@Component({
  templateUrl: './choose-license.dialog.html',
  styleUrls: ['./choose-license.dialog.scss']
})
export class ChooseLicenseDialog {
  allLicenses: License[];
  selectedLicenses: License[];
  includedLicenses: string[] = [];

  constructor(private dialogRef: MatDialogRef<ChooseLicenseDialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.allLicenses = data.allLicenses;
    this.selectedLicenses = data.selectedLicenses;
    this.includedLicenses = this.getIncludedLicenseKeys(this.selectedLicenses);
    this.selectedLicenses = this.selectedLicenses.filter(selLicense => !this.isIncludedInSelected(selLicense.key));
  }

  onSelectedLicenseChange(change) {
    const license = change.source.value;
    if (change.checked) {
      this.selectedLicenses.push(license);
      this.includedLicenses = this.getIncludedLicenseKeys(this.selectedLicenses);
      this.selectedLicenses = this.selectedLicenses.filter(selLicense => !this.isIncludedInSelected(selLicense.key));
    } else {
      this.selectedLicenses = this.selectedLicenses.filter(selLicense => selLicense.key !== license.key);
      this.includedLicenses = this.getIncludedLicenseKeys(this.selectedLicenses);
    }
  }

  onSubmit() {
    this.closeDialog({
      selectedLicenses: this.selectedLicenses.map(it => it.key),
    });
  }

  onQuit() {
    this.closeDialog(null);
  }

  isChecked(license: string) {
    return this.selectedLicenses.map(it => it.key).includes(license);
  }

  isIncludedInSelected(license: string) {
    return this.includedLicenses.includes(license);
  }

  private getIncludedLicenseKeys(licenses: License[]): string[] {
    const allIncludedLicenses = licenses.map(license => license.includedLicenseKeys);

    return allIncludedLicenses.reduce((accumulator, value) => accumulator.concat(value), [])
      .reduce((unique, value) => unique.includes(value) ? unique : [...unique, value], []);
  }

  private closeDialog(result) {
    this.dialogRef.close(result);
  }

  get allALicenses() {
    return this.allLicenses.filter(it => it.group === LicenseGroup.A);
  }

  get allBLicenses() {
    return this.allLicenses.filter(it => it.group === LicenseGroup.B);
  }

  get allCLicenses() {
    return this.allLicenses.filter(it => it.group === LicenseGroup.C);
  }

  get allDLicenses() {
    return this.allLicenses.filter(it => it.group === LicenseGroup.D);
  }

  get allLTLicenses() {
    return this.allLicenses.filter(it => it.group === LicenseGroup.LT);
  }
}
