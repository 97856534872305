export class LearnerLearningStatus {
    learnerId: number;
  
    learnerName: string;
    licenses: string;
    learningStatusPercentage: number;
    learnerStars: number;
    learningTimeInSeconds: number;
    isTheoryLearningActive: boolean;
    isActive: boolean;
  
    constructor(json?: any) {
      if (!json) {
        return;
      }
  
      this.learnerId = +json.learnerId;

      this.learnerName = json.learnerName;
      this.licenses = json.licenses;
      this.learningStatusPercentage = +json.learningStatusPercentage;
      this.learnerStars = +json.learnerStars;
      this.learningTimeInSeconds = +json.learningTimeInSeconds;
      this.isTheoryLearningActive = !!json.isTheoryLearningActive;
      this.isActive = !!json.isActive;
    }
  
    get learningTimeDisplay(): string {
        const h = Math.floor(this.learningTimeInSeconds / 3600);
        const m = Math.floor(this.learningTimeInSeconds % 3600 / 60);

        const hDisplay = h > 0 ? `${h}s ` : '0s ';
        const mDisplay = m > 0 ? `${m}m` : '0m';
        return hDisplay + mDisplay; 
    }

    get statusDisplayName(): string {
      return this.isTheoryLearningActive ? 'Aktiv' : 'Inaktiv';
    }
}
