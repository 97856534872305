import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from '@validators/email.validator';
import { AppRoutes } from '../../app.routes';
import { requiredNonBlank } from '@validators/required-non-blank.validator';
import { SaveLearnerRequest } from '@models/save-learner-request.model';
import { NotificationService } from '@services/notification.service';
import { SchoolLearnerService } from '@services/school-learner.service';
import { SchoolLearner } from '@models/school-learner.model';
import { Learner, LearnerLicense } from '@models/learner.model';
import { LearnerAddress } from '@models/learner-address.model';
import { UserInfo } from '@models/user-info.model';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';
import { LicenseService } from '@services/license.service';
import { getOnlineTheoryLicenses, License } from '@models/license.model';
import { TheoryLessonService } from '@services/theory-lesson.service';
import { environment as env } from '@environments/environment';

const DUPLICATE_EMAIL_ERROR = 'duplicate email';

@Component({
  templateUrl: './sign-up.dialog.html',
  styleUrls: ['./sign-up.dialog.scss'],
})
export class SignUpDialog implements OnInit {
  readonly learnerTermsAndConditionsPage = `/${AppRoutes.Root.learnerTermsAndConditions}`;
  readonly learnerTheoryLessonsDashboardPage = `/${AppRoutes.EddyAcademyLearner.root}/${AppRoutes.EddyAcademyLearner.dashboardPage}`;
  readonly dataPrivacyWebPage = `/${AppRoutes.Root.dataPrivacyWeb}`;

  licenses: License[];
  includedLicenses: string[] = [];

  redirectToWelcomePage = true;
  redirectToTheoryPackagePayment: boolean;
  preselectedLicenseKey: string;

  form: FormGroup;
  step: number;
  learner: SaveLearnerRequest;

  schoolLearner: SchoolLearner;

  signUpRequestInProgress: boolean;
  loginPageUrl = `/${AppRoutes.Root.login}`;
  showPassword: boolean;
  showConfirmPassword: boolean;
  loginInProgress: boolean;

  @ViewChild('postalCode', { read: ElementRef }) postalCodeElement: ElementRef;

  constructor(
    private dialogRef: MatDialogRef<SignUpDialog>,
    private schoolLearnerService: SchoolLearnerService,
    private licenseService: LicenseService,
    private theoryLessonService: TheoryLessonService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.redirectToTheoryPackagePayment = data?.redirectToTheoryPackagePayment;
    this.preselectedLicenseKey = data?.preselectedLicenseKey;
    this.step = 1;
  }

  ngOnInit() {
    this.licenseService.getStandardPriceListLicenses().subscribe((licenses) => {
      this.licenses = getOnlineTheoryLicenses(licenses);
    });
    this.form = new FormGroup({
      email: new FormControl('', [emailValidator, Validators.required]),
      name: new FormControl('', [requiredNonBlank]),
      lastName: new FormControl('', [requiredNonBlank]),
      password: new FormControl('', [requiredNonBlank]),
      confirmPassword: new FormControl('', [requiredNonBlank]),
      postalCode: new FormControl(this.data?.postalCode || '', [
        requiredNonBlank,
      ]),
    });
  }

  onQuit() {
    this.dialogRef.close(null);
  }

  onEnterDetails() {
    if (!this.isStep1DataValid || this.signUpRequestInProgress) {
      this.form.markAllAsTouched();
      this.form.controls['postalCode'].markAsUntouched();
      return;
    }
    if (!this.equalPasswords) {
      this.notificationService.error('Passwörter stimmen nicht überein!');
      return;
    }

    this.learner = {
      firstName: this.form.value.name,
      lastName: this.form.value.lastName,
      email: this.form.value.email.trim(),
      password: this.form.value.password,
      licenses: [],
    };

    this.authService
      .userOrAccountWithEmailExists(this.learner.email)
      .subscribe((emailExists) => {
        if (emailExists) {
          this.notificationService.error('Die E-Mail existiert bereits');
          return;
        }

        if (
          !this.preselectedLicenseKey ||
          (this.preselectedLicenseKey && this.preselectedLicenseKey === '')
        ) {
          this.step = 2;
          return;
        }

        const preselectedLicense = this.licenses.find(
          (license) => license.key === this.preselectedLicenseKey
        );
        if (preselectedLicense) {
          this.selectLicense(preselectedLicense);
          this.onSignUp();
        }
      });
  }

  onEnterLicense() {
    if (!this.isStep2DataValid) {
      this.notificationService.error('Bitte wähle einen Führerschein');
      return;
    }
    this.step = 3;
    setTimeout(() => this.postalCodeElement?.nativeElement.focus());
  }

  selectLicense(license: License) {
    const learnerLicense: LearnerLicense = {
      licenseId: license.id,
      license: license,
      isOnlineTheoryActivated: true,
      areTheoryVideosActivated: false,
    };
    this.learner.licenses = [learnerLicense];
  }

  isLicenseSelected(licenseId: number): boolean {
    if (!this.learner || !this.learner.licenses) {
      return false;
    }

    return (
      this.learner.licenses.findIndex(
        (license) => license.licenseId === licenseId
      ) !== -1
    );
  }

  goBack() {
    this.step -= 1;
  }

  onToggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  onToggleShowConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  onSignUp() {
    if (this.form.invalid || this.signUpRequestInProgress) {
      this.form.markAllAsTouched();
      return;
    }

    if (
      (this.step === 1 || this.step === 2) &&
      (!this.preselectedLicenseKey ||
        (this.preselectedLicenseKey && this.preselectedLicenseKey === ''))
    ) {
      return;
    }

    if (!this.form.value.postalCode) {
      return;
    }

    if (!this.equalPasswords) {
      this.notificationService.error('Passwörter stimmen nicht überein!');
      return;
    }

    this.learner.postalCode = this.form.value.postalCode;
    const schoolLearner = this.generateSchoolLearner();
    this.signUpRequestInProgress = true;

    this.schoolLearnerService
      .saveSchoolLearner(schoolLearner, true, false, false, true)
      .subscribe(
        (schoolLearner) => {
          this.schoolLearner = schoolLearner;
          if (this.redirectToTheoryPackagePayment) {
            this.loginAndRedirectToDashboard();
            return;
          }

          this.loginAndRedirectToDashboard();

          this.signUpRequestInProgress = false;
        },
        (err) => {
          this.signUpRequestInProgress = false;

          if (this.isDuplicateEmailError(err)) {
            this.notificationService.error('Die E-Mail existiert bereits');
            return;
          }

          this.notificationService.error(
            'Eingabe konnte nicht gespeichert werden. Ist die Postleitzahl richtig?'
          );
        }
      );
  }

  private isDuplicateEmailError(err: any) {
    return (
      err &&
      (err.message === DUPLICATE_EMAIL_ERROR ||
        err.error === DUPLICATE_EMAIL_ERROR)
    );
  }

  private generateSchoolLearner() {
    const schoolLearner = new SchoolLearner();
    schoolLearner.isEddyClubLearner = false;
    schoolLearner.usesHarmonization = false;
    schoolLearner.learner = new Learner();
    schoolLearner.learner.address = new LearnerAddress();
    schoolLearner.learner.address.postalCode = this.learner.postalCode || '';
    schoolLearner.learner.user = new UserInfo();
    schoolLearner.learner.user.firstName = this.learner.firstName;
    schoolLearner.learner.user.lastName = this.learner.lastName;
    schoolLearner.learner.user.email = this.learner.email;
    schoolLearner.learner.user.password = this.learner.password;
    schoolLearner.learner.licenses = this.learner.licenses;
    if (this.redirectToTheoryPackagePayment) {
      schoolLearner.learner.sawEddyAcademyWelcomePage = true;
    }
    return schoolLearner;
  }

  get isStep1DataValid(): boolean {
    return (
      this.form &&
      this.form.controls['name'].valid &&
      this.form.controls['lastName'].valid &&
      this.form.controls['email'].valid &&
      this.form.controls['password'].valid
    );
  }

  get isStep2DataValid(): boolean {
    return (
      this.learner && this.learner.licenses && this.learner.licenses.length > 0
    );
  }

  get equalPasswords(): boolean {
    return this.form.value.password === this.form.value.confirmPassword;
  }

  loginAndRedirectToDashboard() {
    this.loginInProgress = true;
    this.authService.login(
      this.learner.email,
      this.learner.password,
      !this.redirectToTheoryPackagePayment && !this.redirectToWelcomePage,
      () => {
        this.loginInProgress = false;
        if (this.redirectToTheoryPackagePayment) {
          this.goToTheoryPackagePaymentPage();
        }
        if (this.redirectToWelcomePage) {
          this.goToLearnerWelcomePage();
        }
        this.onQuit();
      },
      () => {
        this.loginInProgress = false;
        if (this.redirectToTheoryPackagePayment) {
          this.goToTheoryPackagePaymentPage();
        }
        if (this.redirectToWelcomePage) {
          this.goToLearnerWelcomePage();
        }
        this.router.navigateByUrl(env.learnerLanding);
        this.onQuit();
      }
    );
  }

  goToTheoryPackagePaymentPage() {
    window.location.href =
      this.theoryLessonService.buildTheoryPackagePaymentLink(
        this.schoolLearner.learner,
        false,
        `${location.origin}${this.learnerTheoryLessonsDashboardPage}`
      );
  }

  goToLearnerWelcomePage() {
    this.router.navigate([env.learnerWelcome]).then();
  }

  hasBiggerImage(licenseKey: string): boolean {
    return [
      'A+B',
      'A1+B',
      'A2+B',
      'Mofa',
      'B196',
      'B197',
      'A (B vorhanden)',
      'B (A vorhanden)',
      'B (A1 vorhanden)',
    ].includes(licenseKey);
  }

  getImagePath(licenseKey: string): string {
    if (
      licenseKey === 'A (nur klassensp. Kurse)' ||
      licenseKey === 'A (A2 vorhanden)'
    ) {
      return `/assets/product/license/blue/license_a.png`;
    } else if (['A1+B', 'A2+B'].includes(licenseKey)) {
      return `/assets/product/license/blue/license_a+b.png`;
    } else if (licenseKey === 'B (A1 vorhanden)') {
      return `/assets/product/license/blue/license_b-(a-vorhanden).png`;
    } else if (
      licenseKey === 'B (Nur Grundstoffe)' ||
      licenseKey === 'B (nur klassensp.)' ||
      licenseKey === 'BF17' ||
      licenseKey === 'BA'
    ) {
      return `/assets/product/license/blue/license_b.png`;
    } else if (licenseKey === 'BE (B vorhanden)') {
      return `/assets/product/license/blue/license_be.png`;
    } else if (licenseKey == 'A2 (A1 vorhanden)') {
      return '/assets/product/license/blue/license_a2.png';
    } else if (licenseKey == 'AM 195') {
      return '/assets/product/license/blue/license_am.png';
    }
    return `./assets/product/license/blue/license_${licenseKey
      .toLowerCase()
      .replace(/ /g, '-')}.png`;
  }
}
