<div
  class="internal-menu-container"
  [class.narrow]="narrowMode"
  [class.expand]="shouldExpandMenuContainer"
>
  <div class="internal-menu-inner-cont">
    <a class="header-link">
      <img
        *ngIf="!narrowMode"
        class="header-logo"
        src="/assets/logo-white.svg"
        alt="drivEddy - die Fahrschul App - Logo"
      />
      <img
        *ngIf="narrowMode"
        class="narrow-header-logo"
        src="/assets/logo-192x192-white.png"
        alt="drivEddy - die Fahrschul App - Logo"
      />
      <i
        *ngIf="isOverMode && !narrowMode"
        class="fal fa-times"
        (click)="toggleSidenav()"
      ></i>
      <i
        *ngIf="!isOverMode && !narrowMode"
        class="fas fa-angle-left"
        (click)="toggleNarrow()"
      ></i>
      <i
        *ngIf="!isOverMode && narrowMode"
        class="fas fa-angle-right sidenav-toggle"
        (click)="toggleNarrow()"
      ></i>
    </a>

    <ng-container *ngFor="let routeItem of routes">
      <ng-container *ngIf="!routeItem.children?.length">
        <a
          [routerLink]="routeItem.route"
          (click)="onRouteClick()"
          class="menu-item"
          [class.active]="routeItem.route === activeRouteUrl"
          (mouseenter)="onMenuItemMouseEnter(routeItem)"
          (mouseleave)="onMenuItemMouseLeave(routeItem)"
        >
          <ng-container *ngIf="routeItem.iconClasses"
            ><i [class]="routeItem.iconClasses"></i>&nbsp;</ng-container
          >
          <span
            *ngIf="!narrowMode || routeItem['showLabel']"
            class="menu-item-label desktop-only-element-inline-block"
            >{{ routeItem.name }}
            <span *ngIf="routeItem.subtitle" class="menu-item-label-small">{{
              routeItem.subtitle
            }}</span></span
          >
        </a>
      </ng-container>

      <ng-container *ngIf="routeItem.children?.length">
        <a
          (click)="onParentRouteClick(routeItem)"
          class="menu-item parent-menu-item"
          (mouseenter)="onMenuItemMouseEnter(routeItem)"
          (mouseleave)="onMenuItemMouseLeave(routeItem)"
        >
          <ng-container *ngIf="routeItem.iconClasses"
            ><i [class]="routeItem.iconClasses"></i>&nbsp;</ng-container
          >
          <span
            *ngIf="!narrowMode || routeItem['showLabel']"
            class="menu-item-label desktop-only-element-inline-block"
            >{{ routeItem.name }}
            <span *ngIf="routeItem.subtitle" class="menu-item-label-small">{{
              routeItem.subtitle
            }}</span></span
          >
          <i
            class="fal fa-angle-{{ routeItem.opened ? 'up' : 'down' }} toggle"
          ></i>
        </a>

        <ng-container *ngIf="routeItem.opened">
          <a
            *ngFor="let childRouteItem of routeItem.children"
            [routerLink]="childRouteItem.route"
            (click)="onRouteClick()"
            (mouseenter)="onMenuItemMouseEnter(childRouteItem)"
            (mouseleave)="onMenuItemMouseLeave(childRouteItem)"
            class="menu-item child-menu-item"
            [class.active]="childRouteItem.route === activeRouteUrl"
          >
            <ng-container *ngIf="childRouteItem.iconClasses && narrowMode"
              ><i [class]="childRouteItem.iconClasses"></i>&nbsp;
            </ng-container>
            <span
              *ngIf="!narrowMode || childRouteItem['showLabel']"
              class="menu-item-label desktop-only-element-inline-block"
              >{{ childRouteItem.name }}
              <span
                *ngIf="childRouteItem.subtitle"
                class="menu-item-label-small"
                >{{ childRouteItem.subtitle }}</span
              ></span
            >
          </a>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <eddy-internal-user-icon [narrowMode]="narrowMode"></eddy-internal-user-icon>
</div>
