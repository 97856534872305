import { Learner } from './learner.model';

export class LearnerTheoryTopic {
    id: number;
    learnerId: number;
    learner?: Learner;
    theoryTopicKey: string;
    key: string;
    
    unlocked: boolean;
  
    constructor(json) {
      this.id = +json.id;
      this.learnerId = +json.learnerId;

      if (json.learner) {
        this.learner = new Learner(json.learner);
      }

      this.theoryTopicKey = json.theoryTopicKey;
      this.key = json.key;

      this.unlocked = !!json.unlocked;
    }
}
