import { TheoryLessonTopic } from '@models/theory-lesson-topic.model';
import { CalendarEvent } from '@models/calendar-event.model';
import { TheoryLessonAddress } from './theory-lesson-address.model';
import { TeamMember } from '@models/team-member.model';
import * as moment from 'moment';

const NEAR_CAPACITY_THRESHOLD = 5;

export class TheoryLesson {
  id: number;

  key: string;

  eventId: number;
  event?: CalendarEvent;

  topicKey: string;
  topic?: TheoryLessonTopic;

  maximumCapacity: number;

  instructor?: TeamMember;

  address?: TheoryLessonAddress;

  isOnlineLesson: boolean;
  isPureLiveStream: boolean;

  freeSeats?: number;
  freeFastlaneSeats?: number;
  isAvailableForLearner?: boolean;
  isAvailableForLearnerWeekly?: boolean;
  numberOfAttendees?: number;
  confirmedAttendees?: number;

  constructor(json) {
    this.id = +json.id;

    this.key = json.key;

    this.eventId = +json.eventId;
    if (json.event) {
      this.event = new CalendarEvent(json.event);
    }

    this.topicKey = json.topicKey;
    if (json.topic) {
      this.topic = new TheoryLessonTopic(json.topic);
    }

    this.maximumCapacity = +json.maximumCapacity;

    if (json.instructor) {
      this.instructor = new TeamMember(json.instructor);
    }

    if (json.address) {
      this.address = new TheoryLessonAddress(json.address);
    }

    this.isOnlineLesson = !!json.isOnlineLesson;
    this.isPureLiveStream = !!json.isPureLiveStream;

    if (json.freeSeats) {
      this.freeSeats = +json.freeSeats;
    }
    if (json.freeFastlaneSeats) {
      this.freeFastlaneSeats = +json.freeFastlaneSeats;
    }
    if (json.isAvailableForLearner !== undefined) {
      this.isAvailableForLearner = !!json.isAvailableForLearner;
    }
    if (json.isAvailableForLearnerWeekly !== undefined) {
      this.isAvailableForLearnerWeekly = !!json.isAvailableForLearnerWeekly;
    }
    if (json.numberOfAttendees !== undefined) {
      this.numberOfAttendees = +json.numberOfAttendees;
    }
    if (json.confirmedAttendees !== undefined) {
      this.confirmedAttendees = +json.confirmedAttendees;
    }
  }

  get durationInMinutes(): number {
    return this.event.getDurationInMinutes();
  }

  get durationInSeconds(): number {
    return this.event.getDurationInSeconds();
  }

  get instructorFullName(): string {
    if (!this.instructor) {
      return '';
    }

    return this.instructor.getFullName();
  }

  get topicDisplayName() {
    return this.topic ? this.topic.string() : this.topicKey;
  }

  get startDate(): Date {
    if (!this.event) {
      return null;
    }

    return this.event.startDate;
  }

  get endDate(): Date {
    if (!this.event) {
      return null;
    }

    return this.event.endDate;
  }

  isInThePast(): boolean {
    return this.startDate && this.startDate.getTime() < Date.now();
  }

  isInThePastOrStartingNow() {
    return this.startDate && this.startDate.getTime() <= Date.now();
  }

  isInThePastOr5MinutesBefore(): boolean {
    if (!this.startDate) {
      return false;
    }
    const differenceInTime = this.startDate.getTime() - Date.now();
    const differenceInMinutes = differenceInTime / (1000 * 60);
    return differenceInMinutes < 5;
  }

  get isFree(): boolean {
    return this.freeSeats > 0;
  }

  get isNearCapacity(): boolean {
    return this.freeSeats <= NEAR_CAPACITY_THRESHOLD;
  }

  get isFastlaneFree(): boolean {
    return this.freeFastlaneSeats > 0;
  }

  get isFastlaneNearCapacity(): boolean {
    return this.freeFastlaneSeats <= NEAR_CAPACITY_THRESHOLD;
  }

  get isBookable(): boolean {
    const differenceInDays = moment(this.startDate).diff(moment(), 'days');

    return differenceInDays >= 0 && differenceInDays <= 21;
  }

  get dateOfAvailability() {
    return moment(this.startDate).subtract(21, 'd').toDate();
  }

  get noAttendees(): boolean {
    if (this.numberOfAttendees === undefined) {
      return true;
    }
    return this.numberOfAttendees === 0;
  }

  get hasNoAttendeesForExport(): boolean {
    if (this.confirmedAttendees === undefined) {
      return true;
    }

    if (this.isInThePast()) {
      return this.confirmedAttendees === 0;
    }

    return this.noAttendees;
  }

  get numberOfAttendeesForExport(): number {
    if (this.isInThePast()) {
      return this.confirmedAttendees ? this.confirmedAttendees : 0;
    } else {
      return this.numberOfAttendees ? this.numberOfAttendees : 0;
    }
  }
}

export const byStartDate = (a: TheoryLesson, b: TheoryLesson) => {
  if (!a?.event) {
    return 1;
  }
  if (!b?.event) {
    return -1;
  }

  const t1 = a.startDate.getTime();
  const t2 = b.startDate.getTime();

  if (t1 < t2) {
    return -1;
  }

  if (t1 === t2) {
    return 0;
  }

  return 1;
};
