export enum Day {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}

export function getWorkweekDays(): Day[] {
  return [Day.MONDAY, Day.TUESDAY, Day.WEDNESDAY, Day.THURSDAY, Day.FRIDAY];
}

export function getWeekendDays(): Day[] {
  return [Day.SATURDAY, Day.SUNDAY];
}

export function getWorkingDays(): Day[] {
  return [...getWorkweekDays(), Day.SATURDAY];
}

export function getDaysOfWeek(): Day[] {
  return [...getWorkweekDays(), ...getWeekendDays()];
}

export function getDayOfWeek(date: Date): Day {
  if (!date) {
    return null;
  }

  switch (date.getDay()) {
    case 0:
      return Day.SUNDAY;
    case 1:
      return Day.MONDAY;
    case 2:
      return Day.TUESDAY;
    case 3:
      return Day.WEDNESDAY;
    case 4:
      return Day.THURSDAY;
    case 5:
      return Day.FRIDAY;
    case 6:
      return Day.SATURDAY;
    default:
      return null;
  }
}

export function getDisplayName(day: Day) {
  switch (day) {
    case Day.MONDAY:
      return 'Montag';
    case Day.TUESDAY:
      return 'Dienstag';
    case Day.WEDNESDAY:
      return 'Mittwoch';
    case Day.THURSDAY:
      return 'Donnerstag';
    case Day.FRIDAY:
      return 'Freitag';
    case Day.SATURDAY:
      return 'Samstag';
    case Day.SUNDAY:
      return 'Sonntag';
    default:
      return '';
  }
}

export function getDisplayShortName(day: Day) {
  switch (day) {
    case Day.MONDAY:
      return 'Mo';
    case Day.TUESDAY:
      return 'Di';
    case Day.WEDNESDAY:
      return 'Mi';
    case Day.THURSDAY:
      return 'Do';
    case Day.FRIDAY:
      return 'Fr';
    case Day.SATURDAY:
      return 'Sa';
    case Day.SUNDAY:
      return 'So';
    default:
      return '';
  }
}
