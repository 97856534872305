<div class="accordion-item">
  <ng-content *ngIf="open" select=".open-content"></ng-content>
  <ng-content *ngIf="!open" select=".closed-content"></ng-content>

  <i (click)="toggleOpenState()"
     class="fal"
     [class.fa-chevron-up]="open"
     [class.fa-chevron-down]="!open">
  </i>
</div>
