import { City } from '@models/city.model';
import { AssetType } from '@models/asset-type.model';
import { Advertiser } from '@models/advertiser.model';


export enum AdCampaignStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED'
}

export function getCampaignStatuses(): AdCampaignStatus[] {
  return [AdCampaignStatus.ACTIVE, AdCampaignStatus.INACTIVE, AdCampaignStatus.ARCHIVED];
}

export function getCampaignStatusDisplayName(status: AdCampaignStatus): string {
  switch (status) {
    case AdCampaignStatus.ACTIVE:
      return 'Aktiv';
    case AdCampaignStatus.INACTIVE:
      return 'Paused';
    case AdCampaignStatus.ARCHIVED:
      return 'Archiviert';
    default:
      return '';
  }
}

export class AdCampaign {
  id: string | undefined;
  name: string;
  status: AdCampaignStatus;

  advertiser: Advertiser;

  ideaText: string;
  ideaDescription: string;

  ctaLabel: string;
  ctaLink: string;

  location: string;
  locationCities?: City[];

  impressionTargetUrl: string;

  germanLanguage: boolean;
  englishLanguage: boolean;

  males: boolean;
  females: boolean;

  ageFrom: number;
  ageTo: number;

  budget: number | undefined;
  costPerImpression: number;

  startDate: Date | string | undefined;
  endDate: Date | string | undefined;

  impressions: number | undefined;
  clicks: number | undefined;

  assets: any = {};

  targetQuestionId?: number;
  targetQuestionChoiceId?: number;

  constructor(json) {
    this.id = json.id;
    this.name = json.name;
    this.status = json.status;

    this.advertiser = json.advertiser;

    this.ideaText = json.ideaText;
    this.ideaDescription = json.ideaDescription;

    this.ctaLabel = json.ctaLabel;
    this.ctaLink = json.ctaLink;

    this.location = json.location;
    this.locationCities = json.locationCities || [];

    this.impressionTargetUrl = json.impressionTargetUrl;

    this.germanLanguage = !!json.germanLanguage;
    this.englishLanguage = !!json.englishLanguage;

    this.males = !!json.males;
    this.females = !!json.females;

    this.ageFrom = +json.ageFrom;
    this.ageTo = +json.ageTo;

    this.budget = +json.budget;
    this.costPerImpression = +json.costPerImpression;

    this.startDate = new Date(json.startDate);
    this.endDate = new Date(json.endDate);

    this.impressions = +json.impressions;
    this.clicks = +json.clicks;

    this.assets = {};
    this.assets[AssetType.LEADERBOARD_DESKTOP] = json.leaderboardDesktopAsset;
    this.assets[AssetType.LEADERBOARD_MOBILE] = json.leaderboardMobileAsset;
    this.assets[AssetType.MEDIUM_RECTANGLE] = json.mediumRectangleAsset;
    this.assets[AssetType.MOBILE] = json.mobileAsset;
    this.assets[AssetType.VIDEO] = json.videoAsset;

    if (json.targetQuestionId) {
      this.targetQuestionId = +json.targetQuestionId;
    }
    if (json.targetQuestionChoiceId) {
      this.targetQuestionChoiceId = +json.targetQuestionChoiceId;
    }
  }

  static emptyCampaign(): AdCampaign {
    return {
      id: undefined,

      name: '',
      status: null,

      advertiser: null,

      ideaText: '',
      ideaDescription: '',

      ctaLabel: '',
      ctaLink: '',

      location: '',
      locationCities: [],

      impressionTargetUrl: null,

      germanLanguage: false,
      englishLanguage: false,

      males: false,
      females: false,

      ageFrom: 0,
      ageTo: 100,

      budget: undefined,
      costPerImpression: undefined,

      startDate: undefined,
      endDate: undefined,

      impressions: undefined,
      clicks: undefined,

      assets: {}
    };
  }
}
