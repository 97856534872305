import { Component, HostListener } from '@angular/core';
import { SidenavService } from '@services/sidenav.service';
import { ScreenWidthService } from '@services/screen-width.service';
import { GlobalCommunicationService } from '@services/global-communication.service';

@Component({
  selector: 'eddy-internal-header',
  templateUrl: './internal-header.component.html',
  styleUrls: ['./internal-header.component.scss'],
})
export class InternalHeaderComponent {
  isDesktop: boolean;
  navbarDisplayed = true;
  lastTopPosition = 0;
  private topBuffer = 20;

  constructor(
    private sidenavService: SidenavService,
    private screenWidthService: ScreenWidthService,
    private globalCommunicationService: GlobalCommunicationService
  ) {
    this.trackIsDesktopChange();
  }

  toggleSidenav() {
    this.sidenavService.toggle().then();
  }

  private trackIsDesktopChange() {
    this.screenWidthService.isDesktop().subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    if (!this.isDesktop) {
      const isScrollUp = this.isScrollUp();

      if (
        (this.navbarDisplayed && !isScrollUp) ||
        (!this.navbarDisplayed && isScrollUp)
      ) {
        this.globalCommunicationService.navbarDisplayChanged(isScrollUp);
      }

      this.navbarDisplayed = isScrollUp;
    }

    this.lastTopPosition = window.scrollY;
  }

  private isScrollUp() {
    return (
      this.lastTopPosition <= this.topBuffer ||
      this.lastTopPosition > window.scrollY
    );
  }
}
