import { Injectable } from '@angular/core';
import { PageListingResponse } from '@models/page-listing-response.model';
import { LessonBooking } from '@models/lesson-booking.model';
import { Observable } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { map, tap } from 'rxjs/operators';
import { LearnerAccountBalance } from '@models/learner-account-balance.model';
import { saveAs } from 'file-saver';
import { AddLearnerRefundRequest } from '@models/add-learner-refund-request.model';
import { LearnerRefund } from '@models/learner-refund.model';
import { LearnerAvailableCredits } from '@models/learner-available-credits.model';
import { BlobWithFileName } from '@models/blob-with-filename.model';
import { YoudriveBooking } from '@models/youdrive-booking.model';
import { DateUtils } from './date-utils.service';
import { TheoryLearningPaymentReceipt } from '@models/theory-learning-payment-receipt.model';
import { PracticeLessonsPaymentStatistics } from '@models/graph-stats/practice_lessons_payment_statistics.model';
import { BookingsPaymentTypeStatistics } from '@models/graph-stats/bookings_payment_type_statistics.model';
import { BookingsRevenueStatistics } from '@models/graph-stats/bookings_revenue_statistics.model';
@Injectable()
export class LessonBookingService {
  private readonly apiPath = `${env.apiSchoolService}`;
  private readonly theoryPackagePrefix = 'theory_package_';

  constructor(private api: ApiService) {}

  getLessonBookingsPaged(
    instructorId: number,
    filters: any,
    pageNumber: number,
    pageSize: number
  ): Observable<PageListingResponse<LessonBooking>> {
    return this.api
      .getPaged(
        `${this.apiPath}/team_members/${instructorId}/lesson_bookings/paged`,
        pageNumber,
        pageSize,
        filters
      )
      .pipe(map(this.mapCalendarBookingPaged));
  }

  getLessonBookingsForLearnerPaged(
    learnerId: number,
    filters: any,
    pageNumber: number,
    pageSize: number
  ): Observable<PageListingResponse<LessonBooking>> {
    return this.api
      .getPaged(
        `${this.apiPath}/learners/${learnerId}/lesson_bookings`,
        pageNumber,
        pageSize,
        filters
      )
      .pipe(map(this.mapCalendarBookingPaged));
  }

  getLessonBookingsForSchoolPaged(
    schoolId: number,
    filters: any,
    pageNumber: number,
    pageSize: number
  ): Observable<PageListingResponse<LessonBooking>> {
    return this.api
      .getPaged(
        `${this.apiPath}/schools/${schoolId}/instructors_lesson_bookings`,
        pageNumber,
        pageSize,
        filters
      )
      .pipe(map(this.mapCalendarBookingPaged));
  }

  private mapCalendarBookingPaged(
    res: PageListingResponse
  ): PageListingResponse<LessonBooking> {
    return {
      total: res.total,
      records: res.records.map((it) => new LessonBooking(it)),
    };
  }

  getLessonBooking(bookingId: number): Observable<LessonBooking> {
    return this.api
      .get<LessonBooking>(`${this.apiPath}/lesson_bookings/${bookingId}`)
      .pipe(map((it) => new LessonBooking(it)));
  }

  getLearnerAccountBalance(
    learnerId: number
  ): Observable<LearnerAccountBalance> {
    return this.api
      .get<LearnerAccountBalance>(
        `${this.apiPath}/learners/${learnerId}/account_balance`
      )
      .pipe(map((it) => new LearnerAccountBalance(it)));
  }

  getLearnerAvailableCredits(
    learnerId: number
  ): Observable<LearnerAvailableCredits> {
    return this.api.get<LearnerAvailableCredits>(
      `${this.apiPath}/learners/${learnerId}/available_credits`
    );
  }

  downloadLearnerPaymentReceipt(
    booking: LessonBooking,
    indirectOrderKey?: string
  ): Observable<any> {
    const key = indirectOrderKey ? indirectOrderKey : booking.key;
    return this.api
      .getBlob(`${env.paymentRootLink}/lessonpayments/${key}/receipt`)
      .pipe(
        tap((blob: Blob) => {
          if (blob.type === 'application/zip') {
            saveAs(blob, `${booking.getReceiptFileName()}.zip`);
          } else {
            saveAs(blob, `${booking.getReceiptFileName()}.pdf`);
          }
        })
      );
  }

  downloadLearnerRefundReceipt(booking: LessonBooking): Observable<any> {
    return this.api
      .getBlobWithFileName(
        `${env.paymentRootLink}/learnerrefunds/${booking.key}/refundDocument`
      )
      .pipe(
        tap((blobWithFileName: BlobWithFileName) => {
          saveAs(blobWithFileName.blob, blobWithFileName.fileName);
        })
      );
  }

  addTheoryTopicRefund(
    learnerTheoryTopicKey: string,
    req: AddLearnerRefundRequest
  ): Observable<LearnerRefund> {
    return this.api.post<LearnerRefund>(
      `${env.apiSchoolService}/learner_theory_topics/${learnerTheoryTopicKey}/refund`,
      req
    );
  }

  addTheoryPackageRefund(
    learnerTheoryPackageKey: string,
    req: AddLearnerRefundRequest
  ): Observable<LearnerRefund> {
    return this.api.post<LearnerRefund>(
      `${env.apiSchoolService}/learner_theory_packages/${learnerTheoryPackageKey}/refund`,
      req
    );
  }

  addBasicFeeRefund(
    learnerLicenseKey: string,
    req: AddLearnerRefundRequest
  ): Observable<LearnerRefund> {
    return this.api.post<LearnerRefund>(
      `${env.apiSchoolService}/learner_basic_fees/${learnerLicenseKey}/refund`,
      req
    );
  }

  addPracticeLessonRefund(
    practiceLessonKey: string,
    req: AddLearnerRefundRequest
  ): Observable<LearnerRefund> {
    return this.api.post<LearnerRefund>(
      `${env.apiSchoolService}/practice_lessons/${practiceLessonKey}/refund`,
      req
    );
  }

  addTheoryLearningRefund(
    key: string,
    req: AddLearnerRefundRequest
  ): Observable<LearnerRefund> {
    return this.api.post<LearnerRefund>(
      `${env.apiSchoolService}/theory_learnings/${key}/refund`,
      req
    );
  }

  addLearnerCreditRefund(
    key: string,
    req: AddLearnerRefundRequest
  ): Observable<LearnerRefund> {
    return this.api.post<LearnerRefund>(
      `${env.apiSchoolService}/learner_credits/${key}/refund`,
      req
    );
  }

  markLearnerCreditAsPaidInCash(
    creditKey: string,
    isPaidByBankTransfer: boolean,
    description: string,
    isPaidToEddy: boolean
  ): Observable<void> {
    return this.api.post<void>(
      `${env.apiSchoolService}/learner_credits/${creditKey}/paid_in_cash`,
      { isPaidByBankTransfer, description, isPaidToEddy }
    );
  }

  markBasicFeeAsPaidInCash(
    key: string,
    isPaidByBankTransfer: boolean,
    description: string,
    isPaidToEddy: boolean
  ): Observable<void> {
    return this.api.post<void>(
      `${env.apiSchoolService}/learner_basic_fees/${key}/paid_in_cash`,
      { isPaidByBankTransfer, description, isPaidToEddy }
    );
  }

  markTheoryPackageAsPaidInCash(
    key: string,
    isPaidByBankTransfer: boolean,
    description: string,
    isPaidToEddy: boolean
  ): Observable<void> {
    return this.api.post<void>(
      `${env.apiSchoolService}/learner_theory_packages/${key}/paid_in_cash`,
      { isPaidByBankTransfer, description, isPaidToEddy }
    );
  }

  markTheoryTopicAsPaidInCash(
    key: string,
    isPaidByBankTransfer: boolean,
    description: string,
    isPaidToEddy: boolean
  ): Observable<void> {
    return this.api.post<void>(
      `${env.apiSchoolService}/learner_theory_topics/${key}/paid_in_cash`,
      { isPaidByBankTransfer, description, isPaidToEddy }
    );
  }

  markTheoryLearningAsPaidInCash(
    key: string,
    isPaidByBankTransfer: boolean,
    description: string,
    isPaidToEddy: boolean
  ): Observable<void> {
    return this.api.post<void>(
      `${env.apiSchoolService}/theory_learnings/${key}/paid_in_cash`,
      { isPaidByBankTransfer, description, isPaidToEddy }
    );
  }

  getLearnerTheoryLearningPaymentStatus(
    learnerId: number
  ): Observable<TheoryLearningPaymentReceipt> {
    return this.api
      .get<TheoryLearningPaymentReceipt>(
        `${env.apiSchoolService}/theory_learnings/${learnerId}/status`
      )
      .pipe(map((it) => new TheoryLearningPaymentReceipt(it)));
  }

  generateCashPaymentForBooking(
    orderKey: string,
    isTheoryPackage = false
  ): Observable<void> {
    if (isTheoryPackage) {
      orderKey = `${this.theoryPackagePrefix}${orderKey}`;
    }
    return this.api.post<void>(
      `${env.paymentRootLink}/lessonpayments/${orderKey}/generateCashPayment`,
      {}
    );
  }

  payBookingByCredit(learnerId: number, orderKey: string) {
    return this.api.post<void>(
      `${env.apiSchoolService}/learners/${learnerId}/bookings/${orderKey}/payByCredit`,
      {}
    );
  }

  resendBookingConfirmationEmail(orderKey: string): Observable<void> {
    return this.api.post<void>(
      `${env.paymentRootLink}/lessonpayments/${orderKey}/resendBookingConfirmationEmail`,
      {}
    );
  }

  archiveBooking(orderKey: string): Observable<void> {
    return this.api.post<void>(
      `${env.apiSchoolService}/bookings/${orderKey}/archive`,
      {}
    );
  }

  undoArchiveBooking(orderKey: string): Observable<void> {
    return this.api.post<void>(
      `${env.apiSchoolService}/bookings/${orderKey}/archive/undo`,
      {}
    );
  }

  downloadYoudriveBookingProof(
    booking: YoudriveBooking,
    fileName?: string
  ): Observable<Blob> {
    return this.api
      .getBlob(`${this.apiPath}/youdrive_bookings/${booking.id}/proof`)
      .pipe(
        tap((blob: Blob) => {
          if (!fileName || fileName === '') {
            fileName = `${DateUtils.dateOnlyToString(
              booking.date
            )}-nachweis.pdf`;
          }
          saveAs(blob, fileName);
        })
      );
  }

  getPracticeLessonsPaymentStatistics(
    filters: Map<string, any>
  ): Observable<PracticeLessonsPaymentStatistics> {
    return this.api.get<PracticeLessonsPaymentStatistics>(
      `${this.apiPath}/lesson_bookings/payment_statistics`,
      filters
    );
  }

  getBookingsPaymentTypeStatistics(
    filters: Map<string, any>
  ): Observable<BookingsPaymentTypeStatistics> {
    return this.api.get<BookingsPaymentTypeStatistics>(
      `${this.apiPath}/lesson_bookings/payment_type_statistics`,
      filters
    );
  }

  getBookingsRevenueStatistics(
    filters: Map<string, any>
  ): Observable<BookingsRevenueStatistics> {
    return this.api.get<BookingsRevenueStatistics>(
      `${this.apiPath}/lesson_bookings/revenue_statistics`,
      filters
    );
  }

  buildLessonPaymentLink(booking: LessonBooking): string {
    if (booking.practiceLesson || booking.learnerTheoryTopic) {
      return this.buildPracticeLessonPaymentLink(booking.key);
    } else if (booking.basicFee) {
      return this.buildBasicFeePaymentLink(
        this.removeBasicFeePaymentPrefix(booking.key)
      );
    } else if (booking.learnerCredit) {
      return this.buildLearnerCreditPaymentLink(booking.key);
    } else if (booking.theoryLearningPaymentReceipt) {
      return this.buildTheoryLearningPaymentLink(booking.key);
    }
    return this.buildTheoryPackagePaymentLink(
      this.removeTheoryPackagePaymentPrefix(booking.key)
    );
  }

  buildPracticeLessonPaymentLink(key: string): string {
    return `${
      env.paymentRootLink
    }/lessonbookings/${key}?redirectUrl=${this.getPaymentRedirectUrl(true)}`;
  }

  buildTheoryPackagePaymentLink(key: string): string {
    return `${
      env.paymentRootLink
    }/theorypackages/${key}?redirectUrl=${this.getPaymentRedirectUrl()}`;
  }

  buildBasicFeePaymentLink(key: string): string {
    return `${
      env.paymentRootLink
    }/basicfees/${key}?redirectUrl=${this.getPaymentRedirectUrl()}`;
  }

  buildLearnerCreditPaymentLink(key: string): string {
    return `${
      env.paymentRootLink
    }/credits/${key}?redirectUrl=${this.getPaymentRedirectUrl()}`;
  }

  buildTheoryLearningPaymentLink(key: string): string {
    return `${
      env.paymentRootLink
    }/theorylearning/${key}?redirectUrl=${this.getPaymentRedirectUrl()}`;
  }

  removeTheoryPackagePaymentPrefix(orderKey: string): string {
    if (orderKey.startsWith('tp_')) {
      return orderKey.substring(3);
    } else if (orderKey.startsWith('theory_package_')) {
      return orderKey.substring(15);
    }

    return orderKey;
  }

  removeBasicFeePaymentPrefix(orderKey: string): string {
    if (orderKey.startsWith('bf_')) {
      return orderKey.substring(3);
    } else if (orderKey.startsWith('basic_fee_')) {
      return orderKey.substring(10);
    }

    return orderKey;
  }

  private getPaymentRedirectUrl(
    isSingleLessonPayment?: boolean,
    baseUrl?: string
  ) {
    const base = baseUrl || window.location.href;

    const paymentSuccessParam = isSingleLessonPayment
      ? 'lessonpayment=success'
      : 'payment=success';

    let url = base;
    if (base.indexOf('?') !== -1) {
      url = url.replace('?', `?${paymentSuccessParam}&`);
    } else {
      url = `${url}?${paymentSuccessParam}`;
    }

    return encodeURIComponent(url);
  }
}
