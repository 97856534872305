export class VendorBankDetail {
  bank: string;
  bankCode: number;
  accountOwner: string;
  accountNumber: number;
  bic: string;
  iban: string;
  id?: number;

  constructor(json?: any) {
    if (!json) {
      return;
    }

    if (json.id) {
      this.id = +json.id;
    }

    this.bank = json.bank;
    this.bankCode = json.bankCode;
    this.accountOwner = json.accountOwner;
    this.accountNumber = json.accountNumber;
    this.bic = json.bic;
    this.iban = json.iban;
  }

  static empty() {
    return new VendorBankDetail();
  }

  static driveddyBankDetail() {
    return new VendorBankDetail({
      bank: 'Deutsche Bank',
      iban: 'DE55 1007 0024 0136 0882 00',
      bic: 'DEUTDEDBBER',
    });
  }
}
