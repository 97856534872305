import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AnalyticsEvent } from '@models/analytics-event.model';
import { environment as env } from '@environments/environment';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class AnalyticsService {
  private readonly isServer: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: any) {
    this.isServer = isPlatformServer(platformId);
  }

  registerEvent(analyticsEvent: AnalyticsEvent) {
    if (this.isServer) {
      return;
    }

    const obj = {
      event_category: analyticsEvent.category,
      event_label: analyticsEvent.label,
      environment: env.environmentName
    };

    if (!!analyticsEvent.value) {
      obj['value'] = analyticsEvent.value;
    }

    // @ts-ignore
    gtag('event', analyticsEvent.action, obj);
  }
}
