import { NumberUtils } from '@services/number-utils.service';
import { InstructorTheoryPackageLicenseType } from './instructor-theory-package-license-type.model';

export class InstructorTheoryLessonsPackage {
  licenseType: InstructorTheoryPackageLicenseType;
  key: string;
  paymentLink: string;
  priceInCents: number;
  isPaid: boolean;
  datePaid?: Date;
  paymentExpireDate?: Date;

  constructor(json) {
    this.licenseType = json.licenseType;
    this.key = json.key;
    this.paymentLink = json.paymentLink;
    this.priceInCents = +json.priceInCents;
    this.isPaid = !!json.isPaid;

    if (json.datePaid) {
      this.datePaid = new Date(json.datePaid);
    }

    if (json.paymentExpireDate) {
      this.paymentExpireDate = new Date(json.paymentExpireDate);
    }
  }

  get displayPrice(): string {
    return NumberUtils.formatCurrency(this.priceInCents);
  }
}
