<div class="custom-autocomplete" *ngIf="!isSettingsError">
  <div class="custom-autocomplete__container">
    <div class="custom-autocomplete__input" [ngClass]="{'button-included':settings.showSearchButton}">
      <div class="input-padding-container">
        <div class="submit-button btn btn-primary" *ngIf="settings.showSearchButton" (click)="processSearchQuery()">
          {{settings.labels.searchPlaceholderText}}
        </div>
        <div class="search-box-container">
          <input [(ngModel)]="locationInput" (click)="searchinputClickCallback($event)"
                 (keyup)="searchinputCallback($event)"
                 type="search" name="search" id="search_places" placeholder="{{settings.inputPlaceholderText}}"
                 autocomplete="off"/>
        </div>
      </div>
    </div>
    <pre class="custom-autocomplete__loader" *ngIf="gettingCurrentLocationFlag"><i class="gif"></i></pre>
  </div>
  <ul class="custom-autocomplete__dropdown"
      *ngIf="dropdownOpen && (settings.showCurrentLocation || queryItems.length)">
    <li *ngIf="settings.showCurrentLocation" class="currentlocation">
      <a href="javascript:;" (click)="currentLocationSelected()">
        <i class="location-icon" *ngIf="settings.currentLocIconUrl"
           [ngStyle]="{'background-image': 'url(' + settings.currentLocIconUrl + ')'}"></i>{{settings.labels.currentLocationText}}
        <i class="location-icon current-default-icon" *ngIf="!settings.currentLocIconUrl"></i>
      </a>
    </li>
    <li class="heading heading-recent" *ngIf="!recentDropdownOpen && queryItems.length"><span>{{settings.labels.locationsText}}</span><span
      class="line line-location"></span></li>
    <li class="heading heading-recent" *ngIf="recentDropdownOpen && queryItems.length">
      <span>{{settings.labels.recentSearchesText}}</span><span class="line line-recent"></span>
    </li>
    <li *ngFor="let data of queryItems;let $index = index" [ngClass]="{'active': data.active}">
      <a href="javascript:;" (mouseover)="activeListNode($index)" (click)="selectedListNode($index)">
        <i class="custom-icon" *ngIf="settings.locationIconUrl"
           [ngStyle]="{'background-image': 'url(' + settings.locationIconUrl + ')'}"></i>
        <i class="custom-icon location-default-icon" *ngIf="!settings.locationIconUrl"></i>
        <span class="main-text">
                {{data.structured_formatting?.main_text ? data.structured_formatting.main_text : data.description}}
              </span>
        <span class="secondary_text"
              *ngIf="data.structured_formatting?.secondary_text">{{data.structured_formatting.secondary_text}}</span>
      </a>
    </li>
  </ul>
</div>
<div class="custom-autocomplete--error" *ngIf="isSettingsError">{{settingsErrorMsg}}</div>
