export enum CalendarEventType {
  THEORY = 'THEORY',
  PRACTICE = 'PRACTICE',
  BLOCK_TIME = 'BLOCK_TIME',
  PRACTICE_EXAM = 'PRACTICE_EXAM',
  THEORY_EXAM = 'THEORY_EXAM',
}

export function getNonVacationCalendarEventTypes(): CalendarEventType[] {
  return [CalendarEventType.PRACTICE, CalendarEventType.THEORY];
}

export function getExamCalendarEventTypes(): CalendarEventType[] {
  return [CalendarEventType.PRACTICE_EXAM, CalendarEventType.THEORY_EXAM];
}

export function getDisplayName(type: CalendarEventType): string {
  switch (type) {
    case CalendarEventType.PRACTICE:
      return 'Praktische Fahrstunde';
    case CalendarEventType.THEORY:
      return 'Theoriestunde';
    case CalendarEventType.PRACTICE_EXAM:
      return 'Praktische Prüfung';
    case CalendarEventType.THEORY_EXAM:
      return 'Theoretische Prüfung';
    default:
      return '';
  }
}
