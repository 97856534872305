import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PageListingResponse } from '@models/page-listing-response.model';
import { throwError } from 'rxjs';
import { environment as env } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable()
export class LanguageService {

  constructor(private http: HttpClient,
              private authService: AuthService) {
  }

  public getLanguages() {
    return this.http.get<PageListingResponse>(`${env.api}/language/all`, this.authService.getHttpOptions())
      .pipe(
        map(res => res['data']),
        catchError(err => throwError(err))
      );
  }
}
