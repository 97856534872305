import { AbstractControl, ValidationErrors } from '@angular/forms';

const PHONE_NUMBER_REGEXP = /^[+0-9\- ]*$/;

export function phoneValidator(control: AbstractControl): ValidationErrors | null {
  return isValidPhoneNumber(control.value) ? null : {
    validatePhone: {
      valid: false
    }
  };
}

export function isValidPhoneNumber(phoneNumber: string): boolean {
  return PHONE_NUMBER_REGEXP.test(phoneNumber);
}